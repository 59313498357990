import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Button, Input, Form, FormGroup, Label, UncontrolledTooltip } from 'reactstrap';
import api from '../services/api';
import Header from './Header';
import { getImageUrl } from './util';
import {withRouter} from "../containers/withRouter/withRouter";

class WriteReview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            errors: {},
            name: '',
            firstname: '',
            profileImg: '',
            areaslist: [],
            areaslistvalue: [],
            anonymously: '',
            starsratings: '',
            postedName: '',
            posteddate: moment(),
            postedtitle: '',
            postedcomment: '',
            areaslistselected: '',
            consultationselected: '',
            editorState: EditorState.createEmpty(),
            loading: false,
        };
        this.SubmitWriteReview = this.SubmitWriteReview.bind(this);
    }
    componentDidMount() {
        let userName = this.props.match.params.userName;
        let params = new URLSearchParams(this.props.location.search);
        let name = params.get('name');
        let email = params.get('email');
        if (name !== null && email !== null) {
            let fields = this.state.fields;
            fields["firstname"] = name;
            fields["email"] = email;
        }
        fetch(process.env.REACT_APP_API_ROOT + 'profile/public_profile?slug=' + userName, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            },
        }).then(function (res) {
            if (res.status < 200 || res.status >= 300) {
                //console.log("Error 401");
                //console.log(res);
                //return res.json().then(err => {throw err;});
                throw res;
                //throw Error(.statusText);
            } else { return res.json() }
        })
            .then(
                (res) => {
                    var loaderelement = document.getElementById('loader');
                    loaderelement.parentNode.removeChild(loaderelement);
                    let firstname = '';
                    let slugfirstname = '';
                    let fullname = res[0].name.match(/(\w+)(?:\s[^\s]+)?\s(\w+).*/);
                    if (fullname !== null) {
                        firstname = fullname[1] + " " + fullname[2].charAt(0) + ".";
                        slugfirstname = fullname[1].toLowerCase();
                    }
                    else {
                        firstname = res[0].name;
                        slugfirstname = firstname.toLowerCase();
                    }
                    
                    slugfirstname = slugfirstname.trim();

                    let slugValue = '';
                    let slugId = '';
                    let city = '';
                    if(res[0].location_served!==null){

                        let locationData = res[0].location_served;
                        
                        locationData = locationData.replace(/[^a-zA-Z0-9 ]/g, "");
                        locationData = locationData.trim();
                        locationData = locationData.replace(/ /g, "-");
                        locationData = locationData.replace(/--/, "-");
                        locationData = locationData.toLowerCase();
                        city = locationData;
                        slugId = res[0].id;
                        slugValue = city+"/"+slugfirstname+"-"+slugId;
                      


                    }else{


                        slugId = res[0].id;
                        slugValue = slugfirstname+"-"+slugId;

                    }

                    this.setState({
                        profileImg: res[0].profile_pic,
                        name: res[0].name,
                        firstname: firstname,
                        slugName: slugValue,
                        user_type_id: res[0].user_type_id,
                    });

                    api.get('review/question_list', null, false)
                    .then(r => {
                        if(this.state.user_type_id === 1) {
                            const review_question_buyer = [{
                                review_question: 'Industry knowledge'
                            },{
                                review_question: 'Follow-through'
                            },{
                                review_question: 'Business Valuation'
                            },{
                                review_question: 'Negotiating the deal'
                            },{
                                review_question: 'Taking time to listen'
                            },{
                                review_question: 'Responsive and available'
                            },{
                                review_question: 'Respectful'
                            },{
                                review_question: 'Fun to work with'
                            },];
                            this.setState({
                                areaslist: review_question_buyer
                            });
                        } else {
                            this.setState({
                                areaslist: r
                            });
                        }
                    })
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        //error
                    });
                }
            )
            
    }

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState
        });
    };

    handleWriteReviewValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        //rating
        if (!fields["rating"]) {
            formIsValid = false;
            errors["rating"] = "Please select rating.";
        }
        //reviewtitle
        if (!fields["reviewtitle"]) {
            formIsValid = false;
            errors["reviewtitle"] = "Add a title to your review";
        }
        //shareexperience
        /*if (!fields["shareexperience"]) {
            formIsValid = false;
            errors["shareexperience"] = `Please describe your experience with ${this.state.firstname}.`;
        }*/
        if (draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())).length <= 8) {
          formIsValid = false;
          errors["shareexperience"] = `Please describe your experience with ${this.state.firstname}.`;
        }
        //areas
        if (!this.state.areaslistvalue.length > 0) {
            formIsValid = false;
            errors["areas"] = `Please select the areas where you think ${this.state.firstname} excels?`;
        }
        //consultation
        if (!fields["consultation"]) {
            formIsValid = false;
            errors["consultation"] = "Was this a consultation or did you hire this intermediary?";
        }
        //anonymously
        if (!fields["anonymously"]) {
            formIsValid = false;
            errors["anonymously"] = "Post your review anonymously?";
        }
        if (this.state.anonymously === 'No, use my first name') {
            //firstname
            if (!fields["firstname"]) {
                formIsValid = false;
                errors["firstname"] = "Please enter your name";
            }

            if (fields["firstname"] && fields["firstname"].length > 50) {
                formIsValid = false;
                errors["firstname"] = "Name is too long";
            }

            //Email
            if (!fields["email"]) {
                formIsValid = false;
                errors["email"] = "Please enter email address.";
            }
            if (typeof fields["email"] !== "undefined") {
                let lastAtPos = fields["email"].lastIndexOf('@');
                let lastDotPos = fields["email"].lastIndexOf('.');
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                    formIsValid = false;
                    errors["email"] = "Please enter valid email address.";
                }
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleChangeWriteReview(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        if (e.target.name === 'anonymously') {
            if (fields[field] === 'No, use my first name') {
                this.setState({ anonymously: 'No, use my first name' });
            }
            else {
                this.setState({ anonymously: '' });
            }
        }
        var areascheckboxes = document.getElementsByName('areas[]');
        var areasvalue = "";
        for (var i = 0, n = areascheckboxes.length; i < n; i++) {
            if (areascheckboxes[i].checked) {
                areasvalue += ", " + areascheckboxes[i].value;
            }
        }
        if (areasvalue) areasvalue = areasvalue.substring(1);
        this.setState({ fields, areaslistvalue: areasvalue });
    }
    SubmitWriteReview(e) {
        e.preventDefault();
        if (this.handleWriteReviewValidation()) {
            document.getElementById('loaderwritereviews').setAttribute("style", "display: block;");
            let fields = this.state.fields;
            let name = '', email = '';
            if (this.state.anonymously === 'No, use my first name') {
                name = fields["firstname"].match(/(\w+)(?:\s[^\s]+)?\s(\w+).*/);
                if (name !== null) {
                    name = name[1] + " " + name[2].charAt(0) + ".";
                }
                else {
                    name = fields["firstname"];
                }
                email = fields["email"];
            }
            else {
                name = 'anonymous';
                email = 'anonymous';
            }
            let jsonwritereviewsData = JSON.stringify({
                "slug": this.props.match.params.userName,
                "reviewer_name": name,
                "email": email,
                "review_title": fields["reviewtitle"],
                "review_text": draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
                "consultation_hire": fields["consultation"],
                "status": "1",
                "ip_address": localStorage.getItem("ip"),
                "review_question_list": this.state.areaslistvalue,
                "rating_options_list": fields["rating"],
                "anonymously": fields["anonymously"]
            });
            //console.log(jsonwritereviewsData);
            this.setState({ loading: true });
            fetch(process.env.REACT_APP_API_ROOT + 'review/add', {
                method: 'POST',
                body: jsonwritereviewsData,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    'x-access-token': localStorage.getItem('token'),
                },
            }).then((res) => {
                if (res.status < 200 || res.status >= 300) {
                    //console.log("Error 401");
                    //console.log(res);
                    if (res.status === 401) {
                        this.props.history.push('/logout');
                    } else {
                        return res.json().then(err => { throw err; });
                    }
                } else { return res.json() }
            })
                .then(
                    (res) => {
                        this.setState({ loading: false });
                        document.getElementById('loaderwritereviews').setAttribute("style", "display: none;");
                        //console.log(res);
                        this.setState({ starsratings: fields["rating"], postedName: name, postedtitle: fields["reviewtitle"], postedcomment: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())), areaslistselected: this.state.areaslistvalue, consultationselected: fields["consultation"] })
                    },
                    (error) => {
                        this.setState({
                            //error
                        });
                    }
                )
        } else {
            //console.log("Form has errors.")
        }
    }
    starRatings = (stars) => {
        let ratings = [];
        let filledstars = stars;
        let blankstars = 5 - stars;
        for (let k = 0; k < filledstars; k++) {
            ratings.push(<i className="sprite i-starfill-md" key={"filledstars" + k}></i>);
        }
        for (let k = 0; k < blankstars; k++) {
            ratings.push(<i className="sprite i-star-md" key={"blankstars" + k}></i>);
        }
        return ratings;
    }
    createMarkup(data) {
        return { __html: data };
    }
    render() {
        const { editorState } = this.state;
        return (
            <div>
                <div className="loader bg-white" id="loader" style={{ display: 'block' }}><h3>Please Wait...</h3><div className="spiner"></div></div>
                <Header slugUrl={this.state.slugName} />
                <section className="profile-bg write-review">
                    <div className="container">
                        <div className="profile-bx">
                            <div className="loader bg-white" id="loaderwritereviews" style={{ display: 'none' }}><div className="spiner"></div></div>
                            {this.state.starsratings === '' &&
                                <Form onSubmit={this.SubmitWriteReview}>
                                    <div className="text-center WriteReviewForm" ref={(section) => { this.WriteReviewForm = section; }}>
                                        <div className="profile-pic">
                                            <div className="pic-overflow">
                                            <img src={(this.state.profileImg) ? (this.state.profileImg.indexOf('user_profile') > -1) ? getImageUrl(this.state.profileImg) : getImageUrl(this.state.profileImg) : process.env.PUBLIC_URL + "/images/logo-big.png"} className="img-fluid rounded-circle" alt={this.state.firstname} />
                                            </div>
                                        </div>
                                        <h4 className="text-blue">Write a Review for {this.state.firstname}</h4>
                                        <FormGroup>
                                            <h5>Please assign a rating: <span style={{ color: 'red' }}>*</span>
                                         <span className="ratings">
                                                    <Input type="radio" name="rating" id="rating5" value="5" className="rating-input" onChange={this.handleChangeWriteReview.bind(this, "rating")} />
                                                    <Label className="sprite rating-star" check htmlFor="rating5"></Label>
                                                    <Input type="radio" name="rating" id="rating4" value="4" className="rating-input" onChange={this.handleChangeWriteReview.bind(this, "rating")} />
                                                    <Label className="sprite rating-star" check htmlFor="rating4"></Label>
                                                    <Input type="radio" name="rating" id="rating3" value="3" className="rating-input" onChange={this.handleChangeWriteReview.bind(this, "rating")} />
                                                    <Label className="sprite rating-star" check htmlFor="rating3"></Label>
                                                    <Input type="radio" name="rating" id="rating2" value="2" className="rating-input" onChange={this.handleChangeWriteReview.bind(this, "rating")} />
                                                    <Label className="sprite rating-star" check htmlFor="rating2"></Label>
                                                    <Input type="radio" name="rating" id="rating1" value="1" className="rating-input" onChange={this.handleChangeWriteReview.bind(this, "rating")} />
                                                    <Label className="sprite rating-star" check htmlFor="rating1"></Label>
                                                </span>
                                            </h5>
                                            <span className="error">{this.state.errors["rating"]}</span>
                                        </FormGroup>
                                    </div>
                                    <div className="profile-modal">
                                        <FormGroup>
                                            <Label htmlFor="reviewtitle">Add a title <span style={{ color: 'red' }}>*</span></Label>
                                            <Input type="text" name="reviewtitle" id="reviewtitle" ref="reviewtitle" autoFocus onChange={this.handleChangeWriteReview.bind(this, "reviewtitle")} value={this.state.fields["reviewtitle"]} placeholder="" />
                                            <span className="error">{this.state.errors["reviewtitle"]}</span>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="shareexperience">Please describe your experience with {this.state.firstname} <span style={{ color: 'red' }}>*</span></Label>
                                            <Editor
                                              editorState={editorState}
                                              wrapperClassName="demo-wrapper"
                                              editorClassName="rich-editor"
                                              onEditorStateChange={this.onEditorStateChange}
                                              toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'embedded', 'emoji', 'image', 'remove', 'history'],
                                              }}
                                              spellCheck={true}
                                            />
                                            <span className="error">{this.state.errors["shareexperience"]}</span>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="areas">In what areas did {this.state.firstname} excel? <span style={{ color: 'red' }}>*</span></Label>
                                            {this.state.areaslist.map((areas, j) => {
                                                return (
                                                    <FormGroup check key={`areas-${j}`}>
                                                        <Input type="checkbox" id={`areas_${j}`} value={areas.review_question} name="areas[]" onChange={this.handleChangeWriteReview.bind(this, "areas")}/>
                                                        <Label check htmlFor={`areas_${j}`}>
                                                            {areas.review_question}
                                                        </Label>
                                                    </FormGroup>
                                                )
                                            })
                                            }
                                            <span className="error">{this.state.errors["areas"]}</span>
                                        </FormGroup>
                                        <FormGroup>
                                        <Label for="consultation">{this.state.user_type_id === 1 ? 'Was this consultation or did you work with this buyer on a transaction?': 'Was this a consultation or did you hire this intermediary?'} <span style={{ color: 'red' }}>*</span></Label>
                                            {this.state.user_type_id === 1 ? 
                                            <div>
                                                <FormGroup check inline>
                                                    <Input type="radio" id="consultation" name="consultation" label="Consulted" value="Consulted" inline onChange={this.handleChangeWriteReview.bind(this, "consultation")} />
                                                    <Label check htmlFor="consultation">
                                                        Consulted
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check inline>
                                                    <Input type="radio" id="consultation1" name="consultation" label="Consulted" value="Worked on a transaction" inline onChange={this.handleChangeWriteReview.bind(this, "consultation")} />
                                                    <Label check htmlFor="consultation1">
                                                        Worked on a transaction
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                            :
                                            <div>
                                                <FormGroup check inline>
                                                    <Input type="radio" id="consultation" name="consultation" label="Consulted" value="Consulted" inline onChange={this.handleChangeWriteReview.bind(this, "consultation")} />
                                                    <Label check htmlFor="consultation">
                                                        Consulted
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check inline>
                                                    <Input type="radio" id="consultation1" name="consultation" label="Consulted" value="Hired" inline onChange={this.handleChangeWriteReview.bind(this, "consultation")} />
                                                    <Label check htmlFor="consultation1">
                                                        Hired
                                                    </Label>
                                                </FormGroup>
                                            </div>}
                                            <span className="error">{this.state.errors["consultation"]}</span>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="anonymously">Post your review anonymously? <span style={{ color: 'red' }}>*</span></Label>
                                            <div>
                                                <FormGroup check inline>
                                                    <Input type="radio" id="anonymously" name="anonymously" value="Yes" inline onChange={this.handleChangeWriteReview.bind(this, "anonymously")} />
                                                    <Label check htmlFor="anonymously">
                                                        Yes
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check inline>
                                                    <Input type="radio" id="anonymously1" name="anonymously" value="No, use my first name" inline onChange={this.handleChangeWriteReview.bind(this, "anonymously")} />
                                                    <Label check htmlFor="anonymously1">
                                                        No, use my first name
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                            <span className="error">{this.state.errors["anonymously"]}</span>
                                        </FormGroup>
                                        {(this.state.anonymously === 'No, use my first name') &&
                                            <React.Fragment>
                                                <FormGroup>
                                                    <Label htmlFor="firstname">Name <span style={{ color: 'red' }}>*</span></Label>
                                                    <Input type="text" name="firstname" id="firstname" ref="firstname" onChange={this.handleChangeWriteReview.bind(this, "firstname")} value={this.state.fields["firstname"]} placeholder="John Doe" />
                                                    <span className="error">{this.state.errors["firstname"]}</span>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label htmlFor="email">Email address <span style={{ color: 'red' }}>*</span></Label>
                                                    <Input type="email" name="email" id="email" ref="email" onChange={this.handleChangeWriteReview.bind(this, "email")} value={this.state.fields["email"]} placeholder="john.doe@gmail.com" />
                                                    <span className="error">{this.state.errors["email"]}</span>
                                                </FormGroup>
                                            </React.Fragment>
                                        }
                                        <Button disabled={this.state.loading} type="submit" className="btn-bluedark">Submit</Button>
                                    </div>
                                </Form>
                            }
                            {this.state.starsratings !== '' &&
                                <React.Fragment>
                                    <h3 className="text-blue">Thanks, your review has been submitted.</h3>
                                    {/*<p>Each review is read and screened by a member of our team before it is approved to go live on our site or rejected. We'll notify you as soon as this happens, and this typically happens within 24 hours.</p>*/}
                                    <hr />
                                    <h5>Submitted review for {this.state.firstname}</h5>
                                    <p className="posted-review"><span className="me-3">{this.starRatings(this.state.starsratings)}</span> <span className="d-inline-block">Posted by {this.state.postedName} <span className="pl-2 pr-2">|</span> {moment(this.state.posteddate).format("MMMM DD, YYYY")}</span></p>
                                    <h6>{this.state.postedtitle}</h6>
                                    <div className="posted-comment" dangerouslySetInnerHTML={this.createMarkup(this.state.postedcomment)}></div>
                                    <p className="text-lighted">{this.state.consultationselected} this intermediary {this.state.consultationselected !== '' && <i className="sprite i-info" id={`Tooltip-consultation`}></i>}
                                        <UncontrolledTooltip placement="bottom" target={`Tooltip-consultation`} autohide={false} innerClassName="review-tooltip">{this.state.consultationselected === 'Hired' ? `${this.state.firstname} was hired as an intermediary for a specific engagement with this reviewer.` : `${this.state.firstname} consulted as an intermediary, but did not necesarily get hired, by this reviewer for a specific engagement.`}</UncontrolledTooltip>
                                    </p>
                                    <p>{this.state.areaslistselected}</p>
                                    <a href={'/profile/' + this.state.slugName} className="text-blue text-link">View all {this.state.firstname}’s reviews</a>
                                    <p className="text-link d-none">Would you like to review another M&A Advisor? <a className="text-blue" href={'/profile/' + this.state.slugName}>Click here</a></p>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default withRouter(WriteReview);
