import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../../storage/actions';


import ListItem from './ListItem';
import {FeaturedListingType} from '../../constants/Listing';

const FeaturedListing = ({list, gridView, setFeaturedTransactionClick}) => {
  if (Object.keys(list).length)
    return (
      
        <div className="search_fixed_width margin_center row gridview col-sm-12 col-md-10 col-lg-12">
          {
            list.gold.map((item, j) => <ListItem data={item.transaction}
                                                 key={`dataGold-${j}`}
                                                 j={'1'}
                                                 gridView={gridView === '3' ? '3' : '10'}
                                                 type={FeaturedListingType.GOLD}
                                                 onFollow={() => {setFeaturedTransactionClick(item.id)}}
            />)
          }
          {
            list.silver.map((item, j) => <ListItem data={item.transaction}
                                                   key={`dataSilver-${j}`}
                                                   j={'2'}
                                                   gridView={gridView === '3' ? '3' : '10'}
                                                   type={FeaturedListingType.GOLD}
                                                   onFollow={() => {setFeaturedTransactionClick(item.id)}}
            />)
          }
          {
            list.bronze.map((item, j) => <ListItem data={item.transaction}
                                                   key={`data-${j}`}
                                                   j={'3'}
                                                   gridView={gridView === '3' ? '3' : '10'}
                                                   type={FeaturedListingType.GOLD}
                                                   onFollow={() => {setFeaturedTransactionClick(item.id)}}
            />)
          }
        </div>
    );
  else return null;
};

FeaturedListing.propTypes = {
  list: PropTypes.object,
  gridView: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  setFeaturedTransactionClick: id => {dispatch(actions.setFeaturedTransactionClick(id))},
});

export default connect(null, mapDispatchToProps)(FeaturedListing);
