import React, {useEffect, useState} from 'react';
import api from '../../services/api';
import {Row, Col, Table} from 'reactstrap';
import {Link} from 'react-router-dom';

const IntegrationStatuses = ['Disabled', 'Enabled']

const UserIntegrations = () => {
    const [loader, setLoader] = useState(true);
    const [userIntegrations, setUserIntegrations] = useState([])
    const [integrationEvents, setIntegrationEvents] = useState([])


    const getUserIntegrations = () => {
        api.get('console/user_integrations', null, true).then(integrations => {
            setUserIntegrations(integrations);
        });
    }
    const getIntegrationEvents = () => {
        api.get('console/integration_events', null, true).then(events => {
            setIntegrationEvents(events);
            setLoader(false);
        });
    }

    useEffect(() => {
        getUserIntegrations()
        getIntegrationEvents()
    }, [])

    const renderIntegrationEvents = (integration) => {
        return integration.events.map(userEvent => {
            return integrationEvents.find(({event}) => {
                return event === userEvent
            })?.name
        }).join(', ')
    }


    return (
        <div>
            {
                loader && <div className="loader" style={{backgroundColor: 'rgb(245, 246, 247)', display: 'none'}} id="loader">
                    <div className="spiner"/>
                </div>
            }

            <Link to="/console">&larr; Back</Link>
            <br/><br/>
            <div className="h2">User Integrations</div>
            <Row>
                <Col sm="12">
                    <Table bordered responsive>
                        <thead style={{textAlign: 'center'}}>
                        <tr>
                            <th>#ID</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>User Link</th>
                            <th>Integration Name</th>
                            <th>Webhook Url</th>
                            <th>Events</th>
                            <th>Status</th>
                            <th>Count</th>
                        </tr>
                        </thead>
                        <tbody>
                        {userIntegrations.map(i =>
                            <tr style={{textAlign: 'center'}}>
                                <td>{i.id}</td>
                                <td>{i.userName}</td>
                                <td>{i.userEmail}</td>
                                <td>{i.userLink}</td>
                                <td>{i.name}</td>
                                <td>{i.url}</td>
                                <td>{renderIntegrationEvents(i)}</td>
                                <td style={{color: i.status ? 'green' : 'red'}}>{IntegrationStatuses[i.status]}</td>
                                <td>{i.fireCount}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    )
}

export default UserIntegrations

