import React from "react";
import {getImageUrl} from "../../../../util";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavLink,
    UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import {Link} from "react-router-dom";
import {useAuthContext} from "../../../../../contexts/auth";

const FavoriteDeal = ({deal, deleteListing, onAssignListing, getLastNote, unHideDeal}) => {
    const { isAdmin } = useAuthContext()
    const sliceText = (text, count = 15) => {
        return text && text.length >= count ? text.slice(0, count) + '...' : text;
    }

    return (<div className="business-card">
        <div className="business-card__image position-relative">
            <a href={deal.isAnonymous ? `/user/subscription` : `/business-for-sale/${deal.transaction_slug}/${deal.id}`}
               target="_blank" rel="noopener noreferrer">
                <img src={(deal.image !== null && deal.image !== '')
                    ? getImageUrl(deal.image, true)
                    : process.env.PUBLIC_URL + '/images/empty.jpg'
                } alt="" style={{}}/>

                <div className="business-card_price_button" style={{position: 'absolute', bottom: 0}}>
                    <div style={{borderRadius: 0}}
                         className="btn btn-primary w-100">Price: {(deal.hide_price || (!deal.price || deal.price === '0')) ? 'Not Specified' : deal.price}</div>
                </div>
            </a>
        </div>
        <div className="business-card__content business-card-list-item" style={{width: '100%'}}>
            {
                unHideDeal
                    ? <div className="business-card__actions">
                        <button id="unhideIconTooltip" onClick={() => unHideDeal(deal.id)}
                                className="btn btn-tag" style={{fontSize: 13, float: 'right', padding: '15px 15px', border: 'none'}}>

                            <i className="far fa-eye-slash" style={{ fontSize: 20 }} />
                        </button>

                        <UncontrolledTooltip placement="bottom" target={`unhideIconTooltip`}
                                             autohide={false}
                                             innerClassName="areas-tooltip">
                            Unhide
                        </UncontrolledTooltip>
                    </div>
                    : <div className="business-card__actions">


                        <UncontrolledDropdown>
                            <DropdownToggle tag={'span'} className={'btn'}>
                                <i className="fas fa-ellipsis-h" style={{fontSize: 24, color: '#212529'}}/>
                            </DropdownToggle>
                            <DropdownMenu className={'dropdown-listing'}>
                                <DropdownItem onClick={() => onAssignListing(deal)}>
                                    <i className="fas fa-folder-open"/> Assign
                                </DropdownItem>
                                <DropdownItem onClick={() => deleteListing(deal.id)}>
                                    <i className="fas fa-trash-alt"/> Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>


                    </div>
            }
            <h3 className="business-card__title">
                <a style={{color: '#373c41'}}
                   href={deal.isAnonymous ? `/user/subscription` : `/business-for-sale/${deal.transaction_slug}/${deal.id}`}
                   target="_blank" rel="noopener noreferrer">
                    {sliceText(deal.name, 35)}
                </a>
            </h3>

            <p className="d-flex" style={{fontSize: 16}}>

                {deal.completed === 2 && isAdmin && <span style={{ backgroundColor: 'transparent', border: '1px solid black', display: 'inline-flex', marginBottom: 5, marginRight: 10 }} class="badge text-dark">
                  Private Deal
              </span>}
                {deal.guru_id === 0 && isAdmin &&
                    <span style={{backgroundColor: 'transparent', border: '1px solid black', marginRight: 10}}
                          className="badge text-dark">
                                                        <i style={{color: 'gold'}} className="fas fa-star"/> Premium Deal
                                                    </span>}
                {deal.isConcierge &&
                    <span style={{backgroundColor: '#58ae3a', border: '1px solid black'}}
                          className="badge text-dark">
                                                        <i style={{color: 'gold'}} className="fa-solid fa-crown"/> Concierge Deal
                                                    </span>}
                {deal.seller_financing &&
                    <span style={{backgroundColor: 'transparent', border: '1px solid black'}}
                          className={`badge text-dark ${deal.guru_id === 0 ? 'ms-1' : 'me-1'}`}>
                                                        Seller Financing Available
                                                    </span>}
                {deal.keywords === 'FSBO' &&
                    <span style={{backgroundColor: 'transparent', border: '1px solid black'}}
                          className={`badge text-dark`}>
                        <i style={{color: '#3890da'}} className="far fa-gem"/> Proprietary Deal
                    </span>}
            </p>

            {deal.location_address && <p className="business-card__location d-flex">
                                                <span className="me-1">
                                                    <svg className="icon" viewBox="0 0 18 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                              d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z"
                                                              fill="currentColor"/>
                                                    </svg>
                                                </span>
                <span>{deal.location_address}</span>
            </p>}
            <p className="business-card__description" style={{whiteSpace: 'normal', position: 'relative'}}>

                <div style={{filter: deal.isAnonymous ? 'blur(4px)' : 'inherit'}}>
                    {sliceText(deal.description, 300)}
                </div>
                {
                    deal.isAnonymous &&
                    <div style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <p className="text-center">
                            <NavLink style={{fontSize: 15}} href="/user/subscription" className="btn btn-success">
                                <i className="fas fa-lock"/> Upgrade To View Description
                            </NavLink>
                        </p>
                    </div>
                }
            </p>

            {getLastNote(deal.id) && <p className="business-card__description chat-body__message-body"
                                        style={{
                                            whiteSpace: 'normal',
                                            backgroundColor: '#EAF4FC',
                                            padding: '10px 10px'
                                        }}>
                <Link className='no-link-style' to={'/user/favorites'}>
                    {getLastNote(deal.id)}
                </Link>
            </p>}

            <div className="row">
                <div className="col">
                    <p>
                        <span className="font-weight-bolder">Revenue: </span>
                        <p className="text-primary-blue">{deal.revenue}</p>
                    </p>
                </div>
                <div className="col">
                    <p>
                        <span className="font-weight-bolder">SDE: </span>
                        <p className="text-primary-blue">{deal.cashflow}</p>
                    </p>
                </div>
                <div className="col">
                    <p>
                        <span className="font-weight-bolder">EBITDA: </span>
                        <p className="text-primary-blue">{deal.ebitda}</p>
                    </p>
                </div>
                {
                    !deal.isAnonymous && <div className="col">
                        <a href={`/business-for-sale/${deal.transaction_slug}/${deal.id}`}
                           target="_blank" rel="noopener noreferrer"
                           className="btn btn-primary">View details</a>
                    </div>
                }

            </div>


        </div>
    </div>)
}

export default FavoriteDeal
