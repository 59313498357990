import React from 'react';
import {
    Col,
    FormGroup,
    Label,
    Row,
    Input,
} from 'reactstrap';
import api from '../../../services/api';
import './style.css';

export default class Qualification extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                
            },
        };
    }

    componentDidMount() {
        document.getElementById('loader').setAttribute("style", "display: block;");
        api.get('user/editprofile', null, true)
        .then(res => {
            this.setState({
                fields: res.buyer_qualification !== null ? JSON.parse(res.buyer_qualification) : {}
            });
            document.getElementById('loader').setAttribute("style", "display: none;");
        });
    }

    handleChangeCheckBox = (field, e) => {
        if (e.target !== '' && e.target !== undefined) {
            let fields = this.state.fields;
            fields[field] = e.target.value;
            this.setState({fields});
        }
    }

    handleSubmit = () => {
        document.getElementById('loader').setAttribute("style", "display: block;");
        api.post('user/buyer_qualification', { buyer_qualification: JSON.stringify(this.state.fields) }, true)
        .then(res => {
            document.getElementById('loader').setAttribute("style", "display: none;");
        });
    }

    saveTrigger = () => {
        this.handleSubmit();
    }

    

    render() {
        
        return (
            <div className="">
                <div className="loader bg-white" id="loader" style={{display: 'none'}}>
                    <div className="spiner"></div>
                </div>
                <Row>
                    <Col sm="12">
                        <FormGroup>
                            <Label></Label>

                            <Row>
                                <Col sm="8">
                                    <Label className="qlabel">Have you owned and run a business before</Label>
                                </Col>
                                <Col sm="4">
                                    <form>
                                        <Row>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }}  htmlFor="1">Yes</Label>
                                                <Input type="radio"
                                                    id="1"
                                                    value={'yes'}
                                                    checked={this.state.fields['Have you owned and run a business before'] === 'yes'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Have you owned and run a business before')}/>
                                            </Col>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }} htmlFor="2">No</Label>
                                                <Input type="radio"
                                                    id="2"
                                                    value={'no'}
                                                    checked={this.state.fields['Have you owned and run a business before'] === 'no'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Have you owned and run a business before')}/>
                                            </Col>
                                        </Row>
                                    </form>
                                </Col>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                                <Col sm="8">
                                    <Label className="qlabel">Do you have committed capital ready to deploy for an acquisition?</Label>
                                </Col>
                                <Col sm="4">
                                    <form>
                                        <Row>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }}  htmlFor="3">Yes</Label>
                                                <Input type="radio"
                                                    id="3"
                                                    value={'yes'}
                                                    checked={this.state.fields['Do you have committed capital ready to deploy for an acquisition?'] === 'yes'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Do you have committed capital ready to deploy for an acquisition?')}/>
                                            </Col>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }} htmlFor="4">No</Label>
                                                <Input type="radio"
                                                    id="4"
                                                    value={'no'}
                                                    checked={this.state.fields['Do you have committed capital ready to deploy for an acquisition?'] === 'no'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Do you have committed capital ready to deploy for an acquisition?')}/>
                                            </Col>
                                        </Row>
                                    </form>
                                </Col>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                                <Col sm="8">
                                    <Label className="qlabel">Are you currently employed?</Label>
                                </Col>
                                <Col sm="4">
                                    <form>
                                        <Row>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }}  htmlFor="5">Yes</Label>
                                                <Input type="radio"
                                                    id="5"
                                                    value={'yes'}
                                                    checked={this.state.fields['Are you currently employed?'] === 'yes'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you currently employed?')}/>
                                            </Col>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }} htmlFor="6">No</Label>
                                                <Input type="radio"
                                                    id="6"
                                                    value={'no'}
                                                    checked={this.state.fields['Are you currently employed?'] === 'no'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you currently employed?')}/>
                                            </Col>
                                        </Row>
                                    </form>
                                </Col>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                                <Col sm="8">
                                    <Label className="qlabel">Do you have a good credit score?</Label>
                                </Col>
                                <Col sm="4">
                                    <form>
                                        <Row>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }}  htmlFor="7">Yes</Label>
                                                <Input type="radio"
                                                    id="7"
                                                    value={'yes'}
                                                    checked={this.state.fields['Do you have a good credit score?'] === 'yes'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Do you have a good credit score?')}/>
                                            </Col>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }} htmlFor="8">No</Label>
                                                <Input type="radio"
                                                    id="8"
                                                    value={'no'}
                                                    checked={this.state.fields['Do you have a good credit score?'] === 'no'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Do you have a good credit score?')}/>
                                            </Col>
                                        </Row>
                                    </form>
                                </Col>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                                <Col sm="8">
                                    <Label className="qlabel">Are you interested in working in the business day-to-day?</Label>
                                </Col>
                                <Col sm="4">
                                    <form>
                                        <Row>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }}  htmlFor="9">Yes</Label>
                                                <Input type="radio"
                                                    id="9"
                                                    value={'yes'}
                                                    checked={this.state.fields['Are you interested in working in the business day-to-day?'] === 'yes'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you interested in working in the business day-to-day?')}/>
                                            </Col>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }} htmlFor="10">No</Label>
                                                <Input type="radio"
                                                    id="10"
                                                    value={'no'}
                                                    checked={this.state.fields['Are you interested in working in the business day-to-day?'] === 'no'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you interested in working in the business day-to-day?')}/>
                                            </Col>
                                        </Row>
                                    </form>
                                </Col>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                                <Col sm="8">
                                    <Label className="qlabel">Are you interested in a home-based business?</Label>
                                </Col>
                                <Col sm="4">
                                    <form>
                                        <Row>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }}  htmlFor="11">Yes</Label>
                                                <Input type="radio"
                                                    id="11"
                                                    value={'yes'}
                                                    checked={this.state.fields['Are you interested in a home-based business?'] === 'yes'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you interested in a home-based business?')}/>
                                            </Col>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }} htmlFor="12">No</Label>
                                                <Input type="radio"
                                                    id="12"
                                                    value={'no'}
                                                    checked={this.state.fields['Are you interested in a home-based business?'] === 'no'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you interested in a home-based business?')}/>
                                            </Col>
                                        </Row>
                                    </form>
                                </Col>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                                <Col sm="8">
                                    <Label className="qlabel">Are you interested in a relocatable business?</Label>
                                </Col>
                                <Col sm="4">
                                    <form>
                                        <Row>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }}  htmlFor="13">Yes</Label>
                                                <Input type="radio"
                                                    id="13"
                                                    value={'yes'}
                                                    checked={this.state.fields['Are you interested in a relocatable business?'] === 'yes'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you interested in a relocatable business?')}/>
                                            </Col>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }} htmlFor="14">No</Label>
                                                <Input type="radio"
                                                    id="14"
                                                    value={'no'}
                                                    checked={this.state.fields['Are you interested in a relocatable business?'] === 'no'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you interested in a relocatable business?')}/>
                                            </Col>
                                        </Row>
                                    </form>
                                </Col>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                                <Col sm="8">
                                    <Label className="qlabel">Are you interested in an online business such as a website, app, or SaaS opportunity?</Label>
                                </Col>
                                <Col sm="4">
                                    <form>
                                        <Row>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }}  htmlFor="15">Yes</Label>
                                                <Input type="radio"
                                                    id="15"
                                                    value={'yes'}
                                                    checked={this.state.fields['Are you interested in an online business such as a website, app, or SaaS opportunity?'] === 'yes'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you interested in an online business such as a website, app, or SaaS opportunity?')}/>
                                            </Col>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }} htmlFor="16">No</Label>
                                                <Input type="radio"
                                                    id="16"
                                                    value={'no'}
                                                    checked={this.state.fields['Are you interested in an online business such as a website, app, or SaaS opportunity?'] === 'no'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you interested in an online business such as a website, app, or SaaS opportunity?')}/>
                                            </Col>
                                        </Row>
                                    </form>
                                </Col>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                                <Col sm="8">
                                    <Label className="qlabel">Are you interested in buying an existing franchise resale?</Label>
                                </Col>
                                <Col sm="4">
                                    <form>
                                        <Row>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }}  htmlFor="17">Yes</Label>
                                                <Input type="radio"
                                                    id="17"
                                                    value={'yes'}
                                                    checked={this.state.fields['Are you interested in buying an existing franchise resale?'] === 'yes'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you interested in buying an existing franchise resale?')}/>
                                            </Col>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }} htmlFor="18">No</Label>
                                                <Input type="radio"
                                                    id="18"
                                                    value={'no'}
                                                    checked={this.state.fields['Are you interested in buying an existing franchise resale?'] === 'no'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you interested in buying an existing franchise resale?')}/>
                                            </Col>
                                        </Row>
                                    </form>
                                </Col>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                                <Col sm="8">
                                    <Label className="qlabel">Are you interested in buying a new franchise opportunity?</Label>
                                </Col>
                                <Col sm="4">
                                    <form>
                                        <Row>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }}  htmlFor="19">Yes</Label>
                                                <Input type="radio"
                                                    id="19"
                                                    value={'yes'}
                                                    checked={this.state.fields['Are you interested in buying a new franchise opportunity?'] === 'yes'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you interested in buying a new franchise opportunity?')}/>
                                            </Col>
                                            <Col sm="6">
                                                <Label style={{ width: '50%' }} htmlFor="20">No</Label>
                                                <Input type="radio"
                                                    id="20"
                                                    value={'no'}
                                                    checked={this.state.fields['Are you interested in buying a new franchise opportunity?'] === 'no'}
                                                    onChange={this.handleChangeCheckBox.bind(this, 'Are you interested in buying a new franchise opportunity?')}/>
                                            </Col>
                                        </Row>
                                    </form>
                                </Col>
                            </Row>
                            <div className="divider"></div>
                            <div style={{ paddingTop: '50px' }}></div>
                            
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="21">Please describe your professional background as it relates to buying a business. What about your experience makes you suited for this?</Label>
                                        <textarea className="textarea_test" id="21" 
                                                onChange={this.handleChangeCheckBox.bind(this, 'Please describe your professional background as it relates to buying a business. What about your experience makes you suited for this?')}
                                                value={this.state.fields["Please describe your professional background as it relates to buying a business. What about your experience makes you suited for this?"]} ></textarea>
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="22">How soon do you want to buy a business?</Label>
                                        <textarea className="textarea_test" id="22"
                                                onChange={this.handleChangeCheckBox.bind(this, 'How soon do you want to buy a business?')}
                                                value={this.state.fields["How soon do you want to buy a business?"]} ></textarea>
                                    </FormGroup>
                                </Col>
                                
                            </Row>
                            <Row>
                                <Col sm="12">
                                        <FormGroup>
                                            <Label htmlFor="23">What are your strengths as an operator that would make you a good fit for a particular business?</Label>
                                            <textarea className="textarea_test" id="23"
                                                    onChange={this.handleChangeCheckBox.bind(this, 'What are your strengths as an operator that would make a good fit for a particular business?')}
                                                    value={this.state.fields["What are your strengths as an operator that would make a good fit for a particular business?"]} ></textarea>
                                        </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="24">How long have you been looking for a business to acquire?</Label>
                                        <textarea className="textarea_test" id="24"
                                                onChange={this.handleChangeCheckBox.bind(this, 'How long have you been looking for a business to acquire?')}
                                                value={this.state.fields["How long have you been looking for a business to acquire?"]} ></textarea>
                                    </FormGroup>
                                </Col>
                                
                            </Row>
                            <Row>
                            <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="25">How much annual income do you need or want from the business?</Label>
                                        <textarea className="textarea_test" id="25"
                                                onChange={this.handleChangeCheckBox.bind(this, 'How much annual income do you need or want from the business?')}
                                                value={this.state.fields["How much annual income do you need or want from the business?"]} ></textarea>
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="26">How much cash are you willing to spend? (not including loans or financing)</Label>
                                        <textarea className="textarea_test" id="26"
                                                onChange={this.handleChangeCheckBox.bind(this, 'How much cash are you willing to put down at acquisition (not including loans or financing)?')}
                                                value={this.state.fields["How much cash are you willing to put down at acquisition (not including loans or financing)?"]} ></textarea>
                                    </FormGroup>
                                </Col>

                            </Row>

                            <Row>
                            
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="30">Anything else that you care to communicate to a broker or potential seller?</Label>
                                        <textarea className="textarea_test" id="30"
                                                onChange={this.handleChangeCheckBox.bind(this, 'Anything else that you care to communicate to a broker or potential seller?')}
                                                value={this.state.fields["Anything else that you care to communicate to a broker or potential seller?"]} ></textarea>
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="28">How do you plan to finance an acquisition? (SBA loan, bank loan, seller financing, private investors etc.)</Label>
                                        <textarea className="textarea_test" id="28"
                                                onChange={this.handleChangeCheckBox.bind(this, 'How do you plan to finance an acquisition? (SBA loan, bank loan, seller financing, private investors etc.)')}
                                                value={this.state.fields["How do you plan to finance an acquisition? (SBA loan, bank loan, seller financing, private investors etc.)"]} ></textarea>
                                    </FormGroup>
                                </Col>
                                </Row>

                            <Row>
                            
                                
                                
                            
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="27">What would you like your total investment to be?</Label>
                                        <textarea className="textarea_test" id="27"
                                                onChange={this.handleChangeCheckBox.bind(this, 'What would you like your total investment to be?')}
                                                value={this.state.fields["What would you like your total investment to be?"]} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        
                        </FormGroup>
                        {/* <p className="text-center pt-3 pb-4"><Button style={{ width: 200 }} onClick={this.handleSubmit} color="primary">Save Buyer Qualification Profile</Button></p> */}
                    </Col>
                </Row>
            </div>
        )
    }
}