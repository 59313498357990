import '../../../chat.css';
import React from 'react';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
// import Select from 'react-select';
import { Link } from 'react-router-dom';
import {getFirstAndLastName, getImageUrl, trackEvent} from '../../util';
// import _ from 'lodash';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {AuthContext} from "../../../contexts/auth";
import {USER_TYPE} from "../../../constants/userTypes";

const scrollableNodeRef = React.createRef();

export default class Messages extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            message: '',
            messages: [],
            listings: [],
            users: [],
            sortedUsers: [],
            selectedListing: null,
            unread: [],
            messagesModal: false,
            loading: true,
            loadingButton: false,
        };
    }

    static contextType = AuthContext

    componentDidMount() {
        this.fetchMessages();
    }

    fetchMessages(preventUserSelection = false) {
        let queryParams = '';
        if(this.state.selectedListing) {
            queryParams = '?transaction_id=' + this.state.selectedListing.value;
        }

        return fetch(process.env.REACT_APP_API_ROOT + 'messages/conversations' + queryParams, {
			method: 'get',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'x-access-token': localStorage.getItem('token'),
			},
		}).then(function (res) {
			if (res.status < 200 || res.status >= 300) {
				throw res;
			} else { return res.json() }
		}).then(response => {
            if(Object.keys(response.conversations).length === 0) {
                this.setState({ loading: false });
                return;
            } 

            const messages = [];
            Object.keys(response.conversations).forEach(k => {
                //if(response.conversations[k][0].transaction != null) {
                    response.conversations[k].forEach((j, i) => {
                        messages.push(response.conversations[k][i]);
                    });
                //}
            });
            const listings = Object.keys(response.conversations).filter(k => response.conversations[k][0].transaction != null);
            const users = this._groupBy(messages, 'thread_id');
            const unread = [];
            Object.keys(users).forEach(key => {
                if(users[key].length === 1) {
                  unread.push(key);
                }
            });
            

            const sortedUsers = Object.keys(users).sort((a, b) => users[b][0].id - users[a][0].id);

            if( ! this.state.selectedListing) {
                this.setState({
                    listings: listings.map(k => {
                        return {
                            label: `${response.conversations[k][0].transaction.name}, Revenue: ${response.conversations[k][0].transaction.revenue || 'n/a'}, Cash Flow: ${response.conversations[k][0].transaction.cashflow || 'n/a'}`,
                            value: response.conversations[k][0].transaction.id
                        }
                    }),
                })
            }
            
            this.setState(prevState => ({
                ...prevState,
                users,
                unread,
                sortedUsers,
                selectedUser: preventUserSelection ? prevState.selectedUser : sortedUsers[0],
            }), () => {
                this.loadConversation(this.state.selectedUser);
            });
		})
    }

    fetchMessagesByUser(user) {
        if(!user.id) {
            this.setState({ loading: false });
            return;
        }
        let queryParams = '';
        if(this.state.selectedListing) {
            queryParams = '?transaction_id=' + this.state.selectedListing.value;
        }
        fetch(process.env.REACT_APP_API_ROOT + 'messages/get_by_thread/' + user.id + queryParams, {
			method: 'get',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'x-access-token': localStorage.getItem('token'),
			},
		}).then(function (res) {
			if (res.status < 200 || res.status >= 300) {
				throw res;
			} else { return res.json() }
		}).then(response => {
            this.setState({
                messages: response,
                loading: false,
            }, () => {
                if(scrollableNodeRef) {
                    const { scrollHeight, clientHeight } = scrollableNodeRef.current;
                    const maxScrollTop = scrollHeight - clientHeight;
                    scrollableNodeRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
                }
            });
		})
    }

    _groupBy = (items, key) => items.reduce(
        (result, item) => ({
          ...result,
          [item[key]]: [
            ...(result[item[key]] || []),
            item,
          ],
        }), 
        {},
    );

    sendMessage = () => {
        if(!this.state.message) {
            return;
        }

        this.setState({ loadingButton: true });

        const conversation = this.state.messages[this.state.messages.length - 1];
        const guru = this.getUserObject(this.state.selectedUser);

        fetch(process.env.REACT_APP_API_ROOT + 'messages', {
			method: 'POST',
			body: JSON.stringify({
				message: this.state.message,
				guru_id: guru.id,
				transaction_id: conversation.transaction_id,
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'x-access-token': localStorage.getItem('token'),
			},
		}).then(function (res) {
			if (res.status < 200 || res.status >= 300) {
				throw res;
            } else { return res.json() } })
           .then(res => {
               if(res.success) {
                   trackEvent({ category: 'Messages', action: 'Message Send' });
                   this.fetchMessages(true).finally(() => {
                       this.setState({ loadingButton: false, message: '' });
                   });
               } else {
                   this.setState({ messagesModal: true, loadingButton: false, });
               }
		});
    }

    onChangeListing = (v) => {
        this.setState({ selectedListing: v }, () => {
            this.fetchMessages();
        });
    }

    getUserObject(userKey) {
        if( ! this.state.users[userKey]) {
            return {};
        }

        const user = this.state.users[userKey][0].from_user_id === this.props.user_id ? this.state.users[userKey][0].receiver : this.state.users[userKey][0].sender;
        return user ? user : {};
    }

    loadConversation(selectedUser, click = false) {
        this.setState({ selectedUser }, () => {
            if(click) {
                fetch(process.env.REACT_APP_API_ROOT + 'messages/conversations', {
                    method: 'get',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('token'),
                    },
                }).then(function (res) {
                    if (res.status < 200 || res.status >= 300) {
                        throw res;
                    } else { return res.json() }
                }).then(response => {
                    const messages = [];
                    Object.keys(response.conversations).forEach(k => {
                        response.conversations[k].forEach((j, i) => {
                            messages.push(response.conversations[k][i]);
                        });
                    });
                    const users = this._groupBy(messages, 'thread_id');
                    this.setState({
                        users,
                    });
                })
            }
            this.fetchMessagesByUser(this.getUserObject(selectedUser));
        });
    }

    cutUserName(name) {
        if( ! name) {
            return '';
        }
        const spit_username = name.split(' ');
        if(spit_username.length > 1) {
            return spit_username.slice(0, spit_username.length - 1) + ' ' + spit_username[spit_username.length - 1].slice(0,1) + '.'
        } else {
            return name;
        }
    }

    getLastMessage(arr) {
        const result = arr.sort((a, b) => b.id - a.id)[0];
        if(result.transaction) {
            return result;
        } else {
            return {
                ...result,
                transaction: {
                    name: ''
                }
            };    
        }
    }

    getBadge(userKey) {
        return Object.keys(this._groupBy(this.state.users[userKey], 'transaction_id')).length;
    }

    sliceText(text, l = 15) {
        return text.length >= l ? text.slice(0, l) + '...' : text;
    }

    urlify(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, '<a href="$1">$1</a>');
    }

    getAvatar(userKey) {
        const user = this.getUserObject(userKey);
        if(this.state.users[userKey] && this.state.users[userKey][0].transaction && this.state.users[userKey][0].transaction.keywords === 'FSBO' && this.props.user_id !== this.state.users[userKey][0].transaction.guru_id) {
            return '/images/thumbnail-small.gif';
        } else {
            return (user.profile_pic) ? (user.profile_pic.indexOf('base64') > -1) ? `${user.profile_pic}` : (user.profile_pic.indexOf('user_profile') > -1) ? getImageUrl(user.profile_pic) : getImageUrl(user.profile_pic) : '/images/thumbnail-small.gif'
        }
    }

    getInboxAvatar(object) {
        if(object.transaction && object.transaction.keywords === 'FSBO' && object.sender.id === object.transaction.guru_id && object.sender.id !== this.props.user_id) {
            return '/images/thumbnail-small.gif';
        } else {
            return (object.sender.profile_pic) ? (object.sender.profile_pic.indexOf('base64') > -1) ? `${object.sender.profile_pic}` : (object.sender.profile_pic.indexOf('user_profile') > -1) ? getImageUrl(object.sender.profile_pic) : getImageUrl(object.sender.profile_pic) : '/images/thumbnail-small.gif'
        }
    }

    getUnread(key) {
        const count = this.state.users[key].filter(u => u.read === false && u.to_user_id === this.props.user_id);
        return count.length > 0 ?
            <span style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 45, width: 27, height: 27, padding: '4px 11px', backgroundColor: '#4D8FD5', position: 'absolute', right: 0, top: 27, fontSize: 16, color: '#FFFFFF' }}>{count.length}</span> : '';
    }

    isFSBO(userKey) {
        if(userKey && this.state.users[userKey][0] && this.state.users[userKey][0].transaction && this.state.users[userKey][0].transaction.keywords === 'FSBO' && this.props.user_id !== this.state.users[userKey][0].transaction.guru_id) {
            return true;
        } else {
            return false;
        }
    }

    getInterestingIn () {
        const user = this.getUserObject(this.state.selectedUser);
        const { first } = getFirstAndLastName(user.name);
        const body = this.state.users[this.state.selectedUser][0] && this.state.users[this.state.selectedUser][0].transaction ? `${first}, - can we connect to chat about the ${this.state.users[this.state.selectedUser][0].transaction.name} deal you have available?\n`: '';
        // if(!user.email_visible) {
        //     return (null)
        // }
        const dealName = this.state.users[this.state.selectedUser][0] && this.state.users[this.state.selectedUser][0].transaction ? this.state.users[this.state.selectedUser][0].transaction.name : '';
        const userType = () => {
            switch(user.user_type_id) {
                case 1: return 'Business Buyer';
                case 2: return 'Business Seller';
                case 3: return 'M&A Advisor';
                case 4: return 'Service Provider';
                default: return '';
            }
        }
        const isFSBO = this.isFSBO(this.state.selectedUser);

        return (
            <>
            <Row>
                <Col sm="1">
                    {isFSBO ? <img alt="" width="60px" height="60px" src="/images/thumbnail-small.gif"
                                   className="img-fluid rounded-circle d-block m-auto" /> : <a href={`/profile/${user.id}`}><img alt="" width="60px" height="60px" src={this.getAvatar(this.state.selectedUser)}
                    onError={
                        (e) => { 
                        //@ts-ignore
                            if(!e.target.src.includes('images/thumbnail-small.gif')) {
                                e.target.src = `/images/thumbnail-small.gif`;
                            }
                        }
                    }
                    className="img-fluid rounded-circle d-block m-auto" /></a>}
                </Col>
                <Col sm="11">
                    <b>{isFSBO ? 'Seller' : user.name} {(user.linkedin_url && !isFSBO) ? <><a target="_blank" rel="noopener noreferrer" href={user.linkedin_url}><i className="fab fa-linkedin-in ms-1"/></a></> : null}</b>
                    <div style={{ fontSize: 14, color: 'gray' }}>{userType()}</div>
                    <div>{this.sliceText(dealName, 50)}</div>

                    <div className="d-none d-sm-flex" style={{ float: 'right', display: 'flex', position: 'absolute', right: 30, top: 0 }}>
                        {user.email_visible && !isFSBO && <a target="_blank" rel="noopener noreferrer" href={`mailto:${getNotificationEmail(user)}?subject=BizNexus Lead&body=${encodeURI(body)}`} className="chat-box me-2">
                            <i className="far fa-envelope" style={{ fontSize: 24 }}></i>
                            Direct email
                        </a>}
                        {!isFSBO && <a href={`/profile/${user.id}`} target="_blank" rel="noopener noreferrer" className="chat-box">

                            <i className="fa-solid fa-user-tie" style={{ fontSize: 24 }}></i>

                            More info
                        </a>}
                    </div>
                    {/*<p style={{ fontSize: 13, marginBottom: 5 }}>Email: <a target="_blank" rel="noopener noreferrer" className="no-link-style" style={{ textDecoration: 'underline' }} href={`mailto:${getNotificationEmail(user)}?subject=BizNexus Lead&body=${body}`}>{getNotificationEmail(user)}</a></p>*/}
                    {/*{(user.phone && !isFSBO) && <p style={{ fontSize: 13 }}>Phone: <a className="no-link-style" href={`tel:${user.phone}`} style={{ textDecoration: 'underline' }}>{user.phone}</a></p>}*/}

                </Col>
                
            </Row>
            <div className="divider"></div>
            </>
        )
    }

    render() {
        let temp_transaction_id = 0;
        let temp_sender_id = null;

        const getUserName = userKey => {
            if(this.state.users[userKey][0] && this.state.users[userKey][0].transaction && this.state.users[userKey][0].transaction.keywords === 'FSBO' && this.props.user_id !== this.state.users[userKey][0].transaction.guru_id) {
                return 'Seller';
            }
            return this.sliceText(this.cutUserName(this.getUserObject(userKey).name));
        }

        const isFSBO = this.isFSBO(this.state.selectedUser);

        return (
            <div className="">
              <div className="" style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <Row style={{ marginBottom: 150 }}>
                    {
                        !!this.state.sortedUsers.length &&
                        <Col sm="4" className="mt-2 border-right contact-list" style={{  }}>
                            <SimpleBar className="people-conversation">
                                {this.state.sortedUsers.map((userKey, i) => (
                                    <>
                                        <div onClick={() => this.loadConversation(userKey, true)} style={{ cursor: 'pointer', marginLeft: 0, marginRight: 0 }} className={ userKey === this.state.selectedUser ? 'user_selected row chat-row': 'user_not_selected row chat-row' } key={i}>
                                            <div className="col-sm-2" style={{ margin: 'auto', padding: '3px' }}>
                                                <img alt="" width="60px" height="60px" src={this.getAvatar(userKey)}
                                                     onError={
                                                         (e) => {
                                                             //@ts-ignore
                                                             if(!e.target.src.includes('images/thumbnail-small.gif')) {
                                                                 e.target.src = `/images/thumbnail-small.gif`;
                                                             }
                                                         }
                                                     }
                                                     className="img-fluid rounded-circle d-block m-auto" />
                                            </div>
                                            <div className="col-sm-10">
                                                <Card style={{ border: 'none', backgroundColor: 'transparent' }}>
                                                    <CardBody style={{ height: '80px', padding: '7px' }}>
                                                        <CardTitle style={{ fontSize: '14px' }}>
                                                            <div style={{ color: '#007bff' }}>{getUserName(userKey)} </div>
                                                            {/*{this.sliceText(this.getLastMessage(this.state.users[userKey]).transaction.name, 40)}*/}
                                                        </CardTitle>
                                                        <CardSubtitle style={{ fontSize: '13px', fontWeight: 400 }}>
                                                            <div style={{ maxWidth: 385 }}>{this.sliceText(this.getLastMessage(this.state.users[userKey]).body, 80)}</div>
                                                            {this.getUnread(userKey)}
                                                        </CardSubtitle>
                                                    </CardBody>
                                                </Card>
                                            </div>

                                        </div>
                                        <div style={{ width: '100%' }} className="break "><div className="break__content"></div></div>
                                    </>
                                ))}
                            </SimpleBar>

                        </Col>
                    }

                    <Col sm={!this.state.messages.length && !this.state.loading ? 12 : 8} className="position-relative">

                    {this.state.messages.length > 0 ? this.getInterestingIn() : null}
                        
                    {(!this.state.messages.length && !this.state.loading) && <div className="mesgs_empty text-center" style={{ zIndex: 99, maxWidth: 470 }}>
                        {this.context.user.user_type_id !== USER_TYPE.BROKER && <a href="/advanced-search" className="btn btn-outline-primary-blue mb-2">Search deals</a>}
                        <p style={{ fontSize: this.context.user.user_type_id === USER_TYPE.BROKER ? 14 : 20, color: '#7E7E7E' }}>
                            {
                                this.context.user.user_type_id === USER_TYPE.BROKER
                                    ? 'You have no messages yet. Reach out to some suggested buyers for one of your posted teasers or listings to get a conversation going. FYI, the majority of approved buyers on our platform will reach out to you directly outside of our platform, so make sure your contact info (phone, email, social) is up-to-date.'
                                    : 'Browse the BizNexus search to find a right match for you. Contact sellers, brokers and your chats will appear here.'
                            }

                        </p>
                    </div>}
                        <div className="" style={{ marginTop: '0px' }}>
                            <SimpleBar className="msg_history chat-conversation" scrollableNodeProps={{ ref: scrollableNodeRef }}>
			                {this.state.messages.map((m, i) => (
				                <div key={i}> {/*style={m.sender.id === temp_sender_id ? {position: 'relative', top: -30} : {}}*/}
					                {temp_transaction_id !== m.transaction_id && <div className="break txt--center txt--subtle push--top push_half--bottom">
						            <div style={{ backgroundColor: 'rgb(245, 246, 247)' }} className="break__content">{m.transaction_id === 0 ? 'No listing selected' : <Link target="_blank" to={`/business-for-sale/${m.transaction.transaction_slug}/${m.transaction.id}`}>{m.transaction.name}</Link>}</div></div>}
                                    {(temp_sender_id !== null && m.sender.id !== temp_sender_id) && <hr />}
                                    <div className="incoming_msg" style={{ display: 'flex' }}>

                                        <div style={(temp_sender_id === null || m.sender.id !== temp_sender_id || temp_transaction_id !== m.transaction_id) ? { width: 70 } : { width: 70 }}>
                                            {(temp_sender_id === null || m.sender.id !== temp_sender_id || temp_transaction_id !== m.transaction_id) && <a href={isFSBO ? `/user/messages` : `/profile/${m.sender.id}`}><img alt="" width="60px" height="60px" src={this.getInboxAvatar(m)}
                                                onError={
                                                    (e) => { 
                                                    //@ts-ignore
                                                        if(!e.target.src.includes('images/thumbnail-small.gif')) {
                                                            e.target.src = `/images/thumbnail-small.gif`;
                                                        }
                                                    }
                                                }
                                                className="img-fluid rounded-circle" /></a>}
                                            {(m.sender.id === temp_sender_id) && <div style={{ width: 60, maxHeight: 20 }}></div>}
                                        </div>

                                        <div className="received_msg">

                                            {(temp_sender_id === null || m.sender.id !== temp_sender_id) && <span style={{  }} className="time_date">
                                                <b>
                                                {(m.sender.user_type_id !== 3 && (m.transaction && m.transaction.guru_id === m.sender.id) && this.props.user_id !== m.sender.id) ? 'Seller' :
                                                    <Link style={{ color: 'black', fontSize: 14 }} target="_blank" to={`/profile/${m.sender.id}`}>{m.sender.name}</Link>
                                                }
                                                
                                                </b>
                                            </span>}

                                            <div className="time_date time_date_responsive" style={{ right: 0 }}>{moment(new Date(m.sent_on)).format('ddd MM/DD/YY, h:mm A')}</div>

                                            <div className="received_withd_msg">

                                                <p style={{  }} dangerouslySetInnerHTML={{__html : this.urlify(m.body)}}></p>

                                            </div>
                                        </div>
                                        <div style={{ display: 'none' }}>{temp_transaction_id = m.transaction_id}{temp_sender_id = m.sender.id}</div>
                                    </div>
				                </div>
			))}

                        <div style={{ float:"left", clear: "both" }}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>


            
                             </SimpleBar>
		                </div>

                        {this.state.messages.length > 0 && <div className="type_msg">
                            <div className="input_msg_write">
                                <Row style={{ marginRight: 0, marginLeft: 0 }}>
                                    <Col sm="9">
                                <textarea
                                    disabled={this.state.loadingButton}
                                    value={this.state.message}
                                    onChange={(e) => this.setState({ message: e.target.value })}
                                    className="form-control pl-2 my-0"
                                    rows={5}
                                    placeholder="Type a message">
                                </textarea>
                                    </Col>
                                    <Col sm="2" className="position-relative d-flex align-items-center justify-content-center">
                                        <Button disabled={this.state.loadingButton}  onClick={() => this.sendMessage()} type="submit" color="primary" className="btn btn-primary w-100"><i
                                            className="fa-regular fa-paper-plane me-1"></i>Send</Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>}
                        
                    </Col>

                </Row>
              </div>
              <Modal isOpen={this.state.messagesModal} toggle={() => this.setState({ messagesModal: !this.state.messagesModal })}>
                <ModalHeader toggle={() => this.setState({ messagesModal: !this.state.messagesModal })}>BizNexus</ModalHeader>
                <ModalBody>
                    Your daily messages limit is exceeded
                </ModalBody>
                <ModalFooter>
                <a href="/premium" className="btn btn-success">Become a Premium</a>
                </ModalFooter>
            </Modal>
             </div>
        )
    }


}

function getNotificationEmail(user) {
    if(user.notification_email) {
        return user.notification_email;
    } else {
        return user.email;
    }
}
