import React from "react";
import Carousel from "react-bootstrap/Carousel";
import ArrowIcon from "../icons/ArrowIcon";
import Profile from "../cards/profile";
import PromoListing from "./PromoListing";
import './sposored-advisors.scss';

const SponsoredAdvisors = ({ carouselItems }) => (
    <div className="d-md-block sponsored-advisors_carousel">
        <Carousel prevIcon={<span className="prev-control"><ArrowIcon/></span>}
                  nextIcon={<span className="next-control"><ArrowIcon/></span>}>
            {
                carouselItems.map((profile, i) => {
                    if (profile.isPromo) {
                        return (<Carousel.Item key="promo">
                            <PromoListing />
                        </Carousel.Item>)
                    }
                    return (<Carousel.Item key={profile.id}>
                        <Profile boosted boost={profile} user={profile.user}
                                 height={'100%'} aligment="horizontal"/>
                    </Carousel.Item>)
                })
            }
        </Carousel>
    </div>
)

export default SponsoredAdvisors
