import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './popular-searches.scss';

const primarySearchLinks = [
    {
        text: 'Amazon FBA',
        link: '/advanced-search/?name=FBA&page=1',
    },
    {
        text: 'Cyber Security',
        link: '/advanced-search/?name=cyber%20security&page=1',
    },
    {
        text: 'HVAC',
        link: '/advanced-search/?name=hvac&page=1',
    },
    {
        text: 'MSP',
        link: '/advanced-search/?name=msp,managed%20service%20provider,managed%20services%20provider&page=1',
    },
    {
        text: 'Manufacturing',
        link: '/advanced-search/?name=manufacturing&page=1',
    },
    {
        text: 'SaaS',
        link: '/advanced-search/?name=saas&page=1',
    },
];
const extendedSearchLinks = [
    {
        text: 'Car Wash',
        link: '/advanced-search/?name=car%20wash&page=1',
    },
    {
        text: 'Government',
        link: '/advanced-search/?name=government&page=1',
    },
    {
        text: 'Marine',
        link: '/advanced-search/?name=boat&page=1',
    },
    {
        text: 'Shopify',
        link: '/advanced-search/?name=shopify&page=1',
    },
    {
        text: 'Solar',
        link: '/advanced-search/?name=solar&page=1',
    },
    {
        text: 'Trucking',
        link: '/advanced-search/?name=trucking&page=1',
    },
    {
        text: 'Work From Home',
        link: '/advanced-search/?name=work%20from%20home&page=1',
    },
];

const PopularSearches = () => {
    const [showMore, setShowMore] = useState(false);

    return (<div className="popular-searches-left-bar">
        <h3 className="h3" style={{padding: '0px 18px 0', marginLeft: 0, marginRight: 0}}>
            Popular Searches
        </h3>
        <ul className="profile-card__nav list-unstyled"
            style={{padding: '0px 18px 0', marginLeft: 0, marginRight: 0}}>
            {
                primarySearchLinks.map(({link, text}) => (
                    <li key={link}>
                        <NavLink to={link} target="_blank"
                                 rel="noopener noreferrer" className="profile-card__nav-link">
                            <span className="text-nowrap">{text}</span>
                        </NavLink>
                    </li>
                ))
            }
            {
                showMore
                    ? extendedSearchLinks.map(({link, text}) => (
                        <li key={link}>
                            <NavLink to={link} target="_blank"
                                     rel="noopener noreferrer" className="profile-card__nav-link">
                                <span className="text-nowrap">{text}</span>
                            </NavLink>
                        </li>
                    ))
                    : null
            }
        </ul>
        <button
            type="button"
            className="btn btn-sm btn-outline-primary-blue show-more_btn"
            onClick={() => setShowMore(!showMore)}
        >
            {showMore ? 'Show Less' : 'Show More'}
        </button>
    </div>)
}

export default PopularSearches;
