export const SubscriptionLevels = {
    BASIC: 20,
    PREMIUM: 30,
    CONCIERGE: 100
};

export const SubscriptionLevelKeys = {
    20: 'BASIC',
    30: 'PREMIUM',
    100: 'CONCIERGE',
};
