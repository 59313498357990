import React, {useState} from "react";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";

const priceOptions = [{
    value: 500000,
    text: '500,000',
}, {
    value: 1000000,
    text: '1,000,000',
}, {
    value: 2500000,
    text: '2,500,000',
}, {
    value: 5000000,
    text: '5,000,000',
}, {
    value: 7500000,
    text: '7,500,000',
}, {
    value: 10000000,
    text: '10,000,000',
}, {
    value: 25000000,
    text: '25,000,000',
}];
const multipleOptions = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4];

const PriceFilter = ({
    title,
    handleChange,
    handleMinChange,
    handleMaxChange,
    filterNiceValue,
    clearMetrics,
    isOptionAvailable,
    valueMap,
    minValue,
    maxValue,
    minName,
    maxName,
    onCollapseClick,
    isMultiple
}) => {
    const [isMinDropdownOpen, setIsMinDropdownOpen] = useState(false);
    const [isMaxDropdownOpen, setIsMaxDropdownOpen] = useState(false);

    return (
        <div className="row align-items-center row-gutter-10">
            <div className="col-md-12 mb-2 ">
                <div className="advanced-filter-section">
                    <button style={{borderRadius: '0px'}}
                            onClick={onCollapseClick ? onCollapseClick : undefined}
                            className={`btn btn-filter w-100 text-nowrap w-100 justify-content-between`} type="button"
                            data-toggle="collapse" data-target={`#dropdown${minName}Range`} aria-expanded={false}
                            aria-controls="collapse-8">
                                    <span className={!valueMap.isValid && 'text-danger'}>
                                        {title}
                                    </span>
                        <span className="btn-icon">
                                        <span className="d-when-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span className="d-when-not-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
                                                  fill="currentColor"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
                                                  fill="currentColor"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                    </button>

                    <div id={`dropdown${minName}Range`} className={`collapse`} aria-labelledby={`dropdown${minName}Range`}>
                        <div className="py-2 px-3">
                            <div className="row row-gutter-15">
                                <div className="col">
                                    <Dropdown isOpen={isMinDropdownOpen} toggle={() => {}}>
                                        <DropdownToggle className="dropdown-number-toggle">
                                            <input type="text"
                                                   onBlur={() => {
                                                       if (isMinDropdownOpen) {
                                                           setTimeout(() => {
                                                               setIsMinDropdownOpen(false)
                                                           }, 500)
                                                       }
                                                   }}
                                                   onFocus={() => setIsMinDropdownOpen(true)}
                                                   autoComplete="off"
                                                   className="form-control form-control--filter"
                                                   onChange={(e) => {
                                                       handleChange(minName, e)
                                                   }}
                                                   value={minValue}
                                                   placeholder="Min" name={minName}/>
                                        </DropdownToggle>
                                        <DropdownMenu  className={`advanced-dropdown dropdown-menu ${isMinDropdownOpen ? 'show' : ''}`}>
                                            {
                                                isMultiple
                                                    ? multipleOptions.map((value) => (
                                                        <button
                                                            key={String(value)}
                                                            onClick={() => {
                                                                handleMinChange({target: {value: String(value)}})
                                                            }}
                                                            className="dropdown-item"
                                                            disabled={!isOptionAvailable(value, true)}
                                                        >
                                                            {value}
                                                        </button>
                                                    ))
                                                    : priceOptions.map(({ value, text }) => (
                                                        <button
                                                            key={text}
                                                            onClick={() => {
                                                                handleMinChange({target: {value: text}})
                                                            }}
                                                            className="dropdown-item"
                                                            disabled={!isOptionAvailable(value, true)}
                                                        >
                                                            ${text}
                                                        </button>
                                                    ))
                                            }
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                <div className="col">
                                    <Dropdown isOpen={isMaxDropdownOpen} toggle={() => {}}>
                                            <DropdownToggle className="dropdown-number-toggle">
                                                <input type="text"
                                                       onBlur={() => {
                                                           if (isMaxDropdownOpen) {
                                                               setTimeout(() => {
                                                                   setIsMaxDropdownOpen(false)
                                                               }, 500)
                                                           }
                                                       }}
                                                       onFocus={() => setIsMaxDropdownOpen(true)}
                                                       autoComplete="off"
                                                       className="form-control form-control--filter"
                                                       onChange={(e) => {
                                                           handleChange(maxName, e)
                                                       }}
                                                       value={maxValue}
                                                       placeholder="Max" name={maxName}/>
                                            </DropdownToggle>
                                            <DropdownMenu  className={`advanced-dropdown dropdown-menu ${isMaxDropdownOpen ? 'show' : ''}`}>
                                                {
                                                    isMultiple
                                                        ? multipleOptions.map((value) => (
                                                            <button
                                                                key={String(value)}
                                                                onClick={() => handleMaxChange({target: {value: String(value)}})}
                                                                className="dropdown-item"
                                                                disabled={!isOptionAvailable(value, false)}
                                                            >
                                                                {value}
                                                            </button>
                                                        ))
                                                        : priceOptions.map(({ value, text }) => (
                                                            <button
                                                                key={text}
                                                                onClick={() => handleMaxChange({target: {value: text}})}
                                                                className="dropdown-item"
                                                                disabled={!isOptionAvailable(value, false)}
                                                            >
                                                                ${text}
                                                            </button>
                                                        ))
                                                }
                                            </DropdownMenu>
                                </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(minValue || maxValue) &&
                        <div className="row align-items-center row-gutter-10 mt-1 ms-1">
                            <div className="col-auto mb-2">
                                <button onClick={(e) => e.preventDefault()}
                                        className={`btn btn-sm  btn-text-xsm ${!valueMap.isValid ? 'btn-danger' : 'btn-primary-blue'}`}
                                        style={{padding: '1.5px 11px'}}>
                                    <span
                                        className="me-2">{filterNiceValue(minValue, maxValue, isMultiple)}</span>
                                    <svg onClick={() => clearMetrics(minName, maxName)}
                                         className="icon" viewBox="0 0 14 13" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                              fill="currentColor"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                              fill="currentColor"/>
                                    </svg>
                                </button>
                            </div>
                        </div>}
                </div>

            </div>
        </div>
    )
}

export default PriceFilter
