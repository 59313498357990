import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import api from '../services/api';
import {getImageUrl} from './util';

class AdDisplayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ad: props.plan || {},
        };
    }

    componentDidMount() {
        if (!this.props.plan) {
            this.fetchAd();
        }
    }

    async fetchAd() {
        await api.get('ads/ad/' + this.props.planId + '?url=' + window.location.href, null, true)
            .then(res => {
                this.setState({ ad: res });
            });
    }

    render() {

        if( ! this.state.ad.id) {
            return (null)
        }

        if(this.state.ad.type === 'text') {
            return (
                <p>
                    <a style={{ fontSize: this.props.fontSize || 16 }} target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_ROOT + 'ads/click/' + this.state.ad.id}>
                        {this.state.ad.text}
                    </a>
                    <svg style={{ marginLeft: '5px', marginBottom: '5px' }} id={`AdTooltip${this.state.ad.id}`} className="icon text-secondary" viewBox="0 0 20 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                            fill="currentColor"></path>
                      <path
                          d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z"
                          fill="currentColor"></path>
                    </svg>
                    <UncontrolledTooltip placement="bottom" target={`AdTooltip${this.state.ad.id}`}
                                        autohide={false}
                                        innerClassName="areas-tooltip">
                        Targeted ads to engage with our niche community of business buyers, sellers, intermediaries & more. <a rel="noopener noreferrer" target="_blank" href="https://www.biznexus.com/advertise">Learn more now</a>
                    </UncontrolledTooltip>
                </p>
            )
        } else {
            return (
                <a target="_blank" style={{ maxWidth: 600, maxHeight: 200 }} rel="noopener noreferrer" href={process.env.REACT_APP_API_ROOT + 'ads/click/' + this.state.ad.id}>
                    <img alt="" style={{ boxShadow: '1px 1px 15px #c2c2c2', width: '100%', maxWidth: 600 }} src={getImageUrl(this.state.ad.image)} />
                    <svg style={{ marginLeft: '5px', marginBottom: '5px' }} id={`AdTooltip${this.state.ad.id}`} className="icon text-secondary" viewBox="0 0 20 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                            fill="currentColor"></path>
                      <path
                          d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z"
                          fill="currentColor"></path>
                    </svg>
                    <UncontrolledTooltip placement="bottom" target={`AdTooltip${this.state.ad.id}`}
                                        autohide={false}
                                        innerClassName="areas-tooltip">
                        Targeted ads to engage with our niche community of business buyers, sellers, intermediaries & more. <a rel="noopener noreferrer" target="_blank" href="https://www.biznexus.com/advertise">Learn more now</a>
                    </UncontrolledTooltip>
                </a>
            )
        }
    }
}


export default AdDisplayer;
