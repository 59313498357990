import {SubscriptionLevelKeys, SubscriptionLevels} from "../../../constants/subscriptionLevels";
import Select from "react-select";
import React, { useState } from "react";
import UserSubscriptionModal from "../UserSubscriptionModal/userSubscriptionModal";
import moment from "moment";

export default function SubscriptionSelect({ user, onChangeSubscription }) {
    const [isOpen, setIsOpen] = useState(false)

  const levels = Object.keys(SubscriptionLevels);
  const options = [{ label: 'No Subscription', value: 0 }].concat( levels.map(l => ({ label: l, value: SubscriptionLevels[l] })) )
  const [level, setLevel] = useState(options.find(({value}) => value === +(user.premium_level || 0)));

  return (
      <div className="">
        <div>Subscription: {level.label}</div>
          {
              level.label
                  ? <>
                      <div>Is Contract: {user.premium_obj?.is_contract ? 'Yes' : 'No'}</div>
                      <div>Due Date: {user.premium_obj?.due_date ? moment(user.premium_obj?.due_date).format("MMMM, D, YYYY") : 'None'}</div>
                  </>
                  : ''
          }
          <div>

              <button className="btn btn-primary btn-sm mt-2" onClick={() => setIsOpen(true)}>Update Subscription</button>
              {
                  isOpen && <UserSubscriptionModal user={user} isOpen={isOpen} onSubmit={onChangeSubscription} onClose={() => setIsOpen(false)} />
              }
          </div>
      </div>

  )
}
