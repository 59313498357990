import React from 'react'
import { getImageUrl } from '../util';
import { USER_TYPE_LABEL } from "../../constants/userTypes";

export default class Profile extends React.Component {

    sliceText(text, count = 15) {
        return text && text.length >= count ? text.slice(0, count) + '...' : text;
    }

    render() {
        const user = this.props.user;

        if(this.props.aligment === 'horizontal' && !this.props.boosted) {
            return (
                <div className="col-md-auto profile-home-horizontal" style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="professional-card" style={{ maxWidth: 186, minWidth: 186, height: this.props.height || 220 }}>
                        <a target="_blank" rel="noopener noreferrer" href={user.id ? `/profile/${user.id}` : user.website}>
                            <img 
                            onError={
                                (e) => { 
                                //@ts-ignore
                                if(!e.target.src.includes('images/thumbnail-small.gif')) {
                                    e.target.src = `/images/thumbnail-small.gif`;
                                }
                                }
                            }
                            src={(user.profile_pic) ? 
                            (user.profile_pic.indexOf('base64') > -1)
                                ? user.profile_pic
                                : (user.profile_pic.indexOf('user_profile') > -1)
                                    ? getImageUrl(user.profile_pic, true)
                                    : getImageUrl(user.profile_pic, true)
                                : process.env.PUBLIC_URL + '/images/thumbnail-small.gif'}
                            class="professional-card__image" alt="" />
                        </a>
                        <h3 class="professional-card__title"><a className="no-link-style" href={user.id ? `/profile/${user.id}` : user.website}>{user.name}</a></h3>
                        {user.location_served && <p class="professional-card__location d-flex">
                            <span class="me-1">
                                <svg class="icon" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z"
                                      fill="currentColor"></path>
                                </svg>
                            </span>
                            <span>{user.location_served}</span>
                        </p>
                        }
                        {(user.ptype && user.firm_name) && 
                            <span class="professional-card__location d-flex">{user.ptype} at {user.firm_name}</span>
                        }
                        {user.ind_string && user.ind_string.length ? <p class="professional-card__description">Industries: {user.ind_string.join(', ')}</p> : null}
                        <a target="_blank" rel="noopener noreferrer" style={{ width: '100%', bottom: 0 }} href={user.id ? `/profile/${user.id}` : user.website} class="btn professional-card__btn">View Profile</a>
                    </div>
                </div>
            )
        } else if(this.props.boosted) {
                return <div class="col-12 col-md-auto">
                    <a target="_blank" rel="noopener noreferrer" href={`/profile/${user.id}`} className="no-link-style">
                <div class="business-card" style={{ height: this.props.height || 220, marginTop: 10, padding: '10px 10px', paddingTop: 15 }}>
                    <div className="business-card__image" style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <img 
                        style={{ width: 100, height: 100, border: '3px solid #AFAFAF', borderRadius: 80 }}
                        onError={
                            (e) => { 
                            //@ts-ignore
                            if(!e.target.src.includes('images/thumbnail-small.gif')) {
                                e.target.src = `/images/thumbnail-small.gif`;
                            }
                            }
                        }
                        src={(user.profile_pic) ? 
                        (user.profile_pic.indexOf('base64') > -1) ? user.profile_pic : (user.profile_pic.indexOf('user_profile') > -1) ? getImageUrl(user.profile_pic, true) : getImageUrl(user.profile_pic, true) : process.env.PUBLIC_URL + '/images/thumbnail-small.gif'}  alt="" />
                        <span className="type-badge">{USER_TYPE_LABEL[user.user_type_id]}</span>
                    </div>
                    <div className="boosted-profile-home" style={{  }}>
                        <div className="boosted-profile_personal-info">
                            <h3 class="professional-card__title"><a className="no-link-style" href={`/profile/${user.id}`}>
                                {user.name} {(user.ptype && user.firm_name) &&
                                <>| {user.ptype} at {user.firm_name}</>
                            }
                            </a></h3>
                            {user.location_served && <p class="professional-card__location d-flex">
                            <span class="me-1">
                                <svg class="icon" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z" fill="currentColor"></path>
                                </svg>
                            </span>
                                <span>{user.location_served}</span>
                            </p>}
                            <p className="professional-card__description">{this.props.boost.description}</p>
                        </div>

                        <div className="boosted-profile_contact-button">
                            <button className="btn btn-primary-blue">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.664062 13.1667V1.50008C0.664062 1.26397 0.744063 1.06591 0.904063 0.905915C1.06351 0.74647 1.26128 0.666748 1.4974 0.666748H12.3307C12.5668 0.666748 12.7646 0.74647 12.9241 0.905915C13.0841 1.06591 13.1641 1.26397 13.1641 1.50008V9.00008C13.1641 9.23619 13.0841 9.43397 12.9241 9.59342C12.7646 9.75342 12.5668 9.83342 12.3307 9.83342H3.9974L0.664062 13.1667ZM4.83073 14.0001C4.59462 14.0001 4.39656 13.9201 4.23656 13.7601C4.07712 13.6006 3.9974 13.4029 3.9974 13.1667V11.5001H14.8307V4.00008H16.4974C16.7335 4.00008 16.9313 4.0798 17.0907 4.23925C17.2507 4.39925 17.3307 4.5973 17.3307 4.83342V17.3334L13.9974 14.0001H4.83073ZM11.4974 2.33341H2.33073V9.14592L3.3099 8.16675H11.4974V2.33341Z" fill="white"/>
                                </svg> Contact
                            </button>
                        </div>
                    </div>
                   
                </div>
                </a>
            </div>

        } else {    
            return (
                <div class="professional-card professional-card--horizontal">
                    <a target="_blank" rel="noopener noreferrer" href={`/profile/${user.id}`}>
                        <img onError={
                                (e) => { 
                                //@ts-ignore
                                if(!e.target.src.includes('images/thumbnail-small.gif')) {
                                    e.target.src = `/images/thumbnail-small.gif`;
                                }
                                }
                            }
                            src={(user.profile_pic) ? 
                            (user.profile_pic.indexOf('base64') > -1) ? user.profile_pic : (user.profile_pic.indexOf('user_profile') > -1) ? getImageUrl(user.profile_pic, true) : getImageUrl(user.profile_pic, true) : process.env.PUBLIC_URL + '/images/thumbnail-small.gif'} class="professional-card__image" alt="" />
                    </a>
                    <h3 class="professional-card__title">
                        <a className="no-link-style" href={`/profile/${user.id}`}>
                            {user.name}
                        </a>
                        
                    </h3>
                    <p class="professional-card__location d-flex">
                        <span class="me-1">
                        <svg class="icon" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z" fill="currentColor"></path>
                        </svg>
                        </span>
                        <span>{user.location_served}</span>
                    </p>
                    {user.ind_string && user.ind_string.length ? <p class="professional-card__description">Industries: {user.ind_string.join(', ')}</p> : null}
                    <a target="_blank" rel="noopener noreferrer" style={{ position: 'absolute', bottom: 10 }} href={`/profile/${user.id}`} class="btn professional-card__btn">View Profile</a>
                </div>
            )
        }
    }
}
