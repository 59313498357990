/* eslint-disable */
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Geosuggest from '@ubilabs/react-geosuggest';
import _ from 'lodash';
import api from "../services/api";
import UserPendingModal from "./UserPendingModal/UserPendingModal";
import {USER_TYPE} from "../constants/userTypes";
import {withRouter} from "./withRouter/withRouter";

class Setup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownminrevenue: false,
      dropdownmaxrevenue: false,

      dropdownmincashflow: false,
      dropdownmaxcashflow: false,

      fields: {
        searchbox: '',
        excluded: '',
      },
      locations: [],
      incKeywords: '',
      projectName: 'My first project',
      searchName: '',

      step: 1,
      error: '',
      loading: true,
      status: null,
      hasSearchPreferences: null,

      revenueSelected: [
        { checked: false, min: null, max: 1000000 },
        { checked: false, min: 1000000, max: 5000000 },
        { checked: false, min: 5000000, max: 25000000 },
        { checked: false, min: 25000000, max: null },
        { checked: false, min: null, max: null },
      ],
      profitSelected: [
        { checked: false, min: null, max: 250000 },
        { checked: false, min: 250000, max: 500000 },
        { checked: false, min: 500000, max: 2500000 },
        { checked: false, min: 2500000, max: null },
        { checked: false, min: null, max: null },
      ],
      brokerIndustry: [],
      categoryList: [],
    };
  }

  handleChangeSearch(field, e) {
    if (e.target !== '' && e.target !== undefined) {
      let fields = this.state.fields;
      fields[field] = e.target.value;

      if (e.target.name === 'minpricerange') {
        let minpricerange = e.target.value.replace(/[^0-9]/g, '');
        fields[field] = minpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (e.target.name === 'maxpricerange') {
        let maxpricerange = e.target.value.replace(/[^0-9]/g, '');
        fields[field] = maxpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (e.target.name === 'mincashflow') {
        let mincashflow = e.target.value.replace(/[^0-9]/g, '');
        fields[field] = mincashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (e.target.name === 'maxcashflow') {
        let maxcashflow = e.target.value.replace(/[^0-9]/g, '');
        fields[field] = maxcashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (e.target.name === 'minrevenue') {
        let minrevenue = e.target.value.replace(/[^0-9]/g, '');
        fields[field] = minrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (e.target.name === 'maxrevenue') {
        let maxrevenue = e.target.value.replace(/[^0-9]/g, '');
        fields[field] = maxrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (e.target.name === 'minebitda') {
        let minebitda = e.target.value.replace(/[^0-9]/g, '');
        fields[field] = minebitda.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (e.target.name === 'maxebitda') {
        let maxebitda = e.target.value.replace(/[^0-9]/g, '');
        fields[field] = maxebitda.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (e.target.name === 'asset_sale') {
        fields[field] = e.target.checked;
      }
      if (e.target.name === 'minebitda_multiple') {
        fields[field] = e.target.value.replace(/[^0-9.]/g, '');
      }
      if (e.target.name === 'maxebitda_multiple') {
        fields[field] = e.target.value.replace(/[^0-9.]/g, '');
      }
      if (e.target.name === 'minrevenue_multiple') {
        fields[field] = e.target.value.replace(/[^0-9.]/g, '');
      }
      if (e.target.name === 'maxrevenue_multiple') {
        fields[field] = e.target.value.replace(/[^0-9.]/g, '');
      }
      if (e.target.name === 'mincashflow_multiple') {
        fields[field] = e.target.value.replace(/[^0-9.]/g, '');
      }
      if (e.target.name === 'maxcashflow_multiple') {
        fields[field] = e.target.value.replace(/[^0-9.]/g, '');
      }
      this.setState({ fields });
    }
  }

  handleChangeMinRevenue = (e) => {
    let fields = this.state.fields;
    fields["minrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.setState({ fields, minrevenueselected: e.target.value });
  }
  handleChangeMaxRevenue = (e) => {
    let fields = this.state.fields;
    fields["maxrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.setState({ fields, maxrevenueselected: e.target.value });
  }

  handleChangeMincashflow = (e) => {
    let fields = this.state.fields;
    fields["mincashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.setState({ fields, mincashflowselected: e.target.value });
  }
  handleChangeMaxcashflow = (e) => {
    let fields = this.state.fields;
    fields["maxcashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.setState({ fields, maxcashflowselected: e.target.value });
  }

  filterNiceValue = (value1, value2, removeSign) => {
    const reg = /\B(?=(\d{3})+(?!\d))/g;
    if(value1 && value2) {
      return `${value1 !== 'Any Min' ? `${removeSign ? '' : '$'}` + value1.replace(reg, ","): value1} - ${value2 !== 'Any Max' ? `${removeSign ? '' : '$'}` + value2.replace(reg, ","): value2}`;
    } else {
      if(value1 && !value2) {
        return `${value1 !== 'Any Min' ? `Min ${removeSign ? '' : '$'}` + value1.replace(reg, ","): value1}`;
      } else if(!value1 && value2) {
        return `${value2 !== 'Any Max' ? `Max ${removeSign ? '' : '$'}` + value2.replace(reg, ","): value2}`;
      } else {
        return '';
      }
    }
  }

  clearMetrics = (a, b) => {
    let fields = this.state.fields;
    fields[a] = '';
    fields[b] = '';
    this.setState({ fields });
  }

  handleGeosuggestChange = (location) => {
    if (location !== undefined) {
      let addressComponent = location.gmaps.address_components;
      let locationType, city, state, country = '';
      switch(addressComponent[0].types[0]) {
        case 'locality': locationType = 'city'; break;
        case 'administrative_area_level_1': locationType = 'state'; break;
        case 'country': locationType = 'country'; break;
        default: locationType = 'point';
      }

      const duplicate = this.state.locations.find(l => l.location === location.label);

      if(duplicate) {
        this.refs.location.update('');
        return;
      }

      for (var j = 0; j < location.gmaps.address_components.length; j++) {
        if (addressComponent[j].types[0] === 'locality') {
          this.setState({ city: addressComponent[j].long_name });
          city = addressComponent[j].long_name;
        }
        if (addressComponent[j].types[0] === 'administrative_area_level_1') {
          this.setState({ state: addressComponent[j].short_name });
          state = addressComponent[j].short_name;
        }
        if (addressComponent[j].types[0] === 'country') {
          this.setState({ country: addressComponent[j].short_name });
          country = addressComponent[j].short_name;
        }
      }
      this.state.locations.push({
        city,
        state,
        country,
        locationType: locationType,
        Lat: location.location.lat,
        Lng: location.location.lng,
        location: location.label,
      })

      this.refs.location.update('');
      this.setState({ Lat: location.location.lat });
      this.setState({ Lng: location.location.lng });
      this.setState({ location: location.label, locationType });
    }
  }

  onRemoveLocation = loc => {
    const { locations } = this.state;
    const newLocations = _.reject(locations, l => l.location === loc.location);
    this.setState({ locations: newLocations });
  }

  componentDidMount() {
    // document.getElementById('loader_setup') && document.getElementById('loader_setup').setAttribute("style", "display: none;");
    window.scrollTo(0, 0);
    api.get('user/header', null, true)
        .then(res => {
          this.setState({ status: res.status });

          api.get('user/searchPreferences', null, true)
              .then(res => {
                if (res && res.searchPreferences) {
                  this.setState({ hasSearchPreferences: true })
                }
              })

          api.get('user/saved_search', null, true)
              .then(search => {
                if(search.savedSearchAll.length > 0) {
                  if(res.status === '1') {
                    this.props.history.push('/home')
                  } else {
                    this.setState({ step: 6 }, () => {
                      document.getElementById('loader_setup') && document.getElementById('loader_setup').setAttribute("style", "display: none;");
                    });
                  }
                } else {
                  if (res.user_type_id !== USER_TYPE.BROKER) this.setState({ hasSearchPreferences: false })
                  document.getElementById('loader_setup') && document.getElementById('loader_setup').setAttribute("style", "display: none;");
                }


              });
        });

    fetch(process.env.REACT_APP_API_ROOT + 'industry/legacy', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      },
    }).then(res => res.json())
        .then(
            (res) => {
              let categorylistarray = [];
              let temp = {};
              // eslint-disable-next-line
              res.map((categories, i) => {
                temp = {
                  "value": categories.value,
                  "label": categories.label,
                  "children": categories.children,
                };
                categorylistarray.push(temp);
              });
              this.setState({ categorylist: categorylistarray });
            }
        );

  }

  onRemoveKeyword = item => {
    const { fields } = this.state;
    const incKeywords = _.reject(fields.searchbox.split(','), k => k === item);
    // eslint-disable-next-line
    this.state.fields.searchbox = incKeywords.join(',');
    this.setState({ fields });
  }

  onFinish = () => {
    if(this.state.status === '0') {
      this.setState({ step: 6 });
    }
    const allIndustries = [];
    this.state.categorylist.forEach(ind => {
      allIndustries.push(ind);
      if(ind.children && ind.children.length) {
        ind.children.forEach(ci => allIndustries.push(ci));
      }
    });

    const list = this.state.brokerIndustry.map(bi => bi.children && bi.children.length ? [bi.value].concat(bi.children.map(cc => cc.value)) : bi.value).join(',')
    const categoryOption = allIndustries.filter(item => {
      const query = list.split(',');
      return query.includes(item.value.toString())
    })

    const revenue = this.state.revenueSelected.filter(r => r.checked);
    const anyRevenueChecked = revenue.find(r => r.min === null && r.max === null);

    const profit = this.state.profitSelected.filter(r => r.checked);
    const anyCashflowChecked = profit.find(r => r.min === null && r.max === null);

    const revenueMin = anyRevenueChecked ? null : Math.min(...revenue.map(item => item.min));
    const revenueMax = anyRevenueChecked ? null : Math.max(...revenue.map(item => item.max));

    const profitMin = anyCashflowChecked ? null : Math.min(...profit.map(item => item.min));
    const profitMax = anyCashflowChecked ? null : Math.max(...profit.map(item => item.max));

    const fields = {
      minrevenue: revenueMin ? revenueMin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
      maxrevenue: revenueMax ? revenueMax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',

      mincashflow: profitMin ? profitMin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
      maxcashflow: profitMax ? profitMax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
    }

    let categoryArrayData = [];
    // eslint-disable-next-line
    this.state.brokerIndustry.map((industrySelected, i) => {
      let categoryarray = {
        "id": industrySelected.value,
        "name": industrySelected.label,
        "path": ''
      };
      categoryArrayData.push(categoryarray);
    });

    api.post('user/searchPreferences', {
      options: {
        mincashflow: fields.mincashflow,
        maxcashflow: fields.maxcashflow,
        minrevenue: fields.minrevenue,
        maxrevenue: fields.maxrevenue,
      },
      area_served: this.state.locations.map(loc => ({...loc, latitude: loc.Lat, longitude: loc.Lng})), //
      industries_of_expertise: categoryArrayData,
    }, true)

    api.post('user/create_new_project', {name: this.state.projectName}, true)
        .then(async (res) => {
          api.post('user/saved_search', { project_id: res.id, name: this.state.searchName, data: JSON.stringify({
              fields: fields,
              locations: this.state.locations,
              categoryOption: categoryOption || [],
            })}, true).then(res => {
            if(this.state.status === '1') {
              this.props.history.push(`/advanced-search?search_id=${res.id}`);
            }
          })
        });
  }

  onAddBrokerIndustry = item => {
    const exist = _.find(this.state.brokerIndustry, b => b.value === item.value);

    if( ! exist) {
      this.setState({
        brokerIndustry: this.state.brokerIndustry.concat(item)
      });
    }
  }

  checkedIndustry = item => {
    const exist = _.find(this.state.brokerIndustry, b => b.value === item.value);

    if(exist) {
      return (
          <i className="fa-solid fa-check"></i>
      )
    }
  }

  onRemoveBrokerIndustry = item => {
    const brokerIndustry = _.reject(this.state.brokerIndustry, bi => bi.value === item.value);
    this.setState({
      brokerIndustry
    });
  }

  onChangeRevenue = (key) => {
    const revenueSelected = this.state.revenueSelected;
    this.state.revenueSelected[key].checked = !this.state.revenueSelected[key].checked;
    this.setState({
      revenueSelected
    });
  }

  onChangeProfit = (key) => {
    const profitSelected = this.state.profitSelected;
    this.state.profitSelected[key].checked = !this.state.profitSelected[key].checked;
    this.setState({
      profitSelected
    });
  }

  render() {
    if (this.state.hasSearchPreferences) {
      return <UserPendingModal/>
    }

    return (
        <>
          <Header pendingUser/>

          <div className="" style={{ minHeight: 1200, backgroundColor: 'rgba(60, 60, 60, 0.5)' }}>
            <img className="background"
                 src="/images/bg-signup.png" alt="background" />
            <div className="loader" id="loader_setup" style={{ display: "block" }}>
              <div className="spiner"></div>
            </div>
            <div className="loader" id="loader" style={{ display: "none" }}>
              <div className="spiner"></div>
            </div>

            <div className="row container margin_center" style={{ maxWidth: 800, margin: '0 auto' }}>

              <div className="col-xl-12 mt-4">
                <div className="profile-bx">
                  <div className="profile-form mt-4 mb-4 advanced-search-console">

                    {this.state.step !== 6 && <h1 className="h1 mt-1">

                      Let’s create your first target search{` `}
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                           viewBox="0 0 501.551 501.551" style={{enableBackground:'new 0 0 501.551 501.551', width: 25, height: 25}}>
                        <g>
                          <polygon style={{fill:'#FFD15C'}} points="238.237,274.808 170.318,460.8 155.69,501.551 82.547,501.551 108.669,428.408
		164.049,274.808 	"/>
                          <polygon style={{fill:'#FFD15C'}} points="419.004,501.551 345.861,501.551 331.233,460.8 263.314,274.808 337.502,274.808
		392.882,428.408 	"/>
                        </g>
                        <g>
                          <path style={{fill:'#F8B64C'}} d="M164.049,274.808h74.188L171.363,460.8c-21.943-7.314-42.841-18.808-62.694-32.392L164.049,274.808z
		"/>
                          <path style={{fill:'#F8B64C'}} d="M392.882,428.408c-18.808,13.584-39.706,24.033-62.694,32.392l-66.873-185.992h74.188
		L392.882,428.408z"/>
                        </g>
                        <circle style={{fill:'#FF7058'}} cx="250.776" cy="229.878" r="229.878"/>
                        <circle style={{fill:'#FFFFFF'}} cx="250.776" cy="229.878" r="180.767"/>
                        <circle style={{fill:'#FF7058'}} cx="250.776" cy="229.878" r="132.702"/>
                        <circle style={{fill:'#FFFFFF'}} cx="250.776" cy="229.878" r="84.637"/>
                        <circle style={{fill:'#FF7058'}} cx="250.776" cy="229.878" r="36.571"/>
                        <path style={{fill:'#40596B'}} d="M424.229,85.682l-167.184,149.42H256l-10.449-10.449v-1.045L412.735,73.143h1.045l9.404,10.449
	C424.229,84.637,424.229,84.637,424.229,85.682z"/>
                        <g>
                          <polygon style={{fill:'#334A5E'}} points="401.241,96.131 340.637,150.465 332.278,106.58 392.882,52.245 	"/>
                          <polygon style={{fill:'#334A5E'}} points="383.478,163.004 444.082,109.714 401.241,96.131 340.637,150.465 	"/>
                        </g>

                      </svg>

                    </h1>}
                    {this.state.step !== 6 && <span className="" style={{ color: '#6D7277', fontSize: 14 }}>What you’re looking for? We will send you the search results immediately after confirming your registration.</span>}
                    {this.state.step !== 6 && <div className="form-register d-none d-md-block">
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul role="tablist">
                          <li role="tab" aria-disabled="false" className={`${this.state.step === 1 && 'current done'} ${this.state.step > 1 && 'done'}`} aria-selected="false">
                            <div>
                              <span className="title">1</span>
                              <span className="label" style={{ right: -20 }}>Revenue</span>
                            </div>
                          </li>
                          <li role="tab" aria-disabled="false" className={`${this.state.step === 2 && 'current done'} ${this.state.step > 2 && 'done'}`} aria-selected="false">
                            <div>
                              <span className="title">2</span>
                              <span className="label" style={{ right: -7 }}>Profit</span>
                            </div>
                          </li>
                          <li role="tab" aria-disabled="false" className={`${this.state.step === 3 && 'current done'} ${this.state.step > 3 && 'done'}`} aria-selected="false">
                            <div>
                              <span className="title">3</span>
                              <span className="label" style={{ right: -20 }}>Location</span>
                            </div>
                          </li>
                          <li role="tab" aria-disabled="false" className={`${this.state.step === 4 && 'current done'} ${this.state.step > 4 && 'done'}`} aria-selected="false">
                            <div>
                              <span className="title">4</span>
                              <span className="label" style={{ right: -20 }}>Industry</span>
                            </div>
                          </li>
                          <li role="tab" aria-disabled="false" className={`${this.state.step === 5 && 'current done'} ${this.state.step > 5 && 'done'}`} aria-selected="false">
                            <div>
                              <span className="title">5</span>
                              <span className="label" style={{ right: -9 }}>Finish</span>
                            </div>
                          </li>


                        </ul>
                      </div>
                    </div>
                    </div>}

                    {this.state.step === 1 && <div>

                    <h2 className="h2 pt-4 pb-4">What's Your Target Revenue?</h2>

                      <div className="setup-content d-inline-flex text-center">
                        <div onClick={() => this.onChangeRevenue(0)} className={`setup-button ${this.state.revenueSelected[0].checked && 'setup-button-active'}`}>
                          <span className="setup-button-title">Side Hustle</span>
                          <span className="setup-button-subtitle">$0 - $1M</span>
                        </div>
                        <div onClick={() => this.onChangeRevenue(1)} className={`setup-button ${this.state.revenueSelected[1].checked && 'setup-button-active'}`}>
                          <span className="setup-button-title">Main Street</span>
                          <span className="setup-button-subtitle">$1M - $5M</span>
                        </div>
                        <div onClick={() => this.onChangeRevenue(2)} className={`setup-button ${this.state.revenueSelected[2].checked && 'setup-button-active'}`}>
                          <span className="setup-button-title">Low Market</span>
                          <span className="setup-button-subtitle">$5M - $25M</span>
                        </div>
                        <div onClick={() => this.onChangeRevenue(3)} className={`setup-button ${this.state.revenueSelected[3].checked && 'setup-button-active'}`}>
                          <span className="setup-button-title">Mid Market</span>
                          <span className="setup-button-subtitle">$25M+</span>
                        </div>
                        <div onClick={() => this.onChangeRevenue(4)} className={`setup-button ${this.state.revenueSelected[4].checked && 'setup-button-active'}`}>
                          <span className="setup-button-title">Any</span>
                          <span className="setup-button-subtitle">0 - ∞</span>
                        </div>
                      </div>


                    <span className="error mt-2">{this.state.error}</span>

                      <div className="text-center"><button onClick={() => {
                        const valid = _.find(this.state.revenueSelected, { checked: true });
                        if( valid ) {
                          this.setState({ step: 2, error: '' });
                        } else {
                          this.setState({ error: 'Please, choose your target revenue' });
                        }

                      }} className="btn btn-primary mt-4">Next →</button></div>


                  </div>}

                    {this.state.step === 2 && <div>

                      <h2 className="h2 pt-4 pb-4">What's Your Target Annual Profit?</h2>

                      <div className="setup-content d-inline-flex text-center">
                        <div onClick={() => this.onChangeProfit(0)} className={`setup-button ${this.state.profitSelected[0].checked && 'setup-button-active'}`}>
                          <span className="setup-button-title">Side Hustle</span>
                          <span className="setup-button-subtitle" style={{ fontSize: 13 }}>$0 - $250k</span>
                        </div>
                        <div onClick={() => this.onChangeProfit(1)} className={`setup-button ${this.state.profitSelected[1].checked && 'setup-button-active'}`}>
                          <span className="setup-button-title">Main Street</span>
                          <span className="setup-button-subtitle" style={{ fontSize: 13 }}>$250k - $500k</span>
                        </div>
                        <div onClick={() => this.onChangeProfit(2)} className={`setup-button ${this.state.profitSelected[2].checked && 'setup-button-active'}`}>
                          <span className="setup-button-title">Low Market</span>
                          <span className="setup-button-subtitle" style={{ fontSize: 13 }}>$500k - $2.5M</span>
                        </div>
                        <div onClick={() => this.onChangeProfit(3)} className={`setup-button ${this.state.profitSelected[3].checked && 'setup-button-active'}`}>
                          <span className="setup-button-title">Mid Market</span>
                          <span className="setup-button-subtitle" style={{ fontSize: 13 }}>$2.5M+</span>
                        </div>
                        <div onClick={() => this.onChangeProfit(4)} className={`setup-button ${this.state.profitSelected[4].checked && 'setup-button-active'}`}>
                          <span className="setup-button-title">Any</span>
                          <span className="setup-button-subtitle" style={{ fontSize: 13 }}>0 - ∞</span>
                        </div>
                      </div>


                      <span className="error mt-2">{this.state.error}</span>

                      <div className="text-center">
                        <button onClick={() => this.setState({ step: 1 })} className="btn btn-outline mt-4">← Back</button>
                        <button onClick={() => {
                        const valid = _.find(this.state.profitSelected, { checked: true });
                        if( valid ) {
                          this.setState({ step: 3, error: '' });
                        } else {
                          this.setState({ error: 'Please, choose your target annual profit' });
                        }

                      }} className="btn btn-primary mt-4">Next →</button></div>


                    </div>}

                    {this.state.step === 3 && <div>

                      <h2 className="h2 pt-4 pb-2">Choose your target area(s):</h2>

                      <div className="mt-1">


                        <Geosuggest
                            inputClassName={`form-control form-control--filter`}
                            id="by-location"
                            name="location"
                            ref="location"
                            placeholder="Search by location"
                            onSuggestSelect={this.handleGeosuggestChange}
                        />

                        {this.state.locations.length > 0 && <div class="row align-items-center row-gutter-10 mt-2 ms-1">
                          {this.state.locations.map(loc => <div class="col-auto mb-2" style={{ paddingLeft: 0 }}>
                            <button onClick={(e) => e.preventDefault()} class="btn btn-sm btn-primary-blue btn-text-xsm" style={{ padding: '1.5px 11px' }}>
                              <span class="me-2">{loc.location}</span>
                              <svg onClick={() => this.onRemoveLocation(loc)} class="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                              </svg>
                            </button>
                          </div>)}
                        </div>}

                      </div>

                      <span className="error mt-2">{this.state.error}</span>

                      <div className="text-center"><button onClick={() => this.setState({ step: 2 })} className="btn btn-outline mt-4">← Back</button>
                      <button onClick={() => {
                        this.setState({ step: 4, error: '' });
                      }} className="btn btn-primary mt-4">Next →</button></div>

                    </div>}

                    {this.state.step === 4 && <div>

                      <h2 className="h2 pt-4">Choose one or more target industries:</h2>
                      <span style={{ color: '#6D7277', fontSize: 14 }}>FYI you can save unlimited narrow searches once accepted.</span>

                      <div className="mt-1">

                        <div className="homepage advanced-search-console">
                          <div className="advanced-filter-section">
                            <div className="dropdown">
                              <button style={{borderRadius: '1px', border: '1px solid #ececec', justifyContent: 'flex-start', backgroundColor: 'white'}}
                                      className={`btn btn-filter w-100`} type="button" id="dropdownMenuIndustry"
                                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="me-1">Industry <span
                                    className="text-xsm">({this.state.brokerIndustry.length})</span></span>
                                <span className="text-xsm">
                                <svg className="icon" viewBox="0 0 20 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z"
                                          fill="currentColor"></path>
                                </svg>
                            </span>

                              </button>
                              <div className="dropdown-menu dropdown-menu--minw-260"
                                   aria-labelledby="dropdownMenuIndustry">
                                <div className="dropdown-content-padding pb-0">
                                  <div className="row row-gutter-10">
                                    {this.state.brokerIndustry.map(bi => <div className="col-auto mb-2">
                                      <button onClick={(e) => e.preventDefault()}
                                              className="btn btn-sm btn-primary-blue btn-text-xsm">
                                        <span className="me-2">{bi.label}</span>
                                        <svg onClick={() => this.onRemoveBrokerIndustry(bi)} className="icon"
                                             viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                fill="currentColor"></path>
                                          <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                fill="currentColor"></path>
                                        </svg>
                                      </button>
                                    </div>)}
                                  </div>
                                </div>
                                {this.state.brokerIndustry.length > 0 && <div className="dropdown-divider"></div>}
                                {this.state.categorylist.map(item => <>
                                  <a onClick={() => this.onAddBrokerIndustry(item)} className={`dropdown-item ${item.children.length > 0 ? 'submenu' : ''}`} href="javascript:void(0)">{this.checkedIndustry(item)} {item.label}</a>
                                  {item.children.length > 0 && <div className="dropdown-menu">
                                    {item.children.map(c =>
                                        <a className="dropdown-item" onClick={() => this.onAddBrokerIndustry(c)}
                                           href="javascript:void(0)">{this.checkedIndustry(c)} {c.label}</a>
                                    )}
                                  </div>}
                                </>)}

                              </div>
                            </div>


                          </div>
                        </div>

                        {this.state.brokerIndustry.length > 0 &&
                            <div className="row align-items-center row-gutter-10 ms-1">
                              {this.state.brokerIndustry.map(bi => <div className="col-auto mb-2 mt-2"
                                                                        style={{paddingLeft: 0}}>
                                <button onClick={(e) => e.preventDefault()}
                                        className="btn btn-sm btn-primary-blue btn-text-xsm"
                                        style={{padding: '1.5px 11px'}}>
                                  <span className="me-2">{bi.label}</span>
                                  <svg onClick={() => this.onRemoveBrokerIndustry(bi)} className="icon"
                                       viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                          fill="currentColor"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                          fill="currentColor"></path>
                                  </svg>
                                </button>
                              </div>)}
                            </div>}


                      </div>

                      <span className="error mt-2">{this.state.error}</span>

                      <div className="text-center"><button onClick={() => this.setState({ step: 3 })} className="btn btn-outline mt-4">← Back</button>
                        <button onClick={() => {
                          this.setState({ step: 5, error: '' });
                        }} className="btn btn-primary mt-4">Next →</button></div>

                    </div>}



                    {this.state.step === 5 && <div>

                      <h2 className="h2 pt-4">Give a name for this search</h2>

                      <div className="mt-4">
                        <span style={{ color: '#6D7277', fontSize: 14 }}>Search Name: </span>
                        <input
                            type="text"
                            maxLength={100}
                            placeholder={`E.g "Hotels in california"`}
                            className="form-control form-control--filter mb-2"
                            value={this.state.searchName}
                            onChange={(e) => {
                              this.setState({searchName: e.target.value});
                            }}
                        />

                        {/*<span style={{ color: '#6D7277', fontSize: 14 }}>Project Name: </span>*/}
                        {/*<input*/}
                        {/*    type="text"*/}
                        {/*    maxLength={100}*/}
                        {/*    placeholder={`Ex: “HVAC Add-On Targets”`}*/}
                        {/*    className="form-control form-control--filter mb-2"*/}
                        {/*    value={this.state.projectName}*/}
                        {/*    onChange={(e) => {*/}
                        {/*      this.setState({projectName: e.target.value});*/}
                        {/*    }}*/}
                        {/*/>*/}
                      </div>

                      <span className="error mt-2">{this.state.error}</span>
                      <div className="text-center">
                      <button onClick={() => this.setState({ step: 4 })} className="btn btn-outline mt-4">← Back</button>
                      <button onClick={() => {
                        if(this.state.searchName && this.state.projectName) {
                          this.setState({ error: '', });
                          this.onFinish();
                        } else {
                          this.setState({ error: 'All fields are required' });
                        }

                      }} className="btn btn-primary mt-4">Finish →</button></div>

                    </div>}

                    {this.state.step === 6 && <div>

                      <h2 className="h2">Great! </h2>

                      <p>For quality control, we need to review all applications before granting full access to our platform. We’ll send you your search results within 24 hours as soon as you’re approved.</p>

                      <p>While you wait, here’s complimentary access to our premium deal origination space in our community:</p>


                      <div className="text-center"><a href="https://www.linkedin.com/smart-links/AQERpb6fzIR7Fw" target="_blank" className="btn btn-primary mt-2">Check out BizNexus Community{`  `} <i
                          className="fa-sharp fa-solid fa-arrow-up-right-from-square"></i></a></div>
                    </div>}

                  </div>






                </div>
              </div>

            </div>

          </div>
          <Footer />
        </>
    );
  }
}


export default withRouter(Setup);
