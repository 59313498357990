import React from 'react';
import {Link} from 'react-router-dom';
import {LinkedIn, } from 'react-linkedin-login-oauth2';
import Select from 'react-select';
import { Form, FormGroup, Input, Label, Row, Col} from 'reactstrap';
import Geosuggest from '@ubilabs/react-geosuggest';
import {LINKEDIN_STATUSES} from '../constants/linkedin';
import {professionLabels, professionSubCategories, USER_TYPE} from '../constants/userTypes';
import {isTokenExpired} from './Token';
import InputMask from "react-input-mask";
import CompanyEmailValidator from 'company-email-validator';
import Header from './Header';
import Footer from './Footer';
import {trackEvent} from './util';
import FAQ from "./EditProfile/tabs/components/FAQ/FAQ";
import Testimonials from "./Signup/testimonials";
import {AuthContext} from "../contexts/auth";
import {withRouter} from "../containers/withRouter/withRouter";

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            professionlist: [],
            professionFullList: [],
            userTypeslist: [],
            selectedUserTypesOption: '',
            selectedOption: [],
            options: [],
            isLoading: false,
            multiple: false,
            areaServedValue: [],
            areaData: [],
            doSecondStep: false,
            locationCheckbox: false,
            referral: null,
            buyerReview: false,
            check: false,
            newsletter: true,
            loading: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitFinalStep = this.handleSubmitFinalStep.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem('token') && localStorage.getItem('loggedIn') === "true" && !isTokenExpired()) {
            this.props.history.push('/edit-profile');
        }
        fetch(process.env.REACT_APP_API_ROOT + 'user/userProfessionlist', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            },
        }).then(res => res.json())
            .then(
                (res) => {
                    let professionlistarray = [];
                    // eslint-disable-next-line
                    res.map((choice, i) => {
                        if (choice.status === '1') {
                            let temp = {
                                "value": choice.id,
                                "label": choice.profession_value
                            };
                            professionlistarray.push(temp);
                        }
                    });
                    this.setState({professionlist: professionlistarray, professionFullList: professionlistarray});
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        //error
                    });
                }
            )

        fetch(process.env.REACT_APP_API_ROOT + 'user/userTypeslist')
            .then(res => res.json())
            .then(res => {
                const userTypeslist = res.map(u => {
                    return {'label': professionLabels[u.id], 'value': u.id}
                });

                // const userTypeslist = [
                // 	{ 'label': professionLabels[1], 'value': 1 },
                // 	{ 'label': professionLabels[3], 'value': 3 },
                // 	{ 'label': professionLabels[4], 'value': 4 },
                // ]

                this.setState({userTypeslist});

            });

        const dataFromLogin = localStorage.getItem('registerFromLinkedin');
        if (dataFromLogin) {
            this.continueRegistrationWithLinkedin(JSON.parse(dataFromLogin))
        }

        const {match: {params}} = this.props;

        if (params.referral) {
            this.setState({
                referral: params.referral
            });
        }
    }

    validEmail = (email) => {
        let lastAtPos = email.lastIndexOf('@');
        let lastDotPos = email.lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
            return false;
        }

        return true;
    }


    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        this.setState({errors: errors});
        //firmname
        if (!fields["yourname"]) {
            formIsValid = false;
            errors["yourname"] = "Please enter your name.";
        }

        if (!fields["reason_to_join"]) {
            formIsValid = false;
            errors["reason_to_join"] = "This field is required";
        }

        if (!fields["linkedin_url"]) {
            formIsValid = false;
            errors["linkedin_url"] = "Please enter your LinkedIn URL";
        }
        if (fields["linkedin_url"] && !fields['linkedin_url'].match(/linkedin/)) {
            formIsValid = false;
            errors["linkedin_url"] = "Please enter your LinkedIn URL";
        }
        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please enter email.";
        }
        //

        if (!fields["newpass"]) {
            formIsValid = false;
            errors["newpass"] = "Please enter password.";
        }
        if (typeof fields["newpass"] !== "undefined") {
            if (!fields["newpass"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)) {
                formIsValid = false;
                errors["newpass"] = "Passwords must contain at least 6 characters, including uppercase, lowercase letters and numbers.";
            }
        }
        //confirmpass
        if (!fields["confirmpass"]) {
            formIsValid = false;
            errors["confirmpass"] = "Please confirm password";
        }
        if (typeof fields["confirmpass"] !== "undefined") {
            if (fields["confirmpass"] !== fields["newpass"]) {
                formIsValid = false;
                errors["confirmpass"] = "This field does not match password.";
            }
        }

        if (!fields["phonenumber"]) {
            formIsValid = false;
            errors["phonenumber"] = "Please enter phone number.";
        }

        // const user_type_id = this.state.selectedUserTypesOption.value;

        // if (!fields["firmname"] && user_type_id === 1) {
        // 	formIsValid = false;
        // 	errors["firmname"] = "Please select or type firm name.";
        // }

        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }

        if (this.state.selectedUserTypesOption.length === 0) {
            formIsValid = false;
            errors["typeslist"] = "Please select.";
        }

        if (!this.state.check) {
            formIsValid = false;
            errors["check"] = "Please select.";
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    handleValidationSecondForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        const user_type_id = this.state.selectedUserTypesOption.value;

        //firmname
        if (!fields["firmname"] && user_type_id === 1) {
            formIsValid = false;
            errors["firmname"] = "Please select or type firm name.";
        }
        //phonenumber
        // if (!fields["phonenumber"]) {
        // 	formIsValid = false;
        // 	errors["phonenumber"] = "Please enter phone number";
        // }
        // if (typeof fields["phonenumber"] !== "undefined" && fields["phonenumber"].length > 0) {
        // 	if (!fields["phonenumber"].match(/^(?=.*[0-9]).{14,14}$/)) {
        // 		formIsValid = false;
        // 		errors["phonenumber"] = "Please enter phone no (555) 555-5555 format.";
        // 	}
        // }
        //areaserved
        // if (this.state.areaData.length === 0 && !this.state.locationCheckbox) {
        // 	formIsValid = false;
        // 	errors["areaserved"] = "Please enter areas served";
        // }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;

        if(field === 'email' && this.validEmail(e.target.value) && ! CompanyEmailValidator.isCompanyEmail(e.target.value)) {
            this.setState({ warning_email: 'We strongly suggest you sign up with your business email to help us verify your identity and expedite the approval process.' });
        } else {
            this.setState({ warning_email: '' });
        }

        this.setState({fields});
    }

    handleChangeselect = (selectedOption) => {
        this.setState({selectedOption});
    }

    handleChangeSelectCompany = (selectedOption) => {
        this.setState({selectedOptionCompany: selectedOption});
    }

    handleChangeTypeslist = (selectedOption) => {
        const {professionFullList} = this.state;

        this.setState({
            professionlist: professionFullList.filter(p => professionSubCategories[selectedOption.value].includes(p.label)),
            selectedUserTypesOption: selectedOption,
            selectedOption: []
        });
    }

    handleAreaChange(i, event) {
        if (event.target !== '' && event.target !== undefined) {
            let areaServedValue = [...this.state.areaServedValue];
            areaServedValue[i] = event.target.value;
            this.setState({areaServedValue});
        }
    }

    addClick() {
        if (this.state.areaServedValue.length <= 2) {
            this.setState(prevState => ({areaServedValue: [...prevState.areaServedValue, '']}))
        }
    }

    handleGeosuggestChange = (areaserved) => {
        if (areaserved !== undefined) {
            let addressComponent = areaserved.gmaps.address_components;
            let city, stateData, stateShortData = '';
            for (var j = 0; j < areaserved.gmaps.address_components.length; j++) {
                if (addressComponent[j].types[0] === 'locality') {
                    city = addressComponent[j].long_name;
                }
                if (addressComponent[j].types[0] === 'administrative_area_level_1') {
                    stateData = addressComponent[j].long_name;
                    stateShortData = addressComponent[j].short_name;
                }
            }
            if (stateShortData === "") {
                stateShortData = areaserved.label;
            }
            if (stateData === undefined) {
                stateData = areaserved.label;
            }
            let dataArea = [];
            let temp = '';
            if (this.state.areaData.length === 0) {
                temp = {
                    "location": areaserved.label,
                    "latitude": areaserved.location.lat,
                    "longitude": areaserved.location.lng,
                    "city": city,
                    "state_short": stateShortData,
                    "state_long": stateData
                };
            } else {
                for (var k = 0; k < this.state.areaData.length; k++) {
                    dataArea.push(this.state.areaData[k]);
                }
                temp = {
                    "location": areaserved.label,
                    "latitude": areaserved.location.lat,
                    "longitude": areaserved.location.lng,
                    "city": city,
                    "state_short": stateShortData,
                    "state_long": stateData
                };
            }
            dataArea.push(temp);
            this.setState({areaData: dataArea});
        } else {
            this.setState({areaData: []});
        }
    }

    createUI() {
        return this.state.areaServedValue.map((el, i) =>
            <div key={i} className="mt-2">
                <Geosuggest
                    inputClassName="search-input form-control"
                    id="areaserved"
                    name="areaserved"
                    ref="areaserved"
                    placeholder="Enter your location"
                    onSuggestSelect={this.handleGeosuggestChange}
                    onClick={this.handleAreaChange.bind(this, i)}
                    value={el || ''}
                    types={['(cities)']}
                    country={['us', 'ca']}/>
            </div>
        )
    }

    handleSubmitFinalStep = () => {

        if (this.handleValidation()) {
            document.getElementById('loaderpopup').setAttribute("style", "display: block;");
            // this.setState({
            // 	buyerReview: true,
            // });
            let firmnameValues = "", firmnameid = "";

            if (this.state.options.length > 0) {
                // eslint-disable-next-line
                this.state.options.map((firmname, i) => {
                    if (firmname.customOption === true) {
                        firmnameValues = firmname.firm_name;
                    } else {
                        firmnameid = firmname.id;
                    }
                });
            } else {
                firmnameValues = this.state.fields["firmname"];
            }

            let linkedin_token = localStorage.getItem("linkedin_token");
            linkedin_token = linkedin_token !== 'undefined' ? linkedin_token : null
            let jsonsetsignupData = JSON.stringify({
                "name": localStorage.getItem("yourname"),
                "profile_pic": localStorage.getItem("avatar"),
                "linkedin_token": linkedin_token,
                "firm_name": firmnameValues,
                "email": localStorage.getItem("email"),
                "profession_type_id": localStorage.getItem("professionlist"),
                "user_type_id": localStorage.getItem("user_type_id"),
                "area_served": this.state.areaData,
                "creation_ip": localStorage.getItem("ip"),
                "phone": this.state.fields["phonenumber"],
                "referral": this.state.referral,
                "linkedin_url": this.state.fields["linkedin_url"],
                "newsletter": this.state.newsletter,
                reason_to_join: this.state.fields.reason_to_join,
                "company_id": firmnameid,
            });


            fetch(process.env.REACT_APP_API_ROOT + 'user/signup', {
                method: 'POST',
                body: jsonsetsignupData,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                },
            }).then((res) => {
                trackEvent({
                    category: 'Account',
                    action: 'Created an Account'
                });
                if (res.status < 200 || res.status >= 300) {
                    if (res.status === 401) {
                        this.props.history.push('/login');
                    } else {
                        return res.json().then(err => {
                            throw err;
                        });
                    }
                } else {
                    return res.json()
                }
            })
                .then(
                    res => {

                        if (res.data !== 'Email already exists!' && res.data !== 'Company already exists! Please select from the List') {
                            if (res.token) {

                                // this.setState({
                                // 	buyerReview: true,
                                // });

                                let jsonsetpassData = JSON.stringify({
                                    "id": res.id,
                                    "newpassword": this.state.fields.newpass,
                                    "verification_code": res.verification_code,
                                    user_type_id: localStorage.getItem("user_type_id"),
                                });

                                fetch(process.env.REACT_APP_API_ROOT + 'user/setUserpassword', {
                                    method: 'POST',
                                    body: jsonsetpassData,
                                    headers: {
                                        Accept: 'application/json',
                                        'Content-Type': 'application/json',
                                        "Access-Control-Allow-Origin": "*"
                                    },
                                }).then(() => {
                                    localStorage.setItem('token', res.token);
                                    localStorage.setItem('loggedIn', true);
                                    this.redirectToApp();
                                })


                            }
                        } else {
                            this.setState({loading: false});
                            let errors = {};
                            errors["Emailalreadyexists"] = res.data;
                            this.setState({errors: errors, buyerReview: false});
                        }
                    },
                    (error) => {
                        this.setState({
                            //error
                        });
                    }
                )
                .catch((err) => {
                    //console.log(err);
                })
        } else {
            //console.log("Form has errors.")
        }
    }

    handleSubmit = event => {
        event.preventDefault();

        if (this.handleValidation()) {
            let professionlistValues = "";
            let professionlistLabel = "";
            // eslint-disable-next-line
            this.state.selectedOption.map((choice, i) => {
                professionlistValues += choice.value + ",";
                professionlistLabel += choice.label + ",";
            });
            professionlistValues = professionlistValues.slice(0, -1);
            professionlistLabel = professionlistLabel.slice(0, -1);
            localStorage.setItem('professionlist', professionlistValues);
            localStorage.setItem('user_type_id', this.state.selectedUserTypesOption.value);
            localStorage.setItem('yourname', this.state.fields["yourname"]);
            localStorage.setItem('email', this.state.fields["email"]);
            localStorage.setItem('avatar', this.state.fields["avatar"]);
            localStorage.setItem('linkedin_token', this.state.fields["linkedin_token"]);
            if (process.env.REACT_APP_ROOT === 'https://app.biznexus.com/') {
                let jsonsethooksDatasignup = JSON.stringify({
                    "name": this.state.fields["yourname"],
                    "email": this.state.fields["email"],
                    "broker_type": professionlistLabel
                });
                //console.log(jsonsethooksDatasignup);
                fetch('https://hooks.zapier.com/hooks/catch/1629295/qalebk/', {
                    method: 'POST',
                    body: jsonsethooksDatasignup,
                    headers: {
                        'Accept': 'application/json'
                    },
                }).then(function (res) {
                    if (res.status < 200 || res.status >= 300) {
                        //console.log("Error 401");
                        //console.log(res);
                        //return res.json().then(err => {throw err;});
                        throw res;
                        //throw Error(.statusText);
                    } else {
                        return res.json()
                    }
                })
                    .then(
                        (res) => {
                            //console.log(res);
                        },
                        (error) => {
                            this.setState({
                                //error
                            });
                        }
                    )
            }
            this.setState({loading: true});
            fetch(process.env.REACT_APP_API_ROOT + 'user/validateEmail', {
                method: 'POST',
                body: JSON.stringify({email: this.state.fields["email"]}),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.status) {
                        let errors = {};
                        this.setState({loading: false});
                        errors["email"] = "This user already exists. Please log in or use our forgot password feature.";
                        this.setState({errors});
                    } else {
                        this.setState({errors: []});
                        this.handleSubmitFinalStep();
                    }
                });
        }
    }

    handleLinkedinSuccess = (code) => {
        document.getElementById('loaderpopup').setAttribute("style", "display: block;");
        const fetchBody = {
            client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
            client_secret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
            code: code,
            grant_type: 'authorization_code',
            redirect_uri: process.env.REACT_APP_LINKEDIN_REDIRECT_URL,
        };
        fetch(process.env.REACT_APP_API_ROOT + 'user/linkedinToken', {
            method: 'POST',
            body: JSON.stringify(fetchBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(function (res) {
            if (res.status < 200 || res.status >= 300) {
                throw res;
            } else {
                return res.json()
            }
        }).then(response => {
            document.getElementById('loaderpopup').setAttribute('style', 'display: none;');
            if (response.status === LINKEDIN_STATUSES.NOT_EXIST) {
                this.continueRegistrationWithLinkedin(response.userData);
            }
            if (response.status === LINKEDIN_STATUSES.COMPLETED) {
                this.logIn(response.userData);
            }
        }).catch(err => {
            //oops something went wrong modal?
            this.setState({
                errorMessage: 'Something went wrong, please try later.',
            })
        });

        this.setState({
            code: code,
            errorMessage: '',
        });
    };

    logIn = userData => {
        localStorage.setItem('token', userData.token);
        localStorage.setItem('email', userData.email);
        localStorage.setItem('franchiseStatus', userData.franchise_status);
        localStorage.setItem('loggedIn', true);
        this.redirectToApp();
    };

    continueRegistrationWithLinkedin = userData => {
        this.setState({
            fields: {
                yourname: userData.firstName + ' ' + userData.lastName,
                email: userData.email,
                avatar: userData.image,
                linkedin_token: userData.linkedin_token,
            }
        })
    };

    handleLinkedinFailure = (error) => {
        console.log('error');
        console.log(error);
        this.setState({
            code: '',
            errorMessage: error.errorMessage,
        });
    };

    static contextType = AuthContext

    redirectToApp = () => {
        const params = (new URL(document.location)).searchParams;

        if (params.has('redirect_url')) {
            window.location = params.get('redirect_url');
        }

        this.context.actions.fetchUser()

    }

    getRedirectUrlPath = () => {
        const params = (new URL(document.location)).searchParams;

        if (params.has('redirect_url')) {
            return params.get('redirect_url');
        } else {
            return null;
        }
    }

    render() {
        const {selectedOption} = this.state;
        const redirectUrlPath = this.getRedirectUrlPath();
        const loginLink = redirectUrlPath ? `/login?redirect_url=${redirectUrlPath}` : '/login';

        return (
            <>
                <Header/>

                <Row>
                    <Col sm="6" style={{ backgroundColor: '#F3F3F3' }}>
                        <section className="signup-section" style={{minHeight: 800}}>
                            <a href="/"><img src={process.env.PUBLIC_URL + '/images/biznexus-logo.png'}
                                             style={{width: 250}}
                                             alt="logo" className="d-block m-auto"/></a>
                            <div className="signup-bx">
                                <h1 className="h1"
                                    style={{width: 525, paddingTop: 10}}>Request Access
                                    Today</h1>
                                <div className=""
                                     style={{paddingTop: 10}}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="login-circle-help">1</div>
                                        <div className="ms-1">No charge to join or post a confidential deal opportunity</div>
                                    </div>
                                    <div className="pt-2" style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="login-circle-help">2</div>
                                        <div className="ms-1">Access thousands of exclusive acquisition opportunities</div>
                                    </div>
                                    <div className="pt-2" style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="login-circle-help">3</div>
                                        <div className="ms-1">Get matched with best-fit buyers, sellers & M&A advisors</div>
                                    </div>


                                    <div style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }} className="mt-1 pt-2">
                                        <div>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.25 14.75H10.75V9H9.25V14.75ZM10 7.3C10.2333 7.3 10.425 7.22067 10.575 7.062C10.725 6.904 10.8 6.70833 10.8 6.475C10.8 6.25833 10.725 6.07067 10.575 5.912C10.425 5.754 10.2333 5.675 10 5.675C9.76667 5.675 9.575 5.754 9.425 5.912C9.275 6.07067 9.2 6.25833 9.2 6.475C9.2 6.70833 9.275 6.904 9.425 7.062C9.575 7.22067 9.76667 7.3 10 7.3ZM10 19.5C8.68333 19.5 7.446 19.25 6.288 18.75C5.12933 18.25 4.125 17.575 3.275 16.725C2.425 15.875 1.75 14.8707 1.25 13.712C0.75 12.554 0.5 11.3167 0.5 10C0.5 8.68333 0.75 7.44567 1.25 6.287C1.75 5.129 2.425 4.125 3.275 3.275C4.125 2.425 5.12933 1.75 6.288 1.25C7.446 0.75 8.68333 0.5 10 0.5C11.3167 0.5 12.5543 0.75 13.713 1.25C14.871 1.75 15.875 2.425 16.725 3.275C17.575 4.125 18.25 5.129 18.75 6.287C19.25 7.44567 19.5 8.68333 19.5 10C19.5 11.3167 19.25 12.554 18.75 13.712C18.25 14.8707 17.575 15.875 16.725 16.725C15.875 17.575 14.871 18.25 13.713 18.75C12.5543 19.25 11.3167 19.5 10 19.5ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" fill="#1C1B1F"/>
                                            </svg>
                                        </div>
                                        <div className="ms-2">
                                            To maintain the quality of our
                                            marketplace,
                                            all applicants must be reviewed to access BizNexus
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="loader bg-white" id="loaderpopup" style={{display: 'none'}}><h3>Please
                                Wait...</h3>
                                <div className="spiner"></div>
                            </div>
                            <div className="signup-bx">

                                <Row className="pb-4">
                                    <div style={{ fontSize: 20 }} className="col-sm-6 btn font-weight-500 gray-help-text">
                                        <Link className="w-100" style={{ color: 'inherit' }} to={loginLink}>Log In</Link>
                                    </div>
                                    <div style={{ fontSize: 20 }} className="login-active-tab col-sm-6 btn font-weight-500">
                                        Sign Up
                                    </div>
                                </Row>

                                <LinkedIn onError={this.handleLinkedinFailure}
                                          onSuccess={this.handleLinkedinSuccess}
                                          clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
                                          redirectUri={process.env.REACT_APP_LINKEDIN_REDIRECT_URL}
                                          scope={'r_liteprofile,r_emailaddress'}
                                >
                                    {
                                        ({ linkedInLogin }) => (
                                        <button className="btn-linkedin" onClick={linkedInLogin}>
                                        <i className="fab fa-linkedin-in"/> Request Access Now
                                        </button>
                                        )
                                    }
                                </LinkedIn>


                                <div className="break txt--center txt--subtle push--top push_half--bottom">
                                    <h4 className="break__content">Or, Request Access With Email</h4>
                                </div>

                                <Form onSubmit={this.handleSubmit} id="signupform">
                                    <FormGroup>
                                        <Label for="yourname">Your name <span style={{color: 'red'}}>*</span></Label>
                                        <Input type="text" name="yourname" id="yourname" ref="yourname" autoFocus
                                               onChange={this.handleChange.bind(this, "yourname")}
                                               value={this.state.fields["yourname"]} placeholder="John Doe"/>
                                        <span className="error ">{this.state.errors["yourname"]}</span>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="newpass">Password <span style={{color: 'red'}}>*</span></Label>
                                        <Input type="password" name="newpass" id="newpass" ref="newpass" autoFocus
                                               onChange={this.handleChange.bind(this, "newpass")}
                                               value={this.state.fields["newpass"]}/>
                                        <span className="error ">{this.state.errors["newpass"]}</span>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="confirmpass">Confirm Password <span style={{color: 'red'}}>*</span></Label>
                                        <Input type="password" name="confirmpass" id="confirmpass" ref="confirmpass"
                                               onChange={this.handleChange.bind(this, "confirmpass")}
                                               value={this.state.fields["confirmpass"]}/>
                                        <span className="error ">{this.state.errors["confirmpass"]}</span>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="LinkedIn">LinkedIn Profile URL <span style={{color: 'red'}}>*</span></Label>
                                        <Input type="text" name="linkedin_url" id="LinkedIn" ref="LinkedIn"
                                               onChange={this.handleChange.bind(this, "linkedin_url")}
                                               value={this.state.fields["linkedin_url"]}
                                               placeholder="https://www.linkedin.com/in/john-doe/"/>
                                        <span className="error ">{this.state.errors["linkedin_url"]}</span>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="phonenumber">Phone Number <span
                                            style={{color: 'red'}}>*</span></Label>
                                        <InputMask mask="(999) 999-9999"
                                                   onChange={this.handleChange.bind(this, "phonenumber")}
                                                   value={this.state.fields["phonenumber"]}
                                        >
                                            {(inputProps) => <Input {...inputProps} type="text" name="phonenumber" id="phonenumber" ref="phonenumber"/>}
                                        </InputMask>
                                        <span className="error ">{this.state.errors["phonenumber"]}</span>
                                    </FormGroup>

                                    {/* <FormGroup>
							<Label for="firmname">Company Name {user_type_id === 1 ? '' : '(optional)'}</Label>
								<AsyncTypeahead
									{...this.state}
									allowNew
									emptyLabel={false}
									newSelectionPrefix="Don’t see your company, Add New! "
									labelKey="firm_name"
									onSearch={query => {
										this.setState({ isLoading: true });
										fetch(process.env.REACT_APP_API_ROOT + `company/search?firm_name=${query}`)
											.then(resp => resp.json())
											.then(json => this.setState({
												isLoading: false,
												options: json.list,
											}));
									}}
									options={this.state.options}
									// placeholder="Type company name..."
									ref="firmname"
									onChange={this.handleChangeSelectCompany}
									onBlur={this.handleChange.bind(this, "firmname")}
									value={options}
								/>
								<span className="error ">{this.state.errors["firmname"]}</span>
						</FormGroup> */}
                                    <FormGroup>
                                        <Label for="emailaddress">Business Email address <span
                                            style={{color: 'red'}}>*</span></Label>
                                        <Input type="email" name="email" ref="email" id="email"
                                               onChange={this.handleChange.bind(this, "email")}
                                               value={this.state.fields["email"]} placeholder="john.doe@gmail.com"/>
                                        <span className="error ">{this.state.errors["email"]}</span>
                                        <span className="signup_warning">{this.state.warning_email}</span>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="professionlist">What brings you to BizNexus? <span
                                            style={{color: 'red'}}>*</span></Label>
                                        <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                            name="professionlist"
                                            id="professionlist"
                                            isSearchable={false}
                                            isClearable={false}
                                            onBlur={this.handleChange.bind(this, "typeslist")}
                                            onChange={this.handleChangeTypeslist}
                                            options={this.state.userTypeslist}
                                            value={this.state.selectedUserTypesOption}
                                            ref="userTypeslist"
                                        />
                                        <span className="error ">{this.state.errors["typeslist"]}</span>
                                    </FormGroup>
                                    {this.state.professionlist.length !== 0 && this.state.selectedUserTypesOption.length !== 0 ?
                                        <FormGroup>
                                            <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control Select-multi' : 'Select-control Select-multi',
                                            }}
                                                name="professionlist"
                                                id="professionlist"
                                                isMulti
                                                className="custom-multiselect"
                                                isSearchable={false}
                                                isClearable={false}
                                                arrowRenderer={() => selectedOption.length === this.state.professionlist.length
                                                    ? ''
                                                    : <span className="custom-multiselect-dropdown-icon"/>}
                                                onBlur={this.handleChange.bind(this, "professionlist")}
                                                onChange={this.handleChangeselect}
                                                options={this.state.professionlist}
                                                value={selectedOption}
                                                placeholder="Select your role(s)"
                                                ref="professionlist"
                                            />
                                            <span className="error ">{this.state.errors["professionlist"]}</span>
                                        </FormGroup> : null}

                                    <FormGroup>
                                        <Label for="reason_to_join">Acquisition Target <span
                                            style={{color: 'red'}}>*</span></Label>
                                        <textarea id="reason_to_join"
                                                  onChange={this.handleChange.bind(this, "reason_to_join")}
                                                  value={this.state.fields["reason_to_join"]}
                                                  style={{fontSize: 14, backgroundColor: '#fafafa'}}
                                                  className="w-100 mb-3"
                                                  placeholder="Tell us about what you’re looking for so we can help set up your searches if/when you get access."
                                                  rows={4}></textarea>
                                        <span className="error ">{this.state.errors["reason_to_join"]}</span>
                                    </FormGroup>

                                    <div>

                                        <label htmlFor="check"><input checked={this.state.check}
                                                                      onClick={e => this.setState({check: e.target.checked})}
                                                                      id="check" type="checkbox"/> By clicking "Join
                                            Now" you agree
                                            to our <a rel="noopener noreferrer"
                                                      href="https://www.biznexus.com/terms-of-use/"
                                                      target="_blank">Terms of Use</a>.</label>
                                        <span className="error ">{this.state.errors["check"]}</span>
                                    </div>

                                    <div>
                                        <label htmlFor="newsletter"><input checked={this.state.newsletter}
                                                                           onClick={e => this.setState({newsletter: e.target.checked})}
                                                                           id="newsletter" type="checkbox"/> I'd like to
                                            subscribe
                                            to Biznexus Newsletter with personalized acquisition opportunities & events.</label>
                                    </div>
                                    <button disabled={this.state.loading} type="submit" className="btn-blue-login">Join
                                        Now</button>
                                </Form>

                                <div className="text-center">
                                    <p className="lgn-link-login gray-help-text">Already a BizNexus Member? <Link to={loginLink}> Log In</Link></p>
                                </div>


                            </div>

                        </section>
                    </Col>
                    <Col sm="6">
                        <div className="subscription-page" style={{ padding: 30, marginTop: 80 }}>
                            <h1>Thousands Of New Acquisition Opportunities Verified Daily</h1>
                            <div style={{ maxWidth: '66%' }}>

                                <p className="pt-4 font-weight-bold">Over 10,000 deals verified, removed, updated & added daily.</p>

                                <p>Reduce wasted time and source <span style={{ textDecoration: 'underline' }} className="font-weight-bold">LIVE</span> intermediated deal flow in one single location.</p>

                                <p>Intermediary listings, privately submitted deals & pre-CIM teasers aggregated from across the lower middle market.</p>

                                <p>Start scalling your deal origination today.</p>
                            </div>


                            <Testimonials />

                            <FAQ fullWidth userType={USER_TYPE.BUYER}/>
                        </div>
                    </Col>
                </Row>


                <Footer/>
            </>
        );
    }
}

export default withRouter(Signup);
