import React from "react";

const PromoListing = () => (
    <div className="col-12 col-md-auto">
        <a target="_blank" rel="noopener noreferrer"
           href={`/user/ads`}
           className="no-link-style">
            <div className="business-card promo-card" style={{
                height: '100%',
                marginTop: 10,
                padding: '10px 10px',
                paddingTop: 15,

            }}>
                <div style={{
                    border: '2px solid #4D8FD5',
                    borderStyle: 'dashed', display: 'inline-flex',
                    alignItems: 'center', padding: '10px 10px', width: 'calc(100% + 20px)', margin: '-10px'
                }}>
                    <div className="promo-img" style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                    }}>
                        <img
                            style={{
                                width: 100,
                                height: 100,
                                border: '3px solid #fff',
                                borderRadius: 80
                            }}
                            src={process.env.PUBLIC_URL + '/images/thumbnail-small.gif'}
                            alt=""/>
                    </div>
                    <div className="boosted-profile-home"
                         style={{width: '100%', left: 'auto'}}>
                        <h3 className="professional-card__title"><a
                            className="no-link-style"
                            href={`/user/ads`} style={{color: '#4D8FD5'}}>
                            You can be spotlighted here
                        </a></h3>


                        <p className="professional-card__description d-block d-sm-none">
                            • Best visibility for sellers and buyers<br/>
                            • Smart suggestions will promote you
                        </p>
                        <div className="d-none d-sm-block">
                            <a target="_blank" style={{width: 120}}
                               className="btn btn-sm btn-primary-blue" rel="noopener noreferrer"
                               href={`/user/ads`}>Learn How</a>
                        </div>
                    </div>
                    <div className="d-block d-sm-none">
                        <a target="_blank" style={{width: 120}}
                           className="btn btn-sm btn-primary-blue" rel="noopener noreferrer"
                           href={`/user/ads`}>Learn How</a>
                    </div>
                </div>

            </div>
        </a>
    </div>
)

export default PromoListing;
