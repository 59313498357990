import React from 'react';
import api from '../../services/api';
import { Table, Row, Col } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default class Boosts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            boosts: {
                listings: [],
                profiles: [],
            },
        };
    }

    componentDidMount() {
        this.init();
    }

    init = () => {
        api.get('console/boosts', null, true).then(boosts => {
            this.setState({ boosts });
        });
    }

    onChangeProfileVisibility = profile => {
        return api.post('console/profile_visibility', {
            id: profile.id,
            is_active: ! profile.is_active,
        }, true)
            .then(() => {
                this.init();
            });
    }

    onChangeDealVisibility = deal => {
        return api.post('console/deal_visibility', {
            id: deal.id,
            is_active: ! deal.is_active,
        }, true)
            .then(() => {
                this.init();
            });
    }

    

    render() {

        return (
            <div>
                <Link to="/console">&larr; Back</Link>
                        <br /><br />
                        <Row>
                        <Col sm="6">
                            <h2>Boosted Listings</h2>
                            <Table bordered hover responsive>
                                <thead style={{ textAlign: 'center' }}>
                                    <tr>
                                        <th>Name</th>
                                        <th>User</th>
                                        <th>User Email</th>
                                        <th>Created At</th>
                                        <th>Live</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.boosts.listings.map(s => 
                                        <tr style={{ textAlign: 'center' }}>
                                            <td>{s.transaction ? s.transaction.name : null}</td>
                                            <td>{s.transaction ? s.transaction.guru.name : null}</td>
                                            <td>{s.transaction ? s.transaction.guru.email : null}</td>
                                            <td>{moment(new Date(s.feature_start)).format('LLL')}</td>
                                            <td>
                                                <div className="custom-control custom-switch">
                                                    <input onChange={(e) => this.onChangeDealVisibility(s)} type="checkbox"
                                                           checked={s.is_active ? true : false}
                                                           className="custom-control-input" id={`customDealSwitch${s.id}`}/>
                                                    <label className="custom-control-label"
                                                           htmlFor={`customDealSwitch${s.id}`}></label>
                                                </div>
                                            </td>

                                        </tr>    
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                        <Col sm="6">
                            <h2>Boosted Profiles</h2>
                            <Table bordered hover responsive>
                                <thead style={{ textAlign: 'center' }}>
                                    <tr>
                                        <th>User</th>
                                        <th>Email</th>
                                        <th>Created At</th>
                                        <th>Live</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.boosts.profiles.map(s => 
                                        <tr style={{ textAlign: 'center' }}>
                                            <td>{s.user ? s.user.name : null}</td>
                                            <td>{s.user ? s.user.email : null}</td>
                                            <td>{moment(new Date(s.created_at)).format('LLL')}</td>
                                            <td>
                                                <div className="custom-control custom-switch">
                                                    <input onChange={(e) => this.onChangeProfileVisibility(s)} type="checkbox"
                                                           checked={s.is_active ? true : false}
                                                           className="custom-control-input" id={`customSwitch${s.id}`}/>
                                                    <label className="custom-control-label"
                                                           htmlFor={`customSwitch${s.id}`}></label>
                                                </div>
                                            </td>
                                        </tr>    
                                    )}
                                </tbody>
                            </Table>
                        </Col>    
                        </Row>
            </div>
        )
    }
}

// {moment(new Date(b.last_login_at)).format('LLL')}
