import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Col, Modal, ModalBody, ModalHeader, Row, Table} from "reactstrap";
import api from "../../../services/api";
import Pagination from "react-js-pagination";
import moment from "moment";
import './user-deal-statuses.scss'

export const UserDealStatusesMap = {
    '-10': {
        label: 'Lost Deal',
        color: '#dc3545',
        value: '-10',
    },
    0: {
        label: 'Not Interested',
        color: '#f5c976',
        value: '0',
    },
    10: {
        label: 'Interested',
        color: '#63a3f3',
        value: '10',
    },
    20: {
        label: 'LOI Submitted',
        color: '#636af3',
        value: '20',
    },
    30: {
        label: 'Under LOI',
        color: '#8c63f3',
        value: '30',
    },
    40: {
        label: 'Under Contract',
        color: '#b647ee',
        value: '40',
    },
    50: {
        label: 'Acquired',
        color: '#379403',
        value: '50',
    }
}
export const UserDealStatusesList = {
    LostDeal: -10,
    NotInterested: 0,
    Interested: 10,
    LOISubmited: 20,
    UnderLOI: 30,
    UnderContract: 40,
    Acquired: 50,
}

const MessagesModal = ({isOpen, dealName, closeModal, user, guru}) => {
    const [loading, setLoading] = useState(true)
    const [messages, setMessages] = useState([])
    useEffect(() => {
        if (isOpen) {
            getMessages()
        }
    }, [isOpen])
    const getMessages = async () => {
        try {
            setLoading(true)
            const res = await api.get(`console/user-messages?user_id=${user.id}&guru_id=${guru.id}`, null, true)
            setMessages(res.list)
            setLoading(false)
        } catch (e) {
            console.error(e);
            setLoading(false)
        }
    }
    return (
        <Modal size="md" isOpen={isOpen} toggle={closeModal}
               className="modal-dialog-centered deal-note-modal" keyboard={true} backdrop="static">
            <ModalHeader toggle={closeModal}>
                <strong className="deal-notes_deal-name">{dealName}</strong>
            </ModalHeader>
            <ModalBody>
                <div className="messages-container">

                    {
                        loading
                            ? <div className="user-listings-wrapper">
                                <div className="loader_listings_page" id="loaderlistings">
                                    <div className="spiner"/>
                                </div>
                            </div>
                            : messages.length
                                ? messages.map(message => (
                                    <div className={`message-wrapper ${Number(message.from_user_id) === Number(user.id) && 'response'}`}>
                                        <div className={`message`}>
                                <span className="message-sender">
                                    {Number(message.from_user_id) === Number(guru.id) ? guru.name : user.name}
                                </span>
                                            <span className="message-body">
                                    {message.body}
                                </span>
                                            <span className="date">
                                    {moment(message.sent_on).format("MMMM, D, YYYY hh:mm:ss")}
                                </span>
                                        </div>
                                    </div>
                                ))
                                : 'No messages found'
                    }
                </div>
            </ModalBody>
        </Modal>
    )
}

const UserDealStatuses = () => {
    const [loading, setLoading] = useState(true)
    const [dealStatuses, setDealStatuses] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [guru, setGuru] = useState(false)
    const [user, setUser] = useState(false)
    const [dealName, setDealName] = useState('')
    const recordsPerPage = 20;

    useEffect(() => {
        getDealStatuses()
    }, [currentPage])

    const closeModal = () => {
        setIsOpen(false)
        setGuru(null)
        setUser(null)
        setDealName('')
    }

    const getDealStatuses = async () => {
        try {
            setLoading(true)
            const res = await api.get(`console/deal-statuses?page=${currentPage}&limit=${recordsPerPage}`, null, true)
            setDealStatuses(res.list)
            console.log(res.list);
            setTotalCount(res.count)
            setLoading(false)
        } catch (e) {
            console.error(e);
            setLoading(false)
        }
    }
    const openMessages = (rowUser, rowGuru, rowDealName) => {
        setIsOpen(true)
        setUser(rowUser)
        setGuru(rowGuru)
        setDealName(rowDealName)
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }
    const sliceText = (text, count = 15) => {
        return text && text.length >= count ? text.slice(0, count) + '...' : text;
    }
    return (<>
        <Link to="/console">&larr; Back</Link>
        <br/><br/>
        <Row>
            <Col sm="12">
                <h2>User Deal Statuses</h2>

                {!!dealStatuses.length && <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={currentPage}
                    itemsCountPerPage={recordsPerPage}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                />}
                {loading ? <div className="user-listings-wrapper">
                    <div className="loader_listings_page" id="loaderlistings">
                        <div className="spiner"/>
                    </div>
                </div> : dealStatuses.length ? <Table bordered hover responsive>
                    <thead style={{textAlign: 'center'}}>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>User Name</th>
                        <th>Deal Broker</th>
                        <th>Integration</th>
                        <th>Messages</th>
                        <th>Unlock Date</th>
                        <th>Status Updated Date</th>
                        <th>Deleted On</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {dealStatuses.map((deal, idx) => (<tr>
                        <td>{idx + 1}</td>
                        <td>
                            {deal.deleted_on && <><span
                                style={{backgroundColor: '#dbdbdb', border: '1px solid black'}}
                                className="badge text-dark">No Longer Available
                                </span>{' '}</>}
                            <a href={`/business-for-sale/${deal.transaction_slug}/${deal.id}`}
                               target="_blank"
                               rel="noopener noreferrer"
                               style={{fontSize: '14px'}}
                            >
                                {sliceText(deal.name, 35)}
                            </a>
                        </td>
                        <td>
                            <a href={`/profile/${deal.user.id}`}
                               target="_blank"
                               rel="noopener noreferrer"
                               style={{fontSize: '14px'}}
                            >{deal.user.name}</a>
                        </td>
                        <td>{deal.guru ? <a href={`/profile/${deal.guru.id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{fontSize: '14px'}}
                        >{deal.guru.name}</a> : 'N/A'}</td>
                        <td>{deal.integration ? deal.integration.name : 'N/A'}</td>
                        <td>{deal.integration ? 'N/A' :
                            <button type="button" className={`deal-notes btn btn-primary-blue`}
                                    onClick={() => openMessages(deal.user, deal.guru, sliceText(deal.name, 35))}>
                                View
                            </button>}</td>
                        <td>{deal.unlockDate ? moment(deal.unlockDate).format("MMMM, D, YYYY") : 'N/A'}</td>
                        <td>{deal.updatedDate ? moment(deal.updatedDate).format("MMMM, D, YYYY") : 'N/A'}</td>
                        <td>{deal.deleted_on ? moment(deal.deleted_on).format("MMMM, D, YYYY") : 'N/A'}</td>
                        <td>{deal.status_id !== null ? <span
                            style={{
                                backgroundColor: UserDealStatusesMap[deal.status_id].color,
                                border: '1px solid black'
                            }}
                            className="badge text-dark">{UserDealStatusesMap[deal.status_id].label}
                                </span> : <span
                            style={{backgroundColor: '#dbdbdb', border: '1px solid black'}}
                            className="badge text-dark">Not Selected
                                </span>}</td>
                    </tr>))}
                    </tbody>
                </Table> : ''}


                {!!dealStatuses.length && <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={currentPage}
                    itemsCountPerPage={recordsPerPage}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                />}
            </Col>
        </Row>
        {
            isOpen &&
            <MessagesModal
                isOpen={isOpen}
                guru={guru}
                user={user}
                closeModal={closeModal}
                dealName={dealName}
            />
        }
    </>)
}

export default UserDealStatuses
