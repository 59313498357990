import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import {withRouter} from "../../containers/withRouter/withRouter";

class Unsubscribe extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            thanks: false,
        }
    }

    unsubscribe = () => {
        const params = (new URL(document.location)).searchParams;
                
        if(params.has('token')) {
            const email = params.get('token');

            api.post('newsletter/unsubscribe', {email}, false)
                .then(() => {
                    this.setState({ thanks: true });
                });

        }
    }


    render() {

        return (
            <div>
                <Header />

                <section className="profile-bg" style={{ backgroundColor: '#f5f6f7', minHeight: 800 }}>
                    <div className="profile-form mt-4 mb-3">

                        {!this.state.thanks && <div className="container text-center" style={{ width: '100%', maxWidth: '100%' }}>
                            <h1 className="text-center">BizNexus Newsletter</h1>
                            <br /><br />
                            <button className="btn btn-primary" onClick={() => this.unsubscribe()}>Unsubscribe</button>
                        </div>}

                        {this.state.thanks && <div className="container text-center" style={{ width: '100%', maxWidth: '100%' }}>
                            <h1 className="text-center">You have been successfully unsubscribed</h1>

                            <Link className="btn btn-primary" to={'/'}>Back to the App</Link>
                        </div>}
                    </div>
                </section>      
                <Footer />
            </div>
        )
    }
}

export default withRouter(Unsubscribe);
