import React from 'react';
import api from '../../services/api';
import {Table, Row, Col, Input} from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Select from "react-select";
import {SubscriptionLevels, SubscriptionLevelKeys} from "../../constants/subscriptionLevels";
import {Bars} from "react-loader-spinner";
import DataTable from "react-data-table-component";

export default class Premium extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            subscriptions: {
                active: [],
                inactive: [],
            },
            loading: true,
            email: '',
            level: SubscriptionLevels.PREMIUM,
        };
    }

    componentDidMount() {
        this.fetchSubscriptions()
    }

    fetchSubscriptions = () => {
        api.get('console/subscriptions', null, true).then(subscriptions => {
            this.setState({ subscriptions, loading: false });
        }).catch(err => {
            this.setState({
                loading: false
            })
        });
    }

    doSubmit = () => {
        api.post('console/add_premium', { email: this.state.email, level: this.state.level }, true).then(res => {
            if(res.success) {
                window.location.reload();
            } else {
                alert(res.message);
            }
        });
    }

    onRevoke = (id) => {
        api.post('console/revoke_premium', { id }, true).then(() => {
            window.location.reload();
        });
    }

    handleChange = option => {
        this.setState({ level: option.value });
    }

    onContractChange = (id) => (event) => {
        this.setState(prevState => ({
            ...prevState,
            subscriptions: {
                active: prevState.subscriptions.active.map(s => {
                    if (s.id === id) {
                        return {
                            ...s,
                            is_contract: event.target.checked
                        }
                    }
                    return s
                }),
                inactive: prevState.subscriptions.inactive.map(s => {
                    if (s.id === id) {
                        return {
                            ...s,
                            is_contract: event.target.checked
                        }
                    }
                    return s
                }),
            }
        }), () => {
            api.post('console/update_premium', { id, is_contract: event.target.checked }, true)
        });
    }

    render() {
        const levels = Object.keys(SubscriptionLevels);
        const options = levels.map(l => ({ label: l, value: SubscriptionLevels[l] }))
        const value = options.find(({value}) => +value === +this.state.level)
        const columns = [
            { name: 'Name', key: 'user.name', sortable: true, selector: row => row.user.name },
            { name: 'Email', key: 'user.email', sortable: true, selector: row => row.user.email, cell: s => <a href={`mailto:${s.user ? s.user.email : null}`}>{s.user ? s.user.email : null}</a> },
            { name: 'LinkedIn',  key: 'user.linkedin', sortable: true, selector: s => s.user.linkedin_url, cell: (s) => {
                    return <a target="_blank" rel="noopener noreferrer" href={`${s.user ? s.user.linkedin_url : null}`}>{s.user ? s.user.linkedin_url : null}</a>
                }},
            { name: 'Level', key: 'level', sortable: true, selector: row => row.level, cell: (s) => {
                    return SubscriptionLevelKeys[s.level]
                }},
            { name: 'Created At', key: 'created_at', sortable: true, selector: row => row.created_at, cell: (s) => {
                    return moment(new Date(s.created_at)).format('LLL')
                }},
            { name: 'Is Contract', key: 'is_contract', sortable: true, selector: row => row.is_contract, cell: (s) => {
                    return <Input type="checkbox"
                                  id="override"
                                  name="override"
                                  onChange={this.onContractChange(s.id)}
                                  className="custom-control-input"
                                  checked={s.is_contract}/>
                }},
            { name: 'Due Date', key: 'due_date', sortable: true, selector: row => row.due_date, cell: (s) => {
                    return s.due_date ? moment(new Date(s.due_date)).format('LLL') : 'None'
                }},
            { name: 'Action', key: 'action', cell: (s) => {
                    return <>
                        {(s.stripe_subscription_id === '-') && <button onClick={() => this.onRevoke(s.id)} className="btn btn-sm btn-danger">Revoke Access</button>}
                        {(s.stripe_subscription_id !== '-' && s.stripe_plan_id !== '-') && <a target="_blank" rel="noopener noreferrer" href={`https://dashboard.stripe.com/subscriptions/${s.stripe_subscription_id}`} className="btn btn-sm btn-info">View in Stripe</a>}
                    </>
                }},
        ];

        return (
            <div>
                <Link to="/console">&larr; Back</Link>
                        <br /><br />
                        <Row>
                        <Col sm="10">
                            <h2>Subscriptions</h2>
                            <DataTable
                                columns={columns}
                                progressPending={this.state.loading}
                                data={this.state.subscriptions.active}
                                persistTableHead
                                progressComponent={
                                    <div style={{ padding: 100 }} className="text-center d-flex justify-content-center">
                                        <Bars
                                            color="#3890da"
                                            height={100}
                                            width={100}
                                            visible={true}
                                            ariaLabel="bars-loading"
                                        />
                                    </div>
                                }
                            />
                        </Col>
                        <Col sm="2">
                            <div className="h2">Subscription</div>
                            <input placeholder="email..." className="form-control" type="text" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                            <Select 
                                classNamePrefix="Select"
                                classNames={{
                                    control: (state) =>
                                        state.isFocused ? 'Select-control' : 'Select-control',
                                }}
                                className="mt-2"
                                isSearchable={false}
                                isClearable={false}
                                onChange={this.handleChange}
                                options={options}
                                value={value}
                                style={{backgroundColor: 'white'}}
                            />
                            <button className="btn btn-primary w-100 mt-2" onClick={() => this.doSubmit()}>Submit</button>
                        </Col>    
                        </Row>
            </div>
        )
    }
}
