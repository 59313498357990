import React from 'react';
import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    FormGroup,
    Input, UncontrolledTooltip
} from 'reactstrap';
import Geosuggest from '@ubilabs/react-geosuggest';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import classnames from 'classnames';
import _ from 'lodash';
import api from '../services/api';
import stripeEnv from '../constants/stripe';
import {trackEvent} from './util';
import {withRouter} from "../containers/withRouter/withRouter";
import {AuthContext} from "../contexts/auth";
import {USER_TYPE} from "../constants/userTypes";
import {SubscriptionLevels} from "../constants/subscriptionLevels";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

class FeaturedModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            plans: [],
            description: props.description || '',
            industries: [],
            industry: [],
            locations: [],
            level: 1,
            techMedia: null,
            plan: { price: 0},
            errors: {},
            credits: 0,
        };
    }

    static contextType = AuthContext

    init = props => {
        api.get('promote/profile/plans', null, true)
            .then(plans => {
                this.setState({ plans });
            });
        api.get('promote/credits', null, true)
            .then(credits => {
                this.setState({ credits: credits.featured_profile });
            });
        api.get('industry/legacy', null, false)
            .then(industries => {
                this.setState({
                    industries: industries.filter(i => i.label !== 'Tech & Media'),
                    techMedia: _.find(industries, { label : 'Tech & Media' })
                });
            });
    }

    componentWillReceiveProps(nextProps) {
        this.init(nextProps);
    }

    componentDidMount() {
        this.init(this.props);
    }

    createCheckoutSession = (planId, industry, locations, description) => {
        return api.post('promote/profile/create-checkout-session', { planId, industry, locations, description }, true);
    }

    getCurrentPlan = () => {
        const { level } = this.state;
        switch(level) {
            case 1: return _.find(this.state.plans, { id : stripeEnv.profile_first });
            case 2: return _.find(this.state.plans, { id : stripeEnv.profile_second });
            case 3: return _.find(this.state.plans, { id : stripeEnv.profile_third });
            default: return null;
        }
    }

    getLimitations = () => {
        const locationLimit = { 1: 99, 2: 3, 3: 1 };

        return {
            location: locationLimit[this.state.level] || 0,
        }
    }

    checkout = () => {
        let errors = {};
        if(!this.state.level) {
            return null;
        }

        if((!this.state.industry.length || !this.state.locations.length) && this.state.level !== 1) {
            if(!this.state.industry.length) {
                errors.industry = 'Target Industies is required.'
            }
            if(!this.state.locations.length) {
                errors.location = 'Target Areas is required.'
            }
            if(!this.state.description.length) {
                errors.description = 'Description is required.'
            }
            this.setState({ errors });
            return null;
        }

        if(!this.state.description.length) {
            errors.description = 'Description is required.'
            this.setState({ errors });
            return null;
        }

        if(this.state.description.length > 200) {
            errors.description = 'Max 200 characters.'
            this.setState({ errors });
            return null;
        }

        const industryId = this.state.industry.join(',');
        const locations = JSON.stringify(this.state.locations);

        const currentPlan = this.getCurrentPlan();
        const planId = currentPlan.id;

        trackEvent({ category: 'Ads', action: 'Boosted Profile' });

        if(this.state.credits > 0 || this.context.user.user_type_id === USER_TYPE.BROKER && this.context.user.premium_level >= SubscriptionLevels.PREMIUM) {
            return api.post('promote/use_credits_profile', {
                planId, industry: industryId, locations, description: this.state.description
            }, true).then(() => {
                window.location.href = window.location.pathname + '?type=profile'
            })
        }

        this.createCheckoutSession(planId, industryId, locations, this.state.description)
            .then(res => {
                this.props.stripe
                    .redirectToCheckout({
                        sessionId: res.sessionId,
                    })
                    .then(r => {
                        if (r.error) {
                            console.log(r.error);
                        }
                    })
            });
    }

    handleGeosuggestChange = (location) => {

        if (location !== undefined) {
            let addressComponent = location.gmaps.address_components;
            if (addressComponent[0].types[0] === 'country') {
                this.refs.areaserved.update('');
                this.setState({ errors: { location: 'Country is not allowed' } });
                return;
            }
            this.refs.areaserved.update('');
            this.state.locations.push({
                lat: location.location.lat,
                lng: location.location.lng,
                label: location.label
            });
            this.setState({ locations: this.state.locations });
        }
    }

    removeClick = (e, i) => {
        this.state.locations.splice(e, 1);
        let dataAreaLists = [];
        dataAreaLists = this.state.locations;
        this.setState({locations: dataAreaLists});
    }

    addIndustry = (i) => {
        if(this.state.industry.includes(i.value)) {
            const industry = this.state.industry.filter(f => f !== i.value);
            this.setState({ industry });
            return;
        }
        const limit = { 3: 1, 2: 3, 1:99 };

        if(this.state.industry.length < limit[this.state.level]) {
            this.state.industry.push(i.value);
            this.setState({ industry: this.state.industry });
        }

    }

    onChangeLevel = level => {
        this.setState({
            level,
            industry: [],
            locations: [],
        });
    }

    onChangeDescription = e => {
        this.setState({ description: e.target.value });
    }

    render() {
        const currentPlan = this.getCurrentPlan();
        const limit = this.getLimitations();

        return (
            <div>
                <ModalHeader>
                    {
                        this.props.edit
                            ? 'Edit Sposored Profile'
                            : <>
                                Promote your profile
                                <p style={{ fontSize: '16px', fontWeight: 100 }}>Promoted profiles are billed monthly. Pay with a credit card. Cancel anytime.</p>
                            </>
                    }
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            {/*<p>Choose your package<span style={{ color: 'red' }}>*</span>: </p>*/}
                            {/*<div className="text-center">*/}
                            {/*    <img alt="-" src="/images/boosts/benefits_boosted_profile.png" />*/}
                            {/*</div>*/}
                            {/*<div style={{ display: 'inline-flex' }}>*/}
                            {/*    <a href="#/" className={classnames({ 'btn btn-success': this.state.level === 3, 'btn btn-secondary': this.state.level !== 3 })} onClick={() => this.onChangeLevel(3)} style={{ marginLeft: '10px' }}>*/}
                            {/*        Premium*/}
                            {/*        <i style={{ margin: '0px 3px 3px' }} id="bronze" className="sprite i-question"></i>*/}
                            {/*            <UncontrolledTooltip placement="bottom" target="bronze"*/}
                            {/*                                autohide={false}*/}
                            {/*                                innerClassName="areas-tooltip">*/}
                            {/*                Boost your profile to one location & one industry*/}
                            {/*            </UncontrolledTooltip>*/}
                            {/*    </a>*/}
                            {/*    */}
                            {/*    <a href="#/" className={classnames({ 'btn btn-success': this.state.level === 2, 'btn btn-secondary': this.state.level !== 2 })} onClick={() => this.onChangeLevel(2)} style={{ marginLeft: '10px' }}>*/}
                            {/*        Pro*/}
                            {/*        <i style={{ margin: '0px 3px 3px' }} id="silver" className="sprite i-question"></i>*/}
                            {/*            <UncontrolledTooltip placement="bottom" target="silver"*/}
                            {/*                                autohide={false}*/}
                            {/*                                innerClassName="areas-tooltip">*/}
                            {/*                Boost your profile to three locations & three industries*/}
                            {/*            </UncontrolledTooltip>*/}
                            {/*    </a>*/}
                            {/*    <a*/}
                            {/*        href="#/"*/}
                            {/*        className={`${this.state.level === 1 ? 'btn btn-success' : 'btn btn-secondary'}`}*/}
                            {/*        onClick={() => this.onChangeLevel(1)} */}
                            {/*        style={{ marginLeft: '10px' }}>*/}
                            {/*            Elite*/}
                            {/*            <i style={{ margin: '0px 3px 3px' }} id="gold" className="sprite i-question"></i>*/}
                            {/*            <UncontrolledTooltip placement="bottom" target="gold"*/}
                            {/*                                autohide={false}*/}
                            {/*                                innerClassName="areas-tooltip">*/}
                            {/*                Boost your profile across ALL locations & ALL industries*/}
                            {/*            </UncontrolledTooltip>*/}
                            {/*    </a>*/}
                            {/*    */}
                            {/*    */}
                            {/*</div>*/}

                            <div style={{ marginTop: '15px' }}>
                                <p>Description (max 200 characters) {this.state.description ? `${this.state.description.length}/200` : null}: <span style={{ color: 'red' }}>*</span>

                                <i style={{ margin: '0px 3px 3px' }} id="profileDesc" className="sprite i-question"></i>
                                        <UncontrolledTooltip placement="bottom" target="profileDesc"
                                                            autohide={false}
                                                            innerClassName="areas-tooltip">
                                            Describe the value you offer to our users in one, concise intro statement. The goal is to get the user to click through to your profile & learn more about you.
                                        </UncontrolledTooltip>
                                </p>
                                <div>
                                    <textarea
                                    placeholder="Ex. Helping HVAC businesses with exit planning throughout the United States & Canada"
                                    rows="5" onChange={(e) => this.onChangeDescription(e)} value={this.state.description} className="form-control italized-placeholder"></textarea>
                                    <span className="error">{this.state.errors["description"]}</span>
                                </div>

                            </div>
                            {this.state.level !== 1 &&

                            <>

                            <div style={{ marginTop: '15px' }}>
                                <p>Target Industries:</p>
                                <div>
                                    {this.state.industries.map((i, k) =>
                                        <a href="#/" onClick={() => this.addIndustry(i, k)} key={k} className={classnames({ 'btn btn-success': this.state.industry.includes(i.value), 'btn btn-secondary': !this.state.industry.includes(i.value), 'disabled': this.state.level === 4 })}  style={{ display: 'inline-block', margin: '3px' }}>{i.label}</a>
                                    )}
                                </div>
                                <span className="error">{this.state.errors["industry"]}</span>
                            </div>

                            <div style={{ marginTop: '15px' }}>
                                <p>Target Areas:
                                <i style={{ margin: '8px' }} id="Areas" className="sprite i-question"></i>
                                        <UncontrolledTooltip placement="bottom" target="Areas"
                                                            autohide={false}
                                                            innerClassName="areas-tooltip">
                                            Choose location by city or county. Location not relevant for internet-based companies.
                                        </UncontrolledTooltip>
                                </p>
                                <FormGroup>
                                    <Geosuggest
                                        inputClassName="search-input form-control"
                                        id="location"
                                        name="location"
                                        ref="areaserved"
                                        types={['(regions)']}
                                        placeholder="Search by location"
                                        disabled={(this.state.locations.length >= limit.location && "disabled")}
                                        onSuggestSelect={this.handleGeosuggestChange}
                                        initialValue={''}
                                    />
                                    <div className="location-tags">
                                        {this.state.locations.length > 0 && this.state.locations.map((locations, i) => {
                                            if (!locations) {
                                                return ''
                                            }
                                            return (
                                                <span key={`locations-${i}`} className="selected-location">
                                                    <span onClick={this.removeClick.bind(this, i)} className="location-value-icon">×</span>
                                                    <span className="location-value-label">{locations.label}
                                                        <Input type="checkbox"
                                                            name="location"
                                                            id={"locations-" + i}
                                                            value={locations.label}
                                                            defaultChecked="true"/>
                                                    </span>
                                                </span>
                                            )
                                        })}

                                                </div>
                                    <span className="error">{this.state.errors["location"]}</span>
                                </FormGroup>
                            </div></>}
                        </Col>
                        <Col sm={2}>

                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {
                        this.props.edit ? '' : this.state.credits > 0 ?
                            <div>
                                Total: <span style={{ textDecoration: 'line-through' }}>${currentPlan ? currentPlan.amount/100 : '0'}/${currentPlan ? currentPlan.interval : 'mo'}</span>
                                <span style={{ color: 'green' }}> Free ({this.state.credits} credits)</span>
                            </div>
                            : this.context.user.user_type_id === USER_TYPE.BROKER && this.context.user.premium_level >= SubscriptionLevels.PREMIUM
                                ? <div>
                                    Total: <span style={{ textDecoration: 'line-through' }}>${currentPlan ? currentPlan.amount/100 : '0'}/${currentPlan ? currentPlan.interval : 'mo'}</span>
                                    <span style={{ color: 'green' }}> Free access</span>
                                </div>
                                : <div>Total: ${currentPlan ? currentPlan.amount/100 : '0'}/${currentPlan ? currentPlan.interval : 'mo'}</div>

                    }
                    <Button color="" onClick={this.props.onClose} disabled={this.props.loading}>Cancel</Button>
                    {
                        this.props.edit
                            ? <Button
                                color="success"
                                onClick={() => this.props.handleEdit(this.state.description)}
                                disabled={this.props.loading}
                            >
                                Edit
                            </Button>
                            : <Button color="success" onClick={() => this.checkout()}>Checkout</Button>
                    }
                </ModalFooter>
            </div>
        )
    }
}

const PromoteContainer = (props) => (
    <Elements stripe={stripePromise}>
        <ElementsConsumer>
            {({stripe, elements}) => (
                <FeaturedModal {...props} stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    </Elements>
  );

export default withRouter(PromoteContainer);
