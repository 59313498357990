import React from "react";
import PropTypes from "prop-types";
import {SubscriptionLevels} from "../../../../../constants/subscriptionLevels";
import AvailableOption from "./AvailableOption";

const PartnerOption = ({
                             premium,
                             premiumUntil,
                             premiumLevel,
                             userStatus,
                             annual,
                         }) => {

    return (

        <div className="col-md-4 col-sm-12" style={{maxWidth: 375, marginBottom: 32}}>
            <div className="pricing-card">
                <div className="pricing-card-head">
                    <h2 className="pricing-card__title">Partner</h2>
                    <p className="pricing-card__price" style={{fontSize: 20, lineHeight: '22px', padding: '16px 0'}}>
                        Collaborative Origination
                    </p>
                    <p className="pricing-card__annual">
                        Application Only
                    </p>
                    <div className="content-divider" style={{marginTop: !annual ? 42 : 0}}/>
                </div>
                {/* <h3 class="pricing-card__title-sm">Everything in Starter, plus:</h3> */}
                <ul className="pricing-card__features">
                    <AvailableOption>Custom dashboard</AvailableOption>
                    <AvailableOption>Custom pipeline</AvailableOption>
                    <AvailableOption>Curated targets</AvailableOption>
                    <AvailableOption>Outreach software</AvailableOption>
                    <AvailableOption>8-week accelerator</AvailableOption>
                    <AvailableOption>Concierge playbook access</AvailableOption>
                    <AvailableOption>Origination masterclass</AvailableOption>
                    <AvailableOption>Weekly masterminds</AvailableOption>
                    <AvailableOption>OmniSource™ Access</AvailableOption>
                </ul>

                <div className="position-relative text-center mt-auto">

                    {userStatus === '1'
                        && <a
                            href="https://www.biznexus.com/demo"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`${premium && premiumLevel >= SubscriptionLevels.PREMIUM && !premiumUntil ? 'btn-premium-no-action' : ''} btn ${premium && premiumLevel >= SubscriptionLevels.CONCIERGE && !premiumUntil
                                ? 'btn-primary-blue'
                                : 'btn-dark'} pricing-card__select-btn`}
                        >
                            Request Demo
                        </a>}

                </div>
            </div>
        </div>
    )
}

PartnerOption.propTypes = {
    premiumLevel: PropTypes.number,
    userStatus: PropTypes.number,
}

export default PartnerOption
