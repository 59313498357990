import React from 'react';
import './init.js'
import './style.css';
import './scss/index.scss'
import App from './App';
import {unregister} from './registerServiceWorker';

import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import {thunk} from 'redux-thunk';
import 'bootstrap/dist/css/bootstrap.css';
// import 'react-select/dist/react-select.css';
// import 'yet-another-react-lightbox/dist/styles.css';

import {createRoot} from "react-dom/client";

const root = createRoot(document.getElementById("root"));
import rootReducer from './storage/reducers/rootReducer';
import {BrowserRouter} from "react-router-dom";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

Sentry.init({
    dsn: "https://74e8033f9c7b4d0c889cfc129180cd4c@o897950.ingest.sentry.io/5841621",
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
});

const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(thunk),
    ),
);


root.render(
    <BrowserRouter>
      <Provider store={store}>
        <App/>
      </Provider>
    </BrowserRouter>);
unregister();
