import React from 'react';
import { LinkedinIcon, FacebookIcon, TwitterIcon,  } from 'react-share';
import { SocialIcon } from 'react-social-icons';

export default class Footer extends React.Component {

    render() {
        return (
            <footer style={{ backgroundColor: '#282828', color: 'white' }} className="page-footer font-small pt-4">
                <div className="container-fluid text-center text-md-left">
                    <ul className="list-unstyled text-center">
                        <li style={{ margin: '0 10px', display: 'inline-block', paddingTop: 0, textAalign: 'center' }}>
                            <a className="link-hover" href="https://www.biznexus.com/advertise">Advertise</a>
                        </li>

                        <li style={{ margin: '0 10px', display: 'inline-block', paddingTop: 0, textAalign: 'center' }}>
                            <a className="link-hover" href="https://www.biznexus.com/jobs">Work @ BizNexus</a>
                        </li>

                        <li style={{ margin: '0 10px', display: 'inline-block', paddingTop: 0, textAalign: 'center' }}>
                            <a className="link-hover" href="https://www.biznexus.com/terms-of-use/">Terms of Use</a>
                        </li>
                        <li style={{ margin: '0 10px', display: 'inline-block', paddingTop: 0, textAalign: 'center' }}>
                            <a className="link-hover" href="https://www.biznexus.com/privacy-policy">Privacy Policy</a>
                        </li>

                        <li style={{ margin: '0 10px', display: 'inline-block', paddingTop: 0, textAalign: 'center' }}>
                            <a className="link-hover" href="https://www.biznexus.com/contact">Contact</a>
                        </li>
                    </ul>
                </div>
                <div style={{ fontSize: '13px' }} className="footer-copyright text-center py-3">
                
                    <p style={{ paddingTop: '10px' }}>
                        <a target="_blank" rel="noopener noreferrer" href="https://youtube.com/@biznexus"><SocialIcon className="zoom" network="youtube" fgColor="#ffffff" style={{ height: 42, width: 42 }} /></a>
                        <a target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }} href="https://www.linkedin.com/company/biznexus"><LinkedinIcon className="zoom" size={42} round={true} /></a>
                        <a target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }} href="https://www.facebook.com/getbiznexus"><FacebookIcon className="zoom" size={42} round={true} /></a>
                        <a target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }} href="https://twitter.com/BizNexus"><TwitterIcon className="zoom" size={42} round={true} /></a>
                    </p>
                    
                </div>
            </footer>
        )
    }
}