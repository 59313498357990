import React from 'react';
import moment from 'moment';
import '../components/EditProfile/tabs/style.css';
import {  Row, Col
} from 'reactstrap';
import api from '../services/api';
import {AuthContext} from "../contexts/auth";
import {USER_TYPE} from "../constants/userTypes";
import {withRouter} from "./withRouter/withRouter";

class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterSearch: null,
      billing: {
        all_subscriptions: [],
        paymentMethod: null,
        invoices: {
          data: [],
        },
      },
    };
  }

  static contextType = AuthContext

  componentDidMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  getInvoiceAmount = sub => {
    let discount = 0
    let discountPercent = 0
    if (sub.discount && sub.discount.coupon && sub.discount.coupon.valid) {
      if (sub.discount.coupon.amount_off) {
        discount = sub.discount.coupon.amount_off
      } else if (sub.discount.coupon.percent_off) {
        discountPercent = sub.discount.coupon.percent_off
      }
    }
    return ((sub.plan && sub.plan.amount ? sub.plan.amount : 0) - discount)  / 100 * ((100 - discountPercent) / 100)
  }

  init = props => {
    const { match } = props;

    if(match.params.subtab) {
      const event = match.params.subtab;
      this.setState({ filterSearch: event });
    }
    document.getElementById('loader').setAttribute("style", "display: block;");
    api.get('premium/billing', null, true).then(res => {
      document.getElementById('loader').setAttribute("style", "display: none;");
      this.setState({
        billing: {
          all_subscriptions: res.subscriptions,
          paymentMethod: res.paymentMethod,
          invoices: res.invoices && res.invoices.data ? res.invoices : { data: res.invoices || [] },
        },
      });
    });


  }

  openPortal = () => {
    api.post('premium/portal', null, true).then(res => {
      if(res.url) window.open(res.url)
    });
  }

  render() {

    return (
        <>


          <div className="mt-4 mb-3">
            <div>


              <div className="">
                <div className="">

                  <div className="loader" id="loader" style={{display: 'none'}}>
                    <div className="spiner"></div>
                  </div>

                  <Row>
                    <Col md="3">
                      <p onClick={() => {
                        this.props.history.push('/user/favorites/preferences');
                      }}
                         className={`ms-1 position-relative favorite-item`}
                         style={{cursor: 'pointer', fontSize: 16}}>
                        <i className="far fa-bell"></i> <span
                          style={{position: 'absolute', left: 56}}>My Match Preferences</span>
                      </p>
                        <p onClick={() => {
                          this.props.history.push('/user/account/subscription');
                        }}
                           className={`ms-1 position-relative ${this.state.filterSearch === 'subscription' ? 'favorite-item-active' : 'favorite-item'} `}
                           style={{cursor: 'pointer', fontSize: 16}}>
                          <i className="fas fa-credit-card"/> <span
                            style={{position: 'absolute', left: 56}}>Subscriptions</span>
                        </p>

                      <p onClick={() => {
                        this.props.history.push('/user/account/billing');
                      }}
                         className={`ms-1 position-relative ${this.state.filterSearch === 'billing' ? 'favorite-item-active' : 'favorite-item'} `}
                         style={{cursor: 'pointer', fontSize: 16}}>
                        <i className="fas fa-file-invoice-dollar"/> <span
                          style={{position: 'absolute', left: 56}}>Billing</span>
                      </p>
                      <p onClick={() => {
                        window.location.href = 'https://www.biznexus.com/newsletter-for-intermediaries'
                      }}
                         className={`ms-1 position-relative favorite-item`}
                         style={{cursor: 'pointer', fontSize: 16}}>
                        <i className="fa-sharp fa-solid fa-rss"></i> <span
                          style={{position: 'absolute', left: 56}}>Newsletter</span>
                      </p>
                    </Col>
                    <Col sm={"9"}>

                      {this.state.filterSearch === 'subscription' && <Row>
                        <Col sm="12">



                          <div className="pb-3 pt-2">

                            <Row>
                              <Col sm="9">
                                <h1 className="h1 mb-4">
                                  Subscriptions
                                </h1>



                              </Col>
                              <Col sm="3">

                                {Boolean(this.state.billing.all_subscriptions.length > 0 || this.state.billing.invoices.data.length) && <div style={{ float: 'right' }}>
                                  {/*eslint-disable-next-line*/}
                                  <a href={'#'} onClick={() => this.openPortal()} className="btn btn-tag" style={{ marginLeft: 5, fontSize: 13, padding: '15px 15px' }}>
                                    <i className="fas fa-pen"></i> Manage
                                  </a>
                                </div>}
                              </Col>
                            </Row>

                            {(this.state.billing.all_subscriptions.length === 0) && <div className="justify-content-center row">
                              <div className="col-xl-12">
                                <div className="profile-bx" style={{ boxShadow: '1px 1px 20px #e1e1e1', backgroundColor: '#f8f8f8' }}>
                                  <div className="profile-form mt-4 mb-4">
                                    <p className="text-center" style={{ fontSize: '15px',  fontStyle: 'italic' }}>
                                      You don't have any active subscriptions.
                                    </p>
                                    {
                                      this.context.user.user_type_id !== USER_TYPE.BROKER &&
                                        <p className="text-center">
                                          <a href="/user/subscription" className="btn btn-primary">Start Premium Subscription</a>
                                        </p>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>}

                            <Row>
                              <Col>
                                {this.state.billing.all_subscriptions.map(sub => <>
                                  <p style={{ whiteSpace: 'normal', marginBottom: 5 }}>
                                    {sub.product_name}
                                  </p>
                                  <p style={{ whiteSpace: 'normal', marginBottom: 5 }}>
                                    ${(sub.plan && sub.plan.amount ? sub.plan.amount : 0) / 100}/{sub.plan.interval} {sub.plan.currency}
                                  </p>
                                  <p style={{ whiteSpace: 'normal', marginBottom: 5 }}>
                                    Next invoice on {moment.unix(sub.current_period_end).format('LL')} for ${this.getInvoiceAmount(sub)}
                                  </p>
                                  <p style={{ whiteSpace: 'normal', marginBottom: 5 }}>
                                    <a target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-primary" href={sub.invoice.hosted_invoice_url}>View Invoice</a>
                                    <a className="btn btn-sm btn-primary ms-2" href={sub.invoice.invoice_pdf}>Download Invoice</a>
                                  </p>
                                  <div className="divider"></div><br />
                                </>)}
                              </Col>
                            </Row>



                          </div>

                          </Col>
                        </Row>}

                      {this.state.filterSearch === 'billing' && <Row>
                        <Col sm="12">


                          {this.state.billing.paymentMethod && <>
                          <div className="pb-3 pt-2">
                            <Row>
                              <Col sm="9">
                                <h1 className="h1 mb-4">
                                  Billing Information
                                </h1>


                                <p style={{ whiteSpace: 'normal', marginBottom: 5 }}>
                                  Name on card: {this.state.billing.paymentMethod.billing_details.name}
                                </p>
                                <p style={{ whiteSpace: 'normal', marginBottom: 5 }}>
                                  Email: {this.state.billing.paymentMethod.billing_details.email}
                                </p>
                                <p style={{ whiteSpace: 'normal', marginBottom: 5 }}>
                                  Billing Address: {this.state.billing.paymentMethod.billing_details.address.line1}{' '}
                                  {this.state.billing.paymentMethod.billing_details.address.line2}{' '}
                                  {this.state.billing.paymentMethod.billing_details.address.city}{' '}
                                  {this.state.billing.paymentMethod.billing_details.address.state}{' '}
                                  {this.state.billing.paymentMethod.billing_details.address.postal_code}{' '}
                                  {this.state.billing.paymentMethod.billing_details.address.country}
                                </p>
                              </Col>
                              <Col sm="3">

                                <div style={{ float: 'right' }}>
                                  {/*eslint-disable-next-line*/}
                                  <a href={'#'} onClick={() => this.openPortal()} className="btn btn-tag" style={{ marginLeft: 5, fontSize: 13, padding: '15px 15px' }}>
                                    <i className="fas fa-pen"></i> Edit
                                  </a>
                                </div>
                              </Col>
                            </Row>

                            <div className="divider"></div><br /><br /><br />

                            <Row>
                              <Col sm="9">
                                <h1 className="h1 mb-4">
                                  Payment Method
                                </h1>


                                <p style={{ whiteSpace: 'normal', marginBottom: 5 }}>
                                  <i className="fas fa-credit-card"></i>  •••• •••• •••• {this.state.billing.paymentMethod.card.last4}
                                </p>
                                <p style={{ whiteSpace: 'normal', marginBottom: 5 }}>
                                  Exp. date: {this.state.billing.paymentMethod.card.exp_month}/{this.state.billing.paymentMethod.card.exp_year}
                                </p>
                              </Col>
                              <Col sm="3">

                                <div style={{ float: 'right' }}>
                                  {/*eslint-disable-next-line*/}
                                  <a href={'#'} onClick={() => this.openPortal()} className="btn btn-tag" style={{ marginLeft: 5, fontSize: 13, padding: '15px 15px' }}>
                                    <i className="fas fa-pen"></i> Edit
                                  </a>
                                </div>
                              </Col>
                            </Row>

                            <div className="divider"></div>


                          </div>

                          </>}

                          {(!this.state.billing.paymentMethod) && <div className="justify-content-center row">
                            <div className="col-xl-12">
                              <div className="profile-bx" style={{ boxShadow: '1px 1px 20px #e1e1e1', backgroundColor: '#f8f8f8' }}>
                                <div className="profile-form mt-4 mb-4">
                                  <p className="text-center" style={{ fontSize: '15px',  fontStyle: 'italic' }}>
                                    Billing information is not available.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>}

                        </Col>
                      </Row>}
                  </Col>

                  </Row>





                </div>



              </div>


            </div></div>


        </>
    )
  }
}

export default withRouter(Billing);
