import React from 'react';
import {
    Row,
    Col,
    TabContent, TabPane, Nav, NavItem, NavLink, Badge, Button, Modal
} from 'reactstrap';
import classnames from 'classnames';
import { LinkedinShareButton, LinkedinIcon, FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from 'react-share';
// import _ from 'lodash';
import api from '../../services/api';
import FeaturedProfile from '../FeaturedProfile';
import {Link} from 'react-router-dom';

export default class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            posts: [],
            categories: [],
            selectedCategory: null,
            profile: {},
            activeTab: 1,
            boostModalOpen: false,
        };
    }

    init = () => {
        this.getPosts();

        api.get('user/editprofile', null, true)
            .then(profile => this.setState({ profile }));
    }

    componentDidMount() {
        this.init();
    }

    getPosts = async () => {
        document.getElementById('loader').setAttribute("style", "display: block;");
        return await api.get('home/posts', null, true)
            .then(posts => {
                const categories = [];
                posts.forEach(p => {
                    if(p.category) {
                        categories.push(...p.category.split(','))
                    }
                })
                
                const uniqueCategories = [...new Set(categories)];
                this.setState({ posts, categories: uniqueCategories });
                document.getElementById('loader').setAttribute("style", "display: none;");
            });
    }

    sliceText(text) {
        return text && text.length >= 100 ? text.slice(0, 100) + '...' : text;
    }

    selectCategory = async c => {
        let cat = null;
        if(c === this.state.selectedCategory) {
            this.setState({ selectedCategory: null });
            this.getPosts();
            return;
        } else {
            cat = c;
            this.setState({ selectedCategory: c });
            await this.getPosts();
        }
        
        const filterPosts = cat ? this.state.posts.filter(p => {
            if(p.category) {
                return p.category.split(',').includes(cat)
            } else {
                return false;
            }
            
        }): this.state.posts;
        this.setState({ posts: filterPosts });
    }

    render() {

        let firstname = '';
        if(this.state.profile.name) {
            let fullname = this.state.profile.name.match(/(\w+)(?:\s[^\s]+)?\s(\w+).*/);
            if (fullname !== null) {
                firstname = fullname[1];
            } else {
                firstname = this.state.profile.name;
            }
        }
       
        return (
            <div className="container" style={{ width: '1000px' }}>
                <Row>
                    <Col sm="4">
                        <h3 style={{ color: 'grey' }}>Welcome, {firstname}</h3>
                    </Col>
                    <Col sm="4" className="text-center">
                        {/* <span>Earn a free premium subscription!</span>
                        <Button style={{ marginTop: '7px', backgroundColor: 'rgb(56, 144, 218)' }} onClick={() => window.location.href = '/edit-profile/referral-program'} color="primary">
                            Learn More
                        </Button> */}
                    </Col>
                    <Col sm="4">
                        <h3 style={{ paddingTop: '5px', paddingLeft: '100px' }}>
                            <Link style={{ color: 'grey' }} to={'/edit-profile/profile'}>Edit profile &rarr;</Link>
                        </h3>
                    </Col>
                </Row>

                <Row style={{ marginTop: '0px', display: 'block' }} className="text-center">
                    <img style={{ height: '250px' }} src={process.env.PUBLIC_URL + '/images/Broker_Homepage_Header.png'} class="img-fluid" alt="" />
                </Row>

                <Row className="text-center">
                    <Col sm="12">
                        <Button style={{ backgroundColor: 'rgb(56, 144, 218)' }} onClick={() => window.location.href = '/edit-profile/ads'} color="primary">Advertise on BizNexus</Button>  
                        <Button style={{ marginLeft: '12px', backgroundColor: 'rgb(56, 144, 218)' }} onClick={() => this.setState({ boostModalOpen: true })} color="primary">Boost Your Profile</Button>
                    </Col>     
                </Row>


                <Row style={{ paddingTop: '30px' }}>
                    <Col sm="12">
                        <Nav tabs>
                            <NavItem style={{ cursor: 'pointer', width: '50%' }}>
                                <NavLink className={classnames({ active: this.state.activeTab === 1 })}
                                            onClick={() => { this.setState({ activeTab: 1 }) }}
                                            style={{ color: '#3890da', fontSize: '16px', fontWeight: '700', height: '100%' }}>
                                    Trending Content
                                </NavLink>
                            </NavItem>
                            <NavItem style={{ cursor: 'pointer', width: '50%' }}>
                                <NavLink className={classnames({ active: this.state.activeTab === 2 })}
                                            onClick={() => { this.setState({ activeTab: 2 }) }}
                                            style={{ color: '#3890da', fontSize: '15px', fontWeight: '700' }}>
                                    Join & Engage With M&A-Friendly Online Networks & Groups
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId={1}>
                                <div style={{ paddingTop: '20px', paddingBottom: '50px' }}>
                                    <div style={{ display: 'inline-flex' }}>
                                        {this.state.categories.map(c => 
                                            <h4 style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => this.selectCategory(c)}><Badge style={this.state.selectedCategory !== c ? { backgroundColor: 'rgb(56, 144, 218)', color: '#fff'} : {} } color={this.state.selectedCategory === c ? 'success': ''}>{c}</Badge></h4>
                                        )}
                                    </div>
                                    <div className="loader bg-white" id="loader" style={{display: 'none'}}>
                                        <div className="spiner"></div>
                                    </div>
                                    {this.state.posts.map(p => <div key={p.id}>
                                    <div className="sale-listingview" style={{ marginTop: '50px' }}>
                                        <div className="sale-listing">
                                        <Row>
                                                <Col md="3">
                                                    <div className="listing-img">
                                                        <img width="240px" height="130px" src={p.image} alt="" />
                                                    </div>
                                                </Col>
                                                <Col md="7">
                                                    <a href={p.link} target="_blank" rel="noopener noreferrer" className="sale-text-title mb-3">{p.name}</a>
                                                    <p className="mb-0">{this.sliceText(p.text)}</p>
                                                    {p.category && p.category.split(',').map(c =>
                                                        <Badge style={{ marginRight: '5px' }}>{c}</Badge>
                                                    )}
                                                </Col>
                                                <Col md="2" className="text-center">
                                                    <b style={{ paddingBottom: '5px' }}>Share Now!</b><br /><br />
                                                    <LinkedinShareButton url={p.link}>
                                                        <LinkedinIcon size={32} round={true} />
                                                    </LinkedinShareButton>
                                                    <FacebookShareButton url={p.link} style={{ marginLeft: '10px' }}>
                                                        <FacebookIcon size={32} round={true} />
                                                    </FacebookShareButton>
                                                    <TwitterShareButton url={p.link} style={{ marginLeft: '10px' }}>
                                                        <TwitterIcon size={32} round={true} />
                                                    </TwitterShareButton>
                                                </Col>
                                            </Row>
                                        </div>
                                        
                                        </div>
                                    </div>)}
                                </div>
                            </TabPane>
                            <TabPane tabId={2}>
                                <div style={{ marginTop: '25px' }}>
                                    <h4>LinkedIn Groups</h4>
                                    <ul>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/2004530/" target="_blank">I Want to Sell My Business in 5 Years – Exit Planning</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/1789603/" target="_blank">I Want to Buy a Business</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/1806481/" target="_blank">American Business Brokers Association - ABBA</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/3790878/" target="_blank">Business Owners, Entrepreneurs & Start-Ups</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/84596/" target="_blank">The M&A Forum</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/145170/" target="_blank">International Business Brokers Association</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/879777/" target="_blank">Exit Planning Exchange</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/111694/" target="_blank">Association of Professional Merger & Acquisition Advisors (APMAA)</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/157449/" target="_blank">Successful QuickBooks Consultants QBO QB Power Hour Cloud Connect Accountant</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/157449/" target="_blank">CPA Bookkeeping Online</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/59189/" target="_blank">SCORE Mentors Group</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/46192/" target="_blank">The Family Office Club - #1 Largest Family Office Association | Ultra-Wealthy Wealth</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/46192/" target="_blank">Management</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/2056958/" target="_blank">Accounting & Audit</a></li>
                                        
                                    </ul>
                                    <h4>Facebook Groups</h4>
                                    <ul>
                                        <li><a rel="noopener noreferrer" href="https://www.facebook.com/groups/entrepreneurshipthroughacquisition/" target="_blank">Entrepreneurship Through Acquisition</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.facebook.com/groups/796589517213446/?ref=group_header" target="_blank">IBBA - International Business Brokers Association Members</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.facebook.com/groups/2190421317862883/?ref=group_header" target="_blank">Acquisition Insight: USA M&A Community</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.facebook.com/groups/bn.digital" target="_blank">Social Selling For Intermediaries</a></li>
                                        
                                    </ul>
                                    <h4>Quora Threads</h4>
                                    <ul>
                                        <li><a rel="noopener noreferrer" href="https://www.quora.com/topic/Business-for-Sale" target="_blank">Business for Sale</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.quora.com/topic/Small-Business-Owners" target="_blank">Small Business Owners</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.quora.com/topic/Buying-and-Selling-a-Business" target="_blank">Buying and Selling a Business</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.quora.com/topic/Business-Brokerage" target="_blank">Business Brokerage</a></li>
                                        
                                    </ul>
                                </div>    
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>

                <Modal size={'lg'} isOpen={this.state.boostModalOpen} toggle={() => this.setState({ boostModalOpen: !this.state.boostModalOpen })} className="modal-dialog modal-featured" keyboard={false} backdrop="static">
                    <FeaturedProfile
                        description={''}
                        onClose={() =>this.setState({ boostModalOpen: false }) }
                    />
                </Modal>  
                
            </div>
        )
    }
}