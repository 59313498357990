import {Bars} from "react-loader-spinner";
import React from 'react';
import api from '../../services/api';
import {Table, Row, Col, Modal, ModalBody, ModalHeader, Label, FormGroup} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {LeasedOrOwned} from "../../constants/Listing";
import {agents} from "../../constants/agents";
import Select from "react-select";

const options = [{ label: 'Default', value: 'default'}, { label: 'Shared', value: 'shared'}, {label: 'Concierge', value: 'concierge' }]

export default class Shared extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dealType: { label: 'Default', value: 'default'},
            agents: [],
            listModal: false,
            list: [],
            agentsStatusList: [],
            loading: false,
        };
    }

    componentDidMount() {
        this.init();
    }

    init = () => {
        api.get('console/agents', null, true).then(res => {
            this.setState({ agents: res.agents, agentsStatusList: res.agentsStatusList });
        });
    }

    onPreview = () => {
        const file = document.getElementById('preview');
        
        if(file.files[0]) {
            const data = new FormData();
            data.append('file', file.files[0]);
            data.append('preview', true);
            fetch(process.env.REACT_APP_API_ROOT + 'integration/internal/add_shared_deals', {
                method: 'POST',
                body: data,
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            }).then(response => response.json())
            .then(list => {
                this.setState({ listModal: true, list });
            })
        }
        
    }

    onUpload = () => {
        const file = document.getElementById('upload');
        
        if(file.files[0]) {
            this.setState({ loading: true })
            const data = new FormData();
            data.append('file', file.files[0]);
            data.append('deal_type', this.state.dealType.value);
            fetch(process.env.REACT_APP_API_ROOT + 'integration/internal/add_shared_deals', {
                method: 'POST',
                body: data,
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            }).then(response => response.json())
            .then(list => {
                window.location.reload();
            })
        }
        
    }

    onChangeVisibility = (broker_access, integration_id) => {
        api.post('console/change_broker_access', { integration_id, broker_access }, true).then(() => {
            this.init();
        });
    }

    getBrokerAccess = integration_id => {
        const agent = this.state.agentsStatusList.find(a => a.integration_id === integration_id);
        if(agent) {
            return agent.broker_access;
        } else {
            return false;
        }
    }

    handleTypeChange = (newType) => {
        this.setState({
            dealType: newType
        })
    }

    

    render() {

        const agentName = id => {
            return Object.keys(agents).find(key => agents[key] === id);

        }

        // const custom = this.state.agents.find(a => a.integration_id === 99) || {}

        return (
            <div>
                <Link to="/console">&larr; Back</Link>
                        <br /><br />
                        <Row>
                        <Col sm="8">
                            <h2>Agents</h2>
                            <Table bordered hover responsive>
                                <thead style={{ textAlign: 'center' }}>
                                    <tr>
                                        <th>Agent</th>
                                        <th># of deals</th>
                                        <th>M&A Advisor Access</th>
                                        <th>Upload At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {/* <tr style={{ textAlign: 'center' }}>
                                    <td><Link to={`/console/shared/99`}>Manually Added</Link></td>
                                    <td>{custom.count || 0}</td>
                                    <td>
                                        <div class="custom-control custom-switch">
                                            <input onChange={(e) => this.onChangeVisibility(e.target.checked, 99)} checked={this.getBrokerAccess(99)} type="checkbox" class="custom-control-input" id={`customSwitch${99}`} />
                                            <label class="custom-control-label" for={`customSwitch${99}`}></label>
                                        </div> 
                                    </td>
                                </tr>     */}
                                {this.state.agents.map(s => 
                                        <tr style={{ textAlign: 'center' }}>
                                            <td><Link to={`/console/shared/${s.integration_id}`}>{agentName(s.integration_id)}</Link></td>
                                            <td>{s.count}</td>
                                            <td>
                                                <div class="custom-control custom-switch">
                                                    <input onChange={(e) => this.onChangeVisibility(e.target.checked, s.integration_id)} checked={this.getBrokerAccess(s.integration_id)} type="checkbox" class="custom-control-input" id={`customSwitch${s.integration_id}`} />
                                                    <label class="custom-control-label" for={`customSwitch${s.integration_id}`}></label>
                                                </div> 
                                            </td>
                                            <td>{moment(s.last_update).format('LLL')}</td>
                                        </tr>    
                                )}
                                </tbody>
                            </Table>
                        </Col>
                        <Col sm="4">
                            <div className="card">
                                <div className="card-body">
                                    {this.state.loading ?
                                        <div className="text-center d-flex justify-content-center">
                                        <Bars
                                            color="#3890da"
                                            height={30}
                                            width={30}
                                            visible={true}
                                            ariaLabel="bars-loading"
                                        />
                                        </div>
                                    : 
                                    <>
                                        <h2>Upload</h2>
                                        <FormGroup>
                                            <Label htmlFor="deal_type">Preselect deals type:</Label>
                                            <Select
                                                classNamePrefix="Select"
                                                classNames={{
                                                    control: (state) =>
                                                        state.isFocused ? 'Select-control' : 'Select-control',
                                                }}
                                                className="mt-2"
                                                isSearchable={false}
                                                isClearable={false}
                                                onChange={this.handleTypeChange}
                                                options={options}
                                                value={this.state.dealType}
                                                id="deal_type"
                                                name="deal_type"
                                                style={{backgroundColor: 'white'}}
                                            />
                                        </FormGroup>

                                        <br />
                                            <input id="preview" type="file" name="file" accept=".csv" />
                                            <input onClick={() => this.onPreview()} value="Preview" type="submit" className="btn btn-primary" />
                                        <br /><br />                          
                                        <input id="upload" type="file" name="file" accept=".csv" />
                                        <input onClick={() => this.onUpload()} value="Submit" type="submit" className="btn btn-primary" />
                                    </>}
                                    

                                    
                                </div>
                                
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div className="card-body">
                                <a href="/custom.csv">Sample CSV</a>
                                    <p>
                                        <sub><i>cashflow</i>, <i>ebitda</i> or <i>sde</i> - if there is cashflow we take cashflow as sde, if there is no cashflow and sde we take sde, if cashflow amount more than 600,000 we set it as ebitda, but also ebitda can be set explicitly.</sub>
                                    </p>
                                    <p>
                                        <sub><i>integration_id</i> or <i>url</i> - if there is integration_id=99 (Manual) or any other integration_id (that is added to db) we consider this deal as agent from db. if there is no integration_id but url is presented we check domain for existing url domains in db</sub>
                                    </p>
                                    <p>
                                        <sub><i>externalLink</i> - external broker website url</sub>
                                    </p>
                                    <p>
                                        <sub><i>reference is required field</i> - for manually added spreadshit use next function  <strong>=CONCATENATE("MA", ROW(), UPPER(LEFT(E2, 2)), CHAR(RANDBETWEEN(65, 90)), CHAR(RANDBETWEEN(65, 90)), CHAR(RANDBETWEEN(65, 90)), CHAR(RANDBETWEEN(65, 90)))</strong> - where E2 - means E(row number in spreadsheet), all other numbers keep same</sub>
                                    </p>
                                    <h2>Duplicates</h2><br />
                                    duplicates not found
                                </div>
                            </div>
                        </Col>    
                        </Row>

                        <Modal style={{ maxWidth: '85%' }} size={'lg'} isOpen={this.state.listModal} toggle={() => this.setState({ listModal: !this.state.listModal })} className="modal-dialog modal-featured" keyboard={true} backdrop="static">
                            <ModalHeader toggle={() => this.setState({ listModal: !this.state.listModal })}>
                                
                            </ModalHeader>
                            <ModalBody>
                            <div className="text-center">
                            <Table bordered hover responsive>
                                <thead style={{ textAlign: 'center' }}>
                                    <tr>
                                        <th>name</th>
                                        <th>brokerEmail</th>
                                        <th>brokerPhone</th>
                                        <th>brokerOfficePhone</th>
                                        <th>sde</th>
                                        <th>city</th>
                                        <th>country</th>
                                        <th>county</th>
                                        <th>created_at</th>
                                        <th>description</th>
                                        <th>ebitda</th>
                                        <th>image</th>
                                        <th>integration_id</th>
                                        <th>integration_reference_id</th>
                                        <th>inventory</th>
                                        <th>lat</th>
                                        <th>long</th>
                                        <th>price</th>
                                        <th>referencenumber</th>
                                        <th>revenue</th>
                                        <th>state</th>
                                        <th>transaction_slug</th>
                                        <th>broker_company_name</th>
                                        <th>broker_site_url</th>
                                        <th>established</th>
                                        <th>leased_or_owned</th>
                                        <th>real_estate</th>
                                        <th>reason_selling</th>
                                        <th>facilities</th>
                                        <th>financing</th>
                                        <th>competition</th>
                                        <th>growth_opportunity</th>
                                        <th>ongoing_support</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.list.map(s => 
                                        <tr style={{ textAlign: 'center' }}>
                                            <td>{s.name}</td>
                                            <td>{s.brokerEmail}</td>
                                            <td>{s.brokerPhone}</td>
                                            <td>{s.brokerOfficePhone}</td>
                                            <td>{s.cashflow}</td>
                                            <td>{s.city}</td>
                                            <td>{s.country}</td>
                                            <td>{s.county}</td>
                                            <td>{s.created_at}</td>
                                            <td>{s.description}</td>
                                            <td>{s.ebitda}</td>
                                            <td>{s.image}</td>
                                            <td>{s.integration_id}</td>
                                            <td>{s.integration_reference_id}</td>
                                            <td>{s.inventory}</td>
                                            <td>{s.lat}</td>
                                            <td>{s.long}</td>
                                            <td>{s.price}</td>
                                            <td>{s.referencenumber}</td>
                                            <td>{s.revenue}</td>  
                                            <td>{s.state}</td>  
                                            <td>{s.transaction_slug}</td>
                                            <td>{s.broker_company_name}</td>
                                            <td>{s.broker_site_url}</td>
                                            <td>{s.date}</td>
                                            <td>{s.leased_or_owned ? LeasedOrOwned[s.leased_or_owned] : ''}</td>
                                            <td>{s.real_estate}</td>
                                            <td>{s.reasonselling}</td>
                                            <td>{s.facilities}</td>
                                            <td>{s.financing}</td>
                                            <td>{s.competition}</td>
                                            <td>{s.growthopportunity}</td>
                                            <td>{s.ongoingsupport}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            </div>
                            </ModalBody>
                        </Modal>  
            </div>
        )
    }
}

// {moment(new Date(b.last_login_at)).format('LLL')}
