import React, {useEffect, useState} from "react";
import {Bars} from "react-loader-spinner";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Preferences from "../../EditProfile/tabs/preferences";
import Select from "react-select";

const SharedLeadsImport = ({ pageLoading }) => {
    const [loading, setLoading] = useState(pageLoading)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [preferences, setPreferences] = useState({})
    const [isApollo, setIsApollo] = useState(false)
    const [typesList, setTypesList] = useState([])
    const [type, setType] = useState([])
    const [step, setStep] = useState(1)
    const [forceOverride, setForceOverride] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    useEffect(() => {
        fetch(process.env.REACT_APP_API_ROOT + 'user/userProfessionlist', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            },
        }).then(res => res.json())
            .then(
                (res) => {
                    let professionlistarray = [];
                    // eslint-disable-next-line
                    res.map((choice, i) => {
                        if (choice.status === '1') {
                            let temp = {
                                "value": choice.id,
                                "label": choice.profession_value
                            };
                            professionlistarray.push(temp);
                        }
                    });
                    setTypesList(professionlistarray);
                }
            )
    }, [])

    useEffect(() => {
        if (step === 2) {
            setIsApollo(true)
        }
    }, [step])

    const handleOverrideChange = event => {
        setForceOverride(event.target.checked)
    }

    const onUpload = () => {
        const file = document.getElementById('upload');

        if(file.files[0]) {
            setLoading(true)
            const data = new FormData();
            data.append('file', file.files[0]);
            data.append('preferences', JSON.stringify({...preferences, isApollo, type: type.map(({label}) => label), forceOverride}));
            fetch(process.env.REACT_APP_API_ROOT + 'console/import_leads', {
                method: 'POST',
                body: data,
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            }).then(response => response.json())
                .then(list => {
                    closeModal()
                    window.location.reload();
                })
        }

    }
    const handleTypeChange = option => {
        setType(option)
    }

    const handeFileChange = event => {
        if (event.target.value) {
            setIsButtonDisabled(false)
        }
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setPreferences({})
        setIsButtonDisabled(true)
        setStep(1);
    }

    return (
        <div>
            <div className="card">
                <div className="card-body">
                    {loading ?
                        <div className="text-center d-flex justify-content-center">
                            <Bars
                                color="#3890da"
                                height={30}
                                width={30}
                                visible={true}
                                ariaLabel="bars-loading"
                            />
                        </div>
                        :
                        <>
                            <h2>Import</h2><br />
                            <Button type="button" className="btn-bluedark" onClick={() => setIsModalOpen(true)}>Upload</Button>
                        </>}

                    <p className="pt-4">
                        <a href="/shared_leads_sample.csv">Download Sample CSV</a>
                    </p>


                    <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}
                           className="modal-dialog modal-dialog-centered delete-item" keyboard={false} backdrop="static">
                        <ModalHeader toggle={closeModal}>
                            {
                                step === 1 && <div>
                                    What type of file?
                                </div>
                            }
                            {
                                step === 2 && <div>
                                    Select preferences
                                </div>
                            }
                            {
                                step === 3 && <div>
                                    Upload the file
                                </div>
                            }
                        </ModalHeader>
                        <ModalBody>
                            {
                                step === 1 && <p>
                                    Based on a file type there are different fields  in CSV
                                </p>
                            }
                            {
                                step === 2 && <div>
                                    <FormGroup className="row" noMargin>
                                        <Label for="professionlist" className="col-sm-3 d-flex align-items-center">Type</Label>
                                        <div className="col-sm-9">
                                            <div className="py-2 px-3 position-relative">
                                                <Select
                                                    classNamePrefix="Select"
                                                    classNames={{
                                                        control: (state) =>
                                                            state.isFocused ? 'Select-control Select-multi' : 'Select-control Select-multi',
                                                    }}
                                                    name="professionlist"
                                                    id="professionlist"
                                                    isMulti
                                                    className="custom-multiselect"
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    arrowRenderer={() => selectedOption.length === this.state.professionlist.length
                                                        ? ''
                                                        : <span className="custom-multiselect-dropdown-icon"/>}
                                                    onChange={handleTypeChange}
                                                    options={typesList}
                                                    value={type}
                                                    placeholder="Select Buyer Type(s)"
                                                />
                                            </div>
                                        </div>

                                    </FormGroup>
                                    <Preferences embedded noPreset onPreferencesChanged={setPreferences} />
                                    <FormGroup className="row">
                                        <Label for="override" className="col-sm-10">Force Override</Label>
                                        <div className="col-sm-2">
                                            <Input type="checkbox"
                                                   id="override"
                                                   name="override"
                                                   onChange={handleOverrideChange}
                                                   className="custom-control-input"
                                                   checked={forceOverride}/>
                                        </div>

                                    </FormGroup>
                                </div>
                            }
                            {
                                step === 3 && <div>
                                    <p>Select a file</p>
                                    <input id="upload" type="file" name="file" accept=".csv" onChange={handeFileChange}/>
                                </div>
                            }
                        </ModalBody>
                        <ModalFooter>
                            {
                                step === 1 && <div className="d-flex justify-content-between w-100">
                                    <Button type="button" className="btn-bluedark" onClick={() => setStep(2)}>Apollo</Button>
                                    <Button type="button" className="btn-bluedark" onClick={() => setStep(3)}>Regular</Button>
                                </div>
                            }
                            {
                                step === 2 && <div className="d-flex justify-content-between w-100">
                                    <Button type="button" className="btn-bluedark" onClick={() => setStep(1)}>Back</Button>
                                    <Button type="button" className="btn-bluedark" onClick={() => setStep(3)}>Next</Button>
                                </div>
                            }
                            {
                                step === 3 && <div className="d-flex justify-content-between w-100">
                                    <Button type="button" className="btn-bluedark" onClick={() => setStep(isApollo ? 2 : 1)}>Back</Button>
                                    <input onClick={() => onUpload()} disabled={isButtonDisabled} value="Upload" type="submit" className="btn btn-primary text-center mt-3" />
                                </div>
                            }
                        </ModalFooter>
                    </Modal>

                </div>

            </div>

        </div>
    )
}

export default SharedLeadsImport
