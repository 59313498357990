import React from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import api from '../../../../../services/api';
import {toast} from "react-toastify";
import {withRouter} from "../../../../../containers/withRouter/withRouter";


class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {}
    };
    this.SubmitLogin = this.SubmitLogin.bind(this);
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //newpass
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Please enter your new Email";
    }
    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf('@');
      let lastDotPos = fields["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  componentDidMount() {
    document.getElementById('loaderpopup').setAttribute("style", "display: none;");
  }
  SubmitLogin = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      document.getElementById('loaderpopup').setAttribute("style", "display: block;");
      api.post('user/changeemail', {email: this.state.fields["email"]}, true)
          .then(res => {
            if (res.success) {
              this.setState({
                fields: {
                  email: '',
                },
                errors: {}
              });
              document.getElementById('loaderpopup').setAttribute("style", "display: none;");
              toast.info("Your Login Email was successfully updated", {
                position: "bottom-right",
                className: 'toast-bar',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            else {
              let errors = {};
              errors["email"] = res.message;
              this.setState({ errors: errors });
              document.getElementById('loaderpopup').setAttribute("style", "display: none;");
            }
          });

    }
  }

  render() {
    return (
        <section className="">
          <div className="loader bg-white" id="loaderpopup"><h3>Please Wait...</h3><div className="spiner"></div></div>
          <h1 className="h1">Change Login Email</h1>
          <div className="mt-3">
            <span className="error ">{this.state.errors["errorMessage"]}</span>
            <Form onSubmit={this.SubmitLogin}>
              <FormGroup>
                <Label for="passwordlogin">Your New Email <span style={{ color: 'red' }}>*</span></Label>
                <Input type="text" placeholder="new email" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} />
                <span className="error ">{this.state.errors["email"]}</span>
              </FormGroup>


              <button type="submit" className="btn btn-primary">Change Login Email</button>
            </Form>
          </div>
        </section>
    );
  }
}
export default withRouter(ChangePassword);
