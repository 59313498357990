import React from 'react';
import api from '../../services/api';
import { Table, Row, Col, FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {getImageUrl} from '../util';
import _ from 'lodash';
import Dropzone from "../Dropzone";

export default class Ads extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ads: [],
            plans: [],

            isOpen: false,
            modalName: '',
            modalType: '',
            activePlan: null,
            file: null,
            image: null,
            linkTo: '',
            text: '',
            errors: {

            },
            metadata: {},
            boostModalOpen: false,
        };
    }

    componentDidMount() {
        this.init();
    }

    init = () => {
        this.setState({ isOpen: false, modalName: '', modalType: '', activePlan: null, file: null, image: null, linkTo: '', text: '', errors: {}, metadata: {} });
        api.get('console/ads', null, true).then(ads => {
            this.setState({ ads });
        });

        api.get('ads/plans', null, true)
            .then(res => {
                this.setState({ plans: res });
            });
    }

    onFileError = failedResults => {
        this.setState({ errors: { image: failedResults[0].errors[0].message } });
    }

    onSelectFile = async value => {
        const d = await this.getImageDimensions(value[0]);
        this.setState({ errors: {}});
        if(d.w === parseInt(this.state.metadata.width) && d.h === parseInt(this.state.metadata.height)) {
            this.setState({
                file: value[0],
                image: d.src,
            });
        } else {
            this.setState({ errors: { image: `Image should be ${this.state.metadata.width}x${this.state.metadata.height}` } });
        }
    }

    getImageDimensions = file => {
        return new Promise (function (resolved, rejected) {
            var i = new Image()
            i.onload = function(){
                resolved({w: i.width, h: i.height, src: i.src})
            };
            const reader = new FileReader();
            reader.onload = (event) => {
                i.src = event.target.result;
            };
            reader.readAsDataURL(file);
        })
    }

    openModal = ({ planId }) => {
        const plan = _.find(this.state.plans, { id: planId });
        this.setState({
            activePlan: planId,
            modalName: plan.nickname,
            modalType: plan.metadata.type,
            metadata: plan.metadata,
            isOpen: true,
        })
    }


    onCheckout = () => {
        if(this.state.text.length > 100) {
            this.setState({ errors: { text: 'Text is too long' } });
            return;
        }

        if(this.state.linkTo.length > 100) {
            this.setState({ errors: { link: 'Link is too long' } });
            return;
        }

        if(this.state.modalType === 'image') {
            const { name: filename, filetype: type } = this.state.file;

            const imgUrl = this.state.image
            return fetch(imgUrl)
                .then(r => r.blob())
                .then(blob => new File([blob], filename, { type: type }))
                .then(file => {
                    const data = new FormData();
                    data.append('file', file);
                    data.append('linkTo', this.state.linkTo);
                    data.append('planId', this.state.activePlan);
                    data.append('modalType', this.state.modalType);

                    return fetch(process.env.REACT_APP_API_ROOT + 'ads/create', {
                        method: 'POST',
                        body: data,
                        headers: {
                            Accept: 'application/json',
                            "Access-Control-Allow-Origin": "*",
                            'x-access-token': localStorage.getItem('token'),
                        }})
                        .then(res => {
                            if (res.status < 200 || res.status >= 300) {
                                res.json().then(r => {throw r})
                            }
                            else {
                                this.init();
                                return res.json();
                            }
                        })
                });
        } else if(this.state.modalType === 'text') {
            const { text } = this.state;
            let linkTo = this.state.linkTo;
            if(! linkTo.startsWith('https://')) {
                linkTo = `https://${linkTo}`;
            }

            return api.post('ads/create', {
                text,
                linkTo,
                planId: this.state.activePlan,
                modalType: this.state.modalType
            }, true)
                .then(() => {
                    this.init();
                })
        }


    }

    onChangeVisibility = ad => {
        return api.post('ads/visibility', {
            id: ad.id,
            is_active: ! ad.is_active,
        }, true)
            .then(() => {
                this.init();
            });
    }
    

    render() {

        return (
            <div>
                <Link to="/console">&larr; Back</Link>
                        <br /><br />
                        <Row>
                        <Col sm="12">
                            <h2>Live Ads</h2>
                            <section className="pt-3 pb-4">
                            <div className="card">
                                <div className="card-body">
                                    <h2>New Ad</h2>
                                    <div className="row pt-2">
                                        {this.state.plans.map(p =>
                                            <div class="col-md-6 col-lg-4 mb-4">
                                                <div class="option-card">

                                                    <div class="option-card__content">
                                                        <h3 class="option-card-title h2">{p.nickname}</h3>
                                                        <p class="option-card-description">{p.metadata.text}</p>
                                                        <p class="option-card-price">
                                                            <span class="option-card-price--old"></span>
                                                            <span>${(p && p.amount ? p.amount : 0) / 100} / month</span>
                                                        </p>
                                                        <button onClick={() => this.openModal({planId: p.id, nickname: p.nickname})} class="btn btn-primary w-100">Create Ad</button>
                                                    </div>
                                                </div>
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                            </section>
                            <Table bordered hover responsive>
                                <thead style={{ textAlign: 'center' }}>
                                    <tr>
                                        <th>Image/Text</th>
                                        <th>Created</th>
                                        <th>Link To</th>
                                        <th>Plan ID</th>
                                        <th>Live</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.ads.map(s => 
                                        <tr style={{ textAlign: 'center' }}>
                                            <td>{s.type === 'image' ? <img alt="" style={{ boxShadow: '1px 1px 15px #c2c2c2' }} src={getImageUrl(s.image)} /> : s.text}</td>
                                            <td>{moment(new Date(s.created_on)).format('LLL')}</td>
                                            <td>{s.link_to}</td>
                                            <td>{s.stripe_plan_id}</td>
                                            <td><React.Fragment>
                                                <div class="custom-control custom-switch">
                                                    <input onChange={(e) => this.onChangeVisibility(s)} type="checkbox" checked={s.is_active ? true : false} class="custom-control-input" id={`customSwitch${s.id}`} />
                                                    <label class="custom-control-label" for={`customSwitch${s.id}`}></label>
                                                </div>
                                            </React.Fragment></td>
                                        </tr>    
                                    )}
                                </tbody>
                            </Table>
                        </Col>

                        </Row>


                        <Modal style={{ width: '400px', height: '400px' }} isOpen={this.state.isOpen} toggle={this.toogle} className="modal-dialog-centered delete-item" keyboard={false} backdrop="static">
                            <ModalHeader>{this.state.modalName}</ModalHeader>
                            <ModalBody>
                                {this.state.modalType === 'image' && <Row style={{paddingLeft: '15px' }}>
                                    <FormGroup>
                                        <Label htmlFor="file">Image:</Label>
                                        {this.state.image && <div className="img-preview">
                                            <img alt="" src={this.state.image} className="img-fluid" />
                                        </div>}
                                        {!this.state.image && <div className="banner-drop user-banner">
                                            <Dropzone
                                                text="Click to upload JPG or PNG here"
                                                maxFileSize={5024000}
                                                multiple={false}
                                                onSuccess={this.onSelectFile}
                                                onFailure={this.onFileError}
                                            />
                                        </div>
                                        }
                                        <span className="error ">{this.state.errors["image"]}</span>
                                        <a href="mailto:advertise@biznexus.com">Need help making your ad?</a>
                                    </FormGroup>
                                </Row>}
                                {this.state.modalType === 'text' && <Row style={{paddingLeft: '15px' }}>
                                    <FormGroup style={{ width: '90%' }}>
                                        <Label htmlFor="name">Text <span style={{ color: 'red' }}>*</span>:</Label>
                                        <textarea name="link" id="link" className="textarea_test"
                                                onChange={(e) => this.setState({ text: e.target.value })}
                                                value={this.state.text} placeholder=""></textarea>
                                        <span className="error ">{this.state.errors["text"]}</span>
                                    </FormGroup>   
                                </Row>}
                                <Row style={{paddingLeft: '15px' }}>
                                    <FormGroup style={{ width: '90%' }}> 
                                        <Label htmlFor="name">Link to <span style={{ color: 'red' }}>*</span>:</Label>
                                        <Input type="text" name="link" id="link"
                                                onChange={(e) => this.setState({ linkTo: e.target.value })}
                                                value={this.state.linkTo} placeholder="https://..."/>
                                        <span className="error ">{this.state.errors["link"]}</span>
                                    </FormGroup>   
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={() => this.setState({isOpen:false})}>Cancel</Button>
                                <Button disabled={this.state.modalType === 'text' ? !this.state.text || !this.state.linkTo : !this.state.image || !this.state.linkTo} color="success" onClick={(e) => this.onCheckout(e)}>Checkout</Button>
                            </ModalFooter>
                        </Modal>
            </div>
        )
    }
}

// {moment(new Date(b.last_login_at)).format('LLL')}
