import React from 'react'

const FolderIcon = ({ color, fontSize }) => {
    return (
        <i style={{ fontSize: fontSize || 16 }}>
            <svg width="20" height={fontSize || "16"} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.3077 15.5001C1.80257 15.5001 1.375 15.3251 1.025 14.9751C0.675 14.6251 0.5 14.1975 0.5 13.6924V2.69244C0.5 2.18732 0.675 1.75977 1.025 1.40977C1.375 1.05977 1.80257 0.884766 2.3077 0.884766H8.38462L9.99998 2.50012H17.6923C18.1974 2.50012 18.625 2.67512 18.975 3.02512C19.325 3.37512 19.5 3.80268 19.5 4.30782V13.6924C19.5 14.1975 19.325 14.6251 18.975 14.9751C18.625 15.3251 18.1974 15.5001 17.6923 15.5001H2.3077ZM1.99998 2.69244V13.6924C1.99998 13.7821 2.02883 13.8558 2.08653 13.9135C2.14423 13.9712 2.21795 14.0001 2.3077 14.0001H17.6923C17.782 14.0001 17.8557 13.9712 17.9134 13.9135C17.9711 13.8558 18 13.7821 18 13.6924V4.30782C18 4.21807 17.9711 4.14434 17.9134 4.08664C17.8557 4.02894 17.782 4.00009 17.6923 4.00009H9.38653L7.77118 2.38474H2.3077C2.21795 2.38474 2.14423 2.41359 2.08653 2.47129C2.02883 2.52897 1.99998 2.60269 1.99998 2.69244ZM1.99998 2.69244V2.38474V14.0001V2.69244Z" fill={color || '#879FB7'}/>
            </svg>
        </i>
    )
}

export default FolderIcon
