import React from 'react';
import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    FormGroup,
    Input, UncontrolledTooltip
} from 'reactstrap';
import Geosuggest from '@ubilabs/react-geosuggest';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import classnames from 'classnames';
import _ from 'lodash';
import api from '../../services/api';
import stripeEnv from '../../constants/stripe';
import {trackEvent} from '../util';
import {withRouter} from "../../containers/withRouter/withRouter";
import {AuthContext} from "../../contexts/auth";
import {USER_TYPE} from "../../constants/userTypes";
import {SubscriptionLevels} from "../../constants/subscriptionLevels";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

class FeaturedModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            plans: [],
            listing: {},
            industries: [],
            industry: [],
            locations: [],
            level: null,
            techMedia: null,
            plan: { price: 0},
            errors: {},
            credits: 0,
        };
    }

    static contextType = AuthContext

    init = props => {
        const { listingId } = props;

        api.get('transaction/' + listingId, null, true)
            .then(listing => {
                this.setState({ listing });
            });
        api.get('promote/plans', null, true)
            .then(plans => {
                this.setState({ plans });
            });
        api.get('promote/credits', null, true)
            .then(credits => {
                this.setState({ credits: credits.featured_listing });
            });
        api.get('industry/legacy', null, false)
            .then(industries => {
                this.setState({ 
                    industries: industries.filter(i => i.label !== 'Tech & Media'),
                    techMedia: _.find(industries, { label : 'Tech & Media' })
                });
            });
    }

    componentWillReceiveProps(nextProps) {
        this.init(nextProps);
    }

    componentDidMount() {
        this.init(this.props);
    }

    createCheckoutSession = (planId, level, industry, locations) => {
        return api.post('promote/create-checkout-session', { level, planId, transaction_id: this.state.listing.id, industry, locations }, true);
    }

    getCurrentPlan = () => {
        const { level } = this.state;
        switch(level) {
            case 1: return _.find(this.state.plans, { id : stripeEnv.listing_first });
            case 2: return _.find(this.state.plans, { id : stripeEnv.listing_second });
            case 3: return _.find(this.state.plans, { id : stripeEnv.listing_third });
            case 4: return _.find(this.state.plans, { id : stripeEnv.listing_techmedia });
            default: return null;
        }
    }

    getLimitations = () => {
        const locationLimit = { 1: 4, 2: 2, 3: 1, 4: 0 };
        const indusrtyLimit = { 1: 4, 2: 2, 3: 1, 4: 0 };

        return {
            location: locationLimit[this.state.level] || 0,
            industry: indusrtyLimit[this.state.level] || 0,
        }
    }

    checkout = () => {

        let errors = {};
        if(!this.state.level) {
            return null;
        }

        this.setState({ errors });

        if((!this.state.industry.length || !this.state.locations.length) && this.state.level !== 4) {
            if(!this.state.industry.length) {
                errors.industry = 'Target Industies is required.'
            }
            if(!this.state.locations.length) {
                errors.location = 'Target Areas is required.'
            }
            this.setState({ errors });
            return null;
        }

        const industryId = this.state.industry.join(',');
        const locations = JSON.stringify(this.state.locations);

        const currentPlan = this.getCurrentPlan();
        const planId = currentPlan.id;
        trackEvent({ category: 'Ads', action: 'Boosted Listing' });
        if(this.state.credits > 0 || this.context.user.user_type_id === USER_TYPE.BROKER && this.context.user.premium_level >= SubscriptionLevels.PREMIUM) {
            return api.post('promote/use_credits', { 
                level: this.state.level, planId, transaction_id: this.state.listing.id, industry: industryId, locations
            }, true).then(() => {
                window.location.href = window.location.pathname + '?type=boosted_listing'
            })
        }

        this.createCheckoutSession(planId, this.state.level, industryId, locations)
            .then(res => {
                this.props.stripe
                    .redirectToCheckout({
                        sessionId: res.sessionId,
                    })
                    .then(r => {
                        if (r.error) {
                            console.log(r.error);
                        }
                    })
            });
    }

    handleGeosuggestChange = (location) => {
       this.setState({ errors: {} });
        if (location !== undefined) {
            let addressComponent = location.gmaps.address_components;
            if (addressComponent[0].types[0] === 'country') {
                this.refs.areaserved.update('');
                this.setState({ errors: { location: 'Country is not allowed' } });
                return;
            }
            this.refs.areaserved.update('');
            this.state.locations.push({
                lat: location.location.lat,
                lng: location.location.lng,
                label: location.label
            });
            this.setState({ locations: this.state.locations });
        }
    }

    removeClick = (e, i) => {
        this.state.locations.splice(e, 1);
        let dataAreaLists = [];
        dataAreaLists = this.state.locations;
        this.setState({locations: dataAreaLists});
    }

    addIndustry = (i) => {
        const limit = this.getLimitations();

        if(this.state.industry.includes(i.value)) {
            const industry = this.state.industry.filter(f => f !== i.value);
            this.setState({ industry });
            return;
        }        

        if(this.state.industry.length < limit.industry) {
            this.state.industry.push(i.value);
            this.setState({ industry: this.state.industry });
        }
    }

    onChangeLevel = level => { 
        this.setState({
            level,
            industry: level === 4 ? [this.state.techMedia.value] : [],
            locations: [],
        })
    }

    render() {
        const currentPlan = this.getCurrentPlan();
        const limit = this.getLimitations();

        return (
            <div>
                <ModalHeader>
                    <p>Promote {this.props.listingName}</p>
                    <span style={{ fontSize: '16px', fontWeight: 100 }}>Promoted listings are billed monthly. Pay with a credit card. Cancel anytime.</span>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <p>Choose your package <span style={{ color: 'red' }}>*</span>: </p>
                            <div style={{ display: 'inline-flex' }}>
                                <a 
                                href="#/"
                                    className={`${this.state.level === 1 ? 'btn btn-success' : 'btn btn-secondary'}`}
                                    onClick={() => this.onChangeLevel(1)} 
                                    style={{ marginLeft: '10px' }}>
                                        First Position Boost 
                                        <i style={{ margin: '0px 3px 3px' }} id="gold" className="sprite i-question"></i>
                                        <UncontrolledTooltip placement="bottom" target="gold"
                                                            autohide={false}
                                                            innerClassName="areas-tooltip">
                                            Claim the top spot in the search results. Choose up to four locations, and up to four industries for ad placement.
                                        </UncontrolledTooltip>
                                </a>
                                <a href="#/" className={`${this.state.level === 2 ? 'btn btn-success' : 'btn btn-secondary'}`} onClick={() => this.onChangeLevel(2)} style={{ marginLeft: '10px' }}>
                                    Second Position Boost
                                    <i style={{ margin: '0px 3px 3px' }} id="silver" className="sprite i-question"></i>
                                        <UncontrolledTooltip placement="bottom" target="silver"
                                                            autohide={false}
                                                            innerClassName="areas-tooltip">
                                            Claim the 2nd spot in the search results. Choose up to two locations, and up to two industries for ad placement.
                                        </UncontrolledTooltip>
                                </a>
                                <a href="#/" className={`${this.state.level === 3 ? 'btn btn-success' : 'btn btn-secondary'}`} onClick={() => this.onChangeLevel(3)} style={{ marginLeft: '10px' }}>
                                    Third Position Boost
                                    <i style={{ margin: '0px 3px 3px' }} id="bronze" className="sprite i-question"></i>
                                        <UncontrolledTooltip placement="bottom" target="bronze"
                                                            autohide={false}
                                                            innerClassName="areas-tooltip">
                                            Claim the 3rd spot in the search results. Choose one location and one industry for ad placement.
                                        </UncontrolledTooltip>
                                </a>
                                {/*<a href="#/" className={`${this.state.level === 4 ? 'btn btn-success' : 'btn btn-secondary'}`} onClick={() => this.onChangeLevel(4)} style={{ marginLeft: '10px' }}>*/}
                                {/*    Tech & Media*/}
                                {/*    <i style={{ margin: '0px 3px 3px' }} id="tech" className="sprite i-question"></i>*/}
                                {/*        <UncontrolledTooltip placement="bottom" target="tech"*/}
                                {/*                            autohide={false}*/}
                                {/*                            innerClassName="areas-tooltip">*/}
                                {/*             Tech & Media industry, all locations included*/}
                                {/*        </UncontrolledTooltip>*/}
                                {/*</a>*/}
                            </div>
                            
                            <div style={{ marginTop: '15px' }}>
                                <p>Target Industries:</p>
                                <div>
                                    {this.state.industries.map((i, k) =>
                                        <a href="#/" onClick={() => this.addIndustry(i, k)} key={k} className={classnames({ 'btn btn-success': this.state.industry.includes(i.value), 'btn btn-secondary': !this.state.industry.includes(i.value), 'disabled': !this.state.industry.includes(i.value) && this.state.industry.length >= limit.industry })}  style={{ display: 'inline-block', margin: '3px' }}>{i.label}</a>
                                    )}
                                    <a href="#/" onClick={() => this.onChangeLevel(4)} className={`${this.state.level === 4 ? 'btn btn-success' : 'btn btn-secondary'}`}  style={{ display: 'inline-block', margin: '3px' }}>
                                        Tech & Media
                                    </a>
                                </div>
                                <span className="error ">{this.state.errors["industry"]}</span>
                            </div>

                            {this.state.level !== 4 && <div style={{ marginTop: '15px' }}>
                                <p>Target Areas:
                                <i style={{ margin: '8px' }} id="Areas" className="sprite i-question"></i>
                                        <UncontrolledTooltip placement="bottom" target="Areas"
                                                            autohide={false}
                                                            innerClassName="areas-tooltip">
                                            Choose location by city or county. Location not relevant for internet-based companies.
                                        </UncontrolledTooltip>
                                </p>
                                <FormGroup>
                                    <Geosuggest
                                        inputClassName="search-input form-control"
                                        id="location"
                                        name="location"
                                        ref="areaserved"
                                        types={['(regions)']}
                                        placeholder="Search by location"
                                        disabled={(this.state.locations.length >= limit.location && "disabled")}
                                        onSuggestSelect={this.handleGeosuggestChange}
                                        initialValue={''}
                                    />
                                    <div className="location-tags">
                                        {this.state.locations.length > 0 && this.state.locations.map((locations, i) => {
                                            if (!locations) {
                                                return ''
                                            }
                                            return (
                                                <span key={`locations-${i}`} className="selected-location">
                                                    <span onClick={this.removeClick.bind(this, i)} className="location-value-icon">×</span>
                                                    <span className="location-value-label">{locations.label}
                                                        <Input type="checkbox"
                                                            name="location"
                                                            id={"locations-" + i}
                                                            value={locations.label}
                                                            defaultChecked="true"/> 
                                                    </span>
                                                </span>
                                            )
                                        })}

                                                </div>
                                    <span className="error">{this.state.errors["location"]}</span>
                                </FormGroup>
                            </div>}
                        </Col>
                        <Col sm={2}>
                            
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {this.state.credits > 0 ?
                        <div>
                            Total: <span style={{ textDecoration: 'line-through' }}>${currentPlan ? currentPlan.amount/100 : '0'}/mo</span>
                            <span style={{ color: 'green' }}> Free ({this.state.credits} credits)</span>
                        </div>
                    : this.context.user.user_type_id === USER_TYPE.BROKER && this.context.user.premium_level >= SubscriptionLevels.PREMIUM
                        ? <div>Total: <span style={{ textDecoration: 'line-through' }}>${currentPlan ? currentPlan.amount/100 : '0'}/mo</span>
                                <span style={{ color: 'green' }}> Free access</span></div>
                        : <div>Total: ${currentPlan ? currentPlan.amount/100 : '0'}/mo</div>
                    }
                    <Button color="" onClick={this.props.onClose}>Cancel</Button>
                    <Button disabled={this.state.level === null} color="success" onClick={() => this.checkout()}>Boost Now!</Button>
                </ModalFooter>
            </div>
        )
    }
}

const PromoteContainer = (props) => (
    <Elements stripe={stripePromise}>
        <ElementsConsumer>
            {({stripe, elements}) => (
                <FeaturedModal {...props} stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    </Elements>
  );

export default withRouter(PromoteContainer);
