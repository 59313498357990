import React, {useState} from 'react'
import {GoogleMap, InfoWindow, Marker, useJsApiLoader} from '@react-google-maps/api';

const containerStyle = {
    height: '400px',
    position: 'relative',
    width: '100%'
};

const center = {
    lat: 39.50,
    lng: -98.35
};

function MapContainer({markers}) {
    const [state, setState] = useState({})
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD_tM2rsjEvLfWKxFttkumFkydEeJNVWlM"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    const onMarkerClick = (marker) => {
        setState({
            ...state,
            activeMarker: marker,
            selectedPlace: {
                lat: marker.latitude,
                lng: marker.longitude,
                name: marker.location,
            },
            showingInfoWindow: true
        })
    };
    const onInfoWindowClose = () =>
        setState({
            ...state,
            activeMarker: null,
            selectedPlace: null,
            showingInfoWindow: false
        });
    const onMapClicked = () => {
        if (state.showingInfoWindow)
            setState({
                ...state,
                activeMarker: null,
                selectedPlace: null,
                showingInfoWindow: false
            });
    };

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            onClick={onMapClicked}
            onLoad={onLoad}
            onUnmount={onUnmount}
            className={'map'}
            center={{
                lat: 39.50,
                lng: -98.35
            }}
            zoom={3}
        >
            {
                markers.map((areaserved, i) => {
                    let locationsplit = areaserved.location;
                    locationsplit = locationsplit.split(/,/);

                    return (
                        // @ts-ignore
                        <Marker key={`areaservedMarker-${i}`}
                            // @ts-ignore
                                name={locationsplit[0]}
                                onClick={() => onMarkerClick(areaserved)}
                                position={{ lat: areaserved.latitude, lng: areaserved.longitude }}
                        >
                        </Marker>

                    )
                })
            }
            {
                state.showingInfoWindow &&
                <InfoWindow
                    position={{ lat: state.activeMarker.latitude, lng: state.activeMarker.longitude }}
                    marker={state.activeMarker}
                    onClose={onInfoWindowClose}
                >
                    <div>
                        <h6 className="text-blue">{state.selectedPlace?.name}</h6>
                    </div>
                </InfoWindow>
            }
        </GoogleMap>
    ) : <></>
}

export default React.memo(MapContainer)
