import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import {withRouter} from "../containers/withRouter/withRouter";



class ChangePassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
			errors: {}
		};
		this.SubmitLogin = this.SubmitLogin.bind(this);
	}
	handleValidation() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
		
		//newpass
		// if (!fields["passwordlogin"]) {
		// 	formIsValid = false;
		// 	errors["passwordlogin"] = "Please enter your current password.";
		// }
		// if (typeof fields["passwordlogin"] !== "undefined") {
		// 	if (!fields["passwordlogin"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)) {
		// 		formIsValid = false;
		// 		errors["passwordlogin"] = "Passwords must contain at least 6 characters, including uppercase, lowercase letters and numbers.";
		// 	}
		// }

		if (!fields["newpasswordlogin"]) {
			formIsValid = false;
			errors["newpasswordlogin"] = "Please enter your new password.";
		}
		if (typeof fields["newpasswordlogin"] !== "undefined") {
			if (!fields["newpasswordlogin"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)) {
				formIsValid = false;
				errors["newpasswordlogin"] = "Passwords must contain at least 6 characters, including uppercase, lowercase letters and numbers.";
			}
		}

		if (!fields["repasswordlogin"]) {
			formIsValid = false;
			errors["repasswordlogin"] = "Please re-enter your new password.";
		}else if(fields["repasswordlogin"]!==fields["newpasswordlogin"]){
			formIsValid = false;
			errors["repasswordlogin"] = "Please enter same password.";
		}else{
			if (typeof fields["repasswordlogin"] !== "undefined") {
				if (!fields["repasswordlogin"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)) {
					formIsValid = false;
					errors["repasswordlogin"] = "Passwords must contain at least 6 characters, including uppercase, lowercase letters and numbers.";
				}
			}
		}
		
		this.setState({ errors: errors });
		return formIsValid;
	}
	handleChange(field, e) {
		let fields = this.state.fields;
		fields[field] = e.target.value;
		this.setState({ fields });
	}
	componentDidMount() {
		document.getElementById('loaderpopup').setAttribute("style", "display: none;");
	}
	SubmitLogin = event => {
		event.preventDefault();
		if (this.handleValidation()) {			
			document.getElementById('loaderpopup').setAttribute("style", "display: block;");
			let emailId = localStorage.getItem("email");
			let password = this.state.fields["newpasswordlogin"];
			let jsonsetLoginData = JSON.stringify({
				"email": emailId,
				"password": password,
				"ip_address": localStorage.getItem("ip")
			});
			//console.log(jsonsetLoginData);
			fetch(process.env.REACT_APP_API_ROOT + 'user/changepassword', {
				method: 'POST',
				body: jsonsetLoginData,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					"Access-Control-Allow-Origin": "*"
				},
			}).then(function (res) {
				if (res.status < 200 || res.status >= 300) {
					//console.log("Error 401");
					//console.log(res);
					//return res.json().then(err => {throw err;});
					throw res;
					//throw Error(.statusText);
				} else { return res.json() }
			})
				.then(
					(res) => {
						//console.log(res);
						if (res.data.status === true) {
							localStorage.setItem('token', res.data.token);
							localStorage.setItem('email', emailId);
							localStorage.setItem('forgotPassword', false);
							localStorage.setItem('loggedIn', true);
							document.getElementById('loaderpopup').setAttribute("style", "display: none;");
							this.props.history.push('/user/profile');
							//console.log(res.data.token);
						}
						else {
							let errors = {};
							errors["passwordlogin"] = res.data.message;
							this.setState({ errors: errors });
							document.getElementById('loaderpopup').setAttribute("style", "display: none;");
						}
					},
					(error) => {
						this.setState({
							//error
						});
					}
				)
		} else {
			//console.log("Form has errors.")
		}
	}

	render() {
		return (
			<section className="signup-section">
				<div className="loader bg-white" id="loaderpopup"><h3>Please Wait...</h3><div className="spiner"></div></div>
				<img src={process.env.PUBLIC_URL + '/images/biznexus-logo.png'} style={{ width: 250 }} alt="logo" className="d-block m-auto" />
				
				<div className="signup-bx">
					<h4 className="text-center">Change Password</h4>
					<span className="error ">{this.state.errors["errorMessage"]}</span>
					<Form onSubmit={this.SubmitLogin}>
						<FormGroup>
							<Label for="newpasswordlogin">New Password</Label>
							<Input type="password" name="newpasswordlogin" id="newpasswordlogin" placeholder="password" ref="newpasswordlogin" onChange={this.handleChange.bind(this, "newpasswordlogin")} value={this.state.fields["newpasswordlogin"]} />
							<span className="error ">{this.state.errors["newpasswordlogin"]}</span>
						</FormGroup>

						<FormGroup>
							<Label for="repasswordlogin">Re-Enter Password</Label>
							<Input type="password" name="repasswordlogin" id="repasswordlogin" placeholder="password" ref="repasswordlogin" onChange={this.handleChange.bind(this, "repasswordlogin")} value={this.state.fields["repasswordlogin"]} />
							<span className="error ">{this.state.errors["repasswordlogin"]}</span>
						</FormGroup>

						
						<Button type="submit" className="btn-bluedark">Change Password</Button>
					</Form>
				</div>
				<p className="text-center link-login">Already a BizNexus Member?  <Link to={'/login'}>Sign In</Link></p>
			</section>
		);
	}
}
export default withRouter(ChangePassword);
