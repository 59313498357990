import React from "react";
import './search-loader.css'

const SearchLoader = () => (
    <div className="search-loader">
        <div className="loader_listings_page" id="loaderlistings">
            <div className="spinner-wrapper">
                <div className="spiner"/>
            </div>
        </div>
    </div>
)

export default SearchLoader
