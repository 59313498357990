const stage = {
    premium_month: 'price_1GwtFXBgjGJy2wT9i3lUymLf',
    premium_annual: 'price_1KtUI0BgjGJy2wT96iaNKyEa',
    // basic_price: 'price_1MCaUcBgjGJy2wT9HFmwun3X',
    // basic_price_annual: 'price_1MCaW7BgjGJy2wT9Sd6kEEHm',
    premium_pro: 'price_1HmMJBBgjGJy2wT9Tr9ntSkz',
    premium_pro_annual: 'price_1HpFzuBgjGJy2wT9m39FXkFo',

    listing_first: 'plan_H65oYQa5KrJa0c',
    listing_second: 'plan_H65oShymkyA4Pr',
    listing_third: 'plan_H65ocFVxJO9coc',
    listing_techmedia: 'plan_HHfhiierJqL7Hv',

    profile_first: 'price_1MeSoEBgjGJy2wT9f03DAHIh',
    profile_second: 'price_1KOiEABgjGJy2wT9yFKzp1qH',
    profile_third: 'price_1KOiDtBgjGJy2wT91JU5TdWW',
    profile_techmedia: 'plan_HHbc058l48ha5Y',

    ad_search_first: 'plan_GwI3iBjVKkCfJI',
    ad_search_second: 'plan_GwI5YGSKtO3BTz',

    home_banner: 'plan_GwI9SwfOoWdvHR',
    home_text: 'plan_GwI768L0Mb3Vs0',

    search_text: 'plan_GwHwvzy338DCat',
};

const prod = {
    premium_month: 'price_1H2aFHBgjGJy2wT9eT5pImEX',
    premium_annual: 'price_1KtULaBgjGJy2wT9gEBRTXwQ',
    // basic_price: 'price_1MCaZWBgjGJy2wT9s5V7kygP',
    // basic_price_annual: 'price_1MCaZVBgjGJy2wT94j54a0O5',
    premium_pro: 'price_1HtrInBgjGJy2wT9XrXDxnNW',
    premium_pro_annual: 'price_1HtrszBgjGJy2wT9SmPLPB0t',

    listing_first: 'plan_HLKcaSW6gyJtxY',
    listing_second: 'plan_HLKc0Yl4MJY7AJ',
    listing_third: 'plan_HLKcfSCxAsWKK8',
    listing_techmedia: 'plan_HLKdnbV1WqDnzO',

    profile_first: 'price_1MeSsBBgjGJy2wT9w72cacDl',
    profile_second: 'price_1KQtS2BgjGJy2wT94ONZCo97',
    profile_third: 'price_1KQtRpBgjGJy2wT9BKBPxJKO',
    profile_techmedia: 'plan_HLKViXk29Y7cry',

    ad_search_first: 'plan_HG47e9lnSnYLEN',
    ad_search_second: 'plan_HG46iLNIfVnGli',

    home_banner: 'plan_HG485clG9Qcg16',
    home_text: 'plan_HG47o0ahbPLSnn',

    search_text: 'plan_HG45EgwCNTai6q', 

};

const currentEnv = window.location.host === 'app.biznexus.com' ? prod : stage;

export default currentEnv;
