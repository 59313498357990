import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {
    Button,
    Collapse,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalFooter,
    ModalHeader,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    UncontrolledDropdown,
    DropdownItem,
    Row,
    Col,
    Input,
    ModalBody,
    FormGroup,
} from 'reactstrap';
import api from '../services/api';
import {Helmet} from "react-helmet";
import { ADMIN_ROLES, USER_TYPE } from "../constants/userTypes";
import {headerLinks} from "../constants/userLinks";
import CustomAlert from "./alerts/CustomAlert";
import {SubscriptionLevels} from "../constants/subscriptionLevels";
import {AuthContext} from "../contexts/auth";
import {withRouter} from "../containers/withRouter/withRouter";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            linkurl: '',
            id: null,
            linkedinModal: false,
            linkedin_url: '',
            errors: {},
            search: '',
        };
        this.Navtoggle = this.Navtoggle.bind(this);
    }
    static contextType = AuthContext

    Navtoggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleChangeLink = (e) => {
        if (this.props.profileupdate === 1) {
            e.preventDefault();
            this.props.ProfileUpdatetoggle();
            this.setState({linkurl: e.target.getAttribute("href")});
        }
    }

    cutUserName(name, onyFirstName = false) {
        if (!name) {
            return '';
        }
        const spit_username = name.split(' ');
        if (spit_username.length > 1) {
            if (onyFirstName) {
                return spit_username[0];
            }
            return spit_username.slice(0, spit_username.length - 1) + ' ' + spit_username[spit_username.length - 1].slice(0, 1) + '.'
        } else {
            return name;
        }
    }

    updateLinkedin = () => {
        let formIsValid = true;
        let errors = {};

        if (!this.state.linkedin_url) {
            formIsValid = false;
            errors["linkedin_url"] = "Please enter your LinkedIn URL";
        }
        if (this.state.linkedin_url && !this.state.linkedin_url.match(/linkedin/)) {
            formIsValid = false;
            errors["linkedin_url"] = "Please enter your LinkedIn URL";
        }

        if (formIsValid) {
            api.post('user/update_linkedin', {linkedin_url: this.state.linkedin_url}, true).then(() => {
                this.setState({linkedinModal: false});
            })
        }

        this.setState({errors: errors});
    }

    onSearch = e => {
        e.preventDefault();

        if (window.location.pathname === '/advanced-search/') {
            window.location.href = `/advanced-search?name=${this.state.search}`
        } else {
            this.props.history.push(`/advanced-search?name=${this.state.search}`);
        }
    }

    render() {
        return (
            <header className="header navbar_header">
                <Helmet title={'BizNexus'}/>
                <CustomAlert/>
                {!this.props.pendingUser && this.context.headerAd && <div style={{
                    left: '0',
                    top: '0px',
                    right: '0%',
                    bottom: 'auto',
                    zIndex: 1001,
                    textAlign: 'center',
                    display: 'flex',
                    width: '100%',
                    height: '40px',
                    backgroundColor: 'rgb(56, 144, 218)',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div style={{textAlign: 'center', color: '#FFF'}}>{this.context.headerAd.body} <a
                        style={{color: '#FFF', textDecoration: 'underline', fontWeight: '800'}}
                        href={this.context.headerAd.link}>Learn more →</a></div>
                </div>}

                <Navbar className={'header-navbar'} expand="lg" style={{backgroundColor: '#282828', padding: 0}}>

                    <NavbarBrand href="https://www.biznexus.com/" onClick={this.handleChangeLink.bind()}><img
                        src={process.env.PUBLIC_URL + '/images/logo-new-black.png'} alt="logo"
                        width="150"/></NavbarBrand>
                    {(this.context.tokenValue !== '' && this.context.loggedInValue === "true") &&
                        <React.Fragment>

                            <div style={{position: 'relative'}}>
                                <NavbarToggler onClick={this.Navtoggle}/>
                                {
                                    this.context.unread > 0
                                        ? <span className="message-badge burger-view">{this.context.unread}</span>
                                        : ''
                                }
                            </div>
                            <Collapse isOpen={this.state.isOpen} navbar>
                                {
                                    this.context.user_type_id && this.context.user_type_id !== USER_TYPE.BROKER && this.context.user.premium_level >= SubscriptionLevels.PREMIUM &&
                                    <form onSubmit={this.onSearch}
                                          className="input-group header-group d-none d-xl-flex "
                                          style={{maxWidth: 300}}>
                                        <input style={{backgroundColor: '#ececec'}}
                                               className="form-control py-2 border-right-0 search-header" type="text"
                                               onChange={(e) => this.setState({search: e.target.value})}
                                               value={this.state.search} maxLength={100}
                                               placeholder={'Search Marketplace'}/>
                                        <span className="input-group-append"
                                              style={{backgroundColor: '#ececec', borderRadius: 25}}>
                      <button style={{backgroundColor: '#ececec'}}
                              className="btn btn-outline-secondary border-end-0 border" type="submit">
                      <i className="fa fa-search"/>
                  </button>
                  </span>
                                    </form>
                                }

                                <Nav className="ms-auto" navbar style={{marginRight: 10}}>
                                    {
                                        (this.props.pendingUser ? [] : headerLinks[this.context.user_type_id])?.map(({
                                                                                                                       title,
                                                                                                                       link,
                                                                                                                       icon,
                                                                                                                       options
                                                                                                                   }) => options
                                            ? (<UncontrolledDropdown nav inNavbar key={title}>
                                                <DropdownToggle nav caret className="link-hover">
                                                    {title}
                                                </DropdownToggle>
                                                <DropdownMenu right style={{width: '250px'}}>
                                                    {
                                                        options.map(option => (
                                                            <DropdownItem key={option.link}>
                                                                {
                                                                    option.link.includes('http')
                                                                        ? (<a
                                                                            key={option.link}
                                                                            href={option.link}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            className="nav-link"
                                                                        >
                                                                            {!!option.icon && <i className={option.icon}
                                                                                                 style={{
                                                                                                     display: 'inline-flex',
                                                                                                     marginRight: '3px'
                                                                                                 }}/>}
                                                                            <span>{option.title}</span>
                                                                        </a>)
                                                                        : (<NavLink
                                                                            exact
                                                                            key={option.link}
                                                                            to={option.link}
                                                                            activeClassName="link-hover-active"
                                                                            className="nav-link"
                                                                        >
                                                                            {!!option.icon && <i className={option.icon}
                                                                                                 style={{
                                                                                                     display: 'inline-flex',
                                                                                                     marginRight: '3px'
                                                                                                 }}/>}
                                                                            <span>{option.title}</span>
                                                                        </NavLink>)
                                                                }
                                                            </DropdownItem>
                                                        ))
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>)
                                            : (<NavItem key={title}>
                                                {
                                                    link.includes('http')
                                                        ? (<a
                                                            href={link}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="nav-link link-hover text-center"
                                                        >
                                                            {!!icon && <i className={icon}/>}
                                                            {title}
                                                        </a>)
                                                        : (<NavLink
                                                            exact
                                                            to={link}
                                                            activeClassName="link-hover-active"
                                                            className="nav-link link-hover text-center"
                                                        >
                                                            {!!icon && <i className={icon}/>} {title}
                                                        </NavLink>)
                                                }
                                            </NavItem>))
                                    }
                                    {
                                        !this.props.pendingUser &&
                                        <NavItem>
                                            <NavLink
                                                exact
                                                to="/user/messages" className="nav-link link-hover message-header-link"
                                                     activeClassName="link-hover-active">
                                                <i style={{fontSize: 24}} className="far fa-envelope"/>
                                                {
                                                    this.context.unread > 0
                                                        ? <span className="message-badge">{this.context.unread}</span>
                                                        : ''
                                                }
                                            </NavLink>
                                        </NavItem>
                                    }
                                    {(!this.props.pendingUser && !this.context.user.premium && this.context.user.name && this.context.user_type_id !== USER_TYPE.BROKER) &&
                                        <NavItem>
                                            <a href="/user/subscription" className="nav-link link-hover">
                                                <i class="fas fa-star" style={{color: 'gold'}}/> Upgrade
                                            </a>
                                        </NavItem>}


                                    <UncontrolledDropdown nav inNavbar>
                                        {this.context.user.name && <DropdownToggle caret style={{
                                            backgroundColor: '#282828',
                                            borderColor: '#282828'
                                        }}>

                                            <img src={this.context.profile_pic} alt=""
                                                 width="50" height="50"
                                                 onError={
                                                     (e) => {
                                                         //@ts-ignore
                                                         if (!e.target.src.includes('images/thumbnail-small.gif')) {
                                                             e.target.src = `/images/thumbnail-small.gif`;
                                                         }
                                                     }
                                                 }
                                                 className="img-fluid rounded-circle z-depth-0"/>
                                            <div style={{color: 'white', paddingLeft: 20, paddingRight: 10}}>
                                                <div style={{
                                                    maxWidth: 100,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}><b style={{
                                                    fontSize: 16,
                                                    fontWeight: 800
                                                }}>{this.cutUserName(this.context.user.name, true)}</b></div>
                                                {!this.props.pendingUser &&
                                                    this.context.user_type_id !== USER_TYPE.BROKER &&
                                                    this.context.user.premium_level >= SubscriptionLevels.PREMIUM &&
                                                    <div>
                                                        <span className="badge text-dark"
                                                              style={{
                                                                  backgroundColor: 'gold',
                                                                  fontSize: 12,
                                                                  padding: '0.25em 0.6rem',
                                                                  borderRadius: '1rem'
                                                              }}>Premium plan</span>
                                                    </div>
                                                }
                                            </div>


                                        </DropdownToggle>}
                                        {
                                            !this.props.pendingUser
                                                ?
                                                <DropdownMenu right style={{
                                                    width: 465,
                                                    maxWidth: 'calc(100vw - 30px)',
                                                    overflow: 'hidden'
                                                }}>


                                                    <Row className="" style={{padding: 15, paddingTop: 0}}>
                                                        <Col sm="6">
                                                            <div className="h3 mt-3" style={{paddingLeft: 10}}>My
                                                                BizNexus
                                                            </div>
                                                            <div className="mt-3">
                                                                <DropdownItem style={{paddingLeft: 10}}><Link
                                                                    style={{paddingLeft: 0}} target="_blank"
                                                                    to={this.props.slugUrl !== undefined ? "/profile/" + this.props.slugUrl : "/profile/" + this.context.id}
                                                                    className="nav-link"
                                                                    onClick={this.handleChangeLink.bind()}>Public
                                                                    Profile</Link></DropdownItem>
                                                                <DropdownItem style={{paddingLeft: 10}}><Link
                                                                    style={{paddingLeft: 0}}
                                                                    to={"/user/favorites/preferences"}
                                                                    className="nav-link">Match
                                                                    Preferences</Link></DropdownItem>
                                                                {
                                                                    this.context.user_type_id !== USER_TYPE.BROKER &&
                                                                    <DropdownItem style={{paddingLeft: 10}}><Link
                                                                        style={{paddingLeft: 0}} to={"/user/favorites"}
                                                                        className="nav-link">{'My Targets'}</Link></DropdownItem>
                                                                }
                                                                {
                                                                    this.context.user_type_id === USER_TYPE.BROKER &&
                                                                    <DropdownItem style={{paddingLeft: 10}}><Link
                                                                        style={{paddingLeft: 0}} to="/user/listings/suggested_buyers"
                                                                        className="nav-link">Suggested Buyers</Link></DropdownItem>
                                                                }
                                                                <DropdownItem style={{paddingLeft: 10}}><Link
                                                                    style={{paddingLeft: 0}} to={"/user/listings"}
                                                                    className="nav-link">My Deals</Link></DropdownItem>
                                                                <DropdownItem style={{paddingLeft: 10}}><Link
                                                                    style={{paddingLeft: 0}} to={"/user/ads"}
                                                                    className="nav-link">My Ads</Link></DropdownItem>
                                                                <DropdownItem style={{paddingLeft: 10}}><Link
                                                                    style={{paddingLeft: 0}} to={"/user/integrations"}
                                                                    className="nav-link">My
                                                                    Integrations</Link></DropdownItem>

                                                            </div>
                                                        </Col>
                                                        <Col sm="6">
                                                            <div className="h3 mt-3" style={{paddingLeft: 10}}>Settings
                                                            </div>
                                                            <div className="mt-3">
                                                                {this.context.role !== ADMIN_ROLES.NotAdmin &&
                                                                    <DropdownItem style={{paddingLeft: 10}}><Link
                                                                        style={{paddingLeft: 0}} to={"/console"}
                                                                        className="nav-link">Console</Link></DropdownItem>
                                                                }
                                                                <DropdownItem style={{paddingLeft: 10}}><Link
                                                                    style={{paddingLeft: 0}} to={"/user/profile"}
                                                                    className="nav-link">Profile
                                                                    settings</Link></DropdownItem>
                                                                {
                                                                    this.context.user_type_id !== USER_TYPE.BROKER && (
                                                                        <DropdownItem style={{paddingLeft: 10}}><Link
                                                                            style={{paddingLeft: 0}} to={"/user/subscription"}
                                                                            className="nav-link">Subscription</Link></DropdownItem>
                                                                    )
                                                                }
                                                                <DropdownItem style={{paddingLeft: 10}}><Link
                                                                    style={{paddingLeft: 0}}
                                                                    to={"/user/account/billing"}
                                                                    className="nav-link">Billing</Link></DropdownItem>
                                                                <DropdownItem style={{paddingLeft: 10}}><a
                                                                    style={{paddingLeft: 0}} target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={this.context.user_type_id === 3 ? 'https://www.biznexus.com/newsletter-for-intermediaries' : 'https://www.biznexus.com/newsletter'}
                                                                    className="nav-link">Newsletter</a> </DropdownItem>
                                                                <DropdownItem style={{paddingLeft: 10}}><Link
                                                                    style={{paddingLeft: 0}} to={"/logout"}
                                                                    className="nav-link"
                                                                    onClick={this.handleChangeLink.bind()}>Sign
                                                                    Out</Link></DropdownItem>
                                                            </div>
                                                        </Col>
                                                    </Row>


                                                </DropdownMenu>
                                                : <DropdownMenu right style={{
                                                    width: 245,
                                                    maxWidth: 'calc(100vw - 30px)',
                                                    overflow: 'hidden'
                                                }}>


                                                    <Row className="" style={{padding: 15, paddingTop: 0}}>
                                                        <Col sm="12">
                                                            <div className="mt-3">
                                                                {this.context.role !== ADMIN_ROLES.NotAdmin &&
                                                                    <DropdownItem style={{paddingLeft: 10}}><Link
                                                                        style={{paddingLeft: 0}} to={"/console"}
                                                                        className="nav-link">Console</Link></DropdownItem>
                                                                }
                                                                <DropdownItem style={{paddingLeft: 10}}><Link
                                                                    style={{paddingLeft: 0}} target="_blank"
                                                                    to={this.props.slugUrl !== undefined ? "/profile/" + this.props.slugUrl : "/profile/" + this.context.id}
                                                                    className="nav-link"
                                                                    onClick={this.handleChangeLink.bind()}>Public
                                                                    Profile</Link></DropdownItem>
                                                                {
                                                                    this.context.user_type_id === USER_TYPE.BROKER &&
                                                                    <DropdownItem style={{paddingLeft: 10}}><Link
                                                                        style={{paddingLeft: 0}} to="/user/listings/suggested_buyers"
                                                                        className="nav-link">Suggested Buyers</Link></DropdownItem>
                                                                }
                                                                <DropdownItem style={{paddingLeft: 10}}><Link
                                                                    style={{paddingLeft: 0}} to={"/user/profile"}
                                                                    className="nav-link">Profile
                                                                    settings</Link></DropdownItem>
                                                                <DropdownItem style={{paddingLeft: 10}}><a
                                                                    style={{paddingLeft: 0}} target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={this.context.user_type_id === 3 ? 'https://www.biznexus.com/newsletter-for-intermediaries' : 'https://www.biznexus.com/newsletter'}
                                                                    className="nav-link">Newsletter</a> </DropdownItem>
                                                                <DropdownItem style={{paddingLeft: 10}}><Link
                                                                    style={{paddingLeft: 0}} to={"/logout"}
                                                                    className="nav-link"
                                                                    onClick={this.handleChangeLink.bind()}>Sign
                                                                    Out</Link></DropdownItem>
                                                            </div>
                                                        </Col>
                                                    </Row>


                                                </DropdownMenu>
                                        }
                                    </UncontrolledDropdown>
                                </Nav>
                            </Collapse>
                        </React.Fragment>
                    }
                    {!this.context.tokenValue &&
                        <React.Fragment>
                            <NavbarToggler onClick={this.Navtoggle}/>
                            <Collapse isOpen={this.state.isOpen} navbar>
                                <Nav className="ms-auto" navbar>

                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret className="link-hover">
                                            Popular
                                        </DropdownToggle>
                                        <DropdownMenu right style={{width: '250px'}}>
                                            <DropdownItem><a href="/advanced-search/?page=1" className="nav-link">Most
                                                Recent </a> </DropdownItem>
                                            <DropdownItem><a
                                                href="/advanced-search/?date_added=all&industry_id=1293401&page=1"
                                                className="nav-link">Tech Deals</a> </DropdownItem>
                                            <DropdownItem><a
                                                href="/advanced-search/?cashflow_min=1000000&ebitda_min=1000000&date_added=all&page=1"
                                                className="nav-link">Middle Market</a> </DropdownItem>
                                            <DropdownItem><a
                                                href="/advanced-search/?cashflow_min=500000&ebitda_min=500000&date_added=all&page=1"
                                                className="nav-link">Search Fund Deals</a> </DropdownItem>
                                            <DropdownItem><a
                                                href="/advanced-search/?page=1&price_max=50000&revenue_max=500000"
                                                className="nav-link">Under $50k</a> </DropdownItem>
                                            <DropdownItem><a href="/advanced-search/?name=home-based&page=1"
                                                             className="nav-link">Home Based</a> </DropdownItem>
                                            <DropdownItem><a href="/advanced-search/?name=medical&page=1"
                                                             className="nav-link">Medical</a> </DropdownItem>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret className="link-hover">
                                            Resources
                                        </DropdownToggle>
                                        <DropdownMenu right style={{width: '350px'}}>

                                            <DropdownItem><a target="_blank" rel="noopener noreferrer"
                                                             href={this.context.user_type_id === 3 ? 'https://www.biznexus.com/newsletter-for-intermediaries' : 'https://www.biznexus.com/newsletter'}
                                                             className="nav-link">Newsletter</a> </DropdownItem>
                                            <DropdownItem><a target="_blank" rel="noopener noreferrer"
                                                             href="https://www.biznexus.com/blog"
                                                             className="nav-link">Blog</a> </DropdownItem>


                                            <DropdownItem><a href="https://podcast.biznexus.com/"
                                                             className="nav-link">Podcast </a> </DropdownItem>
                                            <DropdownItem><a href="https://community.biznexus.com/events"
                                                             className="nav-link">Events </a> </DropdownItem>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret className="link-hover">
                                            Business Acquisition
                                        </DropdownToggle>
                                        <DropdownMenu right style={{width: '250px'}}>
                                            <DropdownItem><a target="_blank" rel="noopener noreferrer"
                                                             href="https://www.biznexus.com/concierge-services"
                                                             className="nav-link">Concierge Deal Origination</a>
                                            </DropdownItem>

                                            <DropdownItem><a target="_blank" rel="noopener noreferrer"
                                                             href="https://www.biznexus.com/acquisition-financing"
                                                             className="nav-link">Acquisition Financing</a>
                                            </DropdownItem>
                                            <DropdownItem><a target="_blank" rel="noopener noreferrer"
                                                             href="https://www.biznexus.com/due-diligence"
                                                             className="nav-link">Due Diligence Services</a>
                                            </DropdownItem>
                                            <DropdownItem><a target="_blank" rel="noopener noreferrer"
                                                             href="https://community.biznexus.com/all-courses"
                                                             className="nav-link">Learning Center </a> </DropdownItem>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret className="link-hover">
                                            Business Exit
                                        </DropdownToggle>
                                        <DropdownMenu right style={{width: '250px'}}>
                                            <DropdownItem><a target="_blank" rel="noopener noreferrer"
                                                             href="https://www.biznexus.com/exitprep"
                                                             className="nav-link">ExitPrep</a> </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <NavItem>
                                        <Link to="/login" className="nav-link">Log In</Link>
                                    </NavItem>
                                </Nav></Collapse>
                        </React.Fragment>
                    }
                </Navbar>

                {/*{this.context.premiumUntil && <div style={{ left: '0',*/}
                {/*              top: '0px',*/}
                {/*              right: '0%',*/}
                {/*              bottom: 'auto',*/}
                {/*              zIndex: 1001, */}
                {/*              textAlign: 'center',*/}
                {/*              display: 'flex',*/}
                {/*              width: '100%',*/}
                {/*              height: '40px',*/}
                {/*              backgroundColor: '#848484',*/}
                {/*              alignItems: 'center',*/}
                {/*              justifyContent: 'center'*/}
                {/*           }}>*/}
                {/*     <div style={{ textAlign: 'center', color: '#FFF' }}>Hi, {this.cutUserName(this.context.user.name, true)}.*/}
                {/*       {premiumLeft === 0 ? `This is your last day` : `You have ${premiumLeft} days left`} in your trial. <a style={{ color: '#FFF', textDecoration: 'underline', fontWeight: '600' }} href="/user/subscription">Manage subscription →</a></div>*/}
                {/*</div>}*/}

                {/*{this.context.free_trial_end && <div style={{ left: '0',*/}
                {/*  top: '0px',*/}
                {/*  right: '0%',*/}
                {/*  bottom: 'auto',*/}
                {/*  zIndex: 1001,*/}
                {/*  textAlign: 'center',*/}
                {/*  display: 'flex',*/}
                {/*  width: '100%',*/}
                {/*  height: '40px',*/}
                {/*  backgroundColor: '#FF385C',*/}
                {/*  alignItems: 'center',*/}
                {/*  justifyContent: 'center'*/}
                {/*}}>*/}
                {/*  <div style={{ textAlign: 'center', color: '#FFF' }}>Hi, {this.cutUserName(this.context.user.name, true)}. Your free trial has expired. <a style={{ color: '#FFF', textDecoration: 'underline', fontWeight: '600' }} href="/user/subscription">Upgrade now →</a></div>*/}
                {/*</div>}*/}


                <Modal isOpen={this.props.ProfileUpdatemodal} toggle={this.props.ProfileUpdatetoggle}
                       className="modal-dialog-centered delete-item" keyboard={false} backdrop="static">
                    <ModalHeader toggle={this.props.ProfileUpdatetoggle}>Please save your profile changes!</ModalHeader>
                    <ModalFooter>
                        <Button color="primary" onClick={this.props.profilehandleSubmit} data-autosave="submitprofile"
                                data-url={this.state.linkurl}>Save Changes</Button>
                        <Button color="danger" onClick={this.props.ProfileUpdatetoggle} data-url={this.state.linkurl}>Continue
                            without saving</Button>
                    </ModalFooter>
                </Modal>

                <Modal style={{maxWidth: 580}} isOpen={this.context.linkedinModal} toggle={() => {
                    this.context.updateOtherProps({linkedinModal: !this.context.linkedinModal})
                }} className="modal-dialog-centered delete-item" keyboard={false} backdrop="static">
                    <ModalHeader style={{borderBottom: 0}} toggle={() => {
                        this.context.updateOtherProps({linkedinModal: !this.context.linkedinModal})
                    }}/>
                    <ModalBody style={{padding: '2rem'}}>

                        <FormGroup>
                            Thanks for coming back to BizNexus. Going forward, we require all members to have the
                            following profile information completed to get matched with any closed-access deals /
                            teasers:
                            <ul>
                                <li>Uploaded headshot photo</li>
                                <li>Deal preferences</li>
                                <li>Profile overview</li>
                                <li>LinkedIn URL</li>
                            </ul>
                            You can use the marketplace without this information added, but you <span
                            style={{fontStyle: 'italic'}}>will not qualify to match with teaser deals until this information is 100% complete.</span>
                        </FormGroup>

                        <FormGroup>
                            <b>Submit Your LinkedIn Profile URL Now</b>
                        </FormGroup>

                        <FormGroup className="text-center">
                            {/* <Label for="LinkedIn">LinkedIn Profile URL <span style={{ color: 'red' }}>*</span></Label> */}
                            <div style={{display: 'inline-flex', width: '100%'}}>
                                <Input className="me-3" type="text" value={this.state.linkedin_url} onChange={e => {
                                    this.setState({linkedin_url: e.target.value})
                                }} placeholder="https://www.linkedin.com/in/john-doe/"/>
                                <Button style={{width: 175}} color="primary"
                                        onClick={this.updateLinkedin}>Submit</Button>
                            </div>
                            <span className="error ">{this.state.errors["linkedin_url"]}</span>
                        </FormGroup>

                        {/*<FormGroup className="text-center">*/}
                        {/*  <a href="/user/profile" className="btn btn-primary">Update Your Profile Now</a>*/}
                        {/*</FormGroup>*/}

                    </ModalBody>

                </Modal>
            </header>
        );
    }
}

export default withRouter(Header);
