import React, {useState} from "react";
import './broker-home-search.scss'

const BrokerHomeSearch = ({ deals, setFilteredDeals }) => {
    const [value, setValue] = useState('')
    const filterDeals = (filterValue) => {
        setValue(filterValue)
        const filteredDeals = deals.filter(deal => {
            const searchValue = filterValue.trim().toLowerCase()
            if (deal.name.toLowerCase().includes(searchValue)) {
                return true
            }
            if (deal.description.toLowerCase().includes(searchValue)) {
                return true
            }
            return !!deal.keyword_search?.toLowerCase().includes(searchValue);

        })
        setFilteredDeals(filteredDeals)
    }
    return (
        <form className="broker-listings-search input-group header-group d-flex ">
            <input style={{backgroundColor: '#ececec'}} className="form-control py-2 border-right-0 search-header"
                   type="text"
                   onChange={(e) => filterDeals(e.target.value)}
                   value={value} maxLength={100} placeholder={'Search Your Listings'}/>
            <span className="input-group-append btn border-end-0 border" style={{backgroundColor: '#ececec', margin: 0, borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}>
                  <i className="fa fa-search"/>
              </span>
        </form>
    )
}

export default BrokerHomeSearch
