import React from 'react';
import { useNavigate, useParams, Navigate} from "react-router";
import {USER_TYPE} from "../../constants/userTypes";
import {useAuthContext} from "../../contexts/auth";
import { UserStatusNameMap} from "../Console/signups";
import UpgradeSubscriptionModal from "../../containers/UpgradeSubscriptionModal/UpgradeSubscriptionModal";

const defaultConfig = {
    loggedIn: true,
    approved: true,
    [USER_TYPE.BUYER]: {
        allowed: true,
        minSubscription: null,
    },
    [USER_TYPE.SELLER]: {
        allowed: true,
        minSubscription: null,
    },
    [USER_TYPE.BROKER]: {
        allowed: true,
        minSubscription: null,
    },
    [USER_TYPE.PROVIDER]: {
        allowed: true,
        minSubscription: null,
    },
}

const RouteContainer = (props) => {
    const {
        path,
        exact,
        component,
        config = {},
        fallback = '/',
        render,
    } = props
    const {user, loading} = useAuthContext()
    const params = useParams()
    const navigate = useNavigate()
    if (loading) {
        return <div className="loader" id="loaderlistingview"><div className="spiner"/></div>
    }

    const getCurrentConfig = () => {
        let currentConfig = {}
        let currentFallback = ''

        if (params.tab && config.tabs && config.tabs[params.tab]) {

            if (params.subtab && config.tabs[params.tab].subtabs && config.tabs[params.tab].subtabs[params.subtab]) {
                currentConfig = {
                    ...defaultConfig,
                    ...config.tabs[params.tab].subtabs[params.subtab]
                }
                currentFallback = config.tabs[params.tab][user.user_type_id] && config.tabs[params.tab][user.user_type_id].fallback
                    ? config.tabs[params.tab].subtabs[params.subtab][user.user_type_id].fallback
                    : config.tabs[params.tab].subtabs[params.subtab].fallback || fallback
            } else {
                currentConfig = {
                    ...defaultConfig,
                    ...config.tabs[params.tab]
                }
                currentFallback = config.tabs[params.tab][user.user_type_id] && config.tabs[params.tab][user.user_type_id].fallback
                    ? config.tabs[params.tab][user.user_type_id].fallback
                    : config.tabs[params.tab].fallback || fallback
            }
        } else {
            currentConfig = {
                ...defaultConfig,
                ...config
            }
            currentFallback = fallback
        }

        return {
            currentConfig,
            currentFallback
        }
    }

   const { currentFallback, currentConfig } = getCurrentConfig()


    if (currentConfig.loggedIn && !user.user_type_id) {
        return <Navigate to={`/login?redirect_url=${window.location}`} />
    }
    if (currentConfig.approved && !loading && user.status !== UserStatusNameMap.Approved) {
        return navigate(`${user.user_type_id === USER_TYPE.BROKER ? '/setup-for-intermediaries' : '/setup'}/`)
    }
    if (currentConfig.loggedOut && user.user_type_id) {

        const params = (new URL(document.location)).searchParams;

        if (params.has('redirect_url')) {
            window.location = params.get('redirect_url');
            return ''
        } else {
            return <Navigate to={`/home`} />
        }
    }
    if (!loading && user.user_type_id && currentConfig[user.user_type_id] && !currentConfig[user.user_type_id].allowed) {
        const params = (new URL(document.location)).searchParams;

        if (params.has('redirect_url')) {
            window.location = params.get('redirect_url');
            return
        } else {
            return <Navigate to={`/home`} />
        }
    }
    if (!loading && currentConfig[user.user_type_id] &&
        (!currentConfig[user.user_type_id].allowed ||
            (currentConfig[user.user_type_id].minSubscription &&
            user.premium_level < currentConfig[user.user_type_id].minSubscription))) {
        if (currentFallback === '/user/subscription') {
            return <UpgradeSubscriptionModal isMessages={params.tab === 'messages'}/>
        } else {
            return <Navigate to={currentFallback} />
        }
    }
    if (!loading) {
        return (React.createElement(component))
    }

}

export default RouteContainer
