import React from "react";
import {Link} from "react-router-dom";
import ReportCopy from "./ReportCopy";

const ReportsConsole = () => {
    return (
        <div className="container" style={{ width: '100%', maxWidth: '100%' }}>
            <Link to="/console">&larr; Back</Link>
            <br/><br/>
            <h3>Reports</h3>
            <ReportCopy/>
        </div>
    );
};

export default ReportsConsole
