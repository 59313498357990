import React from 'react';
import {
    Button,
    Col,
    Collapse,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row, UncontrolledTooltip
} from 'reactstrap';
import {Link} from 'react-router-dom';
import htmlToDraft from 'html-to-draftjs';
import {USER_TYPE} from '../../../constants/userTypes';
import api from '../../../services/api';

export default class Testimonials extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editorStateTestimonials: '',
            errors: {},
            success: {},
            collapse: false,
            testimonialslist: [],
            fields: [],
            testimonialsId: '',
            testimonialData: 'none',
            Deletelistmodal: false,
            itemID: '',
            itemDeleted: '',
        };
    }

    getTestimonialsList = () => {
        api.get('testimonial/list', null, true)
        .then(res => {
            this.setState({testimonialslist: res.list});
        });
    }

    componentDidMount() {
        this.getTestimonialsList();
    }

    testimonialshandleSubmit = async (event) => {
        event.preventDefault();
        if (this.testimonialshandleValidation()) {
            document.getElementById('loadertestimonials').setAttribute("style", "display: block;");
            let jsontestimonialsData = {
                "id": this.state.testimonialsId,
                "name": this.state.fields["attribution"],
                "testimonial_text": this.state.editorStateTestimonials,
                "ip_address": localStorage.getItem("ip")
            };
            let TestimonialSave = '';
            if (this.state.testimonialsId !== '') {
                TestimonialSave = "saveedit";
            } else {
                TestimonialSave = "add"
            }

            await api.post('testimonial/' + TestimonialSave, jsontestimonialsData, true)
                .then(res => {
                        document.getElementById('loadertestimonials').setAttribute("style", "display: none;");
                        window.Intercom('trackEvent', 'testimonial-updated');
                        this.state.fields["attribution"] = '';
                        let success = {};
                        success["success"] = res.data.message;
                        this.setState({
                            success: success,
                            testimonialsId: '',
                            editorStateTestimonials: ''
                        });
                        window.setTimeout(() => {
                            this.setState({success: ''});
                        }, 5000);
                        this.getTestimonialsList();
                });
        } // end validation
    }

    testimonialshandleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        //testimonials
        if (this.state.editorStateTestimonials.length <= 10) {
            formIsValid = false;
            errors["testimonialsdescription"] = "Mix length of testimonial is 10 characters";
        }
        //attribution
        if (!fields["attribution"]) {
            formIsValid = false;
            errors["attribution"] = "Add name of person testimonial is attributed to";
        }

        if (fields["attribution"] && fields["attribution"].length > 50) {
            formIsValid = false;
            errors["attribution"] = "Max length of testimonial is 50 characters";
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    TestimonialshandleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    onEditorStateChangeTestimonials = (e) => {
        this.setState({
            editorStateTestimonials: e.target.value
        });
    };

    handletestimonialsClick = async (e) => {
        e.preventDefault();
        if (e.target.id !== '') {
            let testimonialsId = e.target.id;
            document.getElementById('loadertestimonials').setAttribute("style", "display: block;");
            let jsonTestimonialsEitData = {
                "id": testimonialsId
            };
            
            await api.post('testimonial/edit', jsonTestimonialsEitData, true)
                .then(res => {
                    document.getElementById('loadertestimonials').setAttribute("style", "display: none;");
                    let fields = this.state.fields.slice();
                    fields["attribution"] = res[0].name;
                    const contentBlock = htmlToDraft(res[0].testimonial_text);
                    if (contentBlock) {
                        
                        this.setState({
                            editorStateTestimonials: res[0].testimonial_text
                        });
                    }
                    window.Intercom('trackEvent', 'testimonial-updated');
                    this.setState({
                        testimonialsId: testimonialsId,
                        fields,
                    });
                });

            this.getTestimonialsList();
        }
    };

    handleRemovetestimonialsClick = async (e) => {
        this.setState({Deletelistmodal: !this.state.Deletelistmodal});
        if (e.target.getAttribute("data-itemID") !== '') {
            document.getElementById('loadertestimonials').setAttribute("style", "display: block;");
            let jsontestimonialsData = {
                "id": e.target.getAttribute("data-itemID")
            };
            await api.post('testimonial/delete', jsontestimonialsData, true)
                .then(res => {
                    document.getElementById('loadertestimonials').setAttribute("style", "display: none;");
                    if (res.data.status === "true") {
                        this.setState({itemID: '', itemDeleted: ''});
                    }
                });
            
            this.getTestimonialsList();
        }
    }

    testimonialstoggle = (e) => {
        e.preventDefault();
        this.setState({collapse: !this.state.collapse});
        if (this.state.testimonialData === '') {
            this.setState({testimonialData: 'none'});
        } else {
            this.setState({testimonialData: ''});
        }
    }

    createMarkup(data) {
        return {__html: data};
    }

    Deletelisttoggle = (e) => {
        e.preventDefault();
        let deleteID = e.target.id.split("_");
        this.setState({Deletelistmodal: !this.state.Deletelistmodal, itemID: deleteID[1], itemDeleted: deleteID[0]});
    }

    render() {
        const { editorStateTestimonials } = this.state;

        return (
            <div>
                            <div className="loader" id="loadertestimonials" style={{display: 'none'}}>
                                <div className="spiner"></div>
                            </div>
                            <Form onSubmit={this.testimonialshandleSubmit} className="">
                                <Row>
                                    <Col sm="12">
                                        <FormGroup>
                                            <Label className="h1 mb-3">{this.props.user_type_id === USER_TYPE.SELLER ? 'Customer & Employee Testimonials: ' : 'Testimonials: '}
                                            <i id="TestimonialsTooltip"
                                                        className="sprite i-question"></i>
                                    <UncontrolledTooltip placement="bottom" target="TestimonialsTooltip"
                                                        autohide={false}
                                                        innerClassName="certification-tooltip">Copy & paste customer testimonials from your website and put them directly onto your BizNexus profile.</UncontrolledTooltip>
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <FormGroup>
                                            <Label htmlFor="testimonialsdescription"
                                                   className="font-size-sm">Description <span style={{ color: 'red' }}>*</span></Label>
                                            <textarea
                                                value={editorStateTestimonials}
                                                className="form-control pl-2 my-0"
                                                style={{ height: '150px' }}
                                                onChange={this.onEditorStateChangeTestimonials}
                                            ></textarea>
                                            <span
                                                className="error  ">{this.state.errors["testimonialsdescription"]}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label htmlFor="attribution" className="font-size-sm">Attribution <span style={{ color: 'red' }}>*</span></Label>
                                            <Input type="text" name="attribution" id="attribution" ref="attribution"
                                                   onChange={this.TestimonialshandleChange.bind(this, "attribution")}
                                                   value={this.state.fields["attribution"]} placeholder="John Doe"/>
                                            <span
                                                className="error  ">{this.state.errors["attribution"]}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <p className="text-success text-center mt-3">{this.state.success["success"]}</p>
                                <p className="text-center pt-2 pb-4">
                                    <button className="btn btn-primary">Submit Testimonials</button>
                                </p>
                            </Form>
                            {
                                this.state.testimonialslist.length > 0 &&
                                <div className="testimonials mb-4">
                                    {this.state.testimonialslist.map((testimonials, j) => {
                                        return (
                                            testimonials.status === "1" && j < 3 &&
                                            <div className="testimonialslist" key={`testimonialslist-${j}`}>
                                                <div style={{ overflowWrap: 'break-word' }} className="editor-text"
                                                     dangerouslySetInnerHTML={this.createMarkup(testimonials.testimonial_text)}></div>
                                                <p className="text-blue">{testimonials.name}</p>
                                                <Link to="" onClick={e => {
                                                    e.preventDefault()
                                                }}><i id={testimonials.id} onClick={this.handletestimonialsClick}
                                                      className="sprite i-edit"></i></Link>
                                                <Link to="" onClick={e => {
                                                    e.preventDefault()
                                                }}><i id={`testimonials_${testimonials.id}`}
                                                      onClick={this.Deletelisttoggle}
                                                      className="sprite i-delete"></i></Link>
                                            </div>
                                        )
                                    })
                                    }
                                    <Collapse isOpen={this.state.collapse}>
                                        {this.state.testimonialslist.map((testimonials, j) => {
                                            return (
                                                testimonials.status === "1" && j >= 3 &&
                                                <div className="testimonialslist" key={`testimonialslist-${j}`}>
                                                    <div className="editor-text"
                                                         dangerouslySetInnerHTML={this.createMarkup(testimonials.testimonial_text)}></div>
                                                    <p className="text-blue">{testimonials.name}</p>
                                                    <Link to="" onClick={e => {
                                                        e.preventDefault()
                                                    }}><i id={testimonials.id} onClick={this.handletestimonialsClick}
                                                          className="sprite i-edit"></i></Link>
                                                    <Link to="" onClick={e => {
                                                        e.preventDefault()
                                                    }}><i id={`testimonials_${testimonials.id}`}
                                                          onClick={this.Deletelisttoggle}
                                                          className="sprite i-delete"></i></Link>
                                                </div>
                                            )
                                        })
                                        }
                                    </Collapse>
                                    {this.state.testimonialslist.length > 3 &&
                                    <p className="text-center toggle-link"><Link to="" className="link-more"
                                                                                 onClick={this.testimonialstoggle}>{(this.state.testimonialData === '') ? "Hide more testimonials" : "Read more testimonials"}
                                        <i className={(this.state.testimonialData === '') ? "sprite i-up-arrow" : "sprite i-down-arrow"}></i></Link>
                                    </p>
                                    }
                                </div>
                            }
                            <Modal isOpen={this.state.Deletelistmodal} toggle={this.Deletelisttoggle}
                                   className="modal-dialog-centered delete-item" keyboard={false} backdrop="static">
                                <ModalHeader>Are you sure?</ModalHeader>
                                <ModalBody>
                                    Would you like to remove this item from the list?
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={this.Deletelisttoggle}>No</Button>
                                    <Button color="danger"
                                            onClick={(this.state.itemDeleted === 'testimonials') ? this.handleRemovetestimonialsClick : (this.state.itemDeleted === 'awardslists') ? this.handleRemoveawardsClick : this.handleRemovecertiClick}
                                            data-itemID={this.state.itemID}>Yes</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
        )
    }


}
