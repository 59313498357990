import React, {useState} from 'react';

const ScrollButton = () => {

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 400){
      setVisible(true)
    }
    else if (scrolled <= 400){
      setVisible(false)
    }
  };

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
      <button onClick={scrollToTop} className="btn btn-primary scroll-button" style={{display: visible ? '' : 'none'}}>
        <i className="fa-solid fa-arrow-up"></i>
      </button>
  );
}

export default ScrollButton;