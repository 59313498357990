import React from 'react';
import moment from 'moment';
import {getImageUrl} from "../../components/util";
import {find} from "lodash";

function sliceText(text, count = 20) {
    return text && text.length >= count ? text.slice(0, count) + '...' : text;
}

function renderDefaultImage(data) {
    const images = {
        1293401: process.env.REACT_APP_ROOT + 'images/default_listing/techmedia.jpg',
        1293501: process.env.REACT_APP_ROOT + 'images/default_listing/manufacturing.jpg',
        1293601: process.env.REACT_APP_ROOT + 'images/default_listing/food.jpg',
        1293701: process.env.REACT_APP_ROOT + 'images/default_listing/leisure.jpg',
        1293801: process.env.REACT_APP_ROOT + 'images/default_listing/retail.jpg',
        1293901: process.env.REACT_APP_ROOT + 'images/default_listing/services.jpg',
        1299301: process.env.REACT_APP_ROOT + 'images/default_listing/agriculture.jpg',
        1316601: process.env.REACT_APP_ROOT + 'images/default_listing/engineering.jpg',
        1358401: process.env.REACT_APP_ROOT + 'images/default_listing/franchise.jpg',
        1360201: process.env.REACT_APP_ROOT + 'images/default_listing/realestate.jpg',
        1365401: process.env.REACT_APP_ROOT + 'images/default_listing/wholesale.jpg',
        9999999: process.env.REACT_APP_ROOT + 'images/default_listing/other.jpg',

        1296101: process.env.REACT_APP_ROOT + 'images/default_listing/maintenance.png',
        1342801: process.env.REACT_APP_ROOT + 'images/default_listing/printing.png',
        1386501: process.env.REACT_APP_ROOT + 'images/default_listing/wedding.png',
        1294502: process.env.REACT_APP_ROOT + 'images/default_listing/advertising.png',
        1297301: process.env.REACT_APP_ROOT + 'images/default_listing/postal.png',
        1297201: process.env.REACT_APP_ROOT + 'images/default_listing/pets.png',
        1296801: process.env.REACT_APP_ROOT + 'images/default_listing/hire.png',
        1296501: process.env.REACT_APP_ROOT + 'images/default_listing/financial.png',
        1296401: process.env.REACT_APP_ROOT + 'images/default_listing/transportation.png',
        1296901: process.env.REACT_APP_ROOT + 'images/default_listing/house.png',
        1299101: process.env.REACT_APP_ROOT + 'images/default_listing/construction.png',
    };
    let image = '';
    const { industries } = data;
    const first_industry = find(industries, e => e.industry.parent_id === 1);
    const first_sub_industry = find(industries, e => e.industry.parent_id !== 1);

    if(first_industry) {
        image = images[first_industry.industry.id]
    } else if(industries[0] && industries[0].industry.parent_id) {
        image = images[industries[0].industry.parent_id]
    }

    if(first_sub_industry) {
        image = images[first_sub_industry.industry.id] || process.env.REACT_APP_ROOT + 'images/default_listing/other.jpg'
    }

    return image;
}

const Completed = ({deals}) => {

    return (
        <div className="content-card mb-4">
            <a className="float-right text-sm" target={'_blank'} rel={'noreferrer nofollow'} href={"/completed-transactions"}>View All</a>
            <div className="content-body">
                <h2>Completed Transactions</h2>


                <div className="">
                    <div className="row">

                        {deals.map(deal =>
                            <div className="col-md-4">
                                <div className="card h-100" style={{ borderRadius: 10 }}>
                                    <div style={{ position: 'absolute', top: 5, right: 5, fontSize: 10, color: '#2a2f34' }} className="text-md-right btn btn-primary bg-light btn-sm font-weight-normal">
                                        {deal.listing_date ? moment(deal.listing_date).format("YYYY") : moment(new Date()).format("YYYY")}
                                    </div>
                                    <img src={getImageUrl(renderDefaultImage(deal), true)} className="card-img-top"
                                         alt="Listing image" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }} />
                                    <div className="card-body text-center">
                                        {deal.industries.map(c => c.industry.name).map(name =>
                                            <div style={{ fontSize: 10, color: '#2a2f34' }} className="btn btn-primary bg-light btn-sm font-weight-normal me-1 mt-1">
                                                {name}
                                            </div>
                                        )}

                                        <hr />

                                        <div style={{ fontSize: 14 }} className="mb-1 font-weight-500">{sliceText(deal.name, 35)}</div>
                                        {(deal.seller_company_name || deal.seller_buyer_type) && <div style={{ fontSize: 12, color: 'gray' }} className="mb-1">Acquired by</div>}
                                        <div style={{ fontSize: 14 }} className="font-weight-500">{sliceText(deal.seller_company_name) || sliceText(deal.seller_buyer_type)}</div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Completed
