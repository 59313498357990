import React from "react";

const ArrowIcon = () => (<svg className="icon" viewBox="0 0 20 12" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd"
          clipRule="evenodd"
          d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z"
          fill="currentColor"/></svg>)

export default ArrowIcon
