import React from 'react';
import { Link } from 'react-router-dom';
import {Col, Form, FormGroup, Input, Label, Row, UncontrolledTooltip} from 'reactstrap';
import {LinkedIn} from 'react-linkedin-login-oauth2';
import Helmet from 'react-helmet/es/Helmet';
import { LINKEDIN_STATUSES } from '../constants/linkedin';
import Header from './Header';
import Footer from './Footer';
import Testimonials from "./Signup/testimonials";
import {AuthContext} from "../contexts/auth";
import {withRouter} from "../containers/withRouter/withRouter";
import ReactGA from "react-ga4";

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
			errors: {},
			buyerReview: false,
		};
		this.SubmitLogin = this.SubmitLogin.bind(this);
	}
	
	handleValidation() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
		//Email
		if (!fields["emaillogin"]) {
			formIsValid = false;
			errors["emaillogin"] = "Please enter email";
		}

		if (typeof fields["emaillogin"] !== "undefined") {
			let lastAtPos = fields["emaillogin"].lastIndexOf('@');
			let lastDotPos = fields["emaillogin"].lastIndexOf('.');

			if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["emaillogin"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["emaillogin"].length - lastDotPos) > 2)) {
				formIsValid = false;
				errors["emaillogin"] = "Please enter valid email address";
			}
		}
		//newpass
		if (!fields["passwordlogin"]) {
			formIsValid = false;
			errors["passwordlogin"] = "Please enter password."; 
		}
		if (typeof fields["passwordlogin"] !== "undefined") {
			if (!fields["passwordlogin"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)) {
				formIsValid = false;
				errors["passwordlogin"] = "The email and/or password entered doesn't match our records. Please try again.";
			}
		}
		this.setState({ errors: errors });
		return formIsValid;
	}
	handleChange(field, e) {
		let fields = this.state.fields;
		fields[field] = e.target.value;
		this.setState({ fields });
	}
	componentDidMount() {
		document.getElementById('loaderpopup').setAttribute("style", "display: none;");

		if(localStorage.getItem('forgotPassword')===true){
			this.props.history.push('/changepassword');
		}

		
	}

	static contextType = AuthContext

	SubmitLogin = event => {
		event.preventDefault();
		if (this.handleValidation()) {			
			document.getElementById('loaderpopup').setAttribute("style", "display: block;");
			let emailId = this.state.fields["emaillogin"];
			let password = this.state.fields["passwordlogin"];
			let jsonsetLoginData = JSON.stringify({
				"email": emailId,
				"password": password,
				"ip_address": localStorage.getItem("ip")
			});
			//console.log(jsonsetLoginData);
			fetch(process.env.REACT_APP_API_ROOT + 'user/signin', {
				method: 'POST',
				body: jsonsetLoginData,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					"Access-Control-Allow-Origin": "*"
				},
			}).then(function (res) {
				if (res.status < 200 || res.status >= 300) {
					//console.log("Error 401");
					//console.log(res);
					//return res.json().then(err => {throw err;});
					throw res;
					//throw Error(.statusText);
				} else { return res.json() }
			})
				.then(
					(res) => {
						//console.log(res);
						if (res.data.status === true) {

							if(res.data.forgotPassword===true){

								localStorage.setItem('token', res.data.token);
								localStorage.setItem('email', emailId);
								localStorage.setItem('forgotPassword', res.data.forgotPassword);
								localStorage.setItem('franchiseStatus', res.data.franchise_status);
								localStorage.setItem('loggedIn', true);
								document.getElementById('loaderpopup').setAttribute("style", "display: none;");
								this.props.history.push('/changepassword');

							} else if(res.data.review) {
								document.getElementById('loaderpopup').setAttribute("style", "display: none;");
								this.setState({ buyerReview: true });
							} else{

								localStorage.setItem('token', res.data.token);
								localStorage.setItem('email', emailId);
								localStorage.setItem('franchiseStatus', res.data.franchise_status);
								localStorage.setItem('loggedIn', true);

							}


							this.context.actions.fetchUser()
						}
						else {
							let errors = {};
							errors["passwordlogin"] = "The email and/or password entered doesn't match our records. Please try again.";
							this.setState({ errors: errors });
							document.getElementById('loaderpopup').setAttribute("style", "display: none;");
						}
					},
					(error) => {
						this.setState({
							//error
						});
					}
				)
		} else {
			//console.log("Form has errors.")
		}
	}

  handleLinkedinSuccess = (code) => {
    document.getElementById('loaderpopup').setAttribute("style", "display: block;");
    const fetchBody = {
      client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
      client_secret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
      code,
      grant_type: 'authorization_code',
      redirect_uri: process.env.REACT_APP_LINKEDIN_REDIRECT_URL,
    };
    fetch(process.env.REACT_APP_API_ROOT + 'user/linkedinToken', {
      method: 'POST',
      body: JSON.stringify(fetchBody),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(function (res) {
      if (res.status < 200 || res.status >= 300) {
        throw res;
      } else { return res.json() }
    }).then(response => {
      if (response.status === LINKEDIN_STATUSES.NOT_EXIST) {
        this.continueRegistrationWithLinkedin(response.userData);
      }
      if (response.status === LINKEDIN_STATUSES.COMPLETED) {
        this.logIn(response.userData);
      }
    }).catch(err => {
	document.getElementById('loaderpopup').setAttribute('style', 'display: none;');
      this.setState({
        errorMessage: 'Something went wrong, please try later.',
      })
    });

    this.setState({
      code,
      errorMessage: '',
    });
  };

  logIn = userData => {
    localStorage.setItem('token', userData.token);
    localStorage.setItem('email', userData.email);
    localStorage.setItem('franchiseStatus', userData.franchise_status);
    localStorage.setItem('loggedIn', true);
	  this.context.actions.fetchUser();
  };

  continueRegistrationWithLinkedin = userData => {
  	localStorage.setItem('registerFromLinkedin', JSON.stringify(userData));
		this.props.history.push('/signup');
  };

  handleLinkedinFailure = (error) => {
    this.setState({
      code: '',
      errorMessage: error.errorMessage,
    });
  };

	render() {
		return (
			<>
			<Header />
				<Row>
					<Col sm="6" style={{ backgroundColor: '#F3F3F3' }}>
						<section className="signup-section">
							<Helmet>
								<title>Log In Or Join BizNexus Today</title>
							</Helmet>
							<div className="loader bg-white" id="loaderpopup"><h3>Please Wait...</h3><div className="spiner"></div></div>
							<a href="/"><img src={process.env.PUBLIC_URL + '/images/biznexus-logo.png'} style={{ width: 250 }} alt="logo" className="d-block m-auto" /></a>

							{this.state.buyerReview &&
									<div className="signup-bx">
										<h4 className="text-center">Pending Review</h4>
										<p className="text-center">Great! Thanks for applying to join our community. Our team typically reviews applications within 24 hours, and we'll let you know as soon as it's official. </p>
									</div>
							}

							{this.state.buyerReview === false && <div className="signup-bx">

								<Row className="pb-4">
									<div style={{ fontSize: 20 }} className="login-active-tab col-sm-6 btn font-weight-500">Log In</div>
									<div style={{ fontSize: 20 }} className="col-sm-6 btn font-weight-500 gray-help-text">
										<Link className="w-100" style={{ color: 'inherit' }} to={'/signup'}>Sign Up</Link>
									</div>
								</Row>
								<LinkedIn onError={this.handleLinkedinFailure}
										  onSuccess={this.handleLinkedinSuccess}
										  clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
										  redirectUri={process.env.REACT_APP_LINKEDIN_REDIRECT_URL}
										  scope={'r_liteprofile,r_emailaddress'}
								>{
									({ linkedInLogin }) => (
										<button className="btn-linkedin" onClick={linkedInLogin}>
											<i className="fab fa-linkedin-in"/> Log In with LinkedIn
										</button>
									)
								}</LinkedIn>

								<Form onSubmit={this.SubmitLogin}>





									<div className="break txt--center txt--subtle push--top push_half--bottom">
										<div className="break__content">Or, use my email address</div>
									</div>


									<FormGroup>
										<Label for="emaillogin">Email Address</Label>
										<Input type="email" name="emaillogin" id="emaillogin" placeholder="Email Address" ref="emaillogin" autoFocus onChange={this.handleChange.bind(this, "emaillogin")} value={this.state.fields["emaillogin"]} />
										<span className="error">{this.state.errors["emaillogin"]}</span>
									</FormGroup>
									<FormGroup>
										<Label for="passwordlogin">Password</Label>
										<div style={{ float: 'right' }}>
											<span style={{ fontSize: 14 }} className="gray-help-text" for="passwordlogin">
												<Link className="gray-help-text" to={'/forgotpass'}><i className="far fa-question-circle"></i> Forgot your password?</Link></span>
										</div>
										<Input type="password" name="passwordlogin" id="passwordlogin" placeholder="Password" ref="passwordlogin" onChange={this.handleChange.bind(this, "passwordlogin")} value={this.state.fields["passwordlogin"]} />
										<span className="error">{this.state.errors["passwordlogin"]}</span>
									</FormGroup>
									<FormGroup check>
										<Label check className="d-none">
											<Input type="checkbox" />
											Remember me </Label>

									</FormGroup>
									<button type="submit" className="btn-blue-login">Log In</button>


								</Form>
								<div className="text-center">
									<p className="lgn-link-login gray-help-text">Don't have an account? <Link to={'/signup'}> Sign up</Link></p>
								</div>



							</div>}



						</section>
					</Col>
					<Col sm="6">
						<div style={{ padding: 30, marginTop: 80 }}>
							<h1>Thousands Of New Acquisition Opportunities Verified Daily</h1>

							<div style={{ maxWidth: '66%' }}>
								<p className="pt-4 font-weight-bold">Over 10,000 deals verified, removed, updated & added daily.</p>

								<p>Reduce wasted time and source <span style={{ textDecoration: 'underline' }} className="font-weight-bold">LIVE</span> intermediated deal flow in one single location.</p>

								<p>Intermediary listings, privately submitted deals & pre-CIM teasers aggregated from across the lower middle market.</p>

								<p>Start scalling your deal origination today.</p>
							</div>

							<Testimonials />

						</div>
					</Col>
				</Row>

			<Footer />
			</>
		);
	}
}
export default withRouter(Login);
