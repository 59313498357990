/* eslint-disable */
import React from 'react'
import Geosuggest from '@ubilabs/react-geosuggest';
import _ from 'lodash';
import queryString from 'query-string'
import {connect} from 'react-redux';
import PriceFilter from "../../cards/SearchFilters/PriceFilter";

class ReportCopy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                searchbox: '',
                excluded: '',
            },
            errors: {},
            message: '',
            gridview: '12',
            categoryOption: '',
            categorylist: [],
            shortbyOption: '',
            location: '',
            Lat: '',
            Lng: '',
            Searchcollapse: true,
            AdvanceSearchToggle: 'none',
            minpricerangeselected: '',
            maxpricerangeselected: '',
            mincashflowselected: '',
            maxcashflowselected: '',
            minrevenueselected: '',
            maxrevenueselected: '',
            minebitdaselected: '',
            maxebitdaselected: '',
            brokername: '',
            brokerprofile_pic: '',
            brokerslug_url: '',
            brokerfirm_name: '',
            brokerptype: '',
            brokerarea_served: '',
            brokerreviews: '',
            brokerratings: '',
            brokertranscount: '',
            brokerlistcount: '',
            orderby: 'listing_date',
            SearchResultList: [],
            franchiseRecordListing: [],
            totalrecords: '',
            activePage: 1,
            staticNumberOfRow: 25,
            showingData: '',
            modal: false,
            guideModalOpen: false,
            offMarket: 'yes',

            premiumModal: false,
            dropdownRevenueMultiple: false,
            dropdownCashflowMultiple: false,
            dropdownEBITDAMultiple: false,

            brokerIndustry: [],
            locationType: '',

            city: '',
            state: '',
            country: '',

            incKeywords: '',
            excKeywords: '',
            locations: [],
            keywordsToggle: false,

            dropdownminpricerange: false,
            dropdownmaxpricerange: false,
            dropdownmincashflow: false,
            dropdownmaxcashflow: false,
            dropdownminrevenue: false,
            dropdownmaxrevenue: false,

            dropdownminrevenuemultiple: false,
            dropdownmaxrevenuemultiple: false,
            dropdownminebitdamultiple: false,
            dropdownmaxebitdamultiple: false,
            dropdownmincashflowmultiple: false,
            dropdownmaxcashflowmultiple: false,


        };
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
    }

    componentDidMount() {

        fetch(process.env.REACT_APP_API_ROOT + 'industry/legacy', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => res.json())
            .then(
                (res) => {
                    let categorylistarray = [];
                    let temp = {};
                    res.map((categories, i) => {
                        temp = {
                            "value": categories.value,
                            "label": categories.label,
                            "children": categories.children,
                        };
                        categorylistarray.push(temp);
                    });
                    this.setState({categorylist: categorylistarray});


                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        //error
                    });
                }
            );
        this.gettopbrockerlist();

        this.fetchPremium();


    }

    fetchPremium() {

        if (!localStorage.getItem('token')) {
            return;
        }

        return fetch(process.env.REACT_APP_API_ROOT + 'user/header', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(res => res.json()).then(res => {
            this.setState({
                premium: res.premium,
            });
        });
    }


    gettopbrockerlist = () => {
        fetch(process.env.REACT_APP_API_ROOT + 'profile/featured_profile', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(function (res) {
                if (res.status < 200 || res.status >= 300) {
                    //console.log("Error 401");
                    //console.log(res);
                    //return res.json().then(err => {throw err;});
                    throw res;
                    //throw Error(.statusText);
                } else {
                    return res.json()
                }

            })
            .then((res) => {
                //console.log(res);
                if (res.length > 0) {
                    let firstname = '';
                    let fullname = res[0].name.match(/(\w+)(?:\s[^\s]+)?\s(\w+).*/);
                    if (fullname !== null) {
                        firstname = fullname[1] + " " + fullname[2].charAt(0) + ".";
                    } else {
                        firstname = res[0].name;
                    }
                    this.setState({
                        brokername: firstname,
                        brokerprofile_pic: res[0].profile_pic,
                        brokerslug_url: res[0].slug_url,
                        brokerfirm_name: res[0].firm_name,
                        brokerptype: res[0].ptype,
                        brokerarea_served: res[0].area_served,
                        brokerreviews: res[0].reviews,
                        brokerratings: res[0].ratings,
                        brokertranscount: res[0].transcount,
                        brokerlistcount: res[0].listcount,
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    //error
                });
            })
    }

    handleSearchResultValidation() {
        // let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        this.setState({errors: errors});
        return formIsValid;
    }

    handleGeosuggestChange = (location) => {
        if (location !== undefined) {
            let addressComponent = location.gmaps.address_components;
            let locationType, city, state, country = '';
            switch (addressComponent[0].types[0]) {
                case 'locality':
                    locationType = 'city';
                    break;
                case 'administrative_area_level_1':
                    locationType = 'state';
                    break;
                case 'country':
                    locationType = 'country';
                    break;
                default:
                    locationType = 'point';
            }

            const duplicate = this.state.locations.find(l => l.location === location.label);

            if (duplicate) {
                this.refs.location.update('');
                return;
            }

            for (var j = 0; j < location.gmaps.address_components.length; j++) {
                if (addressComponent[j].types[0] === 'locality') {
                    this.setState({city: addressComponent[j].long_name});
                    city = addressComponent[j].long_name;
                }
                if (addressComponent[j].types[0] === 'administrative_area_level_1') {
                    this.setState({state: addressComponent[j].short_name});
                    state = addressComponent[j].short_name;
                }
                if (addressComponent[j].types[0] === 'country') {
                    this.setState({country: addressComponent[j].short_name});
                    country = addressComponent[j].short_name;
                }
            }
            this.state.locations.push({
                city,
                state,
                country,
                locationType: locationType,
                Lat: location.location.lat,
                Lng: location.location.lng,
                location: location.label,
            })

            this.refs.location.update('');
            this.setState({Lat: location.location.lat});
            this.setState({Lng: location.location.lng});
            this.setState({location: location.label, locationType});
        }
    }
    handlecategoryChange = (categoryOption) => {

        this.setState({categoryOption});
        // selectedOption can be null when the `x` (close) button is clicked
        if (categoryOption) {
            // console.log(`Selected: ${shortbyOption.label}`);
        }
    }
    handleChangeGridview = () => {
        this.setState({gridview: "3"});
    }
    handleChangeListview = () => {
        this.setState({gridview: "12"});
    }

    handleChangeSearch(field, e) {
        if (e.target !== '' && e.target !== undefined) {
            let fields = this.state.fields;
            fields[field] = e.target.value;

            if (e.target.name === 'minpricerange') {
                let minpricerange = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxpricerange') {
                let maxpricerange = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'mincashflow') {
                let mincashflow = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = mincashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxcashflow') {
                let maxcashflow = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxcashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'minrevenue') {
                let minrevenue = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxrevenue') {
                let maxrevenue = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'minebitda') {
                let minebitda = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minebitda.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxebitda') {
                let maxebitda = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxebitda.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'minebitda_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');
            }
            if (e.target.name === 'maxebitda_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');
            }
            if (e.target.name === 'minrevenue_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');
            }
            if (e.target.name === 'maxrevenue_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');
            }
            if (e.target.name === 'mincashflow_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');
            }
            if (e.target.name === 'maxcashflow_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');
            }
            if (e.target.name === 'fromDateRange' || e.target.name === 'toDateRange') {
                fields[field] = e.target.value;
            }
            this.setState({fields});
        }
    }

    handleChangeMinPriceRange = (e) => {
        let fields = this.state.fields;
        fields["minpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, minpricerangeselected: e.target.value});
    }
    handleChangeMaxPriceRange = (e) => {
        let fields = this.state.fields;
        fields["maxpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, maxpricerangeselected: e.target.value});
    }
    handleChangeMincashflow = (e) => {
        let fields = this.state.fields;
        fields["mincashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, mincashflowselected: e.target.value});
    }
    handleChangeMaxcashflow = (e) => {
        let fields = this.state.fields;
        fields["maxcashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, maxcashflowselected: e.target.value});
    }
    handleChangeMinRevenue = (e) => {
        let fields = this.state.fields;
        fields["minrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, minrevenueselected: e.target.value});
    }
    handleChangeMaxRevenue = (e) => {
        let fields = this.state.fields;
        fields["maxrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, maxrevenueselected: e.target.value});
    }
    handleChangeMinEbitda = (e) => {
        let fields = this.state.fields;
        fields["minebitda"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, minebitdaselected: e.target.value});
    }

    handleChangeMaxEbitda = (e) => {
        let fields = this.state.fields;
        fields["maxebitda"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, maxebitdaselected: e.target.value});
    }

    getSearchResultlist = () => {
        let location, latitude, longitude, minpricerangeselected, maxpricerangeselected, mincashflowselected, maxcashflowselected, minrevenueselected, maxrevenueselected, from_date = null, to_date = null, minebitdaselected, maxebitdaselected = '';
        let fields = this.state.fields;
        if (this.state.location !== '' && this.state.location !== undefined) {
            location = this.state.location;
            latitude = this.state.Lat;
            longitude = this.state.Lng;
        } else {
            location = "";
            latitude = ""
            longitude = "";
        }

        if (this.state.fields.fromDateRange) {
            from_date = new Date(this.state.fields.fromDateRange).toISOString();
        }
        if (this.state.fields.toDateRange) {
            to_date = new Date(this.state.fields.toDateRange).toISOString();
        }
        if (fields["minpricerange"] !== undefined) {
            if (this.state.minpricerangeselected === "Any Min") {
                minpricerangeselected = '';
            }
            else {
                minpricerangeselected = fields["minpricerange"].replace(/,/g, "");
            }
        }
        if (fields["maxpricerange"] !== undefined) {
            if (this.state.maxpricerangeselected === "Any Max") {
                maxpricerangeselected = '';
            }
            else {
                maxpricerangeselected = fields["maxpricerange"].replace(/,/g, "");
            }
        }
        if (fields["mincashflow"] !== undefined) {
            if (this.state.mincashflowselected === "Any Min") {
                mincashflowselected = '';
            }
            else {
                mincashflowselected = fields["mincashflow"].replace(/,/g, "");
            }
        }
        if (fields["maxcashflow"] !== undefined) {
            if (this.state.maxcashflowselected === "Any Max") {
                maxcashflowselected = '';
            }
            else {
                maxcashflowselected = fields["maxcashflow"].replace(/,/g, "");
            }
        }
        if (fields["minrevenue"] !== undefined) {
            if (this.state.minrevenueselected === "Any Min") {
                minrevenueselected = '';
            }
            else {
                minrevenueselected = fields["minrevenue"].replace(/,/g, "");
            }
        }
        if (fields["maxrevenue"] !== undefined) {
            if (this.state.maxrevenueselected === "Any Max") {
                maxrevenueselected = '';
            }
            else {
                maxrevenueselected = fields["maxrevenue"].replace(/,/g, "");
            }
        }
        if (fields["minebitda"] !== undefined) {
            if (this.state.minebitdaselected === "Any Min") {
                minebitdaselected = '';
            }
            else {
                minebitdaselected = fields["minebitda"].replace(/,/g, "");
            }
        }
        if (fields["maxebitda"] !== undefined) {
            if (this.state.maxebitdaselected === "Any Max") {
                maxebitdaselected = '';
            }
            else {
                maxebitdaselected = fields["maxebitda"].replace(/,/g, "");
            }
        }


        const categoryOption = this.state.brokerIndustry.map(bi => bi.children && bi.children.length ? [bi.value].concat(bi.children.map(cc => cc.value)) : bi.value);

        const dataForQuery = {
            "location": location,
            "industry_id": categoryOption.length ? categoryOption : null,
            "from_latitude": latitude,
            "from_longitude": longitude,
            "name": fields["searchbox"],
            excluded: fields["excluded"],
            "price_min": minpricerangeselected,
            "price_max": maxpricerangeselected,
            "cashflow_min": mincashflowselected,
            "cashflow_max": maxcashflowselected,
            "revenue_min": minrevenueselected,
            "revenue_max": maxrevenueselected,
            "ebitda_min": minebitdaselected,
            "ebitda_max": maxebitdaselected,
            "from_date": from_date,
            to_date,
            "orderby": 'listing_date',
            "radius": fields.radius,
            locations: this.state.locations && this.state.locations.length ? JSON.stringify(this.state.locations) : null,
            sortKey: this.state.sort,
            sortDirectionDesc: this.state.sortDirectionDesc,
        };

        if(this.state.locations && this.state.locations.length === 1) {
            // this.setState({ pageTitle: `Businesses for sale in ${this.state.locations[0].city}, ${this.state.locations[0].state}` });
        }

        if(fields['asset_sale']) {
            dataForQuery.asset_sale = '1';
        }

        if(fields['minebitda_multiple'] && fields['minebitda_multiple'] !== 'Any Max' && fields['minebitda_multiple'] !== 'Any Min') {
            dataForQuery.minebitda_multiple = fields['minebitda_multiple'];
        }

        if(fields['maxebitda_multiple'] && fields['maxebitda_multiple'] !== 'Any Max' && fields['maxebitda_multiple'] !== 'Any Min') {
            dataForQuery.maxebitda_multiple = fields['maxebitda_multiple'];
        }

        if(fields['minrevenue_multiple'] && fields['minrevenue_multiple'] !== 'Any Max' && fields['minrevenue_multiple'] !== 'Any Min') {
            dataForQuery.minrevenue_multiple = fields['minrevenue_multiple'];
        }

        if(fields['maxrevenue_multiple'] && fields['maxrevenue_multiple'] !== 'Any Max' && fields['maxrevenue_multiple'] !== 'Any Min') {
            dataForQuery.maxrevenue_multiple = fields['maxrevenue_multiple'];
        }

        if(fields['mincashflow_multiple'] && fields['mincashflow_multiple'] !== 'Any Max' && fields['mincashflow_multiple'] !== 'Any Min') {
            dataForQuery.mincashflow_multiple = fields['mincashflow_multiple'];
        }

        if(fields['maxcashflow_multiple'] && fields['maxcashflow_multiple'] !== 'Any Max' && fields['maxcashflow_multiple'] !== 'Any Min') {
            dataForQuery.maxcashflow_multiple = fields['maxcashflow_multiple'];
        }

        this.makeSearch(JSON.stringify(dataForQuery))

    };


    makeSearch = searchData => {
        return fetch(process.env.REACT_APP_API_ROOT + 'transaction/report', {
            method: 'post',
            body: searchData,
            headers: {
                Accept: 'text/csv',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token')
            },
        })
            .then(function (res) {
                if (res.status < 200 || res.status >= 300) {
                    //console.log("Error 401");
                    //console.log(res);
                    //return res.json().then(err => {throw err;});
                    throw res;
                    //throw Error(.statusText);
                } else {
                    return res
                }
            })
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = `deals-${new Date().getTime()}.csv`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.log(err)
            })
    };

    //
    handleChangeMinEbitdaMultiple = (e) => {
        let fields = this.state.fields;
        fields["minebitda_multiple"] = e.target.value;
        this.setState({fields, minebitdamultipleselected: e.target.value});
    }
    handleChangeMaxEbitdaMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxebitda_multiple"] = e.target.value;
        this.setState({fields, maxebitdamultipleselected: e.target.value});
    }

    handleChangeMinRevenueMultiple = (e) => {
        let fields = this.state.fields;
        fields["minrevenue_multiple"] = e.target.value;
        this.setState({fields, minrevenuemultipleselected: e.target.value});
    }
    handleChangeMaxRevenueMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxrevenue_multiple"] = e.target.value;
        this.setState({fields, maxrevenuemultipleselected: e.target.value});
    }

    handleChangeMinCashflowMultiple = (e) => {
        let fields = this.state.fields;
        fields["mincashflow_multiple"] = e.target.value;
        this.setState({fields, mincashflowmultipleselected: e.target.value});
    }
    handleChangeMaxCashflowMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxcashflow_multiple"] = e.target.value;
        this.setState({fields, maxcashflowmultipleselected: e.target.value});
    }

    onAddBrokerIndustry = item => {
        const exist = _.find(this.state.brokerIndustry, b => b.value === item.value);

        if (!exist) {
            this.setState({
                brokerIndustry: this.state.brokerIndustry.concat(item)
            }, () => {
                this.handleCategoryChange();
            });
        }
    }

    checkedIndustry = item => {
        const exist = _.find(this.state.brokerIndustry, b => b.value === item.value);

        if (exist) {
            return (
                <i className="fa-solid fa-check"/>
            )
        }
    }

    onRemoveBrokerIndustry = item => {
        const brokerIndustry = _.reject(this.state.brokerIndustry, bi => bi.value === item.value);
        this.setState({
            brokerIndustry
        }, () => {
            this.handleCategoryChange();
        });
    }

    onRemoveKeyword = item => {
        const {fields} = this.state;
        const incKeywords = _.reject(fields.searchbox.split(','), k => k === item);
        this.state.fields.searchbox = incKeywords.join(',');
        this.setState({fields});
    }

    onRemoveExcKeyword = item => {
        const {fields} = this.state;
        const excKeywords = _.reject(fields.excluded.split(','), k => k === item);
        this.state.fields.excluded = excKeywords.join(',');
        this.setState({fields});
    }

    onRemoveLocation = loc => {
        const {locations} = this.state;
        const newLocations = _.reject(locations, l => l.location === loc.location);
        this.setState({locations: newLocations});
    }


    handleCategoryChange = () => {
        // this.state.brokerIndustry.map(bi => bi.children && bi.children.length ? [bi.value].concat(bi.children) : []
    }

    cleanBrokerIndustry = () => {
        this.setState({brokerIndustry: []});
    }

    clearMetrics = (a, b) => {
        let fields = this.state.fields;
        fields[a] = '';
        fields[b] = '';
        this.setState({fields});
    }

    filterNiceValue = (value1, value2, removeSign) => {
        const reg = /\B(?=(\d{3})+(?!\d))/g;
        if (value1 && value2) {
            return `${value1 !== 'Any Min' ? `${removeSign ? '' : '$'}` + value1.replace(reg, ",") : value1} - ${value2 !== 'Any Max' ? `${removeSign ? '' : '$'}` + value2.replace(reg, ",") : value2}`;
        } else {
            if (value1 && !value2) {
                return `${value1 !== 'Any Min' ? `Min ${removeSign ? '' : '$'}` + value1.replace(reg, ",") : value1}`;
            } else if (!value1 && value2) {
                return `${value2 !== 'Any Max' ? `Max ${removeSign ? '' : '$'}` + value2.replace(reg, ",") : value2}`;
            } else {
                return '';
            }
        }
    }

    render() {
        const priceValues = {
            price: {
                min: +this.state.fields.minpricerange?.replace(/[^0-9]/g, ''),
                max: +this.state.fields.maxpricerange?.replace(/[^0-9]/g, ''),
            },
            revenue: {
                min: +this.state.fields.minrevenue?.replace(/[^0-9]/g, ''),
                max: +this.state.fields.maxrevenue?.replace(/[^0-9]/g, ''),
            },
            cashflow: {
                min: +this.state.fields.mincashflow?.replace(/[^0-9]/g, ''),
                max: +this.state.fields.maxcashflow?.replace(/[^0-9]/g, ''),
            },
            ebidta: {
                min: +this.state.fields.minebitda?.replace(/[^0-9]/g, ''),
                max: +this.state.fields.maxebitda?.replace(/[^0-9]/g, ''),
            },
            revenue_multiple: {
                min: +this.state.fields.minrevenue_multiple,
                max: +this.state.fields.maxrevenue_multiple,
            },
            ebitda_multiple: {
                min: +this.state.fields.minebitda_multiple,
                max: +this.state.fields.maxebitda_multiple,
            },
            cashflow_multiple: {
                min: +this.state.fields.mincashflow_multiple,
                max: +this.state.fields.maxcashflow_multiple,
            },
        }
        const isPriceValueAvailable = (priceType) => (value, isMin) => {
            if (isMin) {
                return !priceValues[priceType].max || priceValues[priceType].max > value
            }
            return !priceValues[priceType].min || priceValues[priceType].min < value
        }
        Object.keys(priceValues).forEach(priceType => {
            priceValues[priceType].isValid = !priceValues[priceType].min ||
                !priceValues[priceType].max ||
                priceValues[priceType].min < priceValues[priceType].max
        })
        const isButtonDisabled = Object.keys(priceValues).reduce((prev, priceType) => prev || !priceValues[priceType].isValid, false)
        return (
            <div>
                <section
                    style={{boxShadow: '1px 1px 1px #e1e1e1'}}
                    className="content-section-padding homepage sidebar-card-design advanced-search-console"
                >
                    <div className="d-md-block">

                        <div className="row align-items-center row-gutter-10">
                            <div className="col-md-12 mb-2">
                                <div className="advanced-filter-section">
                                    <button style={{borderRadius: '0px'}}
                                            className={`btn btn-filter w-100 text-nowrap w-100 justify-content-between`}
                                            type="button" data-toggle="collapse" data-target="#dropdownIncludeKeywords"
                                            aria-expanded={`false`} aria-controls="collapse-8">
                                        <span>Keywords</span>
                                        <span className="btn-icon">
                                        <span className="d-when-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span className="d-when-not-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
                                                  fill="currentColor"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
                                                  fill="currentColor"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                                    </button>

                                    <div style={{padding: 15}} className={`collapse`} id="dropdownIncludeKeywords">
                                        <p className="mt-1 mb-2" style={{fontSize: 13}}>Included Keywords: </p>
                                        <input
                                            type="text"
                                            maxLength={100}
                                            placeholder={`Try: SaaS or "recurring revenue"`}
                                            className="form-control form-control--filter mb-2"
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    const {fields} = this.state;
                                                    if (fields.searchbox) {
                                                        const k = fields.searchbox.split(',');
                                                        if (this.state.incKeywords) {
                                                            k.push(this.state.incKeywords);
                                                        }

                                                        fields.searchbox = k.join(',');
                                                    } else {
                                                        fields.searchbox = this.state.incKeywords;
                                                    }

                                                    this.setState({fields, incKeywords: ''});
                                                }
                                            }}
                                            value={this.state.incKeywords}
                                            onChange={(e) => {
                                                this.setState({incKeywords: e.target.value});
                                            }}
                                        />
                                        <div className="row align-items-center row-gutter-10">
                                            {this.state.fields.searchbox && this.state.fields.searchbox.split(',').map(bi =>
                                                <div className="col-auto mb-2">
                                                    <button onClick={(e) => e.preventDefault()}
                                                            className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                            style={{padding: '1.5px 11px', wordBreak: 'break-all'}}>
                                                        <span className="me-2">{bi}</span>
                                                        <svg onClick={() => this.onRemoveKeyword(bi)} className="icon"
                                                             viewBox="0 0 14 13" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                  fill="currentColor"/>
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                  fill="currentColor"/>
                                                        </svg>
                                                    </button>
                                                </div>)}
                                        </div>

                                        <p className="mt-3 mb-2" style={{fontSize: 13}}>Excluded Keywords: </p>
                                        <input
                                            type="text"
                                            placeholder={`Try: "Gas Station"`}
                                            maxLength={100}
                                            className="form-control form-control--filter mb-2"
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    const {fields} = this.state;
                                                    if (fields.excluded) {
                                                        const k = fields.excluded.split(',');
                                                        if (this.state.excKeywords) {
                                                            k.push(this.state.excKeywords);
                                                        }

                                                        fields.excluded = k.join(',');
                                                    } else {
                                                        fields.excluded = this.state.excKeywords;
                                                    }

                                                    this.setState({fields, excKeywords: ''});
                                                }
                                            }}
                                            value={this.state.excKeywords}
                                            onChange={(e) => {
                                                this.setState({excKeywords: e.target.value});
                                            }}
                                        />
                                        <div className="row align-items-center row-gutter-10">
                                            {this.state.fields.excluded && this.state.fields.excluded.split(',').map(bi =>
                                                <div className="col-auto mb-2">
                                                    <button onClick={(e) => e.preventDefault()}
                                                            className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                            style={{padding: '1.5px 11px', wordBreak: 'break-all'}}>
                                                        <span className="me-2">{bi}</span>
                                                        <svg onClick={() => this.onRemoveExcKeyword(bi)}
                                                             className="icon" viewBox="0 0 14 13" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                  fill="currentColor"/>
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                  fill="currentColor"/>
                                                        </svg>
                                                    </button>
                                                </div>)}
                                        </div>

                                    </div>

                                    {(!this.state.keywordsToggle && this.state.fields.searchbox) &&
                                        <div className="row align-items-center row-gutter-10" style={{paddingLeft: 15}}>
                                            {this.state.fields.searchbox &&
                                                <p className="mt-1 mb-1" style={{fontSize: 13}}>Included: </p>}
                                            {this.state.fields.searchbox && this.state.fields.searchbox.split(',').map(bi =>
                                                <div className="col-auto mb-2" style={{paddingLeft: 0}}>
                                                    <button onClick={(e) => e.preventDefault()}
                                                            className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                            style={{padding: '1.5px 11px', wordBreak: 'break-all'}}>
                                                        <span className="me-2">{bi}</span>
                                                        <svg onClick={() => this.onRemoveKeyword(bi)} className="icon"
                                                             viewBox="0 0 14 13" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                  fill="currentColor"/>
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                  fill="currentColor"/>
                                                        </svg>
                                                    </button>
                                                </div>)}
                                        </div>}

                                    {(!this.state.keywordsToggle && this.state.fields.excluded) &&
                                        <div className="row align-items-center row-gutter-10" style={{paddingLeft: 15}}>
                                            {this.state.fields.excluded &&
                                                <p className="mt-1 mb-1" style={{fontSize: 13}}>Excluded: </p>}
                                            {this.state.fields.excluded && this.state.fields.excluded.split(',').map(bi =>
                                                <div className="col-auto mb-2" style={{paddingLeft: 0}}>
                                                    <button onClick={(e) => e.preventDefault()}
                                                            className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                            style={{padding: '1.5px 11px', wordBreak: 'break-all'}}>
                                                        <span className="me-2">{bi}</span>
                                                        <svg onClick={() => this.onRemoveExcKeyword(bi)}
                                                             className="icon" viewBox="0 0 14 13" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                  fill="currentColor"/>
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                  fill="currentColor"/>
                                                        </svg>
                                                    </button>
                                                </div>)}
                                        </div>}

                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center row-gutter-10">
                            {/* <!-- Industry Filter --> */}

                            <div className="col-md-12 mb-2">
                                <div className="advanced-filter-section">
                                    <div className="dropdown">
                                        <button style={{borderRadius: '0px', justifyContent: 'flex-start'}}
                                                className={`btn btn-filter w-100`} type="button"
                                                id="dropdownMenuIndustry" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                            <span className="me-1">Industry <span
                                                className="text-xsm">({this.state.brokerIndustry.length})</span></span>
                                            <span className="text-xsm">
                                <svg className="icon" viewBox="0 0 20 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z"
                                          fill="currentColor"/>
                                </svg>
                            </span>

                                        </button>
                                        <div className="dropdown-menu dropdown-menu--minw-260"
                                             aria-labelledby="dropdownMenuIndustry">
                                            <div className="dropdown-content-padding pb-0">
                                                <div className="row row-gutter-10">
                                                    {this.state.brokerIndustry.map(bi => <div className="col-auto mb-2">
                                                        <button onClick={(e) => e.preventDefault()}
                                                                className="btn btn-sm btn-primary-blue btn-text-xsm">
                                                            <span className="me-2">{bi.label}</span>
                                                            <svg onClick={() => this.onRemoveBrokerIndustry(bi)}
                                                                 className="icon" viewBox="0 0 14 13" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd"
                                                                      d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                      fill="currentColor"/>
                                                                <path fillRule="evenodd" clipRule="evenodd"
                                                                      d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                      fill="currentColor"/>
                                                            </svg>
                                                        </button>
                                                    </div>)}
                                                </div>
                                            </div>
                                            {this.state.brokerIndustry.length > 0 &&
                                                <div className="dropdown-divider"/>}
                                            {this.state.categorylist.map(item => <>
                                                <a onClick={() => this.onAddBrokerIndustry(item)}
                                                   className={`dropdown-item ${item.children.length > 0 ? 'submenu' : ''}`}
                                                   href="javascript:void(0)">{this.checkedIndustry(item)} {item.label}</a>
                                                {item.children.length > 0 && <div className="dropdown-menu">
                                                    {item.children.map(c =>
                                                        <a className="dropdown-item"
                                                           onClick={() => this.onAddBrokerIndustry(c)}
                                                           href="javascript:void(0)">{this.checkedIndustry(c)} {c.label}</a>
                                                    )}
                                                </div>}
                                            </>)}

                                        </div>
                                    </div>
                                    {this.state.brokerIndustry.length > 0 &&
                                        <span onClick={() => this.cleanBrokerIndustry()}
                                              className="text-xsm cursor_pointer">
                            <svg style={{position: 'absolute', top: 11, right: 17, color: '#6D7277'}} className="icon"
                                 viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                      fill="currentColor"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                      fill="currentColor"/>
                            </svg>
                        </span>}
                                    {this.state.brokerIndustry.length > 0 &&
                                        <div className="row align-items-center row-gutter-10 ms-2">
                                            {this.state.brokerIndustry.map(bi => <div className="col-auto mb-2 mt-2"
                                                                                      style={{paddingLeft: 0}}>
                                                <button onClick={(e) => e.preventDefault()}
                                                        className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                        style={{padding: '1.5px 11px'}}>
                                                    <span className="me-2">{bi.label}</span>
                                                    <svg onClick={() => this.onRemoveBrokerIndustry(bi)}
                                                         className="icon" viewBox="0 0 14 13" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                              fill="currentColor"/>
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                              fill="currentColor"/>
                                                    </svg>
                                                </button>
                                            </div>)}
                                        </div>}
                                </div>
                            </div>
                        </div>

                        {/* <!-- Search by location --> */}
                        <div className="row align-items-center row-gutter-10">
                            <div className="col-md-12 mb-2">
                                <div className="advanced-filter-section">


                                    <div>
                                        <button style={{borderRadius: '0px'}}
                                                className={`btn btn-filter w-100 text-nowrap w-100 justify-content-between`}
                                                type="button" data-toggle="collapse" data-target="#dropdownLocation"
                                                aria-expanded={`false`} aria-controls="collapse-8">
                                            <span>Locations</span>
                                            <span className="btn-icon">
                                        <span className="d-when-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span className="d-when-not-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
                                                  fill="currentColor"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
                                                  fill="currentColor"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                                        </button>
                                        <div style={{padding: 15}} className={`collapse`} id="dropdownLocation">


                                            <Geosuggest
                                                inputClassName={`form-control form-control--filter`}
                                                id="by-location"
                                                name="location"
                                                ref="location"
                                                placeholder="Search by location"
                                                onSuggestSelect={this.handleGeosuggestChange}
                                            />

                                        </div>

                                    </div>
                                    {this.state.locations.length > 0 &&
                                        <div className="row align-items-center row-gutter-10 ms-2 mt-2">
                                            {this.state.locations.map(loc => <div className="col-auto mb-2"
                                                                                  style={{paddingLeft: 0}}>
                                                <button onClick={(e) => e.preventDefault()}
                                                        className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                        style={{padding: '1.5px 11px'}}>
                                                    <span className="me-2">{loc.location}</span>
                                                    <svg onClick={() => this.onRemoveLocation(loc)} className="icon"
                                                         viewBox="0 0 14 13" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                              fill="currentColor"/>
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                              fill="currentColor"/>
                                                    </svg>
                                                </button>
                                            </div>)}
                                        </div>}
                                </div>
                            </div>

                        </div>


                        <div className="">

                            {/* <!-- Price --> */}
                            <PriceFilter
                                title="Price Range"
                                maxValue={this.state.fields["maxpricerange"]}
                                minValue={this.state.fields["minpricerange"]}
                                maxName="maxpricerange"
                                minName="minpricerange"
                                clearMetrics={this.clearMetrics}
                                filterNiceValue={this.filterNiceValue}
                                valueMap={priceValues.price}
                                isOptionAvailable={isPriceValueAvailable('price')}
                                handleChange={this.handleChangeSearch}
                                handleMaxChange={this.handleChangeMaxPriceRange}
                                handleMinChange={this.handleChangeMinPriceRange}
                            />
                            {/* <!-- Annual revenue --> */}
                            <PriceFilter
                                title="Annual revenue"
                                maxValue={this.state.fields["maxrevenue"]}
                                minValue={this.state.fields["minrevenue"]}
                                maxName="maxrevenue"
                                minName="minrevenue"
                                clearMetrics={this.clearMetrics}
                                filterNiceValue={this.filterNiceValue}
                                valueMap={priceValues.revenue}
                                isOptionAvailable={isPriceValueAvailable('revenue')}
                                handleChange={this.handleChangeSearch}
                                handleMaxChange={this.handleChangeMaxRevenue}
                                handleMinChange={this.handleChangeMinRevenue}
                            />


                            {/* <!-- Annual profit --> */}
                            <PriceFilter
                                title="Annual profit"
                                maxValue={this.state.fields["maxcashflow"]}
                                minValue={this.state.fields["mincashflow"]}
                                maxName="maxcashflow"
                                minName="mincashflow"
                                clearMetrics={this.clearMetrics}
                                filterNiceValue={this.filterNiceValue}
                                valueMap={priceValues.cashflow}
                                isOptionAvailable={isPriceValueAvailable('cashflow')}
                                handleChange={this.handleChangeSearch}
                                handleMaxChange={this.handleChangeMaxcashflow}
                                handleMinChange={this.handleChangeMincashflow}
                            />

                            {/* <!-- Revenue multiple --> */}
                            <PriceFilter
                                title="Revenue multiple"
                                maxValue={this.state.fields["maxrevenue_multiple"]}
                                minValue={this.state.fields["minrevenue_multiple"]}
                                maxName="maxrevenue_multiple"
                                minName="minrevenue_multiple"
                                clearMetrics={this.clearMetrics}
                                filterNiceValue={this.filterNiceValue}
                                valueMap={priceValues.revenue_multiple}
                                isOptionAvailable={isPriceValueAvailable('revenue_multiple')}
                                handleChange={this.handleChangeSearch}
                                handleMaxChange={this.handleChangeMaxRevenueMultiple}
                                handleMinChange={this.handleChangeMinRevenueMultiple}
                                isMultiple
                            />
                            {/* <!-- SDE Multiple --> */}
                            <PriceFilter
                                title="SDE Multiple"
                                maxValue={this.state.fields["maxcashflow_multiple"]}
                                minValue={this.state.fields["mincashflow_multiple"]}
                                maxName="maxcashflow_multiple"
                                minName="mincashflow_multiple"
                                clearMetrics={this.clearMetrics}
                                filterNiceValue={this.filterNiceValue}
                                valueMap={priceValues.cashflow_multiple}
                                isOptionAvailable={isPriceValueAvailable('cashflow_multiple')}
                                handleChange={this.handleChangeSearch}
                                handleMaxChange={this.handleChangeMaxCashflowMultiple}
                                handleMinChange={this.handleChangeMinCashflowMultiple}
                                isMultiple
                            />

                            {/* <!-- EBITDA Multiple --> */}
                            <PriceFilter
                                title="EBITDA Multiple"
                                maxValue={this.state.fields["maxebitda_multiple"]}
                                minValue={this.state.fields["minebitda_multiple"]}
                                maxName="maxebitda_multiple"
                                minName="minebitda_multiple"
                                clearMetrics={this.clearMetrics}
                                filterNiceValue={this.filterNiceValue}
                                valueMap={priceValues.ebitda_multiple}
                                isOptionAvailable={isPriceValueAvailable('ebitda_multiple')}
                                handleChange={this.handleChangeSearch}
                                handleMaxChange={this.handleChangeMaxEbitdaMultiple}
                                handleMinChange={this.handleChangeMinEbitdaMultiple}
                                isMultiple
                            />

                            {/* <!-- Date added --> */}
                            <div className="row align-items-center row-gutter-10">
                                <div className="col-md-12 mb-2">
                                    <div className="dropdown">
                                        <button style={{borderRadius: '0px'}}
                                                className={`btn btn-filter w-100 text-nowrap w-100 justify-content-between`}
                                                type="button" data-toggle="collapse" data-target="#dropdownDate"
                                                aria-expanded={`false`} aria-controls="collapse-8">
                                            <span>Date Range</span>
                                            <span className="btn-icon">
                                        <span className="d-when-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span className="d-when-not-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
                                                  fill="currentColor"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
                                                  fill="currentColor"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                                        </button>
                                        <div style={{padding: 15}} className={`collapse`} id="dropdownDate">
                                            <div className="py-2 px-3">
                                                <div className="row row-gutter-15">
                                                    <div className="col">
                                            <input type="text"
                                                   autoComplete="off"
                                                   className="form-control form-control--filter"
                                                   onChange={(e) => {
                                                       this.handleChangeSearch('fromDateRange', e)
                                                   }}
                                                   value={this.state.fields.fromDateRange}
                                                   placeholder="From - MM/DD/YYYY" name="fromDateRange"/>
                                                    </div>
                                                    <div className="col">
                                            <input type="text"
                                                   autoComplete="off"
                                                   className="form-control form-control--filter"
                                                   onChange={(e) => {
                                                       this.handleChangeSearch('toDateRange', e)
                                                   }}
                                                   value={this.state.fields.toDateRange}
                                                   placeholder="To - MM/DD/YYYY" name="toDateRange"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div className="">
                                    <button disabled={isButtonDisabled} onClick={this.getSearchResultlist}
                                            className="btn btn-primary w-100 mb-2 mt-2" style={{height: 40}}>
                                        Download File
                                    </button>
                                </div>

                            </div>

                            <div className="row">
                                <div className="text-sm col-md-6 mt-1">
                                    <a href="/console/reports" className="btn btn-unstyled btn-link">Clear All</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}


export default connect()(ReportCopy);
