import React, {useState} from 'react'
import AvatarEditor from 'react-avatar-editor'
import {FormGroup, Input, Label} from "reactstrap";

const ImageEditor = ({image, setRef, height, width, zoom, borderRadius}) => {
    const [scale, setScale] = useState(1)
    return (<>
            <AvatarEditor
                image={image}
                ref={setRef}
                width={width}
                height={height}
                border={20}
                scale={scale || 1}
                rotate={0}
                borderRadius={borderRadius || 0}
            />
            {
                zoom
                    ? <FormGroup inline style={{display: 'flex'}}>
                        <Label for="zoom" className="me-3">
                            Zoom:
                        </Label>
                        <Input
                            id="zoom"
                            name="range"
                            type="range"
                            min={0.5}
                            step={0.1}
                            max={2}
                            onChange={(event) => setScale(Number(event.target.value))}
                            value={scale}
                        />
                    </FormGroup>
                    : ''
            }
        </>
    )
}

export default ImageEditor
