import React from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, Label } from 'reactstrap';



class Forgotpass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {}
    };
  }

  handleChange(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  componentDidMount() {
    document.getElementById('loaderpopup').setAttribute("style", "display: none;");
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //Email
    if (!fields["emaillogin"]) {
      formIsValid = false;
      errors["emaillogin"] = "Please enter email";
    }

    if (typeof fields["emaillogin"] !== "undefined") {
      let lastAtPos = fields["emaillogin"].lastIndexOf('@');
      let lastDotPos = fields["emaillogin"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["emaillogin"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["emaillogin"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["emaillogin"] = "Please enter valid email address";
      }
    }
    
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = event => {
    event.preventDefault();
    if (this.handleValidation()) {

      document.getElementById('loaderpopup').setAttribute("style", "display: block;");
      let emailId = this.state.fields["emaillogin"];
      let jsonsetLoginData = JSON.stringify({
        "email": emailId
      });
      //console.log(jsonsetLoginData);
      fetch(process.env.REACT_APP_API_ROOT + 'user/forgotpassword', {
      //fetch('http://localhost:8080/user/forgotpassword', {
        method: 'POST',
        body: jsonsetLoginData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*"
        },
      }).then(function (res) {
        if (res.status < 200 || res.status >= 300) {
          //console.log("Error 401");
          //console.log(res);
          //return res.json().then(err => {throw err;});
          throw res;
          //throw Error(.statusText);
        } else { return res.json() }
      })
        .then(
          (res) => {
            //console.log(res.data.status);
            if(res.data.status==="false") {
              
              let errors = {};
              errors["errorMessage"] = res.data.message;
              this.setState({ errors: errors });
              document.getElementById('loaderpopup').setAttribute("style", "display: none;");

            }else{
              
              let errors = {};
              errors["successMessage"] = "We have sent you a mail with new password.";
              localStorage.setItem('email', emailId);
              this.setState({ errors:errors });
              document.getElementById('loaderpopup').setAttribute("style", "display: none;");
              //this.props.history.push('/login');
              //console.log(res.data.token);

            }
          },
          (error) => {
            this.setState({
              //error
            });
          }
        )


    }else{
      //console.log("Form has errors.");
    }
  }

  render() {
    return (
      <section className="signup-section">
        <div className="loader bg-white" id="loaderpopup"><h3>Please Wait...</h3><div className="spiner"></div></div>
        <img src={process.env.PUBLIC_URL + '/images/biznexus-logo.png'} style={{ width: 250 }} alt="logo" className="d-block m-auto" />
        <div className="signup-bx">
          <h4 className="text-center">Forgot your password?</h4>
          <p className="text-center">Please enter the email address associated with your account and <br />we’ll email you a link to reset your password.</p>
          <p className="text-center success text-success">{this.state.errors["successMessage"]}</p>
          <p className="text-center danger ">{this.state.errors["errorMessage"]}</p>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label for="emailaddress">Email Address*</Label>
              <Input type="email" name="emaillogin" id="emaillogin" placeholder="Email Address" ref="emaillogin" autoFocus onChange={this.handleChange.bind(this, "emaillogin")} value={this.state.fields["emaillogin"]} />
              <span className="error ">{this.state.errors["emaillogin"]}</span>
            </FormGroup>
            <button type="submit" className="btn-blue-login mt-0">Reset Password</button>
          </Form>
          <div className="text-center">
            <p className="lgn-link-login gray-help-text">Don't have an account? <Link to={'/signup'}> Sign up</Link></p>
          </div>
        </div>

      </section>
    );
  }
}
export default Forgotpass;
