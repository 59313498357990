import React from 'react';
import SaleListing from '../../SaleListing';


export default class Listings extends React.Component {

    render() {
        return (
            <React.Fragment>
                <SaleListing userId={this.props.userId} />
            </React.Fragment>
        )
    }


}