export const FeaturedListingType = {
  GOLD: 'gold',
  SILVER: 'silver',
  BRONZE: 'bronze',
};

export const TransactionCompletedStatuses = {
  Completed: 1,
  NotCompleted: 0,
  Private: 2,
  UnderReview: 3,
  Rejected: 4,
  Off: 5,
  Archived: 6,
  CompletedTurnedOff: 7,
}

export const LeasedOrOwned = {
  1: 'Leased',
  2: 'Owned',
}
