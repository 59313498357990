import React, {useEffect, useMemo, useState} from "react";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Select from "react-select";
import {SubscriptionLevels} from "../../../constants/subscriptionLevels";
import DatePicker from "react-datepicker";
import './user-subscriptions-modal.scss'

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {isEqual} from "lodash";

const levels = Object.keys(SubscriptionLevels);
const options = [{ label: 'No Subscription', value: 0 }].concat( levels.map(l => ({ label: l, value: SubscriptionLevels[l] })) )

const UserSubscriptionModal = ({ user, isOpen, onSubmit, onClose} = { onClose(){}, onSubmit(){} } ) => {
    const initialData = {
        subscription: options.find(({value}) => value === +(user.premium_level || 0)),
        isContract: user?.premium_obj?.is_contract,
        dueDate: user?.premium_obj?.due_date,
    }

    const [dateDisabled, setDateDisabled] = useState(false)
    const [data, setData] = useState(initialData)
    const buttonDisabled = useMemo(() => {
        return isEqual(data, initialData)
    }, [data])
    const closeModal = () => {
        onClose()
    }

    const onSubscriptionChange = (option) => {
        if (!option.value) {
            setData({
                subscription: option,
                dueDate: null,
                isContract: false,
            })
        } else {
            setData({
                ...data,
                subscription: option,
                dueDate: !data.subscription.value ? new Date(moment().add(1, 'year')) : data.dueDate,
            })
        }
    }

    const onContractChange = event => {
        const isContract = event.target.checked
        setData({
            ...data,
            isContract,
            dueDate: isContract ? null: data.dueDate,
        })
        setDateDisabled(isContract)
    }

    const onDueDateChange = date => {
        console.log(date)
        setData({
            ...data,
            dueDate: date,
        })
    }

    const onNoDueDateChange = event => {
        const noDueDate = event.target.checked

        setDateDisabled(noDueDate)

        if (noDueDate) {
            setData({
                ...data,
                dueDate: null,
            })
        }

    }

    const handleSubmit = () => {
        onSubmit(user.email, {
            level: data.subscription.value,
            due_date: data.dueDate,
            is_contract: data.isContract
        }, closeModal)
    }

    return (<>
            <Modal size="md" isOpen={isOpen} toggle={closeModal}
                   className="modal-dialog-centered user-subscription-modal" keyboard={true} backdrop="static">
                <ModalHeader toggle={closeModal}>
                    <strong className="deal-notes_deal-name">{user.name}'s Subscription</strong>
                </ModalHeader>
                <ModalBody>
                    <FormGroup className="row" noMargin>
                        <Label for="professionlist" className="col-sm-4 d-flex align-items-center">Subscription</Label>
                        <div className="col-sm-8 mb-3">
                            <Select
                                classNamePrefix="Select"
                                classNames={{
                                    control: (state) =>
                                        state.isFocused ? 'Select-control' : 'Select-control',
                                }}
                                className="mt-2"
                                isSearchable={false}
                                isClearable={false}
                                placeholder="Subscription"
                                onChange={onSubscriptionChange}
                                options={options}
                                value={data.subscription}
                            />
                        </div>

                    </FormGroup>
                    <FormGroup className="row" noMargin>
                        <Label for="professionlist" className="col-sm-4 d-flex align-items-center">Is Contract</Label>
                        <div className="col-sm-8">
                            <Input type="checkbox"
                                   id="override"
                                   disabled={!data.subscription.value}
                                   name="override"
                                   onChange={onContractChange}
                                   className="custom-control-input"
                                   checked={data.isContract}/>
                        </div>
                    </FormGroup>
                    <FormGroup className="row" noMargin>
                        <Label for="professionlist" className="col-sm-4 d-flex align-items-center">Due Date</Label>
                        <div className="col-sm-5">
                            <div className={dateDisabled || !data.subscription.value ? 'disabled-date-picker' : ''}>
                                <DatePicker minDate={new Date()} className={dateDisabled || !data.subscription.value ? 'disabled' : ''} disabled={dateDisabled || !data.subscription.value} selected={data.dueDate} onChange={onDueDateChange} />
                            </div>
                        </div>
                        {
                            data.dueDate
                                ? <div className="col-sm-3">
                                    <Button type="button" className="btn-bluedark" onClick={() => onDueDateChange(null)}>Clear</Button>
                                </div>
                                : ''
                        }
                        {
                            dateDisabled
                                ? <div className="col-sm-3">
                                    <Button type="button" className="btn-bluedark" onClick={() => setDateDisabled(false)}>Enable</Button>
                                </div>
                                : ''
                        }

                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-between w-100">
                        <Button color="danger" type="button" className="btn-bluedark" onClick={closeModal}>Cancel</Button>
                        <div className="d-flex justify-content-end w-100">
                            <Button type="button" className="btn-sm" disabled={buttonDisabled} onClick={() => setData(initialData)}>Reset</Button>
                            <Button color="success" type="button" className="btn btn-primary btn-sm ms-1" disabled={buttonDisabled} onClick={handleSubmit}>Save</Button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
    </>
    )
}

export default UserSubscriptionModal
