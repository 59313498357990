import React, {useEffect, useState} from 'react';
import {Button, Col, FormGroup, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

export default function ChangeName ({ item, onClickUpdateName, target }) {
    const [editPopup, setEditPopup] = useState(false);
    const [name, setName] = useState(item.name);

    useEffect(() => {
        setName(item.name)
    }, [item.name])

    return (
        <div onClick={e => {
            e.preventDefault();
            e.stopPropagation();
        }}>
            <i onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setEditPopup(true);
            }} className="fa-solid fa-pen cursor_pointer"></i>
            <Modal isOpen={editPopup}
                   toggle={() => {
                setEditPopup(!editPopup)
            }} keyboard={false} backdrop="static">
                <ModalHeader toggle={() => setEditPopup(!editPopup)}>Edit {item.name}</ModalHeader>
                <ModalBody className="pt-3 pb-5">
                    <FormGroup row>
                        <Label md={5}>Name</Label>
                        <Col md={6}>
                            <InputGroup>
                                <input onChange={e => setName(e.target.value)} value={name} className="form-control"></input>
                            </InputGroup>
                        </Col>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        setEditPopup(false);
                        onClickUpdateName(name, item.id, target);
                    }}>Update</Button>
                </ModalFooter>
            </Modal>
        </div>



    )
}
