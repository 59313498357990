import React, { useState } from 'react';
import api from '../../services/api';
import {
  Table,
  Row,
  Col,
  Label,
  InputGroup,
  FormGroup,
  ModalHeader,
  Button,
  ModalFooter, ModalBody, Modal
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {DndProvider, useDrag, useDrop} from "react-dnd";
import update from "immutability-helper";
import {HTML5Backend} from "react-dnd-html5-backend";
import ExternalLink from "../common/ExternalLink";


const TableRow = ({ index, item, moveRow, onDelete, dropRow, onCancelEdit }) => {
  const ref = React.useRef(null);
  const [name, setName] = useState(item.name);
  const [url, setUrl] = useState(item.url);
  const [editPopup, setEditPopup] = useState(false);

  const [, drop] = useDrop({
    accept: "row",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop(item) {
      dropRow();
    }
  });
  const [{ isDragging }, drag] = useDrag({
    type: "row",
    item: { type: "row", id: item.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const onEdit = item => {
    setEditPopup(true);
    setName(item.name);
    setUrl(item.url);
  }

  const onClickUpdate = () => {
    onCancelEdit({ id: item.id, name, url });
    setEditPopup(false);
  }

  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));
  return (
      <>
        <tr ref={ref} className="cursor_pointer" style={{ opacity, textAlign: 'center' }}>
          <td>{item.name}</td>
          <td><ExternalLink href={item.url}>{item.url}</ExternalLink></td>
          <td>
            <button onClick={() => onEdit(item)} className="btn btn-sm btn-primary">Edit</button>
            <button onClick={() => onDelete(item.id)} className="ms-1 btn btn-sm btn-danger">Remove</button>
          </td>
        </tr>
        <Modal isOpen={editPopup} toggle={() => {
          setEditPopup(!editPopup)
        }} keyboard={false} backdrop="static">
          <ModalHeader toggle={() => setEditPopup(!editPopup)}>{item.name}</ModalHeader>
          <ModalBody className="pt-3 pb-5">
            <FormGroup row>
              <Label md={5}>Label</Label>
              <Col md={6}>
                <InputGroup>
                  <textarea style={{ height: 100 }} onChange={e => setName(e.target.value)} value={name} className="form-control"></textarea>
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={5}>URL</Label>
              <Col md={6}>
                <InputGroup>
                  <textarea style={{ height: 100 }} onChange={e => setUrl(e.target.value)} value={url} className="form-control"></textarea>
                </InputGroup>
              </Col>
            </FormGroup>

          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={onClickUpdate}>Update</Button>
          </ModalFooter>
        </Modal>

      </>
  );
};



export default class ConciergeLinks extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      name: '',
      url: '',
      clientName: '',
    };

  }

  componentDidMount() {
    this.init();
  }

  init() {
    api.get(`console/concierge_links/${this.props.id}`, null, true).then(list => {
      this.setState({ list });
    });

    api.get(`console/fetch_user/${this.props.id}`, null, true).then(user => {
      this.setState({ clientName: user.name });
    });
  }

  onAdd() {
    const { name, url } = this.state;
    if(name && url) {
      this.setState({ name: '', url: '' });

      api.post(`console/add_concierge_link`, {
        name, url, user_id: this.props.id
      }, true).then(() => {
        this.init();
      });
    }
  }

  onDelete(id) {
    api.post(`console/delete_concierge_link`, {
      id
    }, true).then(() => {
      this.init();
    });
  }


  onCancelEdit = ({ id, name, url }) => {
    api.post(`console/update_links`, { id, name, url }, true).then(() => {
      this.init();
    });
  }

  moveRow = (dragIndex, hoverIndex) => {
    const { list } = this.state;
    const dragRow = list[dragIndex];
    const newRows = update(list, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRow],
      ],
    })
    this.setState({
     list: newRows
    });
  };

  dropRow = () => {
    api.post(`console/update_links_order`, { list: this.state.list.map((item, index) => ({...item, index})) }, true);
  }

  render() {

    return (
        <div>
          <Link to="/console/concierge">&larr; Back</Link>
          <br /><br />
          <h2 className="h2">{this.state.clientName}</h2>

          <Row>

            <Col sm="8">
              <DndProvider backend={HTML5Backend}>
                <Table bordered hover responsive>
                  <thead style={{ textAlign: 'center' }}>
                  <tr>
                    <th>Label</th>
                    <th>URL</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.list.map((link, index) =>
                      <TableRow
                          key={link.id}
                          index={index}
                          item={link}
                          moveRow={this.moveRow}
                          dropRow={this.dropRow}
                          onDelete={this.onDelete}
                          onCancelEdit={this.onCancelEdit}
                      />
                  )}
                  </tbody>
                </Table>
              </DndProvider>
            </Col>

            <Col sm="4">
              <div className="mb-5">
                <div>
                  <label className="">Label</label>
                  <input placeholder="Label..." className="form-control" type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                </div>

                <div className="mt-2">
                  <label className="">URL</label>
                  <input placeholder="URL..." className="form-control" type="text" value={this.state.url} onChange={(e) => this.setState({ url: e.target.value })} />
                </div>

                <div className="mt-4 w-100">
                  <button onClick={() => this.onAdd()} className="btn btn-primary">Add New Link</button>
                </div>
              </div>
            </Col>

          </Row>
        </div>
    )
  }
}

