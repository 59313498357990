import React from 'react';
import {
    FormGroup,
    Label,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input, Col, Nav, NavItem, NavLink, Table, TabContent, TabPane, CardTitle, CardBody, Card, CardText
} from 'reactstrap';
import classnames from 'classnames'
import moment from 'moment'
import _ from 'lodash';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import api from '../../../services/api';
import FeaturedProfile from '../../FeaturedProfile'
import {trackEvent} from '../../util';
import './ads.scss';
import {Link} from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import ConfirmationPopup from "../../ConfirmationPopup/confirmationPopup";
import Dropzone from "../../Dropzone";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

class AdsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            inactive: [],
            boostedProfiles: [],
            boostedProfilesInactive: [],
            isOpen: false,
            modalName: '',
            modalType: '',
            activePlan: null,
            file: null,
            image: null,
            linkTo: '',
            text: '',
            errors: {

            },
            activeTab: 1,
            activeTabBoosted: 1,
            views: [],
            clicks: [],
            plans: [],
            metadata: {},
            boostModalOpen: false,
            header: {},
            needApproveModal: false,
            editModal: false,
            editAd: {},
            currentStep: '',
            index: -1,
        };
    }

    setIndex(index) {
        this.setState({ index });
    }

    componentDidMount() {
        document.getElementById('loader').setAttribute("style", "display: block;");

        const params = (new URL(document.location)).searchParams;
        if (params.has('type')) {
            this.setState({
                eventModal: true,
                eventHeader: 'Thank You!',
                eventMessage: params.get('type') === 'ad'
                    ? 'Your ad was successfully added.'
                    : 'Your profile was successfully boosted.'
            })
        }
        this.getList();
    }

    async getList() {
        await api.get('user/header', null, true)
            .then(res => {
                this.setState({ header: res });
            });

        await api.get('ads/list', null, true)
            .then(res => {
                this.setState({ list: res });
            });

        await api.get('ads/boosted-profiles', null, true)
            .then(res => {
                this.setState({ boostedProfiles: res.list, boostedProfilesInactive: res.inactive });
            });


        await api.get('ads/plans', null, true)
            .then(res => {
                this.setState({ plans: res });
            });

        await api.get('ads/list_inactive', null, true)
            .then(res => {
                this.setState({ inactive: res });
                document.getElementById('loader').setAttribute("style", "display: none;");
            });
    }

    updateCurrentStep(step) {
        this.setState({
            currentStep: step
        });
    }

    goBack() {
        this.updateCurrentStep('')
    }

    createCheckoutSession = (planId) => {
        if(this.state.modalType === 'image') {
            const { name: filename, filetype: type } = this.state.file;

            const imgUrl = this.state.image
            return fetch(imgUrl)
                .then(r => r.blob())
                .then(blob => new File([blob], filename, { type: type }))
                .then(file => {
                    const data = new FormData();
                    data.append('file', file);
                    data.append('planId', planId);
                    data.append('linkTo', this.state.linkTo);
                    data.append('modalType', this.state.modalType);

                    return fetch(process.env.REACT_APP_API_ROOT + `ads/create-checkout-session`, {
                        method: 'POST',
                        body: data,
                        headers: {
                          Accept: 'application/json',
                          "Access-Control-Allow-Origin": "*",
                          'x-access-token': localStorage.getItem('token'),
                      }})
                      .then(res => {
                        if (res.status < 200 || res.status >= 300) {
                          res.json().then(r => {throw r})
                        } 
                        else { 
                          return res.json();
                        }
                      })
                });
        } else if(this.state.modalType === 'text') {
            const { modalType, text } = this.state;
            let linkTo = this.state.linkTo;
            if(! linkTo.startsWith('https://')) {
                linkTo = `https://${linkTo}`;
            }
            return api.post('ads/create-checkout-session', { planId, modalType, text, linkTo }, true);
        }
    }

    onCheckout = (e) => {
        e.preventDefault();
        // if( ! this.state.linkTo.startsWith('https://')) {
        //     this.setState({ errors: { link: 'Link is invalid' } });
        //     return;
        // }

        if(this.state.text.length > 100) {
            this.setState({ errors: { text: 'Text is too long' } });
            return;
        }

        if(this.state.linkTo.length > 100) {
            this.setState({ errors: { link: 'Link is too long' } });
            return;
        }
        const { activePlan } = this.state;
        trackEvent({ category: 'Ads ', action: this.state.modalName });
        this.createCheckoutSession(activePlan)
            .then(res => {
                this.props.stripe
                    .redirectToCheckout({
                        sessionId: res.sessionId,
                    })
                    .then(r => {
                        if (r.error) {
                            console.log(r.error);
                        }
                    })
            });
    }

    openModal = ({ planId }) => {
        if(this.state.header.status === '0') {
            this.setState({ needApproveModal: true });
            return;
        }
        const plan = _.find(this.state.plans, { id: planId });
        this.setState({
            activePlan: planId,
            modalName: plan.nickname,
            modalType: plan.metadata.type,
            metadata: plan.metadata,
        }, () => {
            this.toogle();
        })
    }

    toogle = () => {
        this.setState({
            isOpen: ! this.state.isOpen,
            errors: {},
            image: null,
            file: null,
        });
    }

    onFileError = failedResults => {
        this.setState({ errors: { image: failedResults[0].errors[0].message } });
    }

    onSelectFile = async value => {
        const d = await this.getImageDimensions(value[0]);
        this.setState({ errors: {}});
        if(d.w === parseInt(this.state.metadata.width) && d.h === parseInt(this.state.metadata.height)) {
            this.setState({
                file: value[0],
                image: d.src,
            });
        } else {
            this.setState({ errors: { image: `Image should be ${this.state.metadata.width}x${this.state.metadata.height}` } });
        }
    }

    getImageDimensions = file => {
        return new Promise (function (resolved, rejected) {
          var i = new Image()
          i.onload = function(){
            resolved({w: i.width, h: i.height, src: i.src})
          };
            const reader = new FileReader();
            reader.onload = (event) => {
                i.src = event.target.result;
            };
            reader.readAsDataURL(file);
        })
    }
    toggleUnsubscribe = (id, type) => {
        this.setState({ unsubscribeId: id, unsubscribeType: type })
    }
    closeConfirmationPopup = () => {
        this.setState({
            unsubscribeLoading: false,
            unsubscribeId: null,
            unsubscribeType: null,
        })
    }

    editBoostedProfile = s => {
        this.setState({editingProfile: s})
    }
    closeEditProfileModal = () => {
        this.setState({
            editingProfile: null,
            editLoading: false
        })
    }

    handleEditBoostedProfile = (description) => {
        this.setState({editLoading: true})
        //if(confirm('Please, confirm your action')) {
        api.post(`promote/update-profile/${this.state.editingProfile.id}`, { description }, true)
            .finally(async () => {
                this.closeEditProfileModal()
                await this.getList()
            });

    }

    unsubscribe = async () => {
        this.setState({unsubscribeLoading: true})
        //if(confirm('Please, confirm your action')) {
            api.post(`${this.state.unsubscribeType === 'profiles' ? 'promote' : 'ads'}/unsubscribe${this.state.unsubscribeType === 'profiles' ? '-profile' : ''}`, { id: this.state.unsubscribeId }, true)
            .then(() => {
                setTimeout(() => {
                    this.getList()
                    this.closeConfirmationPopup()
                }, 300);
            });
        //}
    }

    getAmount = planId => {
        const plan = _.find(this.state.plans, { id: planId });
        if(plan) {
            return '$' + plan.amount / 100;
        }

        return null;
    }

    openProfileBoost() {
        if(this.state.header.status === '0') {
            this.setState({ needApproveModal: true });
            return;
        }
        this.setState({ boostModalOpen: true });
    }

    editAd = ad => {
        const plan = _.find(this.state.plans, { id: ad.stripe_plan_id });
        this.setState({
            editModal: true,
            editAd: ad,
            modalType: ad.type,
            text: ad.text,
            metadata: plan.metadata,
            linkTo: ad.link_to
        });
    }

    updateAd = (data) => {
        this.setState({
            editModal: false,
            editAd: {},
            text: '',
            linkTo: '',
            errors: {},
            image: null,
            file: null,
        });

        return fetch(process.env.REACT_APP_API_ROOT + 'ads/update', {
            method: 'POST',
            body: data,
            headers: {
                Accept: 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            }})
            .then(res => {
                if (res.status < 200 || res.status >= 300) {
                    res.json().then(r => {throw r})
                } else {
                    this.getList();
                    return res.json();
                }
            })
    }

    onEdit = () => {
        const id = this.state.editAd.id;
        if(this.state.text.length > 100) {
            this.setState({ errors: { text: 'Text is too long' } });
            return;
        }

        if(this.state.linkTo.length > 100) {
            this.setState({ errors: { link: 'Link is too long' } });
            return;
        }

        if(this.state.modalType === 'image') {
            if (this.state.file) {
                const { filename, filetype: type } = this.state.file;
                return fetch(this.state.file.result)
                    .then(r => r.blob())
                    .then(blob => new File([blob], filename, { type: type }))
                    .then(file => {
                        const data = new FormData();
                        data.append('file', file);
                        data.append('linkTo', this.state.linkTo);
                        data.append('id', id);

                        return this.updateAd(data)
                    });
            }

            const data = new FormData();
            data.append('linkTo', this.state.linkTo);
            data.append('id', id);

            return this.updateAd(data);

        } else if(this.state.modalType === 'text') {
            const { text } = this.state;
            let linkTo = this.state.linkTo;
            if(! linkTo.startsWith('https://')) {
                linkTo = `https://${linkTo}`;
            }
            this.setState({
                editModal: false,
                editAd: {},
                text: '',
                linkTo: '',
                errors: {},
                image: null,
                file: null,
            });
            return api.post('ads/update', { text, linkTo, id }, true)
                .then(() => {
                    this.getList();
                })
        }


    }

    render() {
        const isUpdateBtnDisabled = this.state.modalType === 'text'
            ? !this.state.text || !this.state.linkTo
            : (!this.state.image && (!this.state.editAd || !this.state.editAd.image)) || !this.state.linkTo;
        return (
            <div className="advertise-page">
                <div className="loader" id="loader" style={{display: 'none'}}>
                    <div className="spiner"/>
                </div>

                <div class="container">
                <h1 className="h1">Advertising Opportunities With BizNexus</h1>
                <h2 className="h2 pt-1 mb-4">We Offer Highly Targeted Advertising To Reach Business Owners, Buyers & Intermediaries</h2>
                    {/* <section class="mb-5">
                        <div class="cta-banner">
                        <div class="cta-banner__image">
                            <img src="/images/boosting-page/illustration.png"
                                class="img-fluid"
                                alt="" />
                        </div>
                        <div class="cta-banner__content">
                            <h2 class="cta-banner__content-title">Boost Your Profile</h2>
                            <p class="cta-banner__content-description">Boost your profile across the BizNexus website and our social outreach to get increased exposure with qualified prospects interested in business acquisition, operation & sale.</p>
                            <button class="btn btn-primary-blue" onClick={() => this.openProfileBoost()}>Boost</button>
                        </div>
                        </div>
                    </section> */}

                    <div className="divider mb-4"/>
                    {
                        !!this.state.currentStep && <Link to="/user/ads" onClick={() => this.updateCurrentStep('')}>&larr; Back</Link>
                    }
                    <div className="boost-panel">
                        {
                            !this.state.currentStep && <>
                                <h2 className="cta-banner__content-title text-center main-boost-title" style={{color: '#373c41'}}>Boost Your
                                    Profile In Our Marketplace & Newsletter</h2>
                            <Row>
                                <Col md="4" sm="12">
                                    <Card className="boost-card">
                                        <CardBody>
                                            <div className="img-holder">
                                                <img
                                                    alt="Sample"
                                                    src="/images/boosting-page/sponsor-profile.png"
                                                />
                                            </div>
                                            <CardTitle tag="h5">
                                                Sponsor your profile
                                            </CardTitle>
                                            <CardText>
                                                Showcase your profile across the BizNexus marketplace and daily newsletter.
                                            </CardText>
                                            <button className="btn btn-primary-blue" onClick={() => this.updateCurrentStep('sponsor')}>
                                                Sponsor
                                            </button>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="4" sm="12">
                                    <Card className="boost-card">
                                        <CardBody>
                                            <div className="img-holder">
                                                <img
                                                    alt="Sample"
                                                    src="/images/boosting-page/post-a-display-ad.png"
                                                />
                                            </div>
                                            <CardTitle tag="h5">
                                                Post a display ad
                                            </CardTitle>
                                            <CardText>
                                                Post a display ad on key pages in BizNexus marketplace and in our daily newsletter.
                                            </CardText>
                                            <button className="btn btn-primary-blue" onClick={() => this.updateCurrentStep('post')}>
                                                Post
                                            </button>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="4" sm="12">
                                    <Card className="boost-card">
                                        <CardBody>
                                            <div className="img-holder">
                                                <img
                                                    alt="Sample"
                                                    src="/images/boosting-page/boost-your-brand.png"
                                                />
                                            </div>
                                            <CardTitle tag="h5">
                                                Boost your brand
                                            </CardTitle>
                                            <CardText>
                                                Get promoted as a qualified advisor for your specific M&A/B2B niche or territory.
                                            </CardText>
                                            <button className="btn btn-primary-blue" onClick={() => this.updateCurrentStep('boost')}>
                                                Boost
                                            </button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            </>
                        }

                        {
                            this.state.currentStep === 'sponsor' && <section class="mb-5 text-center">
                                <h2 class="cta-banner__content-title text-center" style={{ color: '#373c41' }}>Boost Your Profile In Our Marketplace & Newsletter</h2>
                                <img src="/images/boosting-page/boost-profile-1.png"
                                     class="img-fluid mb-4"
                                     style={{ width: 'auto', maxHeight: 262 }}
                                     alt="" />
                                <br />
                                <button class="btn btn-primary-blue" style={{ width: 180 }} onClick={() => this.openProfileBoost()}>Boost</button>
                            </section>
                        }

                        {
                            this.state.currentStep === 'boost' &&

                            <section class="mb-5 text-center">
                                <h2 class="cta-banner__content-title text-center" style={{color: '#373c41'}}>Boost Your
                                    Brand In Our Community</h2>
                                <img src="/images/boosting-page/boost-profile-2.png"
                                     class="img-fluid mb-4"
                                     alt=""/>
                                <br/>
                                <a target="_blank" rel="noopener noreferrer" class="btn btn-primary-blue text-center"
                                   style={{width: 180}} href="https://www.biznexus.com/biznexus-advisor-panel">Apply Now</a>
                            </section>
                        }

                        {
                            this.state.currentStep === 'post' &&

                            <section class="pt-3 pb-4">
                                <h2 class="h1 font-weight-500 text-center">Promote Display Ads In Our Marketplace &
                                    Newsletter</h2>
                                <p class="mb-4 text-center">Need help creating an ad? Email us at
                                    <a href="mailto:advertise@biznexus.com"> advertise@biznexus.com</a> and we'll help you
                                    get it done.</p>
                                <div class="row pt-2">

                                    <Lightbox
                                        open={this.state.index >= 0}
                                        index={this.state.index}
                                        close={() => this.setIndex(-1)}
                                        slides={this.state.plans.map((p, i) => ({
                                            src: p.metadata.image,
                                            key: i
                                        }))}
                                    />


                                    {this.state.plans.map((p, i) =>
                                        <div class="col-md-6 col-lg-4 mb-4">
                                            <div class="option-card">
                                                <div onClick={() => this.setIndex(i)} class="option-card__image cursor_pointer">
                                                    <img src={p.metadata.image} alt="" height="320px"/>
                                                </div>
                                                <div class="option-card__content">
                                                    <h3 class="option-card-title">{p.nickname}</h3>
                                                    <p class="option-card-description">{p.metadata.text}</p>
                                                    <p class="option-card-price">
                                                        <span class="option-card-price--old"></span>
                                                        <span>${(p && p.amount ? p.amount : 0) / 100} / month</span>
                                                    </p>
                                                    <button
                                                        onClick={() => this.openModal({planId: p.id, nickname: p.nickname})}
                                                        class="btn btn-primary w-100">Create Ad
                                                    </button>
                                                </div>
                                            </div>
                                        </div>)}


                                </div>

                                <div class="text-center mt-4">
                                    <a href="mailto:advertise@biznexus.com" class="btn btn-primary-blue px-4 px-sm-5">Need
                                        Help Posting An Ad?</a>
                                </div>
                            </section>
                        }
                    </div>



                    <div className="divider mb-4"/>

                {(this.state.list.length > 0 || this.state.inactive.length > 0) && <>

                <h2 className="h2 pt-1 mb-4">Posted Ads</h2>
                <Row>
                    

                    <Col sm="12">

                    <Nav tabs style={{ paddingTop: '10px' }}>
                        <NavItem style={{ cursor: 'pointer', width: '50%' }}>
                            <NavLink className={classnames({ active: this.state.activeTab === 1 })}
                                        onClick={() => { this.setState({ activeTab: 1 }) }}
                                        style={{ textTransform: 'capitalize' }}>
                                Active
                            </NavLink>
                        </NavItem>
                        <NavItem style={{ cursor: 'pointer', width: '50%' }}>
                            <NavLink className={classnames({ active: this.state.activeTab === 2 })}
                                        onClick={() => { this.setState({ activeTab: 2 }) }}
                                        style={{ textTransform: 'capitalize' }}>
                                Past Orders
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId={1}>
                            <Row style={{ paddingTop: '10px' }}>
                                <Col sm="12">
                                    <Table hover responsive>
                                        <thead>
                                            <tr>
                                                <th style={{ padding: '.75rem' }}>#</th>
                                                <th style={{ padding: '.75rem' }}>Link</th>
                                                <th style={{ padding: '.75rem' }}>Price</th>
                                                <th style={{ padding: '.75rem' }}>Purchase date</th>
                                                <th style={{ padding: '.75rem' }}>Clicks</th>
                                                <th style={{ padding: '.75rem' }}>Views</th>
                                                <th style={{ padding: '.75rem' }}>CTR</th>
                                                <th style={{ padding: '.75rem' }}>Action</th>
                                                <th style={{ padding: '.75rem' }}>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.list.map((s,i) => (
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{s.link_to}</td>
                                                    <td>{s.stripe_subscription_id !== '-' ? `${this.getAmount(s.stripe_plan_id)}` : 'Free access'}</td>
                                                    <td>{moment(s.created_on).format('LL')}</td>
                                                    <td>{s.clicks}</td>
                                                    <td>{s.views}</td>
                                                    <td>{s.views > 0 ? (s.clicks / s.views * 100).toFixed(2): '0'}%</td>
                                                    <td><Button onClick={() => this.toggleUnsubscribe(s.id, 'ad')} size="sm">Unsubscribe</Button></td>
                                                    <td><Button onClick={() => this.editAd(s)} size="sm">Edit</Button></td>
                                                </tr>
                                            ))}
                                            
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId={2}>
                            <Row style={{ paddingTop: '10px' }}>
                                <Col sm="12">
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ padding: '.75rem' }}>#</th>
                                            <th style={{ padding: '.75rem' }}>Link</th>
                                            <th style={{ padding: '.75rem' }}>Price</th>
                                            
                                            <th style={{ padding: '.75rem' }}>Clicks</th>
                                            <th style={{ padding: '.75rem' }}>Views</th>
                                            <th style={{ padding: '.75rem' }}>CTR</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.inactive.map((s,i) => (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{s.link_to}</td>
                                                <td>${_.find(this.state.plans, { id: s.stripe_plan_id }).amount / 100}</td>

                                                <td>{s.clicks}</td>
                                                <td>{s.views}</td>
                                                <td>{s.views > 0 ? (s.clicks / s.views * 100).toFixed(2): '0'}%</td>
                                            </tr>
                                        ))}
                                            
                                    </tbody>
                                </Table>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>


                        
                    </Col>

                </Row></>}
                {(this.state.boostedProfiles.length > 0 || this.state.boostedProfilesInactive.length > 0) && <>

                <h2 className="h2 pt-1 mb-4">Sponsored Profiles</h2>
                <Row>


                    <Col sm="12">

                    <Nav tabs style={{ paddingTop: '10px' }}>
                        <NavItem style={{ cursor: 'pointer', width: '50%' }}>
                            <NavLink className={classnames({ active: this.state.activeTabBoosted === 1 })}
                                        onClick={() => { this.setState({ activeTabBoosted: 1 }) }}
                                        style={{ textTransform: 'capitalize' }}>
                                Active
                            </NavLink>
                        </NavItem>
                        <NavItem style={{ cursor: 'pointer', width: '50%' }}>
                            <NavLink className={classnames({ active: this.state.activeTabBoosted === 2 })}
                                        onClick={() => { this.setState({ activeTabBoosted: 2 }) }}
                                        style={{ textTransform: 'capitalize' }}>
                                Past Orders
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTabBoosted}>
                        <TabPane tabId={1}>
                            <Row style={{ paddingTop: '10px' }}>
                                <Col sm="12">
                                    <Table hover responsive>
                                        <thead>
                                            <tr>
                                                <th style={{ padding: '.75rem' }}>#</th>
                                                <th style={{ padding: '.75rem' }}>Title</th>
                                                <th style={{ padding: '.75rem' }}>Description</th>
                                                <th style={{ padding: '.75rem' }}>Price</th>
                                                <th style={{ padding: '.75rem' }}>Purchase date</th>
                                                <th style={{ padding: '.75rem' }}>Action</th>
                                                <th style={{ padding: '.75rem' }}>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.boostedProfiles.map((s,i) => (
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>
                                                        {s.user.name} |{' '}
                                                        {(s.user.ptype && s.user.firm_name) &&
                                                            <span class="professional-card__location d-flex">{s.user.ptype} at {s.user.firm_name}</span>
                                                        }
                                                    </td>
                                                    <td>{s.description}</td>
                                                    <td>{s.stripe_subscription_id !== '-' ? `$${s.plan.amount/100}.00/${s.plan.interval}` : 'Free access'}</td>
                                                    <td>{moment(s.created_at).format('LL')}</td>
                                                    <td><Button onClick={() => this.toggleUnsubscribe(s.id, 'profiles')} size="sm">Unsubscribe</Button></td>
                                                    <td><Button onClick={() => this.editBoostedProfile(s)} size="sm">Edit</Button></td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId={2}>
                            <Row style={{ paddingTop: '10px' }}>
                                <Col sm="12">
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ padding: '.75rem' }}>#</th>
                                            <th style={{ padding: '.75rem' }}>Title</th>
                                            <th style={{ padding: '.75rem' }}>Description</th>
                                            <th style={{ padding: '.75rem' }}>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.boostedProfilesInactive.map((s,i) => (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                                {s.user.name} |{' '}
                                                {(s.user.ptype && s.user.firm_name) &&
                                                    <span
                                                        className="professional-card__location d-flex">{s.user.ptype} at {s.user.firm_name}</span>
                                                }
                                            </td>
                                            <td>{s.description}</td>
                                            <td>{s.stripe_subscription_id !== '-' ? `$${s.plan.amount/100}.00/${s.plan.interval}` : 'Free access'}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>



                    </Col>

                </Row></>}

                </div>
                <Modal size={'lg'} isOpen={!!this.state.editingProfile} toggle={() => this.setState({ editingProfile: null })} className="modal-dialog modal-featured" keyboard={false} backdrop="static">
                    <FeaturedProfile
                        description={this.state.editingProfile && this.state.editingProfile.description}
                        edit
                        loading={this.state.editLoading}
                        handleEdit={this.handleEditBoostedProfile}
                        onClose={() =>this.setState({ editingProfile: null }) }
                    />
                </Modal>
                <ConfirmationPopup
                    isOpen={!!this.state.unsubscribeId}
                    onClose={this.closeConfirmationPopup}
                    onAccept={this.unsubscribe}
                    onCancel={this.closeConfirmationPopup}
                    acceptText="Unsubscribe"
                    acceptClass="danger"
                    loading={this.state.unsubscribeLoading}
                    title={`Are you sure you want to unsubscribe from ${this.state.unsubscribeType === 'profiles' ? 'Sponsored Profile' : 'Posted Ad'}?`}
                />

                <Modal style={{ width: '400px', height: '400px' }} isOpen={this.state.editModal} toggle={this.toogle} className="modal-dialog-centered delete-item" keyboard={false} backdrop="static">
                    <ModalHeader>Edit</ModalHeader>
                    <ModalBody>
                        {this.state.modalType === 'image' && <Row style={{paddingLeft: '15px' }}>
                            <FormGroup>
                                <Label htmlFor="file">Image:</Label>
                                {(this.state.editAd.image && !this.state.image) && <div className="img-preview">
                                    <img alt="" src={process.env.REACT_APP_IMAGE_ROOT + this.state.editAd.image} className="img-fluid" />
                                </div>}
                                {this.state.image && <div className="img-preview">
                                    <img alt="" src={this.state.image} className="img-fluid" />
                                </div>}
                                    {!this.state.image && <div className="banner-drop user-banner">
                                            <Dropzone
                                                text="Click to upload JPG or PNG here"
                                                maxFileSize={5024000}
                                                multiple={false}
                                                onSuccess={this.onSelectFile}
                                                onFailure={this.onFileError}
                                            />
                                        </div>
                                    }
                                <span className="error ">{this.state.errors["image"]}</span>
                                <a href="mailto:advertise@biznexus.com">Need help making your ad?</a>
                            </FormGroup>
                        </Row>}
                        {this.state.modalType === 'text' && <Row style={{paddingLeft: '15px' }}>
                            <FormGroup style={{ width: '90%' }}>
                                <Label htmlFor="name">Text <span style={{ color: 'red' }}>*</span>:</Label>
                                <textarea name="link" id="link" className="textarea_test"
                                          onChange={(e) => this.setState({ text: e.target.value })}
                                          value={this.state.text} placeholder=""></textarea>
                                <span className="error ">{this.state.errors["text"]}</span>
                            </FormGroup>
                        </Row>}
                        <Row style={{paddingLeft: '15px' }}>
                            <FormGroup style={{ width: '90%' }}>
                                <Label htmlFor="name">Link to <span style={{ color: 'red' }}>*</span>:</Label>
                                <Input type="text" name="link" id="link"
                                       onChange={(e) => this.setState({ linkTo: e.target.value })}
                                       value={this.state.linkTo} placeholder="https://..."/>
                                <span className="error ">{this.state.errors["link"]}</span>
                            </FormGroup>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.setState({editModal: false})}>Cancel</Button>
                        <Button disabled={isUpdateBtnDisabled} color="success" onClick={(e) => this.onEdit(e)}>Update</Button>
                    </ModalFooter>
                </Modal>

               

                <Modal size={'lg'} isOpen={this.state.boostModalOpen} toggle={() => this.setState({ boostModalOpen: !this.state.boostModalOpen })} className="modal-dialog modal-featured" keyboard={false} backdrop="static">
                    <FeaturedProfile
                        description={''}
                        onClose={() =>this.setState({ boostModalOpen: false }) }
                    />
                </Modal>   

                <Modal style={{ width: '400px', height: '400px' }} isOpen={this.state.isOpen} toggle={this.toogle} className="modal-dialog-centered delete-item" keyboard={false} backdrop="static">
                    <ModalHeader>{this.state.modalName}</ModalHeader>
                    <ModalBody>
                        {this.state.modalType === 'image' && <Row style={{paddingLeft: '15px' }}>
                            <FormGroup>
                                <Label htmlFor="file">Image:</Label>
                                {this.state.image && <div className="img-preview">
                                    <img alt="" src={this.state.image} className="img-fluid" />
                                </div>}
                                        {!this.state.image && <div className="banner-drop user-banner">
                                                <Dropzone
                                                    text="Click to upload JPG or PNG here"
                                                    maxFileSize={5024000}
                                                    multiple={false}
                                                    onSuccess={this.onSelectFile}
                                                    onFailure={this.onFileError}
                                                />
                                            </div>
                                        }
                                        <span className="error ">{this.state.errors["image"]}</span>
                                <a href="mailto:advertise@biznexus.com">Need help making your ad?</a>
                            </FormGroup>
                        </Row>}
                        {this.state.modalType === 'text' && <Row style={{paddingLeft: '15px' }}>
                            <FormGroup style={{ width: '90%' }}>
                                <Label htmlFor="name">Text <span style={{ color: 'red' }}>*</span>:</Label>
                                <textarea name="link" id="link" className="textarea_test"
                                        onChange={(e) => this.setState({ text: e.target.value })}
                                        value={this.state.text} placeholder=""></textarea>
                                <span className="error ">{this.state.errors["text"]}</span>
                            </FormGroup>   
                        </Row>}
                        <Row style={{paddingLeft: '15px' }}>
                            <FormGroup style={{ width: '90%' }}> 
                                <Label htmlFor="name">Link to <span style={{ color: 'red' }}>*</span>:</Label>
                                <Input type="text" name="link" id="link"
                                        onChange={(e) => this.setState({ linkTo: e.target.value })}
                                        value={this.state.linkTo} placeholder="https://..."/>
                                <span className="error ">{this.state.errors["link"]}</span>
                            </FormGroup>   
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.setState({isOpen:false})}>Cancel</Button>
                        <Button disabled={this.state.modalType === 'text' ? !this.state.text || !this.state.linkTo : !this.state.image || !this.state.linkTo} color="success" onClick={(e) => this.onCheckout(e)}>Checkout</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.eventModal} toggle={() => this.setState({eventModal: !this.state.eventModal})}
                       className="modal-dialog modal-featured modal-dialog-centered" keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({eventModal: !this.state.eventModal})}>

                    </ModalHeader>
                    <ModalBody>
                        <div className="container text-center" style={{position: 'relative', top: 18}}>
                            <h1 className="h1">{this.state.eventHeader}</h1>
                            <div style={{fontSize: 18}}>{this.state.eventMessage}</div>
                        </div>
                        <ModalFooter style={{paddingTop: '15px', borderTop: 'none'}}>
                            <Button color="primary" onClick={() => this.setState({eventModal: false})}>Close</Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.needApproveModal} toggle={() => this.setState({ needApproveModal: !this.state.needApproveModal })} className="modal-dialog modal-featured" keyboard={true} backdrop="static">
                    <ModalBody>
                            <div className="advertise-page">
                                <section class="">
                                    <div class="cta-banner" style={{ boxShadow: 'none', }}>
                                    <div class="cta-banner__image">
                                        <i style={{ fontSize: 130, color: '#3890da' }} class="far fa-clock"></i>
                                    </div>
                                    <div class="cta-banner__content">
                                        <h2 class="cta-banner__content-title">Your Account Is Under Review</h2>
                                        <p class="cta-banner__content-description" style={{ fontSize: 13 }}>It's not you, it's us. We review all new platform applicants within 24 hours of signup. During that time, you may have limited access to key features while we confirm you're not a malicious bot or bad actor. We appreciate your patience, and if you have any questions please reach out to our team at <a href="mailto:help@biznexus.com">help@biznexus.com</a></p>
                                    </div>
                                    </div>
                                </section>
                            </div>
                    </ModalBody>  
                    <ModalFooter>
                            <Button color="primary" onClick={() => this.setState({ needApproveModal: !this.state.needApproveModal })}>Ok</Button>
                        </ModalFooter>  
                    </Modal>
            </div>
        )
    }
}

const AdsContainer = (props) => (
    <Elements stripe={stripePromise}>
        <ElementsConsumer>
            {({stripe, elements}) => (
                <AdsComponent {...props} stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    </Elements>
  );

export default AdsContainer;
