export const agents = {
    Transworld: 4,
    Sunbelt: 5,
    FCBB: 6,
    Latonas: 7,
    Link: 8,
    Alliantbrokers: 9,
    Cbiteam: 10,
    Quietlight: 11,
    Vestedbb: 12,
    Saintlouisgroup: 13,
    BusinessTeam: 14,
    Bizex: 15,
    Inbargroup: 16,
    Horizonbrokers: 17,
    Benchmark: 18,
    Tbbinc: 19,
    Capitalbbw: 20,
    Vikingmergers: 21,
    Amma: 22,
    WebsiteProperties: 23,
    ecommerceBrokers: 24,
    synergyBB: 25,
    Roi: 26,
    Trufortebusinessgroup: 27,
    Wcibusinessbrokers: 28,
    Fbxbrokers: 29,
    BayStateBusinessBrokers: 30,
    Premierbb: 31,

    CertifiedBusinessBroker: 32,
    BusinessFindersCanada: 33,
    BizConsultingExpert: 34,
    TmBusinessBrokers: 35,
    SBX: 36,
    RainCatcher: 37,
    CompanySellers: 38,
    TheBusinessExchange: 39,
    AppBusinessBrokers: 40,
    VRBusinessBrokers: 41,
    CalhounCompanies: 42,

    MoneyNomad: 43,
    EcomBrokers: 44,
    SellerForce: 45,
    BusinessExits: 46,
    ExitEquity: 47,
    Colliers: 48,
    WestboundRoad: 50,
    BizExBusinessBrokers: 51,
    InternationalBusinessExchange: 52,
    MurphyBusiness: 53,
    BristolGroup: 54,
    BizMLS: 55,
    TheVeldGroup: 56,
    StoneridgePartners: 57,
    AmericanHealthcare: 58,
    GottesmanCompany: 59,
    EmbraceBenchmark: 60,
    DealForce: 61,
    Intelligent: 62,
    MergersCorp: 63,
    GoldStarBusinessBrokers: 64,
    ArizonaRestaurantSales: 65,
    StrategicMedicalBrokers: 66,
    TNTBusinessBrokers: 67,
    BusinessBrokersIntermediaries: 68,
    AegisBusinessAdvisors: 69,
    SouthwestBusinessBrokerageLlc: 70,
    CantrellGriffinBusinessBrokers: 71,
    AllenYoungBusinessBrokerage: 72,
    MergerAcquisitionSpecialists: 73,
    SoCalRestaurantBroker: 74,
    OldIncBusinessBrokers: 75,
    WebsiteClosers: 76,
    AcquisitionsDirect: 77,
    RestaurantRealtyCompany: 78,
    ArrayCapital: 79,
    FEInternational: 80,
    InvestorsClub: 81,
    Businessesforsale: 82,
    MidStreetMergersNAcquisitions: 83,
    SunbeltCanada: 84,
    TheLibertyGroup: 85,
    MyBizsale: 86,
    TinsleyMedicalPracticeBrokers: 87,
    Woodbridge: 88,
    FCBBAU: 89,
    TheDealFirm: 90,
    GulfStreamMergers: 91,
    Veldma: 92,
    CalderMiddleMarket: 93,
    CalderMainStreet: 94,
    PoeGroupAdvisors: 95,
    HedgeStone: 96,
    Vallexa: 97,
    TheFirm: 98,
    TouchstoneBusinessAdvisors: 100,
    ApexBusinessAdvisors: 101,
    AdvancedBusinessBrokers: 102,
    KensingtonCompanyAffiliates: 103,
    AlpineBusinessBrokers: 104,
    AmericanBusinessBrokers: 105,
    RegalConsultingCorp: 106,
    EdisonBusinessAdvisors: 107,
    AffiliatedBusinessBrokers: 108,
    TheCBAGroup: 109,
    GatewayBusinessAdvisors: 110,
    ArmenNazarian: 111,
    CoastalBusinessBrokers: 112,
    SantaRosaBusinessCommercial: 113,
    EvolutionAdvisors: 114,
    FrontRangeBusiness: 115,
    SourceWorksInc: 116,
    CincinnatiBusinessBrokers: 117,
    ProFormaPartnersLLC: 118,
    TheSalehGroup: 119,
    OcalaBusinessBrokersInc: 120,
    PoolRouteSales: 121,
    RFS: 122,
    HughesProperties: 123,
    ABAAdvisors: 124,
    CorporateInvestmentBusinessBrokerage: 125,
    AllenYoung: 126,
    PSBroker: 127,
    RiverBrokers: 128,
    TheRestaurantBrokers: 129,
    BizMatch: 130,
    MorganWestfield: 131,
    EnlignAdvisors: 132,
    MurrayAssociates: 133,
    ListitenAssociates: 134,
    BottomLineManagement: 135,
    InbarGroupInc: 136,
    BusinessBrokersOfArizona: 137,
    CorbettRestaurantGroup: 138,
    BMIMergers: 139,
    FusionAdvantage: 140,
    NashvilleBusinessBrokers: 141,
    AMDBusinessBrokers: 142,
    PacificCommercialBrokers: 143,
    SDBusinessAdvisors: 144,

    ManuallyAdded: 99,
    MailParser: 500,
}
