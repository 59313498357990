import React from 'react';
import api from '../../services/api';
import { Table, Row, Col } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default class News extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            link: null,
            cat: null,
            editId: null,
            editText: '',
            editNameId: null,
            editName: '',
            description: '',
        };
    }

    componentDidMount() {
        this.init();
    }

    init = () => {
        api.get('home/posts', null, true).then(posts => {
            this.setState({ posts });
        });
    }

    onSubmit = () => {
        if(this.state.link) {
            api.post('console/add_news', {link: this.state.link, cat: this.state.cat, description: this.state.description}, true).then(() => {
                this.setState({ link: '', cat: '', description: '' });
                this.init();
            });
        }
    }

    onDelete = id => {
        api.post(`console/delete_news`, { id }, true).then(() => {
            this.init();
        });
    }

    onChangeEditId = (id, text) => {
        if(!this.state.editId || id !== this.state.editId) {
            this.setState({ editId: id, editText: text });
        }
    }

    onCancelEdit = () => {
        api.post(`console/update_news`, { id: this.state.editId, text: this.state.editText, key: 'text' }, true).then(() => {
            this.setState({ editId: null });
            this.init();
        });
    }

    onChangeTextEditId = (id, text) => {
        if(! this.state.editNameId || id !== this.state.editNameId) {
            this.setState({ editNameId: id, editName: text });
        }
    }

    onCancelTextEdit = () => {
        api.post(`console/update_news`, { id: this.state.editNameId, text: this.state.editName, key: 'name' }, true).then(() => {
            this.setState({ editNameId: null });
            this.init();
        });
    }



    render() {

        return (
            <div>
                <Link to="/console">&larr; Back</Link>
                <br /><br />
                <Row>
                    <Col sm="8">
                        <h2>Latest news</h2>
                        <Table bordered hover responsive>
                            <thead style={{ textAlign: 'center' }}>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Created At</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.posts.map(s =>
                                <tr style={{ textAlign: 'center' }}>
                                    <td onClick={() => this.onChangeTextEditId(s.id, s.name)} style={{ cursor: 'pointer', width: '25%' }}>
                                        {this.state.editNameId === s.id ?
                                            <div><textarea style={{ height: 100 }} onChange={(e) => this.setState({ editName: e.target.value })}
                                                           value={this.state.editName}
                                                           className="form-control"></textarea>
                                                <button onClick={() => this.onCancelTextEdit()} className="mt-1 btn btn-primary w-100">Save</button>
                                            </div>
                                            : s.name
                                        }
                                    </td>
                                    <td onClick={() => this.onChangeEditId(s.id, s.text)} style={{ cursor: 'pointer' }}>
                                        {this.state.editId === s.id ?
                                            <div><textarea style={{ height: 100 }} onChange={(e) => this.setState({ editText: e.target.value })}
                                                           value={this.state.editText}
                                                           className="form-control"></textarea>
                                                <button onClick={() => this.onCancelEdit()} className="mt-1 btn btn-primary w-100">Save</button>
                                            </div>
                                            : s.text
                                        }
                                    </td>
                                    <td>{moment(new Date(s.created_at)).format('LLL')}</td>
                                    <td><button onClick={() => this.onDelete(s.id)} className="btn btn-danger">Delete</button></td>

                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                    <Col sm="4">
                        <div className="card">
                            <div className="card-body">
                                <h2>Add new</h2><br />
                                <input value={this.state.link} onChange={(e) => this.setState({ link: e.target.value })} className="form-control" type="text" placeholder="Link" /> <br />
                                <textarea style={{ height: 100 }} value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} className="form-control" placeholder="Description" ></textarea><br />
                                <button className="btn btn-primary" onClick={() => this.onSubmit()}>Submit</button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

// {moment(new Date(b.last_login_at)).format('LLL')}