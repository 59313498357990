import React from 'react';
import {
    Button,
    Card,
    CardBody,
    Col,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup, FormText,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    UncontrolledDropdown, UncontrolledTooltip,
} from 'reactstrap';
import ScrollBooster from 'scrollbooster';
import Select from 'react-select';
import api from '../../../services/api';
import {toast} from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';
import {roundedMertic, trackEvent} from '../../util';
import ListItem from "../../SearchResult/ListItem";
import {CSVLink} from "react-csv";
import Switch from "react-switch";
import Preferences from "./preferences";
import './favorites.scss';
import FavoriteDeal from "./components/FavoriteDeal/FavoriteDeal";
import {SubscriptionLevels} from "../../../constants/subscriptionLevels";
import ChangeName from "./components/ChangeName/ChangeName";
import PremiumModal from "../../modals/premium-modal";
import FolderAllOpenIcon from "../../icons/FolderAllOpenIcon";
import FolderAllIcon from "../../icons/FolderAllIcon";
import FolderOpenIcon from "../../icons/FolderOpenIcon";
import FolderIcon from "../../icons/FolderIcon";
import ArrowIcon from "../../icons/ArrowIcon";
import {ADMIN_ROLES, USER_TYPE} from "../../../constants/userTypes";
import {AuthContext} from "../../../contexts/auth";
import ViewedDeals from "./ViewedDeals/ViewedDeals";
import {withRouter} from "../../../containers/withRouter/withRouter";


class Favorites extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sortConfig: {},
            savedSearchToggles: {},
            scrollTo: {},
            savedListingsToggles: {
                all: true,
            },
            navToggles: {
                all: true,
            },
            savedSearch: [],
            savedSearchList: [],
            savedListingsList: [],
            savedSearchProjects: [],
            savedSearchAll: [],
            hiddenDeals: [],
            userNotes: [],
            notes: [],
            recommended: false,
            user_type_id: null,

            newProjectModal: false,
            errorDeleteProjectModal: false,
            projectName: '',
            assignTo: '',
            search: {},
            assignListingModal: false,
            listing: {},
            assignListingTo: '',
            header: {},
            filterSearch: null,
            deleteProjectId: null,
            confirmProjectModal: false,
            savedListings: [],
            assignTable: false,
            sortDirectionDesc: true,
            sort: 'listing_date',
            sortDirectionDescAll: true,
            sortAll: 'listing_date',
            listingToFavorite: {},
            previewModal: false,
        };
    }

    static contextType = AuthContext

    componentDidMount() {

        this.init(this.props);

        if (this.context.user.user_type_id !== USER_TYPE.BROKER) {
            document.getElementById('loader').setAttribute("style", "display: block;");
            this.getSavedSearch();
            this.getSavedListings();
        }

    }

    componentWillReceiveProps(nextProps) {
        this.init(nextProps);
    }

    init = props => {
        const {match} = props;

        if (match.params.subtab) {
            const event = match.params.subtab;

            if (event === 'preferences') {
                this.setState({filterSearch: 'preferences'});
            }

            if (event === 'hidden') {
                this.setState({filterSearch: 'HiddenDeals'});
            }

            if (event === 'unlocked') {
                this.setState({filterSearch: 'UnlockedDeals'});
            }

        } else {
            this.setState({filterSearch: null});
        }
    }

    async getSavedSearchHome() {
        await api.get('home/search', null, true).then(savedSearch => {
            this.setState({
                savedSearch: savedSearch.map(s => ({
                    ...s, list: s.list.sort((a, b) => {
                        if (this.state.sortDirectionDesc) {
                            return new Date(b['listing_date']) - new Date(a['listing_date']);
                        } else {
                            return new Date(a['listing_date']) - new Date(b['listing_date']);
                        }
                    }),
                    sortDirectionDesc: true,
                    sort: 'listing_date',
                }))
            })
        });
    }

    async getSavedSearch() {

        api.get('user/header', null, true)
            .then(async (res) => {
                this.setState({
                    recommended: res.recommend_listings,
                    user_type_id: res.user_type_id,
                    header: res,
                });
                this.getSavedListings();
                this.getSavedSearchHome();

                await api.get('user/saved_search', null, true)
                    .then(res => {
                        this.setState({
                            savedSearchList: res.savedSearch,
                            savedSearchProjects: res.savedSearchProjects.map(ssp => ({
                                ...ssp,
                                sortDirectionDesc: true,
                                sort: 'listing_date',
                            })),
                            savedSearchAll: res.savedSearchAll,
                            savedListings: res.savedListings.map(l => l.transaction_id)
                        });
                    });

                api.get('transaction/hide_deals_list', null, true)
                    .then(res => {
                        this.setState({
                            hiddenDeals: res.map(deal => ({
                                ...deal,
                                user_hidden_deals: {...deal.user_hidden_deals}
                            }))
                        });
                    });

                this.getNotes()

                api.get('newsletter/status', null, true).then(newsletter => this.setState({newsletter: newsletter.result}));

                document.getElementById('loader').setAttribute("style", "display: none;");

                const viewport = document.querySelectorAll('.drag-viewport');
                const content = document.querySelectorAll('.drag-content');
                if (viewport[0] && content[0]) {
                    for (let i = 0; i < viewport.length; i++) {
                        new ScrollBooster({
                            viewport: viewport[i],
                            content: content[i],
                            direction: "horizontal",
                            scrollMode: "native",
                            emulateScroll: true,
                            // bounce: false,
                            onUpdate: (state) => {
                                viewport.scrollLeft = state.position.x;
                            }
                        });
                    }

                }
            });
    }

    getNotes() {
        return api.get('listing_notes/all', null, true).then(userNotes => this.setState({userNotes}));
    }

    getSavedListings() {
        api.get('user/saved_listings', null, true)
            .then(res => {
                this.setState({savedListingsList: res.map(deal => ({...deal}))});
            });
    }

    deleteSearch = id => {
        api.delete('user/saved_search', {id}, true)
            .then(() => {
                toast.info("Success", {
                    position: "bottom-right",
                    className: 'toast-bar',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                api.get('user/saved_search', null, true)
                    .then(res => {
                        this.setState({
                            savedSearchList: res.savedSearch,
                            savedSearchProjects: res.savedSearchProjects,
                            savedSearchAll: res.savedSearchAll,
                            savedListings: res.savedListings.map(l => l.transaction_id)
                        });
                    });
            });
    }

    deleteListing = id => {
        api.delete('user/saved_listings/' + id, null, true)
            .then(() => {
                toast.info("Success", {
                    position: "bottom-right",
                    className: 'toast-bar',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                api.get('user/saved_search', null, true)
                    .then(res => {
                        this.setState({
                            savedSearchList: res.savedSearch,
                            savedSearchProjects: res.savedSearchProjects,
                            savedSearchAll: res.savedSearchAll,
                            savedListings: res.savedListings.map(l => l.transaction_id)
                        });
                    });
            });
    }

    updateSubscriptions = (checked, i) => {
        i.notify = checked;
        document.getElementById('loader').setAttribute("style", "display: block;");
        api.post('user/saved_search_preferences', {
            savedSearchList: [i],
            recommend_listings: this.state.recommended
        }, true)
            .then(() => {
                toast.info("Success", {
                    position: "bottom-right",
                    className: 'toast-bar',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.getSavedSearch();
            });
    }

    updateRecommended = (checked, i) => {
        document.getElementById('loader').setAttribute("style", "display: block;");
        api.post('user/saved_search_preferences', {savedSearchList: [], recommend_listings: checked}, true)
            .then(() => {
                this.getSavedSearch();
            });
    }

    updateNewsletter = (checked, i) => {
        document.getElementById('loader').setAttribute("style", "display: block;");
        api.post('newsletter/update', {status: checked}, true)
            .then(() => {
                this.getSavedSearch();
            });
    }

    savePreferences = () => {
        document.getElementById('loader').setAttribute("style", "display: block;");

        api.post('user/saved_search_preferences', {recommend_listings: this.state.recommended}, true)
            .then(() => {
                this.getSavedSearch();
            });
    }

    openNewProject = () => {
        this.setState({newProjectModal: true});
    }

    onCreateNewProject = () => {
        if (!this.state.projectName) {
            this.setState({newProjectError: 'Name is required'})
            return null;
        }
        trackEvent({category: 'Listing', action: 'New Buy-Side Project'});
        document.getElementById('loader').setAttribute("style", "display: block;");
        this.setState({newProjectModal: false});

        api.post('user/create_new_project', {name: this.state.projectName}, true)
            .then(async (res) => {
                if (this.state.search.id && res && res.id) {
                    await api.post('user/assign_project', {project_id: res.id, id: this.state.search.id}, true)
                        .then(() => {
                            this.setState({search: {}, assignTo: null});
                        });
                }

                if (this.state.listingToFavorite.id && res && res.id) {
                    await api.post('user/saved_listings/' + this.state.listingToFavorite.id, {project_id: res.id,}, true)

                    await api.get('transaction/track/' + this.state.listingToFavorite.id, null, true);
                }
                this.setState({projectName: ''});
                this.getSavedSearch();
            });
    }

    deleteProject = project => {
        // if(project.searches.length > 0 || project.listings.length > 0) {
        //     this.setState({ errorDeleteProjectModal: true });
        //     return null;
        // }
        //
        this.setState({deleteProjectId: project.id, confirmProjectModal: true});

        // document.getElementById('loader').setAttribute("style", "display: block;");
        // api.post('user/delete_project', {id: project.id}, true)
        //     .then(() => {
        //         this.setState({ filterSearch: null });
        //         this.getSavedSearch();
        //     });
    }

    deleteProjectConfirm = () => {
        document.getElementById('loader').setAttribute("style", "display: block;");
        api.post('user/delete_project', {id: this.state.deleteProjectId}, true)
            .then(() => {
                this.setState({filterSearch: null, deleteProjectId: null, confirmProjectModal: false});
                this.getSavedSearch();
            });
    }

    onAssign = (search) => {
        this.setState({assignModal: true, search});
    }

    onAssignFavoritedListing = (listing, table = false, project = null) => {
        this.setState({
            assignListingModal: true,
            listing,
            assignTable: table,
            listingToFavorite: listing,
            assignListingTo: project
        });
    }

    onAssignListing = (listing, table = false, project = null) => {
        if (this.state.savedListings.find(l => l === listing.id)) {
            this.deleteListing(listing.id)
        } else {
            this.setState({
                assignListingModal: true,
                listing,
                assignTable: table,
                listingToFavorite: listing,
                assignListingTo: project
            });
        }
    }

    onClickAssign = () => {
        if (!this.state.assignTo) {
            return null;
        }

        document.getElementById('loader').setAttribute("style", "display: block;");
        this.setState({assignModal: false});
        api.post('user/assign_project', {project_id: this.state.assignTo.value, id: this.state.search.id}, true)
            .then(() => {
                this.setState({search: {}, assignTo: null});
                this.getSavedSearch();
            });
    }

    onClickAssignListing = () => {
        if (!this.state.assignListingTo) {
            return null;
        }

        this.setState({assignListingModal: false});
        api.post(`user/saved_listings/${this.state.listing.id}`, {
            project_id: this.state.assignListingTo.value,
        }, true)
            .then(() => {
                this.setState({listing: {}, assignListingTo: null, assignTable: false});
                this.getSavedSearch();
            });
    }


    unHideDeal = id => {
        api.post('transaction/unhide_deal', {id}, true)
            .then(() => {
                this.setState({search: {}, assignTo: null});
                this.getSavedSearch();
            });
    }

    savedSearchTitle = search => {
        const obj = JSON.parse(search.data); // ${obj.categoryOption ? obj.categoryOption.label : ''} TODO
        return ` ${obj.location ? obj.location : ''} ${(obj.fields && obj.fields.searchbox) ? obj.fields.searchbox : ''}`;
    }

    sliceText(text, count = 15) {
        return text && text.length >= count ? text.slice(0, count) + '...' : text;
    }

    onChangeFilterSearch(id) {
        if (id === this.state.filterSearch) {
            return;
        }
        // const firstSavedSearch = this.state.savedSearch.filter(l => l.project_id === id || (id === 'Unassigned' && !l.project_id))?.[0]
        this.setState({filterSearch: id});
    }

    printProjectName(ss) {
        const project = _.find(this.state.savedSearchProjects, p => p.id === ss.project_id);

        if (!project) {
            return 'Unassigned';
        } else {
            return project.name;
        }
    }

    updateNote = (body, id) => {
        const note = _.find(this.state.userNotes, n => n.id === id);
        note.body = body;
        this.setState({userNotes: this.state.userNotes});
    }

    getNoteValue = id => {
        const note = _.find(this.state.userNotes, n => n.id === id);
        return note ? note.body : '';
    }

    saveNote = id => {
        const note = _.find(this.state.userNotes, n => n.id === id);
        api.post('listing_notes/save', {id: note.listing_id, text: note.body}, true)
            .then(() => {
                document.getElementById('loader').setAttribute("style", "display: block;");
                this.getSavedSearch();
            });
    }

    deleteNote = id => {
        api.post('listing_notes/delete', {id}, true)
            .then(() => {
                document.getElementById('loader').setAttribute("style", "display: block;");
                this.getSavedSearch();
            });
    }

    updateNotes = () => {
        this.getSavedSearch()
        return this.getNotes()
    }

    getLastNote = dealId => {
        const note = this.state.userNotes[dealId];
        return note ? this.sliceText(note[0].body, 100) : '';
    }

    transformPrice = (str) => {
        const koef = {
            k: 1000,
            m: 1000000,
        }

        const preparedStr = str.toLowerCase()
        if (preparedStr.includes('k+')) {
            return +preparedStr.replace('k', '').replace(/[^0-9.]/g, '') * koef.k
        }
        if (preparedStr.includes('m+')) {
            return +preparedStr.replace('m', '').replace(/[^0-9.]/g, '') * koef.m
        }
        return +preparedStr.replace(/[^0-9.]/g, '')
    }

    renderSortIcon = (name, ssId, isSavedSearch) => {
        const key = isSavedSearch ? 'savedSearch' : 'savedSearchProjects'
        const savedSection = this.state[key].find(({ id }) => id === ssId)
        if (Number.isInteger(ssId) && savedSection && name === savedSection.sort) {
            if (savedSection.sortDirectionDesc) {
                return (
                    <i class="fas fa-sort-down"
                       style={{color: 'white', position: 'relative', top: -3, fontSize: 16}}/>
                )
            } else {
                return (
                    <i class="fas fa-sort-up" style={{color: 'white', position: 'relative', top: 3, fontSize: 16}}/>
                )
            }

        } else if (ssId === 'unassigned' && name === this.state.sort) {
            if (this.state.sortDirectionDesc) {
                return (
                    <i class="fas fa-sort-down"
                       style={{color: 'white', position: 'relative', top: -3, fontSize: 16}}/>
                )
            } else {
                return (
                    <i class="fas fa-sort-up" style={{color: 'white', position: 'relative', top: 3, fontSize: 16}}/>
                )
            }

        } else if (ssId === 'all' && name === this.state.sortAll) {
            if (this.state.sortDirectionDescAll) {
                return (
                    <i class="fas fa-sort-down"
                       style={{color: 'white', position: 'relative', top: -3, fontSize: 16}}/>
                )
            } else {
                return (
                    <i class="fas fa-sort-up" style={{color: 'white', position: 'relative', top: 3, fontSize: 16}}/>
                )
            }

        }

        return (<i class="fas fa-sort-down" style={{color: 'white', position: 'relative', top: -3, fontSize: 16}}></i>);
    }

    onChangeSort = (sort, id, isSavedSearch) => {
        if (id === 'all') {
            const sortDirectionDesc = sort === this.state.sortAll ? !this.state.sortDirectionDescAll : true
            this.setState({
                sortAll: sort,
                sortDirectionDescAll: sortDirectionDesc,
            })
            return
        }
        if (id === 'unassigned') {
            const sortDirectionDesc = sort === this.state.sort ? !this.state.sortDirectionDesc : true
            this.setState({
                sort,
                sortDirectionDesc,
                savedListingsList: this.state.savedListingsList.sort((a, b) => {
                    if (['price', 'revenue', 'cashflow', 'ebitda'].includes(sort)) {
                        if (sortDirectionDesc) {
                            return this.transformPrice(b[sort]) - this.transformPrice(a[sort]);
                        } else {
                            return this.transformPrice(a[sort]) - this.transformPrice(b[sort]);
                        }
                    }
                    if (['name', 'industry', 'location', 'listing_date'].includes(sort)) {
                        let nameA, nameB = '';
                        if (sort === 'name') {
                            nameA = a.name.toUpperCase();
                            nameB = b.name.toUpperCase();
                        }

                        if (sort === 'listing_date') {
                            nameA = new Date(a.listing_date);
                            nameB = new Date(b.listing_date);
                        }

                        if (sort === 'location') {
                            nameA = a.location_address.toUpperCase();
                            nameB = b.location_address.toUpperCase();
                        }

                        if (sort === 'industry') {
                            nameA = a.industries.length ? a.industries[0].industry.name.toUpperCase() : ''
                            nameB = b.industries.length ? b.industries[0].industry.name.toUpperCase() : ''
                        }

                        if (sortDirectionDesc) {
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                        } else {
                            if (nameA > nameB) {
                                return -1;
                            }
                            if (nameA < nameB) {
                                return 1;
                            }
                        }

                    }
                    return true;
                })
            })
            return
        }
        const key = isSavedSearch ? 'savedSearch' : 'savedSearchProjects'
        const listKey = isSavedSearch ? 'list' : 'listings'
        const itemKey = isSavedSearch ? undefined : 'transaction'
        this.setState({
            [key]: this.state[key].map((s) => {
                const sortDirectionDesc = sort === s.sort ? !s.sortDirectionDesc : true
                return id === s.id
                        ? ({
                            ...s,
                            [listKey]: s[listKey].sort((aItem, bItem) => {
                                const a = itemKey ? aItem[itemKey] : aItem
                                const b = itemKey ? bItem[itemKey] : bItem
                                if (['price', 'revenue', 'cashflow', 'ebitda'].includes(sort)) {
                                    if (sortDirectionDesc) {
                                        return this.transformPrice(b[sort]) - this.transformPrice(a[sort]);
                                    } else {
                                        return this.transformPrice(a[sort]) - this.transformPrice(b[sort]);
                                    }
                                }
                                if (['name', 'industry', 'location', 'listing_date'].includes(sort)) {
                                    let nameA, nameB = '';
                                    if (sort === 'name') {
                                        nameA = a.name.toUpperCase();
                                        nameB = b.name.toUpperCase();
                                    }

                                    if (sort === 'listing_date') {
                                        nameA = new Date(a.listing_date);
                                        nameB = new Date(b.listing_date);
                                    }

                                    if (sort === 'location') {
                                        nameA = a.location_address.toUpperCase();
                                        nameB = b.location_address.toUpperCase();
                                    }

                                    if (sort === 'industry') {
                                        nameA = a.industries.length ? a.industries[0].industry.name.toUpperCase() : ''
                                        nameB = b.industries.length ? b.industries[0].industry.name.toUpperCase() : ''
                                    }

                                    if (sortDirectionDesc) {
                                        if (nameA < nameB) {
                                            return -1;
                                        }
                                        if (nameA > nameB) {
                                            return 1;
                                        }
                                    } else {
                                        if (nameA > nameB) {
                                            return -1;
                                        }
                                        if (nameA < nameB) {
                                            return 1;
                                        }
                                    }

                                }
                                return true;
                            }),
                            sort,
                            sortDirectionDesc,
                        })
                        : s
                }
            )
        })
    }

    getDownloadListClick = () => {
        if (!this.state.header.premium_level || this.state.header.premium_level < SubscriptionLevels.PREMIUM) {
            this.setState({premiumModal: true});
            return false;
        }
    }

    getDownloadList = (list, project, search) => {
        if (!this.state.header.premium) {
            return '';
        }

        const getNotes = dealId => {
            return this.state.userNotes[dealId] ? this.state.userNotes[dealId].map(n => n.body).join(',') : '';
        }

        const tryToFindProject = item => {
            const unassigned = this.state.savedListingsList.find(l => l.id === item.id);

            if (unassigned) {
                return 'Unassigned';
            }

            if (item.project_name) {
                return item.project_name;
            }
        }

        return list.map(item => {
            return {
                name: item.name,
                industry: item.industries ? item.industries.map(catresult => catresult.industry.name).join(', ') : '',
                location: item.location_address,
                price: (item.hide_price || !item.price) ? 'Not Specified' : item.guru_id === 0 && !this.state.header.premium ? roundedMertic(item.price, true) : item.price,
                revenue: this.state.header.premium ? item.revenue || 'Not Specified' : roundedMertic(item.revenue, true) || 'Not Specified',
                ebitda: this.state.header.premium ? item.ebitda || 'Not Specified' : roundedMertic(item.ebitda, true) || 'Not Specified',
                sde: this.state.header.premium ? item.cashflow || 'Not Specified' : roundedMertic(item.cashflow, true) || 'Not Specified',
                updated: moment(item.listing_date).format('M/D/YY'),
                description: item.description,
                notes: getNotes(item.id),
                project: project ? project : tryToFindProject(item),
                search_name: search,
                url: window.location.origin + `/business-for-sale/${item.transaction_slug}`
            }
        })
    }

    handleSavedSearchToggle = (id) => (e) => {
        e.preventDefault()
        this.setState({
            savedSearchToggles: {
                ...this.state.savedSearchToggles,
                [id]: !this.state.savedSearchToggles[id]
            }
        })
    }

    handleSavedListingsToggle = (id) => (e, value = undefined) => {
        if (e) {
            e.preventDefault()
        }
        this.setState({
            savedListingsToggles: {
                ...this.state.savedListingsToggles,
                [id]: value !== undefined ? value : !this.state.savedListingsToggles[id]
            }
        })
    }

    handleNavToggle = (id) => (e, value = undefined) => {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }
        this.setState({
            navToggles: {
                ...this.state.navToggles,
                [id]: value !== undefined ? value : !this.state.navToggles[id]
            }
        })
    }

    onClickUpdateName = async (name, id, target) => {
        if (name) {
            await api.post('user/update_favorive_name', {name, id, target}, true)
            toast.info("Success", {
                position: "bottom-right",
                className: 'toast-bar',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.getSavedSearch();
        }

    }


    render() {
        const filterSearch = p => {
            return p.filter(p => {
                if (this.state.filterSearch) {
                    return p.id === this.state.filterSearch;
                } else {
                    return true;
                }
            })
        }

        const renderProjectName = () => {
            const project = filterSearch(this.state.savedSearchProjects);
            if (project[0] && project[0].id === this.state.filterSearch) {
                return project[0].name;
            } else if (this.state.filterSearch === 'Unassigned') {
                return 'Unaasigned';
            }
            return 'All targets'
        }

        const getProject = () => {
            const project = filterSearch(this.state.savedSearchProjects);
            if (project[0] && project[0].id === this.state.filterSearch) {
                return project[0];
            } else if (this.state.filterSearch === 'Unassigned') {
                return null;
            }
        }

        // const emptyListings = this.state.savedSearchProjects.filter(p => {
        //     return p.listings.filter(l => l.transaction).length > 0 ? true : false
        // });
        const assignedDeals = [];
        this.state.savedSearchProjects.forEach(p => {
            p.listings.forEach(l => {
                if (l.transaction && l.transaction.id) {
                    assignedDeals.push({...l.transaction, project_name: p.name})
                }
            })
        });
        const allTargetsListings = this.state.savedSearchProjects
            .reduce((res, p) => [...res, ...p.listings], [...this.state.savedListingsList])
            .filter(deal => !(deal.transaction && !deal.transaction.id))
            .map(deal => deal.transaction && deal.transaction?.id ? deal.transaction : deal)
            .sort((a, b) => {
                if (['price', 'revenue', 'cashflow', 'ebitda'].includes(this.state.sortAll)) {
                    if (this.state.sortDirectionDescAll) {
                        return this.transformPrice(b[this.state.sortAll]) - this.transformPrice(a[this.state.sortAll]);
                    } else {
                        return this.transformPrice(a[this.state.sortAll]) - this.transformPrice(b[this.state.sortAll]);
                    }
                }
                if (['name', 'industry', 'location', 'listing_date'].includes(this.state.sortAll)) {
                    let nameA, nameB = '';
                    if (this.state.sortAll === 'name') {
                        nameA = a.name.toUpperCase();
                        nameB = b.name.toUpperCase();
                    }

                    if (this.state.sortAll === 'listing_date') {
                        nameA = new Date(a.listing_date);
                        nameB = new Date(b.listing_date);
                    }

                    if (this.state.sortAll === 'location') {
                        nameA = a.location_address.toUpperCase();
                        nameB = b.location_address.toUpperCase();
                    }

                    if (this.state.sortAll === 'industry') {
                        nameA = a.industries.length ? a.industries[0].industry.name.toUpperCase() : ''
                        nameB = b.industries.length ? b.industries[0].industry.name.toUpperCase() : ''
                    }

                    if (this.state.sortDirectionDescAll) {
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                    } else {
                        if (nameA > nameB) {
                            return -1;
                        }
                        if (nameA < nameB) {
                            return 1;
                        }
                    }

                }
                return true;
            })
        const unassignedListingsAmount = this.state.savedListingsList.length
        const unassignedSearchesAmount = this.state.savedSearch.filter(l => l.project_id === null).length
        const allTargetsListingsAmount = allTargetsListings.length
        const allTargetsSearchesAmount = this.state.savedSearch.length
        const sortedFolders = this.state.savedSearchProjects.sort((a, b) => {
            const aName = a.name.toLowerCase()
            const bName = b.name.toLowerCase()
            if (aName > bName) {
                return 1
            } else if (aName < bName) {
                return -1
            }
            return 0
        })

        return (
            <div className="mb-3">
                <div className="loader" style={{backgroundColor: 'rgb(245, 246, 247)', display: 'none'}} id="loader">
                    <div className="spiner"></div>
                </div>

                <Row>
                    <Col md="3">
                        <div className="sticky-sidebar">
                            {
                                this.context.user.user_type_id !== USER_TYPE.BROKER &&
                                <div className="target-folders">

                                    <h3
                                        id="TargetFoldersTooltip"
                                        className="h3"
                                        style={{fontSize: 18}}
                                    >My Target Folders
                                        <i onClick={this.openNewProject} className="fas fa-plus-circle"
                                           style={{cursor: 'pointer', float: 'right', fontSize: 30, marginTop: -5}}/>
                                    </h3>
                                    <UncontrolledTooltip placement="bottom" target={`TargetFoldersTooltip`}
                                                         autohide={false}
                                                         innerClassName="areas-tooltip">
                                        Set up buy-side engagements to categorize your target searches and match alerts
                                    </UncontrolledTooltip>
                                    {
                                        this.context.user.user_type_id !== USER_TYPE.BROKER &&
                                        <p onClick={() => this.props.history.push('/user/favorites/unlocked')}
                                           className={`unlocked-deals-link  ${this.state.filterSearch === 'UnlockedDeals' ? 'favorite-item-active' : 'favorite-item'} `}
                                           style={{cursor: 'pointer', fontSize: 16}}>
                                            <i className="far fa-eye"/>
                                            <span style={{position: 'absolute', left: 42}}>
                                                Unlocked Deals
                                                <span className="text-secondary ms-1" id="hiddenTooltip">
                                                    <svg className="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                </span>
                                                <UncontrolledTooltip placement="bottom" target={`hiddenTooltip`}
                                                                     autohide={false}
                                                                     innerClassName="areas-tooltip">
                                                    Please, update deal statuses regularly to maintain platform access.
                                                </UncontrolledTooltip>
                                            </span>
                                        </p>
                                    }
                                    <div className="divider"/>

                                    <p onClick={() => {
                                        this.handleSavedListingsToggle('all')(null, true)
                                        this.onChangeFilterSearch(null)
                                        if (!!allTargetsListingsAmount || !!allTargetsSearchesAmount) this.handleNavToggle('all')(null, this.state.filterSearch === null ? undefined : true)
                                        this.props.history.push('/user/favorites')
                                    }}
                                       className={`ms-1 ${this.state.filterSearch === null ? 'favorite-item-active' : 'favorite-item'} `}
                                       style={{cursor: 'pointer', fontSize: 16}}>
                                        <div className="folder-icon-wrapper"
                                             onClick={!!allTargetsListingsAmount || !!allTargetsSearchesAmount ? this.handleNavToggle('all') : null}
                                             style={{cursor: (!!allTargetsListingsAmount || !!allTargetsSearchesAmount) ? 'pointer' : 'default'}}
                                        >
                                                    <span
                                                        className={`folder-icon ${this.state.navToggles.all && 'open'}`}>
                                                        <ArrowIcon/>
                                                    </span>
                                        </div>
                                        {
                                            this.state.filterSearch === null
                                                ? <FolderAllOpenIcon/>
                                                : <FolderAllIcon/>
                                        }
                                        {' '}All targets
                                    </p>
                                    <Collapse isOpen={this.state.navToggles.all}>
                                        {!!allTargetsListingsAmount && <p className={`favorite-subitem`}
                                                                          onClick={() => this.setState({
                                                                              filterSearch: null,
                                                                              scrollTo: {all: 'favorited'}
                                                                          })}>Favorited Deals <span
                                            className="badge">{allTargetsListingsAmount}</span></p>}
                                        {!!allTargetsSearchesAmount && <p className={`favorite-subitem`}
                                                                          onClick={() => this.setState({
                                                                              filterSearch: null,
                                                                              scrollTo: {all: 'searches'}
                                                                          })}>Saved Searches <span
                                            className="badge">{allTargetsSearchesAmount}</span></p>}
                                    </Collapse>
                                    {sortedFolders.map(p => {
                                            const listingsAmount = p.listings.length
                                            const searchesAmount = this.state.savedSearch.filter(l => l.project_id === p.id).length

                                            return <>
                                                <p onClick={() => {
                                                    this.onChangeFilterSearch(p.id)
                                                    this.handleSavedListingsToggle(p.id)(null, true)
                                                    if (!!listingsAmount || !!searchesAmount) this.handleNavToggle(p.id)(null, this.state.filterSearch === p.id ? undefined : true)
                                                }}
                                                   className={`ms-1 ${this.state.filterSearch === p.id ? 'favorite-item-active' : 'favorite-item'}`}
                                                   style={{cursor: 'pointer', fontSize: 16}}
                                                >
                                                    <div className="folder-icon-wrapper"
                                                         onClick={!!listingsAmount || !!searchesAmount ? this.handleNavToggle(p.id) : null}
                                                         style={{cursor: (!!listingsAmount || !!searchesAmount) ? 'pointer' : 'default'}}
                                                    >
                                                    <span
                                                        className={`folder-icon ${this.state.navToggles[p.id] && 'open'}`}>
                                                        <ArrowIcon/>
                                                    </span>
                                                    </div>
                                                    {
                                                        this.state.filterSearch === p.id
                                                            ? <FolderOpenIcon/>
                                                            : <FolderIcon/>
                                                    }
                                                    {' ' + this.sliceText(p.name, 22)}
                                                </p>
                                                <Collapse isOpen={this.state.navToggles[p.id]}>
                                                    {!!listingsAmount && <p className={`favorite-subitem`}
                                                                            onClick={() => this.setState({
                                                                                filterSearch: p.id,
                                                                                scrollTo: {[p.id]: 'favorited'}
                                                                            })}>Favorited Deals <span
                                                        className="badge">{listingsAmount}</span></p>}
                                                    {!!searchesAmount && <p className={`favorite-subitem`}
                                                                            onClick={() => this.setState({
                                                                                filterSearch: p.id,
                                                                                scrollTo: {[p.id]: 'searches'}
                                                                            })}>Saved Searches <span
                                                        className="badge">{searchesAmount}</span></p>}
                                                </Collapse>
                                            </>
                                        }
                                    )}
                                    <p onClick={() => {
                                        this.handleSavedListingsToggle('unassigned')(null, true)
                                        this.onChangeFilterSearch('Unassigned')
                                        if (!!unassignedListingsAmount || !!unassignedSearchesAmount) this.handleNavToggle('unassigned')(null, this.state.filterSearch === 'Unassigned' ? undefined : true)
                                    }}
                                       className={`ms-1 ${this.state.filterSearch === 'Unassigned' ? 'favorite-item-active' : 'favorite-item'} `}
                                       style={{cursor: 'pointer', fontSize: 16}}>
                                        <div className="folder-icon-wrapper"
                                             onClick={!!unassignedListingsAmount || !!unassignedSearchesAmount ? this.handleNavToggle('unassigned') : null}
                                             style={{cursor: (!!unassignedListingsAmount || !!unassignedSearchesAmount) ? 'pointer' : 'default'}}
                                        >
                                                    <span
                                                        className={`folder-icon ${this.state.navToggles.unassigned && 'open'}`}>
                                                        <ArrowIcon/>
                                                    </span>
                                        </div>
                                        {
                                            this.state.filterSearch === 'Unassigned'
                                                ? <FolderOpenIcon/>
                                                : <FolderIcon/>
                                        }
                                        {' Unassigned'}
                                    </p>
                                    <Collapse isOpen={this.state.navToggles.unassigned}>
                                        {!!unassignedListingsAmount && <p className={`favorite-subitem`}
                                                                          onClick={() => this.setState({
                                                                              filterSearch: 'Unassigned',
                                                                              scrollTo: {unassigned: 'favorited'}
                                                                          })}>Favorited Deals <span
                                            className="badge">{unassignedListingsAmount}</span></p>}
                                        {!!unassignedSearchesAmount && <p className={`favorite-subitem`}
                                                                          onClick={() => this.setState({
                                                                              filterSearch: 'Unassigned',
                                                                              scrollTo: {unassigned: 'searches'}
                                                                          })}>Saved Searches <span
                                            className="badge">{unassignedSearchesAmount}</span></p>}
                                    </Collapse>

                                    <br/>
                                </div>
                            }

                            <div className="my-preferences">
                                <h3
                                    className="h3"
                                    style={{fontSize: 18}}
                                >
                                    Preferences
                                </h3>

                                <p onClick={() => {
                                    this.props.history.push('/user/favorites/preferences');
                                }}
                                   className={`ms-1 ${this.state.filterSearch === 'preferences' ? 'favorite-item-active' : 'favorite-item'} `}
                                   style={{cursor: 'pointer', fontSize: 16}}>
                                    <i className="fa-solid fa-gears"/> <span
                                    style={{position: 'absolute', left: 56}}>My Match Preferences</span>
                                </p>

                                {/*<p onClick={() => {*/}
                                {/*    this.props.history.push('/user/favorites');*/}
                                {/*}}*/}
                                {/*   className={`ms-1 ${this.state.filterSearch === null ? 'favorite-item-active' : 'favorite-item'} `}*/}
                                {/*   style={{cursor: 'pointer', fontSize: 16}}>*/}
                                {/*    <i className="far fa-heart"/> <span style={{position: 'absolute', left: 56}}>My Favorites</span>*/}
                                {/*</p>*/}
                                {
                                    this.context.user.user_type_id !== USER_TYPE.BROKER &&
                                    <p onClick={() => this.props.history.push('/user/favorites/hidden')}
                                       className={`ms-1 ${this.state.filterSearch === 'HiddenDeals' ? 'favorite-item-active' : 'favorite-item'} `}
                                       style={{cursor: 'pointer', fontSize: 16}}>
                                        <i className="far fa-eye-slash"/> <span style={{position: 'absolute', left: 56}}>Hidden Deals</span>
                                    </p>
                                }

                                <br/>

                            </div>
                        </div>

                    </Col>
                    <Col md="9">

                        {this.state.filterSearch === 'preferences' &&
                            <>
                                <h1 className="h1 mb-4">My Match Preferences
                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`MyMatchPreferences`} className="icon tooltip-icon text-secondary">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                    </svg>
                                    <UncontrolledTooltip placement="bottom" target={`MyMatchPreferences`}
                                                         autohide={false}
                                                         innerClassName="areas-tooltip">
                                        Be sure to set your match preferences so business owners, advisors and referral partners will get matched with your profile and we can suggest they reach out directly.
                                    </UncontrolledTooltip>
                                </h1>
                                <div className="" style={{padding: '15px 0px 0px 0px'}}>
                                    <Preferences/>
                                </div>
                            </>}

                        {(this.state.filterSearch !== 'HiddenDeals' && this.state.filterSearch !== 'UnlockedDeals' && this.state.filterSearch !== 'preferences') &&
                            <Row>

                                <Col sm="12">

                                    <FormGroup>
                                        {(this.state.filterSearch !== 'HiddenDeals') &&
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <span className="list-caption">My target</span>
                                                        <h1 className="h1 mb-4" style={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap'
                                                        }}>{renderProjectName()} {getProject() ?
                                                            <ChangeName item={getProject()}
                                                                        onClickUpdateName={this.onClickUpdateName}
                                                                        target="project"/> : null}</h1>
                                                        <div className="divider"/>
                                                        {!!this.state.filterSearch && filterSearch(this.state.savedSearchProjects).map((p, idx) =>
                                                            <div style={{marginTop: '25px'}} className="mb-4">

                                                                <h4
                                                                    className="h2 project-header"
                                                                    ref={(ref) => {
                                                                        if (this.state.scrollTo[p.id] === 'favorited' && ref) {
                                                                            ref.scrollIntoView({
                                                                                behavior: "smooth",
                                                                                block: "start",
                                                                                inline: "start"
                                                                            })
                                                                            this.setState({
                                                                                scrollTo: {},
                                                                                savedListingsToggles: {[p.id]: true}
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                    <span className="list-subtitle">
                                        <svg className="icon" width="22" height="22" viewBox="0 0 22 22" fill="none"
                                             xmlns="http://www.w3.org/2000/svg"><mask id="mask0_884_2344"
                                                                                      style={{maskType: "alpha"}}
                                                                                      maskUnits="userSpaceOnUse" x="0"
                                                                                      y="0" width="22" height="22"><rect
                                            width="22" height="22" fill="#D9D9D9"/></mask><g
                                            mask="url(#mask0_884_2344)"><path
                                            d="M2.75033 20.1666C2.24616 20.1666 1.81456 19.9871 1.45553 19.6281C1.09651 19.2691 0.916992 18.8375 0.916992 18.3333V8.24996H2.75033V18.3333H18.3337V20.1666H2.75033ZM6.41699 16.5C5.91283 16.5 5.48123 16.3204 5.1222 15.9614C4.76317 15.6024 4.58366 15.1708 4.58366 14.6666V4.58329H9.16699V2.74996C9.16699 2.24579 9.34651 1.8142 9.70553 1.45517C10.0646 1.09614 10.4962 0.916626 11.0003 0.916626H14.667C15.1712 0.916626 15.6028 1.09614 15.9618 1.45517C16.3208 1.8142 16.5003 2.24579 16.5003 2.74996V4.58329H21.0837V14.6666C21.0837 15.1708 20.9041 15.6024 20.5451 15.9614C20.1861 16.3204 19.7545 16.5 19.2503 16.5H6.41699ZM6.41699 14.6666H19.2503V6.41663H6.41699V14.6666ZM11.0003 4.58329H14.667V2.74996H11.0003V4.58329Z"
                                            fill="#8399AE"/></g>
                                        </svg> Favorited deals</span>
                                                                    <div className="btn-actions">
                                                                        <button id="deleteTooltip"
                                                                                className="btn btn-tag" style={{
                                                                            marginLeft: 5,
                                                                            fontSize: 13,
                                                                            float: 'right',
                                                                            padding: '15px 15px',
                                                                        }} onClick={() => this.deleteProject(p)}>

                                                                            <i className="fas fa-trash-alt"/>
                                                                        </button>
                                                                        <UncontrolledTooltip placement="bottom" target={`deleteTooltip`}
                                                                                             autohide={false}
                                                                                             innerClassName="areas-tooltip">
                                                                            Delete project
                                                                        </UncontrolledTooltip>
                                                                        <CSVLink onClick={this.getDownloadListClick}
                                                                                 style={{color: '#848484'}}
                                                                                 data={this.getDownloadList(p.listings.filter(l => l.transaction).map(l => l.transaction), p.name, '')}
                                                                                 filename={`biznexus_${p.name}.csv`}>
                                                                            <button id="csvTooltip"
                                                                                    className="btn btn-tag" style={{
                                                                                marginLeft: 5,
                                                                                fontSize: 13,
                                                                                float: 'right',
                                                                                padding: '15px 15px',
                                                                            }}>
                                                                                <i className="fas fa-download"/> CSV
                                                                            </button>
                                                                            <UncontrolledTooltip placement="bottom" target={`csvTooltip`}
                                                                                                 autohide={false}
                                                                                                 innerClassName="areas-tooltip">
                                                                                Download a CSV of your favorited deals
                                                                            </UncontrolledTooltip>
                                                                        </CSVLink>
                                                                    </div>
                                                                </h4>

                                                                {p.listings.filter(l => l.transaction).length === 0
                                                                    ? <div className="justify-content-center row">
                                                                        <div className="col-xl-12">
                                                                            <div className="profile-bx" style={{
                                                                                boxShadow: '1px 1px 20px #e1e1e1',
                                                                                backgroundColor: '#f8f8f8'
                                                                            }}>
                                                                                <div className="profile-form mt-4 mb-4">
                                                                                    <p className="text-center">

                                                                                    </p>
                                                                                    <p className="text-center" style={{
                                                                                        fontSize: '15px',
                                                                                        fontStyle: 'italic'
                                                                                    }}>
                                                                                        You don't have any saved deals
                                                                                        assigned to <b>"{p.name}"</b>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : <>

                                                                        <div className="collapsible-search">
                                                                            <div
                                                                                className={`collapsible-search__top ${this.state.savedListingsToggles[p.id] ? 'open' : ''}`}
                                                                                onClick={this.handleSavedListingsToggle(p.id)}>
                                                                                {
                                                                                    this.state.savedListingsToggles[p.id] ?
                                                                                        <i className="fa-solid fa-angle-up"/> :
                                                                                        <i className="fa-solid fa-angle-down"/>
                                                                                }
                                                                                <div
                                                                                    className="d-flex flex-column me-4">
                                                                                    <h5 style={{
                                                                                        display: 'flex',
                                                                                        flexWrap: 'wrap'
                                                                                    }}>My Favorited BizNexus Deals </h5>
                                                                                </div>
                                                                            </div>

                                                                            <Collapse
                                                                                className="collapsible-search__bottom"
                                                                                isOpen={this.state.savedListingsToggles[p.id]}>
                                                                                <Card>
                                                                                    <CardBody>

                                                                                        <div className="overflow-auto"
                                                                                             style={{width: '100%'}}>
                                                                                            <Table bordered hover
                                                                                                   striped
                                                                                                   width={'100%'}
                                                                                                   className={'favorite-list-table'}>
                                                                                                <thead>
                                                                                                <tr>
                                                                                                    <th style={{
                                                                                                        backgroundColor: '#282828',
                                                                                                        color: 'white',
                                                                                                        padding: '0.5rem'
                                                                                                    }}/>
                                                                                                    <th className="cursor_pointer"
                                                                                                        onClick={() => this.onChangeSort('name', p.id, false)}
                                                                                                        style={{
                                                                                                            backgroundColor: p.sort === 'name' ? '#1F69A7' : '#282828',
                                                                                                            color: 'white',
                                                                                                            padding: '0.5rem'
                                                                                                        }}>Deal
                                                                                                        Name {this.renderSortIcon('name', p.id, false)}</th>
                                                                                                    <th className="cursor_pointer"
                                                                                                        onClick={() => this.onChangeSort('industry', p.id, false)}
                                                                                                        style={{
                                                                                                            backgroundColor: p.sort === 'industry' ? '#1F69A7' : '#282828',
                                                                                                            color: 'white',
                                                                                                            padding: '0.5rem'
                                                                                                        }}>Industry {this.renderSortIcon('industry', p.id, false)}</th>
                                                                                                    <th className="cursor_pointer"
                                                                                                        onClick={() => this.onChangeSort('location', p.id, false)}
                                                                                                        style={{
                                                                                                            backgroundColor: p.sort === 'location' ? '#1F69A7' : '#282828',
                                                                                                            color: 'white',
                                                                                                            padding: '0.5rem'
                                                                                                        }}>Location {this.renderSortIcon('location', p.id, false)}</th>
                                                                                                    <th className="cursor_pointer"
                                                                                                        onClick={() => this.onChangeSort('price', p.id, false)}
                                                                                                        style={{
                                                                                                            backgroundColor: p.sort === 'price' ? '#1F69A7' : '#282828',
                                                                                                            color: 'white',
                                                                                                            padding: '0.5rem'
                                                                                                        }}>Price {this.renderSortIcon('price', p.id, false)}</th>
                                                                                                    <th className="cursor_pointer"
                                                                                                        onClick={() => this.onChangeSort('revenue', p.id, false)}
                                                                                                        style={{
                                                                                                            backgroundColor: p.sort === 'revenue' ? '#1F69A7' : '#282828',
                                                                                                            color: 'white',
                                                                                                            padding: '0.5rem'
                                                                                                        }}>Revenue /
                                                                                                        Multiple {this.renderSortIcon('revenue', p.id, false)}</th>
                                                                                                    <th className="cursor_pointer"
                                                                                                        onClick={() => this.onChangeSort('ebitda', p.id, false)}
                                                                                                        style={{
                                                                                                            backgroundColor: p.sort === 'ebitda' ? '#1F69A7' : '#282828',
                                                                                                            color: 'white',
                                                                                                            padding: '0.5rem'
                                                                                                        }}>EBITDA /
                                                                                                        Multiple {this.renderSortIcon('ebitda', p.id, false)}</th>
                                                                                                    <th className="cursor_pointer"
                                                                                                        onClick={() => this.onChangeSort('cashflow', p.id, false)}
                                                                                                        style={{
                                                                                                            backgroundColor: p.sort === 'cashflow' ? '#1F69A7' : '#282828',
                                                                                                            color: 'white',
                                                                                                            padding: '0.5rem'
                                                                                                        }}>SDE /
                                                                                                        Multiple {this.renderSortIcon('cashflow', p.id, false)}</th>
                                                                                                    <th className="cursor_pointer"
                                                                                                        style={{
                                                                                                            backgroundColor: p.sort === 'notes' ? '#1F69A7' : '#282828',
                                                                                                            color: 'white',
                                                                                                            padding: '0.5rem'
                                                                                                        }}>Notes
                                                                                                    </th>
                                                                                                    <th className="cursor_pointer"
                                                                                                        onClick={() => this.onChangeSort('listing_date', p.id, false)}
                                                                                                        style={{
                                                                                                            backgroundColor: p.sort === 'listing_date' ? '#1F69A7' : '#282828',
                                                                                                            color: 'white',
                                                                                                            padding: '0.5rem'
                                                                                                        }}>Updated {this.renderSortIcon('listing_date', p.id, false)}</th>
                                                                                                </tr>
                                                                                                </thead>

                                                                                                <tbody
                                                                                                    style={{position: 'relative'}}>
                                                                                                {p.listings.map((deal, j) =>
                                                                                                    (deal.transaction && deal.transaction.id) ?
                                                                                                        <ListItem
                                                                                                            data={deal.transaction}
                                                                                                            premium={this.state.header.premium}
                                                                                                            key={`data-${j}`}
                                                                                                            onAddToFavorite={(l) => this.onAssignListing(l, true)}
                                                                                                            savedListings={this.state.savedListings}
                                                                                                            viewMode={'table'}
                                                                                                            withNotes
                                                                                                            onAssignListing={this.onAssignFavoritedListing}
                                                                                                            updateNotes={this.updateNotes}
                                                                                                            j={j}
                                                                                                        /> : null
                                                                                                )}
                                                                                                </tbody>
                                                                                            </Table>
                                                                                        </div>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Collapse>
                                                                        </div>
                                                                    </>
                                                                }


                                                                <div className="divider"/>
                                                            </div>)}

                                                        {this.state.filterSearch === 'Unassigned' &&
                                                            <div className="mb-4">
                                                                <h4
                                                                    className="h2 project-header"
                                                                    ref={(ref) => {
                                                                        if (this.state.scrollTo.unassigned === 'favorited' && ref) {
                                                                            ref.scrollIntoView({
                                                                                behavior: "smooth",
                                                                                block: "start",
                                                                                inline: "start"
                                                                            })
                                                                            this.setState({
                                                                                scrollTo: {},
                                                                                savedListingsToggles: {unassigned: true}
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                    <span className="list-subtitle">
                                        <svg className="icon" width="22" height="22" viewBox="0 0 22 22" fill="none"
                                             xmlns="http://www.w3.org/2000/svg"><mask id="mask0_884_2344"
                                                                                      style={{maskType: "alpha"}}
                                                                                      maskUnits="userSpaceOnUse" x="0"
                                                                                      y="0" width="22" height="22"><rect
                                            width="22" height="22" fill="#D9D9D9"/></mask><g
                                            mask="url(#mask0_884_2344)"><path
                                            d="M2.75033 20.1666C2.24616 20.1666 1.81456 19.9871 1.45553 19.6281C1.09651 19.2691 0.916992 18.8375 0.916992 18.3333V8.24996H2.75033V18.3333H18.3337V20.1666H2.75033ZM6.41699 16.5C5.91283 16.5 5.48123 16.3204 5.1222 15.9614C4.76317 15.6024 4.58366 15.1708 4.58366 14.6666V4.58329H9.16699V2.74996C9.16699 2.24579 9.34651 1.8142 9.70553 1.45517C10.0646 1.09614 10.4962 0.916626 11.0003 0.916626H14.667C15.1712 0.916626 15.6028 1.09614 15.9618 1.45517C16.3208 1.8142 16.5003 2.24579 16.5003 2.74996V4.58329H21.0837V14.6666C21.0837 15.1708 20.9041 15.6024 20.5451 15.9614C20.1861 16.3204 19.7545 16.5 19.2503 16.5H6.41699ZM6.41699 14.6666H19.2503V6.41663H6.41699V14.6666ZM11.0003 4.58329H14.667V2.74996H11.0003V4.58329Z"
                                            fill="#8399AE"/></g>
                                        </svg> Favorited deals
                                    </span>
                                                                    <CSVLink onClick={this.getDownloadListClick}
                                                                             style={{color: '#848484'}}
                                                                             data={this.getDownloadList(this.state.savedListingsList, 'Unassigned', '')}
                                                                             filename={`biznexus_unassigned.csv`}>
                                                                        <button id="csvTooltip1"
                                                                                className="btn btn-tag" style={{
                                                                            marginLeft: 5,
                                                                            fontSize: 13,
                                                                            float: 'right',
                                                                            padding: '15px 15px',
                                                                        }}>
                                                                            <i className="fas fa-download"/> CSV
                                                                        </button>
                                                                        <UncontrolledTooltip placement="bottom" target={`csvTooltip1`}
                                                                                             autohide={false}
                                                                                             innerClassName="areas-tooltip">
                                                                            Download a CSV of your favorited deals
                                                                        </UncontrolledTooltip>
                                                                    </CSVLink>
                                                                </h4>

                                                                {this.state.savedListingsList.length === 0
                                                                    ? <div className="justify-content-center row">
                                                                        <div className="col-xl-12">
                                                                            <div className="profile-bx" style={{
                                                                                boxShadow: '1px 1px 20px #e1e1e1',
                                                                                backgroundColor: '#f8f8f8'
                                                                            }}>
                                                                                <div className="profile-form mt-4 mb-4">
                                                                                    <p className="text-center">

                                                                                    </p>
                                                                                    <p className="text-center" style={{
                                                                                        fontSize: '15px',
                                                                                        fontStyle: 'italic'
                                                                                    }}>
                                                                                        You don't have any unassigned
                                                                                        deals
                                                                                    </p>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> :
                                                                    <div className="collapsible-search">
                                                                        <div
                                                                            className={`collapsible-search__top ${this.state.savedListingsToggles.unassigned ? 'open' : ''}`}
                                                                            onClick={this.handleSavedListingsToggle('unassigned')}>
                                                                            {
                                                                                this.state.savedListingsToggles.unassigned ?
                                                                                    <i className="fa-solid fa-angle-up"/> :
                                                                                    <i className="fa-solid fa-angle-down"/>
                                                                            }
                                                                            <div className="d-flex flex-column me-4">
                                                                                <h5 style={{
                                                                                    display: 'flex',
                                                                                    flexWrap: 'wrap'
                                                                                }}>My Favorited BizNexus Deals </h5>
                                                                            </div>
                                                                        </div>

                                                                        <Collapse className="collapsible-search__bottom"
                                                                                  isOpen={this.state.savedListingsToggles.unassigned}>
                                                                            <Card>
                                                                                <CardBody>

                                                                                    <div className="overflow-auto"
                                                                                         style={{width: '100%'}}>
                                                                                        <Table bordered hover striped
                                                                                               width={'100%'}
                                                                                               className={'favorite-list-table'}>
                                                                                            <thead>
                                                                                            <tr>
                                                                                                <th style={{
                                                                                                    backgroundColor: '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}/>
                                                                                                <th className="cursor_pointer"
                                                                                                    onClick={() => this.onChangeSort('name', 'unassigned')}
                                                                                                    style={{
                                                                                                        backgroundColor: this.state.sort === 'name' ? '#1F69A7' : '#282828',
                                                                                                        color: 'white',
                                                                                                        padding: '0.5rem'
                                                                                                    }}>Deal
                                                                                                    Name {this.renderSortIcon('name', 'unassigned')}</th>
                                                                                                <th className="cursor_pointer"
                                                                                                    onClick={() => this.onChangeSort('industry', 'unassigned')}
                                                                                                    style={{
                                                                                                        backgroundColor: this.state.sort === 'industry' ? '#1F69A7' : '#282828',
                                                                                                        color: 'white',
                                                                                                        padding: '0.5rem'
                                                                                                    }}>Industry {this.renderSortIcon('industry', 'unassigned')}</th>
                                                                                                <th className="cursor_pointer"
                                                                                                    onClick={() => this.onChangeSort('location', 'unassigned')}
                                                                                                    style={{
                                                                                                        backgroundColor: this.state.sort === 'location' ? '#1F69A7' : '#282828',
                                                                                                        color: 'white',
                                                                                                        padding: '0.5rem'
                                                                                                    }}>Location {this.renderSortIcon('location', 'unassigned')}</th>
                                                                                                <th className="cursor_pointer"
                                                                                                    onClick={() => this.onChangeSort('price', 'unassigned')}
                                                                                                    style={{
                                                                                                        backgroundColor: this.state.sort === 'price' ? '#1F69A7' : '#282828',
                                                                                                        color: 'white',
                                                                                                        padding: '0.5rem'
                                                                                                    }}>Price {this.renderSortIcon('price', 'unassigned')}</th>
                                                                                                <th className="cursor_pointer"
                                                                                                    onClick={() => this.onChangeSort('revenue', 'unassigned')}
                                                                                                    style={{
                                                                                                        backgroundColor: this.state.sort === 'revenue' ? '#1F69A7' : '#282828',
                                                                                                        color: 'white',
                                                                                                        padding: '0.5rem'
                                                                                                    }}>Revenue /
                                                                                                    Multiple {this.renderSortIcon('revenue', 'unassigned')}</th>
                                                                                                <th className="cursor_pointer"
                                                                                                    onClick={() => this.onChangeSort('ebitda', 'unassigned')}
                                                                                                    style={{
                                                                                                        backgroundColor: this.state.sort === 'ebitda' ? '#1F69A7' : '#282828',
                                                                                                        color: 'white',
                                                                                                        padding: '0.5rem'
                                                                                                    }}>EBITDA /
                                                                                                    Multiple {this.renderSortIcon('ebitda', 'unassigned')}</th>
                                                                                                <th className="cursor_pointer"
                                                                                                    onClick={() => this.onChangeSort('cashflow', 'unassigned')}
                                                                                                    style={{
                                                                                                        backgroundColor: this.state.sort === 'cashflow' ? '#1F69A7' : '#282828',
                                                                                                        color: 'white',
                                                                                                        padding: '0.5rem'
                                                                                                    }}>SDE /
                                                                                                    Multiple {this.renderSortIcon('cashflow', 'unassigned')}</th>
                                                                                                <th className="cursor_pointer"
                                                                                                    style={{
                                                                                                        backgroundColor: this.state.sort === 'notes' ? '#1F69A7' : '#282828',
                                                                                                        color: 'white',
                                                                                                        padding: '0.5rem'
                                                                                                    }}>Notes
                                                                                                </th>
                                                                                                <th className="cursor_pointer"
                                                                                                    onClick={() => this.onChangeSort('listing_date', 'unassigned')}
                                                                                                    style={{
                                                                                                        backgroundColor: this.state.sort === 'listing_date' ? '#1F69A7' : '#282828',
                                                                                                        color: 'white',
                                                                                                        padding: '0.5rem'
                                                                                                    }}>Updated {this.renderSortIcon('listing_date', 'unassigned')}</th>
                                                                                            </tr>
                                                                                            </thead>

                                                                                            <tbody
                                                                                                style={{position: 'relative'}}>
                                                                                            {this.state.savedListingsList?.map((deal, j) => (
                                                                                                <ListItem
                                                                                                    data={deal}
                                                                                                    premium={this.state.header.premium}
                                                                                                    key={`data-${j}`}
                                                                                                    onAddToFavorite={(l) => this.onAssignListing(l, true)}
                                                                                                    savedListings={this.state.savedListings}
                                                                                                    viewMode={'table'}
                                                                                                    j={j}
                                                                                                    withNotes
                                                                                                    onAssignListing={this.onAssignFavoritedListing}
                                                                                                    updateNotes={this.updateNotes}
                                                                                                />
                                                                                            ))}
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    </div>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Collapse>
                                                                    </div>
                                                                }
                                                            </div>}
                                                        {this.state.filterSearch === null && <div className="mb-4">
                                                            <h4
                                                                className="h2 project-header"
                                                                ref={(ref) => {
                                                                    if (this.state.scrollTo.all === 'favorited' && ref) {
                                                                        ref.scrollIntoView({
                                                                            behavior: "smooth",
                                                                            block: "start",
                                                                            inline: "start"
                                                                        })
                                                                        this.setState({
                                                                            scrollTo: {},
                                                                            savedListingsToggles: {all: true}
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                    <span className="list-subtitle">
                                        <svg className="icon" width="22" height="22" viewBox="0 0 22 22" fill="none"
                                             xmlns="http://www.w3.org/2000/svg"><mask id="mask0_884_2344"
                                                                                      style={{maskType: "alpha"}}
                                                                                      maskUnits="userSpaceOnUse" x="0"
                                                                                      y="0" width="22" height="22"><rect
                                            width="22" height="22" fill="#D9D9D9"/></mask><g
                                            mask="url(#mask0_884_2344)"><path
                                            d="M2.75033 20.1666C2.24616 20.1666 1.81456 19.9871 1.45553 19.6281C1.09651 19.2691 0.916992 18.8375 0.916992 18.3333V8.24996H2.75033V18.3333H18.3337V20.1666H2.75033ZM6.41699 16.5C5.91283 16.5 5.48123 16.3204 5.1222 15.9614C4.76317 15.6024 4.58366 15.1708 4.58366 14.6666V4.58329H9.16699V2.74996C9.16699 2.24579 9.34651 1.8142 9.70553 1.45517C10.0646 1.09614 10.4962 0.916626 11.0003 0.916626H14.667C15.1712 0.916626 15.6028 1.09614 15.9618 1.45517C16.3208 1.8142 16.5003 2.24579 16.5003 2.74996V4.58329H21.0837V14.6666C21.0837 15.1708 20.9041 15.6024 20.5451 15.9614C20.1861 16.3204 19.7545 16.5 19.2503 16.5H6.41699ZM6.41699 14.6666H19.2503V6.41663H6.41699V14.6666ZM11.0003 4.58329H14.667V2.74996H11.0003V4.58329Z"
                                            fill="#8399AE"/></g>
                                        </svg> Favorited deals
                                    </span>
                                                                <CSVLink onClick={this.getDownloadListClick}
                                                                         style={{color: '#848484'}}
                                                                         data={this.getDownloadList(allTargetsListings, null, '')}
                                                                         filename={`biznexus_all_targets.csv`}>
                                                                    <button id="csvTooltip2"
                                                                            className="btn btn-tag" style={{
                                                                        marginLeft: 5,
                                                                        fontSize: 13,
                                                                        float: 'right',
                                                                        padding: '15px 15px',
                                                                    }}>
                                                                        <i className="fas fa-download"/> CSV
                                                                    </button>
                                                                    <UncontrolledTooltip placement="bottom" target={`csvTooltip2`}
                                                                                         autohide={false}
                                                                                         innerClassName="areas-tooltip">
                                                                        Download a CSV of your favorited deals
                                                                    </UncontrolledTooltip>
                                                                </CSVLink>
                                                            </h4>

                                                            {allTargetsListings.length === 0
                                                                ? <div className="justify-content-center row">
                                                                    <div className="col-xl-12">
                                                                        <div className="profile-bx" style={{
                                                                            boxShadow: '1px 1px 20px #e1e1e1',
                                                                            backgroundColor: '#f8f8f8'
                                                                        }}>
                                                                            <div className="profile-form mt-4 mb-4">
                                                                                <p className="text-center">

                                                                                </p>
                                                                                <p className="text-center" style={{
                                                                                    fontSize: '15px',
                                                                                    fontStyle: 'italic'
                                                                                }}>
                                                                                    You don't have any favorited deals
                                                                                </p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> :
                                                                <div className="collapsible-search">
                                                                    <div
                                                                        className={`collapsible-search__top ${this.state.savedListingsToggles.all ? 'open' : ''}`}
                                                                        onClick={this.handleSavedListingsToggle('all')}>
                                                                        {
                                                                            this.state.savedListingsToggles.all ?
                                                                                <i className="fa-solid fa-angle-up"/> :
                                                                                <i className="fa-solid fa-angle-down"/>
                                                                        }
                                                                        <div className="d-flex flex-column me-4">
                                                                            <h5 style={{
                                                                                display: 'flex',
                                                                                flexWrap: 'wrap'
                                                                            }}>My Favorited BizNexus Deals </h5>
                                                                        </div>
                                                                    </div>

                                                                    <Collapse className="collapsible-search__bottom"
                                                                              isOpen={this.state.savedListingsToggles.all}>
                                                                        <Card>
                                                                            <CardBody>

                                                                                <div className="overflow-auto"
                                                                                     style={{width: '100%'}}>
                                                                                    <Table bordered hover striped
                                                                                           width={'100%'}
                                                                                           className={'favorite-list-table'}>
                                                                                        <thead>
                                                                                        <tr>
                                                                                            <th style={{
                                                                                                backgroundColor: '#282828',
                                                                                                color: 'white',
                                                                                                padding: '0.5rem'
                                                                                            }}/>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('name', 'all')}
                                                                                                style={{
                                                                                                    backgroundColor: this.state.sortAll === 'name' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>Deal
                                                                                                Name {this.renderSortIcon('name', 'all')}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('industry', 'all')}
                                                                                                style={{
                                                                                                    backgroundColor: this.state.sortAll === 'industry' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>Industry {this.renderSortIcon('industry', 'all')}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('location', 'all')}
                                                                                                style={{
                                                                                                    backgroundColor: this.state.sortAll === 'location' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>Location {this.renderSortIcon('location', 'all')}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('price', 'all')}
                                                                                                style={{
                                                                                                    backgroundColor: this.state.sortAll === 'price' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>Price {this.renderSortIcon('price', 'all')}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('revenue', 'all')}
                                                                                                style={{
                                                                                                    backgroundColor: this.state.sortAll === 'revenue' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>Revenue /
                                                                                                Multiple {this.renderSortIcon('revenue', 'all')}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('ebitda', 'all')}
                                                                                                style={{
                                                                                                    backgroundColor: this.state.sortAll === 'ebitda' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>EBITDA /
                                                                                                Multiple {this.renderSortIcon('ebitda', 'all')}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('cashflow', 'all')}
                                                                                                style={{
                                                                                                    backgroundColor: this.state.sortAll === 'cashflow' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>SDE /
                                                                                                Multiple {this.renderSortIcon('cashflow', 'all')}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                style={{
                                                                                                    backgroundColor: this.state.sortAll === 'notes' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>Notes
                                                                                            </th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('listing_date', 'all')}
                                                                                                style={{
                                                                                                    backgroundColor: this.state.sortAll === 'listing_date' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>Updated {this.renderSortIcon('listing_date', 'all')}</th>
                                                                                        </tr>
                                                                                        </thead>

                                                                                        <tbody
                                                                                            style={{position: 'relative'}}>
                                                                                        {allTargetsListings?.map((deal, j) => (
                                                                                            <ListItem
                                                                                                data={deal}
                                                                                                premium={this.state.header.premium}
                                                                                                key={`data-${j}`}
                                                                                                onAddToFavorite={(l) => this.onAssignListing(l, true)}
                                                                                                savedListings={this.state.savedListings}
                                                                                                viewMode={'table'}
                                                                                                withNotes
                                                                                                onAssignListing={this.onAssignFavoritedListing}
                                                                                                updateNotes={this.updateNotes}
                                                                                                j={j}
                                                                                            />
                                                                                        ))}
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Collapse>
                                                                </div>
                                                            }
                                                        </div>}

                                                    </FormGroup>
                                                </Col>
                                                <div className="divider"/>
                                            </Row>}


                                        {this.state.filterSearch && filterSearch(this.state.savedSearchProjects).map(p =>
                                            <div className="mb-4">

                                                <h4
                                                    className="h2 project-header"
                                                    ref={(ref) => {
                                                        if (this.state.scrollTo[p.id] === 'searches' && ref) {
                                                            ref.scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                                inline: "start"
                                                            })
                                                            this.setState({scrollTo: {}})
                                                        }
                                                    }}
                                                >
                                                    <span className="list-subtitle"><i
                                                        className="fas fa-search-location"/> Saved searches</span>
                                                </h4>
                                                {p.searches.length === 0 && <div className="justify-content-center row">
                                                    <div className="col-xl-12">
                                                        <div className="profile-bx" style={{
                                                            boxShadow: '1px 1px 20px #e1e1e1',
                                                            backgroundColor: '#f8f8f8'
                                                        }}>
                                                            <div className="profile-form mt-4 mb-4">
                                                                <p className="text-center">
                                                                    {/* <i style={{ fontSize: '48px' }} className="fas fa-search"></i> */}
                                                                </p>
                                                                <p className="text-center"
                                                                   style={{fontSize: '15px', fontStyle: 'italic'}}>
                                                                    You don't have any saved searches assigned
                                                                    to <b>"{p.name}"</b>
                                                                </p>
                                                                <p className="text-center">
                                                                    <a href="/advanced-search"
                                                                       className="btn btn-primary">Create One Now</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                                {p.searches.map((s, i) => (
                                                    <div key={s.id} className="collapsible-search">

                                                        {this.state.savedSearch.filter((l) => l.project_id === p.id && l.id === s.id).map((ss, idx) =>
                                                            <>
                                                                <div
                                                                    className={`collapsible-search__top ${this.state.savedSearchToggles[ss.id] ? 'open' : ''}`}
                                                                    onClick={this.handleSavedSearchToggle(ss.id)}>
                                                                    {
                                                                        this.state.savedSearchToggles[ss.id] ?
                                                                            <i className="fa-solid fa-angle-up"/> :
                                                                            <i className="fa-solid fa-angle-down"/>
                                                                    }
                                                                    <div className="d-flex flex-column me-4" style={{
                                                                        maxWidth: 'calc(100% - 250px)',
                                                                        width: '100%',
                                                                        minWidth: '250px'
                                                                    }}>
                                                                        <h5 style={{display: 'flex'}}>{ss.name}
                                                                            <ChangeName item={ss}
                                                                                        onClickUpdateName={this.onClickUpdateName}
                                                                                        target="search"/></h5>
                                                                        <a className="link link-primary"
                                                                           href={`/advanced-search?search_id=${s.id}`}
                                                                           onClick={e => e.stopPropagation()}>
                                                                            See the search
                                                                        </a>

                                                                    </div>

                                                                    <div className="text-sm match-alert">
                                                                        <Switch
                                                                            onColor={'#1F69A7'}
                                                                            onChange={(checked) => this.updateSubscriptions(checked, s)}
                                                                            checked={s.notify}
                                                                            onClick={e => e.stopPropagation()}/>

                                                                        <label style={{marginLeft: 10}}
                                                                               htmlFor="email_visible"
                                                                               onClick={e => e.stopPropagation()}>
                                                                            Match Alerts
                                                                        </label>
                                                                    </div>
                                                                    <div className="d-md-block"
                                                                         style={{position: 'absolute', right: 10}}>

                                                                        <UncontrolledDropdown direction="left"
                                                                                              onClick={e => e.stopPropagation()}>
                                                                            <DropdownToggle tag={'span'}
                                                                                            className={'btn'}>
                                                                                <i className="fas fa-ellipsis-h"
                                                                                   style={{
                                                                                       fontSize: 24,
                                                                                       color: '#212529'
                                                                                   }}/>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                {
                                                                                    this.context.role !== ADMIN_ROLES.NotAdmin &&
                                                                                    <CSVLink
                                                                                        onClick={this.getDownloadListClick}
                                                                                        style={{color: '#212529'}}
                                                                                        data={this.getDownloadList(ss.list, p.name, ss.name)}
                                                                                        filename={`biznexus_${p.name}_${ss.name}.csv`}>
                                                                                        <DropdownItem>
                                                                                            <i className="fas fa-download"/> Download
                                                                                            CSV
                                                                                        </DropdownItem>
                                                                                    </CSVLink>
                                                                                }
                                                                                <DropdownItem
                                                                                    onClick={() => this.onAssign(s)}>
                                                                                    <i className="fas fa-folder-open"/> Assign
                                                                                    to Target Folder
                                                                                </DropdownItem>
                                                                                <DropdownItem
                                                                                    onClick={() => this.updateSubscriptions(!s.notify, s)}>
                                                                                    <i className="far fa-paper-plane"/> {s.notify ? `Unsubscribe from Match Alerts` : 'Subscribe to Match Alerts'}
                                                                                </DropdownItem>
                                                                                <DropdownItem
                                                                                    onClick={() => this.deleteSearch(s.id)}>
                                                                                    <i className="fas fa-trash-alt"/> Delete
                                                                                </DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </div>
                                                                </div>

                                                                <Collapse className="collapsible-search__bottom"
                                                                          isOpen={this.state.savedSearchToggles[s.id]}>
                                                                    <Card>
                                                                        <CardBody>
                                                                            <div className="overflow-auto"
                                                                                 style={{width: '100%'}}>
                                                                                <div>
                                                                                    <Table bordered hover striped
                                                                                           width={'100%'}
                                                                                           className={'favorite-list-table'}>
                                                                                        <thead>
                                                                                        <tr>
                                                                                            <th style={{
                                                                                                backgroundColor: '#282828',
                                                                                                color: 'white',
                                                                                                padding: '0.5rem'
                                                                                            }}/>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('name', ss.id, true)}
                                                                                                style={{
                                                                                                    backgroundColor: ss.sort === 'name' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>Deal
                                                                                                Name {this.renderSortIcon('name', ss.id, true)}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('industry', ss.id, true)}
                                                                                                style={{
                                                                                                    backgroundColor: ss.sort === 'industry' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>Industry {this.renderSortIcon('industry', ss.id, true)}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('location', ss.id, true)}
                                                                                                style={{
                                                                                                    backgroundColor: ss.sort === 'location' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>Location {this.renderSortIcon('location', ss.id, true)}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('price', ss.id, true)}
                                                                                                style={{
                                                                                                    backgroundColor: ss.sort === 'price' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>Price {this.renderSortIcon('price', ss.id, true)}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('revenue', ss.id, true)}
                                                                                                style={{
                                                                                                    backgroundColor: ss.sort === 'revenue' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>Revenue /
                                                                                                Multiple {this.renderSortIcon('revenue', ss.id, true)}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('ebitda', ss.id, true)}
                                                                                                style={{
                                                                                                    backgroundColor: ss.sort === 'ebitda' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>EBITDA /
                                                                                                Multiple {this.renderSortIcon('ebitda', ss.id, true)}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('cashflow', ss.id, true)}
                                                                                                style={{
                                                                                                    backgroundColor: ss.sort === 'cashflow' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>SDE /
                                                                                                Multiple {this.renderSortIcon('cashflow', ss.id, true)}</th>
                                                                                            <th className="cursor_pointer"
                                                                                                onClick={() => this.onChangeSort('listing_date', ss.id, true)}
                                                                                                style={{
                                                                                                    backgroundColor: ss.sort === 'listing_date' ? '#1F69A7' : '#282828',
                                                                                                    color: 'white',
                                                                                                    padding: '0.5rem'
                                                                                                }}>Updated {this.renderSortIcon('listing_date', ss.id, true)}</th>
                                                                                        </tr>
                                                                                        </thead>

                                                                                        <tbody
                                                                                            style={{position: 'relative'}}>
                                                                                        {ss.list.map((resultList, j) =>
                                                                                            <ListItem data={resultList}
                                                                                                      premium={this.state.header.premium}
                                                                                                      key={`data-${j}`}
                                                                                                      onAddToFavorite={(l) => this.onAssignListing(l, true, {
                                                                                                          label: p.name,
                                                                                                          value: p.id
                                                                                                      })}
                                                                                                      savedListings={this.state.savedListings}
                                                                                                      viewMode={'table'}
                                                                                                      j={j}
                                                                                            />
                                                                                        )}
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </div>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Collapse>
                                                            </>
                                                        )}</div>
                                                ))}
                                            </div>)}

                                        {this.state.filterSearch === 'Unassigned' && <div className="mb-4">
                                            <h2
                                                className="h2 project-header"
                                                ref={(ref) => {
                                                    if (this.state.scrollTo.unassigned === 'searches' && ref) {
                                                        ref.scrollIntoView({
                                                            behavior: "smooth",
                                                            block: "start",
                                                            inline: "start"
                                                        })
                                                        this.setState({scrollTo: {}})
                                                    }
                                                }}
                                            >
                                    <span className="list-subtitle">
                                        <i className="fas fa-search-location"/>  Saved searches
                                    </span>
                                            </h2>
                                            {this.state.savedSearchList.length === 0 &&
                                                <div className="justify-content-center row">
                                                    <div className="col-xl-12">
                                                        <div className="profile-bx" style={{
                                                            boxShadow: '1px 1px 20px #e1e1e1',
                                                            backgroundColor: '#f8f8f8'
                                                        }}>
                                                            <div className="profile-form mt-4 mb-4">
                                                                <p className="text-center">

                                                                </p>
                                                                <p className="text-center"
                                                                   style={{fontSize: '15px', fontStyle: 'italic'}}>
                                                                    You don't have any saved searches assigned
                                                                    to <b>"Unassigned"</b>
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                            {this.state.savedSearch.filter(l => l.project_id === null).map((s, i) => (
                                                <div key={s.id} className="collapsible-search">
                                                    <div
                                                        className={`collapsible-search__top ${this.state.savedSearchToggles[s.id] ? 'open' : ''}`}
                                                        onClick={this.handleSavedSearchToggle(s.id)}>
                                                        {
                                                            this.state.savedSearchToggles[s.id] ?
                                                                <i className="fa-solid fa-angle-up"/> :
                                                                <i className="fa-solid fa-angle-down"/>
                                                        }
                                                        <div className="d-flex flex-column me-4" style={{
                                                            maxWidth: 'calc(100% - 250px)',
                                                            width: '100%',
                                                            minWidth: '250px'
                                                        }}>
                                                            <h5>{s.name}</h5>
                                                            <a className="link link-primary"
                                                               href={`/advanced-search?search_id=${s.id}`}
                                                               onClick={e => e.stopPropagation()}>
                                                                See the search
                                                            </a>
                                                        </div>

                                                        <div className="text-sm match-alert"
                                                             onClick={e => e.stopPropagation()}>
                                                            <Switch
                                                                onColor={'#1F69A7'}
                                                                onClick={e => e.stopPropagation()}
                                                                onChange={(checked) => this.updateSubscriptions(checked, s)}
                                                                checked={s.notify}/>

                                                            <label style={{marginLeft: 10}} htmlFor="email_visible"
                                                                   onClick={e => e.stopPropagation()}>
                                                                Match Alerts
                                                            </label>
                                                        </div>
                                                        <div className="d-md-block"
                                                             style={{position: 'absolute', right: 10}}>

                                                            <UncontrolledDropdown direction="left"
                                                                                  onClick={e => e.stopPropagation()}>
                                                                <DropdownToggle tag={'span'} className={'btn'}
                                                                                onClick={e => e.stopPropagation()}>
                                                                    <i className="fas fa-ellipsis-h"
                                                                       style={{fontSize: 24, color: '#212529'}}/>
                                                                </DropdownToggle>
                                                                <DropdownMenu onClick={e => e.stopPropagation()}>
                                                                    {
                                                                        this.context.role !== ADMIN_ROLES.NotAdmin &&
                                                                        <CSVLink onClick={this.getDownloadListClick}
                                                                                 style={{color: '#212529'}}
                                                                                 data={this.getDownloadList(s.list, 'Unassigned', s.name)}
                                                                                 filename={`biznexus_unassigned.csv`}>
                                                                            <DropdownItem>
                                                                                <i className="fas fa-download"/> Download
                                                                                CSV
                                                                            </DropdownItem>
                                                                        </CSVLink>
                                                                    }
                                                                    <DropdownItem onClick={() => this.onAssign(s)}>
                                                                        <i className="fas fa-folder-open"/> Assign to
                                                                        Target Folder
                                                                    </DropdownItem>
                                                                    <DropdownItem
                                                                        onClick={() => this.updateSubscriptions(!s.notify, s)}>
                                                                        <i className="far fa-paper-plane"/> {s.notify ? `Unsubscribe from Match Alerts` : 'Subscribe to Match Alerts'}
                                                                    </DropdownItem>
                                                                    <DropdownItem
                                                                        onClick={() => this.deleteSearch(s.id)}>
                                                                        <i className="fas fa-trash-alt"/> Delete
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>

                                                        </div>
                                                    </div>

                                                    <Collapse isOpen={this.state.savedSearchToggles[s.id]}
                                                              className="collapsible-search__bottom">
                                                        <Card>
                                                            <CardBody>
                                                                <div className="overflow-auto" style={{width: '100%'}}>
                                                                    <div>
                                                                        <Table bordered hover striped width={'100%'}
                                                                               className={'favorite-list-table'}>
                                                                            <thead>
                                                                            <tr>
                                                                                <th style={{
                                                                                    backgroundColor: '#282828',
                                                                                    color: 'white',
                                                                                    padding: '0.5rem'
                                                                                }}/>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('name', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'name' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>Deal
                                                                                    Name {this.renderSortIcon('name', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('industry', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'industry' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>Industry {this.renderSortIcon('industry', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('location', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'location' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>Location {this.renderSortIcon('location', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('price', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'price' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>Price {this.renderSortIcon('price', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('revenue', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'revenue' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>Revenue /
                                                                                    Multiple {this.renderSortIcon('revenue', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('ebitda', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'ebitda' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>EBITDA /
                                                                                    Multiple {this.renderSortIcon('ebitda', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('cashflow', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'cashflow' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>SDE /
                                                                                    Multiple {this.renderSortIcon('cashflow', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('listing_date', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'listing_date' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>Updated {this.renderSortIcon('listing_date', s.id, true)}</th>
                                                                            </tr>
                                                                            </thead>

                                                                            <tbody style={{position: 'relative'}}>
                                                                            {s.list.map((resultList, j) =>
                                                                                <ListItem data={resultList}
                                                                                          premium={this.state.header.premium}
                                                                                          key={`data-${j}`}
                                                                                          onAddToFavorite={(l) => this.onAssignListing(l, true)}
                                                                                          savedListings={this.state.savedListings}
                                                                                          viewMode={'table'}
                                                                                          j={j}
                                                                                />
                                                                            )}
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                </div>
                                            ))}
                                        </div>}

                                        {this.state.filterSearch === null && <div className="mb-4">
                                            <h2
                                                className="h2 project-header"
                                                ref={(ref) => {
                                                    if (this.state.scrollTo.all === 'searches' && ref) {
                                                        ref.scrollIntoView({
                                                            behavior: "smooth",
                                                            block: "start",
                                                            inline: "start"
                                                        })
                                                        this.setState({scrollTo: {}})
                                                    }
                                                }}
                                            >
                                    <span className="list-subtitle">
                                        <i className="fas fa-search-location"/>  Saved searches
                                    </span>
                                            </h2>
                                            {this.state.savedSearch.length === 0 &&
                                                <div className="justify-content-center row">
                                                    <div className="col-xl-12">
                                                        <div className="profile-bx" style={{
                                                            boxShadow: '1px 1px 20px #e1e1e1',
                                                            backgroundColor: '#f8f8f8'
                                                        }}>
                                                            <div className="profile-form mt-4 mb-4">
                                                                <p className="text-center">

                                                                </p>
                                                                <p className="text-center"
                                                                   style={{fontSize: '15px', fontStyle: 'italic'}}>
                                                                    You don't have any saved searches
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                            {this.state.savedSearch.map((s, idx) => (
                                                <div key={s.id} className="collapsible-search">
                                                    <div
                                                        className={`collapsible-search__top ${this.state.savedSearchToggles[s.id] ? 'open' : ''}`}
                                                        onClick={this.handleSavedSearchToggle(s.id)}>
                                                        {
                                                            this.state.savedSearchToggles[s.id] ?
                                                                <i className="fa-solid fa-angle-up"/> :
                                                                <i className="fa-solid fa-angle-down"/>
                                                        }
                                                        <div className="d-flex flex-column me-4" style={{
                                                            maxWidth: 'calc(100% - 250px)',
                                                            width: '100%',
                                                            minWidth: '250px'
                                                        }}>
                                                            <h5>{s.name}</h5>
                                                            <a className="link link-primary"
                                                               href={`/advanced-search?search_id=${s.id}`}
                                                               onClick={e => e.stopPropagation()}>
                                                                See the search
                                                            </a>
                                                        </div>

                                                        <div className="text-sm match-alert"
                                                             onClick={e => e.stopPropagation()}>
                                                            <Switch
                                                                onColor={'#1F69A7'}
                                                                onClick={e => e.stopPropagation()}
                                                                onChange={(checked) => this.updateSubscriptions(checked, s)}
                                                                checked={s.notify}/>

                                                            <label style={{marginLeft: 10}} htmlFor="email_visible"
                                                                   onClick={e => e.stopPropagation()}>
                                                                Match Alerts
                                                            </label>
                                                        </div>
                                                        <div className="d-md-block"
                                                             style={{position: 'absolute', right: 10}}>

                                                            <UncontrolledDropdown direction="left"
                                                                                  onClick={e => e.stopPropagation()}>
                                                                <DropdownToggle tag={'span'} className={'btn'}
                                                                                onClick={e => e.stopPropagation()}>
                                                                    <i className="fas fa-ellipsis-h"
                                                                       style={{fontSize: 24, color: '#212529'}}/>
                                                                </DropdownToggle>
                                                                <DropdownMenu onClick={e => e.stopPropagation()}>
                                                                    {
                                                                        this.context.role !== ADMIN_ROLES.NotAdmin &&
                                                                        <CSVLink onClick={this.getDownloadListClick}
                                                                                 style={{color: '#212529'}}
                                                                                 data={this.getDownloadList(s.list, 'Unassigned', s.name)}
                                                                                 filename={`biznexus_${s.name}.csv`}>
                                                                            <DropdownItem>
                                                                                <i className="fas fa-download"/> Download
                                                                                CSV
                                                                            </DropdownItem>
                                                                        </CSVLink>
                                                                    }
                                                                    <DropdownItem onClick={() => this.onAssign(s)}>
                                                                        <i className="fas fa-folder-open"/> Assign to
                                                                        Target Folder
                                                                    </DropdownItem>
                                                                    <DropdownItem
                                                                        onClick={() => this.updateSubscriptions(!s.notify, s)}>
                                                                        <i className="far fa-paper-plane"/> {s.notify ? `Unsubscribe from Match Alerts` : 'Subscribe to Match Alerts'}
                                                                    </DropdownItem>
                                                                    <DropdownItem
                                                                        onClick={() => this.deleteSearch(s.id)}>
                                                                        <i className="fas fa-trash-alt"/> Delete
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>

                                                        </div>
                                                    </div>

                                                    <Collapse isOpen={this.state.savedSearchToggles[s.id]}
                                                              className="collapsible-search__bottom">
                                                        <Card>
                                                            <CardBody>
                                                                <div className="overflow-auto" style={{width: '100%'}}>
                                                                    <div>
                                                                        <Table bordered hover striped width={'100%'}
                                                                               className={'favorite-list-table'}>
                                                                            <thead>
                                                                            <tr>
                                                                                <th style={{
                                                                                    backgroundColor: '#282828',
                                                                                    color: 'white',
                                                                                    padding: '0.5rem'
                                                                                }}/>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('name', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'name' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>Deal
                                                                                    Name {this.renderSortIcon('name', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('industry', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'industry' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>Industry {this.renderSortIcon('industry', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('location', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'location' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>Location {this.renderSortIcon('location', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('price', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'price' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>Price {this.renderSortIcon('price', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('revenue', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'revenue' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>Revenue /
                                                                                    Multiple {this.renderSortIcon('revenue', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('ebitda', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'ebitda' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>EBITDA /
                                                                                    Multiple {this.renderSortIcon('ebitda', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('cashflow', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'cashflow' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>SDE /
                                                                                    Multiple {this.renderSortIcon('cashflow', s.id, true)}</th>
                                                                                <th className="cursor_pointer"
                                                                                    onClick={() => this.onChangeSort('listing_date', s.id, true)}
                                                                                    style={{
                                                                                        backgroundColor: s.sort === 'listing_date' ? '#1F69A7' : '#282828',
                                                                                        color: 'white',
                                                                                        padding: '0.5rem'
                                                                                    }}>Updated {this.renderSortIcon('listing_date', s.id, true)}</th>
                                                                            </tr>
                                                                            </thead>

                                                                            <tbody style={{position: 'relative'}}>
                                                                            {s.list.map((resultList, j) =>
                                                                                <ListItem data={resultList}
                                                                                          premium={this.state.header.premium}
                                                                                          key={`data-${j}`}
                                                                                          onAddToFavorite={(l) => this.onAssignListing(l, true)}
                                                                                          savedListings={this.state.savedListings}
                                                                                          viewMode={'table'}
                                                                                          j={j}
                                                                                />
                                                                            )}
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                </div>
                                            ))}
                                        </div>}


                                    </FormGroup>
                                </Col>

                            </Row>}


                        {this.state.filterSearch === 'HiddenDeals' && <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <h1 className="h1 mb-4">Hidden Deals</h1>
                                    <p className={'mt-1'} style={{color: '#829fb9'}}>All of your hidden deals in one
                                        location</p>
                                    <div className="mb-4">
                                        {this.state.hiddenDeals.length === 0 &&
                                            <div className="justify-content-center row">
                                                <div className="col-xl-12">
                                                    <div className="profile-bx" style={{
                                                        boxShadow: '1px 1px 20px #e1e1e1',
                                                        backgroundColor: '#f8f8f8'
                                                    }}>
                                                        <div className="profile-form mt-4 mb-4">
                                                            <p className="text-center">
                                                                {/* <i style={{ fontSize: '48px' }} className="fas fa-search"></i> */}
                                                            </p>
                                                            <p className="text-center"
                                                               style={{fontSize: '15px', fontStyle: 'italic'}}>
                                                                You don't have any hidden deals
                                                            </p>
                                                            {/* <p className="text-center">
                                                    <a href="/advanced-search" className="btn btn-primary">Start Matching</a>
                                                </p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        {this.state.hiddenDeals.map((s, i) => (
                                            <FavoriteDeal
                                                key={s.user_hidden_deals.transaction_id}
                                                deal={s.user_hidden_deals}
                                                unHideDeal={this.unHideDeal}
                                                getLastNote={this.getLastNote}
                                            />
                                        ))}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>}
                        {
                            this.state.filterSearch === 'UnlockedDeals' && <Row>
                                <Col sm="12">
                                    <ViewedDeals/>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>


                <Modal isOpen={this.state.confirmProjectModal}
                       toggle={() => this.setState({confirmProjectModal: !this.state.confirmProjectModal})}
                       className="modal-dialog-centered delete-item" keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({confirmProjectModal: !this.state.confirmProjectModal})}>
                        Confirm
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label md={12}>Are you sure you want to delete this project?</Label>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.deleteProjectConfirm}>Yes, delete</Button>
                        <Button color="" onClick={() => this.setState({confirmProjectModal: false})}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.newProjectModal}
                       toggle={() => this.setState({newProjectModal: !this.state.newProjectModal})}
                       className="modal-dialog-centered delete-item" keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({newProjectModal: !this.state.newProjectModal})}>
                        Create a new Target Folder
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label md={12}>Add as many specific searches as you'd like for each acquisition client or
                                list you have</Label>
                            <Label htmlFor="" md={4}>Name</Label>
                            <Col md={7}>
                                <InputGroup>
                                    <Input
                                        invalid={!!this.state.newProjectError}
                                        onKeyPress={e => {
                                            if (e.charCode === 13) {
                                                this.onCreateNewProject();
                                            }
                                        }} value={this.state.projectName}
                                        onChange={(e) => this.setState({
                                            projectName: e.target.value,
                                            newProjectError: ''
                                        })} type="text"/>
                                </InputGroup>
                                {this.state.newProjectError &&
                                    <InputGroup>
                                        <FormText color="danger">{this.state.newProjectError}</FormText>
                                    </InputGroup>
                                }
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.onCreateNewProject}>Create</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.errorDeleteProjectModal}
                       toggle={() => this.setState({errorDeleteProjectModal: !this.state.errorDeleteProjectModal})}
                       className="modal-dialog-centered delete-item" keyboard={true} backdrop="static">
                    <ModalHeader
                        toggle={() => this.setState({errorDeleteProjectModal: !this.state.errorDeleteProjectModal})}>
                        Action Required
                    </ModalHeader>
                    <ModalBody>
                        Deleting Projects with active searches or listings are not permitted
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success"
                                onClick={() => this.setState({errorDeleteProjectModal: !this.state.errorDeleteProjectModal})}>Ok</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.assignModal}
                       toggle={() => this.setState({assignModal: !this.state.assignModal})}
                       className="modal-dialog-centered delete-item" keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({assignModal: !this.state.assignModal})}>
                        Assign {this.sliceText(this.state.search.name, 25)}
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label htmlFor="" md={4}>Assign to:</Label>
                            <Col md={7}>
                                <InputGroup>
                                    <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                        className="w-100"
                                        onChange={(v) => {
                                            if (v.value === 0) {
                                                this.setState({assignModal: false});
                                                this.setState({newProjectModal: true});
                                            }
                                            this.setState({assignTo: v})
                                        }}
                                        options={this.state.savedSearchProjects.map(p => {
                                            return {
                                                label: p.name,
                                                value: p.id
                                            }
                                        }).concat([{label: 'Unassigned', value: null}, {
                                            label: '+ Create New',
                                            value: 0
                                        }])}
                                        value={this.state.assignTo}
                                    />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.onClickAssign}>Assign</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.assignListingModal}
                       toggle={() => this.setState({assignListingModal: !this.state.assignListingModal})}
                       className="modal-dialog-centered delete-item" keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({assignListingModal: !this.state.assignListingModal})}>
                        Assign {this.sliceText(this.state.listing.name || (this.state.listing.transaction && this.state.listing.transaction.name), 25)}
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label htmlFor="" md={4}>Assign to:</Label>
                            <Col md={7}>
                                <InputGroup>
                                    <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                        className="w-100"
                                        onChange={(p) => {
                                            if (p.value === 0) {

                                                this.setState({assignListingModal: false});
                                                this.setState({newProjectModal: true});

                                            } else {
                                                this.setState({assignListingTo: p});
                                            }
                                        }}
                                        options={this.state.savedSearchProjects.map(p => {
                                            return {
                                                label: p.name,
                                                value: p.id
                                            }
                                        }).concat([{label: 'Unassigned', value: null}, {
                                            label: '+ Create New',
                                            value: 0
                                        }])}
                                        value={this.state.assignListingTo}
                                    />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.onClickAssignListing}>Assign</Button>
                    </ModalFooter>
                </Modal>

                <PremiumModal isOpen={this.state.premiumModal}
                              toggle={() => this.setState({premiumModal: !this.state.premiumModal})}/>
            </div>
        )
    }
}

export default withRouter(Favorites);
