import {getImageUrl} from "../util";
import React from "react";
import {useState} from "react";

const SuggestedBuyers = ({deal, defaultActive, handleClick, home, brokers}) => {
    const [active, setActive] = useState(defaultActive || false)

    const truncString = (str, max, add) => {
        add = add || '...';
        return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
    };
    const recommendations = brokers ? 'brokers_to_recommend_free' : 'buyers_to_recommend_free'

    return (

        <div className="mb-4"><h3 onClick={() => setActive(!active)}
                                  className="h3 mb-3" style={{color: '#565656', cursor: 'pointer'}}>
            Potential { brokers ? 'M&A Advisors' : 'buyers' } {active ?
                <i className="fa-solid fa-angle-up"/> :
                <i className="fa-solid fa-angle-down"/>}</h3>
            <div>
                {active && <div className={`row row-gutter-10 ${home ? 'flex-no-wrap' : 'flex-md-wrap'}`}>
                    {deal[recommendations].map((buyer, i) => i === 2
                        ? (
                            <div onClick={handleClick} style={home ? {cursor: 'pointer', flex: '1 0 0'} : {cursor: 'pointer'}}
                                 className={`col-12 col-md-auto profile-home-horizontal potential_card ${home && 'home'}`}>
                                <div className="business-card box-potential"
                                     style={home ? { height: 44, boxShadow: 'rgb(194 194 194) 1px 1px 15px -12px' } : {
                                         marginTop: 0,
                                         width: 236,
                                         height: 105,
                                         boxShadow: 'rgb(194 194 194) 1px 1px 15px -12px'
                                     }}>
                                    <div className="business-card__image"
                                         style={{display: 'inline-flex', alignItems: 'center', minWidth: home ? 44 : 70}}>
                                        <img
                                            style={{width: home ? 32 : 60, height: home ? 32 : 60, border: '3px solid #fff', borderRadius: 80}}
                                            onError={
                                                (e) => {
                                                    //@ts-ignore
                                                    if (!e.target.src.includes('images/thumbnail-small.gif')) {
                                                        e.target.src = `/images/thumbnail-small.gif`;
                                                    }
                                                }
                                            }
                                            src={(buyer.profile_pic) ?
                                                (buyer.profile_pic.indexOf('base64') > -1) ? buyer.profile_pic : (buyer.profile_pic.indexOf('user_profile') > -1) ? getImageUrl(buyer.profile_pic) : getImageUrl(buyer.profile_pic) : process.env.PUBLIC_URL + '/images/thumbnail-small.gif'}
                                            alt=""/>
                                    </div>
                                    <div className="" style={{alignSelf: 'center', minWidth: home ? 0 : 155}}>
                                        <h3 style={{fontSize: 16, display: 'block'}} className="professional-card__title">
                                            {truncString(buyer.name, 20, '...')}
                                        </h3>
                                        <span style={{fontSize: 14, color: '#454545', display: 'block'}}>
                                            {truncString(buyer.firm_name, 25, '...')}
                                        </span>
                                    </div>
                                    <div className="business-card__mail"
                                         style={{alignSelf: 'center', textAlign: 'center', position: 'absolute', right: 0}}>
                                        <button className="btn btn-sm btn-outline-primary-blue">Show all</button>
                                    </div>
                                </div>
                            </div>
                        )
                        : (
                        <div onClick={handleClick} style={home ? {cursor: 'pointer', flex: '1 0 0'} : {cursor: 'pointer'}}
                             className={`col-12 col-md-auto profile-home-horizontal potential_card ${home && 'home'}`}>
                            <div className="business-card" style={home ? { height: 44} : {marginTop: 0, width: 295, height: 105}}>
                                <div className="business-card__image"
                                     style={{display: 'inline-flex', alignItems: 'center', minWidth: home ? 44 : 70}}>
                                    <img
                                        style={{width: home ? 32 : 60, height: home ? 32 : 60, border: '3px solid #fff', borderRadius: 80}}
                                        onError={
                                            (e) => {
                                                //@ts-ignore
                                                if (!e.target.src.includes('images/thumbnail-small.gif')) {
                                                    e.target.src = `/images/thumbnail-small.gif`;
                                                }
                                            }
                                        }
                                        src={(buyer.profile_pic) ?
                                            (buyer.profile_pic.indexOf('base64') > -1) ? buyer.profile_pic : (buyer.profile_pic.indexOf('user_profile') > -1) ? getImageUrl(buyer.profile_pic) : getImageUrl(buyer.profile_pic) : process.env.PUBLIC_URL + '/images/thumbnail-small.gif'}
                                        alt=""/>
                                </div>
                                <div className="" style={{alignSelf: 'center', minWidth: home ? 0 : 155}}>
                                    <h3 style={{fontSize: 16, display: 'block'}}
                                        className="professional-card__title">{truncString(buyer.name, 20, '...')}</h3>
                                    <span style={{
                                        fontSize: 14,
                                        color: '#454545',
                                        display: 'block'
                                    }}>{truncString(buyer.firm_name, 25, '...')}</span>
                                </div>
                                <div className="business-card__mail ms-auto"
                                     style={{alignSelf: 'center', textAlign: 'center'}}>
                                    <i className="far fa-envelope" style={{fontSize: home ? 24 : 36, color: '#4D8ED5'}}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>}
            </div>
        </div>
    )
}

export default SuggestedBuyers
