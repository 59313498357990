import React, {useEffect, useState} from "react";
import api from "../../../../services/api";
import Pagination from "react-js-pagination";
import {Nav, NavItem, NavLink, Table, TabContent, TabPane} from "reactstrap";
import moment from "moment";
import {unknownValue} from "../../../../services/html";
import './viewed-deals.scss'
import Select from "react-select";
import {UserDealStatusesList, UserDealStatusesMap} from "../../../Console/UserDealStatuses/user_deal_statuses";
import classnames from "classnames";

const StatusDropdown = ({value, handleChangeStatus, disabled}) => {
    return (
        <Select
            classNamePrefix="Select"
            classNames={{
                control: (state) =>
                    state.isFocused ? 'Select-control' : 'Select-control',
            }}
            name="status"
            id="deal_status_select"
            isSearchable={false}
            isClearable={false}
            onChange={handleChangeStatus}
            options={Object.values(UserDealStatusesMap)}
            value={Object.values(UserDealStatusesMap).find((option) => value !== null && Number(option.value) === Number(value) )}
            disabled={disabled}
        />
    )
}
const activeDealStatuses = [UserDealStatusesList.Interested, UserDealStatusesList.LOISubmited, UserDealStatusesList.UnderLOI, UserDealStatusesList.UnderContract]
const inactiveDealStatuses = [UserDealStatusesList.LostDeal, UserDealStatusesList.NotInterested, UserDealStatusesList.Acquired]


const ViewedDeals = () => {
    const [dealLoading, setDealLoading] = useState(true)
    const [viewedDeals, setViewedDeals] = useState([])
    const [newViewedDeals, setNewViewedDeals] = useState([])
    const [activeViewedDeals, setActiveViewedDeals] = useState([])
    const [inactiveViewedDeals, setInactiveViewedDeals] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState({
        new: 0,
        active: 0,
        inactive: 0,
    })
    const [activeTab, setActiveTab] = useState(1)
    const dealPerPage = 20;
    useEffect(() => {
        getViewedDeals()
    }, [currentPage])

    const categorizeViewedDeals = ([newDeals, activeDeals, inactiveDeals]) => {
        setNewViewedDeals(newDeals.list)
        setActiveViewedDeals(activeDeals.list)
        setInactiveViewedDeals(inactiveDeals.list)
        setViewedDeals([...newDeals.list, ...activeDeals.list, ...inactiveDeals.list])
        setTotalCount({
            new: newDeals.count,
            active: activeDeals.count,
            inactive: inactiveDeals.count,
        })
    }

    const getViewedDeals = async (ignoreLoading = false) => {
        try {
            if (!ignoreLoading) {
                setDealLoading(true)
            }
            const res = await Promise.all([api.get(`transaction/viewed?page=${currentPage}&limit=${dealPerPage}&type=new`, null, true),
            api.get(`transaction/viewed?page=${currentPage}&limit=${dealPerPage}&type=active`, null, true),
            api.get(`transaction/viewed?page=${currentPage}&limit=${dealPerPage}&type=inactive`, null, true)])
            categorizeViewedDeals(res)
            setDealLoading(false)
        } catch (e) {
            console.error(e);
            setDealLoading(false)
        }
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
        getViewedDeals(true)
    }
    const sliceText = (text, count = 15) => {
        return text && text.length >= count ? text.slice(0, count) + '...' : text;
    }
    const handleChangeStatus = (transactionId) => async ({value}) => {
        api.post(`transaction/viewed_status/${transactionId}`, {
            status_id: value
        }, true).then(() => {
            getViewedDeals(true)
        })

    }

    return (<div>
            <h1 className="h1 mb-3" style={{textTransform: 'capitalize'}}>Unlocked Deals</h1>

            <div className="user-listings-wrapper">
                {
                    dealLoading
                        ? <div className="loader_listings_page" id="loaderlistings">
                            <div className="spiner"/>
                        </div>
                        : viewedDeals.length
                            ? <div className="position-relative viewed-deals-wrapper">
                                <Nav tabs style={{ paddingTop: '10px' }}>
                                    <NavItem style={{ cursor: 'pointer', width: '33%' }}>
                                        <NavLink className={classnames({ active: activeTab === 1 })}
                                                 onClick={() => {
                                                     setActiveTab(1)
                                                     setCurrentPage(1)
                                                 }}
                                                 style={{ textTransform: 'capitalize' }}>
                                            New
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={{ cursor: 'pointer', width: '33%' }}>
                                        <NavLink className={classnames({ active: activeTab === 2 })}
                                                 onClick={() => {
                                                     setActiveTab(2)
                                                     setCurrentPage(1)
                                                 }}
                                                 style={{ textTransform: 'capitalize' }}>
                                            Active
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={{ cursor: 'pointer', width: '33%' }}>
                                        <NavLink className={classnames({ active: activeTab === 3 })}
                                                 onClick={() => {
                                                     setActiveTab(3)
                                                     setCurrentPage(1)
                                                 }}
                                                 style={{ textTransform: 'capitalize' }}>
                                            Inactive
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={1}>

                                        {
                                            !!newViewedDeals.length &&
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={currentPage}
                                                itemsCountPerPage={dealPerPage}
                                                totalItemsCount={totalCount.new}
                                                pageRangeDisplayed={3}
                                                onChange={handlePageChange}
                                            />
                                        }
                                        {
                                            newViewedDeals.length
                                                ? <Table className="" style={{backgroundColor: '#fff'}}>
                                                    <thead>
                                                    <tr>
                                                        <th style={{width: '200px'}}>Title</th>
                                                        <th>Category</th>
                                                        <th>Sale Price</th>
                                                        <th>Revenue</th>
                                                        <th>SDE</th>
                                                        <th>EBITDA</th>
                                                        <th>Unlock Date</th>
                                                        <th>Last Viewed</th>
                                                        <th style={{minWidth: '150px'}}>Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        newViewedDeals.map((deal, j) => {

                                                            return (
                                                                <tr key={`transactionlists-${deal.id}`}>
                                                                    <td>
                                                                        {deal.deleted && <span
                                                                            style={{backgroundColor: '#dbdbdb', border: '1px solid black'}}
                                                                            class="badge text-dark">No Longer Available
                                </span>
                                                                        }
                                                                        <a href={deal.isAnonymous ? `/user/subscription` : `/business-for-sale/${deal.transaction_slug}/${deal.id}`}
                                                                           target="_blank"
                                                                           rel="noopener noreferrer"
                                                                           style={{fontSize: '14px'}}
                                                                        >
                                                                            {sliceText(deal.name, 35)}
                                                                        </a>
                                                                    </td>
                                                                    <td>{deal.industries.map(c => c.industry.name).join(", ")}</td>
                                                                    <td>{deal.price || unknownValue()}</td>
                                                                    <td>{deal.revenue || unknownValue()}</td>
                                                                    <td>{deal.cashflow || unknownValue()}</td>
                                                                    <td>{deal.ebitda || unknownValue()}</td>
                                                                    <td>{deal.unlockDate ? moment(deal.unlockDate).format("MMMM, D, YYYY") : unknownValue()}</td>
                                                                    <td>{deal.lastViewedDate ? moment(deal.lastViewedDate).format("MMMM, D, YYYY") : unknownValue()}</td>
                                                                    <td className="text-center">
                                                                        <StatusDropdown
                                                                            value={deal.status_id}
                                                                            handleChangeStatus={handleChangeStatus(deal.id)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                    </tbody>
                                                </Table>
                                                : <p>No new viewed deals</p>
                                        }

                                        {
                                            !!newViewedDeals.length &&
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={currentPage}
                                                itemsCountPerPage={dealPerPage}
                                                totalItemsCount={totalCount.new}
                                                pageRangeDisplayed={3}
                                                onChange={handlePageChange}
                                            />
                                        }
                                    </TabPane>
                                    <TabPane tabId={2}>


                                        {
                                            !!activeViewedDeals.length &&
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={currentPage}
                                                itemsCountPerPage={dealPerPage}
                                                totalItemsCount={totalCount.active}
                                                pageRangeDisplayed={3}
                                                onChange={handlePageChange}
                                            />
                                        }
                                        {
                                            activeViewedDeals.length ?
                                                <Table className="" style={{backgroundColor: '#fff'}}>
                                                    <thead>
                                                    <tr>
                                                        <th style={{width: '200px'}}>Title</th>
                                                        <th>Category</th>
                                                        <th>Sale Price</th>
                                                        <th>Revenue</th>
                                                        <th>SDE</th>
                                                        <th>EBITDA</th>
                                                        <th>Unlock Date</th>
                                                        <th>Last Viewed</th>
                                                        <th style={{minWidth: '150px'}}>Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        activeViewedDeals.map((deal, j) => {

                                                            return (
                                                                <tr key={`transactionlists-${deal.id}`}>
                                                                    <td>
                                                                        {deal.deleted && <span
                                                                            style={{backgroundColor: '#dbdbdb', border: '1px solid black'}}
                                                                            class="badge text-dark">No Longer Available
                                </span>
                                                                        }
                                                                        <a href={deal.isAnonymous ? `/user/subscription` : `/business-for-sale/${deal.transaction_slug}/${deal.id}`}
                                                                           target="_blank"
                                                                           rel="noopener noreferrer"
                                                                           style={{fontSize: '14px'}}
                                                                        >
                                                                            {sliceText(deal.name, 35)}
                                                                        </a>
                                                                    </td>
                                                                    <td>{deal.industries.map(c => c.industry.name).join(", ")}</td>
                                                                    <td>{deal.price || unknownValue()}</td>
                                                                    <td>{deal.revenue || unknownValue()}</td>
                                                                    <td>{deal.cashflow || unknownValue()}</td>
                                                                    <td>{deal.ebitda || unknownValue()}</td>
                                                                    <td>{deal.unlockDate ? moment(deal.unlockDate).format("MMMM, D, YYYY") : unknownValue()}</td>
                                                                    <td>{deal.lastViewedDate ? moment(deal.lastViewedDate).format("MMMM, D, YYYY") : unknownValue()}</td>
                                                                    <td className="text-center">
                                                                        <StatusDropdown
                                                                            value={deal.status_id}
                                                                            handleChangeStatus={handleChangeStatus(deal.id)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                    </tbody>
                                                </Table>
                                                : <p>No active viewed deals</p>
                                        }


                                        {
                                            !!activeViewedDeals.length &&
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={currentPage}
                                                itemsCountPerPage={dealPerPage}
                                                totalItemsCount={totalCount.active}
                                                pageRangeDisplayed={3}
                                                onChange={handlePageChange}
                                            />
                                        }
                                    </TabPane>
                                    <TabPane tabId={3}>


                                        {
                                            !!inactiveViewedDeals.length &&
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={currentPage}
                                                itemsCountPerPage={dealPerPage}
                                                totalItemsCount={totalCount.inactive}
                                                pageRangeDisplayed={3}
                                                onChange={handlePageChange}
                                            />
                                        }
                                        {
                                            inactiveViewedDeals.length
                                                ? <Table className="" style={{backgroundColor: '#fff'}}>
                                                    <thead>
                                                    <tr>
                                                        <th style={{width: '200px'}}>Title</th>
                                                        <th>Category</th>
                                                        <th>Sale Price</th>
                                                        <th>Revenue</th>
                                                        <th>SDE</th>
                                                        <th>EBITDA</th>
                                                        <th>Unlock Date</th>
                                                        <th>Last Viewed</th>
                                                        <th style={{minWidth: '150px'}}>Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        inactiveViewedDeals.map((deal, j) => {

                                                            return (
                                                                <tr key={`transactionlists-${deal.id}`}>
                                                                    <td>
                                                                        {deal.deleted && <span
                                                                            style={{backgroundColor: '#dbdbdb', border: '1px solid black'}}
                                                                            class="badge text-dark">No Longer Available
                                </span>
                                                                        }
                                                                        <a href={deal.isAnonymous ? `/user/subscription` : `/business-for-sale/${deal.transaction_slug}/${deal.id}`}
                                                                           target="_blank"
                                                                           rel="noopener noreferrer"
                                                                           style={{fontSize: '14px'}}
                                                                        >
                                                                            {sliceText(deal.name, 35)}
                                                                        </a>
                                                                    </td>
                                                                    <td>{deal.industries.map(c => c.industry.name).join(", ")}</td>
                                                                    <td>{deal.price || unknownValue()}</td>
                                                                    <td>{deal.revenue || unknownValue()}</td>
                                                                    <td>{deal.cashflow || unknownValue()}</td>
                                                                    <td>{deal.ebitda || unknownValue()}</td>
                                                                    <td>{deal.unlockDate ? moment(deal.unlockDate).format("MMMM, D, YYYY") : unknownValue()}</td>
                                                                    <td>{deal.lastViewedDate ? moment(deal.lastViewedDate).format("MMMM, D, YYYY") : unknownValue()}</td>
                                                                    <td className="text-center">
                                                                        <StatusDropdown
                                                                            value={deal.status_id}
                                                                            handleChangeStatus={handleChangeStatus(deal.id)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                    </tbody>
                                                </Table>
                                                : <p>No inactive viewed deals</p>
                                        }


                                        {
                                            !!inactiveViewedDeals.length &&
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={currentPage}
                                                itemsCountPerPage={dealPerPage}
                                                totalItemsCount={totalCount.inactive}
                                                pageRangeDisplayed={3}
                                                onChange={handlePageChange}
                                            />
                                        }
                                    </TabPane>
                                </TabContent>
                            </div>
                            : <p>No deals were reviewed</p>
                }
            </div>
        </div>
    )
}

export default ViewedDeals
