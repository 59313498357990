import React from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import api from '../../../../../services/api';
import {toast} from "react-toastify";
import {withRouter} from "../../../../../containers/withRouter/withRouter";


class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {}
    };
    this.SubmitLogin = this.SubmitLogin.bind(this);
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //newpass
    if (!fields["passwordlogin"]) {
    	formIsValid = false;
    	errors["passwordlogin"] = "Please enter your current password.";
    }
    if (typeof fields["passwordlogin"] !== "undefined") {
    	if (!fields["passwordlogin"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)) {
    		formIsValid = false;
    		errors["passwordlogin"] = "Passwords must contain at least 6 characters, including uppercase, lowercase letters and numbers.";
    	}
    }

    if (!fields["newpasswordlogin"]) {
      formIsValid = false;
      errors["newpasswordlogin"] = "Please enter your new password.";
    }
    if (typeof fields["newpasswordlogin"] !== "undefined") {
      if (!fields["newpasswordlogin"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)) {
        formIsValid = false;
        errors["newpasswordlogin"] = "Passwords must contain at least 6 characters, including uppercase, lowercase letters and numbers.";
      }
    }

    if (!fields["repasswordlogin"]) {
      formIsValid = false;
      errors["repasswordlogin"] = "Please re-enter your new password.";
    }else if(fields["repasswordlogin"]!==fields["newpasswordlogin"]){
      formIsValid = false;
      errors["repasswordlogin"] = "Please enter same password.";
    }else{
      if (typeof fields["repasswordlogin"] !== "undefined") {
        if (!fields["repasswordlogin"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)) {
          formIsValid = false;
          errors["repasswordlogin"] = "Passwords must contain at least 6 characters, including uppercase, lowercase letters and numbers.";
        }
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  componentDidMount() {
    document.getElementById('loaderpopup').setAttribute("style", "display: none;");
  }
  SubmitLogin = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      document.getElementById('loaderpopup').setAttribute("style", "display: block;");
      let password = this.state.fields["newpasswordlogin"];
      let jsonsetLoginData = {
        "password": password,
        "current_password": this.state.fields["passwordlogin"],
      };
      //console.log(jsonsetLoginData);
    api.post('user/changepassword_profile', jsonsetLoginData, true)
        .then(res => {
          if (res.success) {
            // localStorage.setItem('token', res.token);
            this.setState({
              fields: {
                passwordlogin: '',
                newpasswordlogin: '',
                repasswordlogin: '',
              },
              errors: {}
            });
            document.getElementById('loaderpopup').setAttribute("style", "display: none;");
            toast.info("Your login password was successfully updated", {
              position: "bottom-right",
              className: 'toast-bar',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // this.props.history.push('/user/profile');
          }
          else {
            let errors = {};
            errors["passwordlogin"] = res.message;
            this.setState({ errors: errors });
            document.getElementById('loaderpopup').setAttribute("style", "display: none;");
          }
        });

    }
  }

  render() {
    return (
        <section className="">
          <div className="loader bg-white" id="loaderpopup"><h3>Please Wait...</h3><div className="spiner"></div></div>
         <h1 className="h1">Change Login Password</h1>
          <div className="mt-3">
            <span className="error ">{this.state.errors["errorMessage"]}</span>
            <Form onSubmit={this.SubmitLogin}>
              <FormGroup>
                <Label for="passwordlogin">Current Password <span style={{ color: 'red' }}>*</span></Label>
                <Input type="password" name="passwordlogin" id="passwordlogin" placeholder="current password" ref="passwordlogin" onChange={this.handleChange.bind(this, "passwordlogin")} value={this.state.fields["passwordlogin"]} />
                <span className="error ">{this.state.errors["passwordlogin"]}</span>
              </FormGroup>

              <FormGroup>
                <Label for="newpasswordlogin">New Password <span style={{ color: 'red' }}>*</span></Label>
                <Input type="password" name="newpasswordlogin" id="newpasswordlogin" placeholder="new password" ref="newpasswordlogin" onChange={this.handleChange.bind(this, "newpasswordlogin")} value={this.state.fields["newpasswordlogin"]} />
                <span className="error ">{this.state.errors["newpasswordlogin"]}</span>
              </FormGroup>

              <FormGroup>
                <Label for="repasswordlogin">Re-Enter Password <span style={{ color: 'red' }}>*</span></Label>
                <Input type="password" name="repasswordlogin" id="repasswordlogin" placeholder="re-enter password" ref="repasswordlogin" onChange={this.handleChange.bind(this, "repasswordlogin")} value={this.state.fields["repasswordlogin"]} />
                <span className="error ">{this.state.errors["repasswordlogin"]}</span>
              </FormGroup>


              <button type="submit" className="btn btn-primary">Change Password</button>
            </Form>
          </div>
        </section>
    );
  }
}
export default withRouter(ChangePassword);
