// JavaScript Document
import React from 'react'
import {AuthContext} from "../contexts/auth";
import {withRouter} from "../containers/withRouter/withRouter";
import { Navigate } from "react-router";
class Logout extends React.Component {

	static contextType = AuthContext

	userLogout = () => {
		this.context.actions.logout()
	}

	componentDidMount() {
		var linkedinId = localStorage.getItem("linkedinid");
		if(linkedinId!==undefined && linkedinId!==null){
			//window.IN.User.logout();
			var ip = '';
			ip = localStorage.getItem("ip");
			localStorage.clear();
			localStorage.setItem('ip', ip);
			this.props.history.push('/');
		}else{
			this.userLogout();
		}
		
	}
	render() {
		return (<Navigate to={'/login'} replace />)
	}
}

export default withRouter(Logout)

