import React from 'react';
import api from '../../services/api';
import {Row, Col, Table} from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from "react-select";
import {ADMIN_ROLES_LABEL} from "../../constants/userTypes";

export default class Admin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      admins: [],
      email: '',
      role: 1,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.init();
  }

  init = () => {
    api.get('console/admins', null, true).then(admins => {
      this.setState({ admins, email: '' });
    });
  }

  handleChange = option => {
    this.setState({ role: option.value });
  }

  doSubmit = () => {
    api.post('console/add_admin', {
      email: this.state.email,
      role: this.state.role,
    }, true)
        .then(() => {
          this.init();
        });
  }

  onRevoke = id => {
    api.post('console/revoke', { id }, true)
        .then(() => {
          this.init();
        });
  }

  render() {
      const options = [{label: 'Super Admin', value: 1,}, { label: 'Moderator', value: 10 }]
      const value = options.find(({ value }) => +value === +this.state.role)

    return (
        <div>
          <div className="loader" style={{ backgroundColor: 'rgb(245, 246, 247)', display: 'none' }} id="loader">
            <div className="spiner"/>
          </div>
          <Link to="/console">&larr; Back</Link>
          <br /><br />
          <div className="h2">Admin</div>
          <Row>
            <Col sm="8">
              <Table bordered hover responsive>
                <thead style={{ textAlign: 'center' }}>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Level</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {this.state.admins.map(admin =>
                    <tr style={{ textAlign: 'center' }}>
                      <td>{admin.name}</td>
                      <td>{admin.email}</td>
                      <td>{ADMIN_ROLES_LABEL[admin.admin_id]}</td>
                      <td><button onClick={() => this.onRevoke(admin.id)} className="btn btn-sm btn-danger">Revoke Access</button></td>

                    </tr>
                )}
                </tbody>
              </Table>
            </Col>
            <Col sm="4">
              <div className="h2">New Admin</div>
              <input placeholder="email..." className="form-control" type="text" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
              <Select 
                classNamePrefix="Select"
                classNames={{
                    control: (state) =>
                        state.isFocused ? 'Select-control' : 'Select-control',
                }}
                  className="mt-2"
                  isSearchable={false}
                  isClearable={false}
                  onChange={this.handleChange}
                  options={options}
                  value={value}
                  style={{backgroundColor: 'white'}}
              />
              <button className="btn btn-primary w-100 mt-2" onClick={() => this.doSubmit()}>Submit</button>
            </Col>

          </Row>
        </div>
    )
  }
}

