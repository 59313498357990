import * as actionTypes from '../actionTypes';

const getFeaturedTransactionsStart = () => ({type: actionTypes.GET_FEATURED_TRANSACTIONS_START});
const getFeaturedTransactionsSuccess = transactions => ({
  type: actionTypes.GET_FEATURED_TRANSACTIONS_SUCCESS,
  payload: {transactions},
});
const getFeaturedTransactionsFail = error => ({type: actionTypes.GET_FEATURED_TRANSACTIONS_FAIL, payload: {error}});

export const getFeaturedTransactions = (industry_id, location) => dispatch => {
  dispatch(getFeaturedTransactionsStart());
  fetch(`${process.env.REACT_APP_API_ROOT}transaction/search/featured?industry_id=${industry_id}&latitude=${location.latitude}&longitude=${location.longitude}`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': localStorage.getItem('token'),
    },
  })
    .then(function (res) {
      if (res.status < 200 || res.status >= 300) {
        throw res;
      } else { return res.json() }
    })
    .then(res => {
      dispatch(getFeaturedTransactionsSuccess(res));
    })
    .catch((err) => {
      dispatch(getFeaturedTransactionsFail(err));
    })
};

const setFeaturedTransactionClickStart = () => ({type: actionTypes.SET_FEATURED_TRANSACTION_CLICK_START});
const setFeaturedTransactionClickSuccess = () => ({type: actionTypes.SET_FEATURED_TRANSACTION_CLICK_SUCCESS});
const setFeaturedTransactionClickFail = () => ({type: actionTypes.SET_FEATURED_TRANSACTION_CLICK_FAIL});

export const setFeaturedTransactionClick = id => dispatch => {
  dispatch(setFeaturedTransactionClickStart());
  fetch(`${process.env.REACT_APP_API_ROOT}transaction/search/featured/click`, {
    method: 'post',
    body: JSON.stringify({id}),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  })
    .then(function (res) {
      if (res.status < 200 || res.status >= 300) {
        throw res;
      } else { return res.json() }
    })
    .then(res => {
      dispatch(setFeaturedTransactionClickSuccess(res));
    })
    .catch((err) => {
      dispatch(setFeaturedTransactionClickFail(err));
    })
};
