const SMALL_WIDTH = 1320
const MOBILE_WIDTH = 990


const onResize = (window, cb) => () => {
    const width = window.innerWidth
    if (cb) {
        cb(width)
    }

    return width
}
const addOnResize = (window, cb = null) => {
    window.onresize = onResize(window, cb);
}
const removeOnResize = (window) => {
    window.onresize = null;
}

export {
    onResize,
    addOnResize,
    removeOnResize,
    SMALL_WIDTH,
    MOBILE_WIDTH
}
