import React from 'react'

const FolderAllOpenIcon = ({ color, fontSize }) => {
    return (<i style={{ fontSize: fontSize || 16 }}>
        <svg width="20" height={fontSize || "16"} viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.25 15.5001C1.7641 15.5001 1.35096 15.3299 1.01058 14.9895C0.670192 14.6491 0.5 14.236 0.5 13.7501V2.69244C0.5 2.20656 0.679808 1.78381 1.03943 1.42419C1.39904 1.06457 1.8218 0.884766 2.3077 0.884766H8.38462L9.99998 2.50012H17.6923C18.1397 2.50012 18.5256 2.64467 18.8499 2.93377C19.1743 3.22287 19.3621 3.57831 19.4134 4.00009H9.38653L7.77117 2.38474H2.3077C2.21795 2.38474 2.14423 2.41359 2.08653 2.47129C2.02883 2.52897 1.99998 2.60269 1.99998 2.69244V13.6924C1.99998 13.7629 2.01761 13.8206 2.05287 13.8655C2.08812 13.9103 2.1346 13.9488 2.1923 13.9809L4.58268 6.00009H21.2404L18.7615 14.2578C18.6474 14.6334 18.4336 14.9344 18.1202 15.1606C17.8067 15.3869 17.459 15.5001 17.0769 15.5001H2.25ZM3.7634 14.0001H17.2692L19.2135 7.50007H5.70765L3.7634 14.0001Z" fill={color || "#879FB7"} />
        </svg>
    </i>
    )
}

export default FolderAllOpenIcon
