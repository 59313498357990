import Select from "react-select";
import React, { useState } from "react";
import {UserStatusMap} from "../signups";

export default function StatusSelect({ user, onChangeStatus }) {
    const statuses = Object.keys(UserStatusMap);
    const options = statuses.map(t => ({ label: UserStatusMap[t].name, value: t }))
    const [status, setStatus] = useState(options.find(({value}) => +value === +(user.status)));

    return (
        <div className="form-inline pt-3">
            <div>User Status: </div>
            <Select 
                classNamePrefix="Select"
                classNames={{
                    control: (state) =>
                        state.isFocused ? 'Select-control' : 'Select-control',
                }}
                className="mt-2 w-100"
                isSearchable={false}
                isClearable={false}
                onChange={option => setStatus(option)}
                options={options}
                value={status}
                style={{backgroundColor: 'white'}}
            />
            <button className="btn btn-primary btn-sm mt-2" onClick={() => onChangeStatus(user.id, status.value)}>Update Status</button>
        </div>

    )
}
