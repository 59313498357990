import classnames from 'classnames';
import moment from 'moment';
import React from 'react';

import { Link } from 'react-router-dom';
import {
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';

class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        massagebody: `Hi {CLIENT_NAME}! 
I recently signed up for BizNexus and would greatly appreciate it if you could take two minutes 
to leave me a review when you have a free moment.
You can do that at this url: ${process.env.REACT_APP_ROOT + "reviews/" + props.slugUrl}
Many thanks for your help,
${props.firstName}`
      },
      errors: {},
      success: {},
      reviewslist: [],
      editreviewscollapse: false,
      reviewsmodal: false,
      activeTab: '1',
      reviewsData: 'none',
      clientName: '{CLIENT_NAME}',
    };
    this.reviewcollapsestoggle = this.reviewcollapsestoggle.bind(this);
    this.reviewstoggle = this.reviewstoggle.bind(this);
    this.tabtoggle = this.tabtoggle.bind(this);
    this.SubmitReviews = this.SubmitReviews.bind(this);
  }
  reviewcollapsestoggle(e) {
    e.preventDefault();
    this.setState({ editreviewscollapse: !this.state.editreviewscollapse });
    if (this.state.reviewsData === '') {
      this.setState({ reviewsData: 'none' });
    } else {
      this.setState({ reviewsData: '' });
    }
  }
  reviewstoggle() {
    this.setState({
      reviewsmodal: !this.state.reviewsmodal
    });
    window.setTimeout(() => {
      if (document.getElementById('reviewsname') !== null) {
        document.getElementById('reviewsname').focus();
      }
    }, 500);
  }
  tabtoggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      window.setTimeout(() => {
        if (document.getElementById('reviewsname') !== null) {
          document.getElementById('reviewsname').focus();
        }
        if (document.getElementById('generatelink') !== null) {
          document.getElementById('generatelink').focus();
        }
      }, 100);
    }
  }

  componentDidMount() {
    this.getreviewlist();
  }

  componentDidUpdate(prevProps) {
    if (this.props.slugUrl !== prevProps.slugUrl) { 
      let fields = this.state.fields.slice();
      fields["subject"] = "Please review me on BizNexus!";
      fields["massagebody"] = `Hi ${this.state.clientName}!
A brief request: I recently signed up for BizNexus and could use your help getting my profile ready for public viewing... I would greatly appreciate it if you could take a couple minutes to leave me a review.
You can do that at this url: ${process.env.REACT_APP_ROOT + "reviews/" + this.props.slugUrl + "?name={CLIENT_NAME}&email={CLIENT_EMAIL}"}
Many thanks for your help and hope you are well!
${this.props.profileName}`;
      this.setState({ fields });
      this.getreviewlist();
    }
  }
  handleaddClientlistValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //reviewsname
    if (!fields["reviewsname"]) {
      formIsValid = false;
      errors["reviewsname"] = "Please enter a valid name";
    }
    //Email
    if (!fields["reviewsemail"]) {
      formIsValid = false;
      errors["reviewsemail"] = "Please enter a valid email";
    }
    if (typeof fields["reviewsemail"] !== "undefined") {
      let lastAtPos = fields["reviewsemail"].lastIndexOf('@');
      let lastDotPos = fields["reviewsemail"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["reviewsemail"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["reviewsemail"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["reviewsemail"] = "Please enter valid email address.";
      }
    }
    //subject
    if (!fields["subject"]) {
      formIsValid = false;
      errors["subject"] = "Please enter subject.";
    }
    //massagebody
    if (!fields["massagebody"]) {
      formIsValid = false;
      errors["massagebody"] = "Please enter bodytext.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  handleChangeclientlist(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  SubmitReviews = event => {
    event.preventDefault();
    if (this.handleaddClientlistValidation()) {
      document.getElementById('loaderreviewsform').setAttribute("style", "display: block;");
      let fields = this.state.fields;
      let clientData = {
        "name": fields["reviewsname"],
        "email": fields["reviewsemail"],
        "profile_slug": this.props.slugUrl,
      };

      let jsonreviewsData = JSON.stringify({
        "email_subject": fields["subject"],
        "email_body": fields["massagebody"],
        "client_data": clientData,
      });
      //console.log(jsonreviewsData);
      fetch(process.env.REACT_APP_API_ROOT + 'review/sendemail', {
        method: 'POST',
        body: jsonreviewsData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          'x-access-token': localStorage.getItem('token'),
        },
      }).then((res) => {
        if (res.status < 200 || res.status >= 300) {
          //console.log("Error 401");
          //console.log(res);
          if (res.status === 401) {
            this.props.history.push('/');
          } else {
            return res.json().then(err => { throw err; });
          }
        } else { return res.json() }
      })
        .then(
          (res) => {
            //console.log(res);
            document.getElementById('loaderreviewsform').setAttribute("style", "display: none;");
            let success = {};
            success["success"] = "Mail sent successfully.";
            // eslint-disable-next-line
            this.state.fields["reviewsname"] = '', this.state.fields["reviewsemail"] = '';
            this.setState({ success: success });
            if (document.getElementById('reviewsname') !== null) {
              document.getElementById('reviewsname').focus();
            }
            window.setTimeout(() => {
              this.setState({ success: '' });
            }, 5000);
          },
          (error) => {
            this.setState({
              //error
            });
          }
        )
    } else {
      document.getElementById('reviewsmodalform').scrollIntoView(true);
      //console.log("Form has errors.")
    }
  }
  getreviewlist = () => {
    fetch(process.env.REACT_APP_API_ROOT + 'review/list', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        'x-access-token': localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status < 200 || res.status >= 300) {
        //console.log("Error 401");
        //console.log(res);
        if (res.status === 401) {
          this.props.history.push('/');
        } else {
          return res.json().then(err => { throw err; });
        }
      } else { return res.json() }
    })
      .then(
        (res) => {
          //console.log(res);
          if (res !== undefined && res.length > 0) {
            this.setState({ reviewslist: res });
          }
          else {
            this.setState({ reviewslist: [] });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            //error
          });
        }
      )
  }
  handlereviewsClick = (e) => {
    e.preventDefault();
    if (e.target.id !== '') {
      document.getElementById('loaderreviews').setAttribute("style", "display: block;");
      let id = e.target.id;
      let dataSplitreviewsArray = id.split("_");
      let jsonreviewsData = JSON.stringify({
        "id": dataSplitreviewsArray[1]
      });
      //console.log(jsonreviewsData);
      fetch(process.env.REACT_APP_API_ROOT + 'review/deletereview', {
        method: 'POST',
        body: jsonreviewsData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          'x-access-token': localStorage.getItem('token'),
        },
      }).then((res) => {
        if (res.status < 200 || res.status >= 300) {
          //console.log("Error 401");
          //console.log(res);
          if (res.status === 401) {
            this.props.history.push('/');
          } else {
            return res.json().then(err => { throw err; });
          }
        } else { return res.json() }
      })
        .then(
          (res) => {
            //console.log(res);
            document.getElementById('loaderreviews').setAttribute("style", "display: none;");
            this.getreviewlist();
          },
          (error) => {
            this.setState({
              //error
            });
          }
        )
    }
  }
  starRatings = (stars) => {
    let ratings = [];
    let filledstars = stars;
    let blankstars = 5 - stars;
    for (let k = 0; k < filledstars; k++) {
      ratings.push(<i className="sprite i-starfill" key={"filledstars" + k}></i>);
    }
    for (let k = 0; k < blankstars; k++) {
      ratings.push(<i className="sprite i-star" key={"blankstars" + k}></i>);
    }
    return ratings;
  }
  createMarkup(data) {
    return { __html: data };
  }
  render() {
    return (
      <div>
        <div className="loader" id="loaderreviews" style={{ display: 'none' }}><div className="spiner"></div></div>
        <div className="">
          <FormGroup className="control-bx mb-5">
            <Label className="h1 mb-3">{'Reviews: '}
            <i id="ReviewsTooltip"
                                                        className="sprite i-question"></i>
                                    <UncontrolledTooltip placement="bottom" target="ReviewsTooltip"
                                                        autohide={false}
                                                        innerClassName="certification-tooltip">Request reviews from trusted colleagues and happy customers to give your BizNexus profile essential social proof of your qualifications in the industry.</UncontrolledTooltip>
            </Label>
            {
              this.state.reviewslist.length <= 0 &&
              <p className="text-center">Your profile is missing client reviews. <br />
                Request client reviews now to improve search results and increase seller connection requests</p>
            }
            <button className="btn btn-primary m-auto d-block" onClick={this.reviewstoggle}>Get Client Reviews</button>
            <Modal isOpen={this.state.reviewsmodal} toggle={this.reviewstoggle} className="profile-modal" keyboard={false} backdrop="static" id="reviewsmodalform">
              <ModalHeader className="mb-3" toggle={this.reviewstoggle}>Getting client reviews is the best way to improve your profile.</ModalHeader>
              <ModalBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.tabtoggle('1'); }}>Enter by name</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.tabtoggle('2'); }}>Manually send link</NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="position-relative">
                      <div className="loader bg-white" id="loaderreviewsform" style={{ display: 'none' }}><div className="spiner"></div></div>
                      <p>Use this form to send an email with a link to review your profile.</p>
                      <Form onSubmit={this.SubmitReviews}>
                        <Row>
                          <Col sm="6">
                            <FormGroup>
                              <Label htmlFor="reviewsname">Enter name:</Label>
                              <Input type="text" name="reviewsname" id="reviewsname" ref="reviewsname" onChange={this.handleChangeclientlist.bind(this, "reviewsname")} value={this.state.fields["reviewsname"]} placeholder="John Doe" />
                              <span className="error  ">{this.state.errors["reviewsname"]}</span>
                            </FormGroup>
                          </Col>
                          <Col sm="6">
                            <FormGroup>
                              <Label htmlFor="reviewsemail">Enter email address:</Label>
                              <Input type="email" name="reviewsemail" id="reviewsemail" placeholder="john.doe@gmail.com" ref="reviewsemail" onChange={this.handleChangeclientlist.bind(this, "reviewsemail")} value={this.state.fields["reviewsemail"]} />
                              <span className="error  ">{this.state.errors["reviewsemail"]}</span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label htmlFor="subject">Subject:</Label>
                          <Input type="text" name="subject" id="subject" ref="subject" onChange={this.handleChangeclientlist.bind(this, "subject")} value={this.state.fields["subject"]} placeholder="Please review me on BizNexus!" />
                          <span className="error  ">{this.state.errors["subject"]}</span>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="massagebody">Body:</Label>
                          <Input
                              type="textarea"
                              name="massagebody"
                              id="massagebody"
                              ref="massagebody"
                              style={{ height: 'auto!important' }}
                              onChange={this.handleChangeclientlist.bind(this, "massagebody")}
                              value={this.state.fields["massagebody"]}
                              rows="8" />
                          <span className="error  ">{this.state.errors["massagebody"]}</span>
                          <p className="mt-5">You can use {'{'}CLIENT_NAME{'}'} and {'{'}PROFILE_LINK{'}'} placeholders in your email</p>
                          <p className="text-success text-center mt-3">{this.state.success["success"]}</p>
                        </FormGroup>
                        <button type="submit" className="btn btn-primary w-100">Send Email</button>
                      </Form>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <p>Send your past clients an email asking for their help completing your profle and include the link below. That will take them directly to a page where they can leave you a review.</p>
                    <FormGroup>
                      <Label htmlFor="generatelink">Ask your clients for reviews at this link (copy and paste wherever you'd like):</Label>
                      <Input type="text" name="generatelink" id="generatelink" ref="generatelink" value={process.env.REACT_APP_ROOT + "reviews/" + this.props.slugUrl} />
                      <span className="error  ">{this.state.errors["generatelink"]}</span>
                    </FormGroup>
                  </TabPane>
                </TabContent>
              </ModalBody>
            </Modal>
          </FormGroup>
          {
            this.state.reviewslist.map((reviews, j) => {
              let firstname = '';
              let fullname = reviews.reviewer_name.match(/(\w+)(?:\s[^\s]+)?\s(\w+).*/);
              if (fullname !== null) {
                firstname = fullname[1] + " " + fullname[2].charAt(0) + ".";
              }
              else {
                firstname = reviews.reviewer_name;
              }
              return (
                reviews.status === "1" && j < 3 &&
                <div className="reviews-list" key={`reviews-${j}`}>
                  <div className="review-userpic">
                    <img src={(reviews.image === null || reviews.image === "") ? process.env.PUBLIC_URL + '/images/profile_pic.png' :
                      process.env.PUBLIC_URL + '/images/profile-pic/' + reviews.image} alt={firstname} className="img-fluid rounded-circle" />
                  </div>
                  <div className="review-usercomment">
                    <h5>{reviews.review_title} <span className="ms-2">
                      {this.starRatings(reviews.rating_options_list)}
                    </span></h5>
                    <p dangerouslySetInnerHTML={this.createMarkup(reviews.review_text)}></p>
                    <p className="text-lighted">{reviews.consultation_hire} this intermediary <i className="sprite i-info" id={`Tooltip-${j}`}></i>
                      <UncontrolledTooltip placement="bottom" target={`Tooltip-${j}`} autohide={false} innerClassName="review-tooltip">{reviews.consultation_hire === 'Hired' ? `${this.props.FirstName} was hired as an intermediary for a specific engagement with this reviewer.` : `${this.props.FirstName} consulted as an intermediary, but did not necesarily get hired, by this reviewer for a specific engagement.`}</UncontrolledTooltip>
                    </p>
                    <p><strong>Where {this.props.FirstName} excels according to reviewer:</strong></p>
                    <p>{reviews.review_question_list}</p>
                    <p className="text-blue">{firstname !== "" && <React.Fragment>{firstname} <span className="pl-2 pr-2">|</span></React.Fragment>} {moment(reviews.created_at).format("MMMM DD, YYYY")}</p>
                    {/* <a href="" onClick={e => { e.preventDefault()}}><i id={"reviews_" + reviews.id} onClick={this.handlereviewsClick} className="sprite i-delete"></i></a> */}
                  </div>
                </div>
              )
            })
          }
          <Collapse isOpen={this.state.editreviewscollapse}>
            {
              this.state.reviewslist.map((reviews, j) => {
                let firstname = '';
                let fullname = reviews.reviewer_name.match(/(\w+)(?:\s[^\s]+)?\s(\w+).*/);
                if (fullname !== null) {
                  firstname = fullname[1] + " " + fullname[2].charAt(0) + ".";
                }
                else {
                  firstname = reviews.reviewer_name;
                }
                return (
                  reviews.status === "1" && j >= 3 &&
                  <div className="reviews-list" key={`reviews-${j}`}>
                    <div className="review-userpic">
                      <img src={(reviews.image === null || reviews.image === "") ? process.env.PUBLIC_URL + '/images/profile_pic.png' :
                        process.env.PUBLIC_URL + '/images/profile-pic/' + reviews.image} alt={firstname} className="img-fluid rounded-circle" />
                    </div>
                    <div className="review-usercomment">
                      <h5>{reviews.review_title} <span className="ms-2">
                        {this.starRatings(reviews.rating_options_list)}
                      </span></h5>
                      <p dangerouslySetInnerHTML={this.createMarkup(reviews.review_text)}></p>
                      <p className="text-lighted">{reviews.consultation_hire} this intermediary <i className="sprite i-info" id={`Tooltip-${j}`}></i>
                        <UncontrolledTooltip placement="bottom" target={`Tooltip-${j}`} autohide={false} innerClassName="review-tooltip">{reviews.consultation_hire === 'Hired' ? `${this.props.FirstName} was hired as an intermediary for a specific engagement with this reviewer.` : `${this.props.FirstName} consulted as an intermediary, but did not necesarily get hired, by this reviewer for a specific engagement.`}</UncontrolledTooltip>
                      </p>
                      <p><strong>Where {this.props.FirstName} excels according to reviewer:</strong></p>
                      <p>{reviews.review_question_list}</p>
                      <p className="text-blue">{firstname !== "" && <React.Fragment>{firstname} <span className="pl-2 pr-2">|</span></React.Fragment>} {moment(reviews.created_at).format("MMMM DD, YYYY")}</p>
                      {/* <a href="" onClick={e => { e.preventDefault()}}><i id={"reviews_" + reviews.id} onClick={this.handlereviewsClick} className="sprite i-delete"></i></a> */}
                    </div>
                  </div>
                )
              })
            }
          </Collapse>
          {
            this.state.reviewslist.length > 3 &&
            <p className="text-center toggle-link"> <Link to="" className="link-more" onClick={this.reviewcollapsestoggle}>{(this.state.reviewsData === '') ? "Hide more reviews" : "Read more reviews"} <i className={(this.state.reviewsData === '') ? "sprite i-up-arrow" : "sprite i-down-arrow"}></i></Link></p>
          }

        </div>
      </div>
    );
  }
}
export default Reviews;
