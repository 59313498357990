import { useNavigate, useLocation, useParams } from 'react-router';

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();
        const location = useLocation()
        const params = useParams()

        return (
            <Component
                history={{
                    location,
                    push: navigate
                }}
                location={location}
                match={{params}}
                {...props}
            />
        );
    };

    return Wrapper;
};
