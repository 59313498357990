import { jwtDecode } from "jwt-decode";

export const isTokenExpired = () => {
	var decodedToken = jwtDecode(localStorage.getItem('token'));
	var tokenExpiry = decodedToken.exp;
	var current_time = new Date().getTime() / 1000;
	if (current_time > tokenExpiry) {
		//console.log("Token Expired");
		return true;
	}else{
		/*console.log("Token Alive : ");
		console.log(decodedToken);
		
		console.log("Init: " + new Date(decodedToken.iat*1000));
		console.log("Exp: " + new Date(tokenExpiry*1000));*/
		return false;
	}
}

export const isValidUser = () => {
	/*console.log(localStorage.getItem('userId'));
	console.log(localStorage.getItem('token'));
	console.log(localStorage.getItem('refreshToken'));*/
	return ((localStorage.getItem('token') (localStorage.getItem('loggedIn') === "true") && !isTokenExpired()) ? true : false) ;
}

