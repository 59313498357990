import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {getImageUrl} from '../util';
import {FeaturedListingType} from '../../constants/Listing';
import AdDisplayer from '../../components/AdDisplayer'
import stripeEnv from '../../constants/stripe'
import { find } from 'lodash'
import DealNotes from "../DealNotes/dealNotes";
import FolderOpenIcon from "../icons/FolderOpenIcon";
import {useAuthContext} from "../../contexts/auth";

const ListItem = ({data, updateNotes, type, onFollow, j, onHideDeal, onAddToFavorite, savedListings, premium, viewMode, withNotes, onAssignListing}) => {

    const { loggedInValue, isAdmin } = useAuthContext()
  const images = {
    1293401: process.env.REACT_APP_ROOT + '/images/default_listing/techmedia.jpg',
    1293501: process.env.REACT_APP_ROOT + '/images/default_listing/manufacturing.jpg',
    1293601: process.env.REACT_APP_ROOT + '/images/default_listing/food.jpg',
    1293701: process.env.REACT_APP_ROOT + '/images/default_listing/leisure.jpg',
    1293801: process.env.REACT_APP_ROOT + '/images/default_listing/retail.jpg',
    1293901: process.env.REACT_APP_ROOT + '/images/default_listing/services.jpg',
    1299301: process.env.REACT_APP_ROOT + '/images/default_listing/agriculture.jpg',
    1316601: process.env.REACT_APP_ROOT + '/images/default_listing/engineering.jpg',
    1358401: process.env.REACT_APP_ROOT + '/images/default_listing/franchise.jpg',
    1360201: process.env.REACT_APP_ROOT + '/images/default_listing/realestate.jpg',
    1365401: process.env.REACT_APP_ROOT + '/images/default_listing/wholesale.jpg',
    9999999: process.env.REACT_APP_ROOT + '/images/default_listing/other.jpg', //

    1296101: process.env.REACT_APP_ROOT + '/images/default_listing/maintenance.png',
    1342801: process.env.REACT_APP_ROOT + '/images/default_listing/printing.png',
    1386501: process.env.REACT_APP_ROOT + '/images/default_listing/wedding.png',
    1294502: process.env.REACT_APP_ROOT + '/images/default_listing/advertising.png',
    1297301: process.env.REACT_APP_ROOT + '/images/default_listing/postal.png',
    1297201: process.env.REACT_APP_ROOT + '/images/default_listing/pets.png',
    1296801: process.env.REACT_APP_ROOT + '/images/default_listing/hire.png',
    1296501: process.env.REACT_APP_ROOT + '/images/default_listing/financial.png',
    1296401: process.env.REACT_APP_ROOT + '/images/default_listing/transportation.png',
    1296901: process.env.REACT_APP_ROOT + '/images/default_listing/house.png',
    1299101: process.env.REACT_APP_ROOT + '/images/default_listing/construction.png',
  };
  
  const { industries } = data;
  const first_industry = find(industries, e => e.industry.parent_id === 1);
  const first_sub_industry = find(industries, e => e.industry.parent_id !== 1);
  
  if(first_industry) {
    data.image = images[first_industry.industry.id]
  } else if(industries?.[0]?.industry?.parent_id) {
    data.image = images[industries[0].industry.parent_id]
  }

  if(first_sub_industry) {
    data.image = images[first_sub_industry.industry.id] || data.image
  }

  function truncString(str, max, add) {
    add = add || '...';
    return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
  }
  
  const metricMultiple = (value, valueMultiple) => {
    if(value && valueMultiple) {
      return `${value} / ${valueMultiple.toFixed(2)}`;
    } else {
      return value;
    }
  }
    const getDealName = deal => `'${deal.name}' - ${deal.revenue && deal.revenue !== 'Not Specified' ? deal.revenue + ', ' : ''}${deal.location_address}`

    const loggedIn = loggedInValue === 'true'

    if (viewMode === 'table') {
    return (
      <tr>
          <td style={{ textAlign: 'center', padding: '8px 0' }}>
          {savedListings && savedListings.includes(data.id) ?
              <button style={{ color: 'red' }} className="btn btn-unstyled" onClick={() => onAddToFavorite(data)}>
              <svg style={{ fontSize: 20 }} className="icon" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0049 1.96984C18.6619 -4.87316 35.3059 7.10134 12.0049 22.4988C-11.2961 7.10284 5.34792 -4.87316 12.0049 1.96984Z" fill="currentColor"></path>
              </svg></button> :

              <button style={{ color: '#6D7277' }
              } onClick={() => onAddToFavorite(data)} className="btn btn-unstyled">
                <svg style={{ fontSize: 20 }} className="icon" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0023 4.12093L10.9268 3.01543C8.40228 0.420428 3.77328 1.31593 2.10228 4.57843C1.31778 6.11293 1.14078 8.32843 2.57328 11.1559C3.95328 13.8784 6.82428 17.1394 12.0023 20.6914C17.1803 17.1394 20.0498 13.8784 21.4313 11.1559C22.8638 8.32693 22.6883 6.11293 21.9023 4.57843C20.2313 1.31593 15.6023 0.418928 13.0778 3.01393L12.0023 4.12093ZM12.0023 22.4989C-10.9972 7.30093 4.92078 -4.56107 11.7383 1.71343C11.8283 1.79593 11.9168 1.88143 12.0023 1.96993C12.0869 1.88151 12.175 1.79644 12.2663 1.71493C19.0823 -4.56407 35.0018 7.29943 12.0023 22.4989Z" fill="currentColor"/>
              </svg></button>
          }
              {
                  !!onAssignListing && <>
                    <div style={{ width: '20px', margin: '10px', backgroundColor: '#ccc', height: '1px' }}/>
                      <button type={"button"}
                              className="btn btn-unstyled"
                              onClick={() => onAssignListing(data)}>
                      <FolderOpenIcon color="#888888"/>
                  </button>
              </>
              }

          </td>
          <td>
              {data.guru_id === 0 && isAdmin && <span style={{ backgroundColor: 'transparent', border: '1px solid black', display: 'inline-flex', marginBottom: 5, marginRight: 10 }} class="badge text-dark">
                <i style={{ color: 'gold' }} class="fas fa-star"/> Premium Deal
              </span>}
              {data.completed === 2 && isAdmin && <span style={{ backgroundColor: 'transparent', border: '1px solid black', display: 'inline-flex', marginBottom: 5, marginRight: 10 }} class="badge text-dark">
                  Private Deal
              </span>}
              {data.isConcierge && <span style={{ backgroundColor: '#58ae3a', border: '1px solid black', display: 'inline-flex', marginBottom: 5, marginRight: 10 }} class="badge text-dark">
                <i style={{ color: 'gold' }} class="fa-solid fa-crown"/> Concierge Deal
              </span>}
              {data.seller_financing && <span style={{ backgroundColor: 'transparent', border: '1px solid black', display: 'inline-flex', marginBottom: 5 }} className={`badge text-dark ${data.guru_id === 0 ? 'ms-1' : 'me-1'}`}>
                Seller Financing Available
              </span>}
              {data.keywords === 'FSBO' && <><span style={{ backgroundColor: 'transparent', border: '1px solid black', display: 'inline-flex', marginBottom: 5 }} className={`badge text-dark`}>
                <i style={{ color: '#3890da' }} className="far fa-gem"/>{` Proprietary Deal`}
              </span><br/></>}
              <a href={data.isAnonymous ? `/user/subscription` : `/business-for-sale/${data.transaction_slug}`} target="_blank" rel="noopener noreferrer">{truncString(data.name, 100)}</a>
              </td>
          <td className="">
          {data.industries?.map(catresult => catresult.industry.name).join(', ')}
          </td>
          <td className="">{truncString(data.location_address, 30)}</td>
          <td className="">{(data.hide_price || !data.price) ? 'Not Specified' : data.price}</td>
          <td className="">{metricMultiple(data.revenue, data.revenue_multiple)}</td>
          <td className="">{metricMultiple(data.ebitda, data.ebitda_multiple)}</td>
          <td className="">{metricMultiple(data.cashflow, data.cashflow_multiple)}</td>
          {
              withNotes && <td>
                  <div onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                  }}>
                      <DealNotes
                          notes={data.notes}
                          dealName={getDealName(data)}
                          updateNotes={updateNotes}
                          dealId={data.id}
                      />
                  </div>
              </td>
          }
          <td className="">{moment(data.listing_date).format('M/D/YY')}</td>
      </tr>
    )
  } else {
    return (
      <>
      {j === 3 && <div style={{ paddingBottom: '15px' }} className="text-center"><AdDisplayer planId={stripeEnv.ad_search_first} /></div>}
      {j === 9 && <div style={{ paddingBottom: '15px' }} className="text-center"><AdDisplayer planId={stripeEnv.ad_search_second} /></div>}
      <div class="business-card position-relative" onClick={(e) => {
        if(!data.guru_id !== 0 && !data.isAnonymous) {
          window.open(`/business-for-sale/${data.transaction_slug}`, '_blank');
        }
      }} style={{ width: '100%', cursor: 'pointer' }}>

        {((j+1)%3 === 0 && !loggedIn) &&
          <div className="justify-content-center row " style={{ 
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto',
            top: 'calc(50% - 50px)',
            zIndex: 998,
        }}>
            <div className="col-auto">
                <div className="profile-bx">
                    <div className="mt-4 mb-4">
                        <p className="text-center" style={{ fontSize: '17px', fontWeight: '800' }}>
                            Sign in OR create your free account now to view your search results
                        </p>
                        <p className="text-center">
                            <a href="/signup" className="btn btn-success">Create a Free Account Now</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        }

        <div class="business-card__image position-relative" style={{ filter: (j+1) % 3 === 0 && !loggedIn ? 'blur(4px)': '' }}>

        <img src={(data.image !== null && data.image !== '')
                        ? getImageUrl(data.image, true)
                        : process.env.PUBLIC_URL + '/images/empty.jpg'
                    } alt="" style={{  }} />
  
            <div className="overlay">
              {
                data.industries?.map((catresult, key) => {
                  return (
                    <span className="result-category me-2 mb-2"
                          key={`catresultList-${key}`}>{catresult.industry.name}</span>
                  )
                })
              }
              {moment(data.created_at).add(14, 'days') > moment(new Date()) && <span style={{ backgroundColor: 'green', opacity: '0.8' }} className="result-category me-2 mb-2">NEW {moment(data.created_at).fromNow()}</span>}
  
              
            </div>

            <div className="business-card_price_button" style={{ position: 'absolute', bottom: 0 }}>
              <div style={{ borderRadius: 0 }} class="btn btn-primary w-100">Price: {(data.hide_price || !data.price) ? 'Not Specified' : data.price }</div>
            </div>

        </div>
        <div  class="business-card__content business-card-list-item" style={{ width: '100%', filter: (j+1) % 3 === 0 && !loggedIn ? 'blur(4px)': '' }}>
            <div class="business-card__actions">
            {/* {savedListings && <button class={`btn btn-unstyled ${ savedListings && savedListings.includes(data.id) ? 'active' : ''}`} onClick={() => savedListings && savedListings.includes(data.id) ? null: onAddToFavorite(data)}>
                <svg style={{ fontSize: 20 }} class="icon" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0049 1.96984C18.6619 -4.87316 35.3059 7.10134 12.0049 22.4988C-11.2961 7.10284 5.34792 -4.87316 12.0049 1.96984Z" fill="currentColor"></path>
                </svg>
            </button>} */}
            
            {savedListings && savedListings.includes(data.id) ?
              <button style={{ color: 'red' }} className="btn btn-unstyled" onClick={(e) => {
                  e.stopPropagation();
                  onAddToFavorite(data);
              }}>
              <svg style={{ fontSize: 20 }} className="icon" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0049 1.96984C18.6619 -4.87316 35.3059 7.10134 12.0049 22.4988C-11.2961 7.10284 5.34792 -4.87316 12.0049 1.96984Z" fill="currentColor"></path>
              </svg></button> :

              loggedIn && <button style={{ color: 'gray' }} onClick={(e) => {
                  e.stopPropagation();
                  onAddToFavorite(data);
                }} className="btn btn-unstyled">
                <svg style={{ fontSize: 20 }} className="icon" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0023 4.12093L10.9268 3.01543C8.40228 0.420428 3.77328 1.31593 2.10228 4.57843C1.31778 6.11293 1.14078 8.32843 2.57328 11.1559C3.95328 13.8784 6.82428 17.1394 12.0023 20.6914C17.1803 17.1394 20.0498 13.8784 21.4313 11.1559C22.8638 8.32693 22.6883 6.11293 21.9023 4.57843C20.2313 1.31593 15.6023 0.418928 13.0778 3.01393L12.0023 4.12093ZM12.0023 22.4989C-10.9972 7.30093 4.92078 -4.56107 11.7383 1.71343C11.8283 1.79593 11.9168 1.88143 12.0023 1.96993C12.0869 1.88151 12.175 1.79644 12.2663 1.71493C19.0823 -4.56407 35.0018 7.29943 12.0023 22.4989Z" fill="currentColor"/>
              </svg></button>
            }
            {(loggedIn && onHideDeal) && <button class="btn btn-unstyled" onClick={(e) => {
              e.stopPropagation();
              onHideDeal(data.id);
            }}>
                <svg style={{ fontSize: 20 }} class="icon" viewBox="0 0 49 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M48.6655 20.4711C45.6913 16.5744 42.1191 13.1729 38.0816 10.3929L44.987 3.48748C45.6822 2.81606 45.7015 1.70824 45.0301 1.01303C44.3587 0.317821 43.2509 0.298544 42.5556 0.969964C42.5411 0.984011 42.5267 0.998367 42.5126 1.01303L34.9684 8.5502C31.7573 6.78285 28.1647 5.82197 24.5 5.75019C11.2001 5.75019 0.771984 19.8691 0.334454 20.471C-0.111485 21.0845 -0.111485 21.9154 0.334454 22.5289C3.30867 26.4256 6.88079 29.8271 10.9183 32.6071L4.01291 39.5125C3.3177 40.1839 3.29842 41.2918 3.96984 41.987C4.64126 42.6822 5.74908 42.7014 6.44429 42.03C6.45885 42.016 6.4732 42.0016 6.48735 41.987L14.0315 34.4498C17.2427 36.2171 20.8352 37.178 24.4999 37.2498C37.7998 37.2498 48.228 23.1309 48.6655 22.5289C49.1115 21.9155 49.1115 21.0846 48.6655 20.4711ZM13.4628 30.0679C9.89788 27.6936 6.70238 24.8066 3.97968 21.5C6.55565 18.3798 14.91 9.25012 24.5 9.25012C27.2285 9.30529 29.911 9.96266 32.3557 11.1751L29.3177 14.2131C25.2982 11.5445 19.8764 12.6396 17.2078 16.6591C15.2639 19.5871 15.2639 23.395 17.2078 26.3229L13.4628 30.0679ZM29.225 19.2513C29.5675 19.9516 29.747 20.7204 29.75 21.5C29.75 24.3995 27.3995 26.75 24.5 26.75C23.7204 26.747 22.9516 26.5675 22.2513 26.225L29.225 19.2513ZM19.7751 23.7487C19.4326 23.0484 19.253 22.2795 19.2501 21.4999C19.2501 18.6005 21.6005 16.25 24.5 16.25C25.2796 16.253 26.0484 16.4325 26.7488 16.775L19.7751 23.7487ZM24.5 33.7499C21.7716 33.6947 19.0892 33.0373 16.6444 31.8249L19.6824 28.7869C23.7019 31.4555 29.1237 30.3604 31.7922 26.3409C33.7361 23.4129 33.7361 19.605 31.7922 16.6771L35.5372 12.9321C39.1022 15.3064 42.2977 18.1935 45.0204 21.4999C42.4443 24.6203 34.0899 33.7499 24.5 33.7499Z" fill="currentColor"></path>
                </svg>
            </button>}
            </div>


            <h3 class="business-card__title">
                {truncString(data.name, 85)}
            </h3>
            
            <p class="d-flex" style={{ fontSize: 16 }}>
                {data.completed === 2 && isAdmin && <span style={{ backgroundColor: 'transparent', border: '1px solid black', display: 'inline-flex', marginBottom: 5, marginRight: 10 }} class="badge text-dark">
                  Private Deal
              </span>}
              {data.guru_id === 0 && isAdmin && <span style={{ backgroundColor: 'transparent', border: '1px solid black', marginRight: 10 }} class="badge text-dark">
                <i style={{ color: 'gold' }} class="fas fa-star"></i> Premium Deal
              </span>}
              {data.isConcierge && <span style={{ backgroundColor: '#58ae3a', border: '1px solid black' }} class="badge text-dark">
                <i style={{ color: 'gold' }} class="fa-solid fa-crown"></i> Concierge Deal
              </span>}
              {data.seller_financing && <span style={{ backgroundColor: 'transparent', border: '1px solid black' }} className={`badge text-dark ${data.guru_id === 0 ? 'ms-1' : 'me-1'}`}>
                Seller Financing Available
              </span>}
              {data.keywords === 'FSBO' && <span style={{ backgroundColor: 'transparent', border: '1px solid black' }} className={`badge text-dark`}>
                <i style={{ color: '#3890da' }} className="far fa-gem"></i>{` Proprietary Deal`}
              </span>}
            </p>            

            {data.location_address && <p class="business-card__location d-flex">
              <span class="me-1">
                  <svg class="icon" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z" fill="currentColor"></path>
                  </svg>
              </span>
              <span>{data.location_address} </span>
            </p>}
            <p class="business-card__description" style={{ whiteSpace: 'normal' }}>
            {data.isAnonymous ? <div style={{ position: 'relative' }}>
              <div style={{ filter: 'blur(4px)' }}>
                  {truncString(data.description, 300)}
              </div>
              <div className="justify-content-center row " style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)'
              }}>
                <div className="">
                  <div className="">
                    <div className="mt-4 mb-4">
                      <p className="text-center">
                        <a style={{ fontSize: 15 }} href="/user/subscription" className="btn btn-success"><i className="fas fa-lock"></i> Upgrade To View Description</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> : truncString(data.description, 300)}
            
            </p>
            <div class="row">
              <div class="col-md-2">
                  <p>
                      <span class="font-weight-bolder">Revenue: </span>
                      <p class="text-primary-blue">{data.revenue}</p>
                  </p>
              </div>
              <div class="col-md-2">
                  <p>
                      <span class="font-weight-bolder">SDE: </span>
                      <p class="text-primary-blue">{data.cashflow}</p>
                  </p>
              </div>
              <div class="col-md-2">
                  <p>
                      <span class="font-weight-bolder">EBITDA: </span>
                      <p class="text-primary-blue">{data.ebitda}</p>
                  </p>
              </div>
              <div className="col-md-2">
                <p>
                  <span className="font-weight-bolder">Price: </span>
                  <p className="text-primary-blue">{(data.hide_price || !data.price) ? 'Not Specified' : data.price }</p>
                </p>
              </div>
                {
                    !data.isAnonymous && <div className="col" style={{textAlign: 'right'}}>
                        <a style={{width: 185}} href={`/business-for-sale/${data.transaction_slug}`} target="_blank"
                                rel="noopener noreferrer" className="btn btn-primary btn-deal-cta">View details
                        </a>
                    </div>
                }

            </div>
            {/* <div class="row row-gutter-15">
              <div class="col">
                  <div class="btn btn-secondary w-100 price_button_no_cursor">Price: {data.hide_price ? 'Not Specified' : data.price}</div>
              </div>
              <div class="col">
                  <a href={`/business-for-sale/${data.transaction_slug}`} target="_blank" rel="noopener noreferrer" class="btn btn-primary w-100">View details</a>
              </div>
            </div> */}
        </div>
      </div></>
    )
  }
  

  
};

ListItem.propTypes = {
  type: PropTypes.oneOf([
    FeaturedListingType.GOLD,
    FeaturedListingType.SILVER,
    FeaturedListingType.BRONZE,
  ]),
  onFollow: PropTypes.func,
};

ListItem.defaultProps = {
  type: '',
  onFollow: () => {},
};

export default ListItem;
