import _ from 'lodash';
import ReactGA from 'react-ga4';

export const jsonHeader = {
  'Accept': 'application/json',
  'content-type': 'application/json'
};

export const multipartjsonHeader = {
	'mimeType': 'multipart/form-data'
};


export const tokenHeader = {
  'x-access-token':localStorage.getItem('token')
}

export const customFunction = (arg1,arg2) => {

  return arg1+'==='+arg2;

}


/*
  query response helpers:
 */
export const checkStatus = (response) => {
  if (response.status === 200) {
    return response;
  } else {
    throw response;
    /*
    let error = handleError(response);
    return Promise.reject(error);
    */
  }
};

export const handleError = (response) => {

  let errorMsg = "";
  try{
    return parseJSON(response).then((error)=>{
      if(error.data){
        errorMsg = error.data;
      }else if(_.get(error, "message", false)){
        errorMsg = error.message;
      }else if(_.get(error, "error_message", false)){
        errorMsg = error.error_message
      }else if(_.get(error, "error.message", false)){
        errorMsg = error.error.message;
      }else if(_.get(error, "error.name.error_message", false)){
        errorMsg = error.error.name.error_message;
      }else{
        errorMsg = "There was error in processing your request, Please try again.";
      }
      return errorMsg;
    })
  }
  catch(error){
    errorMsg = "There was error in processing your request, Please try again.";
    return Promise.resolve(errorMsg);
  }
}



export const parseJSON = (response) => {
  return response.json();
};

export const defaultListingImages = {
  1293401: process.env.REACT_APP_ROOT + 'images/default_listing/techmedia.jpg',
  1293501: process.env.REACT_APP_ROOT + 'images/default_listing/manufacturing.jpg',
  1293601: process.env.REACT_APP_ROOT + 'images/default_listing/food.jpg',
  1293701: process.env.REACT_APP_ROOT + 'images/default_listing/leisure.jpg',
  1293801: process.env.REACT_APP_ROOT + 'images/default_listing/retail.jpg',
  1293901: process.env.REACT_APP_ROOT + 'images/default_listing/services.jpg',
  1299301: process.env.REACT_APP_ROOT + 'images/default_listing/agriculture.jpg',
  1316601: process.env.REACT_APP_ROOT + 'images/default_listing/engineering.jpg',
  1358401: process.env.REACT_APP_ROOT + 'images/default_listing/franchise.jpg',
  1360201: process.env.REACT_APP_ROOT + 'images/default_listing/realestate.jpg',
  1365401: process.env.REACT_APP_ROOT + 'images/default_listing/wholesale.jpg',
  9999999: process.env.REACT_APP_ROOT + 'images/default_listing/other.jpg',
  1296101: process.env.REACT_APP_ROOT + 'images/default_listing/maintenance.png',
  1342801: process.env.REACT_APP_ROOT + 'images/default_listing/printing.png',
  1386501: process.env.REACT_APP_ROOT + 'images/default_listing/wedding.png',
  1294502: process.env.REACT_APP_ROOT + 'images/default_listing/advertising.png',
  1297301: process.env.REACT_APP_ROOT + 'images/default_listing/postal.png',
  1297201: process.env.REACT_APP_ROOT + 'images/default_listing/pets.png',
  1296801: process.env.REACT_APP_ROOT + 'images/default_listing/hire.png',
  1296501: process.env.REACT_APP_ROOT + 'images/default_listing/financial.png',
  1296401: process.env.REACT_APP_ROOT + 'images/default_listing/transportation.png',
  1296901: process.env.REACT_APP_ROOT + 'images/default_listing/house.png',
  1299101: process.env.REACT_APP_ROOT + 'images/default_listing/construction.png',
};

export function getImageUrl(inBucketPath, thumb, fallbackUrl) {
  if(inBucketPath && inBucketPath.indexOf('https://media.licdn.com/') === 0){
    return inBucketPath;
  } else if(inBucketPath && (inBucketPath.startsWith('https://') || inBucketPath.startsWith('http://') )) {
    return inBucketPath;
  } else if(inBucketPath && inBucketPath.indexOf('profile-image-') !== -1) {
    return '/images/' + inBucketPath;
  }
  else if (inBucketPath && inBucketPath !== "") {
    // prefix / for legacy urls
    inBucketPath = inBucketPath[0] === "/" ? 
      inBucketPath :
      "/" + inBucketPath;
    
    // add thumb prefix for thumbnail pics
    inBucketPath = thumb ?
      inBucketPath.replace(/([^/]+$)/, "thumb_$1") :
      inBucketPath;

    return process.env.REACT_APP_IMAGE_ROOT + inBucketPath;
  }
  else return fallbackUrl || "";
}

export function formatCurrency(c) {

  if(isNaN(c)) {
    return c;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });
  
  return formatter.format(c);
}


export const roundedMertic = (value, label = false) => {
  if( ! value) {
    return '';
  }

  const onlyNumbers = s => s.replace(/[^0-9]/g, '');
  const metric = parseInt(onlyNumbers(value));
  
  if(metric >= 100000 && metric < 1000000) {
    const randomTitleT = [`$${(Math.floor(metric / 100000) * 100000 ) / 1000}k+`,
                          `over $${(Math.floor(metric / 100000) * 100000 ) / 1000}k+`,
                          ]
    return label? `$${(Math.floor(metric / 100000) * 100000 ) / 1000}k+` : randomTitleT[Math.floor(Math.random() * randomTitleT.length)];
  }

  if(metric < 100000) {
    const randomTitleH = [`$${(Math.floor(metric / 10000) * 10000 ) / 1000}k+`,
                          `over $${(Math.floor(metric / 10000) * 10000 ) / 1000}k+`,
                          ]
    return label? `$${(Math.floor(metric / 10000) * 10000 ) / 1000}k+` : randomTitleH[Math.floor(Math.random() * randomTitleH.length)];
  }

  if(metric >= 1000000) {
    const randomTitleM = [`$${(Math.floor(metric / 1000000) * 1000000 ) / 1000000}M+`,
                          `over $${(Math.floor(metric / 1000000) * 1000000 ) / 1000000}M+`]
    return label? `$${(Math.floor(metric / 1000000) * 1000000 ) / 1000000}M+` : randomTitleM[Math.floor(Math.random() * randomTitleM.length)];
  }
  
  return metric;
}

export function renameListing(listing, premium) {
  if(premium) {
   return listing.name;
  }

  const f3words = '- ' + listing.name.split(' ').slice(0, 3).join(' ') + '...';

  const title = ['Deal', 'Biz', 'Opportunity', 'Acquisition', 'Listing'];
  const metric = [];

  if(listing.cashflow && listing.cashflow !== 'Not Specified') {
    metric.push('cashflow')
  }

  if(listing.ebitda && listing.ebitda !== 'Not Specified') {
    metric.push('ebitda')
  }

  if(listing.revenue && listing.revenue !== 'Not Specified') {
    metric.push('revenue')
  }

  if( ! metric.length && listing.price && listing.price !== 'Not Specified' && !listing.hide_price) {
    metric.push('price')
  }

  const randomTitle = title[Math.floor(Math.random() * title.length)];
  const randomMetric = metric.length ? metric[Math.floor(Math.random() * metric.length)] : [];

  return `${listing.industries && listing.industries[0] ? listing.industries[0].industry.name.replace('Tech & Media', 'Tech').replace('Other', '') : ''} ${randomTitle} ${randomMetric.length ? `with ${listing[randomMetric]} ${randomMetric.replace('cashflow', 'SDE')} ${f3words}`: `${f3words}`}`

}

export function trackEvent(body) {
  if (process.env.REACT_APP_ROOT === 'https://app.biznexus.com/') {
			ReactGA.event(body);
	}
}

export function getFirstAndLastName(name) {
  let fullname = name.match(/(\w+)(?:\s[^\s]+)?\s(\w+).*/);
  if (fullname !== null) {
    return {
      first: fullname[1],
      last: fullname[2]
    };
  } else {
    return {
      first: '',
      last: ''
    };
  }
}
