import React from 'react';

export default class EmbedListingsEditProfile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            embedCode: false,
            code: '',
        };
    }

    componentDidMount() {
        this.setState({
            code: `<iframe src="${process.env.REACT_APP_ROOT}embed/${this.props.userId}" frameborder="0" width="800" height="600"></iframe>`
        })
    }

    render() {
        return (
            <div>
                <h2 className="h1 mb-4">Embed Code</h2>
                <div style={{ paddingTop: '5px' }}>
                    
                        

                        
                        <div className="">
                            <div>
                                <p>To get the BizNexus to appear on your web app simply copy and paste the snippet below to the page where you want the BizNexus to appear for website visitors.</p>
                                <textarea value={this.state.code} style={{ width: '100%' }} rows="3"></textarea>
                            </div>
                        </div>
                        
                </div>
            </div>
        )
    }

}