import React, {useState} from 'react';
import { Alert } from "reactstrap";
import './custom-alert.scss'


const defaultProps = {
    dismissable: false,
    type: 'warning'
}

const CustomAlert = (props = defaultProps) => {
    const { text, type, dismissable } = props
    const [isOpen, setIsOpen] = useState(true)

    const onDismiss = () => setIsOpen(!isOpen)
    const logBack = (e) => {
        e.preventDefault()
        localStorage.setItem('token', localStorage.getItem('admin_token'));
        localStorage.removeItem('admin_token');
        if(localStorage.getItem('back_to_console_signup')) {
            localStorage.removeItem('back_to_console_signup');
            window.location.href = '/console/signups';
        } else {
            window.location.href = '/home';
        }
    }
    if (!text && !localStorage.getItem('admin_token')) {
        return null
    }
    return (<Alert className={`custom-alert ${type}`} color={type} toggle={onDismiss} isOpen={!dismissable || isOpen}>
        {
            text
                ? <>{type === 'info' ? <i className="fa-solid fa-circle-info"/> : ''} {text}</>
                : <>You are currently managing another account. Do you want to <a
            className="alert-link"
            onClick={logBack}
            href="/home"
            rel="noreferrer"
            target="_blank"
            >
            log back
            </a>?</>
        }
    </Alert>)
}

export default CustomAlert
