import React from 'react';
import api from '../../services/api';
import { Table, Row, Col } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {CSVLink} from "react-csv";
import {LeasedOrOwned} from "../../constants/Listing";
import {agents} from "../../constants/agents";

export default class News extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
        };
    }

    componentDidMount() {
        api.get(`console/agent/${this.props.id}`, null, true).then(list => {
            this.setState({ list });
        });
    }

    

    render() {

        return (
            <div>
                <Link to="/console/shared">&larr; Back</Link>
                        <br /><br />

                        <Row>
                            <CSVLink style={{ color: '#848484', margin: 15 }} data={this.state.list.map(s =>
                                (
                                    {
                                        ...s,
                                        link: `https://app.biznexus.com/business-for-sale/${s.transaction_slug}`,
                                        created_at: moment(new Date(s.created_at)).format('LLL'),
                                        modified_at: moment(new Date(s.modified_at)).format('LLL'),
                                    }
                                )
                            )} filename={`list.csv`}>
                                <button className="btn btn-tag" style={{ marginLeft: 5, fontSize: 13, float: 'right', padding: '15px 15px',  }}>
                                    <i className="fas fa-download"></i> Download
                                </button>
                            </CSVLink>
                        <Col sm="12">
                            
                            <Table bordered hover responsive>
                                <thead style={{ textAlign: 'center' }}>
                                    <tr>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Reference</th>
                                        {
                                            this.state.list[0]?.integration_id === agents.MailParser && <>
                                                <th>Source</th>
                                            </>
                                        }
                                        <th>Is Concierge</th>
                                        <th>Description</th>
                                        <th>Created At</th>
                                        <th>Price</th>
                                        <th>SDE</th>
                                        <th>EBITDA</th>
                                        <th>Revenue</th>

                                        <th>Location</th>
                                        

                                        <th>M&A Advisor Name</th>
                                        <th>M&A Advisor Email</th>
                                        <th>M&A Advisor Phone</th>
                                        <th>M&A Advisor Office Phone</th>
                                        <th>External Url</th>
                                        <th>Broker Company Name</th>
                                        <th>Broker Site Url</th>
                                        <th>Established</th>
                                        <th>Leased or Owned</th>
                                        <th>Real Estate Rent</th>
                                        <th>Reason of Selling</th>
                                        <th>Facilities</th>
                                        <th>Financing Overview</th>
                                        <th>Competition Overview</th>
                                        <th>Growth Opportunity</th>
                                        <th>Ongoing Support</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.list.map(s => 
                                        <tr style={{ textAlign: 'center' }}>
                                            <td>{s.id}</td>
                                            <td><a href={`/business-for-sale/${s.transaction_slug}`}>{s.name}</a></td>
                                            <td>{s.referencenumber}</td>
                                            {
                                                this.state.list[0]?.integration_id === agents.MailParser && <>
                                                    <td>{s.agent_source}</td>
                                                </>
                                            }
                                            <td>{s.isConcierge ? 'Yes' : 'No'}</td>
                                            <td>{s.description}</td>
                                            <td>{moment(new Date(s.created_at)).format('LLL')}</td>
                                            <td>{s.price}</td>
                                            <td>{s.cashflow}</td>
                                            <td>{s.ebitda}</td>
                                            <td>{s.revenue}</td>

                                            <td>{s.location_address}</td>
                                            

                                            <td>{s.brokerName}</td>
                                            <td>{s.brokerEmail}</td>
                                            <td>{s.brokerPhone}</td>
                                            <td>{s.brokerOfficePhone}</td>
                                            <td>{s.broker_external_url}</td>
                                            <td>{s.broker_company_name}</td>
                                            <td>{s.broker_site_url}</td>
                                            <td>{s.date}</td>
                                            <td>{s.leased_or_owned ? LeasedOrOwned[s.leased_or_owned] : ''}</td>
                                            <td>{s.real_estate}</td>
                                            <td>{s.reasonselling}</td>
                                            <td>{s.facilities}</td>
                                            <td>{s.financing}</td>
                                            <td>{s.competition}</td>
                                            <td>{s.growthopportunity}</td>
                                            <td>{s.ongoingsupport}</td>
                                            <td>{moment(new Date(s.modified_at)).format('LLL')}</td>
                                            
                                            
                                        </tr>    
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                        
                        </Row>
            </div>
        )
    }
}

