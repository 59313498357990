import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

function Dropzone({onSuccess, text, onFailure, accept, multiple, maxFileSize, className} = {
    multiple: false,
    text: 'Drag \'n\' drop some files here, or click to select files'
}) {
    const accepted = accept || {'image/png': [], 'image/jpeg': [], 'image/jpg': []}
    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (acceptedFiles.length) {
            onSuccess(acceptedFiles)
        }
        if (fileRejections.length) {
            onFailure(fileRejections)
        }

    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: accepted, multiple, maxSize: maxFileSize})

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>{text}</p>
            }
        </div>
    )
}

export default Dropzone
