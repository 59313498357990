import React from 'react';
import {
    Col,
    FormGroup,
    Form,
    Label,
    Row,
    Button,
    Input,
    Progress,
} from 'reactstrap';
// import _ from 'lodash';


export default class ExitPrep extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    componentDidMount() {
        
    }

    

    render() {
        
        return (
            <div className="profile-form mt-4 mb-3">
                <Row style={{ marginLeft: '50px', marginRight: '50px' }}>
                    <Col sm="12" className="bg-white" style={{ boxShadow: '5px 5px 5px gray' }}>
                        <h1 className="text-center">Real Estate (5 of 11)</h1>
                        <Progress multi style={{ height: '2rem' }}>
                            <Progress bar animated color="success" value="9">Overview</Progress>
                            <Progress bar animated color="success" value="9">Seller Info</Progress>
                            <Progress bar animated color="success" value="9">Company Info</Progress>
                            <Progress bar animated color="success" value="9">Financial Snapshot</Progress>
                            <Progress bar animated color="success" value="9">Real Estate (46%)</Progress>
                            <Progress bar color="info" value="9">Products</Progress>
                            <Progress bar color="info" value="9">Industry</Progress>
                            <Progress bar color="info" value="9">Customer</Progress>
                            <Progress bar color="info" value="9">Sales</Progress>
                            <Progress bar color="info" value="9">Employees</Progress>
                            <Progress bar color="info" value="12">Opportunities For Buyer</Progress>
                            
                        </Progress>
                        <Row style={{ marginTop: '15px' }}>
                            <Col sm="4">
                                <Form>
                                    <FormGroup>
                                        <Label for="exampleEmail">Field</Label>
                                        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="exampleEmail">Field</Label>
                                        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="exampleEmail">Field</Label>
                                        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col sm="4">
                                <Form>
                                    <FormGroup>
                                        <Label for="exampleEmail">Field</Label>
                                        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="exampleEmail">Field</Label>
                                        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="exampleEmail">Field</Label>
                                        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col sm="4">
                                <Form>
                                    <FormGroup>
                                        <Label for="exampleEmail">Field</Label>
                                        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="exampleEmail">Field</Label>
                                        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="exampleEmail">Field</Label>
                                        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                                    </FormGroup>
                                </Form>
                            </Col>

                        </Row>
                        <div style={{ display: 'block' }}>
                        <Row style={{ textAlign: 'right', display: 'block', padding: '15px' }}>
                            <Button style={{  }} color="primary">Back</Button>
                            <Button style={{ marginLeft: '10px' }} color="danger">Cancel</Button>
                            <Button style={{ marginLeft: '10px' }} color="secondary">Save as Draft</Button>
                            <Button style={{ marginLeft: '10px', backgroundColor: '#69c636', borderColor: '#69c636' }} color="primary">Next</Button>
                        </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}