import React, { useState } from "react";
import Textarea from "react-expanding-textarea";

export default function Note({ user, onChangeNote }) {

    const [note, setNote] = useState(user.internal_note);

    return (
        <div className="form-inline pt-3">
            <div>Internal Note:</div>
            <Textarea value={note} onChange={e => setNote(e.target.value)} rows={5} className="form-control w-100"></Textarea>
            <button className="btn btn-primary btn-sm mt-2" onClick={() => onChangeNote(user, note)}>Update Note</button>
        </div>

    )
}