import React from 'react';
import api from '../../services/api';
import {
    Table,
    Row,
    Col,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    InputGroup,
    ModalFooter,
    Button,
    Modal
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ExternalLink from "../common/ExternalLink";

export default class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            link: null,
            body: null,
            popup: false,
            popupLink: '',
            popupBody: '',
            popupId: null
        };
    }

    componentDidMount() {
        this.init();
    }

    init = () => {
        api.get('console/fetch_header', null, true).then(posts => {
            this.setState({ posts });
        });
    }

    onSubmit = () => {
        if(this.state.link && this.state.body) {
            api.post('console/add_header_item', {link: this.state.link, body: this.state.body}, true).then(() => {
                this.setState({ link: '', body: '' });
                this.init();
            });
        }
    }

    onDelete = id => {
        api.post(`console/delete_header_item`, { id }, true).then(() => {
            this.init();
        });
    }

    onClickUpdate = () => {
        if(this.state.popupBody && this.state.popupLink) {
            api.post(`console/update_header_item`, { body: this.state.popupBody, link: this.state.popupLink, id: this.state.popupId }, true).then(() => {
                this.setState({ popup: false, popupLink: '', popupBody: '' });
                this.init();
            });
        }
    }

    onEdit = item => {
        this.setState({ popup: true, popupLink: item.link, popupBody: item.body, popupId: item.id });
    }



    render() {

        return (
            <div>
                <Link to="/console">&larr; Back</Link>
                <br /><br />
                <Row>
                    <Col sm="8">
                        <h2>Header</h2>
                        <Table bordered hover responsive>
                            <thead style={{ textAlign: 'center' }}>
                            <tr>
                                <th>Name</th>
                                <th>URL</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.posts.map(s =>
                                <tr style={{ textAlign: 'center' }}>
                                    <td>{s.body}</td>
                                    <td>
                                        <ExternalLink href={s.link}>{s.link}</ExternalLink>
                                    </td>
                                    <td>
                                        <button onClick={() => this.onEdit(s)} className="btn btn-sm btn-primary">Edit</button>
                                        <button onClick={() => this.onDelete(s.id)} className="btn btn-sm btn-danger ms-1">Delete</button>
                                    </td>

                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                    <Col sm="4">
                        <div className="card">
                            <div className="card-body">
                                <h2>Add new</h2><br />
                                <input value={this.state.body} onChange={(e) => this.setState({ body: e.target.value })} className="form-control" type="text" placeholder="Name" /> <br />
                                <input value={this.state.link} onChange={(e) => this.setState({ link: e.target.value })} className="form-control" type="text" placeholder="URL" /> <br />
                                <button className="btn btn-primary" onClick={() => this.onSubmit()}>Add New</button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Modal isOpen={this.state.popup} toggle={() => {
                    this.setState({ popup: !this.state.popup })
                }} keyboard={false} backdrop="static">
                    <ModalHeader toggle={() => this.setState({ popup: !this.state.popup })}>Edit</ModalHeader>
                    <ModalBody className="pt-3 pb-5">
                        <FormGroup row>
                            <Label md={5}>Name</Label>
                            <Col md={6}>
                                <InputGroup>
                                    <textarea style={{ height: 100 }} onChange={e => this.setState({ popupBody: e.target.value })} value={this.state.popupBody} className="form-control"></textarea>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label md={5}>URL</Label>
                            <Col md={6}>
                                <InputGroup>
                                    <textarea style={{ height: 100 }} onChange={e => this.setState({ popupLink: e.target.value })} value={this.state.popupLink} className="form-control"></textarea>
                                </InputGroup>
                            </Col>
                        </FormGroup>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onClickUpdate}>Update</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
