import React from 'react';
import Testimonials from './testimonials';
import Awards from './awards';
import Reviews from '../../Reviews';
import api from '../../../services/api';
import { Row, Col } from 'reactstrap';
import {withRouter} from "../../../containers/withRouter/withRouter";

class SocialProof extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            slugUrl: '',
            filterSearch: null,
        };
    }

    async componentDidMount() {
        const header = await api.get('user/header', null, true);

        this.setState({
            slugUrl: header.slugValue
        });

        this.init(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.init(nextProps);
    }

    init = props => {
        const { match } = props;

        if(match.params.subtab) {
            const event = match.params.subtab;
            if(event === 'awards') {
                this.setState({ filterSearch: 'awards' });
            }

            if(event === 'testimonials') {
                this.setState({ filterSearch: 'testimonials' });
            }

            if(event === 'reviews') {
                this.setState({ filterSearch: 'reviews' });
            }

        } else {
            this.setState({ filterSearch: null });
        }
    }

    render() {
        return (
            <div>
                <Row>
                    <Col sm="3">
                    <p onClick={() => {
                            this.props.history.push('/user/social-proof');
                        }} className={`ms-1 ${this.state.filterSearch === null ? 'favorite-item-active' : 'favorite-item'} `} style={{ cursor: 'pointer', fontSize: 16 }}>
                                    <i class="fas fa-award"></i> Credentials</p>

                        <p onClick={() => {
                            this.props.history.push('/user/social-proof/testimonials');
                        }} className={`ms-1 ${this.state.filterSearch === 'testimonials' ? 'favorite-item-active' : 'favorite-item'} `} style={{ cursor: 'pointer', fontSize: 16 }}>
                                    <i class="far fa-comment-alt"></i>  Testimonials</p> 

                        <p onClick={() => {
                            this.props.history.push('/user/social-proof/reviews');
                        }} className={`ms-1 ${this.state.filterSearch === 'reviews' ? 'favorite-item-active' : 'favorite-item'} `} style={{ cursor: 'pointer', fontSize: 16 }}>
                                    <i class="far fa-comment-dots"></i>  Reviews</p>

                      
                    </Col>

                    <Col sm="9">
                        {this.state.filterSearch === null && <Awards />}
                        {this.state.filterSearch === 'testimonials' && <Testimonials />}
                        {this.state.filterSearch === 'reviews' && <Reviews slugUrl={this.state.slugUrl} />}
                    </Col>
                </Row>
                
            </div>     
        )
    }

}

export default withRouter(SocialProof)
