/* eslint-disable */
import React from 'react'
import Geosuggest from '@ubilabs/react-geosuggest';
import {
    Button,
    Collapse,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledTooltip,
} from 'reactstrap';
import _ from 'lodash';
import queryString from 'query-string'
import {connect} from 'react-redux';
import api from '../../services/api';
import PriceFilter from "./SearchFilters/PriceFilter";
import {compose} from "redux";
import IndustryFilter from "./SearchFilters/IndustryFilter";
import {withRouter} from "../../containers/withRouter/withRouter";


const FilterAmount = ({filtersCount, handleChangeClear}) => !!filtersCount ?
    <button onClick={(e) => e.preventDefault()}
            className={`btn btn-sm  btn-text-xsm btn-primary-blue ms-1`}
            style={{padding: '1.5px 11px'}}>
                                    <span
                                        className="me-2">{filtersCount}</span>
        <svg onClick={handleChangeClear}
             className="icon" viewBox="0 0 14 13" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                  fill="currentColor"/>
        </svg>
    </button> : null;

class Console extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                searchbox: '',
                excluded: '',
            },
            errors: {},
            message: '',
            gridview: '12',
            categoryOption: '',
            categorylist: [],
            dateaddedOption: '',
            shortbyOption: '',
            location: '',
            Lat: '',
            Lng: '',
            Searchcollapse: true,
            AdvanceSearchToggle: 'none',
            minpricerangeselected: '',
            maxpricerangeselected: '',
            mincashflowselected: '',
            maxcashflowselected: '',
            minrevenueselected: '',
            maxrevenueselected: '',
            minebitdaselected: '',
            maxebitdaselected: '',
            brokername: '',
            brokerprofile_pic: '',
            brokerslug_url: '',
            brokerfirm_name: '',
            brokerptype: '',
            brokerarea_served: '',
            brokerreviews: '',
            brokerratings: '',
            brokertranscount: '',
            brokerlistcount: '',
            orderby: 'listing_date',
            SearchResultList: [],
            franchiseRecordListing: [],
            totalrecords: '',
            activePage: 1,
            staticNumberOfRow: 25,
            showingData: '',
            showSavedSearch: false,
            savedSearch: {label: ''},
            modal: false,
            savedSearchName: '',
            guideModalOpen: false,
            offMarket: 'yes',

            premiumModal: false,
            dropdownRevenueMultiple: false,
            dropdownCashflowMultiple: false,
            dropdownEBITDAMultiple: false,

            brokerIndustry: [],
            savedSearchList: [],
            savedSearchProjects: [],
            locationType: '',

            city: '',
            state: '',
            country: '',

            incKeywords: '',
            excKeywords: '',
            locations: [],
            keywordsToggle: false,

            dropdownminpricerange: false,
            dropdownmaxpricerange: false,
            dropdownmincashflow: false,
            dropdownmaxcashflow: false,
            dropdownminrevenue: false,
            dropdownmaxrevenue: false,

            dropdownminrevenuemultiple: false,
            dropdownmaxrevenuemultiple: false,
            dropdownminebitdamultiple: false,
            dropdownmaxebitdamultiple: false,
            dropdownmincashflowmultiple: false,
            dropdownmaxcashflowmultiple: false,


        };
        this.SubmitSearchResult = this.SubmitSearchResult.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
    }

    fetchSavedSearch() {

        if (!localStorage.getItem('token')) {
            return;
        }

        return api.get('user/saved_search', null, true)
            .then(res => {
                this.setState({
                    savedSearchProjects: res.savedSearchProjects.map(p => {
                        return {
                            label: p.name,
                            value: p.id
                        }
                    }),
                    savedSearchList: res.savedSearchAll.map(p => {
                        return {
                            label: p.name,
                            value: p,
                            id: p.id
                        }
                    }),
                    savedListings: res.savedListings.map(l => l.transaction_id)
                });

            });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({ savedSearchList: nextProps.savedSearchList });
    }

    componentDidMount() {

        fetch(process.env.REACT_APP_API_ROOT + 'industry/legacy', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => res.json())
            .then(
                (res) => {
                    let categorylistarray = [];
                    let temp = {};
                    res.map((categories, i) => {
                        temp = {
                            "value": categories.value,
                            "label": categories.label,
                            "children": categories.children,
                        };
                        categorylistarray.push(temp);
                    });
                    if (localStorage.getItem('token')) {
                        this.fetchSavedSearch()
                            .then(() => {
                                this.setState({categorylist: categorylistarray}, () => {
                                    this.parseUrlToSearch();
                                });
                            });
                    } else {
                        this.setState({categorylist: categorylistarray}, () => {
                            this.parseUrlToSearch();
                        });
                    }


                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        //error
                    });
                }
            );
        this.gettopbrockerlist();
        window.onpopstate = () => {
            this.parseUrlToSearch(true);
        }

        this.fetchPremium();


    }

    fetchPremium() {

        if (!localStorage.getItem('token')) {
            return;
        }

        return fetch(process.env.REACT_APP_API_ROOT + 'user/header', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(res => res.json()).then(res => {
            this.setState({
                premium: res.premium,
            });
        });
    }

    componentWillUnmount() {
        window.onpopstate = null;
    }

    parseUrlToSearch = (isFromPopstate = false) => {
        const queryParams = queryString.parse(window.location.search);

        const allIndustries = [];
        this.state.categorylist.forEach(ind => {
            allIndustries.push({...ind, parent_id: 0});
            if (ind.children && ind.children.length) {
                ind.children.forEach(ci => allIndustries.push({...ci, parent_id: ind.value}));
            }
        });
        let categoryOption = queryParams.industry_id
            ? allIndustries.filter(item => {
                const query = queryParams.industry_id.split(',');
                return query.includes(item.value.toString())
            })
            : [];

        categoryOption.filter(co => co.parent_id === 0).forEach(parent => {
            categoryOption = categoryOption.filter(co => co.parent_id !== parent.value)
        });

        const locations = queryParams.locations ? JSON.parse(queryParams.locations) : [];

        if (queryParams.locationType && queryParams.location) {
            locations.push({
                location: queryParams.location,
                city: queryParams.city,
                state: queryParams.state,
                country: queryParams.country,
                locationType: queryParams.locationType,
            });
        }

        const dateOptions = {
            all: {value: `all`, label: `All`},
            lastweek: {value: `lastweek`, label: `Within the last week`},
            lastmonth: {value: `lastmonth`, label: `Within the last month`},
        };

        this.setState({
            location: queryParams.location,
            city: queryParams.city,
            state: queryParams.state,
            country: queryParams.country,
            locations: locations,
            locationType: queryParams.locationType,
            categoryOption,
            brokerIndustry: categoryOption,
            activePage: queryParams.page,
            Lat: queryParams.from_latitude,
            Lng: queryParams.from_longitude,
            dateaddedOption: dateOptions[queryParams.date_added] || '',
            offMarket: queryParams.offMarket ? queryParams.offMarket : 'yes',
            offMarketBlock: !!(queryParams.offMarket && queryParams.offMarket === 'yes'),
            fields: {
                minpricerange: queryParams.price_min ? queryParams.price_min.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                maxpricerange: queryParams.price_max ? queryParams.price_max.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                mincashflow: queryParams.cashflow_min ? queryParams.cashflow_min.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                maxcashflow: queryParams.cashflow_max ? queryParams.cashflow_max.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                minrevenue: queryParams.revenue_min ? queryParams.revenue_min.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                maxrevenue: queryParams.revenue_max ? queryParams.revenue_max.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                minebitda: queryParams.ebitda_min ? queryParams.ebitda_min.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                maxebitda: queryParams.ebitda_max ? queryParams.ebitda_max.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',

                minebitda_multiple: queryParams.minebitda_multiple,
                maxebitda_multiple: queryParams.maxebitda_multiple,
                minrevenue_multiple: queryParams.minrevenue_multiple,
                maxrevenue_multiple: queryParams.maxrevenue_multiple,
                mincashflow_multiple: queryParams.mincashflow_multiple,
                maxcashflow_multiple: queryParams.maxcashflow_multiple,

                searchbox: queryParams.name || '',
                excluded: queryParams.excluded || '',
                radius: queryParams.radius,
                asset_sale: queryParams.asset_sale ? true : false,
            }
        }, () => {
            if (queryParams.search_id) {
                const savedSearch = this.state.savedSearchList.find(l => l.id === parseInt(queryParams.search_id))
                if (savedSearch) {
                    const data = JSON.parse(savedSearch.value.data);
                    this.setState({
                        locations: data.locations || [],
                        savedSearch,
                        Lat: data.from_latitude,
                        Lng: data.from_longitude,
                        fields: data.fields,
                        location: data.location,
                        categoryOption: data.categoryOption,
                        brokerIndustry: data.categoryOption,
                    }, () => {
                        // this.getSearchResultlist({
                        //     orderby: this.state.orderby,
                        //     page: this.state.premium ? this.state.activePage : 1,
                        // }, isFromPopstate);
                    });
                }

            } else {
                if (queryParams.selected_search) {
                    const savedSearch = this.state.savedSearchList.find(l => l.id === parseInt(queryParams.selected_search));
                    this.setState({savedSearch});
                }
                // this.getSearchResultlist({
                //     orderby: this.state.orderby,
                //     page: this.state.premium ? this.state.activePage : 1,
                // }, isFromPopstate);
            }
        });


    };


    gettopbrockerlist = () => {
        fetch(process.env.REACT_APP_API_ROOT + 'profile/featured_profile', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(function (res) {
                if (res.status < 200 || res.status >= 300) {
                    //console.log("Error 401");
                    //console.log(res);
                    //return res.json().then(err => {throw err;});
                    throw res;
                    //throw Error(.statusText);
                } else {
                    return res.json()
                }

            })
            .then((res) => {
                //console.log(res);
                if (res.length > 0) {
                    let firstname = '';
                    let fullname = res[0].name.match(/(\w+)(?:\s[^\s]+)?\s(\w+).*/);
                    if (fullname !== null) {
                        firstname = fullname[1] + " " + fullname[2].charAt(0) + ".";
                    } else {
                        firstname = res[0].name;
                    }
                    this.setState({
                        brokername: firstname,
                        brokerprofile_pic: res[0].profile_pic,
                        brokerslug_url: res[0].slug_url,
                        brokerfirm_name: res[0].firm_name,
                        brokerptype: res[0].ptype,
                        brokerarea_served: res[0].area_served,
                        brokerreviews: res[0].reviews,
                        brokerratings: res[0].ratings,
                        brokertranscount: res[0].transcount,
                        brokerlistcount: res[0].listcount,
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    //error
                });
            })
    }

    handleSearchResultValidation() {
        // let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        this.setState({errors: errors});
        return formIsValid;
    }

    handleGeosuggestChange = (location) => {
        if (location !== undefined) {
            let addressComponent = location.gmaps.address_components;
            let locationType, city, state, country = '';
            switch (addressComponent[0].types[0]) {
                case 'locality':
                    locationType = 'city';
                    break;
                case 'administrative_area_level_1':
                    locationType = 'state';
                    break;
                case 'country':
                    locationType = 'country';
                    break;
                default:
                    locationType = 'point';
            }

            const duplicate = this.state.locations.find(l => l.location === location.label);

            if (duplicate) {
                this.refs.location.update('');
                return;
            }

            for (var j = 0; j < location.gmaps.address_components.length; j++) {
                if (addressComponent[j].types[0] === 'locality') {
                    this.setState({city: addressComponent[j].long_name});
                    city = addressComponent[j].long_name;
                }
                if (addressComponent[j].types[0] === 'administrative_area_level_1') {
                    this.setState({state: addressComponent[j].short_name});
                    state = addressComponent[j].short_name;
                }
                if (addressComponent[j].types[0] === 'country') {
                    this.setState({country: addressComponent[j].short_name});
                    country = addressComponent[j].short_name;
                }
            }
            this.state.locations.push({
                city,
                state,
                country,
                locationType: locationType,
                Lat: location.location.lat,
                Lng: location.location.lng,
                location: location.label,
            })

            this.refs.location.update('');
            this.setState({Lat: location.location.lat});
            this.setState({Lng: location.location.lng});
            this.setState({location: location.label, locationType});
            this.getSearchResultlist({
                orderby: this.state.orderby,
                page: 1
            });
        }
    }
    handlecategoryChange = (categoryOption) => {

        this.setState({categoryOption});
        // selectedOption can be null when the `x` (close) button is clicked
        if (categoryOption) {
            // console.log(`Selected: ${shortbyOption.label}`);
        }
    }
    autoSearch = () => {
        this.setState({showSavedSearch: false, savedSearch: {label: ''}});
        this.props.savedSearch.clearSavedSearch( () => {
            if (this.props.isMobileScreen === false) {
                this.getSearchResultlist({
                    orderby: this.state.orderby,
                    page: 1
                });
            }
        })
    }
    handledateaddedChange = (dateaddedOption) => {
        this.setState({dateaddedOption}, () => {
            this.autoSearch()
        });
    }
    handleChangeGridview = () => {
        this.setState({gridview: "3"});
    }
    handleChangeListview = () => {
        this.setState({gridview: "12"});
    }

    handleChangeSearch(field, e) {
        if (e.target !== '' && e.target !== undefined) {
            let fields = this.state.fields;
            fields[field] = e.target.value;

            if (e.target.name === 'minpricerange') {
                let minpricerange = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxpricerange') {
                let maxpricerange = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'mincashflow') {
                let mincashflow = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = mincashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxcashflow') {
                let maxcashflow = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxcashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'minrevenue') {
                let minrevenue = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxrevenue') {
                let maxrevenue = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'minebitda') {
                let minebitda = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minebitda.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxebitda') {
                let maxebitda = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxebitda.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'asset_sale') {
                fields[field] = e.target.checked;
            }
            if (e.target.name === 'minebitda_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');
            }
            if (e.target.name === 'maxebitda_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');
            }
            if (e.target.name === 'minrevenue_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');
            }
            if (e.target.name === 'maxrevenue_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');
            }
            if (e.target.name === 'mincashflow_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');
            }
            if (e.target.name === 'maxcashflow_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');
            }
            this.setState({fields});
            this.autoSearch()
        }
    }

    handleChangeMinPriceRange = (e) => {
        let fields = this.state.fields;
        fields["minpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, minpricerangeselected: e.target.value});
        this.autoSearch()
    }
    handleChangeMaxPriceRange = (e) => {
        let fields = this.state.fields;
        fields["maxpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, maxpricerangeselected: e.target.value});
        this.autoSearch()
    }
    handleChangeMincashflow = (e) => {
        let fields = this.state.fields;
        fields["mincashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, mincashflowselected: e.target.value});
        this.autoSearch()
    }
    handleChangeMaxcashflow = (e) => {
        let fields = this.state.fields;
        fields["maxcashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, maxcashflowselected: e.target.value});
        this.autoSearch()
    }
    handleChangeMinRevenue = (e) => {
        let fields = this.state.fields;
        fields["minrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, minrevenueselected: e.target.value});
        this.autoSearch()
    }
    handleChangeMaxRevenue = (e) => {
        let fields = this.state.fields;
        fields["maxrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, maxrevenueselected: e.target.value});
        this.autoSearch()
    }
    handleChangeMinEbitda = (e) => {
        let fields = this.state.fields;
        fields["minebitda"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, minebitdaselected: e.target.value});
        this.autoSearch()
    }
    //
    handleChangeMinEbitdaMultiple = (e) => {
        let fields = this.state.fields;
        fields["minebitda_multiple"] = e.target.value;
        this.setState({fields, minebitdamultipleselected: e.target.value});
        this.autoSearch()
    }
    handleChangeMaxEbitdaMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxebitda_multiple"] = e.target.value;
        this.setState({fields, maxebitdamultipleselected: e.target.value});
        this.autoSearch()
    }

    handleChangeMinRevenueMultiple = (e) => {
        let fields = this.state.fields;
        fields["minrevenue_multiple"] = e.target.value;
        this.setState({fields, minrevenuemultipleselected: e.target.value});
        this.autoSearch()
    }
    handleChangeMaxRevenueMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxrevenue_multiple"] = e.target.value;
        this.setState({fields, maxrevenuemultipleselected: e.target.value});
        this.autoSearch()
    }

    handleChangeMinCashflowMultiple = (e) => {
        let fields = this.state.fields;
        fields["mincashflow_multiple"] = e.target.value;
        this.setState({fields, mincashflowmultipleselected: e.target.value});
        this.autoSearch()
    }
    handleChangeMaxCashflowMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxcashflow_multiple"] = e.target.value;
        this.setState({fields, maxcashflowmultipleselected: e.target.value});
        this.autoSearch()
    }
    //

    handleChangeMaxEbitda = (e) => {
        let fields = this.state.fields;
        fields["maxebitda"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, maxebitdaselected: e.target.value});
        this.autoSearch()
    }
    SubmitSearchResult = (e) => {
        e.preventDefault();
        if (this.handleSearchResultValidation()) {
            //let fields = this.state.fields;
            this.getSearchResultlist({
                orderby: this.state.orderby,
                page: 1
            });
            this.setState({
                isOpen: false
            })

        } else {
            //console.log("Form has errors.")
        }
    }
    handleshortbyChange = (shortbyOption) => {
        this.setState({shortbyOption});
        // selectedOption can be null when the `x` (close) button is clicked
        if (shortbyOption) {
            this.setState({orderby: shortbyOption.value});
            this.getSearchResultlist({
                orderby: shortbyOption.value,
                page: 1
            });
            // console.log(`Selected: ${shortbyOption.label}`);
        }
    }
    handlePageChange = (pageNumber) => {
        this.setState({activePage: pageNumber});
        this.getSearchResultlist({
            orderby: this.state.orderby,
            page: pageNumber,
        });
    }
    handleChangeClear = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            city: '',
            state: '',
            country: '',
            brokerIndustry: [],

            incKeywords: '',
            excKeywords: '',
            locations: [],
            fields: {
                searchbox: '',
                excluded: '',
            },
        }, () => {
            this.props.history.push('/advanced-search?page=1')
            this.props.fetchSearch(false, '?')
        })
    }

    filterFields = ['locations', 'brokerIndustry']

    getFiltersCount = () => {
        let res = 0;

        for (let field of this.filterFields) {
            if (Array.isArray(this.state[field]) ? this.state[field].length : this.state[field]) {
                res++
            }
        }
        for (let field of Object.keys(this.state.fields)) {
            if (this.state.fields[field]?.length || this.state.fields[field]) {
                res++
            }
        }
        return res
    }
    getSearchResultlist = ({orderby, page}, isFromPopstate = true) => {


        this.setState({activePage: page});
        let location, latitude, longitude, minpricerangeselected, maxpricerangeselected, mincashflowselected,
            maxcashflowselected, minrevenueselected, maxrevenueselected, dateaddedOption, minebitdaselected,
            maxebitdaselected = '';
        let fields = this.state.fields;
        if (this.state.location !== '' && this.state.location !== undefined) {
            location = this.state.location;
            latitude = this.state.Lat;
            longitude = this.state.Lng;
        } else {
            if (localStorage.getItem("buyloaction") !== null && localStorage.getItem("buyloaction") !== "undefined") {
                location = localStorage.getItem("buyloaction");
                latitude = localStorage.getItem("buylatitude");
                longitude = localStorage.getItem("buylongitude");
            } else {
                location = "";
                latitude = ""
                longitude = "";
            }
        }

        if (this.state.dateaddedOption.value !== undefined) {
            dateaddedOption = this.state.dateaddedOption.value;
        } else {
            dateaddedOption = this.state.dateaddedOption;
        }
        if (fields["minpricerange"] !== undefined) {
            if (this.state.minpricerangeselected === "Any Min") {
                minpricerangeselected = '';
            } else {
                minpricerangeselected = fields["minpricerange"].replace(/,/g, "");
            }
        }
        if (fields["maxpricerange"] !== undefined) {
            if (this.state.maxpricerangeselected === "Any Max") {
                maxpricerangeselected = '';
            } else {
                maxpricerangeselected = fields["maxpricerange"].replace(/,/g, "");
            }
        }
        if (fields["mincashflow"] !== undefined) {
            if (this.state.mincashflowselected === "Any Min") {
                mincashflowselected = '';
            } else {
                mincashflowselected = fields["mincashflow"].replace(/,/g, "");
            }
        }
        if (fields["maxcashflow"] !== undefined) {
            if (this.state.maxcashflowselected === "Any Max") {
                maxcashflowselected = '';
            } else {
                maxcashflowselected = fields["maxcashflow"].replace(/,/g, "");
            }
        }
        if (fields["minrevenue"] !== undefined) {
            if (this.state.minrevenueselected === "Any Min") {
                minrevenueselected = '';
            } else {
                minrevenueselected = fields["minrevenue"].replace(/,/g, "");
            }
        }
        if (fields["maxrevenue"] !== undefined) {
            if (this.state.maxrevenueselected === "Any Max") {
                maxrevenueselected = '';
            } else {
                maxrevenueselected = fields["maxrevenue"].replace(/,/g, "");
            }
        }
        if (fields["minebitda"] !== undefined) {
            if (this.state.minebitdaselected === "Any Min") {
                minebitdaselected = '';
            } else {
                minebitdaselected = fields["minebitda"].replace(/,/g, "");
            }
        }
        if (fields["maxebitda"] !== undefined) {
            if (this.state.maxebitdaselected === "Any Max") {
                maxebitdaselected = '';
            } else {
                maxebitdaselected = fields["maxebitda"].replace(/,/g, "");
            }
        }


        const categoryOption = this.state.brokerIndustry.map(bi => bi.children && bi.children.length ? [bi.value].concat(bi.children.map(cc => cc.value)) : bi.value);

        const dataForQuery = {
            // "location": location,
            "industry_id": categoryOption || [],
            "from_latitude": latitude,
            "from_longitude": longitude,
            "name": fields["searchbox"],
            "excluded": fields["excluded"],
            "price_min": minpricerangeselected,
            "price_max": maxpricerangeselected,
            "cashflow_min": mincashflowselected,
            "cashflow_max": maxcashflowselected,
            "revenue_min": minrevenueselected,
            "revenue_max": maxrevenueselected,
            "ebitda_min": minebitdaselected,
            "ebitda_max": maxebitdaselected,
            "date_added": dateaddedOption === "" ? null : dateaddedOption,
            "orderby": orderby,
            "page": page,
            "radius": fields.radius,
            "rows": this.state.staticNumberOfRow,
            // "city": this.state.city,
            // "state": this.state.state,
            // "country": this.state.country,
            // "locationType": this.state.locationType,
            "locations": this.state.locations ? JSON.stringify(this.state.locations) : null,
            offMarket: this.state.offMarket,

        };

        if (this.state.savedSearch.id) {
            dataForQuery.selected_search = this.state.savedSearch.id;
        }

        if (fields['asset_sale']) {
            dataForQuery.asset_sale = '1';
        }

        if (fields['minebitda_multiple'] && fields['minebitda_multiple'] !== 'Any Max' && fields['minebitda_multiple'] !== 'Any Min') {
            dataForQuery.minebitda_multiple = fields['minebitda_multiple'];
        }

        if (fields['maxebitda_multiple'] && fields['maxebitda_multiple'] !== 'Any Max' && fields['maxebitda_multiple'] !== 'Any Min') {
            dataForQuery.maxebitda_multiple = fields['maxebitda_multiple'];
        }

        if (fields['minrevenue_multiple'] && fields['minrevenue_multiple'] !== 'Any Max' && fields['minrevenue_multiple'] !== 'Any Min') {
            dataForQuery.minrevenue_multiple = fields['minrevenue_multiple'];
        }

        if (fields['maxrevenue_multiple'] && fields['maxrevenue_multiple'] !== 'Any Max' && fields['maxrevenue_multiple'] !== 'Any Min') {
            dataForQuery.maxrevenue_multiple = fields['maxrevenue_multiple'];
        }

        if (fields['mincashflow_multiple'] && fields['mincashflow_multiple'] !== 'Any Max' && fields['mincashflow_multiple'] !== 'Any Min') {
            dataForQuery.mincashflow_multiple = fields['mincashflow_multiple'];
        }

        if (fields['maxcashflow_multiple'] && fields['maxcashflow_multiple'] !== 'Any Max' && fields['maxcashflow_multiple'] !== 'Any Min') {
            dataForQuery.maxcashflow_multiple = fields['maxcashflow_multiple'];
        }

        if (!(this.state.savedSearch && this.state.savedSearch.value)) {
            const recentSearches = localStorage.getItem('recent_searches') ? JSON.parse(localStorage.getItem('recent_searches')) : [];
            recentSearches.unshift(JSON.stringify({
                ...dataForQuery
            }));
            localStorage.setItem('recent_searches', JSON.stringify(recentSearches.slice(0, 3)));
        }
        const searchParams = this.makeSearchQueryString(dataForQuery)
        this.props.history.push('/advanced-search/' + searchParams)
        this.props.fetchSearch(isFromPopstate, searchParams)
    };

    makeSearchQueryString = searchData => {
        delete searchData.rows;
        // in future if this field will be added just remove line below
        delete searchData.orderby;

        let output = '';
        for (let property in searchData) {
            if (searchData.hasOwnProperty(property) && searchData[property]) {
                if (!output) {
                    output = '?'
                } else {
                    output += '&'
                }
                output += property + '=' + searchData[property];
            }
        }
        return output;
    };


    makeSearch = searchData => {
        fetch(process.env.REACT_APP_API_ROOT + 'transaction/search', {
            method: 'post',
            body: searchData,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(function (res) {
                if (res.status < 200 || res.status >= 300) {
                    //console.log("Error 401");
                    //console.log(res);
                    //return res.json().then(err => {throw err;});
                    throw res;
                    //throw Error(.statusText);
                } else {
                    return res.json()
                }
            })
            .then((res) => {
                // document.getElementById('loader').setAttribute("style", "display: none;");
                if (res.status === 200) {
                    this.setState({SearchResultList: res.list, totalrecords: res.total, message: ''})
                } else {
                    this.setState({SearchResultList: [], message: res.message, totalrecords: 0})
                }
                localStorage.setItem("buyloaction", "");
                localStorage.setItem("buylatitude", "")
                localStorage.setItem("buylongitude", "");
                localStorage.setItem("buycategory", "");

            })
            .catch((err) => {
                this.setState({
                    //error
                });
            })
    };


    starRatings = (stars) => {
        let ratings = [];
        let filledstars = stars;
        let blankstars = 5 - stars;
        for (let k = 0; k < filledstars; k++) {
            ratings.push(<i className="sprite i-starfill-md" key={"filledstars" + k}></i>);
        }
        for (let k = 0; k < blankstars; k++) {
            ratings.push(<i className="sprite i-star-md" key={"blankstars" + k}></i>);
        }
        return ratings;
    }

    truncString(str, max, add) {
        add = add || '...';
        return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
    };

    handleSaveSearh = () => {
        if (!this.state.savedSearchName.trim()) {
            return;
        }

        fetch(process.env.REACT_APP_API_ROOT + 'user/saved_search', {
            method: 'post',
            body: JSON.stringify({
                name: this.state.savedSearchName, data: JSON.stringify({
                    fields: this.state.fields,
                    location: this.state.location,
                    categoryOption: this.state.categoryOption,
                    from_latitude: this.state.Lat,
                    from_longitude: this.state.Lng,
                })
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(() => {
            this.setState({showSavedSearch: false});
            this.fetchSavedSearch();
        })
    }

    handleDeleteSavedSearch = () => {
        fetch(process.env.REACT_APP_API_ROOT + 'user/saved_search', {
            method: 'delete',
            body: JSON.stringify({id: this.state.savedSearch.value.id}),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(() => {
            this.setState({showSavedSearch: false, savedSearch: {label: ''}});
            this.fetchSavedSearch();
        });
    }

    isToUpgrade() {
        return !this.state.premium && localStorage.getItem('token');
    }

    onAddBrokerIndustry = items => {
        this.setState({
            brokerIndustry: items
        }, () => {
            this.handleCategoryChange();
        });
    }

    checkedIndustry = item => {
        const exist = _.find(this.state.brokerIndustry, b => b.value === item.value);

        if (exist) {
            return (
                <i className="fa-solid fa-check"></i>
            )
        }
    }
    onRemoveBrokerIndustry = item => {
        const brokerIndustry = _.reject(this.state.brokerIndustry, bi => bi.value === item.value);
        this.setState({
            brokerIndustry
        }, () => {
            this.handleCategoryChange();
        });
    }

    onUpdateBrokerIndustry = (items) => {
        this.setState({
            brokerIndustry: items
        }, () => {
            this.handleCategoryChange();
        });
    }

    onRemoveKeyword = item => {
        const {fields} = this.state;
        const incKeywords = _.reject(fields.searchbox.split(','), k => k === item);
        this.state.fields.searchbox = incKeywords.join(',');
        this.setState({fields});
        this.autoSearch()
    }

    onRemoveExcKeyword = item => {
        const {fields} = this.state;
        const excKeywords = _.reject(fields.excluded.split(','), k => k === item);
        this.state.fields.excluded = excKeywords.join(',');
        this.setState({fields});
        this.autoSearch()
    }

    onRemoveLocation = loc => {
        const {locations} = this.state;
        const newLocations = _.reject(locations, l => l.location === loc.location);
        this.setState({locations: newLocations});
        this.autoSearch()
    }


    handleCategoryChange = () => {
        this.autoSearch()
    }

    cleanBrokerIndustry = () => {
        this.setState({brokerIndustry: []});
        this.autoSearch()
    }

    clearMetrics = (a, b) => {
        let fields = this.state.fields;
        fields[a] = '';
        fields[b] = '';
        this.setState({fields});
        this.autoSearch()
    }

    filterNiceValue = (value1, value2, removeSign) => {
        const reg = /\B(?=(\d{3})+(?!\d))/g;
        if (value1 && value2) {
            return `${value1 !== 'Any Min' ? `${removeSign ? '' : '$'}` + value1.replace(reg, ",") : value1} - ${value2 !== 'Any Max' ? `${removeSign ? '' : '$'}` + value2.replace(reg, ",") : value2}`;
        } else {
            if (value1 && !value2) {
                return `${value1 !== 'Any Min' ? `Min ${removeSign ? '' : '$'}` + value1.replace(reg, ",") : value1}`;
            } else if (!value1 && value2) {
                return `${value2 !== 'Any Max' ? `Max ${removeSign ? '' : '$'}` + value2.replace(reg, ",") : value2}`;
            } else {
                return '';
            }
        }
    }
    onCollapseClick = e => {
        if (!this.state.premium) {
            e.preventDefault()
            e.stopPropagation()
            this.setState({ premiumModal: true });
        }
    }

    render() {
        const {
            savedSearch,
            hasSearch,
            saveThisSearch,
            handleDeleteSavedSearch,
        } = this.props.savedSearch
        const priceValues = {
            price: {
                min: +this.state.fields.minpricerange?.replace(/[^0-9]/g, ''),
                max: +this.state.fields.maxpricerange?.replace(/[^0-9]/g, ''),
            },
            revenue: {
                min: +this.state.fields.minrevenue?.replace(/[^0-9]/g, ''),
                max: +this.state.fields.maxrevenue?.replace(/[^0-9]/g, ''),
            },
            cashflow: {
                min: +this.state.fields.mincashflow?.replace(/[^0-9]/g, ''),
                max: +this.state.fields.maxcashflow?.replace(/[^0-9]/g, ''),
            },
            ebidta: {
                min: +this.state.fields.minebitda?.replace(/[^0-9]/g, ''),
                max: +this.state.fields.maxebitda?.replace(/[^0-9]/g, ''),
            },
            revenue_multiple: {
                min: +this.state.fields.minrevenue_multiple,
                max: +this.state.fields.maxrevenue_multiple,
            },
            ebitda_multiple: {
                min: +this.state.fields.minebitda_multiple,
                max: +this.state.fields.maxebitda_multiple,
            },
            cashflow_multiple: {
                min: +this.state.fields.mincashflow_multiple,
                max: +this.state.fields.maxcashflow_multiple,
            },
        }
        const isPriceValueAvailable = (priceType) => (value, isMin) => {
            if (isMin) {
                return !priceValues[priceType].max || priceValues[priceType].max > value
            }
            return !priceValues[priceType].min || priceValues[priceType].min < value
        }
        Object.keys(priceValues).forEach(priceType => {
            priceValues[priceType].isValid = !priceValues[priceType].min ||
                !priceValues[priceType].max ||
                priceValues[priceType].min < priceValues[priceType].max
        })
        const isButtonDisabled = Object.keys(priceValues).reduce((prev, priceType) => prev || !priceValues[priceType].isValid, false)
        return (<div className={`ac-sidebar-content ${this.state.isOpen && 'open'}`}>
                <Button
                    className="toggle-filter-button"
                    type="button"
                    onClick={() => this.setState(prevState => ({ isOpen: !prevState.isOpen }))}
                >
                    {this.state.isOpen ? 'Close' : 'Open'} filter
                    <FilterAmount filtersCount={this.getFiltersCount()} handleChangeClear={this.handleChangeClear}/>

                </Button>
            <div className={`sticky-advanced-search`}>
                <section
                    style={{boxShadow: '1px 1px 1px #e1e1e1'}}
                    className="content-section-padding homepage sidebar-card-design advanced-search-console"
                >
                    <div className="row align-items-center row-gutter-10">
                        <div className="col-sm-7 mb-2">
                            <strong>Filter</strong>
                            <FilterAmount filtersCount={this.getFiltersCount()} handleChangeClear={this.handleChangeClear}/>
                        </div>
                        <div className="col-auto ms-auto mb-2">
                            <FormGroup className="d-sm-block mb-0">
                                {(!savedSearch && hasSearch) &&
                                    <>
                                        <button onClick={saveThisSearch} id="categoryTooltip"
                                                className="btn btn-sm btn-outline-primary-green">Save search
                                        </button>
                                        <UncontrolledTooltip
                                            placement="bottom" target="categoryTooltip" autohide={true}
                                            innerClassName="areas-tooltip">
                                            We'll shoot you an update as soon as new opportunities that match this exact
                                            criteria become available.
                                        </UncontrolledTooltip>
                                    </>}

                                {savedSearch && <button onClick={handleDeleteSavedSearch}
                                                        className="btn btn-sm btn-outline-primary-red">Delete
                                    "{this.truncString(savedSearch.value.name, 15)}" search</button>}

                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-md-block scrollable-filter">
                            <div className="row align-items-center row-gutter-10 mt-2">
                                <div className="col-md-12 mb-2">
                                    <div className="dropdown">
                                        <button style={{borderRadius: '0px', justifyContent: 'flex-start'}}
                                                className={`btn btn-filter w-100 text-nowrap ${this.state.savedSearch.label ? 'active' : ''}`}
                                                type="button" id="dropdownMenuSavedSearch" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                        <span
                                            className="text-overflow-ellipsis me-1">{this.state.savedSearch.label || 'Saved Search'}</span>
                                            <span className="text-xsm">
                                <svg className="icon" viewBox="0 0 20 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z"
                                          fill="currentColor"></path>
                                </svg>
                            </span>
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuSavedSearch">
                                            {this.state.savedSearchList?.map(ss =>
                                                <a onClick={() => {
                                                    const data = JSON.parse(ss.value.data);

                                                    this.setState({
                                                        savedSearch: ss,
                                                        Lat: data.from_latitude,
                                                        Lng: data.from_longitude,
                                                        fields: data.fields,
                                                        location: data.location,
                                                        categoryOption: data.categoryOption,
                                                        brokerIndustry: data.categoryOption,
                                                        city: data.city,
                                                        state: data.state,
                                                        country: data.country,
                                                        locationType: data.locationType,
                                                        locations: data.locations || [],
                                                    }, () => {
                                                        this.getSearchResultlist({
                                                            orderby: this.state.orderby,
                                                            page: 1
                                                        });
                                                    });
                                                }} className="dropdown-item" href="javascript:void(0)">{ss.label}</a>
                                            )}
                                        </div>
                                    </div>
                                    {this.state.savedSearch.label && <span className="text-xsm cursor_pointer"
                                                                           onClick={() => this.setState({savedSearch: {label: ''}})}>
                        <svg style={{position: 'absolute', top: 11, right: 17, color: '#6D7277'}} className="icon"
                             viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                  fill="currentColor"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                  fill="currentColor"></path>
                        </svg>
                    </span>}

                                </div>
                            </div>

                            <div className="row align-items-center row-gutter-10">
                                <div className="col-md-12 mb-2">
                                    <div className="advanced-filter-section">
                                        <button style={{borderRadius: '0px'}}
                                                className={`btn btn-filter w-100 text-nowrap w-100 justify-content-between`}
                                                type="button" data-toggle="collapse"
                                                data-target="#dropdownIncludeKeywords"
                                                aria-expanded={`false`} aria-controls="collapse-8">
                                            <span>Keywords</span>
                                            <span className="btn-icon">
                                        <span className="d-when-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span className="d-when-not-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                                        </button>

                                        <div style={{padding: 15}} className={`collapse`} id="dropdownIncludeKeywords">
                                            <p className="mt-1 mb-2" style={{fontSize: 13}}>Included Keywords: </p>
                                            <input
                                                type="text"
                                                maxLength={100}
                                                placeholder={`Try: SaaS or "recurring revenue"`}
                                                className="form-control form-control--filter mb-2"
                                                onBlur={() => {
                                                    const {fields} = this.state;
                                                    if (fields.searchbox) {
                                                        const k = fields.searchbox.split(',');
                                                        if (this.state.incKeywords) {
                                                            k.push(this.state.incKeywords);
                                                        }

                                                        fields.searchbox = k.join(',');
                                                    } else {
                                                        fields.searchbox = this.state.incKeywords;
                                                    }

                                                    this.setState({fields, incKeywords: ''});
                                                    this.getSearchResultlist({
                                                        orderby: this.state.orderby,
                                                        page: 1
                                                    });
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        const {fields} = this.state;
                                                        if (fields.searchbox) {
                                                            const k = fields.searchbox.split(',');
                                                            if (this.state.incKeywords) {
                                                                k.push(this.state.incKeywords);
                                                            }

                                                            fields.searchbox = k.join(',');
                                                        } else {
                                                            fields.searchbox = this.state.incKeywords;
                                                        }

                                                        this.setState({fields, incKeywords: ''});
                                                        this.getSearchResultlist({
                                                            orderby: this.state.orderby,
                                                            page: 1
                                                        });
                                                    }
                                                }}
                                                value={this.state.incKeywords}
                                                onChange={(e) => {
                                                    this.setState({incKeywords: e.target.value});
                                                }}
                                            />
                                            <div className="row align-items-center row-gutter-10">
                                                {this.state.fields.searchbox && this.state.fields.searchbox.split(',').map(bi =>
                                                    <div className="col-auto mb-2">
                                                        <button onClick={(e) => e.preventDefault()}
                                                                className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                                style={{padding: '1.5px 11px', wordBreak: 'break-all'}}>
                                                            <span className="me-2">{bi}</span>
                                                            <svg onClick={() => this.onRemoveKeyword(bi)}
                                                                 className="icon"
                                                                 viewBox="0 0 14 13" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                      fill="currentColor"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                      fill="currentColor"></path>
                                                            </svg>
                                                        </button>
                                                    </div>)}
                                            </div>

                                            <p className="mt-3 mb-2" style={{fontSize: 13}}>Excluded Keywords: </p>
                                            <input
                                                type="text"
                                                placeholder={`Try: "Gas Station"`}
                                                maxLength={100}
                                                className="form-control form-control--filter mb-2"
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        const {fields} = this.state;
                                                        if (fields.excluded) {
                                                            const k = fields.excluded.split(',');
                                                            if (this.state.excKeywords) {
                                                                k.push(this.state.excKeywords);
                                                            }

                                                            fields.excluded = k.join(',');
                                                        } else {
                                                            fields.excluded = this.state.excKeywords;
                                                        }

                                                        this.setState({fields, excKeywords: ''});
                                                        this.getSearchResultlist({
                                                            orderby: this.state.orderby,
                                                            page: 1
                                                        });
                                                    }
                                                }}
                                                value={this.state.excKeywords}
                                                onChange={(e) => {
                                                    this.setState({excKeywords: e.target.value});
                                                }}
                                            />
                                            <div className="row align-items-center row-gutter-10">
                                                {this.state.fields.excluded && this.state.fields.excluded.split(',').map(bi =>
                                                    <div className="col-auto mb-2">
                                                        <button onClick={(e) => e.preventDefault()}
                                                                className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                                style={{padding: '1.5px 11px', wordBreak: 'break-all'}}>
                                                            <span className="me-2">{bi}</span>
                                                            <svg onClick={() => this.onRemoveExcKeyword(bi)}
                                                                 className="icon" viewBox="0 0 14 13" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                      fill="currentColor"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                      fill="currentColor"></path>
                                                            </svg>
                                                        </button>
                                                    </div>)}
                                            </div>

                                        </div>

                                        {(!this.state.keywordsToggle && this.state.fields.searchbox) &&
                                            <div className="row align-items-center row-gutter-10"
                                                 style={{paddingLeft: 15}}>
                                                <div className="col-12 d-flex p-1 pb-1 align-items-center flex-wrap">
                                                    {this.state.fields.searchbox &&
                                                        <p className="mt-1 mb-1" style={{fontSize: 13}}>Included: </p>}
                                                    {this.state.fields.searchbox && this.state.fields.searchbox.split(',').map(bi =>
                                                        <div className="col-auto mb-1" style={{paddingLeft: 0}}>
                                                            <button onClick={(e) => e.preventDefault()}
                                                                    className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                                    style={{
                                                                        padding: '1.5px 11px',
                                                                        wordBreak: 'break-all'
                                                                    }}>
                                                                <span className="me-2">{bi}</span>
                                                                <svg onClick={() => this.onRemoveKeyword(bi)}
                                                                     className="icon"
                                                                     viewBox="0 0 14 13" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                          d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                          fill="currentColor"></path>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                          d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                          fill="currentColor"></path>
                                                                </svg>
                                                            </button>
                                                        </div>)}
                                                </div>
                                            </div>}

                                        {(!this.state.keywordsToggle && this.state.fields.excluded) &&
                                            <div className="row align-items-center row-gutter-10"
                                                 style={{paddingLeft: 15}}>
                                                <div className="col-12 d-flex p-1 pb-1 align-items-center flex-wrap">
                                                    {this.state.fields.excluded &&
                                                        <p className="mt-1 mb-1" style={{fontSize: 13}}>Excluded: </p>}
                                                    {this.state.fields.excluded && this.state.fields.excluded.split(',').map(bi =>
                                                        <div className="col-auto mb-2" style={{paddingLeft: 0}}>
                                                            <button onClick={(e) => e.preventDefault()}
                                                                    className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                                    style={{
                                                                        padding: '1.5px 11px',
                                                                        wordBreak: 'break-all'
                                                                    }}>
                                                                <span className="me-2">{bi}</span>
                                                                <svg onClick={() => this.onRemoveExcKeyword(bi)}
                                                                     className="icon" viewBox="0 0 14 13" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                          d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                          fill="currentColor"></path>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                          d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                          fill="currentColor"></path>
                                                                </svg>
                                                            </button>
                                                        </div>)}
                                                </div>
                                            </div>}

                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center row-gutter-10">
                                {/* <!-- Industry Filter --> */}

                                <div className="col-md-12 mb-2">
                                    <div className="advanced-filter-section">
                                            <button style={{borderRadius: '0px', justifyContent: 'flex-start'}}
                                                    className={`btn btn-filter w-100`} type="button"
                                                    onClick={() => this.setState({industryCollapse: !this.state.industryCollapse})}
                                                    aria-haspopup="true"
                                                    aria-expanded="false">
                                            <span className="me-1">Industry <span
                                                className="text-xsm">({this.state.brokerIndustry.length})
                                            </span>

                                            </span>
                                                <span className="text-xsm">
                                <svg className="icon" viewBox="0 0 20 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z"
                                          fill="currentColor"></path>
                                </svg>
                            </span>

                                            </button>
                                            <Collapse isOpen={this.state.industryCollapse}>
                                                <IndustryFilter
                                                    industriesList={this.state.categorylist}
                                                    selectedIndustries={this.state.brokerIndustry}
                                                    updateIndustries={this.onUpdateBrokerIndustry}
                                                    onRemoveIndustry={this.onRemoveBrokerIndustry}
                                                />
                                            </Collapse>
                                            <div className="dropdown-menu dropdown-menu--minw-260"
                                                 aria-labelledby="dropdownMenuIndustry">
                                                <div className="dropdown-content-padding pb-0">
                                                    <div className="row row-gutter-10">
                                                        {this.state.brokerIndustry.map(bi => <div
                                                            className="col-auto mb-2">
                                                            <button onClick={(e) => e.preventDefault()}
                                                                    className="btn btn-sm btn-primary-blue btn-text-xsm">
                                                                <span className="me-2">{bi.label}</span>
                                                                <svg onClick={() => this.onRemoveBrokerIndustry(bi)}
                                                                     className="icon" viewBox="0 0 14 13" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                          d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                          fill="currentColor"></path>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                          d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                          fill="currentColor"></path>
                                                                </svg>
                                                            </button>
                                                        </div>)}
                                                    </div>
                                                </div>
                                                {this.state.brokerIndustry.length > 0 &&
                                                    <div className="dropdown-divider"></div>}
                                                {this.state.categorylist.map(item => <>
                                                    <a onClick={() => this.onAddBrokerIndustry(item)}
                                                       className={`dropdown-item ${item.children.length > 0 ? 'submenu' : ''}`}
                                                       href="javascript:void(0)">{this.checkedIndustry(item)} {item.label}</a>
                                                    {item.children.length > 0 && <div className="dropdown-menu">
                                                        {item.children.map(c =>
                                                            <a className="dropdown-item"
                                                               onClick={() => this.onAddBrokerIndustry(c)}
                                                               href="javascript:void(0)">{this.checkedIndustry(c)} {c.label}</a>
                                                        )}
                                                    </div>}
                                                </>)}

                                            </div>
                                        {this.state.brokerIndustry.length > 0 &&
                                            <span onClick={() => this.cleanBrokerIndustry()}
                                                  className="text-xsm cursor_pointer">
                            <svg style={{position: 'absolute', top: 11, right: 17, color: '#6D7277'}} className="icon"
                                 viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                      fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                      fill="currentColor"></path>
                            </svg>
                        </span>}
                                        {!this.state.industryCollapse && this.state.brokerIndustry.length > 0 &&
                                            <div className="row align-items-center row-gutter-10 ms-2">
                                                {this.state.brokerIndustry.map(bi => <div className="col-auto mb-2 mt-2"
                                                                                          style={{paddingLeft: 0}}>
                                                    <button onClick={(e) => e.preventDefault()}
                                                            className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                            style={{padding: '1.5px 11px'}}>
                                                        <span className="me-2">{bi.label}</span>
                                                        <svg onClick={() => this.onRemoveBrokerIndustry(bi)}
                                                             className="icon" viewBox="0 0 14 13" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                  d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                  fill="currentColor"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                  d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                  fill="currentColor"></path>
                                                        </svg>
                                                    </button>
                                                </div>)}
                                            </div>}
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Search by location --> */}
                            <div className="row align-items-center row-gutter-10">
                                <div className="col-md-12 mb-2">
                                    <div className="advanced-filter-section">


                                        <div>
                                            <button style={{borderRadius: '0px'}}
                                                    className={`btn btn-filter w-100 text-nowrap w-100 justify-content-between`}
                                                    type="button" data-toggle="collapse" data-target="#dropdownLocation"
                                                    aria-expanded={`false`} aria-controls="collapse-8">
                                                <span>Locations</span>
                                                <span className="btn-icon">
                                        <span className="d-when-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span className="d-when-not-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                                            </button>
                                            <div style={{padding: 15}} className={`collapse`} id="dropdownLocation">


                                                <Geosuggest
                                                    inputClassName={`form-control form-control--filter`}
                                                    id="by-location"
                                                    name="location"
                                                    ref="location"
                                                    placeholder="Search by location"
                                                    onSuggestSelect={this.handleGeosuggestChange}
                                                />

                                            </div>

                                        </div>
                                        {this.state.locations.length > 0 &&
                                            <div className="row align-items-center row-gutter-10 ms-2 mt-2">
                                                {this.state.locations.map(loc => <div className="col-auto mb-2"
                                                                                      style={{paddingLeft: 0}}>
                                                    <button onClick={(e) => e.preventDefault()}
                                                            className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                            style={{padding: '1.5px 11px'}}>
                                                        <span className="me-2">{loc.location}</span>
                                                        <svg onClick={() => this.onRemoveLocation(loc)} className="icon"
                                                             viewBox="0 0 14 13" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                  d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                  fill="currentColor"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                  d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                  fill="currentColor"></path>
                                                        </svg>
                                                    </button>
                                                </div>)}
                                            </div>}
                                    </div>
                                </div>

                            </div>


                            {/* <!-- Price --> */}
                            <PriceFilter
                                title="Price Range"
                                maxValue={this.state.fields["maxpricerange"]}
                                minValue={this.state.fields["minpricerange"]}
                                maxName="maxpricerange"
                                minName="minpricerange"
                                clearMetrics={this.clearMetrics}
                                filterNiceValue={this.filterNiceValue}
                                valueMap={priceValues.price}
                                isOptionAvailable={isPriceValueAvailable('price')}
                                handleChange={this.handleChangeSearch}
                                handleMaxChange={this.handleChangeMaxPriceRange}
                                handleMinChange={this.handleChangeMinPriceRange}
                            />
                            {/* <!-- Annual revenue --> */}
                            <PriceFilter
                                title="Annual revenue"
                                maxValue={this.state.fields["maxrevenue"]}
                                minValue={this.state.fields["minrevenue"]}
                                maxName="maxrevenue"
                                minName="minrevenue"
                                clearMetrics={this.clearMetrics}
                                filterNiceValue={this.filterNiceValue}
                                valueMap={priceValues.revenue}
                                isOptionAvailable={isPriceValueAvailable('revenue')}
                                handleChange={this.handleChangeSearch}
                                handleMaxChange={this.handleChangeMaxRevenue}
                                handleMinChange={this.handleChangeMinRevenue}
                            />


                            {/* <!-- Annual profit --> */}
                            <PriceFilter
                                title="Annual profit"
                                maxValue={this.state.fields["maxcashflow"]}
                                minValue={this.state.fields["mincashflow"]}
                                maxName="maxcashflow"
                                minName="mincashflow"
                                clearMetrics={this.clearMetrics}
                                filterNiceValue={this.filterNiceValue}
                                valueMap={priceValues.cashflow}
                                isOptionAvailable={isPriceValueAvailable('cashflow')}
                                handleChange={this.handleChangeSearch}
                                handleMaxChange={this.handleChangeMaxcashflow}
                                handleMinChange={this.handleChangeMincashflow}
                            />

                            {/* <!-- Revenue multiple --> */}
                            <PriceFilter
                                title="Revenue multiple"
                                maxValue={this.state.fields["maxrevenue_multiple"]}
                                minValue={this.state.fields["minrevenue_multiple"]}
                                maxName="maxrevenue_multiple"
                                minName="minrevenue_multiple"
                                clearMetrics={this.clearMetrics}
                                filterNiceValue={this.filterNiceValue}
                                valueMap={priceValues.revenue_multiple}
                                isOptionAvailable={isPriceValueAvailable('revenue_multiple')}
                                handleChange={this.handleChangeSearch}
                                handleMaxChange={this.handleChangeMaxRevenueMultiple}
                                handleMinChange={this.handleChangeMinRevenueMultiple}
                                onCollapseClick={this.onCollapseClick}
                                isMultiple
                            />
                            {/* <!-- SDE Multiple --> */}
                            <PriceFilter
                                title="SDE Multiple"
                                maxValue={this.state.fields["maxcashflow_multiple"]}
                                minValue={this.state.fields["mincashflow_multiple"]}
                                maxName="maxcashflow_multiple"
                                minName="mincashflow_multiple"
                                clearMetrics={this.clearMetrics}
                                filterNiceValue={this.filterNiceValue}
                                valueMap={priceValues.cashflow_multiple}
                                isOptionAvailable={isPriceValueAvailable('cashflow_multiple')}
                                handleChange={this.handleChangeSearch}
                                handleMaxChange={this.handleChangeMaxCashflowMultiple}
                                handleMinChange={this.handleChangeMinCashflowMultiple}
                                onCollapseClick={this.onCollapseClick}
                                isMultiple
                            />

                            {/* <!-- EBITDA Multiple --> */}
                            <PriceFilter
                                title="EBITDA Multiple"
                                maxValue={this.state.fields["maxebitda_multiple"]}
                                minValue={this.state.fields["minebitda_multiple"]}
                                maxName="maxebitda_multiple"
                                minName="minebitda_multiple"
                                clearMetrics={this.clearMetrics}
                                filterNiceValue={this.filterNiceValue}
                                valueMap={priceValues.ebitda_multiple}
                                isOptionAvailable={isPriceValueAvailable('ebitda_multiple')}
                                handleChange={this.handleChangeSearch}
                                handleMaxChange={this.handleChangeMaxEbitdaMultiple}
                                handleMinChange={this.handleChangeMinEbitdaMultiple}
                                onCollapseClick={this.onCollapseClick}
                                isMultiple
                            />

                            {/* <!-- Date added --> */}
                            <div className="row align-items-center row-gutter-10">
                                <div className="col-md-12 mb-2">
                                    <div className="dropdown">
                                        <button style={{borderRadius: '0px', justifyContent: 'flex-start'}}
                                                className={`btn btn-filter w-100 ${this.state.dateaddedOption.label ? 'active' : ''}`}
                                                type="button" id="dropdownMenuDateAdded" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <span
                                                className="me-1">{this.state.dateaddedOption.label || 'Date Updated'}</span>
                                            <span className="text-xsm">
                                <svg className="icon" viewBox="0 0 20 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z"
                                          fill="currentColor"></path>
                                </svg>
                            </span>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu--minw-260"
                                             aria-labelledby="dropdownMenuDateAdded">
                                            {[{value: `all`, label: `All`},
                                                {value: `lastweek`, label: `Within the last week`},
                                                {value: `lastmonth`, label: `Within the last month`},
                                            ].map(da => <a onClick={() => this.handledateaddedChange(da)}
                                                           className="dropdown-item"
                                                           href="javascript:void(0)">{da.label}</a>)}
                                        </div>
                                    </div>
                                    {this.state.dateaddedOption.label &&
                                        <span onClick={() => this.setState({dateaddedOption: {}})}
                                              className="text-xsm cursor_pointer">
                            <svg style={{position: 'absolute', top: 11, right: 17, color: '#6D7277'}} className="icon"
                                 viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                      fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                      fill="currentColor"></path>
                            </svg>
                        </span>}
                                </div>
                            </div>


                            {/* <!-- Asset sale --> */}
                            <div className="row align-items-center row-gutter-10">
                                <div className="col-md-12 mb-2">

                                    <label htmlFor="customCheck1" style={{width: '100%', marginBottom: 0}}>
                                        <div className="custom-control custom-checkbox btn-filter btn" style={{
                                            width: '100%',
                                            justifyContent: 'flex-start',
                                            borderRadius: '0px',
                                            paddingLeft: 34
                                        }}>
                                            <input type="checkbox" name="asset_sale"
                                                   value={this.state.fields.asset_sale}
                                                   checked={this.state.fields.asset_sale}
                                                   onChange={this.handleChangeSearch.bind(this, "asset_sale")}
                                                   className="custom-control-input" id="customCheck1"/>
                                            <label className="custom-control-label text-sm" htmlFor="customCheck1">Asset
                                                sale</label>
                                        </div>
                                    </label>

                                </div>
                            </div>


                            {/* <!-- Clear and Guide --> */}

                        </div>
                    </div>


                    <div className="">
                        <div className="">
                            <button disabled={isButtonDisabled} onClick={this.SubmitSearchResult}
                                    className="btn btn-primary search-now-button w-100 mb-2 mt-2" style={{height: 40}}>
                                Search Now
                            </button>
                        </div>

                    </div>

                    <div className="row">
                        <div className="text-sm col-md-12">
                            <div>
                                <a target="_blank" style={{borderBottom: 0, fontSize: 18}}
                                   href="https://community.biznexus.com/posts/%F0%9F%8E%93help-center-%F0%9F%9B%A0%EF%B8%8Ftoolkit-how-to-use-our-advanced-search-tool-for-deal-flow"
                                   className="link-pale btn btn-unstyled btn-link">
                                    <span>
                                        <svg className="icon" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                                                  fill="currentColor"></path>
                                            <path
                                                d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z"
                                                fill="currentColor"></path>
                                        </svg>
                                    </span>
                                    <span style={{fontSize: 12}}> How to use advanced search</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal isOpen={this.state.premiumModal}
                       toggle={() => this.setState({premiumModal: !this.state.premiumModal})}>
                    <ModalHeader
                        toggle={() => this.setState({premiumModal: !this.state.premiumModal})}>BizNexus</ModalHeader>
                    <ModalBody>
                        Upgrade your subscription to search by multiples.
                    </ModalBody>
                    <ModalFooter>
                        <a href="/user/subscription" className="btn btn-primary">Learn More</a>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
        )
    }
}


export default compose(withRouter, connect())(Console);
