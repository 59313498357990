import React from 'react';
import moment from 'moment';
import '../components/EditProfile/tabs/style.css';
import { Collapse, CardBody, Card, ListGroup, ListGroupItem, 
    Modal, ModalBody, ModalHeader, ModalFooter, Button, UncontrolledTooltip
} from 'reactstrap';
import Footer from '../components/Footer';
import Header from '../components/Header';


class Subscription extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            premium: false,
            premium_obj: null,
            checked: true,
            free_trial: false,
            free: false,
            invoiceModal: false,
            levelToUpgrade: null,

            upcoming: {},

            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            q6: false,
            qUpgrade: false,
            freeModal: false,
            premiumUntil: null,
            free_trial_end: false,

            eventMessage: '',
            eventHeader: 'Thank You!',
            eventModal: false,
        };
    }

    componentDidMount() {
        if(localStorage.getItem('token')) {
            this.props.history.push('/user/subscription');
        }
    }

    render() { // 891
        moment.updateLocale('en', {
            week : {
                dow : 1,
                doy : 4
            }
        });

        return (
            <>
            <Header />

            <section className="profile-bg homepage" style={{ backgroundColor: '#f5f6f7' }}>
                    <div style={{ maxWidth: 1170 }} className={'container'}>


            <div className="subscription-page">
                <div className="">
                   
                    <div className="loader" id="loader" style={{display: 'none'}}>
                        <div className="spiner"></div>
                    </div>
                    
                    


                    <section class="mb-5">
                        <h1 class="h1 mb-4">Choose the right BizNexus for you</h1>
                        <div class="d-flex mb-3 mb-md-4">
                        
                            <p style={{ padding: '5px' }}>
                                Monthly
                            </p>
                            <div className="">
                                <section className="pricing">
                                    <label className="switch">
                                        <input checked={this.state.checked} value={this.state.checked} onChange={(e) => this.setState({checked: e.target.checked})} type="checkbox" id="togBtn" />
                                        <div className="slider round"></div>
                                    </label> 
                                </section>  
                            </div>
                            <p style={{ left: '25px', padding: '5px' }}>
                                Annual save 25%
                            </p>
                   
                        </div>

                        <div class="pricing-plans-wrapper" style={{ paddingTop: 50 }}>
                            <div class="row flex-nowrap pt-2 pb-2">
                                <div class="col-3">
                                <div class="pricing-card">
                                    <div class="pricing-card-head">
                                    <h2 class="pricing-card__title">Starter</h2>
                                    <p class="pricing-card__price">Free</p>
                                    <div class="content-divider"></div>
                                    </div>
                                    <ul class="pricing-card__features">
                                    <li>
                                        Access to all broker-posted deals
                                        
                                        
                                        <span class="pricing-card__features-tooltip" id="inquiryTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`inquiryTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Send unlimited inquiries for all broker-posted deals
                                        </UncontrolledTooltip>
                                    </li>
                                    <li>
                                    Access curated M&A events & news
                                        
                                        
                                        <span class="pricing-card__features-tooltip" id="nicheEventsTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`nicheEventsTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Niche events & content updated daily for your education or social posting strategy
                                        </UncontrolledTooltip>
                                    </li>
                                    <li>
                                    Basic directory matching
                                        
                                        
                                        <span class="pricing-card__features-tooltip" id="matchTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`matchTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Get matched with intermediaries, buyers & more based on your specific target criteria
                                        </UncontrolledTooltip>
                                    </li>
                                    <li>
                                    Basic search filters
                                        
                                        
                                        <span class="pricing-card__features-tooltip" id="metricsTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`metricsTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Search deals by key metrics & keywords
                                        </UncontrolledTooltip>
                                    </li>
                                    <li>
                                    Deal mgmt & private notes
                                        
                                        
                                        <span class="pricing-card__features-tooltip" id="buySideTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`buySideTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Create buy-side projects and target deal searches. Add your own private notes to deals that interest you
                                        </UncontrolledTooltip>
                                    </li>
                                    
                                    </ul>
                                    <div class="position-relative text-center mt-auto">
                                        <a href="/login" class={`btn btn-dark pricing-card__select-btn`}>Sign In</a>
                                    </div>
                                    
                                </div>
                                
                                </div>
                                <div class="col-3">
                                <div class="pricing-card">
                                    <div class="pricing-card-head">
                                    <h2 class="pricing-card__title">Premium</h2>
                                    <p class="pricing-card__price">
                                        {this.state.checked && <span class="pricing-card__price-addition crossed">$99</span>}
                                        {this.state.checked ? '$75' : '$99'}
                                        <span class="pricing-card__price-addition">/mo</span>
                                    </p>
                                    {this.state.checked && <p class="pricing-card__annual">$891 annual</p>}
                                    <div class="content-divider"></div>
                                    </div>
                                    <h3 class="pricing-card__title-sm">Everything in Starter, plus:</h3>
                                    <ul class="pricing-card__features">

                                    <li>
                                    Get access to buyer training workshop
                                    <span class="pricing-card__features-tooltip" id="workshopTooltip">
                                            <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                                                <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                        <UncontrolledTooltip placement="bottom" target={`workshopTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Get access to our secret buyer workshop with tips, tactics & collaboration
                                        </UncontrolledTooltip>
                                    </li>

                                    <li>
                                    Buy-Side project support
                                    <span class="pricing-card__features-tooltip" id="customerSuccessTooltip">
                                            <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                                                <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                        <UncontrolledTooltip placement="bottom" target={`customerSuccessTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Work with a customer success agent to set up your buy-side projects and manage communication alerts
                                        </UncontrolledTooltip>
                                    </li>

                                    <li>
                                    Access premium deals
                                        <span class="pricing-card__features-tooltip" id="premiumTooltip">
                                        <UncontrolledTooltip placement="top" target="premiumTooltip" autohide={false} innerClassName="areas-tooltip">Unlimited access to premium, proprietary & pre-CIM deals. <a target="_blank" rel="noopener noreferrer" href="https://www.biznexus.com/about-subscriptions">Learn More</a></UncontrolledTooltip>
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                    </li>
                                    <li>
                                    Full directory matching
                                        <span class="pricing-card__features-tooltip" id="proMatchesTooltip">
                                            <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                                                <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                        <UncontrolledTooltip placement="bottom" target={`proMatchesTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Access all curated professional matches based on your specific target criteria
                                        </UncontrolledTooltip>
                                    </li>
                                    <li>
                                    Advanced search filters
                                        <span class="pricing-card__features-tooltip" id="multipleFuncTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`multipleFuncTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Access search by multiples functionality
                                        </UncontrolledTooltip>
                                    </li>
                                  
                                    <li>
                                    Premium user profile badge
                                        <span class="pricing-card__features-tooltip" id="premiumProfilesTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`premiumProfilesTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Premium profiles are designated as such so sellers and intermediaries can prioritize their replies to deal inquiries.
                                        </UncontrolledTooltip>
                                    </li>
                                   
                                    </ul>

                                    <div class="position-relative text-center mt-auto">
                                        <a href="/login" class={`btn btn-dark pricing-card__select-btn`}>Sign In</a>
                                    </div>

                                   
                                </div>

                                
                                </div>
                                <div class="col-3">
                                <div class="pricing-card">
                                    <div class="pricing-card-head">
                                        <h2 class="pricing-card__title">Pro</h2>
                                        <p class="pricing-card__price">
                                            {this.state.checked && <span class="pricing-card__price-addition crossed">$250</span>}
                                            {this.state.checked ? '$188' : '$250'}
                                            <span class="pricing-card__price-addition">/mo</span>
                                        </p>
                                        {this.state.checked && <p class="pricing-card__annual">$2250 annual</p>}
                                        <div class="content-divider"></div>
                                    </div>
                                    <h3 class="pricing-card__title-sm">Everything in Premium, plus:</h3>
                                    <ul class="pricing-card__features">
                                    <li>
                                    1 monthly display ad
                                        <span class="pricing-card__features-tooltip" >
                                        

                                    </span>
                                    </li>
                                    <li>
                                    1 monthly boosted profile
                                        <span class="pricing-card__features-tooltip" >
                                        

                                    </span>
                                    </li>
                                    <li>
                                    1 monthly boosted listing
                                        <span class="pricing-card__features-tooltip" >
                                        
                                    </span>
                                    </li>
                                    <li>
                                    1 monthly text ad
                                        <span class="pricing-card__features-tooltip">
                                        

                                    </span>
                                    </li>
                                    <li>
                                    1 quarterly newsletter ad
                                        <span class="pricing-card__features-tooltip">
                                        

                                    </span>
                                    </li>
                                    <li>
                                    1 quarterly podcast ad
                                        <span class="pricing-card__features-tooltip">
                                       

                                    </span>
                                    </li>
                                    <li>
                                    Pro user profile badge
                                        <span class="pricing-card__features-tooltip" id="proProfilesTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`proProfilesTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Pro profiles are designated as such so sellers and intermediaries can prioritize their replies to deal inquiries.
                                        </UncontrolledTooltip>
                                    </li>
                                   
                                    </ul>
                                    <div class="position-relative text-center mt-auto">
                                        <a href="/login" class={`btn btn-dark pricing-card__select-btn`}>Sign In</a>
                                    </div>
                                </div>
                               
                                </div>
                                <div class="col-3">
                                <div class="pricing-card">
                                    <div class="pricing-card-head">
                                    <h2 class="pricing-card__title">Concierge</h2>
                                    <p class="pricing-card__price">
                                        <span class="pricing-card__price-addition">from </span>$1000<span class="pricing-card__price-addition">/mo</span>
                                    </p>
                                    <div class="content-divider"></div>
                                    </div>
                                    <h3 class="pricing-card__title-sm">Everything in Pro, plus:</h3>
                                    <ul class="pricing-card__features">
                                    <li>
                                        Customized off-market deal origination
                                        <span class="pricing-card__features-tooltip" id="ourTeamTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`ourTeamTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Let our team handle your off-market deal origination, qualification & discovery meetings for you
                                        </UncontrolledTooltip>
                                    </li>
                                    <li>
                                    Deal exclusivity
                                        <span class="pricing-card__features-tooltip" id="conciergeClientsTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`conciergeClientsTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Concierge clients are limited by territory &/or industry niche to prevent any conflict of interest
                                        </UncontrolledTooltip>
                                    </li>
                                    <li>
                                    CRM integration & mgmt
                                        <span class="pricing-card__features-tooltip" id="CRMTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`CRMTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            We set up, integrate and manage your CRM for you
                                        </UncontrolledTooltip>
                                    </li>
                                    <li>
                                    Dedicated account manager
                                        <span class="pricing-card__features-tooltip" id="dedicatedCustomerTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`dedicatedCustomerTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Get a dedicated customer success team, PM environment and Slack channel for real-time reporting and communication
                                        </UncontrolledTooltip>
                                    </li>
                                    <li>
                                    Exclusive content marketing opportunities
                                        <span class="pricing-card__features-tooltip" id="PodcastTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`PodcastTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Get priority access as a podcast guest, workshop host and be quoted in our content with links to your site
                                        </UncontrolledTooltip>
                                    </li>
                                    <li>
                                    Exclusive workshop training
                                        <span class="pricing-card__features-tooltip" id="workshopsTooltip">
                                        <svg class="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>

                                    </span>
                                        <UncontrolledTooltip placement="bottom" target={`workshopsTooltip`}
                                                             autohide={false}
                                                             innerClassName="areas-tooltip">
                                            Access client-only deal origination workshops and support materials
                                        </UncontrolledTooltip>
                                    </li>
                                    
                                    </ul>
                                    <div class="position-relative text-center mt-auto">
                                        <a href="https://www.biznexus.com/concierge-services" class="btn btn-dark pricing-card__select-btn">Learn More</a>
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                            </div>

                    </section>

            <section class="pt-5">
                    <h2 class="h1 mb-5 text-center">Frequently asked questions</h2>

                    <div class="accordion" id="faq">
                        <div class="col-lg-6" style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                        <div class="">
                            <div class="">
                            <div class="card">
                                <div class="card-header" id="heading-1">
                                <button class="btn btn-link d-flex w-100 justify-content-between" type="button" data-toggle="collapse" data-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                                    <span>Are there any discounts available?</span>
                                    <span class="btn-icon">
                                        <span class="d-when-expanded">
                                        <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z" fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span class="d-when-not-expanded">
                                        <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                                </button>
                                </div>

                                <div id="collapse-1" class="collapse" aria-labelledby="heading-1" data-parent="#faq">
                                    <div class="card-body">
                                    Yes! We have a referral program for advocates who bring their colleagues to the BizNexus platform. If you’re willing to put in some work, we’ll give you the subscription for free. <a href="https://app.biznexus.com/user/referral-program">Learn more.</a>
                                    </div>
                                </div>
                            </div></div>

                            <div class="">
                            <div class="card">
                                <div class="card-header" id="heading-2">
                                <button class="btn btn-link d-flex w-100 justify-content-between" type="button" data-toggle="collapse" data-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                    <span>What does it cost to post my business opportunity on BizNexus?</span>
                                    <span class="btn-icon">
                                        <span class="d-when-expanded">
                                        <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z" fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span class="d-when-not-expanded">
                                        <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                                </button>
                                </div>

                                <div id="collapse-2" class="collapse" aria-labelledby="heading-2" data-parent="#faq">
                                    <div class="card-body">
                                    It costs nothing to post a confidential, anonymized sell-side opportunity on our platform and instantly see suggested intermediaries, advisors & buyers based on your business's specific geographic area, industry niche and transaction details. 

We have added services for advertising and building out your deal team, but our goal is to help make a good match regardless of whether you're thinking of an exit now, or years from now. 

In our experience, the earlier you begin the conversations with intermediaries, advisors and other professionals to help the better.
                                    </div>
                                </div>
                            </div></div>

                            <div class="">
                            <div class="card">
                                <div class="card-header" id="heading-3">
                                <button class="btn btn-link d-flex w-100 justify-content-between" type="button" data-toggle="collapse" data-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                    <span>If an intermediary doesn’t get back to me, what can I do?</span>
                                    <span class="btn-icon">
                                        <span class="d-when-expanded">
                                        <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z" fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span class="d-when-not-expanded">
                                        <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                                </button>
                                </div>

                                <div id="collapse-3" class="collapse" aria-labelledby="heading-3" data-parent="#faq">
                                    <div class="card-body">
                                    We publicly list typical response times for each intermediary on our platform, we proactively reach out to intermediaries who do not respond within 24 hours, and we kick bad-actors off of our platform, so ideally this won’t be an issue. If you have trouble reaching an intermediary ping us in the chat box or email us at nudge@biznexus.com and we’ll personally reach out to the intermediary to give them a “nudge.”
                                    </div>
                                </div>
                            </div></div>

                            <div class="">
                            <div class="card">
                                <div class="card-header" id="heading-4">
                                <button class="btn btn-link d-flex w-100 justify-content-between" type="button" data-toggle="collapse" data-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                                    <span>Do you have any team or company-wide discounts?</span>
                                    <span class="btn-icon">
                                        <span class="d-when-expanded">
                                        <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z" fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span class="d-when-not-expanded">
                                        <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                                </button>
                                </div>

                                <div id="collapse-4" class="collapse" aria-labelledby="heading-4" data-parent="#faq">
                                    <div class="card-body">
                                    Yes! Please contact our sales team at sales@biznexus.com
                                    </div>
                                </div>
                            </div></div>


                            <div class="">
                            <div class="card">
                                <div class="card-header" id="heading-5">
                                <button class="btn btn-link d-flex w-100 justify-content-between" type="button" data-toggle="collapse" data-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                                    <span>Do you have any discounts for veterans or first responders?</span>
                                    <span class="btn-icon">
                                        <span class="d-when-expanded">
                                        <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z" fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span class="d-when-not-expanded">
                                        <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z" fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                                </button>
                                </div>

                                <div id="collapse-5" class="collapse" aria-labelledby="heading-5" data-parent="#faq">
                                    <div class="card-body">
                                    Absolutely. Please contact our sales team at sales@biznexus.com
                                    </div>
                                </div>
                            </div></div>
                    </div>
                    </div>
                </div>
            </section>

                    
            </div>

            <Modal size={'lg'} isOpen={this.state.invoiceModal} toggle={() => this.setState({ invoiceModal: !this.state.invoiceModal })} className="modal-dialog modal-featured" keyboard={true} backdrop="static">
                <ModalHeader toggle={() => this.setState({ invoiceModal: !this.state.invoiceModal })}>
                    Upcoming Invoice
                </ModalHeader>
                <ModalBody>
                <div className="container">
                        <p>You will be charge for ${this.state.upcoming.subtotal / 100} on {moment.unix(this.state.upcoming.next_payment_attempt).format('LL')}</p>


                     <ListGroup>
                        <ListGroupItem style={{ fontWeight: '800', cursor: 'pointer' }} onClick={() => this.setState({qUpgrade: !this.state.qUpgrade})}>How do prorated charges work?</ListGroupItem>
                    </ListGroup>
                    <Collapse isOpen={this.state.qUpgrade}>
                        <Card>
                        <CardBody style={{ margin: '25px' }}>
                        <b>Proration 101</b> <br />
                        
                        When you upgrade (or downgrade) your subscription plan you will be charged (or credited) a prorated amount.
                        <br /><br />
                        For example, let's say your billing starts on January 1st, and on January 15th you upgrade from a Premium subscription to the Pro subscription plan. On January 1st you were charged $99 which pays for the full month of January. January 15th is 50% of the way through the month and that's when you upgraded to Pro. A few things happen here:
                        <br /><br />
                        You will be credited $49.50 since the Premium plan is no longer in use for the remaining 50% of the month.
                        <br /><br />
                        You will be charged $125.00 to pay for your new Pro plan from April 15th to April 30th (normally $250.00 for the full month).
                        <br /><br />
                        On February 1st, you will be charged $250 (monthly subscription price for the Pro plan) for the entire upcoming month of February.
                        <br /><br />
                        You will have a chance to preview these charges before completing your upgrade and when you do, the one-time charge for the current month will be collected immediately, and you will be enrolled into your new plan going forward.

                        <br /><br />
                        <b>Prorating downgrades</b>
                        <br />
                        When you downgrade your subscription, you may end up with a credit on your account. 
                        <br /><br />
                        Say you paid for Pro and then downgraded to Premium through the month. For our example, we'll say this prorating event gives you a credit of $99 on your account. You will be refunded and account credit for this amount, and future BizNexus charges will first draw down from this credit balance before charging your account again.

                        </CardBody>
                        </Card>
                    </Collapse>
                </div>
                <ModalFooter style={{ paddingTop: '15px', borderTop: 'none' }}>
                    <Button color="success" onClick={() => this.onUpgrade()}>Ok, I Agree</Button>
                    <Button color="error" onClick={() => this.setState({ invoiceModal: false })}>Cancel</Button>
                </ModalFooter>
                </ModalBody>
            </Modal> 

            <Modal isOpen={this.state.freeModal} toggle={() => this.setState({ freeModal: !this.state.freeModal })} className="modal-dialog modal-featured" keyboard={true} backdrop="static">
                <ModalHeader toggle={() => this.setState({ freeModal: !this.state.freeModal })}>
                    Start your free trial
                </ModalHeader>
                <ModalBody>
                <div className="container">
                Click below to start your unlimited deal access for the next 30 days and be sure to ping us with any questions!
                </div>
                <ModalFooter style={{ paddingTop: '15px', borderTop: 'none' }}>
                    <Button color="success" onClick={() => this.onStartFreeTrial()}>Start Free Trial</Button>
                    <Button color="error" onClick={() => this.setState({ freeModal: false })}>Cancel</Button>
                </ModalFooter>
                </ModalBody>
            </Modal>  

            <Modal isOpen={this.state.eventModal} toggle={() => this.setState({ eventModal: !this.state.eventModal })} className="modal-dialog modal-featured modal-dialog-centered" keyboard={true} backdrop="static">
                <ModalHeader toggle={() => this.setState({ eventModal: !this.state.eventModal })}>
                    
                </ModalHeader>
                <ModalBody>
                <div className="container text-center" style={{ position: 'relative', top: 18 }}>
                    <h1 className="h1">{this.state.eventHeader}</h1>
                    <div style={{ fontSize: 18 }}>{this.state.eventMessage}</div>
                </div>
                <ModalFooter style={{ paddingTop: '15px', borderTop: 'none' }}>
                    <Button color="primary" onClick={() => this.setState({ eventModal: false })}>Close</Button>
                </ModalFooter>
                </ModalBody>
            </Modal>  
            
            </div>


            </div></section>

            <Footer />
            </>
        )
    }
}

export default Subscription;
