import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import './user-pending-modal.scss'

const UserPendingModal = () => {
    return (
        <>
            <Header pendingUser/>

            <div className="" style={{minHeight: '260px', height: 'calc(100vh - 220px)', backgroundColor: 'rgba(60, 60, 60, 0.5)', position: 'relative'}}>
                <img className="background"
                     src="/images/bg-signup.png" alt="background"/>
                <div className="row container margin_center pending-user-modal" style={{maxWidth: 800, margin: '0 auto'}}>

                    <div className="col-xl-12 mt-4">
                        <div className="profile-bx">
                            <div className="profile-form mt-4 mb-4 advanced-search-console">

                                <h1 className="h1 mt-1">Application Pending</h1>
                                <p>
                                    We’ll notify you as soon as a decision is made (typically under 48 hours), and upon
                                    approval we’ll immediately begin your complimentary, 30-day, full-access trial to
                                    the
                                    marketplace.
                                    <br/>
                                    <br/>
                                    Thanks for your patience as we work hard to keep the marketplace free of bots,
                                    trolls,
                                    and interweb interlopers.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default UserPendingModal
