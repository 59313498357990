import React from 'react';
import api from '../../services/api';
import {Table, Row, Col, Input} from 'reactstrap';
import { Link } from 'react-router-dom';
import SharedLeadsImport from './SharedLeads/SharedLeadsImport';
import ConfirmationPopup from "../ConfirmationPopup/confirmationPopup";


export default class Leads extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      loading: false,
      selectedLeads: [],
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    api.get('console/leads', null, true).then(res => {
      this.setState({ leads: res });
    });
  }

  handleLeadSelect = (event) => {
    this.setState(prevState => ({
      ...prevState,
      selectedLeads: event.target.checked
          ? [...prevState.selectedLeads, event.target.value]
          : prevState.selectedLeads.filter(id => id !== event.target.value)
    }))
  }

  openConfirmationPopup = () => {
    this.setState({isConfirmationOpen: true})
  }

  closeConfirmationPopup = () => {
    this.setState({isConfirmationOpen: false})
  }

  deleteLeads = async () => {
    api.delete('console/leads', {leadIds: this.state.selectedLeads}, true).then(res => {
      this.setState(prevState => ({ ...prevState, selectedLeads: [], leads: prevState.leads.filter(({id}) => !res.leadIds.includes(id)) }));
      this.closeConfirmationPopup()
    });
  }


  render() {

    return (
        <div>
          <Link to="/console">&larr; Back</Link>
          <br /><br />
          <Row>
            <Col sm="9">
              <h2>Leads Database</h2>
              {
                this.state.selectedLeads.length
                    ? <button className="btn btn-sm btn-danger mb-2" type="button" onClick={this.openConfirmationPopup}>Delete selected leads</button>
                    : ''
              }
              <Table bordered hover responsive>
                <thead style={{ textAlign: 'center' }}>
                <tr>
                  <th>Select</th>
                  <th>Logo</th>
                  <th>type</th>
                  <th>acquirer name</th>
                  <th>city</th>
                  <th>state</th>
                  <th>phone</th>
                  <th>website</th>
                  <th>overview</th>
                  <th>contact_name</th>
                  <th>contact_title</th>
                  <th>contact_email</th>
                  <th>rev-min</th>
                  <th>rev-max</th>
                  <th>cashflow-min</th>
                  <th>cashflow-max</th>
                  <th>linkedin</th>
                  <th>industries</th>
                  <th>tearsheet</th>
                  <th>Match Preferences Industries</th>
                  <th>Match Preferences Locations</th>
                  <th>Keywords</th>
                  <th>Employees</th>
                  <th>Founded Year</th>
                  <th>Facebook</th>
                  <th>Twitter</th>
                  <th>Company Address</th>
                  <th>SEO Descripion</th>
                  <th>Apollo Based</th>

                </tr>
                </thead>
                <tbody>

                {this.state.leads.map(lead => {
                  const areaServed = lead.area_served ? JSON.parse(lead.area_served) : null
                  const industriesOfExpertise = lead.industries_of_expertise ? JSON.parse(lead.industries_of_expertise) : null
                  return (<tr className={this.state.selectedLeads.includes(lead.id) ? 'selected-lead' : ''} style={{textAlign: 'center'}}>
                    <td>
                      <Input type="checkbox"
                             id="override"
                             name="override"
                             onChange={this.handleLeadSelect}
                             className="custom-control-input"
                             value={lead.id}
                             checked={this.state.selectedLeads.includes(lead.id)}/>
                    </td>
                    <td> {lead.logo_url ?
                        <img alt="" width="60px" height="60px" src={lead.logo_url}
                             className="img-fluid rounded-circle d-block m-auto"/>
                        : <svg width="18" height="16" viewBox="0 0 18 16" fill="none">
                          <path
                              d="M0.980469 15.1458V0.5625H8.89714V3.89583H17.0221V15.1458H0.980469ZM2.23047 13.8958H4.3138V11.8125H2.23047V13.8958ZM2.23047 10.5625H4.3138V8.47917H2.23047V10.5625ZM2.23047 7.22917H4.3138V5.14583H2.23047V7.22917ZM2.23047 3.89583H4.3138V1.8125H2.23047V3.89583ZM5.5638 13.8958H7.64714V11.8125H5.5638V13.8958ZM5.5638 10.5625H7.64714V8.47917H5.5638V10.5625ZM5.5638 7.22917H7.64714V5.14583H5.5638V7.22917ZM5.5638 3.89583H7.64714V1.8125H5.5638V3.89583ZM8.89714 13.8958H15.7721V5.14583H8.89714V7.22917H10.7096V8.47917H8.89714V10.5625H10.7096V11.8125H8.89714V13.8958ZM12.543 8.47917V7.22917H13.793V8.47917H12.543ZM12.543 11.8125V10.5625H13.793V11.8125H12.543Z"
                              fill="#515151"/>
                        </svg>
                    }</td>
                    <td>{lead.type}</td>
                    <td>{lead.name}</td>
                    <td>{lead.city}</td>
                    <td>{lead.state}</td>
                    <td>{lead.phone}</td>
                    <td>{lead.website}</td>
                    <td>{lead.overview}</td>
                    <td>{lead.contact_name}</td>
                    <td>{lead.contact_title}</td>
                    <td>{lead.contact_email}</td>
                    <td>{lead.rev_min}</td>
                    <td>{lead.rev_max}</td>
                    <td>{lead.cashflow_min}</td>
                    <td>{lead.cashflow_max}</td>
                    <td>{lead.linkedin}</td>
                    <td>{lead.industries}</td>
                    <td>{lead.tearsheet}</td>
                    <td>{industriesOfExpertise ? industriesOfExpertise.map(({name}) => name).join(', ') : '-'}</td>
                    <td>{areaServed ? areaServed.map(({location}) => location).join('; ') : '-'}</td>
                    <td>{lead.acquirer_keywords}</td>
                    <td>{lead.employees_number}</td>
                    <td>{lead.founded_year}</td>
                    <td>{lead.facebook_url}</td>
                    <td>{lead.twitter_url}</td>
                    <td>{lead.company_address}</td>
                    <td>{lead.seo_description}</td>
                    <td>{lead.is_apollo ? 'Yes' : 'No'}</td>
                  </tr>)
                })}
                </tbody>
              </Table>
            </Col>
            <Col sm="3">
              <SharedLeadsImport/>

              <ConfirmationPopup
                  title="Delete selected leads?"
                  isOpen={this.state.isConfirmationOpen}
                  onClose={this.closeConfirmationPopup}
                  onCancel={this.closeConfirmationPopup}
                  onAccept={this.deleteLeads}
                  acceptText="Delete"
                  acceptClass="danger"
              />

            </Col>
          </Row>


        </div>
    )
  }
}

