/**
 * gets rid of commas, currency symbols, etc and returns a string representation of a number
 * @param {string} str any string representing a number
 */
export function cleanNumericString(str) {
  return str ? str.replace(/[^\d.]/, "") : "";
}
export function truncString(str, max, add) {
  add = add || '...';
  return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
}
