import React from 'react';
import { sample } from 'lodash';

const testimonialsList = [{
  body: `"The team opened up our eyes to the power of using a multi-channel approach & sourced significant opportunities in a very short time."`,
  name: 'Ajay Amin',
  title: 'Head of M&A',
  image: '/images/testimonials/ajayamin.jpeg',
},{
  body: `"The team presented off - grid targets almost immediately & these quickly developed into working relationships.`,
  name: 'Shaun Steingold',
  title: 'GP at Momentum Labs',
  image: '/images/testimonials/shaun.png',
},{
  body: `"BizNexus has been a valuable partner to identify and qualify leads and opportunities we would have never found without their help."`,
  name: 'Daniel Sheppard',
  title: 'M&A Advisor at MedWorld',
  image: '/images/testimonials/daniel.jpeg',
},];

const testimonial = sample(testimonialsList);

const Testimonials = () => {

  return (
      <div className="pt-5 pb-5" style={{ maxWidth: '66%' }}>
        <h2 className="h2">Testimonial</h2>
        <div className="signup-testimonial">
          {testimonial.body}
          <div className="mt-2" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="me-2">
              <img
                  src={testimonial.image}
                  alt="" width="50" height="50"
                  className="img-fluid rounded-circle z-depth-0" />
            </div>
            <div>
              <div className="ms-2">
                <div><b>{testimonial.name}</b></div>
                <div className="text-sm">{testimonial.title}</div>
              </div>

            </div>
          </div>
        </div>

      </div>
  )
}

export default Testimonials
