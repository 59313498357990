// import '@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {LinkedIn} from 'react-linkedin-login-oauth2';
import React from 'react';
import {
    Button,
    Form,
    Modal,
    ModalBody,
    ModalHeader,
    UncontrolledTooltip,
    Input,
    TabContent, TabPane, Badge
} from 'reactstrap';
import {USER_TYPE_LABEL} from '../../constants/userTypes';
import {getImageUrl} from '../util';
import Header from '../Header';
import api from '../../services/api';
import {uploadFile} from '../../services/file';
import Messages from './tabs/messages';
import Listings from './tabs/listings';
import Reviews from '../Reviews';
import Testimonials from './tabs/testimonials';
import Awards from './tabs/awards';
import Profile from './tabs/profile';
import Preferences from './tabs/preferences';
import Favorites from './tabs/favorites';
import Qualification from './tabs/qualification';
import Ads from './tabs/ads';
import SocialProof from './tabs/social_proof';
import BrokerHome from '../Home/BrokerHome';
import ProviderHome from '../Home/ProviderHome';
import FeaturedProfile from '../FeaturedProfile';
import Footer from '../Footer';

// import BuyerHome from '../Home/BuyerHome';
import Home from '../Home/index';
import Premium from './tabs/premium';
import Billing from "../../containers/billing";
import ChangePassword from "./tabs/components/ChangePassword/ChangePassword";
import Integrations from "../../containers/integrations";
import {SubscriptionLevels} from "../../constants/subscriptionLevels";
import { UserStatusNameMap} from "../Console/signups";
import {AuthContext} from "../../contexts/auth";
import {withRouter} from "../../containers/withRouter/withRouter";
import Dropzone from "../Dropzone";
import ImageEditor from "../ImageEditor";

class EditProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            errors: [],
            fields: [],
            loading: false,
            professionlist: [],
            profileBannermodal: false,
            profilePicmodal: false,
            activeTab: '',
            profileBannerImg: '',
            profileBanner: null,
            profilepic: null,
            bannerselection: '',
            bannerselectionId: '',
            setbannerselection: '',
            cropset: {aspectRatio: 4 / 4, autoCropArea: 1},
            cropsetbanner: {aspectRatio: 16 / 3, autoCropArea: 1},
            unread: 0,
            subTab: null,
        };

        this.tabsToUserType = {
            1: ['profile', 'preferences', 'messages', 'favorites', 'qualification', 'ads', 'referral-program'], // buyer
            2: ['profile', 'preferences', 'messages', 'favorites', 'testimonials', 'ads', 'referral-program'], // seller
            3: ['profile', 'messages', 'listings', 'social-proof' , 'favorites', 'ads', 'referral-program'], //broker 'awards', 'reviews', 'testimonials'
            4: ['profile', 'preferences', 'messages', 'awards', 'reviews', 'testimonials', 'ads', 'referral-program'] // provider 
        };
    }

    componentWillMount() {
        // window.addEventListener('resize', this.handleWindowSizeChange);
    }
      
    componentWillUnmount() {
        // window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    init = props => {
        const { match: { params } } = props;

        if(params.tab) { 
            this.setState({ activeTab: params.tab });
        } else {
            this.setState({ activeTab: 'home' });
        }

        if(params.subtab) {
            this.setState({ subTab: params.subtab });
        } else {
            this.setState({ subTab: null });
        }

        if(!localStorage.getItem('token')) {
            this.props.history.push('/');
            return;
        }

        this.fetchHeader();
    }

    componentWillReceiveProps(nextProps) {
        this.init(nextProps);
    }

    componentDidMount() {

        if(this.props.history.location.pathname.includes('edit-profile')) {
            this.props.history.push('/home');
        }

        this.init(this.props);
    }

    fetchHeader = async () => {
        const header = await api.get('user/header', null, true) || {};
        this.setState({
            profileImg: header.profile_pic || '',
            profileBannerImg: header.profile_background_pic || '',
            linkedinToken: header.linkedin_token,
            slugName: header.slugValue,
            fields: {
                profile_pic: header.profile_pic || '',
                name: header.name
            },
            userId: header.id,
            email: header.email,
            user_type_id: header.user_type_id,
            loading: true,
            name: header.name,
            unread: header.unread,
            isPremium: header?.premium_level >= SubscriptionLevels.PREMIUM,
        });

        const config = {
            app_id: process.env.REACT_APP_INTERCOM_APP_ID,
            email: this.getNotificationEmail(header),
            user_id: header.id,
            name: header.name,
            type: USER_TYPE_LABEL[header.user_type_id],
            has_premium: header.premium,
            has_avatar: !header.profile_pic || header.profile_pic === 'undefined' ? false : true,
            has_about: header.about_overview ? true : false,
            profile_url: `https://app.biznexus.com/profile/${header.id}`
        };
        window.location.host === 'app.biznexus.com' && window.Intercom('boot', config);

        // if(window.Froged) {
        //     console.log('flogg')
        //     window.Froged("set", { user_name: header.name,
        //         profile_url: `https://app.biznexus.com/profile/${header.id}`,
        //         marketplace_user_type: USER_TYPE_LABEL[header.user_type_id],
        //         has_premium: header.premium,
        //         has_avatar: !header.profile_pic || header.profile_pic === 'undefined' ? false : true,
        //         has_about: header.about_overview ? true : false,
        //         email: this.getNotificationEmail(header),
        //     });
        // }

    }

    getNotificationEmail(user) {
        if(user.notification_email) {
            return user.notification_email;
        } else if(user.email) {
            return user.email;
        } else if(user.linkedin_email) {
            return user.linkedin_email;
        }
    }

    ProfileBanner = (bannerlist) => {
        let bannerarray = [];
        for (let k = 1; k < bannerlist; k++) {
            bannerarray.push(
                <div className="banner-selection" key={`bannerlist-${k}`}>
                    <img src={`${process.env.PUBLIC_URL}/images/profile-image-${k}.jpg`}
                         alt={`profile-banner${k}`}
                         className="img-fluid"
                    />
                    <div className="overlay">
                        <Input type="radio"
                                     id={`banner-selection${k}`}
                                     name="banner-selection"
                                     label=""
                                     defaultChecked={(this.state.profileBannerImg === `profile-image-${k}.jpg`) ? true : false}
                                     value={`profile-image-${k}.jpg`}
                                     onClick={this.handlebannerClick}
                        />
                    </div>
                </div>
            );
        }
        return bannerarray;
    }

    handlebannerClick = (e) => {
        var value = e.target.value;
        this.setState({bannerselection: value, profileupdate: 1});
        this.setState({bannerselectionId: e.target.id});
        if (this.state.profileBanner !== null) {
            this.setState({
                profileBanner: null
            });
        }
    }

    handleLinkedinSuccess = (code) => {
        const fetchBody = {
            client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
            client_secret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
            code: code,
            id: this.state.userId,
            grant_type: 'authorization_code',
            redirect_uri: process.env.REACT_APP_LINKEDIN_REDIRECT_URL,
        };

        api.post('user/connectLinkedin', fetchBody, true)
            .then(() => {
                this.setState({linkedinToken: true});
                this.fetchHeader();
            });
    }

    handleLinkedinFailure() { }

    profileBannertoggle = () => {
        this.setState({
            profileBannermodal: !this.state.profileBannermodal,
        });
    }

    profilePictoggle = () => {
        this.setState({
            profilePicmodal: !this.state.profilePicmodal,
        });
    }

    showProfilePicChangeModal = () => {
        this.setState({
            profilePicmodal: true,
            profilepic: null,
        });
    }

    updateprofileBanner = async () => {
        if (this.state.profileBanner === null && this.state.bannerselection === '') {
            let errors = {};
            errors["profilebanner"] = "Add a profile banner picture";
            this.setState({errors: errors});
        } else {
            
            if (this.state.profileBanner !== null) {
                const imgUrl = this.cropperbannerPic.getImage().toDataURL()

                this.setState({
                    profileBannerImg: imgUrl,
                    profileBannerPicUpload: 1
                }, async () => {
                    await uploadFile(imgUrl, this.state.profileBanner.name, this.state.profileBanner.type, "user/profile/banner");
                });
            } else {
                this.setState({
                    profileBannerImg: this.state.bannerselection,
                    profileBannerPicUpload: ''
                }, async () => {
                    await api.post('user/profile/update_banner', { name: this.state.profileBannerImg }, true);
                });
            }

            this.setState({errors: {}});
            this.profileBannertoggle();
        }
    }
    onProfilePicFail = failedResults => {
        this.setState({ errors: { profilepic: failedResults[0].errors[0].message } });
    }

    onChangeprofilePic = value => {
        this.setState({profilepic: value[0], profileupdate: 1, errors: {}});
    }

    updateprofilrpic = async () => {
        if (this.state.profilepic === null) {
            let errors = {};
            errors["profilepic"] = "Please select a profile picture";
            this.setState({errors: errors});
        } else {
            const imgUrl = this.profilePic.getImage().toDataURL()
            this.setState({
                profileImg: imgUrl,
                profilePicUpload: 1,
                errors: {}
            }, async () => {
                await uploadFile(imgUrl, this.state.profilepic.name, this.state.profilepic.type, "user/profile/photo");
            });
            
            this.profilePictoggle();
        }
    }
    onBannerFail = failedResults => {
        this.setState({ errors: { profilebanner: failedResults[0].errors[0].message } });
    }

    onChangeprofileBanner = value => {
        this.setState({profileBanner: value[0], profileupdate: 1});
        this.setState({bannerselection: ''});
    }

    setActiveTab = tab => {
        this.setState({ activeTab: tab });
    }

    renderActiveTab = tab => {
        switch(tab) {
            case 'messages': return <Messages user_id={this.state.userId} user_type_id={this.state.user_type_id} />
            case 'listings': return <Listings userId={this.state.userId} user_type_id={this.state.user_type_id} />
            case 'reviews': return <Reviews slugUrl={this.state.slugName} profileName={this.state.name} FirstName={this.state.name} />
            case 'testimonials': return <Testimonials user_type_id={this.state.user_type_id} />
            case 'awards': return <Awards />
            case 'profile': return <Profile />
            // case 'preferences': return <Preferences user_type_id={this.state.user_type_id} />
            case 'favorites': return <Favorites />
            // case 'qualification': return <Qualification />
            case 'social-proof': return <SocialProof />
            // case 'premium': return <Premium />
            case 'ads': return <Ads userId={this.state.userId} email={this.state.email} />
            case 'subscription': return <Premium />
            case 'account': return <Billing />
            case 'integrations': return <Integrations isPremium={this.state.isPremium} />
            case 'change-password': return <ChangePassword />
            default: return (null)
        }
    }

    tabName = tab => {
        switch(tab) {
            case 'qualification': return 'Buyer Qualification'
            case 'listings': return 'Listings'
            case 'ads': return 'Ads'
            case 'referral-program': return 'Referral'
            case 'social-proof': return 'Social Proof'
            case 'awards': return (this.state.user_type_id === 3 || this.state.user_type_id === 4) ? 'Bonafides' : 'Awards'
            case 'favorites': return this.state.user_type_id === 3 ? 'Buy-Side Tools' : 'Favorites & Saved Searches'
            default: return tab
        }
    }

    renderHomePage = () => {
        if(this.state.user_type_id === 1) {
            return <Home />
        } else if(this.state.user_type_id === 3) {
            return <BrokerHome />
        }else if(this.state.user_type_id === 4) {
            return <ProviderHome />
        } else {
            this.setState({ activeTab: 'profile' });
        }
    }

    renderTabTooltip = tab => {
        switch(tab) {
            case 'profile': 
                if(this.state.user_type_id === 1) {
                    return 'This is the bare-minimum info you should fill out so a business broker or seller will know you\'re actually looking to buy a business. Your profile will only be visible to business brokers or sellers associated with listings you inquire about.';
                }
                if(this.state.user_type_id === 3) {
                    return 'This is the bare-minimum info you should fill out to get matched with a buyer or seller on BizNexus. If you don\'t fill this out, you probably will not get matched.';
                }
                if(this.state.user_type_id === 4) {
                    return 'This is the bare-minimum info you should fill out to get matched with a buyer, seller or intermediary on BizNexus. If you don\'t fill this out, you probably will not get matched.';
                }
                break;
            case 'preferences': 
                if(this.state.user_type_id === 1) {
                    return 'This is the information we use to suggest recommended acquisition opportunities.';
                }
                break;
            case 'messages': 
                if(this.state.user_type_id === 1) {
                    return 'Find all of your conversations with intermediaries & sellers here. Filter by listing or by broker.';
                }
                if(this.state.user_type_id === 3) {
                    return 'Find all of your buyer inquiries here. Filter by listing or buyer.';
                }
                if(this.state.user_type_id === 4) {
                    return 'Find all of your buyer inquiries here. Filter by listing or buyer.';
                }
                break;
            case 'favorites': 
                if(this.state.user_type_id === 1) {
                    return 'Manage your email preferences, recommended listings, saved searches & saved listings here.';
                }
                if(this.state.user_type_id === 3) {
                    return 'Add saved searches & saved listings for buyside client searches here.';
                }
                if(this.state.user_type_id === 4) {
                    return 'Find all of your buyer inquiries here. Filter by listing or buyer.';
                }
                break;
            case 'ads': return 'Want to advertise directly to our community of buyers, sellers & intermediaries? This is where you can do that.';
            case 'qualification': return 'Fill this information out to increase your chances of getting a fast reply on attractive listings. This information is shown to an intermediary or seller when you inquire about a specific listing.'
            case 'awards': return 'Add your certifications, associations & awards.'
            case 'social-proof': return 'Add your certifications, associations & awards.Request reviews from your clients, colleagues & fans.'
            case 'reviews': return 'Request reviews from your clients, colleagues & fans.'
            case 'referral-program': return 'BizNexus Referral Program'
            case 'listings': return 'Add your current listings and completed transactions to help you get matched with buyers and sellers.'
            case 'testimonials': return 'Copy and paste testimonials and recommendations from your own website or others and showcase those on your BizNexus profile.'
            default: return null;
        }
    }

    renderTabBadge = tab => {
        if(tab === 'messages' && this.state.unread > 0) {
            return (
                <Badge style={{ marginLeft: '3px', fontSize: '90%' }} color="danger">{this.state.unread}</Badge>
            )
        }
    }

    static contextType = AuthContext

    render() {
        if( ! this.state.loading) {
            return (
                null
            )
        }

        return (
            <div> 
                <Header slugUrl={this.state.slugName} profileupdate={this.state.profileupdate}
                        profilehandleSubmit={this.profilehandleSubmit}
                        ProfileUpdatemodal={this.state.ProfileUpdatemodal}
                        pendingUser={this.context.user.status !== UserStatusNameMap.Approved}
                        ProfileUpdatetoggle={this.ProfileUpdatetoggle}/>

                


                <section className="profile-bg homepage" style={{ backgroundColor: this.state.activeTab !== 'home' ? '#f5f6f7' : '#FFFFFF', minHeight: 800 }}>
                    <div style={{ maxWidth:  this.state.activeTab !== 'messages' && this.state.activeTab !== 'favorites' ? 1170 : '100%' }} className={(this.state.activeTab !== 'home' && this.state.activeTab !== 'messages') ? 'container' : ''}>
                        <div className="" style={{ display: this.state.activeTab !== 'home' ? 'block' : 'none', }}>
                            
                            <div style={{ background: '#f5f6f7', display: 'none' }} className="loader position-fixed" id="loaderprofile">
                                <div className="spiner" style={{ backgroundColor: '#f5f6f7' }}></div>
                            </div>






                            {this.state.activeTab === 'profile' && <Form>
                                <div className="profile-banner">
                                    <div className="overflow-hidden">
                                        {(this.state.profileBannerImg) ?
                                            <img src={(this.state.profileBannerImg) ?
                                                this.state.profileBannerImg.indexOf('profile-image-') === 0 ?
                                                    `${process.env.PUBLIC_URL}/images/${this.state.profileBannerImg}` :
                                                    (this.state.profileBannerImg.indexOf('base64') > -1) ?
                                                        this.state.profileBannerImg :
                                                        (this.state.profileBannerImg.indexOf('user_background') > -1) ?
                                                            getImageUrl(this.state.profileBannerImg) :
                                                            getImageUrl(this.state.profileBannerImg) :
                                                ''
                                            }
                                                 className="img-fluid w-100" alt="profile-banner"/> :
                                            <img src={this.state.profileBannerImg !== '' ?
                                                getImageUrl(this.state.profileBannerImg) :
                                                `${process.env.PUBLIC_URL}/images/profile-image-4.jpg`
                                            }
                                                 className="img-fluid w-100" alt="profile-banner"/>
                                        }
                                    </div>

                                    
                                    <div className="overlay">
                                        <div className="change-profile-banner">
                                            <span onClick={this.profileBannertoggle}><i
                                                className="sprite i-camera">Camera</i> Change cover photo</span>
                                        </div>
                                        <div>
                                           
                                            
                                            <div className="logo-watermark"
                                                 style={this.state.linkedinToken ? {top: '200px'} : {
                                                    // top: '200px',
                                                    position: 'absolute',
                                                    // left: '250px'
                                                 }}>
                                                {!this.state.linkedinToken ?
                                                    <LinkedIn onError={this.handleLinkedinFailure}
                                                              onSuccess={this.handleLinkedinSuccess}
                                                              clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
                                                              redirectUri={process.env.REACT_APP_LINKEDIN_REDIRECT_URL}
                                                              scope={'r_liteprofile,r_emailaddress'}
                                                    >{
                                                        ({ linkedInLogin }) => (
                                                            <button className="btn btn-primary" onClick={linkedInLogin} type="button">
                                                                <div id="linkedinTooltipButton">
                                                                    <i className="fab fa-linkedin-in"/> Connect your LinkedIn
                                                                </div>
                                                                <UncontrolledTooltip
                                                                    placement="bottom"
                                                                    target="linkedinTooltipButton"
                                                                    autohide={false}
                                                                    innerClassName="areas-tooltip">
                                                                    Sync your BizNexus profile with linkedin to
                                                                    automatically populate your profile picture and overview
                                                                    summary, and to have the option to easily log into your
                                                                    BizNexus account with Linkedin.
                                                                </UncontrolledTooltip>
                                                            </button>
                                                        )
                                                    }</LinkedIn> :
                                                    <div>
                                                        <UncontrolledTooltip placement="left" target="linkedinTooltip"
                                                                             autohide={false}
                                                                             innerClassName="areas-tooltip">Your
                                                            LinkedIn account is connected</UncontrolledTooltip>
                                                        <i id="linkedinTooltip" className="fab fa-linkedin-in"/>
                                                    </div>
                                                }
                                                
                                            </div>
                                            
                                            <div className="d-none d-sm-block" style={{ bottom: 0, position: 'absolute', left: 230, top: 215 }}>
                                                <div>
                                                    <a className="btn btn-primary" href={`/profile/${this.state.userId}`} target="_blank" rel="noopener noreferrer">View Public Profile</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <Modal isOpen={this.state.profileBannermodal} toggle={this.profileBannertoggle}
                                               className="modal-dialog-centered full-size" keyboard={false}
                                               backdrop="static">
                                            <ModalHeader toggle={this.profileBannertoggle}>Upload banner
                                                image</ModalHeader>
                                            <ModalBody>
                                                {this.ProfileBanner(10)}
                                                {this.state.profileBanner
                                                    ? <div className="user-banner">
                                                        <ImageEditor
                                                            zoom
                                                            image={this.state.profileBanner}
                                                            setRef={ref => this.cropperbannerPic = ref}
                                                            height={70}
                                                            width={426}
                                                        />
                                                    </div>
                                                    : <div className="banner-drop user-banner">
                                                        <Dropzone
                                                            text="Upload from computer"
                                                            maxFileSize={5024000}
                                                            multiple={false}
                                                            onSuccess={this.onChangeprofileBanner}
                                                            onFailure={this.onBannerFail}
                                                        />
                                                    </div>
                                                }
                                                <p className="error text-center">{this.state.errors["profilebanner"]}</p>
                                                <p className="text-center"><Button type="button"
                                                                                   onClick={this.updateprofileBanner} 
                                                                                   className="btn-bluedark">Upload
                                                    Banner</Button></p>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                    <div className="profile-pic">
                                    
                                        <div className="pic-overflow">
                                            {
                                                (this.state.fields["profile_pic"] && this.state.fields["profile_pic"] !== 'undefined') ?
                                                    <img
                                                        src={(this.state.profileImg) ? (this.state.profileImg.indexOf('base64') > -1) ? this.state.profileImg : (this.state.profileImg.indexOf('user_profile') > -1) ? getImageUrl(this.state.profileImg) : getImageUrl(this.state.profileImg) : ''}
                                                        className="img-fluid rounded-circle"
                                                        alt={this.state.fields["name"]}/> :
                                                    <img
                                                        src={this.state.profileImg !== '' && this.state.profileImg !== 'undefined' ? this.state.profileImg : `${process.env.PUBLIC_URL}/images/logo-big.png`}
                                                        className="img-fluid rounded-circle not-found"
                                                        alt={this.state.fields["name"]}/>
                                            }
                                            
                                        </div>
                                        
                                        
                                        
                                        <Button type="button" onClick={this.showProfilePicChangeModal}
                                                className="btn-camera"><i className="fas fa-camera"></i></Button>
                                        <Modal isOpen={this.state.profilePicmodal} toggle={this.profilePictoggle}
                                               className="modal-dialog-centered" keyboard={false} backdrop="static">
                                            <ModalHeader toggle={this.profilePictoggle}>Upload your photo</ModalHeader>
                                            <ModalBody>

                                                {this.state.profilepic
                                                    ? <div className="user-banner profile-pic-banner">
                                                        <ImageEditor
                                                            zoom
                                                            image={this.state.profilepic}
                                                            setRef={ref => this.profilePic = ref}
                                                            height={150}
                                                            width={150}
                                                            borderRadius={75}
                                                        />
                                                    </div>
                                                    : <div className="banner-drop profile-drop user-banner">
                                                        <Dropzone
                                                            text="Click Here To Select Your Photo OR Drag JPG or PNG here"
                                                            maxFileSize={5024000}
                                                            multiple={false}
                                                            onSuccess={this.onChangeprofilePic}
                                                            onFailure={this.onProfilePicFail}
                                                        />
                                                    </div>
                                                }
                                                <p className="error text-center">{this.state.errors["profilepic"]}</p>
                                                <Button type="button" onClick={this.updateprofilrpic}
                                                        className="btn-bluedark w-100">Upload Photo</Button>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                    
                                </div>
                                {/* <span style={{ fontSize: '16px', fontWeight: '700', color: '#5d5d5d', position: 'relative' }}>{USER_TYPE_LABEL[this.state.user_type_id]}</span> */}
                                {/* <Button style={{ position: 'absolute', top: '225px', left: '233px', backgroundColor: '#69c636', color: '#fff', border: 'none' }} color={'secondary'} onClick={() => this.setState({ boostModalOpen: true })}>Boost your profile!</Button> */}

                            </Form>}
                            <div className="">
                                {/* <Nav tabs>
                                    {this.tabsToUserType[this.state.user_type_id].map((tab, i) => (
                                        <NavItem key={i} style={{ cursor: 'pointer' }}>
                                            <NavLink className={classnames({ active: this.state.activeTab === tab })}
                                                     onClick={() => { this.setActiveTab(tab) }}
                                                     style={{ textTransform: 'capitalize' }}>
                                                <Link to={`/edit-profile/${tab}`}>
                                                    {this.tabName(tab)}
                                                    {this.renderTabBadge(tab)}
                                                    {this.state.user_type_id !== 2 && <i style={{ marginBottom: '5px', marginLeft: '3px' }} id={`tab${i}`} className="sprite i-question"></i>}
                                                    {this.state.user_type_id !== 2 && <UncontrolledTooltip placement="bottom" target={`tab${i}`}
                                                            autohide={false}
                                                            innerClassName="areas-tooltip">
                                            {this.renderTabTooltip(tab)}
                                        </UncontrolledTooltip>}
                                                </Link>
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav> */}


                                

                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId={this.state.activeTab}>
                                                {this.renderActiveTab(this.state.activeTab)}
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                        {this.state.activeTab === 'home' ? this.renderHomePage() : null}
                    </div>
                </section>
                <Modal size={'lg'} isOpen={this.state.boostModalOpen} toggle={() => this.setState({ boostModalOpen: !this.state.boostModalOpen })} className="modal-dialog modal-featured" keyboard={false} backdrop="static">
                    <FeaturedProfile
                        description={''}
                        onClose={() =>this.setState({ boostModalOpen: false }) }
                    />
                </Modal>    
                <Footer /> 
            </div>
        )
    }    
 }

export default withRouter(EditProfile);
