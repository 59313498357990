import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table, UncontrolledTooltip
} from "reactstrap";
import PremiumModal from "../components/modals/premium-modal";
import Select from "react-select";
import api from "../services/api";
import {useAuthContext} from "../contexts/auth";
import {USER_TYPE} from "../constants/userTypes";

const CustomOption = ({label, value, description}) => {
    return (
        <div>
            {label}
            <i className="sprite i-question" style={{marginLeft: 5}} id={`Tooltip-${value}`}/>
            <UncontrolledTooltip placement="right"
                                 target={`Tooltip-${value}`}
                                 autohide={false}
                                 hideArrow
                                 innerClassName="review-tooltip">{description}</UncontrolledTooltip>
        </div>
    );
}

const Integrations = ({isPremium}) => {
    const [integrations, setIntegrations] = useState([])
    const [integrationEvents, setIntegrationEvents] = useState([])
    const [isPremiumOpen, setIsPremiumOpen] = useState(false)
    const [isIntegrationTestOpen, setIsIntegrationTestOpen] = useState(false)
    const [isIntegrationOpen, setIsIntegrationOpen] = useState(false)
    const [integrationData, setIntegrationData] = useState({
        name: '',
        url: '',
        selectedEvents: [],
    })
    const [integrationTestData, setIntegrationTestData] = useState({
        url: '',
        selectedEvent: '',
        response: '',
    })
    const [errors, setErrors] = useState({
        name: '',
        url: '',
        selectedEvents: '',
    })
    const { user } = useAuthContext()

    useEffect(() => {
        Promise.all([
            fetchIntegrations(),
            fetchEvents()
        ]).then(() => {

        })
    }, [])

    const fetchIntegrations = () => {
        return api.get('user/integrations', null, true).then(integrationsResponse => setIntegrations(integrationsResponse));
    }
    const fetchEvents = () => {
        return api.get('user/integration_events', null, true)
            .then(integrationEventsResponse => setIntegrationEvents(integrationEventsResponse.map(integration => ({
                label: integration.name,
                value: integration.event,
                description: integration.description,
            }))));
    }
    const onTestNewIntegration = () => {
        if(integrationTestData.selectedEvent) {
            const body = {
                integration_url: integrationTestData.url.trim(),
                event: integrationTestData.selectedEvent.value,
            }
            api.post('user/test_integrations', body, true)
                .then((response) => {
                    setIntegrationTestData({
                        ...integrationTestData,
                        response: JSON.stringify(response, null, 4)
                    });
                })
        }
    }
    const onDeleteIntegration = id => {
        api.post('user/delete_integration', {id: id}, true)
            .then(fetchIntegrations)
    }
    const renderIntegrationEvents = (integration) => {
        return integration.events.map(userEvent => {
            const one = integrationEvents.find(({value}) => {
                return value === userEvent
            })
            return one.label
        }).join(', ')
    }
    const isValidURL = (string) => {
        // eslint-disable-next-line
        const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    };

    const isCustomIntegrationValid = () => {
        let valid = true
        if (!integrationData.name.trim()) {
            setErrors({ ...errors, name: 'Integration Name is required' });
            valid = false
        }
        if (!isValidURL(integrationData.url)) {
            setErrors({ ...errors, url: 'Please, provide a valid Webhook URL' });
            valid = false
        }
        if (!integrationData.selectedEvents || !integrationData.selectedEvents.length) {
            setErrors({ ...errors, selectedEvents: 'Select at least one Event' });
            valid = false
        }

        return valid
    }

    const onCreateNewIntegration = () => {
        if(isCustomIntegrationValid()) {
            const body = {
                integration_url: integrationData.url.trim(),
                name: integrationData.name.trim(),
                events: integrationData.selectedEvents.map(event => event.value),
            }
            api.post('user/add_integration', body, true)
                .then(() => {
                    fetchIntegrations();
                    setIntegrationData({
                        name: '',
                        url: '',
                        selectedEvents: [],
                    })
                    setIsIntegrationOpen(false)
                })
        }
    }
    const handleChange = field => (value) => {
        setIntegrationData({
            ...integrationData,
            [field]: value
        })
        setErrors({
            ...errors,
            [field]: ''
        })
    }
    const handleTestChange = field => (value) => {
        setIntegrationTestData({
            ...integrationTestData,
            [field]: value
        })
        setErrors({
            ...errors,
            [field]: ''
        })
    }


    return (
        <div className="mt-4 mb-3">
            <h1 className="h1 mb-4">Integrations
                <button onClick={() => {
                    if (user.user_type_id !== USER_TYPE.BROKER && !isPremium) {
                        setIsPremiumOpen(true)
                    } else {
                        setIsIntegrationTestOpen(true)
                    }
                }} className="btn btn-tag"
                        style={{marginLeft: 5, fontSize: 13, float: 'right', padding: '15px 15px',}}>
                    <i className="fas fa-pen"/> Test
                </button>
                <button onClick={() => {
                    if (user.user_type_id !== USER_TYPE.BROKER && !isPremium) {
                        setIsPremiumOpen(true)
                    } else {
                        setIsIntegrationOpen(true)
                    }
                }} className="btn btn-tag"
                        style={{marginLeft: 5, fontSize: 13, float: 'right', padding: '15px 15px',}}>
                    <i className="fas fa-pen"/> Create
                </button>
            </h1>
            <p className={'mt-1'} style={{color: '#829fb9'}}>
                Send favorited deals, private notes, and data to your own CRM
            </p>
            <div className="mb-4">
                {integrations.length === 0 && <div className="justify-content-center row">
                    <div className="col-xl-12">
                        <div className="profile-bx"
                             style={{boxShadow: '1px 1px 20px #e1e1e1', backgroundColor: '#f8f8f8'}}>
                            <div className="profile-form mt-4 mb-4">
                                <p className="text-center">
                                    {/* <i style={{ fontSize: '48px' }} className="fas fa-search"></i> */}
                                </p>
                                <p className="text-center" style={{fontSize: '15px', fontStyle: 'italic'}}>
                                    You don't have any integrations
                                </p>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            {integrationEvents.length && integrations.length
                ? (<Table bordered responsive>
                    <thead style={{textAlign: 'center'}}>
                    <tr>
                        <th>Integration Name</th>
                        <th>Webhook Url</th>
                        <th>Events</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {integrations.map(i =>
                        <tr style={{textAlign: 'center'}}>
                            <td>{i.name}</td>
                            <td>{i.url}</td>
                            <td>{renderIntegrationEvents(i)}</td>
                            <td>
                                <button
                                    onClick={() => onDeleteIntegration(i.id)}
                                    style={{float: 'right'}}
                                    className={'btn btn-sm btn-danger'}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>)
                : ''}


            <Modal isOpen={isIntegrationOpen} toggle={() => setIsIntegrationOpen(!isIntegrationOpen)}
                   className="modal-dialog-centered delete-item profile-modal listing-modal" keyboard={true} backdrop="static">
                <ModalHeader toggle={() => setIsIntegrationOpen(!isIntegrationOpen)}>
                    Zap your events directly to your CRM of choice
                    <p style={{fontSize: 16}}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://community.biznexus.com/posts/%F0%9F%8E%93help-center-%F0%9F%9B%A0%EF%B8%8Ftoolkit-connecting-biznexus-deals-to-your-own-crm"
                        >
                            Learn How
                        </a>
                    </p>
                </ModalHeader>
                <ModalBody>
                    <FormGroup row>
                        <Label htmlFor="" md={4}>Integration Events</Label>
                        <Col md={7}>
                            <Select 
                                classNamePrefix="Select"
                                classNames={{
                                    control: (state) =>
                                        state.isFocused ? 'Select-control Select-multi' : 'Select-control Select-multi',
                                }}
                                name="eventList"
                                id="eventList"
                                isMulti
                                className="custom-multiselect"
                                isSearchable={false}
                                isClearable={false}
                                arrowRenderer={() => integrationData.selectedEvents.length === integrationEvents.length
                                    ? ''
                                    : <span className="custom-multiselect-dropdown-icon"/>}
                                onChange={handleChange('selectedEvents')}
                                optionRenderer={CustomOption}
                                options={integrationEvents}
                                value={integrationData.selectedEvents}
                                placeholder="Select Events"
                            />
                            <span className="error ">{errors.selectedEvents}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="" md={4}>Integration Name</Label>
                        <Col md={7}>
                            <InputGroup>
                                <Input
                                    placeholder={'Custom Integration'}
                                    value={integrationData.name}
                                    onChange={e => handleChange('name')(e.target.value)}
                                    type="text"
                                    required
                                />
                            </InputGroup>
                            <span className="error">{errors.name}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="" md={4}>Webhook URL</Label>
                        <Col md={7}>
                            <InputGroup>
                                <Input
                                    placeholder={'https://...'}
                                    value={integrationData.url}
                                    onChange={e => handleChange('url')(e.target.value)}
                                    type="text"
                                />
                            </InputGroup>
                            <span className="error">{errors.url}</span>
                        </Col>
                    </FormGroup>

                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={onCreateNewIntegration}>Create</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={isIntegrationTestOpen} toggle={() => {
                setIntegrationTestData({
                    url: '',
                    selectedEvent: '',
                    response: '',
                })
                setIsIntegrationTestOpen(false)
            }}
                   className="modal-dialog-centered delete-item" keyboard={true} backdrop="static">
                <ModalHeader toggle={() => {
                    setIntegrationTestData({
                        url: '',
                        selectedEvent: '',
                        response: '',
                    })
                    setIsIntegrationTestOpen(false)
                }}>
                    Test available integration events
                </ModalHeader>
                <ModalBody>
                    <FormGroup row>
                        <Label htmlFor="eventList" md={4}>Integration Event</Label>
                        <Col md={7}>
                            <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                name="eventList"
                                id="eventList"
                                className="custom-multiselect"
                                isSearchable={false}
                                isClearable={false}
                                onChange={handleTestChange('selectedEvent')}
                                optionRenderer={CustomOption}
                                options={integrationEvents}
                                value={integrationTestData.selectedEvent}
                                placeholder="Select Event"
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="" md={4}>Webhook URL</Label>
                        <Col md={7}>
                            <InputGroup>
                                <Input
                                    placeholder={'https://...'}
                                    value={integrationTestData.url}
                                    onChange={(e) => setIntegrationTestData({
                                        ...integrationTestData,
                                        url: e.target.value
                                    })}
                                    type="text"
                                />
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    {
                        integrationTestData.response &&
                        <p>
                            <strong>Test Response:</strong>
                            <br/>
                            <pre>
                                {integrationTestData.response}
                            </pre>
                        </p>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={onTestNewIntegration}>Test</Button>
                </ModalFooter>
            </Modal>
            <PremiumModal isOpen={isPremiumOpen} toggle={() => setIsPremiumOpen(!isPremiumOpen)}/>
        </div>
    )
}

export default Integrations
