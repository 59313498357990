import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import '../UserPendingModal/user-pending-modal.scss'
import {Link} from "react-router-dom";
import {useAuthContext} from "../../contexts/auth";

const UpgradeSubscriptionModal = ({ isMessages }) => {
    const { unread } = useAuthContext()
    return (
        <>
            <Header />

            <div className="" style={{minHeight: '260px', height: 'calc(100vh - 220px)', backgroundColor: 'rgba(60, 60, 60, 0.5)', position: 'relative'}}>
                <img className="background"
                     src="/images/bg-signup.png" alt="background"/>
                <div className="row container margin_center pending-user-modal" style={{maxWidth: 800, margin: '0 auto'}}>

                    <div className="col-xl-12 mt-4">
                        <div className="profile-bx">
                            <div className="profile-form mt-4 mb-4 advanced-search-console">

                                <h1 className="h1 mt-1">Premium Subscription Required</h1>
                                <p>
                                    {
                                        isMessages && unread
                                            ? `You have ${unread} unread messages, `
                                            : 'Sorry, '
                                    }
                                    but this content is available for premium subscribers only. Please upgrade your subscription to access this feature and more.
                                    <br/>
                                    <br/>
                                    <Link
                                        style={{paddingLeft: 0}} to={"/user/subscription"}
                                        className="nav-link">
                                        Learn More
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default UpgradeSubscriptionModal
