import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

import { isTokenExpired } from './Token';
import Header from './Header';
import Footer from './Footer';
import {withRouter} from "../containers/withRouter/withRouter";

class Setpassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
			errors: {},
			buyerReview: true,
			reason_to_join: '',
			reason_form: true,
		};
		this.SubmitCreatePass = this.SubmitCreatePass.bind(this);
	}
	componentDidMount() {
		if (localStorage.getItem('token') && localStorage.getItem('loggedIn') === "true" && !isTokenExpired()) {
			this.props.history.push('/home');
		}
	}
	handleValidation() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		//newpass
		if (!fields["newpass"]) {
			formIsValid = false;
			errors["newpass"] = "Please enter password.";
		}
		if (typeof fields["newpass"] !== "undefined") {
			if (!fields["newpass"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)) {
				formIsValid = false;
				errors["newpass"] = "Passwords must contain at least 6 characters, including uppercase, lowercase letters and numbers.";
			}
		}
		//confirmpass
		if (!fields["confirmpass"]) {
			formIsValid = false;
			errors["confirmpass"] = "Please confirm password";
		}
		if (typeof fields["confirmpass"] !== "undefined") {
			if (fields["confirmpass"] !== fields["newpass"]) {
				formIsValid = false;
				errors["confirmpass"] = "This field does not match password.";
			}
		}
		this.setState({ errors: errors });
		return formIsValid;
	}
	handleChange(field, e) {
		let fields = this.state.fields;
		fields[field] = e.target.value;
		this.setState({ fields });
	}
	SubmitCreatePass = event => {
		event.preventDefault();
		if (this.handleValidation()) {
			document.getElementById('loaderpopup').setAttribute("style", "display: block;");
			let machingId = localStorage.getItem("machingId")
			const user_type_id = localStorage.getItem("user_type_id")
			let newpassword = this.state.fields["newpass"];
			let verification_code = localStorage.getItem("verification_code")
			let jsonsetpassData = JSON.stringify({
				"id": machingId,
				"newpassword": newpassword,
				"verification_code": verification_code,
				user_type_id,
			});
			//console.log(jsonsetpassData);
			fetch(process.env.REACT_APP_API_ROOT + 'user/setUserpassword', {
				method: 'POST',
				body: jsonsetpassData,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					"Access-Control-Allow-Origin": "*"
				},
			}).then((res) => {
				if (res.status < 200 || res.status >= 300) {
					//console.log("Error 401");
					//console.log(res);
					if (res.status === 401) {
						this.props.history.push('/login');
					} else {
						return res.json().then(err => { throw err; });
					}
				} else { return res.json() }
			})
				.then(
					(res) => {
						if (res.data === 1) {
							document.getElementById('loaderpopup').setAttribute("style", "display: none;");
							this.setState({
								buyerReview: true,
							})
							// if(parseInt(user_type_id) === 1) {
							// 	document.getElementById('loaderpopup').setAttribute("style", "display: none;");
							// 	this.setState({
							// 		buyerReview: true,
							// 	})
							// } else {
							// 	var ip = localStorage.getItem("ip");
							// 	const email = localStorage.getItem("email");
							// 	localStorage.clear();
							// 	localStorage.setItem('ip', ip);
	
							// 	fetch(process.env.REACT_APP_API_ROOT + 'user/signin', {
							// 		method: 'POST',
							// 		body: JSON.stringify({
							// 			email,
							// 			password: newpassword,
							// 			ip_address: localStorage.getItem("ip")
							// 		}),
							// 		headers: {
							// 			Accept: 'application/json',
							// 			'Content-Type': 'application/json',
							// 			"Access-Control-Allow-Origin": "*"
							// 		},
							// 	}).then(res => res.json())
							// 	.then(res => {
							// 		localStorage.setItem('token', res.data.token);
							// 			localStorage.setItem('email', email);
							// 			localStorage.setItem('franchiseStatus', res.data.franchise_status);
							// 			localStorage.setItem('loggedIn', true);
							// 			this.redirectToApp();
							// 	})
							// }
							
						}
						else {
							let errors = {};
							errors["confirmpass"] = "Something get wrong, please try again.";
							this.setState({ errors: errors });
						}
					},
					(error) => {
						this.setState({
							//error
						});
					}
				)
				.catch((err) => {
					//console.log(err);
				})
		}
	}

	reasonToJoin = () => {
		const machingId = localStorage.getItem("machingId");

		if(machingId && this.state.reason_to_join) {
			fetch(process.env.REACT_APP_API_ROOT + 'user/reason_to_join', {
				method: 'POST',
				body: JSON.stringify({
					id: machingId,
					reason_to_join: this.state.reason_to_join,
				}),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					"Access-Control-Allow-Origin": "*"
				},
			}).then(() => {
				this.setState({ reason_form: false });
			})
		}
	}

	render() {
		return (
			<>
			<Header />
			<section className="signup-section">
				<div className="loader bg-white" id="loaderpopup" style={{ display: 'none' }}><h3>Please Wait...</h3><div className="spiner"></div></div>
				<a href="/"><img src={process.env.PUBLIC_URL + '/images/biznexus-logo.png'} style={{ width: 250 }} alt="logo" className="d-block m-auto" /></a>
				{this.state.buyerReview === false && <div className="signup-bx">
					<h4 className="text-center">Create password</h4>
					<p className="text-center">Your password must have 6 characters in length, containing at least one <br />letter and one number.</p>
					<Form onSubmit={this.SubmitCreatePass}>
						<FormGroup>
							<Label for="newpass">Password</Label>
							<Input type="password" name="newpass" id="newpass" ref="newpass" autoFocus onChange={this.handleChange.bind(this, "newpass")} value={this.state.fields["newpass"]} />
							<span className="error ">{this.state.errors["newpass"]}</span>
						</FormGroup>
						<FormGroup>
							<Label for="confirmpass">Confirm Password</Label>
							<Input type="password" name="confirmpass" id="confirmpass" ref="confirmpass" onChange={this.handleChange.bind(this, "confirmpass")} value={this.state.fields["confirmpass"]} />
							<span className="error ">{this.state.errors["confirmpass"]}</span>
						</FormGroup>
						<Button type="submit" className="btn-bluedark mt-0">Create Password</Button>
					</Form>
				</div>}
				{this.state.buyerReview && 
					<div className="signup-bx">
						<h4 className="text-center">Pending Review</h4>
						<p className="text-center mb-4">Our team typically reviews applications within 24 hours, and we'll let you know as soon as it's official.</p>
						{this.state.reason_form && <h2 className="h2">Want to speed up the approval process?</h2>}
						{this.state.reason_form && <label>Tell us a little more about why you want to join our community</label>}
						{this.state.reason_form && <textarea value={this.state.reason_to_join} onChange={e => this.setState({ reason_to_join: e.target.value })} style={{ fontSize: 14 }} className="w-100 mb-3 reason_to_join" placeholder="This is optional, but will significantly speed up the approval process." rows={4}></textarea>}
						{this.state.reason_form && <div className="text-center">
							<button onClick={() => this.reasonToJoin()} className="btn btn-primary w-100">Send</button>
						</div>}
					</div>
				}
			</section>
			<Footer />
			</>
		);
	}
}
export default withRouter(Setpassword);
