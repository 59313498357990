import jQuery from "jquery";
import "bootstrap/dist/js/bootstrap.bundle";
import SimpleBar from "simplebar";

// $ = window.jQuery = jQuery;
window.SimpleBar = SimpleBar;

jQuery(function () {
	/* Enable tooltips  */
	/* **************** */

	// new window.JF_FeedbackEmbedButton({
	// 	buttonText: "Feedback!",
	// 	buttonFontColor: "#FFFFFF",
	// 	buttonBackgroundColor: "#3890DA",
	// 	buttonSide: "Bottom",
	// 	buttonAlign: "Left",
	// 	buttonIcon: "default",
	// 	base: "https://form.jotform.com/",
	// 	formId: 202604478476057
	// });

	setInterval(() => {



		// jQuery("[data-toggle=\"tooltip\"]").tooltip();
		if (document.querySelector(".homepage circle")) {
			const progressValue = document.querySelector("[data-progress]");
			const circle = document.querySelector("circle");
			const radius = circle.r.baseVal.value;
			const circumference = radius * 2 * Math.PI;
	
			circle.style.strokeDasharray = `${circumference} ${circumference}`;
			circle.style.strokeDashoffset = `${circumference}`;
	
			function setProgress (percent) {
				const offset = circumference - (percent / 100) * circumference;
				circle.style.strokeDashoffset = offset;
			}
	
			if(progressValue) {
				setProgress(progressValue.dataset.progress);
			}
			
		}
	
		/* Mobile Nav toggle */
		/* ************* */
		const $mobileNavToggles = document.querySelectorAll("[data-nav-toggle]");
	
		for (let i = 0; i < $mobileNavToggles.length; i++) {
			$mobileNavToggles[i].addEventListener(
				"click",
				(e) => {
					e.preventDefault();
					const $toggleBtn = jQuery(`[data-nav-toggle="${$mobileNavToggles[i].dataset.navToggle}"]`);
					const $nav = jQuery(`[data-nav="${$mobileNavToggles[i].dataset.navToggle}"]`);
	
					jQuery("[data-nav-toggle]").removeClass("active");
					$toggleBtn.addClass("active");
	
					jQuery("[data-nav]").removeClass("d-block").addClass("d-none");
					$nav.addClass("d-block");
				},
				true,
			);
		}
	
		/* Layout toggle */
		/* ************* */
	
		const $layoutToggles = document.querySelectorAll("[data-layout-activate]");
	
		for (let i = 0; i < $layoutToggles.length; i++) {
			$layoutToggles[i].addEventListener(
				"click",
				(e) => {
					const $activateLayoutBtn = jQuery(`[data-layout-activate="${$layoutToggles[i].dataset.layoutDeactivate}"]`);
					const $deactivateLayoutBtn = jQuery(`[data-layout-activate="${$layoutToggles[i].dataset.layoutActivate}"]`);
					const $activateLayout = jQuery(`${$layoutToggles[i].dataset.layoutActivate}`);
					const $deactivateLayout = jQuery(`${$layoutToggles[i].dataset.layoutDeactivate}`);
	
					$activateLayoutBtn.removeClass("text-dark");
					$activateLayoutBtn.addClass("text-secondary");
					$deactivateLayoutBtn.removeClass("text-secondary");
					$deactivateLayoutBtn.addClass("text-dark");
	
					$activateLayout.removeClass("d-none").addClass("d-block");
					$deactivateLayout.removeClass("d-block").addClass("d-none");
				},
				true,
			);
	
		}
	}, 2000)
	/* Profile completeness progress bar */
	/* ********************************* */
	

	

	/* Don't close bootstrap dropdown on click inside */
	/* ********************************************** */
	jQuery(document).on("click", ".homepage .dropdown-menu", function (e) {
		e.stopPropagation();
	});

	/* For chat scroll always to the bottom (latest messages) */
	/* ****************************************************** */
	if (jQuery(".js-chat-body-scroll-wrapper")) {
		jQuery.each(jQuery(".js-chat-body-scroll-wrapper"), function (index, value) {
			const simpleBar = new SimpleBar(value);
			const wrapperHeight = simpleBar.getScrollElement().offsetHeight;
			const contentHeight = simpleBar.getScrollElement().childNodes[0].offsetHeight;

			simpleBar.getScrollElement().scrollTop = contentHeight - wrapperHeight;
		});
	}
});
