import React, {useMemo, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import moment from "moment";
import './dealNotes.scss'
import api from "../../services/api";
import ConfirmationPopup from "../ConfirmationPopup/confirmationPopup";
import Textarea from 'react-expanding-textarea'

const DealNotes = ({notes, dealName, updateNotes, dealId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [noteBody, setNoteBody] = useState('');
    const [noteError, setNoteError] = useState('');
    const [loading, setLoading] = useState(false);

    const openNotes = () => {
        setIsOpen(true)
    }
    const closeNotes = () => {
        setIsOpen(false)
    }
    const closeConfirmation = () => {
        setIsConfirmationOpen(false)
    }

    const deleteNote = () => {
        api.post('listing_notes/delete', {id: deleteId}, true).then(() => {
            setDeleteId(null)
            setIsConfirmationOpen(false)
            updateNotes()
        })
    }

    const addNote = () => {
        if (noteBody.trim()) {
            setLoading(true)
            api.post('listing_notes/save', {text: noteBody.trim(), id: dealId}, true).then(() => {
                setNoteBody('')
                updateNotes().then( () => {
                    setLoading(false)
                })

            })
        } else {
            setNoteError('Note text is required')
        }

    }

    const onChange = (e) => {
        const body = e.target.value;
        setNoteBody(body);
        setNoteError(null)
    }

    const toggleDelete = (e, id) => {
        setDeleteId(id)
        setIsConfirmationOpen(true)
    }

    const sortedNotes = useMemo(() => notes.sort((noteA, noteB) => {
        if (noteA.created_at > noteB.created_at) {
            return 1
        } else if (noteA.created_at < noteB.created_at) {
            return -1
        } else {
            return 0
        }
    }), [notes])

    return (<div className="deal-notes_wrapper">
        <button type="button" className={`deal-notes btn ${sortedNotes.length ? 'btn-primary-blue' : 'btn-default'}`} onClick={openNotes}>
            {sortedNotes.length ? 'View' : 'Add'}
        </button>
        <Modal size="md" isOpen={isOpen} toggle={closeNotes}
               className="modal-dialog-centered deal-note-modal" keyboard={true} backdrop="static">
            <ModalHeader toggle={closeNotes}>
                <strong className="deal-notes_title">Your deal notes <span className="note-badge">{notes.length}</span></strong>
                <strong className="deal-notes_deal-name">{dealName}</strong>
            </ModalHeader>
            <ModalBody>
                {
                    sortedNotes.map(note => (<div className="note" key={note.id}>
                        <div className="note-content">
                            <span className="note-time">{note.created_at ? moment(new Date(note.created_at)).fromNow(): 'a long time ago'}</span>
                            <span className="note-body">{note.body}</span>
                        </div>
                        <span className="note-delete" onClick={e => toggleDelete(e, note.id)}>
                            <i className="fas fa-trash-alt"/>
                        </span>
                    </div>))
                }

            </ModalBody>
            <ModalFooter>
                <Textarea
                    disabled={loading}
                    value={noteBody}
                    onChange={onChange}
                    style={{ resize: 'none' }}
                    className={`form-control mb-3 ${!!noteError && 'invalid'}`} placeholder="Enter your own confidential, private note about this deal here." name="note" id="note" rows={3} />
                {!!noteError && <span className="error ">{noteError}</span>}
                <Button
                    className="btn btn-primary btn-deal-cta text-nowrap"
                    type="button"
                    disabled={loading}
                    onClick={addNote}
                >
                    Add New Note
                </Button>
            </ModalFooter>
        </Modal>
        <ConfirmationPopup
            title="Delete the note?"
            isOpen={isConfirmationOpen}
            onClose={closeConfirmation}
            onCancel={closeConfirmation}
            onAccept={deleteNote}
            acceptText="Delete"
            acceptClass="danger"
        />

    </div>)
}

export default DealNotes
