import { toast } from 'react-toastify';

const api = {
    get: async (url, body, withToken = false) => {
        return await apiHandler(url, 'GET', body, withToken);
    },
    post: async (url, body, withToken = false) => {
        return await apiHandler(url, 'POST', body, withToken);
    },
    put: async (url, body, withToken = false) => {
        return await apiHandler(url, 'PUT', body, withToken);
    },
    delete: async (url, body, withToken = false) => {
        return await apiHandler(url, 'DELETE', body, withToken);
    },
};

async function apiHandler(url, method, body, withToken = false) {

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    };


    if(withToken) {
        headers['x-access-token'] = localStorage.getItem('token');
    }

    if(body) {
        body = JSON.stringify(body);
    }

    return await fetch(process.env.REACT_APP_API_ROOT + url, {
        method,
        body,
        headers,
    }).then((res) => {
        if (res.status < 200 || res.status >= 300) {
            throw res;
        } else {
            return res.json()
        }
    }).catch(err => {
        if (err.status === 401) {
            window.location.replace(`${window.location.origin}/logout`)
        }
        err.text().then( errorMessage => {
            
            const error = JSON.parse(errorMessage);
            if(error.message && error.message.includes('upgrade to Premium')) {
                toast.error(error.message, {
                    position: "bottom-right",
                    className: 'toast-bar',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    });

}

export default api;
