import React from 'react';
import api from '../../services/api';
import { Table, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from "moment";

export default class Concierge extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      premiums: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    api.get('console/concierge', null, true).then(premiums => {
      this.setState({ premiums });
    });
  }

  loginAs = email => {
    api.post('console/account', { email }, true).then(res => {
      if (res.success) {
        localStorage.setItem('admin_token', localStorage.getItem('token'));
        localStorage.setItem('token', res.token);
        window.location.href = '/home';
      }
    })
  }

  render() {

    return (
        <div>
          <Link to="/console">&larr; Back</Link>
          <br /><br />
          <Row>
            <Col sm="12">
              <h2>Concierge</h2>
              <Table bordered hover responsive>
                <thead style={{ textAlign: 'center' }}>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>LinkedIn</th>
                  <th>Concierge Started</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {this.state.premiums.map(premium =>
                    <tr style={{ textAlign: 'center' }}>
                      <td>{premium.user ? premium.user.name : null}</td>
                      <td><button className="btn btn-sm btn-primary" onClick={() => this.loginAs(premium.user.email)}>{premium.user ? premium.user.email : null}</button></td>
                      <td style={{ width: 300, wordBreak: 'break-word' }}><a target="_blank" rel="noopener noreferrer" href={`${premium.user ? premium.user.linkedin_url : null}`}>{premium.user ? premium.user.linkedin_url : null}</a></td>
                      <td>{moment(new Date(premium.created_at)).format('LLL')}</td>
                      <td><Link to={`/console/concierge-links/${premium.user ? premium.user.id : null}`} className="btn btn-sm btn-info">Links</Link></td>
                    </tr>
                )}

                </tbody>
              </Table>
            </Col>

          </Row>
        </div>
    )
  }
}
