import React from 'react';
class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
      window.location = 'https://www.biznexus.com/404'
  }

    render() {
    return (
     <div className="text-center mt-5"/>
    );
  }
}
export default NotFound;
