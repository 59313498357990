export const professionLabels = {
    1: 'I want to buy a business',
    2: 'I want to sell a business',
    3: 'I\'m a business broker / intermediary',
    4: 'I\'m a service provider / vendor'
};

export const professionSubCategories = {
    1: ['Individual Buyer', 'Search Fund', 'Fundless Sponsor', 'Private Equity Fund', 'Family Office', 'Strategic Acquirer'],
    2: [],
    3: [],
    4: ['Business Appraiser', 'Business Consultant', 'Insurance Agent', 'Lender', 'Lawyer', 'Accountant', 'Financial Advisor', 'Other']
};

export const USER_TYPE = {
    BUYER: 1,
    SELLER: 2,
    BROKER: 3,
    PROVIDER: 4
};

export const ADMIN_ROLES = {
    NotAdmin: null,
    SuperAdmin: 1,
    Moderator: 10
};

export const ADMIN_ROLES_LABEL = {
    1: 'Super Admin',
    10: 'Moderator',
};

export const USER_TYPE_LABEL = {
    1: 'Buyer',
    2: 'Seller',
    3: 'M&A Advisor',
    4: 'Provider'
};

export const USER_TYPE_LABEL_FROGGED = {
    1: 'Buyer',
    2: 'Seller',
    3: 'Business Broker',
    4: 'Provider'
};
