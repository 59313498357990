import React, {useEffect, useState} from 'react'
import {Collapse} from "reactstrap";
import './industry-filter.scss'
import ArrowIcon from "../../../icons/ArrowIcon";

const IndustryFilter = ({selectedIndustries, onRemoveIndustry, industriesList, updateIndustries}) => {
    const [filteredIndustries, setFilteredIndustries] = useState(industriesList)
    const [filterValue, setFilterValue] = useState('')
    const [toggles, setToggles] = useState({})

    useEffect(() => {
        setFilteredIndustries(industriesList)
    }, [industriesList])

    const onIndustryClick = (item) => () => {
        setToggles({...toggles, [item.value]: !toggles[item.value]})
    }
    const onArrowClick = item => (e) => {
        e.stopPropagation()
        e.preventDefault()
        if (item.children.length)
            setToggles({...toggles, [item.value]: !toggles[item.value]})
    }

    const getItemClassName = (item) => {
        return selectedIndustries.some(i => i.value === item.value)
            ? 'selected'
            : selectedIndustries.some(i => item.children && item.children.some(c => c.value === i.value))
                ? 'partial'
                : ''
    }
    const handleCheck = item => (e) => {
        e.stopPropagation()
        e.preventDefault()
        let items = [...selectedIndustries]
        const selectedParent = selectedIndustries.find(i => i.children && i.children.some(c => c.value === item.value))
        if (selectedIndustries.some(i => i.value === item.value)) {
            items = items.filter(i => i.value !== item.value)
        } else if (selectedParent) {
            items = items.filter(i => i.value !== selectedParent.value)
            selectedParent.children.forEach(c => {
                if (c.value !== item.value) {
                    items.push(c)
                }
            })
        } else {
            items = items.filter(i => !item.children || !item.children.some(c => c.value === i.value))
            items.push(item)
        }
        updateIndustries(items)
    }

    const filterIndustry = value => {
        if (!value) {
            setFilteredIndustries(industriesList)
        }
        setFilterValue(value)

        if (value.length < 3) return
        const lowerCaseValue = value.toLowerCase()
        const newToggles = {}
        const newFilteredIndustries = industriesList
            .map(industry => industry.children
                ? {
                    ...industry,
                    children: industry.children.filter(subIndustry => subIndustry.label.toLowerCase().includes(lowerCaseValue))
                }
                : industry)
            .filter(industry => {
            if (industry.children &&
                industry.children.some(subIndustry => subIndustry.label.toLowerCase().includes(lowerCaseValue))) {
                newToggles[industry.value] = true;
                return true
            }

            return industry.label.toLowerCase().includes(lowerCaseValue);
        }

        )
        setFilteredIndustries(newFilteredIndustries)
        setToggles({
            ...toggles,
            ...newToggles,
        })
    }

    return (
        <div className="industry-filter">
            {
                !!selectedIndustries.length && <div className="row align-items-center row-gutter-10 p-2">
                    {selectedIndustries.map(bi => <div
                        key={`selected-${bi.value}`}
                        className="col-auto mb-2">
                        <button onClick={(e) => e.preventDefault()}
                                className="btn btn-sm btn-primary-blue btn-text-xsm">
                            <span className="me-2">{bi.label}</span>
                            <svg onClick={() => onRemoveIndustry(bi)}
                                 className="icon" viewBox="0 0 14 13" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                      fill="currentColor"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                      fill="currentColor"/>
                            </svg>
                        </button>
                    </div>)}
                </div>
            }
            <div className="industry-search-section">
                {
                    filterValue && <span className="close-btn" onClick={() => filterIndustry('')}>×</span>
                }
                <input className="form-control py-2 border-right-0 search-header" type="text"
                       onChange={(e) => filterIndustry(e.target.value)}
                       value={filterValue} maxLength={100}
                       placeholder={'Search Industry'}/>
                {!!(filterValue.length && filterValue.length < 3) && <span className="hint">Please, input 3 symbols minimum</span>}
            </div>

            <ul className="industry-list">
                {filteredIndustries.map(item => <>
                    <li onClick={onIndustryClick(item)}
                        key={`industry-${item.value}`}
                        className={`dropdown-item ${getItemClassName(item)}`}
                    >
                        <div className="dropdown-item_content">
                            <div className="folder-icon-wrapper"
                                 onClick={item.children.length > 0 ? onArrowClick(item) : onIndustryClick(item)}
                                 style={{cursor: 'pointer'}}
                            >
                            <span
                                style={{visibility: item.children.length > 0 ? 'visible' : 'hidden'}}
                                className={`folder-icon ${toggles[item.value] && 'open'}`}>
                                <ArrowIcon/>
                            </span>
                            </div>
                            <p className="industry-name">
                                {item.label}
                            </p>

                            <span className="checked-icon" onClick={handleCheck(item)}/>
                        </div>

                        {item.children.length > 0 && <Collapse isOpen={toggles[item.value]}>
                            <ul className="sub-list">
                                {item.children.map(c =>
                                    <li
                                        key={`sub-${c.value}`}
                                        className={`dropdown-item ${selectedIndustries.some(i => i.value === c.value || i.value === item.value) ? 'selected' : ''}`}
                                        onClick={handleCheck(c)}
                                    >
                                        <p className="industry-name">
                                            {c.label}
                                        </p>

                                        <span className="checked-icon"/>
                                    </li>
                                )}
                            </ul>
                        </Collapse>
                        }
                    </li>
                </>)}
            </ul>
            {
                !!industriesList.length && !filteredIndustries.length && <p>No industries found</p>
            }
        </div>
    )
}

export default IndustryFilter
