import React from 'react';
import {
    Row,
    Col,
    TabContent, TabPane, Nav, NavItem, NavLink, Badge, Button, UncontrolledTooltip
} from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';
import Geosuggest from '@ubilabs/react-geosuggest';
import classnames from 'classnames';
import Slider from 'react-slick';
import { LinkedinShareButton, LinkedinIcon, FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from 'react-share';
import _ from 'lodash';
import api from '../../services/api';
import { getImageUrl } from '../util';
import {Link} from 'react-router-dom';
import SearchComponent from './search';

const SliderArrow = ({className, to, onClick}) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
      {to === 'next' ? <i style={{ fontSize: '48px', color: '#3890da', opacity: className.includes('slick-disabled') ? '0.3': '1' }} className="fas fa-angle-double-right" /> :
      <i style={{ fontSize: '48px', color: '#3890da', opacity: className.includes('slick-disabled') ? '0.3': '1' }} className="fas fa-angle-double-left" />
      }
    </button>
  )

export default class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: {},
            listings: [],
            recently: [],
            brokers: [],
            search: [],
            favorites: [],
            openSavedSearch: false,
            posts: [],
            categories: [],
            rlistings: [],
            selectedCategory: null,
            // profile: {},
            activeTab: 1,
            locationOpen: false,
            location: {
                label: 'Anywhere',
                location: {
                    lat: '',
                    lng: '',
                },
            },
            categoryOption: {
                value: "Any",
                label: "Any",
            },
            brokerLoading: false,
            recentResult: [],
            recentSearches: [],
        };
    }

    isValidJSON = text => {
        try { 
          const jn = JSON.parse(text); 
          if(jn) {
            return true; 
          } else {
            return false;
          }
        } catch (error) { 
          return false; 
        } 
      }

    init = () => {
        this.getPosts();

        api.get('user/editprofile', null, true)
            .then(profile => this.setState({ profile }));

        api.get('home/broker_listings', null, true)
            .then(listings => this.setState({ listings }));  
            
            api.get('user/saved_listings', null, true)
            .then(favorites => this.setState({ favorites }));

        api.get('user/editprofile', null, true)
            .then(profile => this.setState({ profile }));

        api.get('home/listings', null, true)
            .then(listings => this.setState({ rlistings: listings }));

        api.get('home/recently_added', null, true)
            .then(recently => this.setState({ recently }));

        api.get('home/search', null, true)
            .then(search => this.setState({ search })); 

        api.get('industry/legacy', null, true)
            .then(res => {
                let categorylistarray = [];
                let temp = {};
                temp = {
                    "value": "All",
                    "label": "Any"
                };
                categorylistarray.push(temp);
                // eslint-disable-next-line
                res.map((categories, i) => {
                    temp = {
                        "value": categories.value,
                        "label": categories.label,
                        "children": categories.children.map(c => c.value)
                    };
                    categorylistarray.push(temp);
                });
                this.setState({ categorylist: categorylistarray });
            });
            
        this.getRecent(); 
        this.getBrokersList();  
    }

    componentDidMount() {
        this.init();
    }

    getRecent = async () => {
        const recentResult = [];
        const recentSearches = localStorage.getItem('recent_searches') ? JSON.parse(localStorage.getItem('recent_searches')) : [];
        for(const recent of recentSearches) {
            const result = await api.post('transaction/search', JSON.parse(recent), false);
            recentResult.push(result.list);
        }

        this.setState({ recentResult, recentSearches });
    }

    getBrokersList(industry = [], location = {location: { lat: '', lng: '' }}) {
        if( ! location.location) {
            // return null;
        }
        return api.get(`home/brokers?industry=${industry.join(',')}&lat=${location.location.lat}&lng=${location.location.lng}`, null, true)
            .then(brokers => {
                const nbrokers = brokers.map(b => {
                    return {
                        ind_string: b.industries_of_expertise && this.isValidJSON(b.industries_of_expertise) ? _.compact(JSON.parse(b.industries_of_expertise).slice(0, 3).map(i => i.name)) : [],
                        ...b
                    }
                })
                this.setState({ brokers: nbrokers, brokerLoading: false });
            });
    }

    handleCategoryChange(v) {
        this.setState({ categoryListOpen: false, categoryOption: v, brokerLoading: true }, () => {
            this.getBrokersList(this.state.categoryOption.children && this.state.categoryOption.children.length ? [this.state.categoryOption.value].concat(this.state.categoryOption.children) : [], this.state.location);
        });
    }

    renderCategoryList() {

        if(this.state.categoryListOpen) {
            return (
                <div style={{ width: '200px', display: 'inline-block', position: 'relative', top: '10px' }}>
                    <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                        menuIsOpen={true}
                        openOnFocus
                        style={{ display: 'inline-block' }}
                        isSearchable={false}
                        isClearable={false}
                        autoFocus={true}
                        onChange={(v) => this.handleCategoryChange(v)}
                        options={this.state.categorylist}
                        value={this.state.categoryOption}
                        noOptionsMessage="Any"
                        placeholder="Any"
                        ref={el => (this.selectRef =el)}
                        onBlur={() => {
                            this.setState({ categoryListOpen: false });
                        }}
                    />
                </div>
            )
        } else {
            return (
                <div onClick={() => this.setState({ categoryListOpen: true })} style={{ display: 'inline-block', cursor: 'pointer', color: 'rgb(56, 144, 218)', borderBottom: '3px dotted' }}>{this.state.categoryOption.label}</div>
            )
        }
    }

    handleGeosuggestChange(l) {
        this.setState({ locationOpen: false, location: l, brokerLoading: true }, () => {
            this.getBrokersList(this.state.categoryOption.children && this.state.categoryOption.children.length ? [this.state.categoryOption.value].concat(this.state.categoryOption.children) : [], this.state.location);
        });
    }

    renderLocation() {
        if(this.state.locationOpen) {
            return (
                <div style={{ width: '200px', display: 'inline-block', position: 'relative' }}>
                    <Geosuggest
                        inputClassName="search-input form-control"
                        placeholder={(this.state.location && this.state.location.label) || 'Anywhere'}
                        autoFocus={true}
                        onBlur={() => {
                            this.setState({ locationOpen: false });
                        }}
                        onSuggestSelect={(l) => this.handleGeosuggestChange(l)}
                    />
                </div>
            )
        } else {
            return (
                <span onClick={() => this.setState({ locationOpen: true })} style={{ cursor: 'pointer', color: 'rgb(56, 144, 218)', borderBottom: '3px dotted' }}>{(this.state.location && this.state.location.label) || 'Anywhere'}</span>
            )
        }
        
    }

    makeSearchQueryString = searchData => {
        delete searchData.rows;
        // in future if this field will be added just remove line below
        delete searchData.orderby;

        let output = '';
        for(let property in searchData){
            if(searchData.hasOwnProperty(property) && searchData[property]){
                if(!output){
                    output = '?'
                } else {
                    output += '&'
                }
                output += property + '=' +  searchData[property];
            }
        }
        return output;
    };

    printRecentSearchName = search => {
        const obj = JSON.parse(search);

        return `${obj.categoryOption} ${obj.location} ${obj.name ? obj.name: ''}`;
    }

    getPosts = async () => {
        document.getElementById('loader').setAttribute("style", "display: block;");
        return await api.get('home/posts', null, true)
            .then(posts => {
                const categories = [];
                posts.forEach(p => {
                    if(p.category) {
                        categories.push(...p.category.split(','))
                    }
                })
                
                const uniqueCategories = [...new Set(categories)];
                this.setState({ posts, categories: uniqueCategories });
                document.getElementById('loader').setAttribute("style", "display: none;");
            });
    }

    sliceText(text) {
        return text && text.length >= 100 ? text.slice(0, 100) + '...' : text;
    }

    selectCategory = async c => {
        let cat = null;
        if(c === this.state.selectedCategory) {
            this.setState({ selectedCategory: null });
            this.getPosts();
            return;
        } else {
            cat = c;
            this.setState({ selectedCategory: c });
            await this.getPosts();
        }
        
        const filterPosts = cat ? this.state.posts.filter(p => {
            if(p.category) {
                return p.category.split(',').includes(cat)
            } else {
                return false;
            }
            
        }): this.state.posts;
        this.setState({ posts: filterPosts });
    }

    render() {
        let firstname = '';
        if(this.state.profile.name) {
            let fullname = this.state.profile.name.match(/(\w+)(?:\s[^\s]+)?\s(\w+).*/);
            if (fullname !== null) {
                firstname = fullname[1];
            } else {
                firstname = this.state.profile.name;
            }
        }

        const settings = {
            dots: false,
            infinite: true,
            speed: 200,
            prevArrow: <SliderArrow to="prev" />,
            nextArrow: <SliderArrow to="next" />,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        };
       
        return (
            <div className="container" style={{ width: '1000px' }}>
                <Row>
                    <Col sm="4">
                        <h3 style={{ color: 'grey' }}>Welcome, {firstname}</h3>
                    </Col>
                    <Col sm="4" className="text-center">
                        {/* <span>Earn a free premium subscription!</span>
                        <Button style={{ marginTop: '7px', backgroundColor: 'rgb(56, 144, 218)' }} onClick={() => window.location.href = '/edit-profile/referral-program'} color="primary">
                            Learn More
                        </Button> */}
                    </Col>
                    <Col sm="4">
                        <h3 style={{ paddingTop: '5px', paddingLeft: '100px' }}>
                            <Link style={{ color: 'grey' }} to={'/edit-profile/profile'}>Edit profile &rarr;</Link>
                        </h3>
                    </Col>
                </Row>

                <Row style={{ marginTop: '0px', display: 'block' }} className="text-center">
                    <img style={{ height: '250px' }} src={process.env.PUBLIC_URL + '/images/Broker_Homepage_Header.png'} class="img-fluid" alt="" />
                </Row>

                <Row className="text-center">
                    <Col sm="12">
                        <Button style={{ backgroundColor: 'rgb(56, 144, 218)' }} onClick={() => window.location.href = '/edit-profile/ads'} color="primary">Advertise</Button>
                        <Button style={{ marginLeft: '12px', backgroundColor: 'rgb(56, 144, 218)' }} onClick={() => window.location.href = '/edit-profile/listings'} color="primary">Add Your Listings</Button>
                        <Button style={{ marginLeft: '12px', backgroundColor: 'rgb(56, 144, 218)' }} onClick={() => window.location.href = '/edit-profile/favorites'} color="primary">Buy-Side Tools</Button>
                    </Col>     
                </Row>


                <Row style={{ paddingTop: '30px' }}>
                    <Col sm="12">
                        <Nav tabs>
                            <NavItem style={{ cursor: 'pointer', width: '33%' }}>
                                <NavLink className={classnames({ active: this.state.activeTab === 1 })}
                                            onClick={() => { this.setState({ activeTab: 1 }) }}
                                            style={{ color: '#3890da', fontSize: '16px', fontWeight: '700' }}>
                                    Share Content
                                </NavLink>
                            </NavItem>
                            <NavItem style={{ cursor: 'pointer', width: '33%' }}>
                                <NavLink className={classnames({ active: this.state.activeTab === 3 })}
                                            onClick={() => { this.setState({ activeTab: 3 }) }}
                                            style={{ color: '#3890da', fontSize: '16px', fontWeight: '700' }}>
                                    Buy-Side Matches
                                </NavLink>
                            </NavItem>
                            <NavItem style={{ cursor: 'pointer', width: '33%' }}>
                                <NavLink className={classnames({ active: this.state.activeTab === 2 })}
                                            onClick={() => { this.setState({ activeTab: 2 }) }}
                                            style={{ color: '#3890da', fontSize: '16px', fontWeight: '700' }}>
                                    Join & Engage With Business Groups
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId={1}>
                                <div style={{ paddingTop: '20px', paddingBottom: '50px' }}>
                                    <div style={{ display: 'inline-flex' }}>
                                        {this.state.categories.map(c => 
                                            <h4 style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => this.selectCategory(c)}><Badge style={this.state.selectedCategory !== c ? { backgroundColor: 'rgb(56, 144, 218)', color: '#fff'} : {} } color={this.state.selectedCategory === c ? 'success': ''}>{c}</Badge></h4>
                                        )}
                                    </div>
                                    <div className="loader bg-white" id="loader" style={{display: 'none'}}>
                                        <div className="spiner"></div>
                                    </div>
                                    {this.state.posts.slice(0, 3).map(p => <div key={p.id}>
                                    <div className="sale-listingview" style={{ marginTop: '50px' }}>
                                        <div className="sale-listing">
                                        <Row>
                                                <Col md="3">
                                                    <div className="listing-img">
                                                        <img width="240px" height="130px" src={p.image ? p.image : process.env.PUBLIC_URL + "/images/noimage.png"} alt="" />
                                                    </div>
                                                </Col>
                                                <Col md="7" style={{ height: '130px' }}>
                                                    <a href={p.link} target="_blank" rel="noopener noreferrer" className="sale-text-title mb-3">{p.name}</a>
                                                    <p className="mb-0">{this.sliceText(p.text)}</p>
                                                    {p.category && p.category.split(',').map(c =>
                                                        <Badge style={{ marginRight: '5px' }}>{c}</Badge>
                                                    )}
                                                    <p style={{ fontSize: '12px', bottom: '0px', position: 'absolute' }}>{p.created_at ? moment(new Date(p.created_at)).format('ddd MM/DD/YY, h:mm A') : ''}</p>
                                                </Col>
                                                <Col md="2" className="text-center">
                                                    <b style={{ paddingBottom: '5px' }}>Share Now!</b><br /><br />
                                                    <LinkedinShareButton url={p.link}>
                                                        <LinkedinIcon size={32} round={true} />
                                                    </LinkedinShareButton>
                                                    <FacebookShareButton url={p.link} style={{ marginLeft: '10px' }}>
                                                        <FacebookIcon size={32} round={true} />
                                                    </FacebookShareButton>
                                                    <TwitterShareButton url={p.link} style={{ marginLeft: '10px' }}>
                                                        <TwitterIcon size={32} round={true} />
                                                    </TwitterShareButton>
                                                </Col>
                                            </Row>
                                        </div>
                                        
                                        </div>
                                    </div>)}

                                    {!this.state.selectedCategory && this.state.listings.map(p => <div key={p.id}>
                                    <div className="sale-listingview" style={{ marginTop: '50px' }}>
                                        <div className="sale-listing">
                                        <Row>
                                                <Col md="3">
                                                    <div className="listing-img">
                                                        <img width="240px" height="130px" src={(p.image !== null && p.image !== '') ? getImageUrl(p.image, true) : process.env.PUBLIC_URL + "/images/noimage.png"} alt="" />
                                                    </div>
                                                </Col>
                                                <Col md="7">
                                                    <a href={`${process.env.REACT_APP_ROOT}business-for-sale/${p.transaction_slug}`} target="_blank" rel="noopener noreferrer" className="sale-text-title mb-3">{p.name}</a>
                                                    <p className="mb-0">{this.sliceText(p.description)}</p>
                                                </Col>
                                                <Col md="2" className="text-center">
                                                    <b style={{ paddingBottom: '5px' }}>Share Now!</b><br /><br />
                                                    <LinkedinShareButton url={`${process.env.REACT_APP_ROOT}business-for-sale/${p.transaction_slug}`}>
                                                        <LinkedinIcon size={32} round={true} />
                                                    </LinkedinShareButton>
                                                    <FacebookShareButton url={`${process.env.REACT_APP_ROOT}business-for-sale/${p.transaction_slug}`} style={{ marginLeft: '10px' }}>
                                                        <FacebookIcon size={32} round={true} />
                                                    </FacebookShareButton>
                                                    <TwitterShareButton url={`${process.env.REACT_APP_ROOT}business-for-sale/${p.transaction_slug}`} style={{ marginLeft: '10px' }}>
                                                        <TwitterIcon size={32} round={true} />
                                                    </TwitterShareButton>
                                                </Col>
                                            </Row>
                                        </div>
                                        
                                        </div>
                                    </div>)}


                                    {this.state.posts.slice(3).map(p => <div key={p.id}>
                                    <div className="sale-listingview" style={{ marginTop: '50px' }}>
                                        <div className="sale-listing">
                                        <Row>
                                                <Col md="3">
                                                    <div className="listing-img">
                                                        <img width="240px" height="130px" src={p.image ? p.image : process.env.PUBLIC_URL + "/images/noimage.png"} alt="" />
                                                    </div>
                                                </Col>
                                                <Col md="7" style={{ height: '130px' }}>
                                                    <a href={p.link} target="_blank" rel="noopener noreferrer" className="sale-text-title mb-3">{p.name}</a>
                                                    <p className="mb-0">{this.sliceText(p.text)}</p>
                                                    {p.category && p.category.split(',').map(c =>
                                                        <Badge style={{ marginRight: '5px' }}>{c}</Badge>
                                                    )}
                                                    <p style={{ fontSize: '12px', bottom: '0px', position: 'absolute' }}>{p.created_at ? moment(new Date(p.created_at)).format('ddd MM/DD/YY, h:mm A') : ''}</p>
                                                </Col>
                                                <Col md="2" className="text-center">
                                                    <b style={{ paddingBottom: '5px' }}>Share Now!</b><br /><br />
                                                    <LinkedinShareButton url={p.link}>
                                                        <LinkedinIcon size={32} round={true} />
                                                    </LinkedinShareButton>
                                                    <FacebookShareButton url={p.link} style={{ marginLeft: '10px' }}>
                                                        <FacebookIcon size={32} round={true} />
                                                    </FacebookShareButton>
                                                    <TwitterShareButton url={p.link} style={{ marginLeft: '10px' }}>
                                                        <TwitterIcon size={32} round={true} />
                                                    </TwitterShareButton>
                                                </Col>
                                            </Row>
                                        </div>
                                        
                                        </div>
                                    </div>)}
                                </div>
                            </TabPane>

                            <TabPane tabId={3}>

                            <Row style={{ paddingTop: '10px' }}>
                                <SearchComponent notHomePage={true} brokerHome={true} />
                            </Row>

                            <Row style={{ paddingTop: '35px' }}>
                                <Col sm="12">
                                    <h4 style={{ fontWeight: '600' }}>Popular Searches</h4>
                                </Col>
                            </Row>

                            <div className="deal-view" style={{ display: 'inline-flex' }}>
                                <a className="btn-default" href="/advanced-search/?page=1">Most Recent</a>
                                <a style={{ marginLeft: '10px' }} className="btn-default" href="/advanced-search/?date_added=all&industry_id=1293401&page=1">Tech Deals</a>
                                <a style={{ marginLeft: '10px' }} className="btn-default" href="/advanced-search/?cashflow_min=500000&date_added=all&page=1">Search Fund Deals</a>
                                <a style={{ marginLeft: '10px' }} className="btn-default" href="/advanced-search/?page=1&price_max=50000&revenue_max=500000">Under $50k</a>
                                <a style={{ marginLeft: '10px' }} className="btn-default" href="/advanced-search/?name=home-based&page=1">Home-based</a>
                                <a style={{ marginLeft: '10px' }} className="btn-default" href="/advanced-search/?name=medical&page=1">Medical</a>
                            </div>

                            <Row style={{ paddingTop: '35px' }}>
                                    <Col sm="8">
                                        <h4 style={{ fontWeight: '600' }}>Recently Added Opportunities</h4>
                                    </Col>
                                    <Col sm="4">
                                        <h5 style={{ paddingTop: '3px', textAlign: 'right' }}>
                                            <Link style={{ color: 'grey' }} to={'/advanced-search'}>Browse All &rarr;</Link>
                                        </h5>
                                    </Col>
                            </Row>

                                <div className="deal-view">
                                        
                                        <div className="featured-franchises">
                                            <Slider {...settings}>
                                                {this.state.recently.map((t, i) => (
                                                    <div key={i} className="franchises-bx">
                                                        <a href={`/business-for-sale/${t.transaction_slug}`} target="_blank" rel="noopener noreferrer">
                                                        <div style={{ height: '193px' }}><img src={(t.image !== null && t.image !== '')
                                                                ? getImageUrl(t.image, true)
                                                                : process.env.PUBLIC_URL + '/images/empty.jpg'
                                                            } className="img-fluid" alt="" style={{ height: '193px' }} /></div>
                                                        <div className="franchises-details" style={{ height: '345px' }}>
                                                            <h6>{this.sliceText(t.name, 20)}</h6>
                                                            {(t.location_address !== '' && t.location_address !== null) && <p className="listing-location"><i className="sprite i-listing-locator"></i>{t.location_address}</p>}
                                                            <p className="text-font-light">{t.description && this.sliceText(t.description, 50)}</p>
                                                            <p className="capital-req">Asking Price: <span className="text-blue">{t.hide_price ? 'Not Specified' : t.price}</span></p>
                                                            {t.cashflow && <p className="capital-req">Cash Flow: <span className="text-blue">{t.cashflow}</span></p>}
                                                            {t.revenue && <p className="capital-req">Revenue: <span className="text-blue">{t.revenue}</span></p>}
                                                            {t.ebitda && <p className="capital-req">Revenue: <span className="text-blue">{t.ebitda}</span></p>}
                                                            <a style={{ bottom: '15px', position: 'absolute', width: '188px' }} href={`/business-for-sale/${t.transaction_slug}`} target="_blank" rel="noopener noreferrer" className="btn-default mt-3">Review Now</a>
                                                        </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    
                                </div>


                                

                                

                                <Row style={{ paddingTop: '35px' }}>
                                    <Col sm="8">
                                        <h4 style={{ fontWeight: '600' }}>Recent Searches</h4>
                                    </Col>
                                    <Col sm="4">
                                        <h5 style={{ paddingTop: '3px', textAlign: 'right' }}>
                                            <Link style={{ color: 'grey' }} to={'/advanced-search'}>Browse All &rarr;</Link>
                                        </h5>
                                    </Col>
                                </Row>

                                {this.state.recentResult.map((recent, j) => (<div className="deal-view">
                                                        <h5 style={{ color: 'grey' }}><a style={{ color: '#3890da' }} href={`/advanced-search${this.makeSearchQueryString(JSON.parse(this.state.recentSearches[j]))}`}>Recent Search #{j + 1} {this.printRecentSearchName(this.state.recentSearches[j])}</a></h5>
                                        <div className="featured-franchises">
                                            <Slider {...settings}>
                                                {recent.map((t, i) => (
                                                    <div key={i} className="franchises-bx">
                                                        <a href={`/business-for-sale/${t.transaction_slug}`} target="_blank" rel="noopener noreferrer">
                                                        <div style={{ height: '193px' }}><img src={(t.image !== null && t.image !== '')
                                                                ? getImageUrl(t.image, true)
                                                                : process.env.PUBLIC_URL + '/images/empty.jpg'
                                                            } className="img-fluid" alt="" style={{ height: '193px' }} /></div>
                                                        <div className="franchises-details" style={{ height: '345px' }}>
                                                            <h6>{this.sliceText(t.name, 20)}</h6>
                                                            {(t.location_address !== '' && t.location_address !== null) && <p className="listing-location"><i className="sprite i-listing-locator"></i>{t.location_address}</p>}
                                                            <p className="text-font-light">{t.description && this.sliceText(t.description, 50)}</p>
                                                            <p className="capital-req">Asking Price: <span className="text-blue">{t.hide_price ? 'Not Specified' : t.price}</span></p>
                                                            {t.cashflow && <p className="capital-req">Cash Flow: <span className="text-blue">{t.cashflow}</span></p>}
                                                            {t.revenue && <p className="capital-req">Revenue: <span className="text-blue">{t.revenue}</span></p>}
                                                            {t.ebitda && <p className="capital-req">Revenue: <span className="text-blue">{t.ebitda}</span></p>}
                                                            <a style={{ bottom: '15px', position: 'absolute', width: '188px' }} href={`/business-for-sale/${t.transaction_slug}`} target="_blank" rel="noopener noreferrer" className="btn-default mt-3">Review Now</a>
                                                        </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    
                                </div>))}

                                {true && <div>                            
                                <Col style={{ paddingTop: '35px' }}>
                                    <Row sm="12">
                                        <h4 style={{ fontWeight: '600' }}>
                                            <div style={{ display: 'inline-block' }}>Find Intermediaries Who Work With</div> {this.renderCategoryList()} <div style={{ display: 'inline-block' }}>Industry in {this.renderLocation()}</div>
                                            <i style={{ margin: '8px' }} id="Intermediaries" className="sprite i-question"></i>
                                        <UncontrolledTooltip placement="bottom" target="Intermediaries"
                                                            autohide={false}
                                                            innerClassName="areas-tooltip">
                                            We suggest intermediaries to help with your deal flow based on your stated search preferences, saved searches and search activity on BizNexus.
                                        </UncontrolledTooltip>
                                        </h4>
                                    </Row>
                                </Col>

                                <div className="deal-view">
                                        
                                        {this.state.brokerLoading ? 
                                            <div className="bg-white text-center">
                                                <div className="spiner" style={{ margin: '0px', position: 'relative', 'textAlign': 'center' }}></div>
                                            </div>
                                        : 
                                        <div className="featured-franchises">
                                            <Slider {...settings}>
                                                {this.state.brokers.map((b, i) => (
                                                    <div key={i} className="franchises-bx">
                                                        <a href={`/profile/${b.id}`} target="_blank" rel="noopener noreferrer">
                                                        <div className="deal-profile-pic" style={{ height: '177px', width: '177px' }}><img src={(b.profile_pic) ? 
                                                        (b.profile_pic.indexOf('base64') > -1) ? b.profile_pic : (b.profile_pic.indexOf('user_profile') > -1) ? getImageUrl(b.profile_pic) : getImageUrl(b.profile_pic) : process.env.PUBLIC_URL + '/images/thumbnail-small.gif'}
                                                        className="img-fluid rounded-circle d-block m-auto" 
                                                        onError={
                                                            (e) => { 
                                                            //@ts-ignore
                                                            if(!e.target.src.includes('images/thumbnail-small.gif')) {
                                                                e.target.src = `/images/thumbnail-small.gif`;
                                                            }
                                                            }
                                                        }
                                                        alt="" /></div>
                                                        <div className="franchises-details" style={{ height: '250px' }}>
                                                            <h6>{b.name}</h6>
                                                            <p className="listing-location"><i className="sprite i-listing-locator"></i>{b.location_served}</p>
                                                            <p className="listing-location">M&A Advisor</p>
                                                            {b.ind_string && b.ind_string.length ? <p className="listing-location">Industries: {b.ind_string.join(', ')}, <a href={`/profile/${b.id}`} target="_blank" rel="noopener noreferrer">see more...</a></p> : null}
                                                            <p className="text-font-light"></p>
                                                            
                                                            <a style={{ bottom: '15px', position: 'absolute', width: '188px' }} href={`/profile/${b.id}`} target="_blank" rel="noopener noreferrer" className="btn-default mt-3">View Profile</a>
                                                        </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>}
                                    
                                    </div></div>}

                                {this.state.search.length > 0 && <div><Row style={{ paddingTop: '35px' }}>
                                    <Col sm="7">
                                        <h4 style={{ fontWeight: '600' }}>
                                            Saved Searches
                                            <i style={{ margin: '8px' }} id="Saved" className="sprite i-question"></i>
                                        <UncontrolledTooltip placement="bottom" target="Saved"
                                                            autohide={false}
                                                            innerClassName="areas-tooltip">
                                            Saved searches listings shown below match exactly with results of searches you've saved on BizNexus. To update, delete or add new saved searches, you can do that as you search or in the "favorites & saved searches" tab in the Edit Profile section.
                                        </UncontrolledTooltip>
                                        </h4>
                                    </Col>
                                    <Col sm="5">
                                        <h5 style={{ paddingTop: '3px', textAlign: 'right' }}>
                                            <Link style={{ color: 'grey' }} to={'/edit-profile/favorites'}>Manage Email Alerts &rarr;</Link>
                                        </h5>
                                    </Col>
                                </Row>

                                
                                {this.state.search.map((s, i) => (

                                    <div className="deal-view" key={i}>
                                        <h5 style={{ color: 'grey' }}><a style={{ color: '#3890da' }} href={`/advanced-search?search_id=${s.id}`}>Your Saved Search: "{s.name}"</a></h5>
                                        <div className="featured-franchises">
                                            <Slider {...settings}>
                                                {s.list.map((t, i) => (
                                                    <div key={i} className="franchises-bx">
                                                        <a href={`/business-for-sale/${t.transaction_slug}`} target="_blank" rel="noopener noreferrer">
                                                        <div style={{ height: '193px' }}><img src={(t.image !== null && t.image !== '')
                                                                ? getImageUrl(t.image, true)
                                                                : process.env.PUBLIC_URL + '/images/empty.jpg'
                                                            } className="img-fluid" alt="" style={{ height: '193px' }} /></div>
                                                        <div className="franchises-details" style={{ height: '345px' }}>
                                                            <h6>{this.sliceText(t.name, 20)}</h6>
                                                            {(t.location_address !== '' && t.location_address !== null) && <p className="listing-location"><i className="sprite i-listing-locator"></i>{t.location_address}</p>}
                                                            <p className="text-font-light">{t.description && this.sliceText(t.description, 50)}</p>
                                                            <p className="capital-req">Asking Price: <span className="text-blue">{t.hide_price ? 'Not Specified' : t.price}</span></p>
                                                            {t.cashflow && <p className="capital-req">Cash Flow: <span className="text-blue">{t.cashflow}</span></p>}
                                                            {t.revenue && <p className="capital-req">Revenue: <span className="text-blue">{t.revenue}</span></p>}
                                                            {t.ebitda && <p className="capital-req">Revenue: <span className="text-blue">{t.ebitda}</span></p>}
                                                            <a style={{ bottom: '15px', position: 'absolute', width: '188px' }} href={`/business-for-sale/${t.transaction_slug}`} target="_blank" rel="noopener noreferrer" className="btn-default mt-3">Review Now</a>
                                                        </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                
                                    </div>
                                ))}  
                                </div>}

                                {this.state.favorites && this.state.favorites.length > 0 &&<div>
                                <Row style={{ paddingTop: '35px' }}>
                                    <Col sm="8">
                                        <h4 style={{ fontWeight: '600' }}>Your Saved Listings</h4>
                                    </Col>
                                    <Col sm="4">
                                        <h5 style={{ paddingTop: '3px', textAlign: 'right' }}>
                                            <Link style={{ color: 'grey' }} to={'/edit-profile/favorites'}>Manage Saved Listings &rarr;</Link>
                                        </h5>
                                    </Col>
                                </Row>
                                
                                    
                                <div className="deal-view">
                                    
                                    <div className="featured-franchises">
                                        <Slider {...settings} infinite={false}>
                                            {this.state.favorites.map((t, i) => (
                                                <div key={i} className="franchises-bx">
                                                    <a href={`/business-for-sale/${t.transaction_slug}`} target="_blank" rel="noopener noreferrer">
                                                    <div style={{ height: '193px' }}><img src={(t.image !== null && t.image !== '')
                                                            ? getImageUrl(t.image, true)
                                                            : process.env.PUBLIC_URL + '/images/empty.jpg'
                                                        } className="img-fluid" alt="" style={{ height: '193px' }} /></div>
                                                    <div className="franchises-details" style={{ height: '345px' }}>
                                                        <h6>{this.sliceText(t.name, 20)}</h6>
                                                        {(t.location_address !== '' && t.location_address !== null) && <p className="listing-location"><i className="sprite i-listing-locator"></i>{t.location_address}</p>}
                                                        <p className="text-font-light">{t.description && this.sliceText(t.description, 50)}</p>
                                                        <p className="capital-req">Asking Price: <span className="text-blue">{t.hide_price ? 'Not Specified' : t.price}</span></p>
                                                        {t.cashflow && <p className="capital-req">Cash Flow: <span className="text-blue">{t.cashflow}</span></p>}
                                                        {t.revenue && <p className="capital-req">Revenue: <span className="text-blue">{t.revenue}</span></p>}
                                                        {t.ebitda && <p className="capital-req">Revenue: <span className="text-blue">{t.ebitda}</span></p>}
                                                        <a style={{ bottom: '15px', position: 'absolute', width: '188px' }} href={`/business-for-sale/${t.transaction_slug}`} target="_blank" rel="noopener noreferrer" className="btn-default mt-3">Review Now</a>
                                                    </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                    
                                </div></div>}

                                <Row style={{ paddingTop: '35px' }}>
                                    <Col sm="8">
                                        <h4 style={{ fontWeight: '600' }}>
                                            Recommended Deals
                                            <i style={{ margin: '8px' }} id="Recommended" className="sprite i-question"></i>
                                        <UncontrolledTooltip placement="bottom" target="Recommended"
                                                            autohide={false}
                                                            innerClassName="areas-tooltip">
                                            We recommend Deals based on your stated acquisition preferences. To update your recommended Deals, update your acquisition preferences in the "preferences" tab in your Edit Profile page.
                                        </UncontrolledTooltip>
                                        </h4>
                                    </Col>
                                    <Col sm="4">
                                        <h5 style={{ paddingTop: '3px', textAlign: 'right' }}>
                                            <a style={{ color: 'grey' }} href={this.state.rlistings[0] ? this.state.rlistings[0].searchLink : ''}>Browse All Deals &rarr;</a>
                                        </h5>
                                    </Col>
                                </Row>

                                
                                    
                                <div className="deal-view">
                                    
                                    <div className="featured-franchises">
                                        <Slider {...settings} infinite={true}>
                                            {this.state.rlistings.map((t, i) => (
                                                <div key={i} className="franchises-bx">
                                                    <a href={`/business-for-sale/${t.transaction_slug}`} target="_blank" rel="noopener noreferrer">
                                                    <div style={{ height: '193px' }}><img src={(t.image !== null && t.image !== '')
                                                            ? getImageUrl(t.image, true)
                                                            : process.env.PUBLIC_URL + '/images/empty.jpg'
                                                        } className="img-fluid" alt="" style={{ height: '193px' }} /></div>
                                                    <div className="franchises-details" style={{ height: '345px' }}>
                                                        <h6>{this.sliceText(t.name, 20)}</h6>
                                                        {(t.location_address !== '' && t.location_address !== null) && <p className="listing-location"><i className="sprite i-listing-locator"></i>{t.location_address}</p>}
                                                        <p className="text-font-light">{t.description && this.sliceText(t.description, 50)}</p>
                                                        <p className="capital-req">Asking Price: <span className="text-blue">{t.hide_price ? 'Not Specified' : t.price}</span></p>
                                                        {t.cashflow && <p className="capital-req">Cash Flow: <span className="text-blue">{t.cashflow}</span></p>}
                                                        {t.revenue && <p className="capital-req">Revenue: <span className="text-blue">{t.revenue}</span></p>}
                                                        {t.ebitda && <p className="capital-req">Revenue: <span className="text-blue">{t.ebitda}</span></p>}
                                                        <a style={{ bottom: '15px', position: 'absolute', width: '188px' }} href={`/business-for-sale/${t.transaction_slug}`} target="_blank" rel="noopener noreferrer" className="btn-default mt-3">Review Now</a>
                                                    </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                    
                                </div>
                                

                                
                                



                            

                                
                            </TabPane>

                            <TabPane tabId={2}>
                                <div style={{ marginTop: '25px' }}>
                                    <h4>LinkedIn Groups</h4>
                                    <ul>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/2004530/" target="_blank">I Want to Sell My Business in 5 Years – Exit Planning</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/1789603/" target="_blank">I Want to Buy a Business</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/1806481/" target="_blank">American Business Brokers Association - ABBA</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/3790878/" target="_blank">Business Owners, Entrepreneurs & Start-Ups</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/84596/" target="_blank">The M&A Forum</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/145170/" target="_blank">International Business Brokers Association</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/879777/" target="_blank">Exit Planning Exchange</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/111694/" target="_blank">Association of Professional Merger & Acquisition Advisors (APMAA)</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/157449/" target="_blank">Successful QuickBooks Consultants QBO QB Power Hour Cloud Connect Accountant</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/157449/" target="_blank">CPA Bookkeeping Online</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/59189/" target="_blank">SCORE Mentors Group</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/46192/" target="_blank">The Family Office Club - #1 Largest Family Office Association | Ultra-Wealthy Wealth</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/46192/" target="_blank">Management</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.linkedin.com/groups/2056958/" target="_blank">Accounting & Audit</a></li>
                                        
                                    </ul>
                                    <h4>Facebook Groups</h4>
                                    <ul>
                                        <li><a rel="noopener noreferrer" href="https://www.facebook.com/groups/entrepreneurshipthroughacquisition/" target="_blank">Entrepreneurship Through Acquisition</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.facebook.com/groups/796589517213446/?ref=group_header" target="_blank">IBBA - International Business Brokers Association Members</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.facebook.com/groups/2190421317862883/?ref=group_header" target="_blank">Acquisition Insight: USA M&A Community</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.facebook.com/groups/bn.digital" target="_blank">Social Selling For Intermediaries</a></li>
                                        
                                    </ul>
                                    <h4>Quora Threads</h4>
                                    <ul>
                                        <li><a rel="noopener noreferrer" href="https://www.quora.com/topic/Business-for-Sale" target="_blank">Business for Sale</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.quora.com/topic/Small-Business-Owners" target="_blank">Small Business Owners</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.quora.com/topic/Buying-and-Selling-a-Business" target="_blank">Buying and Selling a Business</a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.quora.com/topic/Business-Brokerage" target="_blank">Business Brokerage</a></li>
                                        
                                    </ul>
                                </div>    
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>

                
                
            </div>
        )
    }
}
