import Select from "react-select";
import React, { useState } from "react";
import {USER_TYPE_LABEL} from "../../../constants/userTypes";

export default function TypeSelect({ user, onChangeType }) {
  const types = Object.keys(USER_TYPE_LABEL);
  const options = types.map(t => ({ label: USER_TYPE_LABEL[t], value: t }))
  const [type, setType] = useState(options.find(({value}) => +value === +(user.user_type_id)));

    return (
      <div className="form-inline">
        <div>User Type: </div>
        <Select 
            classNamePrefix="Select"
            classNames={{
                control: (state) =>
                    state.isFocused ? 'Select-control' : 'Select-control',
            }}
            className="mt-2 w-100"
            isSearchable={false}
            isClearable={false}
            onChange={option => setType(option)}
            options={options}
            value={type}
            style={{backgroundColor: 'white'}}
        />
        <button className="btn btn-primary btn-sm mt-2" onClick={() => onChangeType(user.id, type.value)}>Update User Type</button>
      </div>

  )
}
