import React from "react";

const AvailableOption = ({ checked = true, children }) => {
    return (
        <li>
            {
                checked
                    ? <i className="fas fa-check-circle" style={{color: 'green', fontSize: 16, paddingRight: 5}}/>
                    : <i className="fas fa-times-circle" style={{ color: 'red', fontSize: 16, paddingRight: 5 }}/>
            }
            {children}
        </li>
    )
}

export default AvailableOption
