import React from 'react';
import Geosuggest from '@ubilabs/react-geosuggest';
import {
    Button,
    Col,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import api from '../../../services/api';
import IndustryFilter from "../../cards/SearchFilters/IndustryFilter";
import _ from "lodash";
import {toast} from "react-toastify";

let categoryRemoveClicked = false;

export default class Preferences extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            saveDisabled: true,
            areaservedlist: [],
            errors: {},
            industrylist: [],
            brokerIndustry: [],
            user_type_id: null,
            interested_in_distressed: false,
            recommended: false,
        }
    }

    getUpdatedIndustryList = (item, checked) => {
        return this.state.industrylist.map(i =>
            i.value === item.value
                ? {...i, checked}
                : i.children && i.children.length
                    ? {...i, children: i.children.map(ic => ic.value === item.value ? {...ic, checked} : ic)}
                    : i
        )
    }

    getSelectedIndustryList = (item, checked) => {
        let res = [...this.state.brokerIndustry]
        this.state.industrylist.forEach(i => {
            if (i.value === item.value) {
                res.push({...i, checked, path: `${i.label}>>All`})
            } else if (i.children && i.children.length) {
                const childItem = i.children.find(ic => ic.value === item.value)
                if (childItem) {
                    res.push({...childItem, checked, path: `${i.label}>>${childItem.label}`})
                }
            }
        })

        return res;
    }
    getParentAndChildCounts = (newItems) => {
        let parentCount = 0;
        let childCount = 0;

        // eslint-disable-next-line
        newItems.forEach((industrySelected, i) => {
            if (industrySelected.children && industrySelected.children.length) {
                parentCount++;
            } else {
                childCount++;
            }
        });
        return {parentCount, childCount}
    }

    onRemoveBrokerIndustry = item => {
        const brokerIndustry = _.reject(this.state.brokerIndustry, bi => bi.value === item.value);
        const industrylist = this.getUpdatedIndustryList(item, false)
        const {parentCount, childCount} = this.getParentAndChildCounts(brokerIndustry)

        if (parentCount === 1 && childCount === 1) {
            this.setState({secondlevelmax: 1, ListingdropdownCategory: false});
        } else {
            this.setState({secondlevelmax: '', selectlabel: 0});
        }
        this.setState(prevState => ({
            ...prevState,
            brokerIndustry,
            industrylist,
            saveDisabled: false
        }));
    }

    onUpdateBrokerIndustry = (items = []) => {
        const selectedItem = items.find(item => !this.state.brokerIndustry.some(bi => bi.value === item.value))
        if (!selectedItem) {
            const deselectedItem = this.state.brokerIndustry.find(bi => !items.some(item => bi.value === item.value))
            this.onRemoveBrokerIndustry(deselectedItem)
            return
        }
        const newIndustryList = this.getUpdatedIndustryList(selectedItem, true)
        const brokerIndustry = this.getSelectedIndustryList(selectedItem, true)

        if (!this.props.embedded) {
            const {parentCount, childCount} = this.getParentAndChildCounts(items)

            if (parentCount < 3 && childCount < 3) {
                if (parentCount === 1 && childCount === 1) {
                    this.setState({secondlevelmax: 1, ListingdropdownCategory: false});
                } else {
                    this.setState({secondlevelmax: '', selectlabel: 0});
                }
            } else {
                this.setState({secondlevelmax: 1, ListingdropdownCategory: false});
                return
            }
        }

        this.setState(prevState => ({
            ...prevState,
            brokerIndustry,
            industrylist: newIndustryList,
            saveDisabled: false
        }));
    }


    updateRecommended = (checked, i) => {
        this.setState({ recommended: checked });
        api.post('user/saved_search_preferences', {savedSearchList: [], recommend_listings: checked}, true)
    }

    componentDidMount() {
        if (this.props.noPreset) {
            api.get('industry/legacy', null, true)
                .then(res => {
                    this.setState({ industrylist: res,
                        brokerIndustry: [], });
                });
        } else {
            this.getSearchPreferences();

            api.get('user/editprofile', null, true)
                .then(res => {
                    const selectedIndustries = []
                    res.industries_of_expertise.forEach(industry => {
                        if (industry.checked) {
                            selectedIndustries.push(industry)
                        }
                        if (industry.children && industry.children.length) {
                            industry.children.forEach(subIndustry => {
                                if (subIndustry.checked) {
                                    selectedIndustries.push(subIndustry)
                                }
                            })
                        }
                    })
                    this.setState({
                        areaservedlist: (res.area_served.length === 0) ? this.state.areaservedlist : res.area_served,
                        industrylist: res.industries_of_expertise,
                        brokerIndustry: selectedIndustries,
                        user_type_id: res.user_type_id,
                        interested_in_distressed: res.interested_in_distressed,
                        recommended: res.recommend_listings,
                    });
                });
        }

    }

    handleChangeSearch(field, e) {
        if (e.target !== '' && e.target !== undefined) {
            let fields = this.state.fields;
            fields[field] = e.target.value;
            let v = e.target.value.replace(/[^0-9]/g, '');
            fields[field] = v.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.setState({ fields, saveDisabled: false });
        }
    }

    handleChangeMinPriceRange = (e) => {
        let fields = this.state.fields;
        fields["minpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, minpricerangeselected: e.target.value, saveDisabled: false});
    }
    handleChangeMaxPriceRange = (e) => {
        let fields = this.state.fields;
        fields["maxpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, maxpricerangeselected: e.target.value, saveDisabled: false});
    }
    handleChangeMincashflow = (e) => {
        let fields = this.state.fields;
        fields["mincashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, mincashflowselected: e.target.value, saveDisabled: false});
    }
    handleChangeMaxcashflow = (e) => {
        let fields = this.state.fields;
        fields["maxcashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, maxcashflowselected: e.target.value, saveDisabled: false});
    }
    handleChangeMinRevenue = (e) => {
        let fields = this.state.fields;
        fields["minrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, minrevenueselected: e.target.value, saveDisabled: false });
    }
    handleChangeMaxRevenue = (e) => {
        let fields = this.state.fields;
        fields["maxrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({fields, maxrevenueselected: e.target.value, saveDisabled: false});
    }

    saveSearchPreferences = () => {
        let categoryArrayData = this.state.brokerIndustry.map(i => ({ id: i.value, name: i.label, path: i.path }));
        this.setState({ saveDisabled: true})
        // eslint-disable-next-line
        const data = {
            options: {
                minpricerange: this.state.minpricerangeselected,
                maxpricerange: this.state.maxpricerangeselected,
                mincashflow: this.state.fields.mincashflow,
                maxcashflow: this.state.fields.maxcashflow,
                minrevenue: this.state.fields.minrevenue,
                maxrevenue: this.state.fields.maxrevenue,
                keywords: this.state.keywordsselected,
            },
            area_served: this.state.areaservedlist,
            industries_of_expertise: categoryArrayData,
            interested_in_distressed: this.state.interested_in_distressed,
        }
         if (this.props.embedded) {
             this.props.onPreferencesChanged(data)
         } else {
             setTimeout(() => {
                 api.post('user/searchPreferences', data, true).then(res => {
                     document.getElementById('loader').setAttribute("style", "display: none;");
                     toast.info("Your Match Preferences were successfully updated", {
                         position: "bottom-right",
                         className: 'toast-bar',
                         autoClose: 5000,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                     });
                 });
             }, 250);
         }

    }

    getSearchPreferences() {
        api.get('user/searchPreferences', null, true)
            .then(response => {
                if(response.searchPreferences) {
                    const pref = response.searchPreferences;
                    this.setState({
                        minpricerangeselected: pref.minpricerange ? pref.minpricerange: 'Any Min',
                        maxpricerangeselected: pref.maxpricerange ? pref.maxpricerange: 'Any Max',
                        mincashflowselected: pref.mincashflow ? pref.mincashflow: 'Any Min',
                        maxcashflowselected: pref.maxcashflow ? pref.maxcashflow: 'Any Max',
                        minrevenueselected: pref.minrevenue ? pref.minrevenue: 'Any Min',
                        maxrevenueselected: pref.maxrevenue ? pref.maxrevenue: 'Any Max',
                        keywordsselected: pref.keywords,
                        fields: {
                            mincashflow: pref.mincashflow ? pref.mincashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                            maxcashflow: pref.maxcashflow ? pref.maxcashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                            minrevenue: pref.minrevenue ? pref.minrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                            maxrevenue: pref.maxrevenue ? pref.maxrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                        }
                    });
                }
            })
    }

    removeClick = (e, i) => {
        this.state.areaservedlist.splice(e, 1);
        let dataAreaLists = [];
        dataAreaLists = this.state.areaservedlist;
        this.setState({areaservedlist: dataAreaLists, profileupdate: 1, saveDisabled: false});
    }

    arrowtoggle = (e) => {
        let id = e.target.id;
        let splitValue = id.split("_");
        let splitid = splitValue[1];
        let categorydata = this.state.industrylist;
        if (categorydata[splitid].toggle === undefined) {
            categorydata[splitid].toggle = true;
        } else {
            if (categorydata[splitid].toggle === false) {
                categorydata[splitid].toggle = true;
            } else {
                categorydata[splitid].toggle = false;
            }
        }
        this.setState({industrylist: categorydata});
    }

    Categorytoggle = () => {
        if (categoryRemoveClicked) {
            categoryRemoveClicked = !categoryRemoveClicked
            return;
        }
        this.setState({dropdownCategory: !this.state.dropdownCategory});
    }

    handleGeosuggestChange = (areaserved) => {
        if (this.state.areaservedlist.length <= 3) {
            if (areaserved !== undefined) {

                const duplicate = this.state.areaservedlist.find(l => l.location === areaserved.label);

                if(duplicate) {
                    this.refs.areaserved.update('');
                    return;
                }

                let addressComponent = areaserved.gmaps.address_components;
                let city, stateData, stateShortData, country = '';
                for (var j = 0; j < areaserved.gmaps.address_components.length; j++) {
                    if (addressComponent[j].types[0] === 'locality') {
                        city = addressComponent[j].long_name;
                    }
                    if (addressComponent[j].types[0] === 'administrative_area_level_1') {
                        stateData = addressComponent[j].long_name;
                        stateShortData = addressComponent[j].short_name; 
                    }
                    if (addressComponent[j].types[0] === 'country') {
                        country = addressComponent[j].short_name;
                    }
                }
                if (stateShortData === "") {
                    stateShortData = areaserved.label;
                }
                if (stateData === undefined) {
                    stateData = areaserved.label;
                }
                let dataArea = [];
                let temp = '';
                temp = {
                    "location": areaserved.label,
                    "latitude": areaserved.location.lat,
                    "longitude": areaserved.location.lng,
                    "city": city,
                    "state_short": stateShortData,
                    "state_long": stateData,
                    country,
                };
                if (this.state.areaservedlist.length !== 0) {
                    this.state.areaservedlist.push(temp)
                } else {
                    this.state.areaservedlist.push(temp)
                }
                dataArea.push(temp);
                this.setState({locationvalues: dataArea, profileupdate: 1, saveDisabled: false});
                this.refs.areaserved.update('');
            } else {
                this.setState({locationvalues: [], saveDisabled: false});
            }
        }
    }

    render() {
        return (
            <div className="">
                <div className="loader" id="loader" style={{display: 'none'}}>
                    <div className="spiner"></div>
                </div>
                            <div className="">

                                <div>
                                    <Row className="">
                                        <Col sm="3"><div style={{ paddingTop: 15 }}>Locations</div></Col>
                                        <Col sm={this.props.embedded ? 9 : 6}>
                                            <FormGroup className="py-2 px-3 position-relative" noMargin>
                                                <Geosuggest
                                                    inputClassName="form-control form-control--filter pref_loc_inp"
                                                    id="areaserved"
                                                    name="areaserved"

                                                    ref="areaserved"

                                                    placeholder="Enter your location"
                                                    onSuggestSelect={this.handleGeosuggestChange}
                                                    disabled={(this.state.areaservedlist.length > 3 && "disabled")}
                                                />
                                                <div className="mt-1 d-flex flex-wrap align-items-center row-gutter-10 ms-1">
                                                    {this.state.areaservedlist.map((locations, i) => <div className="mt-1 ms-1">
                                                        <button onClick={(e) => e.preventDefault()}
                                                                className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                                style={{padding: '1.5px 11px', wordBreak: 'break-all'}}>
                                                            <span className="me-2">{locations.location}</span>
                                                            <svg onClick={this.removeClick.bind(this, i)} className="icon"
                                                                 viewBox="0 0 14 13" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                      fill="currentColor"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                      fill="currentColor"></path>
                                                            </svg>
                                                        </button>
                                                    </div>)}
                                                </div>

                                                <span
                                                    className="error ">{this.state.errors["areaserved"]}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm="3"><div style={{ paddingTop: 15 }}>Industries</div></Col>
                                        <Col sm={this.props.embedded ? 9 : 6} className="custom-menu-category">
                                            <div className="py-2 px-3 form-group">
                                            <Dropdown isOpen={this.state.dropdownCategory}
                                                      toggle={this.Categorytoggle}>
                                                <DropdownToggle tag="a" style={{ fontSize: 13, fontWeight: 400, color: 'rgb(132, 132, 132)' }} className="form-control" caret>
                                                    Select Industry
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <IndustryFilter
                                                        industriesList={this.state.industrylist}
                                                        selectedIndustries={this.state.brokerIndustry}
                                                        updateIndustries={this.onUpdateBrokerIndustry}
                                                        onRemoveIndustry={this.onRemoveBrokerIndustry}
                                                    />
                                                </DropdownMenu>
                                            </Dropdown>
                                                <div className="d-flex flex-wrap align-items-center row-gutter-10 ms-1">
                                                    {this.state.brokerIndustry.map((item, i) =>
                                                        <>
                                                            <div className="mt-1 ms-1">
                                                                <button onClick={(e) => e.preventDefault()}
                                                                        className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                                        style={{
                                                                            padding: '1.5px 11px',
                                                                            wordBreak: 'break-all'
                                                                        }}>
                                                                    <span className="me-2">{item.label}</span>
                                                                    <svg id={i + "_parentselected"}
                                                                         onClick={() => this.onRemoveBrokerIndustry(item)}
                                                                         className="icon"
                                                                         viewBox="0 0 14 13" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                                              d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                              fill="currentColor"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                                              d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                              fill="currentColor"/>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                            </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm="3"><div style={{ paddingTop: 15 }}>Cash flow</div></Col>
                                        <Col sm={this.props.embedded ? 9 : 6}>
                                            <div className="py-2 px-3">
                                                <div className="row row-gutter-15">
                                                    <div className="col">
                                                        <Dropdown isOpen={this.state.dropdownmincashflow} toggle={() => {}}>
                                                            <DropdownToggle className="dropdown-number-toggle">
                                                                <input type="text"
                                                                       onBlur={() => {
                                                                           if (this.state.dropdownmincashflow) {
                                                                               setTimeout(() => {
                                                                                   this.setState({dropdownmincashflow: false})
                                                                               }, 500)
                                                                           }
                                                                       }}
                                                                       onFocus={() => this.setState({dropdownmincashflow: true})}
                                                                       autoComplete="off"
                                                                       className="form-control form-control--filter"
                                                                       onChange={(e) => {
                                                                           this.handleChangeSearch("mincashflow", e)
                                                                       }}
                                                                       value={this.state.fields["mincashflow"]}
                                                                       placeholder="Min" name="mincashflow"/>
                                                            </DropdownToggle>
                                                            <DropdownMenu  className={`advanced-dropdown dropdown-menu ${this.state.dropdownmincashflow ? 'show' : ''}`}>
                                                                <button onClick={() => {
                                                                    this.handleChangeMincashflow({target: {value: '500,000'}})
                                                                }} className="dropdown-item">$500,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMincashflow({target: {value: '1,000,000'}})
                                                                }} className="dropdown-item">$1,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMincashflow({target: {value: '2,500,000'}})
                                                                }} className="dropdown-item">$2,500,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMincashflow({target: {value: '5,000,000'}})
                                                                }} className="dropdown-item">$5,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMincashflow({target: {value: '7,500,000'}})
                                                                }} className="dropdown-item">$7,500,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMincashflow({target: {value: '10,000,000'}})
                                                                }} className="dropdown-item">$10,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMincashflow({target: {value: '25,000,000'}})
                                                                }} className="dropdown-item">$25,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMincashflow({target: {value: '50,000,000'}})
                                                                }} className="dropdown-item">$500,000,000
                                                                </button>
                                                            </DropdownMenu>
                                                        </Dropdown>

                                                    </div>
                                                    <div className="col">
                                                        <Dropdown isOpen={this.state.dropdownmaxcashflow} toggle={() => {}}>
                                                            <DropdownToggle className="dropdown-number-toggle">
                                                                <input type="text"
                                                                       onBlur={() => {
                                                                           if (this.state.dropdownmaxcashflow) {
                                                                               setTimeout(() => {
                                                                                   this.setState({dropdownmaxcashflow: false})
                                                                               }, 500)
                                                                           }
                                                                       }}
                                                                       onFocus={() => this.setState({dropdownmaxcashflow: true})}
                                                                       className="form-control form-control--filter"
                                                                       autoComplete="off"
                                                                       onChange={(e) => {
                                                                           this.handleChangeSearch("maxcashflow", e)
                                                                       }}
                                                                       value={this.state.fields["maxcashflow"]}
                                                                       placeholder="Max" name="maxcashflow"/>
                                                            </DropdownToggle>
                                                            <DropdownMenu  className={`advanced-dropdown dropdown-menu ${this.state.dropdownmaxcashflow ? 'show' : ''}`}>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxcashflow({target: {value: '500,000'}})
                                                                }} className="dropdown-item">$500,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxcashflow({target: {value: '1,000,000'}})
                                                                }} className="dropdown-item">$1,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxcashflow({target: {value: '2,500,000'}})
                                                                }} className="dropdown-item">$2,500,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxcashflow({target: {value: '5,000,000'}})
                                                                }} className="dropdown-item">$5,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxcashflow({target: {value: '7,500,000'}})
                                                                }} className="dropdown-item">$7,500,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxcashflow({target: {value: '1,000,000'}})
                                                                }} className="dropdown-item">$10,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxcashflow({target: {value: '25,000,000'}})
                                                                }} className="dropdown-item">$25,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxcashflow({target: {value: '50,000,000'}})
                                                                }} className="dropdown-item">$500,000,000
                                                                </button>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="3"><div style={{ paddingTop: 15 }}>Revenue</div></Col>
                                        <Col sm={this.props.embedded ? 9 : 6}>

                                            <div className="py-2 px-3">
                                                <div className="row row-gutter-15">
                                                    <div className="col">
                                                        <Dropdown isOpen={this.state.dropdownminrevenue} toggle={() => {}}>
                                                            <DropdownToggle className="dropdown-number-toggle">
                                                                <input type="text"
                                                                       onBlur={() => {
                                                                           if (this.state.dropdownminrevenue) {
                                                                               setTimeout(() => {
                                                                                   this.setState({dropdownminrevenue: false})
                                                                               }, 500)
                                                                           }
                                                                       }}
                                                                       onFocus={() => this.setState({dropdownminrevenue: true})}
                                                                       className="form-control form-control--filter"
                                                                       autoComplete="off"
                                                                       onChange={(e) => {
                                                                           this.handleChangeSearch("minrevenue", e)
                                                                       }}
                                                                       value={this.state.fields["minrevenue"]}
                                                                       placeholder="Min" name="minrevenue"/>
                                                            </DropdownToggle>
                                                            <DropdownMenu className={`advanced-dropdown dropdown-menu ${this.state.dropdownminrevenue ? 'show' : ''}`}>
                                                                <button onClick={() => {
                                                                    this.handleChangeMinRevenue({target: {value: '500,000'}})
                                                                }} className="dropdown-item">$500,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMinRevenue({target: {value: '1,000,000'}})
                                                                }} className="dropdown-item">$1,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMinRevenue({target: {value: '2,500,000'}})
                                                                }} className="dropdown-item">$2,500,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMinRevenue({target: {value: '5,000,000'}})
                                                                }} className="dropdown-item">$5,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMinRevenue({target: {value: '7,500,000'}})
                                                                }} className="dropdown-item">$7,500,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMinRevenue({target: {value: '10,000,000'}})
                                                                }} className="dropdown-item">$10,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMinRevenue({target: {value: '2,500,000'}})
                                                                }} className="dropdown-item">$25,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMinRevenue({target: {value: '50,000,000'}})
                                                                }} className="dropdown-item">$50,000,000
                                                                </button>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </div>
                                                    <div className="col">
                                                        <Dropdown isOpen={this.state.dropdownmaxrevenue} toggle={() => {}}>
                                                            <DropdownToggle className="dropdown-number-toggle">
                                                                <input type="text"
                                                                       onBlur={() => {
                                                                           if (this.state.dropdownmaxrevenue) {
                                                                               setTimeout(() => {
                                                                                   this.setState({dropdownmaxrevenue: false})
                                                                               }, 200)
                                                                           }
                                                                       }}
                                                                       onFocus={() => this.setState({dropdownmaxrevenue: true})}
                                                                       className="form-control form-control--filter"
                                                                       autoComplete="off"
                                                                       onChange={(e) => {
                                                                           this.handleChangeSearch("maxrevenue", e)
                                                                       }}
                                                                       value={this.state.fields["maxrevenue"]}
                                                                       placeholder="Max" name="maxrevenue"/>
                                                            </DropdownToggle>
                                                            <DropdownMenu className={`advanced-dropdown dropdown-menu ${this.state.dropdownmaxrevenue ? 'show' : ''}`}>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxRevenue({target: {value: '500,000'}})
                                                                }} className="dropdown-item">$500,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxRevenue({target: {value: '1,000,000'}})
                                                                }} className="dropdown-item">$1,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxRevenue({target: {value: '2,500,000'}})
                                                                }} className="dropdown-item">$2,500,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxRevenue({target: {value: '5,000,000'}})
                                                                }} className="dropdown-item">$5,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxRevenue({target: {value: '7,500,000'}})
                                                                }} className="dropdown-item">$7,500,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxRevenue({target: {value: '10,000,000'}})
                                                                }} className="dropdown-item">$10,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxRevenue({target: {value: '25,000,000'}})
                                                                }} className="dropdown-item">$25,000,000
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.handleChangeMaxRevenue({target: {value: '50,000,000'}})
                                                                }} className="dropdown-item">$50,000,000
                                                                </button>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="row display-flex justify-content-center mt-3">
                                                    <div style={{padding: '0 12px'}}>
                                                        <Button className="w-100" color="success" disabled={this.state.saveDisabled} onClick={this.saveSearchPreferences}>Save</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
        )
    }
}
