import React from 'react';

const Sitemap = () => {
  return (
    <div>
      <p><a href="https://biznexus.com/"><strong>Home</strong></a></p>
      <ul>
        <li>Buy a Business
          <ul>
            <li><a href="https://biznexus.com/buy-a-business-for-sale">Businesses For Sale</a></li>
            <li><a href="https://biznexus.com/franchise-opportunities">Franchise Opportunities</a></li>
            <li><a href="https://biznexus.com/buyers-list">Join Our Buyers List</a></li>
            <li><a href="https://biznexus.com/strategic-buyer-services">Strategic Buyer Services</a></li>
            <li><a href="https://biznexus.com/get-financing">Get Financing</a></li>
            <li><a href="https://biznexus.com/buy-a-business-blog">Business Acquisition Guide</a></li>
          </ul>
        </li>
        <li>Sell a Business
          <ul>
            <li><a href="https://biznexus.com/sell-my-business">Sell Your Business</a></li>
            <li><a href="https://biznexus.com/how-it-works">The BizNexus Process</a></li>
            <li><a href="https://biznexus.com/sell-a-business-blog">Business Exit Guide</a></li>
          </ul>
        </li>
        <li>For Business Brokers
          <ul>
            <li><a href="https://biznexus.com/intermediary-services">BizNexus Intermediary Services</a></li>
          </ul>
        </li>
        <li><a href="https://biznexus.com/terms-of-use/">Terms of Use</a></li>
        <li><a href="https://biznexus.com/privacy-policy">Privacy Policy</a></li>
      </ul>
    </div>
  );
};

export default Sitemap;