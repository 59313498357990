import React from "react";
import {Link} from "react-router-dom";

const HomePromotionButtons = ({withAddPost}) => (
    <div className="broker-listings-header__right">
        <div className="broker-listings__link-row">
            <Link to="/user/ads" className="broker-listing__link boost">Boost
                profile</Link>
            <a className="broker-listing__link advertise"
               href="https://www.biznexus.com/advertise" target="_blank"
               rel="noopener noreferrer">Advertise</a>
        </div>
        <div className="broker-listings__link-row">
            <a className="broker-listing__link advisor-panel"
               href="https://www.biznexus.com/biznexus-advisor-panel" target="_blank"
               rel="noopener noreferrer">Advisor panel</a>
            <a className="broker-listing__link strategy"
               href="https://www.biznexus.com/demo" target="_blank"
               rel="noopener noreferrer">Strategy session</a>

        </div>
        {
            !!withAddPost && <div className="broker-listings__link-row">
                <Link to="/user/listings?createListing=true" className="broker-listing__link post">
                    Post a deal
                </Link>
            </div>
        }

    </div>
)

export default HomePromotionButtons
