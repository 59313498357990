import React from 'react'
import {find} from 'lodash'
import {Link} from 'react-router-dom';
import {getImageUrl} from '../util';
import {NavLink} from "reactstrap";
import DealNotes from "../DealNotes/dealNotes";
import {AuthContext} from "../../contexts/auth";
import {USER_TYPE} from "../../constants/userTypes";

export default class Deal extends React.Component {
    static contextType = AuthContext

    sliceText(text, count = 15) {
        return text && text.length >= count ? text.slice(0, count) + '...' : text;
    }

    getDealName = deal => `'${deal.name}' - ${deal.revenue && deal.revenue !== 'Not Specified' ? deal.revenue + ', ' : ''}${deal.location_address}`

    render() {
        const deal = this.props.deal;
        const savedListings = this.props.savedListings;
        const onAddToFavorite = this.props.onAddToFavorite;
        const onHideDeal = this.props.onHideDeal;
        const lastNote = this.props.lastNote;
        const withNotes = this.props.withNotes;

        const images = {
            1293401: process.env.REACT_APP_ROOT + '/images/default_listing/techmedia.jpg',
            1293501: process.env.REACT_APP_ROOT + '/images/default_listing/manufacturing.jpg',
            1293601: process.env.REACT_APP_ROOT + '/images/default_listing/food.jpg',
            1293701: process.env.REACT_APP_ROOT + '/images/default_listing/leisure.jpg',
            1293801: process.env.REACT_APP_ROOT + '/images/default_listing/retail.jpg',
            1293901: process.env.REACT_APP_ROOT + '/images/default_listing/services.jpg',
            1299301: process.env.REACT_APP_ROOT + '/images/default_listing/agriculture.jpg',
            1316601: process.env.REACT_APP_ROOT + '/images/default_listing/engineering.jpg',
            1358401: process.env.REACT_APP_ROOT + '/images/default_listing/franchise.jpg',
            1360201: process.env.REACT_APP_ROOT + '/images/default_listing/realestate.jpg',
            1365401: process.env.REACT_APP_ROOT + '/images/default_listing/wholesale.jpg',
            9999999: process.env.REACT_APP_ROOT + '/images/default_listing/other.jpg', //
        };
          
        const { industries } = deal;
        const first_industry = find(industries, e => e.industry.parent_id === 1);

        if (!deal.image || deal.image === 'undefined') {
            if(first_industry) {
                deal.image = images[first_industry.industry.id]
            } else if(industries?.[0]?.industry?.parent_id) {
                deal.image = images[industries[0].industry.parent_id]
            }
        }

        if(this.props.aligment === 'horizontal') {
            return (
                <div class="col-12 col-md-auto">
                    <div class="business-card business-card--horizontal" style={{ height: '105%' }}>
                        <div class="business-card__image">
                        <a style={{ color: '#373c41' }} href={deal.isAnonymous ? `/user/subscription` : `/business-for-sale/${deal.transaction_slug}`} target="_blank" rel="noopener noreferrer">
                            <img src={(deal.image !== null && deal.image !== '')
                                ? getImageUrl(deal.image, true)
                                : process.env.PUBLIC_URL + '/images/empty.jpg'
                            } alt="" style={{ width: '100%', height: 137 }} /></a>
                            {/* <img src="https://stage.app.biznexus.com/images/default_listing/food.jpg" alt="" style={{ width: '100%', height: 137 }} /> */}
                            <a href={`/business-for-sale/${deal.transaction_slug}`} target="_blank" rel="noopener noreferrer">
                                <div class="business-card__price btn btn-secondary price_button_no_cursor" style={{ cursor: 'pointer' }}>Price: {deal.hide_price || !deal.price ? 'Not Specified' : deal.price}</div>
                            </a>
                            {this.context.user.user_type_id !== USER_TYPE.BROKER && onHideDeal && <div class="business-card__price business-card__actions btn btn-secondary price_button_no_cursor text-center" style={{ padding: '1px 5px', top: 100, color: '#3890da' }}>
                        
                                {savedListings && !!onAddToFavorite && <button class={`btn btn-unstyled ${ savedListings && savedListings.includes(deal.id) ? 'active' : ''}`} onClick={() => onAddToFavorite(deal)}>
                                    <svg style={{ fontSize: 20 }} class="icon" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0049 1.96984C18.6619 -4.87316 35.3059 7.10134 12.0049 22.4988C-11.2961 7.10284 5.34792 -4.87316 12.0049 1.96984Z" fill="currentColor"></path>
                                    </svg>
                                </button>}
                                {onHideDeal && <button class="btn btn-unstyled" onClick={() => onHideDeal(deal.id)}>
                                    <svg style={{ fontSize: 20 }} class="icon" viewBox="0 0 49 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M48.6655 20.4711C45.6913 16.5744 42.1191 13.1729 38.0816 10.3929L44.987 3.48748C45.6822 2.81606 45.7015 1.70824 45.0301 1.01303C44.3587 0.317821 43.2509 0.298544 42.5556 0.969964C42.5411 0.984011 42.5267 0.998367 42.5126 1.01303L34.9684 8.5502C31.7573 6.78285 28.1647 5.82197 24.5 5.75019C11.2001 5.75019 0.771984 19.8691 0.334454 20.471C-0.111485 21.0845 -0.111485 21.9154 0.334454 22.5289C3.30867 26.4256 6.88079 29.8271 10.9183 32.6071L4.01291 39.5125C3.3177 40.1839 3.29842 41.2918 3.96984 41.987C4.64126 42.6822 5.74908 42.7014 6.44429 42.03C6.45885 42.016 6.4732 42.0016 6.48735 41.987L14.0315 34.4498C17.2427 36.2171 20.8352 37.178 24.4999 37.2498C37.7998 37.2498 48.228 23.1309 48.6655 22.5289C49.1115 21.9155 49.1115 21.0846 48.6655 20.4711ZM13.4628 30.0679C9.89788 27.6936 6.70238 24.8066 3.97968 21.5C6.55565 18.3798 14.91 9.25012 24.5 9.25012C27.2285 9.30529 29.911 9.96266 32.3557 11.1751L29.3177 14.2131C25.2982 11.5445 19.8764 12.6396 17.2078 16.6591C15.2639 19.5871 15.2639 23.395 17.2078 26.3229L13.4628 30.0679ZM29.225 19.2513C29.5675 19.9516 29.747 20.7204 29.75 21.5C29.75 24.3995 27.3995 26.75 24.5 26.75C23.7204 26.747 22.9516 26.5675 22.2513 26.225L29.225 19.2513ZM19.7751 23.7487C19.4326 23.0484 19.253 22.2795 19.2501 21.4999C19.2501 18.6005 21.6005 16.25 24.5 16.25C25.2796 16.253 26.0484 16.4325 26.7488 16.775L19.7751 23.7487ZM24.5 33.7499C21.7716 33.6947 19.0892 33.0373 16.6444 31.8249L19.6824 28.7869C23.7019 31.4555 29.1237 30.3604 31.7922 26.3409C33.7361 23.4129 33.7361 19.605 31.7922 16.6771L35.5372 12.9321C39.1022 15.3064 42.2977 18.1935 45.0204 21.4999C42.4443 24.6203 34.0899 33.7499 24.5 33.7499Z" fill="currentColor"></path>
                                    </svg>
                                </button>}
                            </div>}

                            
                            
                        </div>

                        <a style={{ color: '#373c41', height: '100%' }} href={deal.isAnonymous ? `/user/subscription` : `/business-for-sale/${deal.transaction_slug}`} target="_blank" rel="noopener noreferrer">
                        
                        <div class="business-card__content" style={{ height: '100%', paddingBottom: 30 }}>
                            <h3 class="business-card__title">
                                <a style={{ color: '#373c41' }} href={deal.isAnonymous ? `/user/subscription` : `/business-for-sale/${deal.transaction_slug}`} target="_blank" rel="noopener noreferrer">
                                    {this.sliceText(deal.name, 30)}
                                </a>
                            </h3>




                            {deal.completed === 2 && this.contextisAdmin && <span style={{ backgroundColor: 'transparent', border: '1px solid black', display: 'inline-flex', marginBottom: 5, marginRight: 10 }} class="badge text-dark">
                  Private Deal
              </span>}
                            {deal.description && ( deal.guru_id === 0 && this.context.isAdmin && deal.isAnonymous) ?
                                <span style={{ backgroundColor: 'transparent', border: '1px solid black', fontSize: 12, marginRight: 10 }} class={`badge text-dark ${deal.location_address ? 'mb-2' : ''}`}>
                                    <i style={{ color: 'gold' }} class="fas fa-star"></i> Premium Deal
                                </span> :
                                <>
                                    
                                </>
                            }
                            {deal.isConcierge ?
                                <span style={{ backgroundColor: '#58ae3a', border: '1px solid black', fontSize: 12 }} class={`badge text-dark`}>
                                    <i style={{ color: 'gold' }} class="fa-solid fa-crown"></i> Concierge Deal
                                </span> :
                                <>

                                </>
                            }

                            {deal.location_address && <p class="business-card__location d-flex">
                                <span class="me-1">
                                <svg class="icon" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z" fill="currentColor"></path>
                                </svg>
                                </span>
                                <span>{this.sliceText(deal.location_address, 20)}</span>
                            </p>}

                            


                            <p class="business-card__description">

                                {deal.isAnonymous ? <div style={{ position: 'relative' }}>
                                    <div style={{ filter: 'blur(4px)' }}>
                                        {this.sliceText(deal.description, 300)}
                                    </div>
                                    <div style={{
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}>
                                        <p className="text-center">
                                            <NavLink style={{ fontSize: 14 }} href="/user/subscription" className="btn btn-success"><i className="fas fa-lock" style={{marginRight: 3}}/> Upgrade To View Description</NavLink>
                                        </p>
                                    </div>
                                </div> : this.sliceText(deal.description, 100)}
                            </p>
                            {
                                !!withNotes && <div onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                }}>
                                    <DealNotes
                                        notes={deal.notes}
                                        dealName={this.getDealName(deal)}
                                        updateNotes={this.props.updateNotes}
                                        dealId={deal.id}
                                    />
                                </div>
                            }
                            <p>

                            </p>

                            
                            

                            <p class="mb-1">
                                <span class="font-weight-bolder">Revenue: </span>
                                <span class="text-primary-blue text-nowrap">{deal.revenue || 'Not Specified'}</span>
                            </p>
                            <p class="mb-1">
                                <span class="font-weight-bolder">SDE: </span>
                                <span class="text-primary-blue text-nowrap">{deal.cashflow || 'Not Specified'}</span>
                            </p>
                            <p>
                                <span class="font-weight-bolder">EBITDA: </span>
                                <span class="text-primary-blue text-nowrap">{deal.ebitda || 'Not Specified'}</span>
                            </p>



                            {
                                !deal.isAnonymous && <div className="mt-1" style={{position: 'absolute', bottom: 0, width: '100%'}}>
                                    <a href={`/business-for-sale/${deal.transaction_slug}`} target="_blank"
                                       rel="noopener noreferrer" className="btn btn-primary business-card__footer-btn text-nowrap">
                                        Review Now
                                    </a>
                                </div>
                            }

                        </div></a>
                    </div>
                </div>
            )
        } else {
            return (
                <div class="business-card" onClick={(e) => {
                    if(deal.isAnonymous) {
                        window.open(`/user/subscription`);
                    } else {
                        window.open(`/business-for-sale/${deal.transaction_slug}`, '_blank');
                    }
                }} style={{ cursor: 'pointer' }}>
                    <div class="business-card__image position-relative">
                        <a href={deal.isAnonymous ? `/user/subscription` : `/business-for-sale/${deal.transaction_slug}`} target="_blank" rel="noopener noreferrer">
                            <img src={(deal.image !== null && deal.image !== '') 
                                    ? getImageUrl(deal.image, true)
                                    : process.env.PUBLIC_URL + '/images/empty.jpg'
                                } alt="" style={{ }} />
                                <div className="business-card_price_button" style={{ position: 'absolute', bottom: 0 }}>
                                    <div style={{ borderRadius: 0 }} class="btn btn-primary w-100">Price: {(deal.hide_price || !deal.price) ? 'Not Specified' : deal.price }</div>
                                </div> 
                        </a>
                    </div>
                    <div class="business-card__content business-card-list-item" style={{ width: '100%' }}>
                        <div class="business-card__actions">

                            {this.context.user.user_type_id !== USER_TYPE.BROKER && savedListings && savedListings.includes(deal.id) ?
                                <button style={{color: 'red'}} className="btn btn-unstyled" onClick={(e) => {
                                    e.stopPropagation();
                                    onAddToFavorite(deal)
                                }}>
                                    <svg style={{fontSize: 20}} className="icon" viewBox="0 0 24 23" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M12.0049 1.96984C18.6619 -4.87316 35.3059 7.10134 12.0049 22.4988C-11.2961 7.10284 5.34792 -4.87316 12.0049 1.96984Z"
                                              fill="currentColor"/>
                                    </svg>
                                </button> :
                                this.context.user.user_type_id !== USER_TYPE.BROKER && !!onAddToFavorite ? <button style={{color: 'gray'}} onClick={(e) => {
                                        e.stopPropagation();
                                        onAddToFavorite(deal)
                                    }} className="btn btn-unstyled">
                                        <svg style={{fontSize: 20}} className="icon" viewBox="0 0 24 23" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12.0023 4.12093L10.9268 3.01543C8.40228 0.420428 3.77328 1.31593 2.10228 4.57843C1.31778 6.11293 1.14078 8.32843 2.57328 11.1559C3.95328 13.8784 6.82428 17.1394 12.0023 20.6914C17.1803 17.1394 20.0498 13.8784 21.4313 11.1559C22.8638 8.32693 22.6883 6.11293 21.9023 4.57843C20.2313 1.31593 15.6023 0.418928 13.0778 3.01393L12.0023 4.12093ZM12.0023 22.4989C-10.9972 7.30093 4.92078 -4.56107 11.7383 1.71343C11.8283 1.79593 11.9168 1.88143 12.0023 1.96993C12.0869 1.88151 12.175 1.79644 12.2663 1.71493C19.0823 -4.56407 35.0018 7.29943 12.0023 22.4989Z"
                                                  fill="currentColor"/>
                                        </svg>
                                    </button>
                                    : ''


                            }
                        {this.context.user.user_type_id !== USER_TYPE.BROKER && onHideDeal && <button class="btn btn-unstyled" onClick={(e) => {
                            e.stopPropagation();
                            onHideDeal(deal.id)
                        }}>
                            <svg style={{ fontSize: 20 }} class="icon" viewBox="0 0 49 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M48.6655 20.4711C45.6913 16.5744 42.1191 13.1729 38.0816 10.3929L44.987 3.48748C45.6822 2.81606 45.7015 1.70824 45.0301 1.01303C44.3587 0.317821 43.2509 0.298544 42.5556 0.969964C42.5411 0.984011 42.5267 0.998367 42.5126 1.01303L34.9684 8.5502C31.7573 6.78285 28.1647 5.82197 24.5 5.75019C11.2001 5.75019 0.771984 19.8691 0.334454 20.471C-0.111485 21.0845 -0.111485 21.9154 0.334454 22.5289C3.30867 26.4256 6.88079 29.8271 10.9183 32.6071L4.01291 39.5125C3.3177 40.1839 3.29842 41.2918 3.96984 41.987C4.64126 42.6822 5.74908 42.7014 6.44429 42.03C6.45885 42.016 6.4732 42.0016 6.48735 41.987L14.0315 34.4498C17.2427 36.2171 20.8352 37.178 24.4999 37.2498C37.7998 37.2498 48.228 23.1309 48.6655 22.5289C49.1115 21.9155 49.1115 21.0846 48.6655 20.4711ZM13.4628 30.0679C9.89788 27.6936 6.70238 24.8066 3.97968 21.5C6.55565 18.3798 14.91 9.25012 24.5 9.25012C27.2285 9.30529 29.911 9.96266 32.3557 11.1751L29.3177 14.2131C25.2982 11.5445 19.8764 12.6396 17.2078 16.6591C15.2639 19.5871 15.2639 23.395 17.2078 26.3229L13.4628 30.0679ZM29.225 19.2513C29.5675 19.9516 29.747 20.7204 29.75 21.5C29.75 24.3995 27.3995 26.75 24.5 26.75C23.7204 26.747 22.9516 26.5675 22.2513 26.225L29.225 19.2513ZM19.7751 23.7487C19.4326 23.0484 19.253 22.2795 19.2501 21.4999C19.2501 18.6005 21.6005 16.25 24.5 16.25C25.2796 16.253 26.0484 16.4325 26.7488 16.775L19.7751 23.7487ZM24.5 33.7499C21.7716 33.6947 19.0892 33.0373 16.6444 31.8249L19.6824 28.7869C23.7019 31.4555 29.1237 30.3604 31.7922 26.3409C33.7361 23.4129 33.7361 19.605 31.7922 16.6771L35.5372 12.9321C39.1022 15.3064 42.2977 18.1935 45.0204 21.4999C42.4443 24.6203 34.0899 33.7499 24.5 33.7499Z" fill="currentColor"></path>
                            </svg>
                        </button>}
                        </div>
                        <h3 class="business-card__title">
                            <a style={{ color: '#373c41' }} href={deal.isAnonymous ? `/user/subscription` : `/business-for-sale/${deal.transaction_slug}`} target="_blank" rel="noopener noreferrer">
                                {this.sliceText(deal.name, 50)}
                            </a>
                        </h3>
                        <p class="d-flex" style={{ fontSize: 16 }}>

                            {deal.completed === 2 && this.context.isAdmin && <span style={{ backgroundColor: 'transparent', border: '1px solid black', display: 'inline-flex', marginBottom: 5, marginRight: 10 }} class="badge text-dark">
                  Private Deal
              </span>}
                            {deal.guru_id === 0 && this.context.isAdmin && <span style={{ backgroundColor: 'transparent', border: '1px solid black', marginRight: 10 }} class="badge text-dark">
                                <i style={{ color: 'gold' }} class="fas fa-star"/> Premium Deal
                            </span>}

                            {deal.isConcierge && <span style={{ backgroundColor: '#58ae3a', border: '1px solid black' }} class="badge text-dark">
                                <i style={{ color: 'gold' }} class="fa-solid fa-crown"/> Concierge Deal
                                </span>
                            }
                            {deal.seller_financing && <span style={{ backgroundColor: 'transparent', border: '1px solid black' }} className={`badge text-dark ${deal.guru_id === 0 ? 'ms-1' : 'me-1'}`}>
                                Seller Financing Available
                            </span>}
                            {deal.keywords === 'FSBO' && <span style={{ backgroundColor: 'transparent', border: '1px solid black' }} className={`badge text-dark`}>
                                <i style={{ color: '#3890da' }} className="far fa-gem"/>{` Proprietary Deal`}
                            </span>}
                        </p>
                        {deal.location_address && <p class="business-card__location d-flex">
                            <span class="me-1">
                                <svg class="icon" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z" fill="currentColor"></path>
                                </svg>
                            </span>
                            <span>{deal.location_address}</span>
                        </p>}
                        <p className="business-card__description">
                        {deal.isAnonymous ? <div style={{ position: 'relative' }}>
                            <div style={{ filter: 'blur(4px)' }}>
                                {this.sliceText(deal.description, 200)}
                            </div>
                            <div style={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <p className="text-center">
                                    <NavLink style={{ fontSize: 14 }} href="/user/subscription" className="btn btn-success"><i className="fas fa-lock" style={{marginRight: 3}}/> Upgrade To View Description</NavLink>
                                </p>
                            </div>
                        </div> : this.sliceText(deal.description, 100)}
                        </p>

                        {this.context.user.user_type_id !== USER_TYPE.BROKER && lastNote && <p class="business-card__description chat-body__message-body" style={{ whiteSpace: 'normal', backgroundColor: '#EAF4FC', padding: '10px 10px' }}>
                            <Link style={{ wordBreak: 'break-all' }} className='no-link-style' to={'/user/favorites'}>{lastNote}</Link>
                        </p>}
                        {
                            !!withNotes && <div onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}>
                                <DealNotes
                                    notes={deal.notes}
                                    dealName={this.getDealName(deal)}
                                    updateNotes={this.props.updateNotes}
                                    dealId={deal.id}
                                />
                            </div>
                        }
                        <div class="row">
                            <div class="col">
                                <p>
                                    <span class="font-weight-bolder">Revenue: </span>
                                    <p class="text-primary-blue">{deal.revenue || 'Not Specified'}</p>
                                </p>
                            </div>
                            <div class="col">
                                <p>
                                    <span class="font-weight-bolder">SDE: </span>
                                    <p class="text-primary-blue">{deal.cashflow || 'Not Specified'}</p>
                                </p>
                            </div>
                            <div class="col">
                                <p>
                                    <span class="font-weight-bolder">EBITDA: </span>
                                    <p class="text-primary-blue">{deal.ebitda || 'Not Specified'}</p>
                                </p>
                            </div>
                            {
                                !deal.isAnonymous && <div className="col">
                                    <a href={`/business-for-sale/${deal.transaction_slug}`} target="_blank"
                                       rel="noopener noreferrer" className="btn btn-primary btn-deal-cta text-nowrap">View details</a>
                                </div>
                            }

                        </div>
                       
                    </div>
                </div>
            )
        }
    }
}
