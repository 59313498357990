import React from "react";
import PropTypes from "prop-types";
import {SubscriptionLevels} from "../../../../../constants/subscriptionLevels";
import AvailableOption from "./AvailableOption";

const ConciergeOption = ({
    premium,
    premiumUntil,
                         premiumLevel,
                         userStatus,
                             annual,
                     }) => {

    return (

        <div className="col-md-4 col-sm-12" style={{maxWidth: 375, marginBottom: 32}}>
            <div className="pricing-card">
                <div className="pricing-card-head">
                    <h2 className="pricing-card__title">Concierge</h2>
                    <p className="pricing-card__price" style={{fontSize: 18, lineHeight: '20px', padding: '7px 0'}}>
                        Full-Service Buy-Side Partnerships
                    </p>
                    <p className="pricing-card__annual">
                        Application Only
                    </p>
                    <div className="content-divider" style={{marginTop: !annual ? 42 : 0}}/>
                </div>
                {/* <h3 class="pricing-card__title-sm">Everything in Starter, plus:</h3> */}
                <ul className="pricing-card__features">
                    <AvailableOption>Exclusivity by investment mandate</AvailableOption>
                    <AvailableOption>On-market sourcing (broker deals)</AvailableOption>
                    <AvailableOption>Off-market sourcing (proprietary deals)</AvailableOption>
                    <AvailableOption>Omni-channel prospecting</AvailableOption>
                    <AvailableOption>Custom dashboard & deal pipeline</AvailableOption>
                    <AvailableOption>Dedicated account manager & team</AvailableOption>
                    <AvailableOption>Enterprise CRM Integration</AvailableOption>
                    <AvailableOption>OmniSource™ Team Access</AvailableOption>
                    <AvailableOption>Tracked inbound content</AvailableOption>
                </ul>

                <div className="position-relative text-center mt-auto">

                    {userStatus === '1'
                        && <a
                            href="https://www.biznexus.com/demo"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`btn ${premium && premiumLevel >= SubscriptionLevels.CONCIERGE && !premiumUntil
                                ? 'btn-primary-blue'
                                : 'btn-dark'} pricing-card__select-btn`}
                        >
                            Request Demo
                        </a>}

                </div>
            </div>
        </div>
    )
}

ConciergeOption.propTypes = {
    premiumLevel: PropTypes.number,
    userStatus: PropTypes.number,
}

export default ConciergeOption
