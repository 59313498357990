import React from 'react';
import Geosuggest from '@ubilabs/react-geosuggest';
import {Typeahead} from 'react-bootstrap-typeahead';
import Select from 'react-select';
import {
    Button,
    Col,
    Collapse,
    Container,
    Input,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Label,
    Row,
    UncontrolledDropdown,
    ModalFooter,
    Modal,
    ModalBody,
    ModalHeader, Dropdown
} from 'reactstrap';
import {connect} from 'react-redux';
import { formatCurrency } from '../util';

class SearchComponent extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            errors: {},
            message: '',
            gridview: '12',
            categoryOption: '',
            categorylist: [],
            dateaddedOption: '',
            shortbyOption: '',
            location: '',
            Lat: '',
            Lng: '',
            Searchcollapse: true,
            AdvanceSearchToggle: 'none',
            minpricerangeselected: '',
            maxpricerangeselected: '',
            mincashflowselected: '',
            maxcashflowselected: '',
            minrevenueselected: '',
            maxrevenueselected: '',
            minebitdaselected: '',
            maxebitdaselected: '',
            brokername: '',
            brokerprofile_pic: '',
            brokerslug_url: '',
            brokerfirm_name: '',
            brokerptype: '',
            brokerarea_served: '',
            brokerreviews: '',
            brokerratings: '',
            brokertranscount: '',
            brokerlistcount: '',
            orderby: 'listing_date',
            SearchResultList: [],
            franchiseRecordListing: [],
            totalrecords: '',
            activePage: 1,
            staticNumberOfRow: 25,
            showingData: '',
            showSavedSearch: false,
            savedSearch: null,
            modal: false,
            savedSearchName: '',
            guideModalOpen: false,
            offMarket: 'yes',

            premiumModal: false,
            dropdownRevenueMultiple: false,
            dropdownCashflowMultiple: false,
            dropdownEBITDAMultiple: false,

            locationType: '',
            city: '',
            state: '',
            country: ''
        };
        this.Searchtoggle = this.Searchtoggle.bind(this);
        this.SubmitSearchResult = this.SubmitSearchResult.bind(this);

    }

    createMarkup(data) {
        return { __html: data };
    }

    Searchtoggle(e) {
        e.preventDefault();
        this.setState({ Searchcollapse: !this.state.Searchcollapse });
        if (this.state.AdvanceSearchToggle === '') {
            this.setState({ AdvanceSearchToggle: 'none' });
        } else {
            this.setState({ AdvanceSearchToggle: '' });
        }
    }

    getlistinglist = async () => {
        await fetch(process.env.REACT_APP_API_ROOT + 'franchise/', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        }).then((res) => {
            if (res.status < 200 || res.status >= 300) {
                //console.log("Error 401");
                //console.log(res);
                if (res.status === 401) {
                    this.props.history.push('/');
                } else {
                    return res.json().then(err => { throw err; });
                }
            } else { return res.json() }
        })
            .then(
                (res) => {
                    //console.log(res);               
                    this.setState({ franchiseRecordListing:res });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        //error
                    });
                }
            )
    } 

    fetchSavedSearch() {

        if(!localStorage.getItem('token')) {
            return;
        }

        return fetch(process.env.REACT_APP_API_ROOT + 'user/saved_search', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(res => res.json()).then(res => {
            this.setState({ 
                savedSearchList: res.savedSearch.map(s => ({
                    label: s.name,
                    value: s,
                    id: s.id,
                }))
            });
        });
    }

    componentDidMount() {

        fetch(process.env.REACT_APP_API_ROOT + 'industry/legacy', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => res.json())
            .then(
                (res) => {
                    let categorylistarray = [];
                    let temp = {};
                    temp = { 
                        "value": "All",
                        "label": "All",
                        "children": [],
                    };
                    categorylistarray.push(temp);
                    // eslint-disable-next-line
                    res.map((categories, i) => {
                        temp = {
                            "value": categories.value,
                            "label": categories.label,
                            "children": categories.children.map(c => c.value)
                        };
                        categorylistarray.push(temp);
                    });
                    /*
                    let temp1 = {
                        "value": "Franchise Opportunities",
                        "label": "Franchise Opportunities"
                    }
                    categorylistarray.push(temp1);*/
                    if(localStorage.getItem('token')) {
                        this.fetchSavedSearch()
                        .then(() => {
                            this.setState({ categorylist: categorylistarray }, () => { this.parseUrlToSearch();});
                        });
                    } else {
                        this.setState({ categorylist: categorylistarray }, () => { this.parseUrlToSearch();});
                    }
                    

                    
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        //error
                    });
                }
            );
        this.gettopbrockerlist();
        window.onpopstate = () => {
            this.parseUrlToSearch(true);
        }

        this.fetchPremium();


    }

    fetchPremium() {

        if(!localStorage.getItem('token')) {
            return;
        }

        return fetch(process.env.REACT_APP_API_ROOT + 'user/header', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(res => res.json()).then(res => {
            this.setState({ 
                premium: res.premium,
            });
        });
    }

    componentWillUnmount() {
        window.onpopstate = null;
    }

    parseUrlToSearch = (isFromPopstate = false) => {
        const queryParams = {};
        
        const categoryOption = null; 
        
        this.setState({
            location: queryParams.location,
            city: queryParams.city,
            state: queryParams.state,
            country: queryParams.country,
            locationType: queryParams.locationType,
            categoryOption,
            activePage: queryParams.page,
            Lat: queryParams.from_latitude,
            Lng: queryParams.from_longitude,
            dateaddedOption: queryParams.date_added || '',
            fields: {
                minpricerange: queryParams.price_min,
                maxpricerange: queryParams.price_max,
                mincashflow: queryParams.cashflow_min,
                maxcashflow: queryParams.cashflow_max,
                minrevenue: queryParams.revenue_min,
                maxrevenue: queryParams.revenue_max,
                searchbox: queryParams.name,
                radius: queryParams.radius,
            }
        }, () => {
            if(queryParams.search_id) {
                const savedSearch = this.state.savedSearchList.find(l => l.id === parseInt(queryParams.search_id))
                const data = JSON.parse(savedSearch.value.data);
                this.setState({ savedSearch, Lat: data.from_latitude, Lng: data.from_longitude, fields: data.fields, location: data.location, categoryOption: data.categoryOption }, () => {
                    // this.getSearchResultlist({
                    //     orderby: this.state.orderby,
                    //     page: this.state.activePage,
                    // }, isFromPopstate);
                });
            } else {
                // this.getSearchResultlist({
                //     orderby: this.state.orderby,
                //     page: this.state.activePage,
                // }, isFromPopstate);
            }
        });

        
    };


    gettopbrockerlist = () => {
        fetch(process.env.REACT_APP_API_ROOT + 'profile/featured_profile', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(function (res) {
                if (res.status < 200 || res.status >= 300) {
                    //console.log("Error 401");
                    //console.log(res);
                    //return res.json().then(err => {throw err;});
                    throw res;
                    //throw Error(.statusText);
                } else { return res.json() }

            })
            .then((res) => {
                //console.log(res);
                if (res.length > 0) {
                    let firstname = '';
                    let fullname = res[0].name.match(/(\w+)(?:\s[^\s]+)?\s(\w+).*/);
                    if (fullname !== null) {
                        firstname = fullname[1] + " " + fullname[2].charAt(0) + ".";
                    }
                    else {
                        firstname = res[0].name;
                    }
                    this.setState({ brokername: firstname, brokerprofile_pic: res[0].profile_pic, brokerslug_url: res[0].slug_url, brokerfirm_name: res[0].firm_name, brokerptype: res[0].ptype, brokerarea_served: res[0].area_served, brokerreviews: res[0].reviews, brokerratings: res[0].ratings, brokertranscount: res[0].transcount, brokerlistcount: res[0].listcount, });
                }
            })
            .catch((err) => {
                this.setState({
                    //error
                });
            })
    }
    handleSearchResultValidation() {
        // let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleGeosuggestChange = (location) => {
        if (location !== undefined) {
            let addressComponent = location.gmaps.address_components;
            let locationType = '';
            switch(addressComponent[0].types[0]) {
                case 'locality': locationType = 'city'; break;
                case 'administrative_area_level_1': locationType = 'state'; break;
                case 'country': locationType = 'country'; break;
                default: locationType = 'point';
            }
            for (var j = 0; j < location.gmaps.address_components.length; j++) {
                if (addressComponent[j].types[0] === 'locality') {
                    this.setState({ city: addressComponent[j].long_name });
                }
                if (addressComponent[j].types[0] === 'administrative_area_level_1') {
                    this.setState({ state: addressComponent[j].short_name });
                }
                if (addressComponent[j].types[0] === 'country') {
                    this.setState({ country: addressComponent[j].short_name });
                }
            }
            
            this.setState({ Lat: location.location.lat });
            this.setState({ Lng: location.location.lng });
            this.setState({ location: location.label, locationType });
        }
    }
    handlecategoryChange = (categoryOption) => {
        
        this.setState({ categoryOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (categoryOption) {
            // console.log(`Selected: ${shortbyOption.label}`);
        }
    }
    handledateaddedChange = (dateaddedOption) => {
        this.setState({ dateaddedOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (dateaddedOption) {
            // console.log(`Selected: ${shortbyOption.label}`);
        }
    }
    handleChangeGridview = () => {
        this.setState({ gridview: "3" });
    }
    handleChangeListview = () => {
        this.setState({ gridview: "12" });
    }

    handleChangeSearch(field, e) {
        if (e.target !== '' && e.target !== undefined) {
            let fields = this.state.fields;
            fields[field] = e.target.value;
            if (e.target.name === 'minpricerange') {
                let minpricerange = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxpricerange') {
                let maxpricerange = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'mincashflow') {
                let mincashflow = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = mincashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxcashflow') {
                let maxcashflow = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxcashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'minrevenue') {
                let minrevenue = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxrevenue') {
                let maxrevenue = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'asset_sale') {
                fields[field] = e.target.checked;
            }
            this.setState({ fields });
        }
    }
    handleChangeMinPriceRange = (e) => {
        let fields = this.state.fields;
        fields["minpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, minpricerangeselected: e.target.value });
    }
    handleChangeMaxPriceRange = (e) => {
        let fields = this.state.fields;
        fields["maxpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, maxpricerangeselected: e.target.value });
    }
    handleChangeMincashflow = (e) => {
        let fields = this.state.fields;
        fields["mincashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, mincashflowselected: e.target.value });
    }
    handleChangeMaxcashflow = (e) => {
        let fields = this.state.fields;
        fields["maxcashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, maxcashflowselected: e.target.value });
    }
    handleChangeMinRevenue = (e) => {
        let fields = this.state.fields;
        fields["minrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, minrevenueselected: e.target.value });
    }
    handleChangeMaxRevenue = (e) => {
        let fields = this.state.fields;
        fields["maxrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, maxrevenueselected: e.target.value });
    }
    handleChangeMinEbitda = (e) => {
        let fields = this.state.fields;
        fields["minebitda"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, minebitdaselected: e.target.value });
    }
    handleChangeMaxEbitda = (e) => {
        let fields = this.state.fields;
        fields["maxebitda"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, maxebitdaselected: e.target.value });
    }
    SubmitSearchResult = (e) => {
        e.preventDefault();
        if (this.handleSearchResultValidation()) {
            //let fields = this.state.fields;
            this.getSearchResultlist({
                orderby: this.state.orderby,
                page: 1
            });

        } else {
            //console.log("Form has errors.")
        }
    }
    handleshortbyChange = (shortbyOption) => {
        this.setState({ shortbyOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (shortbyOption) {
            this.setState({ orderby: shortbyOption.value });
            this.getSearchResultlist({
                orderby: shortbyOption.value,
                page: 1
            });
            // console.log(`Selected: ${shortbyOption.label}`);
        }
    }
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
        this.getSearchResultlist({
            orderby: this.state.orderby,
            page: pageNumber,
        });
    }
    handleChangeClear = async (e) => {
        e.preventDefault();
        let fields = this.state.fields;
        // eslint-disable-next-line
        fields["searchbox"] = '', fields["minpricerange"] = '', fields["maxpricerange"] = '', fields["mincashflow"] = '', fields["maxcashflow"] = '', fields["minrevenue"] = '', fields["maxrevenue"] = '', fields["radius"] = '';
        const ClearResult = () => {
            this.refs.location.update('');
            this.setState({ savedSearch:  null, categoryOption: '', dateaddedOption: '', minpricerangeselected: '', maxpricerangeselected: '', mincashflowselected: '', maxcashflowselected: '', minrevenueselected: '', maxrevenueselected: '', location: '' });
            var ip = localStorage.getItem("ip");
            // localStorage.clear();
            localStorage.setItem('ip', ip);
        };
        await ClearResult();
        
        this.getSearchResultlist({
            orderby: this.state.orderby,
            page: 1,
        });
    }
    getSearchResultlist = ({ orderby, page }, isFromPopstate = false) => {
        
        
        this.setState({ activePage: page });
        let location, latitude, longitude, minpricerangeselected, maxpricerangeselected, mincashflowselected, maxcashflowselected, minrevenueselected, maxrevenueselected, dateaddedOption, minebitdaselected, maxebitdaselected = '';
        let fields = this.state.fields;
        if (this.state.location !== '' && this.state.location !== undefined) {
            location = this.state.location;
            latitude = this.state.Lat;
            longitude = this.state.Lng;
        }
        else {
            if (localStorage.getItem("buyloaction") !== null && localStorage.getItem("buyloaction") !== "undefined") {
                location = localStorage.getItem("buyloaction");
                latitude = localStorage.getItem("buylatitude");
                longitude = localStorage.getItem("buylongitude");
            }
            else {
                location = "";
                latitude = ""
                longitude = "";
            }
        }

        if (this.state.dateaddedOption.value !== undefined) {
            dateaddedOption = this.state.dateaddedOption.value;
        }
        else {
            dateaddedOption = this.state.dateaddedOption;
        }
        if (fields["minpricerange"] !== undefined) {
            if (this.state.minpricerangeselected === "Any Min") {
                minpricerangeselected = '';
            }
            else {
                minpricerangeselected = fields["minpricerange"].replace(/,/g, "");
            }
        }
        if (fields["maxpricerange"] !== undefined) {
            if (this.state.maxpricerangeselected === "Any Max") {
                maxpricerangeselected = '';
            }
            else {
                maxpricerangeselected = fields["maxpricerange"].replace(/,/g, "");
            }
        }
        if (fields["mincashflow"] !== undefined) {
            if (this.state.mincashflowselected === "Any Min") {
                mincashflowselected = '';
            }
            else {
                mincashflowselected = fields["mincashflow"].replace(/,/g, "");
            }
        }
        if (fields["maxcashflow"] !== undefined) {
            if (this.state.maxcashflowselected === "Any Max") {
                maxcashflowselected = '';
            }
            else {
                maxcashflowselected = fields["maxcashflow"].replace(/,/g, "");
            }
        }
        if (fields["minrevenue"] !== undefined) {
            if (this.state.minrevenueselected === "Any Min") {
                minrevenueselected = '';
            }
            else {
                minrevenueselected = fields["minrevenue"].replace(/,/g, "");
            }
        }
        if (fields["maxrevenue"] !== undefined) {
            if (this.state.maxrevenueselected === "Any Max") {
                maxrevenueselected = '';
            }
            else {
                maxrevenueselected = fields["maxrevenue"].replace(/,/g, "");
            }
        }
        if (fields["minebitda"] !== undefined) {
            if (this.state.minebitdaselected === "Any Min") {
                minebitdaselected = '';
            }
            else {
                minebitdaselected = fields["minebitda"].replace(/,/g, "");
            }
        }
        if (fields["maxebitda"] !== undefined) {
            if (this.state.maxebitdaselected === "Any Max") {
                maxebitdaselected = '';
            }
            else {
                maxebitdaselected = fields["maxebitda"].replace(/,/g, "");
            }
        }


        const categoryOption = this.state.categoryOption && this.state.categoryOption.children.length ? [this.state.categoryOption.value].concat(this.state.categoryOption.children): null;
        
        const dataForQuery = {
          "location": location,
          "industry_id": this.state.categoryOption && this.state.categoryOption.value !== "All" ? categoryOption : null,
          "from_latitude": latitude,
          "from_longitude": longitude,
          "name": fields["searchbox"],
          "price_min": minpricerangeselected,
          "price_max": maxpricerangeselected,
          "cashflow_min": mincashflowselected,
          "cashflow_max": maxcashflowselected,
          "revenue_min": minrevenueselected,
          "revenue_max": maxrevenueselected,
          "ebitda_min": minebitdaselected,
          "ebitda_max": maxebitdaselected,
          "date_added": dateaddedOption === "" ? null : dateaddedOption,
          "orderby": orderby,
          "page": page,
          "radius": fields.radius,
          "rows": this.state.staticNumberOfRow,
          offMarket: this.state.offMarket,
          city: this.state.city,
          state: this.state.state,
          country: this.state.country,
          locationType: this.state.locationType,
          
        };

        if(fields['asset_sale']) {
            dataForQuery.asset_sale = '1';
        }

        if(fields['minebitda_multiple'] && fields['minebitda_multiple'] !== 'Any Max' && fields['minebitda_multiple'] !== 'Any Min') {
            dataForQuery.minebitda_multiple = fields['minebitda_multiple'];
        }

        if(fields['maxebitda_multiple'] && fields['maxebitda_multiple'] !== 'Any Max' && fields['maxebitda_multiple'] !== 'Any Min') {
            dataForQuery.maxebitda_multiple = fields['maxebitda_multiple'];
        }

        if(fields['minrevenue_multiple'] && fields['minrevenue_multiple'] !== 'Any Max' && fields['minrevenue_multiple'] !== 'Any Min') {
            dataForQuery.minrevenue_multiple = fields['minrevenue_multiple'];
        }

        if(fields['maxrevenue_multiple'] && fields['maxrevenue_multiple'] !== 'Any Max' && fields['maxrevenue_multiple'] !== 'Any Min') {
            dataForQuery.maxrevenue_multiple = fields['maxrevenue_multiple'];
        }

        if(fields['mincashflow_multiple'] && fields['mincashflow_multiple'] !== 'Any Max' && fields['mincashflow_multiple'] !== 'Any Min') {
            dataForQuery.mincashflow_multiple = fields['mincashflow_multiple'];
        }

        if(fields['maxcashflow_multiple'] && fields['maxcashflow_multiple'] !== 'Any Max' && fields['maxcashflow_multiple'] !== 'Any Min') {
            dataForQuery.maxcashflow_multiple = fields['maxcashflow_multiple'];
        }
      
        // this.props.getFeaturedTransactions(industry_id ? industry_id : '');
        if(!(this.state.savedSearch && this.state.savedSearch.value)) {
            const recentSearches = localStorage.getItem('recent_searches') ? JSON.parse(localStorage.getItem('recent_searches')) : [];
            recentSearches.unshift(JSON.stringify({
                ...dataForQuery, categoryOption: this.state.categoryOption ? this.state.categoryOption.label : ''
            }));
            localStorage.setItem('recent_searches', JSON.stringify(recentSearches.slice(0, 3)));
        }
        window.location = '/advanced-search/' + this.makeSearchQueryString(dataForQuery);
    };

    makeSearchQueryString = searchData => {
        delete searchData.rows;
        // in future if this field will be added just remove line below
        delete searchData.orderby;

        let output = '';
        for(let property in searchData){
            if(searchData.hasOwnProperty(property) && searchData[property]){
                if(!output){
                    output = '?'
                } else {
                    output += '&'
                }
                output += property + '=' +  searchData[property];
            }
        }
        return output;
    };


    makeSearch = searchData => {
      fetch(process.env.REACT_APP_API_ROOT + 'transaction/search', {
        method: 'post',
        body: searchData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then(function (res) {
          if (res.status < 200 || res.status >= 300) {
            //console.log("Error 401");
            //console.log(res);
            //return res.json().then(err => {throw err;});
            throw res;
            //throw Error(.statusText);
          } else { return res.json() }
        })
        .then((res) => {
          // document.getElementById('loader').setAttribute("style", "display: none;");
          if (res.status === 200) {
            this.setState({ SearchResultList: res.list, totalrecords: res.total, message: '' })
          } else {
            this.setState({ SearchResultList: [], message: res.message, totalrecords: 0 })
          }
          localStorage.setItem("buyloaction","");
          localStorage.setItem("buylatitude","")
          localStorage.setItem("buylongitude","");
          localStorage.setItem("buycategory","");

        })
        .catch((err) => {
          this.setState({
            //error
          });
        })
    };


    starRatings = (stars) => {
        let ratings = [];
        let filledstars = stars;
        let blankstars = 5 - stars;
        for (let k = 0; k < filledstars; k++) {
            ratings.push(<i className="sprite i-starfill-md" key={"filledstars" + k}></i>);
        }
        for (let k = 0; k < blankstars; k++) {
            ratings.push(<i className="sprite i-star-md" key={"blankstars" + k}></i>);
        }
        return ratings;
    }
    truncString(str, max, add) {
        add = add || '...';
        return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
    };

    handleSaveSearh = () => {
        if(! this.state.savedSearchName.trim()) {
            return;
        }

        fetch(process.env.REACT_APP_API_ROOT + 'user/saved_search', {
            method: 'post',
            body: JSON.stringify({ name: this.state.savedSearchName, data: JSON.stringify({
                fields: this.state.fields,
                location: this.state.location,
                categoryOption: this.state.categoryOption,
                from_latitude: this.state.Lat,
                from_longitude: this.state.Lng,
            }) }),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*",
              'x-access-token': localStorage.getItem('token'),
            },
        }).then(() => {
            this.setState({ showSavedSearch: false });
            this.fetchSavedSearch();
        })
    }

    handleDeleteSavedSearch = () => {
        fetch(process.env.REACT_APP_API_ROOT + 'user/saved_search', {
            method: 'delete',
            body: JSON.stringify({ id:  this.state.savedSearch.value.id}),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*",
              'x-access-token': localStorage.getItem('token'),
            },
        }).then(() => {
            this.setState({ showSavedSearch: false, savedSearch: null });
            this.fetchSavedSearch();
        });
    }

    isToUpgrade() {
        return !this.state.premium && localStorage.getItem('token');
    }

     //
     handleChangeMinEbitdaMultiple = (e) => {
        let fields = this.state.fields;
        fields["minebitda_multiple"] = e.target.value;
        this.setState({ fields, minebitdamultipleselected: e.target.value });
    }
    handleChangeMaxEbitdaMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxebitda_multiple"] = e.target.value;
        this.setState({ fields, maxebitdamultipleselected: e.target.value });
    }

    handleChangeMinRevenueMultiple = (e) => {
        let fields = this.state.fields;
        fields["minrevenue_multiple"] = e.target.value;
        this.setState({ fields, minrevenuemultipleselected: e.target.value });
    }
    handleChangeMaxRevenueMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxrevenue_multiple"] = e.target.value;
        this.setState({ fields, maxrevenuemultipleselected: e.target.value });
    }

    handleChangeMinCashflowMultiple = (e) => {
        let fields = this.state.fields;
        fields["mincashflow_multiple"] = e.target.value;
        this.setState({ fields, mincashflowmultipleselected: e.target.value });
    }
    handleChangeMaxCashflowMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxcashflow_multiple"] = e.target.value;
        this.setState({ fields, maxcashflowmultipleselected: e.target.value });
    }
    //

    render() {
        const { categoryOption, dateaddedOption, } = this.state;
        return (
            <Container className="search-results">
                <Row className="justify-content-center">
                    <Col xl="11">
                         <div className="search-control" style={{ marginTop: this.props.notHomePage ? '0px' : '-81px' }}>
                                <Form onSubmit={this.SubmitSearchResult}>
                                    <div className="form-inline search-index">
                                        <FormGroup style={{ width: this.props.brokerHome ? '164px' : '159px' }} className="col-sm-3 p-0">
                                            <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                                name="category"
                                                id="category"
                                                isSearchable={false}
                                                isClearable={false}
                                                onChange={this.handlecategoryChange}
                                                options={this.state.categorylist}
                                                value={categoryOption}
                                                ref="category"
                                            />
                                        </FormGroup>
                                        <FormGroup className="col-sm-3 p-0">
                                            <Geosuggest
                                                inputClassName="search-input form-control"
                                                id="location"
                                                name="location"
                                                ref="location"
                                                placeholder="Search by location"
                                                onSuggestSelect={this.handleGeosuggestChange}
                                                initialValue={this.state.location}
                                            />

                                        </FormGroup>
                                        <FormGroup className="col-sm-2 p-0">
                                            <Input type="number"
                                                   value={this.state.fields.radius}
                                                   placeholder="Radius in ml"
                                                   onChange={(e) => {
                                                        const { fields } = this.state;
                                                        fields.radius = e.target.value;
                                                        this.setState({ fields });
                                                    }}
                                                    />
                                        </FormGroup>
                                        <FormGroup className="col-sm-3 p-0 custom-menu-category" style={{ width: this.props.notHomePage ? '180px' : '185px' }}>
                                            <Typeahead
                                                multiple
                                                allowNew
                                                id="searchbox"
                                                className={'searchbox'}
                                                newSelectionPrefix="Add a keyword"
                                                name="searchbox"
                                                placeholder="Search keywords"
                                                  
                                                selectHintOnEnter={true}
                                                emptyLabel={false}
                                                onKeyDown={(e)=>{
                                                    if (e.which === 13 || e.which === 188) {
                                                        const instance = this.ref_tags;

                                                        if(instance.getInput().value.trim()) {
                                                            const {initialItem} = instance.state;
                                                            instance._handleMenuItemSelect(initialItem, e);
                                                            e.preventDefault();
                                                        }
                                                    }
                                                }}
                                                ref={(typeahead) => this.ref_tags = typeahead}
                                                onChange={(selected) => {
                                                    const { fields } = this.state;
                                                    fields.searchbox = selected.map(s => s.label).join(',');
                                                    this.setState({ fields });
                                                }}
                                                selected={this.state.fields.searchbox ? this.state.fields.searchbox.split(',').map(k => ({
                                                    label: k,
                                                })) : []}
                                                options={[]}
                                            />
                                        </FormGroup>
                                        <Button type="submit" className="btn-bluedark w-100 col-sm-2 pl-0 pr-0 d-none d-sm-block">Search Now</Button>
                                        
                                    </div>
                                    <Collapse isOpen>
                                        <div className="search-control-border">
                                            <Row>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle caret className="form-control"> Price Range {this.state.fields["minpricerange"] !== undefined && formatCurrency(this.state.fields["minpricerange"])}{this.state.fields["maxpricerange"] !== undefined && "-" + formatCurrency(this.state.fields["maxpricerange"])}</DropdownToggle>
                                                                <DropdownMenu>
                                                                    <div className="form-inline top-start">
                                                                        <FormGroup>
                                                                            <Label htmlFor="minpricerange" hidden>Min</Label>
                                                                            <Input type="text" name="minpricerange" id="minpricerange" ref="minpricerange" onChange={this.handleChangeSearch.bind(this, "minpricerange")} value={this.state.fields["minpricerange"]} placeholder="Min" />
                                                                        </FormGroup>
                                                                        <FormGroup className="line-hr"> &#8210; </FormGroup>
                                                                        <FormGroup>
                                                                            <Label htmlFor="maxpricerange" hidden>Max</Label>
                                                                            <Input type="text" name="maxpricerange" id="maxpricerange" ref="maxpricerange" onChange={this.handleChangeSearch.bind(this, "maxpricerange")} value={this.state.fields["maxpricerange"]} placeholder="Max" />
                                                                        </FormGroup>
                                                                    </div>
                                                                    <Row className="m-0">
                                                                        <Col className="p-0">
                                                                            <Input type="radio" id="minpricerange1" name="minpricerange" label="Any Min" value="Any Min" onChange={this.handleChangeMinPriceRange.bind()} />
                                                                            <Input type="radio" id="minpricerange2" disabled={this.state.maxpricerangeselected && 500000 > this.state.maxpricerangeselected ? "disabled" : ''} name="minpricerange" label="$500,000" value="500000" onChange={this.handleChangeMinPriceRange.bind()} />
                                                                            <Input type="radio" id="minpricerange3" disabled={this.state.maxpricerangeselected && 1000000 > this.state.maxpricerangeselected ? "disabled" : ''} name="minpricerange" label="$1,000,000" value="1000000" onChange={this.handleChangeMinPriceRange.bind()} />
                                                                            <Input type="radio" id="minpricerange4" disabled={this.state.maxpricerangeselected && 2500000 > this.state.maxpricerangeselected ? "disabled" : ''} name="minpricerange" label="$2,500,000" value="2500000" onChange={this.handleChangeMinPriceRange.bind()} />
                                                                            <Input type="radio" id="minpricerange5" disabled={this.state.maxpricerangeselected && 5000000 > this.state.maxpricerangeselected ? "disabled" : ''} name="minpricerange" label="$5,000,000" value="5000000" onChange={this.handleChangeMinPriceRange.bind()} />
                                                                            <Input type="radio" id="minpricerange8" disabled={this.state.maxpricerangeselected && 7500000 > this.state.maxpricerangeselected ? "disabled" : ''} name="minpricerange" label="$7,500,000" value="7500000" onChange={this.handleChangeMinPriceRange.bind()} />
                                                                            <Input type="radio" id="minpricerange9" disabled={this.state.maxpricerangeselected && 10000000 > this.state.maxpricerangeselected ? "disabled" : ''} name="minpricerange" label="$10,000,000" value="10000000" onChange={this.handleChangeMinPriceRange.bind()} />
                                                                            <Input type="radio" id="minpricerange10" disabled={this.state.maxpricerangeselected && 25000000 > this.state.maxpricerangeselected ? "disabled" : ''} name="minpricerange" label="$25,000,000" value="25000000" onChange={this.handleChangeMinPriceRange.bind()} />
                                                                            <Input type="radio" id="minpricerange11" disabled={this.state.maxpricerangeselected && 50000000 > this.state.maxpricerangeselected ? "disabled" : ''} name="minpricerange" label="$50,000,000" value="50000000" onChange={this.handleChangeMinPriceRange.bind()} />
                                                                        </Col>
                                                                        <Col className="p-0">
                                                                            <Input type="radio" id="maxpricerange1" name="maxpricerange" label="Any Max" value="Any Max" onChange={this.handleChangeMaxPriceRange.bind()} />
                                                                            <Input type="radio" id="maxpricerange2" disabled={500000 <= this.state.minpricerangeselected ? "disabled" : ''} name="maxpricerange" label="$500,000" value="500000" onChange={this.handleChangeMaxPriceRange.bind()} />
                                                                            <Input type="radio" id="maxpricerange3" disabled={1000000 <= this.state.minpricerangeselected ? "disabled" : ''} name="maxpricerange" label="$1,000,000" value="1000000" onChange={this.handleChangeMaxPriceRange.bind()} />
                                                                            <Input type="radio" id="maxpricerange4" disabled={2500000 <= this.state.minpricerangeselected ? "disabled" : ''} name="maxpricerange" label="$2,500,000" value="2500000" onChange={this.handleChangeMaxPriceRange.bind()} />
                                                                            <Input type="radio" id="maxpricerange7" disabled={5000000 <= this.state.minpricerangeselected ? "disabled" : ''} name="maxpricerange" label="$5,000,000" value="5000000" onChange={this.handleChangeMaxPriceRange.bind()} />
                                                                            <Input type="radio" id="maxpricerange8" disabled={7500000 <= this.state.minpricerangeselected ? "disabled" : ''} name="maxpricerange" label="$7,500,000" value="7500000" onChange={this.handleChangeMaxPriceRange.bind()} />
                                                                            <Input type="radio" id="maxpricerange9" disabled={10000000 <= this.state.minpricerangeselected ? "disabled" : ''} name="maxpricerange" label="$10,000,000" value="10000000" onChange={this.handleChangeMaxPriceRange.bind()} />
                                                                            <Input type="radio" id="maxpricerange10" disabled={25000000 <= this.state.minpricerangeselected ? "disabled" : ''} name="maxpricerange" label="$25,000,000" value="25000000" onChange={this.handleChangeMaxPriceRange.bind()} />
                                                                            <Input type="radio" id="maxpricerange11" disabled={50000000 <= this.state.minpricerangeselected ? "disabled" : ''} name="maxpricerange" label="$50,000,000" value="50000000" onChange={this.handleChangeMaxPriceRange.bind()} />
                                                                        </Col>
                                                                    </Row>
                                                                    <div className="form-inline bottom-start">
                                                                        <FormGroup>
                                                                            <Label htmlFor="minpricerange" hidden>Min</Label>
                                                                            <Input type="text" name="minpricerange" id="minpricerange" ref="minpricerange" onChange={this.handleChangeSearch.bind(this, "minpricerange")} value={this.state.fields["minpricerange"]} placeholder="Min" />
                                                                        </FormGroup>
                                                                        <FormGroup className="line-hr"> &#8210; </FormGroup>
                                                                        <FormGroup>
                                                                            <Label htmlFor="maxpricerange" hidden>Max</Label>
                                                                            <Input type="text" name="maxpricerange" id="maxpricerange" ref="maxpricerange" onChange={this.handleChangeSearch.bind(this, "maxpricerange")} value={this.state.fields["maxpricerange"]} placeholder="Max" />
                                                                        </FormGroup>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </Col>
                                                        <Col>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle caret className="form-control"> Annual Profit {this.state.fields["mincashflow"] !== undefined && formatCurrency(this.state.fields["mincashflow"])}{this.state.fields["maxcashflow"] !== undefined && "-" + formatCurrency(this.state.fields["maxcashflow"])} {this.state.fields["minebitda"] !== undefined && formatCurrency(this.state.fields["minebitda"])}{this.state.fields["maxebitda"] !== undefined && "-" + formatCurrency(this.state.fields["maxebitda"])}</DropdownToggle>
                                                                <DropdownMenu className="mme-0" style={{ width: '500px' }}>
                                                                    <Row className="annual_profit" style={{ width: '500px' }}>
                                                                        <Col>
                                                                            <div style={{ textAlign: 'center' }} className="form-inline top-start">
                                                                                <p style={{ margin: 'auto', fontSize: '14px' }}>SDE</p>
                                                                            </div>

                                                                            <div style={{ textAlign: 'center' }} className="form-inline top-start">
                                                                                <FormGroup style={{ margin: 'auto' }}>
                                                                                    <Label htmlFor="mincashflow" hidden>Min</Label>
                                                                                    <Input type="text" name="mincashflow" id="mincashflow" ref="mincashflow" onChange={this.handleChangeSearch.bind(this, "mincashflow")} value={this.state.fields["mincashflow"]} placeholder="Min" />
                                                                                </FormGroup>
                                                                                
                                                                                <FormGroup style={{ margin: 'auto' }}>
                                                                                    <Label htmlFor="maxcashflow" hidden>Max</Label>
                                                                                    <Input type="text" name="maxcashflow" id="maxcashflow" ref="maxcashflow" onChange={this.handleChangeSearch.bind(this, "maxcashflow")} value={this.state.fields["maxcashflow"]} placeholder="Max" />
                                                                                </FormGroup>
                                                                            </div>
                                                                            <Row className="m-0">
                                                                                <Col className="p-0">
                                                                                    <Input type="radio" id="mincashflow1" name="mincashflow" label="Any Min" value="Any Min" onChange={this.handleChangeMincashflow.bind()} />
                                                                                    <Input type="radio" id="mincashflow2" disabled={this.state.maxcashflowselected && 500000 > this.state.maxcashflowselected ? "disabled" : ''} name="mincashflow" label="$500,000" value="500000" onChange={this.handleChangeMincashflow.bind()} />
                                                                                    <Input type="radio" id="mincashflow3" disabled={this.state.maxcashflowselected && 1000000 > this.state.maxcashflowselected ? "disabled" : ''} name="mincashflow" label="$1,000,000" value="1000000" onChange={this.handleChangeMincashflow.bind()} />
                                                                                    <Input type="radio" id="mincashflow4" disabled={this.state.maxcashflowselected && 2500000 > this.state.maxcashflowselected ? "disabled" : ''} name="mincashflow" label="$2,500,000" value="2500000" onChange={this.handleChangeMincashflow.bind()} />
                                                                                    <Input type="radio" id="mincashflow5" disabled={this.state.maxcashflowselected && 5000000 > this.state.maxcashflowselected ? "disabled" : ''} name="mincashflow" label="$5,000,000" value="5000000" onChange={this.handleChangeMincashflow.bind()} />
                                                                                    <Input type="radio" id="mincashflow6" disabled={this.state.maxcashflowselected && 7500000 > this.state.maxcashflowselected ? "disabled" : ''} name="mincashflow" label="$7,500,000" value="7500000" onChange={this.handleChangeMincashflow.bind()} />
                                                                                    <Input type="radio" id="mincashflow7" disabled={this.state.maxcashflowselected && 10000000 > this.state.maxcashflowselected ? "disabled" : ''} name="mincashflow" label="$10,000,000" value="10000000" onChange={this.handleChangeMincashflow.bind()} />
                                                                                    <Input type="radio" id="mincashflow8" disabled={this.state.maxcashflowselected && 25000000 > this.state.maxcashflowselected ? "disabled" : ''} name="mincashflow" label="$25,000,000" value="25000000" onChange={this.handleChangeMincashflow.bind()} />
                                                                                    <Input type="radio" id="mincashflow9" disabled={this.state.maxcashflowselected && 50000000 > this.state.maxcashflowselected ? "disabled" : ''} name="mincashflow" label="$50,000,000" value="50000000" onChange={this.handleChangeMincashflow.bind()} />
                                                                                </Col>
                                                                                <Col className="p-0">
                                                                                    <Input type="radio" id="maxcashflow1" name="maxcashflow" label="Any Max" value="Any Max" onChange={this.handleChangeMaxcashflow.bind()} />
                                                                                    <Input type="radio" id="maxcashflow2" disabled={500000 <= this.state.mincashflowselected ? "disabled" : ''} name="maxcashflow" label="$500,000" value="500000" onChange={this.handleChangeMaxcashflow.bind()} />
                                                                                    <Input type="radio" id="maxcashflow3" disabled={1000000 <= this.state.mincashflowselected ? "disabled" : ''} name="maxcashflow" label="$1,000,000" value="1000000" onChange={this.handleChangeMaxcashflow.bind()} />
                                                                                    <Input type="radio" id="maxcashflow4" disabled={2500000 <= this.state.mincashflowselected ? "disabled" : ''} name="maxcashflow" label="$2,500,000" value="2500000" onChange={this.handleChangeMaxcashflow.bind()} />
                                                                                    <Input type="radio" id="maxcashflow5" disabled={5000000 <= this.state.mincashflowselected ? "disabled" : ''} name="maxcashflow" label="$5,000,000" value="5000000" onChange={this.handleChangeMaxcashflow.bind()} />
                                                                                    <Input type="radio" id="maxcashflow6" disabled={7500000 <= this.state.mincashflowselected ? "disabled" : ''} name="maxcashflow" label="$7,500,000" value="7500000" onChange={this.handleChangeMaxcashflow.bind()} />
                                                                                    <Input type="radio" id="maxcashflow7" disabled={10000000 <= this.state.mincashflowselected ? "disabled" : ''} name="maxcashflow" label="$10,000,000" value="10000000" onChange={this.handleChangeMaxcashflow.bind()} />
                                                                                    <Input type="radio" id="maxcashflow8" disabled={25000000 <= this.state.mincashflowselected ? "disabled" : ''} name="maxcashflow" label="$25,000,000" value="25000000" onChange={this.handleChangeMaxcashflow.bind()} />
                                                                                    <Input type="radio" id="maxcashflow9" disabled={50000000 <= this.state.mincashflowselected ? "disabled" : ''} name="maxcashflow" label="$50,000,000" value="50000000" onChange={this.handleChangeMaxcashflow.bind()} />
                                                                                </Col>
                                                                            </Row>
                                                                            
                                                                            <div style={{ textAlign: 'center' }} className="form-inline bottom-start">
                                                                                <FormGroup style={{ margin: 'auto' }}>
                                                                                    <Label htmlFor="mincashflow" hidden>Min</Label>
                                                                                    <Input type="text" name="mincashflow" id="mincashflow" ref="mincashflow" onChange={this.handleChangeSearch.bind(this, "mincashflow")} value={this.state.fields["mincashflow"]} placeholder="Min" />
                                                                                </FormGroup>
                                                                                
                                                                                <FormGroup style={{ margin: 'auto' }}>
                                                                                    <Label htmlFor="maxcashflow" hidden>Max</Label>
                                                                                    <Input type="text" name="maxcashflow" id="maxcashflow" ref="maxcashflow" onChange={this.handleChangeSearch.bind(this, "maxcashflow")} value={this.state.fields["maxcashflow"]} placeholder="Max" />
                                                                                </FormGroup>
                                                                            </div>
                                                                            <div style={{ textAlign: 'center' }} className="form-inline bottom-start">
                                                                                <p style={{ margin: 'auto', fontSize: '14px' }}>SDE</p>
                                                                            </div>
                                                                        </Col>
                                                                        <div className="vertical-divider">
                                                                            <div className="center-element">OR</div>
                                                                        </div>
                                                                        <Col style={{  }}>
                                                                            <div style={{ textAlign: 'center' }} className="form-inline top-start">
                                                                                <p style={{ margin: 'auto', fontSize: '14px' }}>EBITDA</p>
                                                                            </div>
                                                                            <div style={{ textAlign: 'center' }} className="form-inline top-start">
                                                                                <FormGroup style={{ margin: 'auto' }}>
                                                                                    <Label htmlFor="minebitda" hidden>Min</Label>
                                                                                    <Input type="text" name="minebitda" id="minebitda" ref="minebitda" onChange={this.handleChangeSearch.bind(this, "minebitda")} value={this.state.fields["minebitda"]} placeholder="Min" />
                                                                                </FormGroup>
                                                                                
                                                                                <FormGroup style={{ margin: 'auto' }}>
                                                                                    <Label htmlFor="maxebitda" hidden>Max</Label>
                                                                                    <Input type="text" name="maxebitda" id="maxebitda" ref="maxebitda" onChange={this.handleChangeSearch.bind(this, "maxebitda")} value={this.state.fields["maxebitda"]} placeholder="Max" />
                                                                                </FormGroup>
                                                                            </div>
                                                                            <Row className="m-0">
                                                                                <Col className="p-0">
                                                                                    <Input type="radio" id="minebitda1" name="minebitda" label="Any Min" value="Any Min" onChange={this.handleChangeMinEbitda.bind()} />
                                                                                    <Input type="radio" id="minebitda2" disabled={this.state.maxebitdaselected && 500000 > this.state.maxebitdaselected ? "disabled" : ''} name="minebitda" label="$500,000" value="500000" onChange={this.handleChangeMinEbitda.bind()} />
                                                                                    <Input type="radio" id="minebitda3" disabled={this.state.maxebitdaselected && 1000000 > this.state.maxebitdaselected ? "disabled" : ''} name="minebitda" label="$1,000,000" value="1000000" onChange={this.handleChangeMinEbitda.bind()} />
                                                                                    <Input type="radio" id="minebitda4" disabled={this.state.maxebitdaselected && 2500000 > this.state.maxebitdaselected ? "disabled" : ''} name="minebitda" label="$2,500,000" value="2500000" onChange={this.handleChangeMinEbitda.bind()} />
                                                                                    <Input type="radio" id="minebitda5" disabled={this.state.maxebitdaselected && 5000000 > this.state.maxebitdaselected ? "disabled" : ''} name="minebitda" label="$5,000,000" value="5000000" onChange={this.handleChangeMinEbitda.bind()} />
                                                                                    <Input type="radio" id="minebitda6" disabled={this.state.maxebitdaselected && 7500000 > this.state.maxebitdaselected ? "disabled" : ''} name="minebitda" label="$7,500,000" value="7500000" onChange={this.handleChangeMinEbitda.bind()} />
                                                                                    <Input type="radio" id="minebitda7" disabled={this.state.maxebitdaselected && 10000000 > this.state.maxebitdaselected ? "disabled" : ''} name="minebitda" label="$10,000,000" value="10000000" onChange={this.handleChangeMinEbitda.bind()} />
                                                                                    <Input type="radio" id="minebitda8" disabled={this.state.maxebitdaselected && 25000000 > this.state.maxebitdaselected ? "disabled" : ''} name="minebitda" label="$25,000,000" value="25000000" onChange={this.handleChangeMinEbitda.bind()} />
                                                                                    <Input type="radio" id="minebitda9" disabled={this.state.maxebitdaselected && 50000000 > this.state.maxebitdaselected ? "disabled" : ''} name="minebitda" label="$50,000,000" value="50000000" onChange={this.handleChangeMinEbitda.bind()} />
                                                                                </Col>
                                                                                <Col className="p-0">
                                                                                    <Input type="radio" id="maxebitda1" name="maxebitda" label="Any Max" value="Any Max" onChange={this.handleChangeMaxEbitda.bind()} />
                                                                                    <Input type="radio" id="maxebitda2" disabled={500000 <= this.state.minebitdaselected ? "disabled" : ''} name="maxebitda" label="$500,000" value="500000" onChange={this.handleChangeMaxEbitda.bind()} />
                                                                                    <Input type="radio" id="maxebitda3" disabled={1000000 <= this.state.minebitdaselected ? "disabled" : ''} name="maxebitda" label="$1,000,000" value="1000000" onChange={this.handleChangeMaxEbitda.bind()} />
                                                                                    <Input type="radio" id="maxebitda4" disabled={2500000 <= this.state.minebitdaselected ? "disabled" : ''} name="maxebitda" label="$2,500,000" value="2500000" onChange={this.handleChangeMaxEbitda.bind()} />
                                                                                    <Input type="radio" id="maxebitda5" disabled={5000000 <= this.state.minebitdaselected ? "disabled" : ''} name="maxebitda" label="$5,000,000" value="5000000" onChange={this.handleChangeMaxEbitda.bind()} />
                                                                                    <Input type="radio" id="maxebitda6" disabled={7500000 <= this.state.minebitdaselected ? "disabled" : ''} name="maxebitda" label="$7,500,000" value="7500000" onChange={this.handleChangeMaxEbitda.bind()} />
                                                                                    <Input type="radio" id="maxebitda7" disabled={10000000 <= this.state.minebitdaselected ? "disabled" : ''} name="maxebitda" label="$10,000,000" value="10000000" onChange={this.handleChangeMaxEbitda.bind()} />
                                                                                    <Input type="radio" id="maxebitda8" disabled={25000000 <= this.state.minebitdaselected ? "disabled" : ''} name="maxebitda" label="$25,000,000" value="25000000" onChange={this.handleChangeMaxEbitda.bind()} />
                                                                                    <Input type="radio" id="maxebitda9" disabled={50000000 <= this.state.minebitdaselected ? "disabled" : ''} name="maxebitda" label="$50,000,000" value="50000000" onChange={this.handleChangeMaxEbitda.bind()} />
                                                                                </Col>
                                                                            </Row>
                                                                            
                                                                            <div style={{ textAlign: 'center' }} className="form-inline bottom-start">
                                                                                <FormGroup style={{ margin: 'auto' }}>
                                                                                    <Label htmlFor="minebitda" hidden>Min</Label>
                                                                                    <Input type="text" name="minebitda" id="minebitda" ref="minebitda" onChange={this.handleChangeSearch.bind(this, "minebitda")} value={this.state.fields["minebitda"]} placeholder="Min" />
                                                                                </FormGroup>
                                                                                
                                                                                <FormGroup style={{ margin: 'auto' }}>
                                                                                    <Label htmlFor="maxebitda" hidden>Max</Label>
                                                                                    <Input type="text" name="maxebitda" id="maxebitda" ref="maxebitda" onChange={this.handleChangeSearch.bind(this, "maxebitda")} value={this.state.fields["maxebitda"]} placeholder="Max" />
                                                                                </FormGroup>
                                                                            </div>
                                                                            <div style={{ textAlign: 'center' }} className="form-inline bottom-start">
                                                                                <p style={{ margin: 'auto', fontSize: '14px' }}>EBITDA</p>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                    
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </Col>
                                                        <Col>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle caret className="form-control"> Annual revenue {this.state.fields["minrevenue"] !== undefined && formatCurrency(this.state.fields["minrevenue"])}{this.state.fields["maxrevenue"] !== undefined && "-" + formatCurrency(this.state.fields["maxrevenue"])}</DropdownToggle>
                                                                <DropdownMenu>
                                                                    <div className="form-inline top-start">
                                                                        <FormGroup>
                                                                            <Label htmlFor="minrevenue" hidden>Min</Label>
                                                                            <Input type="text" name="minrevenue" id="minrevenue" ref="minrevenue" onChange={this.handleChangeSearch.bind(this, "minrevenue")} value={this.state.fields["minrevenue"]} placeholder="Min" />
                                                                        </FormGroup>
                                                                        <FormGroup className="line-hr"> &#8210; </FormGroup>
                                                                        <FormGroup>
                                                                            <Label htmlFor="maxrevenue" hidden>Max</Label>
                                                                            <Input type="text" name="maxrevenue" id="maxrevenue" ref="maxrevenue" onChange={this.handleChangeSearch.bind(this, "maxrevenue")} value={this.state.fields["maxrevenue"]} placeholder="Max" />
                                                                        </FormGroup>
                                                                    </div>
                                                                    <Row className="m-0">
                                                                        <Col className="p-0">
                                                                            <Input type="radio" id="minrevenue1" name="minrevenue" label="Any Min" value="Any Min" onChange={this.handleChangeMinRevenue.bind()} />
                                                                            <Input type="radio" id="minrevenue2" disabled={this.state.maxrevenueselected && 500000 > this.state.maxrevenueselected ? "disabled" : ''} name="minrevenue" label="$500,000" value="500000" onChange={this.handleChangeMinRevenue.bind()} />
                                                                            <Input type="radio" id="minrevenue3" disabled={this.state.maxrevenueselected && 1000000 > this.state.maxrevenueselected ? "disabled" : ''} name="minrevenue" label="$1,000,000" value="1000000" onChange={this.handleChangeMinRevenue.bind()} />
                                                                            <Input type="radio" id="minrevenue4" disabled={this.state.maxrevenueselected && 2500000 > this.state.maxrevenueselected ? "disabled" : ''} name="minrevenue" label="$2,500,000" value="2500000" onChange={this.handleChangeMinRevenue.bind()} />
                                                                            <Input type="radio" id="minrevenue5" disabled={this.state.maxrevenueselected && 5000000 > this.state.maxrevenueselected ? "disabled" : ''} name="minrevenue" label="$5,000,000" value="5000000" onChange={this.handleChangeMinRevenue.bind()} />
                                                                            <Input type="radio" id="minrevenue6" disabled={this.state.maxrevenueselected && 7500000 > this.state.maxrevenueselected ? "disabled" : ''} name="minrevenue" label="$7,500,000" value="7500000" onChange={this.handleChangeMinRevenue.bind()} />
                                                                            <Input type="radio" id="minrevenue7" disabled={this.state.maxrevenueselected && 10000000 > this.state.maxrevenueselected ? "disabled" : ''} name="minrevenue" label="$10,000,000" value="10000000" onChange={this.handleChangeMinRevenue.bind()} />
                                                                            <Input type="radio" id="minrevenue8" disabled={this.state.maxrevenueselected && 25000000 > this.state.maxrevenueselected ? "disabled" : ''} name="mirevenuee" label="$25,000,000" value="25000000" onChange={this.handleChangeMinRevenue.bind()} />
                                                                            <Input type="radio" id="minrevenue9" disabled={this.state.maxrevenueselected && 50000000 > this.state.maxrevenueselected ? "disabled" : ''} name="mirevenuee" label="$50,000,000" value="50000000" onChange={this.handleChangeMinRevenue.bind()} />
                                                                        </Col>
                                                                        <Col className="p-0">
                                                                            <Input type="radio" id="maxrevenue1" name="maxrevenue" label="Any Max" value="Any Max" onChange={this.handleChangeMaxRevenue.bind()} />
                                                                            <Input type="radio" id="maxrevenue2" disabled={500000 <= this.state.minrevenueselected ? "disabled" : ''} name="maxrevenue" label="$500,000" value="500000" onChange={this.handleChangeMaxRevenue.bind()} />
                                                                            <Input type="radio" id="maxrevenue3" disabled={1000000 <= this.state.minrevenueselected ? "disabled" : ''} name="maxrevenue" label="$1,000,000" value="1000000" onChange={this.handleChangeMaxRevenue.bind()} />
                                                                            <Input type="radio" id="maxrevenue4" disabled={2500000 <= this.state.minrevenueselected ? "disabled" : ''} name="maxrevenue" label="$2,500,000" value="2500000" onChange={this.handleChangeMaxRevenue.bind()} />
                                                                            <Input type="radio" id="maxrevenue5" disabled={5000000 <= this.state.minrevenueselected ? "disabled" : ''} name="maxrevenue" label="$5,000,000" value="5000000" onChange={this.handleChangeMaxRevenue.bind()} />
                                                                            <Input type="radio" id="maxrevenue6" disabled={7500000 <= this.state.minrevenueselected ? "disabled" : ''} name="maxrevenue" label="$7,500,000" value="7500000" onChange={this.handleChangeMaxRevenue.bind()} />
                                                                            <Input type="radio" id="maxrevenue7" disabled={10000000 <= this.state.minrevenueselected ? "disabled" : ''} name="maxrevenue" label="$10,000,000" value="10000000" onChange={this.handleChangeMaxRevenue.bind()} />
                                                                            <Input type="radio" id="maxrevenue8" disabled={25000000 <= this.state.minrevenueselected ? "disabled" : ''} name="marevenuee" label="$25,000,000" value="25000000" onChange={this.handleChangeMaxRevenue.bind()} />
                                                                            <Input type="radio" id="maxrevenue9" disabled={50000000 <= this.state.minrevenueselected ? "disabled" : ''} name="marevenuee" label="$50,000,000" value="50000000" onChange={this.handleChangeMaxRevenue.bind()} />
                                                                        </Col>
                                                                    </Row>
                                                                    <div className="form-inline bottom-start">
                                                                        <FormGroup>
                                                                            <Label htmlFor="minrevenue" hidden>Min</Label>
                                                                            <Input type="text" name="minrevenue" id="minrevenue" ref="minrevenue" onChange={this.handleChangeSearch.bind(this, "minrevenue")} value={this.state.fields["minrevenue"]} placeholder="Min" />
                                                                        </FormGroup>
                                                                        <FormGroup className="line-hr"> &#8210; </FormGroup>
                                                                        <FormGroup>
                                                                            <Label htmlFor="maxrevenue" hidden>Max</Label>
                                                                            <Input type="text" name="maxrevenue" id="maxrevenue" ref="maxrevenue" onChange={this.handleChangeSearch.bind(this, "maxrevenue")} value={this.state.fields["maxrevenue"]} placeholder="Max" />
                                                                        </FormGroup>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </Col>
                                                        <Col>
                                                            <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                                                name="dateaddedOption"
                                                                id="dateaddedOption"
                                                                isSearchable={false}
                                                                isClearable={false}
                                                                placeholder="Date added"
                                                                onChange={this.handledateaddedChange}
                                                                
                                                                options={[
                                                                    { value: `all`, label: `All` },
                                                                    { value: `lastweek`, label: `Within the last week` },
                                                                    { value: `lastmonth`, label: `Within the last month` },
                                                                ]}
                                                                value={dateaddedOption}
                                                                ref="dateaddedOption"
                                                            />
                                                        </Col>
                                                        <Col >
                                                            <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                                                name="savedSearch"
                                                                id="savedSearch"
                                                                isSearchable={false}
                                                                isClearable={false}
                                                                placeholder="Saved Search"
                                                                
                                                                onChange={savedSearch => {
                                                                    const data = JSON.parse(savedSearch.value.data);
                                                                    this.setState({ savedSearch, Lat: data.from_latitude, Lng: data.from_longitude, fields: data.fields, location: data.location, categoryOption: data.categoryOption });
                                                                }}
                                                                options={this.state.savedSearchList}
                                                                value={this.state.savedSearch}
                                                                ref="savedSearch"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm="auto" className="align-self-center">
                                                    <Button color="link" onClick={this.handleChangeClear.bind()} >Clear all</Button>
                                                </Col>
                                                
                                            </Row>
                                            
<Row style={{ marginTop: '7px' }}>

{/* {this.isToUpgrade() && <Col className="col-11 text-center">
    <a style={{
        backgroundColor: '#1e5e90'
        }} href={'/premium'} className="btn btn-green">
            <i style={{ marginRight: '4px' }} className="fa fa-lock" aria-hidden="true"></i>Unlock additional search filters
            <i style={{ marginLeft: '5px', marginBottom: '5px' }} id="AdTooltip" className="sprite i-question"></i>
            <UncontrolledTooltip placement="bottom" target="AdTooltip"
                                 autohide={false}
                                 innerClassName="areas-tooltip"><a href="https://www.biznexus.com/about-premium-subscriptions-for-buyers">Learn More</a>
            </UncontrolledTooltip>
    </a>
</Col>} */}

{<Col>
    
    <Dropdown isOpen={this.state.dropdownRevenueMultiple} toggle={() => {
        if(this.state.premium) {
            this.setState({ dropdownRevenueMultiple: !this.state.dropdownRevenueMultiple })
        } else {
            this.setState({ premiumModal: true });
        }
    }}>
        <DropdownToggle caret className="form-control"> Revenue Multiple {this.state.fields["minrevenue_multiple"] !== undefined && this.state.fields["minrevenue_multiple"]}{this.state.fields["maxrevenue_multiple"] !== undefined && "-" + this.state.fields["maxrevenue_multiple"]}</DropdownToggle>
        <DropdownMenu className="annual_profit">
            <div className="form-inline top-start" style={{ textAlign: 'center' }}>
                <FormGroup style={{ margin: 'auto' }}>
                    <Label htmlFor="minebitda" hidden>Min</Label>
                    <Input type="text" name="minrevenue_multiple" id="minrevenue_multiple" onChange={this.handleChangeSearch.bind(this, "minrevenue_multiple")} value={this.state.fields["minrevenue_multiple"]} placeholder="Min" />
                </FormGroup>
                <FormGroup className="line-hr"> &#8210; </FormGroup>
                <FormGroup style={{ margin: 'auto' }}>
                    <Label htmlFor="maxebitda" hidden>Max</Label>
                    <Input type="text" name="maxrevenue_multiple" id="maxrevenue_multiple" onChange={this.handleChangeSearch.bind(this, "maxrevenue_multiple")} value={this.state.fields["maxrevenue_multiple"]} placeholder="Max" />
                </FormGroup>
            </div>
            <Row className="m-0">
                <Col className="p-0">
                    <Input type="radio" id="minrevenue_multiple1" name="minrevenue_multiple" label="Any Min" value="Any Min" onChange={this.handleChangeMinRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple2" name="minrevenue_multiple" label="0.5" value="0.5" onChange={this.handleChangeMinRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple3" name="minrevenue_multiple" label="1" value="1" onChange={this.handleChangeMinRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple4" name="minrevenue_multiple" label="1.5" value="1.5" onChange={this.handleChangeMinRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple5" name="minrevenue_multiple" label="2" value="2" onChange={this.handleChangeMinRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple6" name="minrevenue_multiple" label="2.5" value="2.5" onChange={this.handleChangeMinRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple7" name="minrevenue_multiple" label="3" value="3" onChange={this.handleChangeMinRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple8" name="minrevenue_multiple" label="3.5" value="3.5" onChange={this.handleChangeMinRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple9" name="minrevenue_multiple" label="4" value="4" onChange={this.handleChangeMinRevenueMultiple.bind()} />
                </Col>
                <Col className="p-0">
                    <Input type="radio" id="minrevenue_multiple10" name="maxrevenue_multiple" label="Any Max" value="Any Max" onChange={this.handleChangeMaxRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple11" disabled={0.5 <= this.state.minrevenuemultipleselected ? "disabled" : ''} name="maxrevenue_multiple" label="0.5" value="0.5" onChange={this.handleChangeMaxRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple12" disabled={1 <= this.state.minrevenuemultipleselected ? "disabled" : ''} name="maxrevenue_multiple" label="1" value="1" onChange={this.handleChangeMaxRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple13" disabled={1.5 <= this.state.minrevenuemultipleselected ? "disabled" : ''} name="maxrevenue_multiple" label="1.5" value="1.5" onChange={this.handleChangeMaxRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple14" disabled={2 <= this.state.minrevenuemultipleselected ? "disabled" : ''} name="maxrevenue_multiple" label="2" value="2" onChange={this.handleChangeMaxRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple15" disabled={2.5 <= this.state.minrevenuemultipleselected ? "disabled" : ''} name="maxrevenue_multiple" label="2.5" value="2.5" onChange={this.handleChangeMaxRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple16" disabled={3 <= this.state.minrevenuemultipleselected ? "disabled" : ''} name="maxrevenue_multiple" label="3" value="3" onChange={this.handleChangeMaxRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple17" disabled={3.5 <= this.state.minrevenuemultipleselected ? "disabled" : ''} name="maxrevenue_multiple" label="3.5" value="3.5" onChange={this.handleChangeMaxRevenueMultiple.bind()} />
                    <Input type="radio" id="minrevenue_multiple18" disabled={4 <= this.state.minrevenuemultipleselected ? "disabled" : ''} name="maxrevenue_multiple" label="4" value="4" onChange={this.handleChangeMaxRevenueMultiple.bind()} />
                </Col>
            </Row>
            <div className="form-inline bottom-start">
                <FormGroup style={{ margin: 'auto' }}>
                    <Label htmlFor="minebitda" hidden>Min</Label>
                    <Input type="text" name="minrevenue_multiple" onChange={this.handleChangeSearch.bind(this, "minrevenue_multiple")} value={this.state.fields["minrevenue_multiple"]} placeholder="Min" />
                </FormGroup >
                <FormGroup className="line-hr"> &#8210; </FormGroup>
                <FormGroup style={{ margin: 'auto' }}>
                    <Label htmlFor="maxebitda" hidden>Max</Label>
                    <Input type="text" name="maxrevenue_multiple" onChange={this.handleChangeSearch.bind(this, "maxrevenue_multiple")} value={this.state.fields["maxrevenue_multiple"]} placeholder="Max" />
                </FormGroup>
            </div>
        </DropdownMenu>
    </Dropdown>
</Col>} 
{<Col>
    <Dropdown isOpen={this.state.dropdownCashflowMultiple} toggle={() => {
        if(this.state.premium) {
            this.setState({ dropdownCashflowMultiple: !this.state.dropdownCashflowMultiple })
        } else {
            this.setState({ premiumModal: true });
        }
    }}>
        <DropdownToggle caret className="form-control"> SDE Multiple {this.state.fields["mincashflow_multiple"] !== undefined && this.state.fields["mincashflow_multiple"]}{this.state.fields["maxcashflow_multiple"] !== undefined && "-" + this.state.fields["maxcashflow_multiple"]}</DropdownToggle>
        <DropdownMenu className="annual_profit">
            <div className="form-inline top-start">
                <FormGroup style={{ margin: 'auto' }}>
                    <Label htmlFor="minebitda" hidden>Min</Label>
                    <Input type="text" name="mincashflow_multiple" id="mincashflow_multiple" onChange={this.handleChangeSearch.bind(this, "mincashflow_multiple")} value={this.state.fields["mincashflow_multiple"]} placeholder="Min" />
                </FormGroup>
                <FormGroup className="line-hr"> &#8210; </FormGroup>
                <FormGroup style={{ margin: 'auto' }}>
                    <Label htmlFor="maxebitda" hidden>Max</Label>
                    <Input type="text" name="maxcashflow_multiple" id="maxcashflow_multiple" onChange={this.handleChangeSearch.bind(this, "maxcashflow_multiple")} value={this.state.fields["maxcashflow_multiple"]} placeholder="Max" />
                </FormGroup>
            </div>
            <Row className="m-0">
                <Col className="p-0">
                    <Input type="radio" id="mincashflow_multiple1" name="mincashflow_multiple" label="Any Min" value="Any Min" onChange={this.handleChangeMinCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple2" name="mincashflow_multiple" label="0.5" value="0.5" onChange={this.handleChangeMinCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple3" name="mincashflow_multiple" label="1" value="1" onChange={this.handleChangeMinCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple4" name="mincashflow_multiple" label="1.5" value="1.5" onChange={this.handleChangeMinCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple5" name="mincashflow_multiple" label="2" value="2" onChange={this.handleChangeMinCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple6" name="mincashflow_multiple" label="2.5" value="2.5" onChange={this.handleChangeMinCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple7" name="mincashflow_multiple" label="3" value="3" onChange={this.handleChangeMinCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple8" name="mincashflow_multiple" label="3.5" value="3.5" onChange={this.handleChangeMinCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple9" name="mincashflow_multiple" label="4" value="4" onChange={this.handleChangeMinCashflowMultiple.bind()} />
                </Col>
                <Col className="p-0">
                    <Input type="radio" id="mincashflow_multiple10" name="maxrevenue_multiple" label="Any Max" value="Any Max" onChange={this.handleChangeMaxCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple11" disabled={0.5 <= this.state.mincashflowmultipleselected ? "disabled" : ''} name="maxcashflow_multiple" label="0.5" value="0.5" onChange={this.handleChangeMaxCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple12" disabled={1 <= this.state.mincashflowmultipleselected ? "disabled" : ''} name="maxcashflow_multiple" label="1" value="1" onChange={this.handleChangeMaxCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple13" disabled={1.5 <= this.state.mincashflowmultipleselected ? "disabled" : ''} name="maxcashflow_multiple" label="1.5" value="1.5" onChange={this.handleChangeMaxCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple14" disabled={2 <= this.state.mincashflowmultipleselected ? "disabled" : ''} name="maxcashflow_multiple" label="2" value="2" onChange={this.handleChangeMaxCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple15" disabled={2.5 <= this.state.mincashflowmultipleselected ? "disabled" : ''} name="maxcashflow_multiple" label="2.5" value="2.5" onChange={this.handleChangeMaxCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple16" disabled={3 <= this.state.mincashflowmultipleselected ? "disabled" : ''} name="maxcashflow_multiple" label="3" value="3" onChange={this.handleChangeMaxCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple17" disabled={3.5 <= this.state.mincashflowmultipleselected ? "disabled" : ''} name="maxcashflow_multiple" label="3.5" value="3.5" onChange={this.handleChangeMaxCashflowMultiple.bind()} />
                    <Input type="radio" id="mincashflow_multiple18" disabled={4 <= this.state.mincashflowmultipleselected ? "disabled" : ''} name="maxcashflow_multiple" label="4" value="4" onChange={this.handleChangeMaxCashflowMultiple.bind()} />
                </Col>
            </Row>
            <div className="form-inline bottom-start">
                <FormGroup style={{ margin: 'auto' }}>
                    <Label htmlFor="minebitda" hidden>Min</Label>
                    <Input type="text" name="mincashflow_multiple" onChange={this.handleChangeSearch.bind(this, "mincashflow_multiple")} value={this.state.fields["mincashflow_multiple"]} placeholder="Min" />
                </FormGroup>
                <FormGroup className="line-hr"> &#8210; </FormGroup>
                <FormGroup style={{ margin: 'auto' }}>
                    <Label htmlFor="maxebitda" hidden>Max</Label>
                    <Input type="text" name="maxcashflow_multiple" onChange={this.handleChangeSearch.bind(this, "maxcashflow_multiple")} value={this.state.fields["maxcashflow_multiple"]} placeholder="Max" />
                </FormGroup>
            </div>
        </DropdownMenu>
    </Dropdown>
</Col>}


{<Col>
    
    <Dropdown isOpen={this.state.dropdownEBITDAMultiple} toggle={() => {
        if(this.state.premium) {
            this.setState({ dropdownEBITDAMultiple: !this.state.dropdownEBITDAMultiple })
        } else {
            this.setState({ premiumModal: true });
        }
    }}>
                    <DropdownToggle caret className="form-control">EBITDA Multiple {this.state.fields["minebitda_multiple"] !== undefined && this.state.fields["minebitda_multiple"]}{this.state.fields["maxebitda_multiple"] !== undefined && "-" + this.state.fields["maxebitda_multiple"]}</DropdownToggle>
                    <DropdownMenu className="annual_profit">
                        <div className="form-inline top-start">
                            <FormGroup style={{ margin: 'auto' }}>
                                <Label htmlFor="minebitda" hidden>Min</Label>
                                <Input type="text" name="minebitda_multiple" ref="minebitda_multiple" id="minebitda_multiple" onChange={this.handleChangeSearch.bind(this, "minebitda_multiple")} value={this.state.fields["minebitda_multiple"]} placeholder="Min" />
                            </FormGroup>
                            <FormGroup className="line-hr"> &#8210; </FormGroup>
                            <FormGroup style={{ margin: 'auto' }}>
                                <Label htmlFor="maxebitda" hidden>Max</Label>
                                <Input type="text" name="maxebitda_multiple" id="maxebitda_multiple" onChange={this.handleChangeSearch.bind(this, "maxebitda_multiple")} value={this.state.fields["maxebitda_multiple"]} placeholder="Max" />
                            </FormGroup>
                        </div>
                        <Row className="m-0">
                            <Col className="p-0">
                                <Input type="radio" id="minebitda_multiple1" name="minebitda_multiple" label="Any Min" value="Any Min" onChange={this.handleChangeMinEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple2" name="minebitda_multiple" label="0.5" value="0.5" onChange={this.handleChangeMinEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple3" name="minebitda_multiple" label="1" value="1" onChange={this.handleChangeMinEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple4" name="minebitda_multiple" label="1.5" value="1.5" onChange={this.handleChangeMinEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple5" name="minebitda_multiple" label="2" value="2" onChange={this.handleChangeMinEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple6" name="minebitda_multiple" label="2.5" value="2.5" onChange={this.handleChangeMinEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple7" name="minebitda_multiple" label="3" value="3" onChange={this.handleChangeMinEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple8" name="minebitda_multiple" label="3.5" value="3.5" onChange={this.handleChangeMinEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple9" name="minebitda_multiple" label="4" value="4" onChange={this.handleChangeMinEbitdaMultiple.bind()} />
                            </Col>
                            <Col className="p-0">
                                <Input type="radio" id="minebitda_multiple10" name="maxebitda_multiple" label="Any Max" value="Any Max" onChange={this.handleChangeMaxEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple11" disabled={0.5 <= this.state.minebitdamultipleselected ? "disabled" : ''} name="maxebitda_multiple" label="0.5" value="0.5" onChange={this.handleChangeMaxEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple12" disabled={1 <= this.state.minebitdamultipleselected ? "disabled" : ''} name="maxebitda_multiple" label="1" value="1" onChange={this.handleChangeMaxEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple13" disabled={1.5 <= this.state.minebitdamultipleselected ? "disabled" : ''} name="maxebitda_multiple" label="1.5" value="1.5" onChange={this.handleChangeMaxEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple14" disabled={2 <= this.state.minebitdamultipleselected ? "disabled" : ''} name="maxebitda_multiple" label="2" value="2" onChange={this.handleChangeMaxEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple15" disabled={2.5 <= this.state.minebitdamultipleselected ? "disabled" : ''} name="maxebitda_multiple" label="2.5" value="2.5" onChange={this.handleChangeMaxEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple16" disabled={3 <= this.state.minebitdamultipleselected ? "disabled" : ''} name="maxebitda_multiple" label="3" value="3" onChange={this.handleChangeMaxEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple17" disabled={3.5 <= this.state.minebitdamultipleselected ? "disabled" : ''} name="maxebitda_multiple" label="3.5" value="3.5" onChange={this.handleChangeMaxEbitdaMultiple.bind()} />
                                <Input type="radio" id="minebitda_multiple18" disabled={4 <= this.state.minebitdamultipleselected ? "disabled" : ''} name="maxebitda_multiple" label="4" value="4" onChange={this.handleChangeMaxEbitdaMultiple.bind()} />
                            </Col>
                        </Row>
                        <div className="form-inline bottom-start">
                            <FormGroup style={{ margin: 'auto' }}>
                                <Label htmlFor="minebitda" hidden>Min</Label>
                                <Input type="text" name="minebitda_multiple" onChange={this.handleChangeSearch.bind(this, "minebitda_multiple")} value={this.state.fields["minebitda_multiple"]} placeholder="Min" />
                            </FormGroup>
                            <FormGroup className="line-hr"> &#8210; </FormGroup>
                            <FormGroup style={{ margin: 'auto' }}>
                                <Label htmlFor="maxebitda" hidden>Max</Label>
                                <Input type="text" name="maxebitda_multiple" onChange={this.handleChangeSearch.bind(this, "maxebitda_multiple")} value={this.state.fields["maxebitda_multiple"]} placeholder="Max" />
                            </FormGroup>
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </Col>}
            <Col sm="1">
                <div style={{ marginTop: '5px', marginLeft: '20px', float: 'right' }}>
                <Input type="checkbox"
                    name="asset_sale" id="asset_sale"
                    value={this.state.fields.asset_sale}
                    checked={this.state.fields.asset_sale}
                    onChange={this.handleChangeSearch.bind(this, "asset_sale")}/>
                <Label style={{ fontSize: '13px' }} htmlFor="asset_sale" check className="">Asset sale</Label>
                </div>
            </Col>
              
            
              
   
</Row>
                                               
<Row style={{ marginTop: '15px' }}>
                                            <Col sm="10"><b>I'm interested in exploring off-market, proprietary acquisition opportunities:</b> </Col>
                                            <Col sm="2">
                                                <Form inline style={{ display: 'inline-flex' }}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="radio" name="radio1" value="yes" 
                                                                onChange={() => this.setState({ offMarket: 'yes' })}
                                                                checked={this.state.offMarket === 'yes'} />{' '}
                                                            Yes
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup check style={{ marginLeft: '20px' }}>
                                                        <Label check>
                                                            <Input type="radio" name="radio1" value="no"
                                                                onChange={() => this.setState({ offMarket: 'no' })}
                                                                checked={this.state.offMarket === 'no'} />{' '}
                                                            No
                                                        </Label>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>   
                                        </div>


                                        
                                    </Collapse>
                                    <Button type="submit" className="btn-bluedark w-100 col-sm-3 pl-0 pr-0 d-block d-sm-none">Search Now</Button>
                                    
                                </Form>
                                {<div onClick={() => this.setState({ guideModalOpen: true })} style={{ float: 'right', color: 'gray', textDecoration: 'underline', cursor: 'pointer' }}>Guide <i style={{ marginTop: '-3px' }} className="sprite i-question"></i></div>}
                                <Modal size={'lg'} isOpen={this.state.guideModalOpen} toggle={() => this.setState({ guideModalOpen: !this.state.guideModalOpen })} className="modal-dialog modal-featured" keyboard={true} backdrop="static">
                                    <ModalHeader toggle={() => this.setState({ guideModalOpen: !this.state.guideModalOpen })}>
                                        
                                    </ModalHeader>
                                    <ModalBody>
                                    <Row>
                                        <Col sm="12" style={{ textAlign:'center' }}>
                                            <iframe title="1" width="600" height="400" src="https://www.youtube.com/embed/pXAwhzreies?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </Col>
                                        
                                    </Row>
                                    
                                    </ModalBody>
                                </Modal> 

                                <Modal isOpen={this.state.premiumModal} toggle={() => this.setState({ premiumModal: !this.state.premiumModal })}>
                                    <ModalHeader toggle={() => this.setState({ premiumModal: !this.state.premiumModal })}>BizNexus</ModalHeader>
                                    <ModalBody>
                                        Upgrade your subscription to search by multiples.
                                    </ModalBody>
                                    <ModalFooter>
                                        <a href="/premium" className="btn btn-info">Learn More</a>
                                    </ModalFooter>
                                </Modal> 
                            </div>
                            </Col>
                    </Row>
                    </Container>
        )
    }

}

export default connect()(SearchComponent);
