import React from 'react';
import {
    Button,
    Col,
    FormGroup,
    Label,
    Row,
    UncontrolledTooltip,
    Input,
    Modal,
} from 'reactstrap';
import InputMask from "react-input-mask";
import Select from 'react-select';
import api from '../../../services/api';
import FeaturedProfile from '../../FeaturedProfile';
import {professionSubCategories, USER_TYPE} from '../../../constants/userTypes';
import Qualification from './qualification';
import Awards from "./awards";
import Testimonials from "./testimonials";
import Reviews from "../../Reviews";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import ChangeEmail from "./components/ChangeEmail/ChangeEmail";
import {withRouter} from "../../../containers/withRouter/withRouter";
// import Preferences from './preferences_new';

let categoryRemoveClicked = false;
//const preferencesRef = useRef();
//const qlfcRef = useRef();

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            fields: [],
            success: {},
            selectedOption: '',
            industryselectedOption: '',
    
            areaservedlist: [],
            locationvalues: [],
            professionlist: [],
            yearsindustry: [],
            industrylist: [],
            dropdownCategory: false,
            firmname: '',
            boostModalOpen: false,
            eventModal: false,
            email_visible: false,
            phone: '',
            filterSearch: 'Info',
            slugUrl: '',
        };

        this.preferencesRef = React.createRef();
        this.qRef = React.createRef();
    }

    getNotificationEmail(user) {
        if(user.notification_email) {
            return user.notification_email;
        } else if(user.email) {
            return user.email;
        } else if(user.linkedin_email) {
            return user.linkedin_email;
        }
    }

    getEditProfile = async () => {
        document.getElementById('loader').setAttribute("style", "display: block;");
        const header = await api.get('user/header', null, true);

        this.setState({
            slugUrl: header.slugValue
        });
        await api.get('user/userProfessionlist', null, false)
        .then(res => {
            let professionlistarray = [];
                    // eslint-disable-next-line
                    res.map((choice, i) => {
                        if (choice.status === '1') {
                            let temp = {
                                "value": choice.id,
                                "label": choice.profession_value
                            };
                            professionlistarray.push(temp);
                        }
                    });
                    this.setState({professionlist: professionlistarray});
        });
        api.get('user/editprofile', null, true)
        .then(res => {
            this.setState({
                professionlist: this.state.professionlist.filter(p => professionSubCategories[res.user_type_id].includes(p.label))
            });

            let parent = 0;
            // eslint-disable-next-line
            res.industries_of_expertise.map((industrySelected, i) => {
                if (industrySelected.checked === true) {
                    parent++;
                } else {
                    // eslint-disable-next-line
                    industrySelected.children.map((industryChildren, j) => {
                        if (industryChildren.checked === true) {
                            parent++;
                        }
                    });
                }
            });
            let fields = this.state.fields.slice();
            fields["name"] = res.name;
            //console.log(res.name);
            let firstname = '';
            let fullname = res.name.match(/(\w+)(?:\s[^\s]+)?\s(\w+).*/);
            let slugfirstname = '';
            //console.log(fullname);
            if (fullname !== null) {
                firstname = fullname[1] + " " + fullname[2].charAt(0) + ".";
                slugfirstname = fullname[1].toLowerCase();
                slugfirstname = slugfirstname.trim();
            } else {
                firstname = res.name;
                slugfirstname = res.name.toLowerCase();
                slugfirstname = slugfirstname.trim();
            }

            fields["linkedinProfile"] = res.linkedinProfile;

            fields["linkedin_url"] = res.linkedin_url;
            fields["facebook_url"] = res.facebook_url;
            fields["website"] = res.website;
            fields["twitter_url"] = res.twitter_url;

            fields["calendar_link"] = res.calendar_link;
            fields["nda_link"] = res.nda_link;
            fields["auto_reply_message"] = res.auto_reply_message;
            fields["about_overview"] = res.about_overview;

            fields["profile_pic"] = res.profile_pic;
            fields['focus_on'] = res.focus_on;
            fields["notification_email"] = this.getNotificationEmail(res);

            fields["brokerlicensenumber"] = (res.license_number === null) ? '' : res.license_number;
            fields['video_url'] = res.video_url;

            //console.log(res);
            let cityValue = res.area_served;
            let getLocationData = cityValue[0];
            let slugValue = '';
            /*console.log(getLocationData.city);
  console.log(getLocationData.state_short);*/
            if (getLocationData !== undefined) {
                if (getLocationData.city !== "" && getLocationData.city !== undefined) {

                    let locationData = getLocationData.location;
                    let city = '';
                    locationData = locationData.replace(/[^a-zA-Z0-9 ]/g, "");
                    locationData = locationData.trim();
                    locationData = locationData.replace(/ /g, "-");
                    locationData = locationData.replace(/--/, "-");
                    locationData = locationData.toLowerCase();
                    city = locationData;
                    let slugId = res.id;
                    slugValue = city + "/" + slugfirstname + "-" + slugId;

                } else {

                    let slugId = res.id;
                    slugValue = slugfirstname + "-" + slugId;

                }
            }
            const professionTypes = res.profession_type_id ? res.profession_type_id.split(',') : []
            const selectedOption = [];
            this.state.professionlist.forEach(({label, value}) => {
                professionTypes.forEach(id => {
                    if (+value === +id) {
                        selectedOption.push({
                            label,
                            value
                        })
                    }
                })
            })
            const yearsSelected = res.years_in_industry !== null ? this.state.yearsindustry.find(({value}) => +value === +res.years_in_industry) : ''

            this.setState({
                fields,
                firstname: firstname,
                email: (res.email === null) ? this.state.email : res.email,
                phone: (res.phone === null) ? this.state.phone : res.phone,
                firmname: (res.firm_name[0].firm_name === null) ? this.state.firmname : res.firm_name[0].firm_name,
                areaservedlist: (res.area_served.length === 0) ? this.state.areaservedlist : res.area_served,
                industryselectedOption: yearsSelected,
                profession: (res.ptype === null) ? this.state.profession : res.ptype,
                selectedOption,
                industrylist: res.industries_of_expertise,
                selectparentlabel: (parent > 0) ? 1 : 0,
                profileImg: res.profile_pic || '',
                profileBannerImg: res.profile_background_pic || '',
                linkedinToken: res.linkedin_token,
                slugName: slugValue,
                userId: res.id,
                interested_in_distressed: res.interested_in_distressed,
                user_type_id: res.user_type_id,
                email_visible: res.email_visible,
            });

            this.setState({ eventModal: true });

            document.getElementById('loader').setAttribute("style", "display: none;");
            setTimeout(() => {
                if (window.a2a) {
                    window.a2a.init_all();
                }
            }, 100);
        })
    }

    componentDidMount() {
        const yearsindustryarray = [];
        for (let i = 1; i <= 50; i++) {
            let temp = {
                "value": i,
                "label": i
            };
            yearsindustryarray.push(temp);
        }
        this.setState({yearsindustry: yearsindustryarray});

        api.get('promote/list-profile', null, true)
        .then(res => {
            
        });


        this.getEditProfile();
        
    }

    profilehandleSubmit = async (event) => {
        event.preventDefault();
        if (this.handleValidation()) {
            let redirectlink = event.target.getAttribute("data-url");
            if (event.target.getAttribute("data-autosave") === "submitprofile") {
                this.ProfileUpdatetoggle();
            }
            document.getElementById('loader').setAttribute("style", "display: block;");
            let fields = this.state.fields;
            let professionlistValues = "";
            let professionlistlabel = "";

            let profileImagePic, jsonsetprofileData = "";
            if (this.state.selectedOption.constructor === Array && this.state.selectedOption.length > 0) {
                // eslint-disable-next-line
                this.state.selectedOption.map((choice, i) => {
                    professionlistValues += choice.value + ",";
                    professionlistlabel += choice.label + ",";
                });
                professionlistValues = professionlistValues.slice(0, -1);
                professionlistlabel = professionlistlabel.slice(0, -1);
            } else {
                professionlistValues = this.state.selectedOption;
            }
            if (this.state.profession !== '' && professionlistlabel === '') {
                professionlistlabel = this.state.profession;
            }
            let industryselectedOption = ''
            if (this.state.industryselectedOption.value !== undefined) {
                industryselectedOption = this.state.industryselectedOption.value;
            } else {
                industryselectedOption = this.state.industryselectedOption;
            }
            let firmnameid = "";


            let categoryArrayData = [];
            // eslint-disable-next-line
            this.state.industrylist.map((industrySelected, i) => {
                if (industrySelected.checked === true) {
                    let categoryarray = {
                        "id": industrySelected.value,
                        "name": industrySelected.label,
                        "path": industrySelected.label + ">>All"
                    };
                    categoryArrayData.push(categoryarray);
                } else {
                    // eslint-disable-next-line
                    industrySelected.children.map((industryChildren, j) => {
                        if (industryChildren.checked === true) {
                            let categoryarray = {
                                "id": industryChildren.value,
                                "name": industryChildren.label,
                                "path": industrySelected.label + ">>" + industryChildren.label
                            };
                            categoryArrayData.push(categoryarray);
                        }
                    });
                }
            });

            // this.preferencesRef.current.saveTrigger();
            if(this.qRef.current) {
                this.qRef.current.saveTrigger();
            }
            
            jsonsetprofileData = {
                "profile_pic": profileImagePic,
                // "profile_background_pic": profileImageBannerPic,
                "name": fields["name"],
                "notification_email": fields["notification_email"],
                "firm_name": this.state.firmname,
                "company_id": firmnameid,
                "phone": this.state.phone,
                "email": this.state.email,
                "status": "1",
                "profession_type_id": professionlistValues,
                "years_in_industry": industryselectedOption,
                "license_number": fields["brokerlicensenumber"],
                "about_overview": this.state.fields.about_overview,
                "ip_address": localStorage.getItem("ip"),
                // area_served: this.state.areaservedlist,
                // industries_of_expertise: categoryArrayData,
                linkedin_url: this.state.fields.linkedin_url,
                facebook_url: this.state.fields.facebook_url,
                twitter_url: this.state.fields.twitter_url,
                website: this.state.fields.website,
                calendar_link: this.state.fields.calendar_link,
                nda_link: this.state.fields.nda_link,
                auto_reply_message: this.state.fields.auto_reply_message,
                video_url: this.state.fields.video_url,
                email_visible: this.state.email_visible,
                focus_on: this.state.fields.focus_on,
            };

            api.post('user/saveeditProfile', jsonsetprofileData, true)
                .then(() => {
                    let success = {};
                    this.getEditProfile();
                    success["success"] = "Profile updated successfully.";
                    this.setState({
                        success: success,
                        profession: professionlistlabel,
                        profileupdate: 0,
                        profileBannerPicUpload: '',
                        profileBanner: {
                            result: null,
                            filename: null,
                            filetype: null,
                            src: null,
                            error: null
                        }
                    });
                    window.setTimeout(() => {
                        this.setState({success: ''});
                    }, 5000);
                    document.getElementById('loader').setAttribute("style", "display: none;");
                    if (redirectlink !== null) {
                        this.props.history.push(redirectlink);
                    }
                });
            
        } else {
            if (event.target.getAttribute("data-autosave") === "submitprofile") {
                this.ProfileUpdatetoggle();
            }
        }
    };

    validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(String(email).toLowerCase());
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        const { user_type_id } = this.state;
        //name
        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Please enter your name.";
        }

        if (!fields["linkedin_url"]) {
			formIsValid = false;
			errors["linkedin_url"] = "Please enter your LinkedIn URL";
		}
		if (fields["linkedin_url"] && !fields['linkedin_url'].match(/linkedin/)) {
			formIsValid = false;
			errors["linkedin_url"] = "Please enter your LinkedIn URL";
		}


        function getId(url) {
            if(!url) {
              return null;
            }
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const match = url.match(regExp);
        
            return (match && match[2].length === 11)
              ? match[2]
              : null;
        }

        if(fields['video_url'] && !getId(fields['video_url'])) {
            formIsValid = false;
            errors["video_url"] = "Invalid YouTube video link";
        }

        if(fields["website"] && !fields["website"].startsWith('https://')) {
            // errors["website"] = 'Link is invalid';
            // formIsValid = false;
            
            fields['website'] = 'https://' + fields['website'];
            this.setState({fields});
        }

        if(fields["calendar_link"] && !fields["calendar_link"].startsWith('https://')) {
            fields['calendar_link'] = 'https://' + fields['calendar_link'];
            this.setState({fields});
        }

        if(fields["nda_link"] && !fields["nda_link"].startsWith('https://')) {
            fields['nda_link'] = 'https://' + fields['nda_link'];
            this.setState({fields});
        }

        if (fields["name"].length >= 50) {
            formIsValid = false;
            errors["name"] = "Name is too long";
        }

        if (!fields["notification_email"].length || !this.validateEmail(fields["notification_email"])) {
            formIsValid = false;
            errors["notification_email"] = "Please, enter valid email";
        }

        //areaserved
        //if (!this.state.areaservedlist.length > 0 && user_type_id !== USER_TYPE.SELLER) {
            // formIsValid = false;
            // errors["areaserved"] = "Please, enter areas served.";
        //}
        //companyname
        if (!this.state.firmname.length > 0 && user_type_id !== USER_TYPE.SELLER) {
            formIsValid = false;
            errors["firmname"] = "Please enter company name";
        }
        
        if (this.state.firmname.length && this.state.firmname.length > 30) {
            formIsValid = false;
            errors["firmname"] = "Max 30 characters";
        }


        //industryselectedOption
        // if (!this.state.industryselectedOption) {
        //     formIsValid = false;
        //     errors["yearsindustry"] = "Please select.";
        // }
        //about
        if (fields["about_overview"] && fields["about_overview"].length <= 30) {
            formIsValid = false;
            errors["about"] = "Tell the entrepreneurs of the world about yourself and what you do. Min 30 characters.";
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    profilehandleChange(field, e) {
        if (e.target !== '' && e.target !== undefined) {
            let fields = this.state.fields;
            fields[field] = e.target.value;
            this.setState({fields, profileupdate: 1});
        }
    }

    removeClick = (e, i) => {
        this.state.areaservedlist.splice(e, 1);
        let dataAreaLists = [];
        dataAreaLists = this.state.areaservedlist;
        this.setState({areaservedlist: dataAreaLists, profileupdate: 1});
    }

    parentCategory(e) {
        let id = e.target.id;
        let splitValue = id.split("_");
        let splitid = splitValue[0];
        let type = splitValue[1];

        let parent = 0;
        let child = 0;

        // eslint-disable-next-line
        this.state.industrylist.map((industrySelected, i) => {
            if (industrySelected.checked === true) {
                parent++;
            } else {
                // eslint-disable-next-line
                industrySelected.children.map((industryChildren, j) => {
                    if (industryChildren.checked === true) {
                        child++;
                    }
                });
            }
        });

        let categorydata = this.state.industrylist;
        if (type === 'parent') {
            if (e.target.checked === true) {
                categorydata[splitid].checked = true;
                // eslint-disable-next-line
                categorydata[splitid].children.map((lists, i) => {
                    categorydata[splitid].children[i].checked = true;
                });
                this.setState({selectparentlabel: 1});
            } else {

                if (parent === 1 && child === 1) {
                    this.setState({selectparentlabel: 1});
                } else if (parent === 0 && child === 1) {
                    this.setState({selectparentlabel: 0});
                } else if (parent === 1 && child === 0) {
                    this.setState({selectparentlabel: 0});
                } else {
                    this.setState({selectparentlabel: 1});
                }
                categorydata[splitid].checked = false;
                categorydata[splitid].partialed = false;
                // eslint-disable-next-line
                categorydata[splitid].children.map((lists, i) => {
                    categorydata[splitid].children[i].checked = false;
                });
            }
            this.setState({industrylist: categorydata, profileupdate: 1});
        } else {
            let child_id = splitValue[2];
            if (e.target.checked === true) {
                categorydata[splitid].partialed = true;
                categorydata[splitid].children[child_id].checked = true;
                let checkboxlength = categorydata[splitid].children.length;
                let count_checked = 0;
                // eslint-disable-next-line
                categorydata[splitid].children.map((lists, i) => {
                    if (categorydata[splitid].children[i].checked === true) {
                        count_checked++;
                    }
                });
                if (count_checked === checkboxlength) {
                    categorydata[splitid].checked = true;
                }
                this.setState({selectparentlabel: 1});
            } else {
                if (parent === 1 && child === 1) {
                    //categorydata[splitid].partialed = false;
                    this.setState({selectparentlabel: 1});
                } else if (parent === 0 && child === 1) {
                    //categorydata[splitid].partialed = false;
                    this.setState({selectparentlabel: 0});
                } else if (parent === 1 && child === 0) {
                    //categorydata[splitid].partialed = false;
                    this.setState({selectparentlabel: 1});
                } else {
                    //categorydata[splitid].partialed = false;
                    this.setState({selectparentlabel: 1});
                }

                let count_checked = 0;
                // eslint-disable-next-line
                categorydata[splitid].children.map((lists, i) => {
                    if (categorydata[splitid].children[i].checked === true) {
                        count_checked++;
                    }
                });
                if (count_checked === 1) {
                    categorydata[splitid].partialed = false;
                } else {
                    categorydata[splitid].partialed = true;
                }

                categorydata[splitid].checked = false;
                categorydata[splitid].children[child_id].checked = false;
            }
            this.setState({industrylist: categorydata, profileupdate: 1});
        }

    }

    Categorytoggle = () => {
        if (categoryRemoveClicked) {
            categoryRemoveClicked = !categoryRemoveClicked
            return;
        }
        this.setState({dropdownCategory: !this.state.dropdownCategory});
    }

    arrowtoggle = (e) => {
        let id = e.target.id;
        let splitValue = id.split("_");
        let splitid = splitValue[1];
        let categorydata = this.state.industrylist;
        if (categorydata[splitid].toggle === undefined) {
            categorydata[splitid].toggle = true;
        } else {
            if (categorydata[splitid].toggle === false) {
                categorydata[splitid].toggle = true;
            } else {
                categorydata[splitid].toggle = false;
            }
        }
        this.setState({industrylist: categorydata});
    }

    removeCategoryClick(e) {
        categoryRemoveClicked = true;
        let parent = 0;
        // eslint-disable-next-line
        this.state.industrylist.map((industrySelected, i) => {
            if (industrySelected.checked === true) {
                parent++;
            } else {
                // eslint-disable-next-line
                industrySelected.children.map((industryChildren, j) => {
                    if (industryChildren.checked === true) {
                        parent++;
                    }
                });
            }
        });

        let categorydata = this.state.industrylist;
        let id = e.target.id;
        let splitValue = id.split("_");
        let splitid = splitValue[0];
        let type = splitValue[1];
        if (type === 'parentselected') {
            categorydata[splitid].checked = false;
            // eslint-disable-next-line
            categorydata[splitid].children.map((lists, i) => {
                categorydata[splitid].children[i].checked = false;
            });
        } else {
            let child_id = splitValue[2];
            categorydata[splitid].children[child_id].checked = false;
        }
        this.setState({industrylist: categorydata, profileupdate: 1});

        if (parent === 1) {
            this.setState({selectparentlabel: 0});
        }
    }

    handleGeosuggestChange = (areaserved) => {
        if (this.state.areaservedlist.length <= 3) {
            if (areaserved !== undefined) {
                let addressComponent = areaserved.gmaps.address_components;
                let city, stateData, stateShortData = '';
                for (var j = 0; j < areaserved.gmaps.address_components.length; j++) {
                    if (addressComponent[j].types[0] === 'locality') {
                        city = addressComponent[j].long_name;
                    }
                    if (addressComponent[j].types[0] === 'administrative_area_level_1') {
                        stateData = addressComponent[j].long_name;
                        stateShortData = addressComponent[j].short_name;
                    }
                }
                if (stateShortData === "") {
                    stateShortData = areaserved.label;
                }
                if (stateData === undefined) {
                    stateData = areaserved.label;
                }
                let dataArea = [];
                let temp = '';
                temp = {
                    "location": areaserved.label,
                    "latitude": areaserved.location.lat,
                    "longitude": areaserved.location.lng,
                    "city": city,
                    "state_short": stateShortData,
                    "state_long": stateData
                };
                if (this.state.areaservedlist.length !== 0) {
                    this.state.areaservedlist.push(temp)
                } else {
                    this.state.areaservedlist.push(temp)
                }
                dataArea.push(temp);
                this.setState({locationvalues: dataArea, profileupdate: 1});
                this.refs.areaserved.update('');
            } else {
                this.setState({locationvalues: []});
            }
        }
    }

    removeClick = (e, i) => {
        this.state.areaservedlist.splice(e, 1);
        let dataAreaLists = [];
        dataAreaLists = this.state.areaservedlist;
        this.setState({areaservedlist: dataAreaLists, profileupdate: 1});
    }

    handleChangeselect = (selectedOption) => {
        this.setState({selectedOption, profileupdate: 1});
    }

    handleChangeBuyerTypeSelect = (selectedBuyerTypeOption) => {
        this.setState({selectedBuyerTypeOption, profileupdate: 1});
    }

    handleChange = (industryselectedOption) => {
        this.setState({industryselectedOption, profileupdate: 1}, () => {
            this.handleValidation();
        });
    }

    Categorytoggle = () => {
        if (categoryRemoveClicked) {
            categoryRemoveClicked = !categoryRemoveClicked
            return;
        }
        this.setState({dropdownCategory: !this.state.dropdownCategory});
    }

    arrowtoggle = (e) => {
        let id = e.target.id;
        let splitValue = id.split("_");
        let splitid = splitValue[1];
        let categorydata = this.state.industrylist;
        if (categorydata[splitid].toggle === undefined) {
            categorydata[splitid].toggle = true;
        } else {
            if (categorydata[splitid].toggle === false) {
                categorydata[splitid].toggle = true;
            } else {
                categorydata[splitid].toggle = false;
            }
        }
        this.setState({industrylist: categorydata});
    }

    handleChangefirmname = (options) => {
        this.setState({firmname: options, profileupdate: 1});
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState, profileupdate: 1
        });
    };

    onChangeFilterSearch(id) {
        if(id === this.state.filterSearch) {
            return;
        }
        this.setState({ filterSearch: id });
    }



    render() {

        const { selectedOption, industryselectedOption } = this.state;

        const warningIcon = null;
        

        return (
            <div style={{paddingTop: '20px'}}>
                <div className="loader" id="loader" style={{display: 'none'}}>
                    <div className="spiner"></div>
                </div>

                
                

                <Row>
                    <Col sm={4} className="mb-4">
                        <p onClick={() => this.onChangeFilterSearch('Info')}
                           className={`ms-1 ${this.state.filterSearch === 'Info' ? 'favorite-item-active' : 'favorite-item'} `}
                           style={{cursor: 'pointer', fontSize: 16}}>
                            <i className="fa-solid fa-pen"/> <span style={{ marginLeft: 10 }}>Basic Info</span>
                        </p>

                        <p onClick={() => {
                            this.onChangeFilterSearch('awards')
                        }}
                           className={`ms-1 ${this.state.filterSearch === 'awards' ? 'favorite-item-active' : 'favorite-item'} `}
                           style={{cursor: 'pointer', fontSize: 16}}>
                            <i className="fas fa-award"></i> <span style={{ marginLeft: 10 }}>Credentials</span>
                        </p>

                        <p onClick={() => {
                            this.onChangeFilterSearch('testimonials')
                        }}
                           className={`ms-1 ${this.state.filterSearch === 'testimonials' ? 'favorite-item-active' : 'favorite-item'} `}
                           style={{cursor: 'pointer', fontSize: 16}}>
                            <i className="far fa-comment-alt"></i> <span style={{ marginLeft: 10 }}>Testimonials</span>
                        </p>

                        <p onClick={() => {
                            this.onChangeFilterSearch('reviews')
                        }}
                           className={`ms-1 ${this.state.filterSearch === 'reviews' ? 'favorite-item-active' : 'favorite-item'} `}
                           style={{cursor: 'pointer', fontSize: 16}}>
                            <i className="far fa-comment-dots"></i> <span style={{ marginLeft: 10 }}>Reviews</span>
                        </p>

                        {this.state.user_type_id === 1 && <p onClick={() => this.onChangeFilterSearch('Qualification')}
                           className={`ms-1 ${this.state.filterSearch === 'Qualification' ? 'favorite-item-active' : 'favorite-item'} `}
                           style={{cursor: 'pointer', fontSize: 16}}>
                            <i className="fa-solid fa-user-check"></i> <span style={{ marginLeft: 10 }}>Buyer Qualification</span>
                        </p>}

                        <p onClick={() => {
                            this.onChangeFilterSearch('change-email')
                        }}
                           className={`ms-1 ${this.state.filterSearch === 'change-email' ? 'favorite-item-active' : 'favorite-item'} `}
                           style={{cursor: 'pointer', fontSize: 16}}>
                            <i className="fa-solid fa-at"></i> <span style={{ marginLeft: 10 }}>Change Login Email</span>
                        </p>

                        <p onClick={() => {
                            this.onChangeFilterSearch('change-password')
                        }}
                           className={`ms-1 ${this.state.filterSearch === 'change-password' ? 'favorite-item-active' : 'favorite-item'} `}
                           style={{cursor: 'pointer', fontSize: 16}}>
                            <i className="fa-solid fa-lock"></i> <span style={{ marginLeft: 10 }}>Change Login Password</span>
                        </p>
                    </Col>
                    <Col sm={8}>

                        {this.state.filterSearch === 'change-password' && <ChangePassword />}
                        {this.state.filterSearch === 'change-email' && <ChangeEmail />}

                        {this.state.filterSearch === 'awards' && <Awards />}
                        {this.state.filterSearch === 'testimonials' && <Testimonials />}
                        {this.state.filterSearch === 'reviews' && <Reviews slugUrl={this.state.slugUrl} firstName={this.state.fields["name"]} />}

                        {this.state.filterSearch === 'Qualification' &&
                            <>
                                <h1 class="h1 pt-2">Buyer Qualification: <i style={{ padding: 10 }} id="Qualification" className="sprite i-question"></i>
                                    <UncontrolledTooltip placement="bottom" target="Qualification"
                                                         autohide={false}
                                                         innerClassName="areas-tooltip">
                                        Complete your buyer qualification questions so sellers will be more likely to take you seriously and prioritize you on competitive deals.
                                    </UncontrolledTooltip></h1>
                                <Qualification ref={this.qRef} />
                                <p className="text-success text-center mt-3">{this.state.success["success"]}</p>
                                <p className="text-center pt-3 pb-4"><Button style={{ width: 200 }} onClick={this.profilehandleSubmit} color="primary">Save Profile</Button></p>
                            </>
                        }


                        {this.state.filterSearch === 'Info' && <div className="ProfileForm profile--new">
                            <h1 className="h1">Your Profile:</h1>
                            <br/>
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="name">Your Name</Label>
                                        <Input className="" type="text" name="name" id="name" ref="name"
                                               onChange={this.profilehandleChange.bind(this, "name")}
                                               value={this.state.fields["name"]} placeholder="John Doe"/>
                                        <span className="error">{this.state.errors["name"]}</span>

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="companyname">Company Name</Label>
                                        <Input className="" type="text" name="name" id="name" ref="name"
                                               onChange={(e) => {
                                                   this.setState({ firmname: e.target.value }, () => {
                                                       this.handleValidation();
                                                   })
                                               }}
                                               value={this.state.firmname} placeholder="John Doe"/>
                                        <span
                                            className="error">{this.state.errors["firmname"]}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="email">Notification email
                                            <span className="pricing-card__features-tooltip" id="notificationTooltip">
                                                    <svg style={{margin: 5}} className="icon" viewBox="0 0 20 20"
                                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                              d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                                                              fill="currentColor"/>
                                                        <path
                                                            d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z"
                                                            fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            <UncontrolledTooltip placement="bottom" target={`notificationTooltip`}
                                                                 autohide={false}
                                                                 innerClassName="areas-tooltip">
                                                Let us know where you want your deal notifications sent
                                            </UncontrolledTooltip>


                                        </Label>
                                        <Input type="text" name="email" id="email" ref="email"
                                               onChange={this.profilehandleChange.bind(this, "notification_email")}
                                               value={this.state.fields["notification_email"]}
                                               placeholder="john@example.com"/>

                                        <div className="custom-control custom-checkbox text-sm align-items-center"
                                             style={{display: 'inline-flex'}}>
                                            <input id="email_visible" name="email_visible"
                                                   style={{display: 'inline-flex'}} className="custom-control-input"
                                                   type="checkbox" checked={this.state.email_visible}
                                                   value={this.state.email_visible} onChange={(e) => {
                                                this.setState({email_visible: e.target.checked})
                                            }}/>
                                            <label style={{padding: 3}} htmlFor="email_visible"
                                                   className="custom-control-label ">Share email in my DM app messages

                                            </label><span className="pricing-card__features-tooltip" id="emailTooltip">
                                                            <svg style={{margin: 5}} className="icon"
                                                                 viewBox="0 0 20 20" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                                                                      fill="currentColor"/>
                                                                <path
                                                                    d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z"
                                                                    fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                            <UncontrolledTooltip placement="bottom" target={`emailTooltip`}
                                                                 autohide={false}
                                                                 innerClassName="areas-tooltip">
                                                We recommend you share your email with people who you reach out to directly on the BizNexus platform. We only show your notification email to people you choose to reach out to within the BizNexus app, so they can get back to you outside of the BizNexus platform if preferred.
                                            </UncontrolledTooltip>
                                        </div>

                                        <span className="error">{this.state.errors["notification_email"]}</span>

                                    </FormGroup>

                                </Col>
                            </Row>



                            <FormGroup>
                                <Label
                                    htmlFor="about">About Me</Label>
                                <textarea
                                    className="form-control pl-2 my-0"
                                    style={{ minHeight: '170px' }}
                                    placeholder=""
                                    onChange={this.profilehandleChange.bind(this, "about_overview")}
                                    value={this.state.fields["about_overview"]}
                                ></textarea>
                                {/* <Editor
                                            editorState={editorState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="rich-editor"
                                            onEditorStateChange={this.onEditorStateChange}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji', 'remove', 'history'],
                                            }}
                                            spellCheck={true}
                                        /> */}
                                <span className="error">{this.state.errors["about"]}</span>
                            </FormGroup>

                            <FormGroup>
                                <Label
                                    htmlFor="about">About the niche I’m focused on</Label>
                                <textarea
                                    className="form-control pl-2 my-0"
                                    style={{ minHeight: '100px' }}
                                    placeholder=""
                                    onChange={this.profilehandleChange.bind(this, "focus_on")}
                                    value={this.state.fields["focus_on"]}
                                ></textarea>
                            </FormGroup>

                            <Row>


                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="auto_reply_message">Introductory Video</Label>
                                        <Input type="text" id=""
                                               placeholder="https://www.youtube.com/..."
                                               onChange={this.profilehandleChange.bind(this, "video_url")}
                                               value={this.state.fields["video_url"]}/>
                                        <span className="error">{this.state.errors["video_url"]}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="LinkedIn">Website</Label>
                                        <Input type="text" id="LinkedIn" placeholder="https://..."
                                               onChange={this.profilehandleChange.bind(this, "website")}
                                               value={this.state.fields["website"]}/>
                                        <span className="error">{this.state.errors["website"]}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="Phone">Phone</Label>
                                        <InputMask mask="(999) 999-9999" onChange={(e) => {
                                            this.setState({phone: e.target.value})
                                        }} value={this.state.phone}>
                                            {(inputProps) => <Input {...inputProps} type="text" name="phonenumber" id="phonenumber" ref="phonenumber"/>}
                                        </InputMask>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="LinkedIn">LinkedIn
                                            Profile {!this.state.fields["linkedin_url"] && warningIcon}</Label>
                                        <Input type="text" id="LinkedIn"
                                               placeholder="https://..."
                                               onChange={this.profilehandleChange.bind(this, "linkedin_url")}
                                               value={this.state.fields["linkedin_url"]}/>
                                        <span className="error">{this.state.errors["linkedin_url"]}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="email">Facebook Profile</Label>
                                        <Input type="text" id="Facebook"
                                               placeholder="https://..."
                                               onChange={this.profilehandleChange.bind(this, "facebook_url")}
                                               value={this.state.fields["facebook_url"]}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="email">Twitter Profile</Label>
                                        <Input type="text" id="Twitter"
                                               placeholder="https://..."
                                               onChange={this.profilehandleChange.bind(this, "twitter_url")}
                                               value={this.state.fields["twitter_url"]}/>
                                    </FormGroup>
                                </Col>
                            </Row>


                            {this.state.user_type_id === 3 && <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="nda_link">Link to NDA
                                            <i style={{margin: '5px'}} id="NDA" className="sprite i-question"></i>
                                            <UncontrolledTooltip placement="bottom" target="NDA"
                                                                 autohide={false}
                                                                 innerClassName="areas-tooltip">Complete NDA & Calendar
                                                field to automatically send to buyers who inquire about your
                                                deals.</UncontrolledTooltip>
                                        </Label>
                                        <Input type="text" id=""
                                               onChange={this.profilehandleChange.bind(this, "nda_link")}
                                               value={this.state.fields["nda_link"]}/>
                                        <span className="error">{this.state.errors["nda_link"]}</span>
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label htmlFor="LinkedIn">Link to Calendar</Label>
                                        <Input type="text" id="calendar_link"
                                               onChange={this.profilehandleChange.bind(this, "calendar_link")}
                                               value={this.state.fields["calendar_link"]}/>
                                        <span className="error">{this.state.errors["calendar_link"]}</span>
                                    </FormGroup>
                                </Col>
                            </Row>}




                            <Row>
                                {this.state.user_type_id === 1 || this.state.user_type_id === 4 ? <Col sm="6">
                                    <FormGroup>
                                        <Label
                                            htmlFor="professionlist">{this.state.user_type_id === 1 ? 'What type of investor/acquirer are you?' : 'What type of service provider are you?'} {selectedOption.length === 0 && warningIcon}</Label>
                                        <Select
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control Select-multi' : 'Select-control Select-multi',
                                            }}
                                            name="professionlist"
                                            id="professionlist"
                                            isMulti
                                            isSearchable={false}
                                            isClearable={false}
                                            onChange={this.handleChangeselect}
                                            options={this.state.professionlist}
                                            value={selectedOption}
                                            ref="professionlist"
                                            style={{backgroundColor: 'white'}}
                                        />

                                        <span
                                            className="error">{this.state.errors["professionlist"]}</span>
                                    </FormGroup>
                                </Col> : null}
                                <Col sm="6">
                                    <FormGroup>
                                        <Label htmlFor="yearsindustry">Number of Years
                                            Experience {!industryselectedOption && warningIcon}</Label>
                                        <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                            name="yearsindustry"
                                            id="yearsindustry"
                                            isSearchable={false}
                                            isClearable={false}
                                            onChange={this.handleChange}
                                            options={this.state.yearsindustry}
                                            value={industryselectedOption}
                                            ref="yearsindustry"
                                            style={{backgroundColor: 'white'}}
                                        />
                                        <span
                                            className="error">{this.state.errors["yearsindustry"]}</span>
                                    </FormGroup>
                                </Col>
                                {this.state.user_type_id === USER_TYPE.BROKER && <Col sm="6">
                                    <FormGroup>
                                        <Label htmlFor="brokerlicensenumber">M&A Advisor License Number (If
                                            Applicable)</Label>
                                        <Input type="text" name="brokerlicensenumber" id="brokerlicensenumber"
                                               ref="brokerlicensenumber"
                                               onChange={this.profilehandleChange.bind(this, "brokerlicensenumber")}
                                               value={this.state.fields["brokerlicensenumber"]}
                                               placeholder="000013856"/>
                                    </FormGroup>

                                </Col>}
                            </Row>


                            <p className="text-success text-center mt-3">{this.state.success["success"]}</p>
                            <p className="text-center pt-3 pb-4"><Button style={{width: 200}}
                                                                         onClick={this.profilehandleSubmit}
                                                                         color="primary">Save</Button>
                            </p>
                        </div>}
                    </Col>
                </Row>


                          
                                <Modal size={'lg'} isOpen={this.state.boostModalOpen} toggle={() => this.setState({ boostModalOpen: !this.state.boostModalOpen })} className="modal-dialog modal-featured" keyboard={false} backdrop="static">
                                    <FeaturedProfile
                                        description={this.state.fields['about_overview']}
                                        onClose={() =>this.setState({ boostModalOpen: false }) }
                                    />
                                </Modal>  



                
                                
           </div>
        )
    }


}

export default withRouter(Profile);
