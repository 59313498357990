import React, { createRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import moment from 'moment';
// import '../static/chat.css';
import SimpleBar from "simplebar-react";
import { getImageUrl } from '../util';
import ReactGA from 'react-ga4';


export default class NewChat extends React.Component {
	messagesEnd;
	msg_history;
    img;

	constructor(props) {
		super(props);
		this.state = {
			message: '',
			messages: [],
			messagesModal: false,
			messageSending: false,
		};
		this.elementRef = React.createRef();
	}

	fetchMessages(message = '') {
		return fetch(process.env.REACT_APP_API_ROOT + 'messages/get_by_thread/' + this.props.guru_id, {
			method: 'get',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'x-access-token': localStorage.getItem('token'),
			},
		}).then(function (res) {
			if (res.status < 200 || res.status >= 300) {
				throw res;
			} else { return res.json() }
		}).then(response => {
			const serviceMessage = {
				body: `${this.props.guru_name} has received a notification about your message and will respond soon. You'll get a notification via email if you're not logged on. <br>${
					this.props.nda
					? `In the interim, please go ahead and sign ${this.props.guru_name}'s NDA here: ${this.props.nda}`
					: response[0] && response[0].receiver.nda_link && response[0].receiver.calendar_link
						? `In the interim, please go ahead and sign ${this.props.guru_name}'s NDA here: ${response[0].receiver.nda_link}`
						: ''
				}<br>${response[0] && response[0].receiver.calendar_link && response[0].receiver.nda_link ? `Once you've completed the NDA, you can go ahead and schedule a time to meet with ${this.props.guru_name} here: ${response[0].receiver.calendar_link} ` : ''}If they don’t respond, we recommend you <strong>stay on them</strong>, and contact them outside of BizNexus with their contact info below:`,
				sender: {name: 'BizNexus'},
				transaction: {},
				transaction_id: this.props.transaction_id,
				sent_on: false,
			};
			const messages = response.filter((r) => r.transaction_id === this.props.transaction_id)
			if(messages.length === 1 && messages[0].to_user_id === this.props.guru_id) {
				response.push(serviceMessage);
			}

			if(message) {
				ReactGA.event({ category: 'Messages', action: 'Listing inquiry sent' });
			}
			this.setState(prevState => ({ ...prevState, messages: response.filter((r) => r.transaction_id === this.props.transaction_id) }));
			
			if(this.msg_history) {
                
				const { scrollHeight, clientHeight } = this.msg_history.current;
				const maxScrollTop = scrollHeight - clientHeight;
				this.msg_history.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
			}
		})
	}
	
	componentDidMount() {

		if(this.props.message) {
			this.sendMessage(this.props.message);
		} 
		
		this.fetchMessages();
		if (this.props.scrollInto) {
			const topPosition = this.elementRef.current.offsetTop;
			window.scrollTo({
				top: topPosition - 350,
				behavior: 'smooth'
			});
		}
	}

	sendMessage = (message = '') => {
		const messageToSent = message ? message : this.state.message;

		if(!messageToSent) {
			return;
		}
		this.setState({
			messageSending: true
		})

		fetch(process.env.REACT_APP_API_ROOT + 'messages', {
			method: 'POST',
			body: JSON.stringify({
				message: messageToSent,
				guru_id: this.props.guru_id,
				transaction_id: this.props.transaction_id,
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'x-access-token': localStorage.getItem('token'),
			},
		}).then(function (res) {
			if (res.status < 200 || res.status >= 300) {
				throw res;
			} else { return res.json() }
		}).then(res => {
			if(res.success) {
				if(this.props.transaction_id === 0) {
					ReactGA.event({ category: 'Messages', action: 'Profile Message Send' });
				} else {
					ReactGA.event({ category: 'Messages', action: 'Message Send' });
				}
				this.fetchMessages(message).finally(() => {
					this.setState({ message: '', messageSending: false, });

				})
			} else {
				this.setState({messagesModal: true, messageSending: false, message: '' });
			}
			
			
		});
	}

	urlify(text) {
		const urlRegex = /(https?:\/\/[^\s]+)/g;
		return text.replace(urlRegex, '<a target="_blank" href="$1">$1</a>');
	}
	

    render() {

        this.msg_history = createRef();
		
        return (
			<div className="chat">
                <div className="chat-header">
                    <div className="d-flex">
                    <img src={this.props.guru_name === 'Seller' ? '/images/logo-big.png' : (this.props.guru_avatar) ? (this.props.guru_avatar.indexOf('base64') > -1) ? `${this.props.guru_avatar}` : (this.props.guru_avatar.indexOf('user_profile') > -1) ? getImageUrl(this.props.guru_avatar) : getImageUrl(this.props.guru_avatar) : '/images/logo-big.png'}
                                ref={img => this.img = img} onError={
                                  (e) => { 
                                    //@ts-ignore
                                    if(!e.target.src.includes('images/logo-big.png')) {
                                      this.img.src = `/images/logo-big.png`
                                    }
                                  }
                                }  className="chat-header-avatar" alt="User" />
                    <p className="chat-header-title">Chat with {this.props.guru_name}</p>
                    </div>
                    <button onClick={() => this.fetchMessages()} className="btn btn-unstyled chat-header-btn">
                    <svg className="icon" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.281 7.71901C4.21133 7.64917 4.12857 7.59375 4.03745 7.55594C3.94633 7.51813 3.84865 7.49867 3.75 7.49867C3.65135 7.49867 3.55367 7.51813 3.46255 7.55594C3.37143 7.59375 3.28867 7.64917 3.219 7.71901L0.219001 10.719C0.0781714 10.8598 -0.000946045 11.0508 -0.000946045 11.25C-0.000946045 11.4492 0.0781714 11.6402 0.219001 11.781C0.359831 11.9218 0.550838 12.001 0.750001 12.001C0.949165 12.001 1.14017 11.9218 1.281 11.781L3.75 9.31051L6.219 11.781C6.35983 11.9218 6.55084 12.001 6.75 12.001C6.94917 12.001 7.14017 11.9218 7.281 11.781C7.42183 11.6402 7.50095 11.4492 7.50095 11.25C7.50095 11.0508 7.42183 10.8598 7.281 10.719L4.281 7.71901ZM23.781 6.21901C23.7113 6.14917 23.6286 6.09375 23.5375 6.05594C23.4463 6.01813 23.3487 5.99867 23.25 5.99867C23.1514 5.99867 23.0537 6.01813 22.9626 6.05594C22.8714 6.09375 22.7887 6.14917 22.719 6.21901L20.25 8.68951L17.781 6.21901C17.6402 6.07818 17.4492 5.99907 17.25 5.99907C17.0508 5.99907 16.8598 6.07818 16.719 6.21901C16.5782 6.35984 16.4991 6.55085 16.4991 6.75001C16.4991 6.94918 16.5782 7.14018 16.719 7.28101L19.719 10.281C19.7887 10.3509 19.8714 10.4063 19.9626 10.4441C20.0537 10.4819 20.1514 10.5014 20.25 10.5014C20.3487 10.5014 20.4463 10.4819 20.5375 10.4441C20.6286 10.4063 20.7113 10.3509 20.781 10.281L23.781 7.28101C23.8508 7.21134 23.9063 7.12858 23.9441 7.03746C23.9819 6.94635 24.0013 6.84866 24.0013 6.75001C24.0013 6.65136 23.9819 6.55368 23.9441 6.46256C23.9063 6.37144 23.8508 6.28868 23.781 6.21901Z" fill="currentColor"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.49999C10.7507 1.49904 9.52107 1.81046 8.42287 2.40593C7.32466 3.00141 6.39278 3.86203 5.712 4.90949C5.60007 5.06819 5.43094 5.17729 5.2402 5.21382C5.04947 5.25036 4.852 5.21148 4.68934 5.10538C4.52668 4.99927 4.41153 4.83421 4.3681 4.64493C4.32467 4.45564 4.35636 4.25689 4.4565 4.0905C5.51474 2.46425 7.07018 1.22353 8.89098 0.553276C10.7118 -0.116974 12.7004 -0.180861 14.5605 0.371137C16.4206 0.923136 18.0524 2.06146 19.2129 3.61643C20.3733 5.1714 21.0002 7.05975 21 9C21 9.19911 20.9209 9.39006 20.7801 9.53086C20.6393 9.67165 20.4484 9.75074 20.2493 9.75074C20.0501 9.75074 19.8592 9.67165 19.7184 9.53086C19.5776 9.39006 19.4985 9.19911 19.4985 9C19.4985 7.01087 18.7083 5.10322 17.3018 3.69669C15.8953 2.29017 13.9876 1.49999 11.9985 1.49999H12ZM3.75 8.25C3.94891 8.25 4.13968 8.32901 4.28033 8.46967C4.42098 8.61032 4.5 8.80108 4.5 9C4.49944 10.6173 5.0217 12.1915 5.98888 13.4877C6.95606 14.784 8.31634 15.7328 9.86686 16.1928C11.4174 16.6528 13.075 16.5993 14.5927 16.0402C16.1103 15.4811 17.4065 14.4464 18.288 13.0905C18.34 13.0041 18.4088 12.9292 18.4904 12.87C18.572 12.8108 18.6647 12.7687 18.7629 12.7462C18.8611 12.7237 18.9629 12.7212 19.0621 12.7388C19.1613 12.7565 19.256 12.794 19.3404 12.8491C19.4248 12.9041 19.4972 12.9756 19.5534 13.0593C19.6095 13.143 19.6483 13.2371 19.6672 13.3361C19.6862 13.4351 19.685 13.5369 19.6637 13.6354C19.6425 13.7339 19.6016 13.8271 19.5435 13.9095C18.4853 15.5357 16.9298 16.7765 15.109 17.4467C13.2882 18.117 11.2996 18.1809 9.4395 17.6289C7.57944 17.0769 5.94756 15.9385 4.78712 14.3836C3.62669 12.8286 2.99983 10.9402 3 9C3 8.90138 3.01945 8.80373 3.05723 8.71264C3.09502 8.62154 3.1504 8.5388 3.2202 8.46913C3.29 8.39947 3.37286 8.34426 3.46403 8.30665C3.55519 8.26905 3.65288 8.2498 3.7515 8.25H3.75Z" fill="currentColor"/>
                    </svg>

                    </button>
                </div>
                <div className={`chat-body ${!this.state.messages.length && 'no-messages'}`}>
                    <SimpleBar scrollableNodeProps={{ ref: this.msg_history }} className="vertical-scroll-layout js-chat-body-scroll-wrapper">
                    {this.state.messages.map((m, i) =>     
                    <div className={`chat-body__message ${m.sender.id === this.props.guru_id ? 'chat-body__message--sent' : 'chat-body__message--received'}`}>
                        <div>
                        <p className="chat-body__message-meta">{m.sender.id === this.props.guru_id ?  m.sender.user_type_id === 3 ? m.sender.name : 'Owner' : m.sender.name} {m.sent_on ? moment(new Date(m.sent_on)).format('ddd Do, h:mm A'): null}</p>
                        <div className="chat-body__message-body">
                            <p dangerouslySetInnerHTML={{__html : this.urlify(m.body)}}></p>
                        </div>
                        </div>
                    </div>)}
                    
                    </SimpleBar>
                </div>

                <div className="chat-footer">

									{
										!this.props.isFSBO &&
										<div className="more-info-in-chat" style={{ display: 'flex', marginBottom: 15,}}>
											{!this.props.isFSBO && this.props.guruEmail ? <a target="_blank" rel="noopener noreferrer" href={`mailto:${this.props.guruEmail}?subject=BizNexus Lead&body=${encodeURI(this.props.body)}`} className="chat-box-btn me-2">
												<i className="far fa-envelope" style={{ fontSize: 24 }}/>
												Direct email
											</a> : ''}
											{!this.props.isFSBO && <a href={`/profile/${this.props.guru_id}`} target="_blank" rel="noopener noreferrer" className="chat-box-btn">

												<i className="fa-solid fa-user-tie" style={{ fontSize: 24 }}/>

												More info
											</a>}
										</div>
									}
                    <div className="row no-gutters">
                    <div className="col">
                        <label htmlFor="chat-message" className="sr-only">Message</label>
                        <textarea
							ref={this.elementRef}
							value={this.state.message}
							style={{height: 'auto'}}
							disabled={this.state.messageSending}
				  			onChange={(e) => this.setState({ message: e.target.value })}
							className="form-control" name="chat-message" id="chat-message" rows={5} placeholder="type a message..."/>
                    </div>
                    <div className="col-auto">
                        <button
													disabled={this.state.messageSending}
													onClick={() => this.sendMessage()} className="btn btn-unstyled chat-footer-btn h-100 px-4">
                        <i className="far fa-paper-plane"></i>

                        </button>
                    </div>
                    </div>
                </div>
                <Modal isOpen={this.state.messagesModal} toggle={() => this.setState({ messagesModal: !this.state.messagesModal })}>
                    <ModalHeader toggle={() => this.setState({ messagesModal: !this.state.messagesModal })}>BizNexus</ModalHeader>
                    <ModalBody>
                        Your daily messages limit is exceeded
                    </ModalBody>
                    <ModalFooter>
                    <a href="/premium" className="btn btn-success">Become a Premium</a>
                    </ModalFooter>
                </Modal>
            </div>
		
		
        )
    }  

}


