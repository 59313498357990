import React from 'react';
import {
    Button,
    Col,
    Form,
    FormGroup,
    Label,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, InputGroup, Input,
} from 'reactstrap';
import Select from 'react-select';
import {Link} from 'react-router-dom';
import api from '../../../services/api';

const AwardCertificationTypes = {
    certification: 1,
    award: 2,
}

export default class Awards extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            certificationsOption: '',
            certificationAwardInputError: '',
            certificationAwardInput: '',
            certificationsOptionselect: '',
            certificationsdropdown: [],
            certificationslist: [],
            awardslistOption: '',
            awardslistOptionselect: '',
            awardslistdropdown: [],
            awardslist: [],
            Deletelistmodal: false,
            itemID: '',
            itemDeleted: '',
        };
    }

    getCertificationList = async () => {

        api.get('certification/alllist', null, true)
            .then(res => {

                let certificationsarray = [];
                res.list.forEach((certificationslist, i) => {
                    let temp = {
                        "value": certificationslist.id,
                        "label": certificationslist.value
                    };
                    if( ! this.state.certificationslist.some(el => el.certification_edu_name === certificationslist.value)) {
                        certificationsarray.push(temp);
                    }
                });
                this.setState({certificationsdropdown: certificationsarray});
            });
    }

    getCertificationsList = () => {
        return api.post('certification/list', {typeValue: 1}, true)
            .then(res => {
                if (res.list !== undefined) {
                    this.setState({certificationslist: res.list});
                } else {
                    this.setState({certificationslist: []});
                }
            });
    }

    getAwardList = async () => {

        return api.get('award/alllist', null, true)
            .then(res => {
                let awardlistarray = [];
                res.list.forEach((awardslist, i) => {
                    let temp = {
                        "value": awardslist.id,
                        "label": awardslist.value
                    };

                    if( ! this.state.awardslist.some(el => el.name === awardslist.value)) {
                        awardlistarray.push(temp);
                    }

                });
                this.setState({awardslistdropdown: awardlistarray});
            });
    }

    getAwardsList = () => {
        return api.post('award/list', {typeValue: 1}, true)
            .then(res => {
                if (res && res.list !== undefined) {
                    this.setState({awardslist: res.list});
                } else {
                    this.setState({awardslist: []});
                }
            });
    }

    async init() {
        await this.getCertificationsList();
        this.getCertificationList();

        await this.getAwardsList();
        this.getAwardList();


    }

    componentDidMount() {
        this.init();

    }


    handleRemoveawardsClick = async (e) => {
        e.preventDefault();
        this.setState({Deletelistmodal: !this.state.Deletelistmodal});
        if (e.target.getAttribute("data-itemID") !== '') {
            document.getElementById('loaderawards').setAttribute("style", "display: block;");
            let jsonRemoveawardsData = {
                "awardId": e.target.getAttribute("data-itemID")
            };
            await api.post('award/deleteaward', jsonRemoveawardsData, true)
                .then(() => {
                    this.setState({itemID: '', itemDeleted: ''});
                    document.getElementById('loaderawards').setAttribute("style", "display: none;");
                });
            this.init();
        }
    }

    awardshandleChange = (awardslistOption) => {
        if (awardslistOption.value === 0) {
            this.setState({newCertificateAward: AwardCertificationTypes.award})
        } else {
            this.setState({awardslistOption, awardslistOptionselect: Object.keys(awardslistOption)});
        }
    }

    awardsValidation() {
        let errors = {};
        let formIsValid = true;
        if (!this.state.awardslistOptionselect.length > 0) {
            formIsValid = false;
            errors["awards"] = "Please select any relevant awards you've received.";
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    awardshandleSubmit = async (event) => {
        event.preventDefault();
        if (this.awardsValidation()) {
            document.getElementById('loaderawards').setAttribute("style", "display: block;");
            let jsonawardsData = {
                "typeValue": "1",
                "name": this.state.awardslistOption.label
            };
            await api.post('award/add', jsonawardsData, true)
                .then(() => {
                    this.setState({awardslistOption: '', awardslistOptionselect: ''});
                    document.getElementById('loaderawards').setAttribute("style", "display: none;");
                });
            this.init();
        }
    }

    handleRemovecertiClick = async (e) => {
        e.preventDefault();
        this.setState({Deletelistmodal: !this.state.Deletelistmodal});
        if (e.target.getAttribute("data-itemID") !== '') {
            document.getElementById('loadercertifications').setAttribute("style", "display: block;");
            let jsonRemovecertiData = {
                "certificationId": e.target.getAttribute("data-itemID")
            };
            await api.post('certification/deleteCertificate', jsonRemovecertiData, true)
                .then(() => {
                    this.setState({itemID: '', itemDeleted: ''});
                    document.getElementById('loadercertifications').setAttribute("style", "display: none;");
                });
            this.init();
        }
    }

    handleChangecertifications = (certificationsOption) => {
        if (certificationsOption.value === 0) {
            this.setState({newCertificateAward: AwardCertificationTypes.certification})
        } else {
            this.setState({certificationsOption, certificationsOptionselect: Object.keys(certificationsOption)});
        }
    }

    certificationsValidation() {
        let errors = {};
        let formIsValid = true;
        if (!this.state.certificationsOptionselect.length > 0) {
            formIsValid = false;
            errors["certifications"] = "Please select any relevant certifications.";
        }
        this.setState({errors: errors});
        return formIsValid;
    }


    onAddNew = async (type) => {
        if (!this.state.certificationAwardInput || !(this.state.certificationAwardInput.trim())) {
            this.setState({certificationAwardInputError: 'Name is required'})
            return
        }
        if (type === AwardCertificationTypes.certification) {
            document.getElementById('loadercertifications').setAttribute("style", "display: block;");
            let jsoncertificationsData = {
                "typeValue": "1",
                "name": this.state.certificationAwardInput,
            };
            this.setState({certificationAwardInput: '', newCertificateAward: false})

            await api.post('certification/add', jsoncertificationsData, true)
                .then(() => {
                    this.setState({certificationsOption: '', certificationsOptionselect: ''});
                    document.getElementById('loadercertifications').setAttribute("style", "display: none;");
                });
            this.init();
        } else if (type === AwardCertificationTypes.award) {
            document.getElementById('loaderawards').setAttribute("style", "display: block;");
            let jsonawardsData = {
                "typeValue": "1",
                "name": this.state.certificationAwardInput,
            };
            this.setState({certificationAwardInput: '', newCertificateAward: false})
            await api.post('award/add', jsonawardsData, true)
                .then(() => {
                    this.setState({awardslistOption: '', awardslistOptionselect: ''});
                    document.getElementById('loaderawards').setAttribute("style", "display: none;");
                });

            this.init();
        }
    }

    certificationshandleSubmit = async (event) => {
        event.preventDefault();
        if (this.certificationsValidation()) {
            document.getElementById('loadercertifications').setAttribute("style", "display: block;");
            let jsoncertificationsData = {
                "typeValue": "1",
                "name": this.state.certificationsOption.label
            };

            await api.post('certification/add', jsoncertificationsData, true)
                .then(() => {
                    this.setState({certificationsOption: '', certificationsOptionselect: ''});
                    document.getElementById('loadercertifications').setAttribute("style", "display: none;");
                });
            this.init();
        }
    }

    Deletelisttoggle = (e) => {
        e.preventDefault();
        let deleteID = e.target.id.split("_");
        this.setState({Deletelistmodal: !this.state.Deletelistmodal, itemID: deleteID[1], itemDeleted: deleteID[0]});
    }


    render() {
        const {certificationsOption, awardslistOption} = this.state;

        return (
            <div>
                <div>
                    <div className="loader" id="loadercertifications" style={{display: 'none'}}>
                        <div className="spiner"></div>
                    </div>
                    <h1 className="h1">Add Some Bonafides To Your Public Profile To Get More Inquiries & Responses</h1>
                    <div className="" style={{paddingTop: 30}}>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Label className="h2 mb-3">Certifications: <i id="CertificationsTooltip"
                                                                                  className="sprite i-question"></i>
                                        <UncontrolledTooltip placement="bottom" target="CertificationsTooltip"
                                                             autohide={false}
                                                             innerClassName="certification-tooltip">Don't see
                                            the certification you're looking for? Simply shoot us a message in
                                            our chat box in the lower right-hand corner of your screen and we'll
                                            add it for you!</UncontrolledTooltip></Label>
                                </FormGroup>
                                {
                                    this.state.certificationslist.length > 0 &&
                                    <ul className="certificationslist">
                                        {this.state.certificationslist.map((certifications, j) => {
                                            return (
                                                <li key={`certificationslist-${j}`}>
                                                    <Link to="" onClick={e => {
                                                        e.preventDefault()
                                                    }}>{certifications.certification_edu_name}<i
                                                        className="sprite i-delete"
                                                        id={`certifications_${certifications.id}`}
                                                        onClick={this.Deletelisttoggle}></i></Link>
                                                </li>
                                            )
                                        })
                                        }
                                    </ul>
                                }
                            </Col>
                        </Row>
                        <Form onSubmit={this.certificationshandleSubmit}>
                            <Row>
                                <Col sm="6">
                                    <FormGroup className="selectpicker">
                                        <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                            name="certifications"
                                            id="certifications"
                                            placeholder="Certifications"
                                            isSearchable={true}
                                            isClearable={false}
                                            onChange={this.handleChangecertifications}
                                            options={this.state.certificationsdropdown.concat([{
                                                label: '+ Create New',
                                                value: 0
                                            }])}
                                            value={certificationsOption}
                                            onInputChange={(value) => this.setState({certificationSearchInput: value})}
                                            ref="certifications"
                                        />
                                        <span
                                            className="error">{this.state.errors["certifications"]}</span>
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <Button className="btn-plus"><i className="sprite i-plus"></i></Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>

                <div>
                    <div className="loader bg-white" id="loaderawards" style={{display: 'none'}}>
                        <div className="spiner"></div>
                    </div>
                    <div className="">
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Label className="h2 mb-3">Associations & Awards: <i id="AwardsTooltip"
                                                                                         className="sprite i-question"></i>
                                        <UncontrolledTooltip placement="bottom" target="AwardsTooltip"
                                                             autohide={false}
                                                             innerClassName="certification-tooltip">Don't see
                                            the association or award you're looking for? Simply shoot us a
                                            message in our chat box in the lower right-hand corner of your
                                            screen and we'll add it for you!</UncontrolledTooltip></Label>
                                </FormGroup>
                                {
                                    this.state.awardslist.length > 0 &&
                                    <ul className="certificationslist">
                                        {this.state.awardslist.map((awardslists, j) => {
                                            return (
                                                <li key={`awardslists-${j}`}>
                                                    <Link to="" onClick={e => {
                                                        e.preventDefault()
                                                    }}>{awardslists.name}<i className="sprite i-delete"
                                                                            id={`awardslists_${awardslists.id}`}
                                                                            onClick={this.Deletelisttoggle}></i></Link>
                                                </li>
                                            )
                                        })
                                        }
                                    </ul>
                                }
                            </Col>
                        </Row>
                        <Form onSubmit={this.awardshandleSubmit}>
                            <Row>
                                <Col sm="6">
                                    <FormGroup className="selectpicker">
                                        <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                            name="awards"
                                            id="awards"
                                            placeholder="Associations & Awards"
                                            isSearchable={true}
                                            isClearable={false}
                                            onChange={this.awardshandleChange}
                                            options={this.state.awardslistdropdown.concat([{
                                                label: '+ Create New',
                                                value: 0
                                            }])}
                                            value={awardslistOption}
                                            ref="awards"
                                        />
                                        <span className="error">{this.state.errors["awards"]}</span>
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <Button className="btn-plus"><i className="sprite i-plus"></i></Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>

                <Modal isOpen={this.state.newCertificateAward} toggle={() => this.setState({
                    newCertificateAward: false,
                    certificationAwardInput: '',
                    certificationAwardInputError: ''
                })}
                       className="modal-dialog-centered delete-item" keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({
                        newCertificateAward: false,
                        certificationAwardInput: '',
                        certificationAwardInputError: ''
                    })}>
                        Create a
                        new {this.state.newCertificateAward === AwardCertificationTypes.certification ? 'Certificate' : 'Award'}
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label htmlFor="" md={4}>Name</Label>
                            <Col md={7}>
                                <InputGroup>
                                    <Input
                                        invalid={this.state.certificationAwardInputError}
                                        onKeyPress={e => {
                                        if (e.charCode === 13) {
                                            this.onAddNew(this.state.newCertificateAward);
                                        }
                                    }} value={this.state.certificationAwardInput} onChange={(e) => this.setState({
                                        certificationAwardInput: e.target.value,
                                        certificationAwardInputError: ''
                                    })} type="text"/>
                                </InputGroup>
                                <span className="error ">{this.state.certificationAwardInputError}</span>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success"
                                onClick={() => this.onAddNew(this.state.newCertificateAward)}>Create</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.Deletelistmodal} toggle={this.Deletelisttoggle}
                       className="modal-dialog-centered delete-item" keyboard={false} backdrop="static">
                    <ModalHeader>Are you sure?</ModalHeader>
                    <ModalBody>
                        Would you like to remove this item from the list?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.Deletelisttoggle}>No</Button>
                        <Button color="danger"
                                onClick={(this.state.itemDeleted === 'testimonials') ? this.handleRemovetestimonialsClick : (this.state.itemDeleted === 'awardslists') ? this.handleRemoveawardsClick : this.handleRemovecertiClick}
                                data-itemID={this.state.itemID}>Yes</Button>
                    </ModalFooter>
                </Modal>

            </div>
        )
    }


}
