import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import Geosuggest from '@ubilabs/react-geosuggest';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
    Button,
    Col,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    InputGroup, InputGroupText,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
    UncontrolledTooltip,
} from 'reactstrap';

import { unknownValue } from '../services/html';
import { cleanNumericString } from '../services/intl';
import api from "../services/api";
import {TransactionCompletedStatuses} from "../constants/Listing";
import Pagination from "react-js-pagination";
import {withRouter} from "../containers/withRouter/withRouter";
import IndustryFilter from "./cards/SearchFilters/IndustryFilter";
import _ from "lodash";

const MAX_PRICE_VALUE = 2147483647

var TransactioncategoryRemoveClicked = false;
class CompletedTransactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            errors: {},
            success: {},
            transactionmodal: false,
            transactionactiveTab: '1',
            locationvalues: [],
            transactionlist: [],
            brokerIndustry: [],
            currentPage: 1,
            totalCount: 0,
            dealPerPage: 10,
            dealLoading: true,
            location: '',
            latitude: '',
            longitude: '',
            city: '',
            state: '',
            listingmonthselectedOption: '',
            listingmonth: [],
            listingyearselectedOption: '',
            listingyear: [],
            sellingmonthselectedOption: '',
            sellingmonth: [],
            sellingyearselectedOption: '',
            sellingyear: [],
            category_date: moment(),
            transactionData: 'none',
            secondlevelmax: '',
            selectlabel: 1,
            Deletelistmodal: false,
            editId: '',
            itemID: '',
            dropdownCategoryTransaction: false,
            industrylistTransaction: [],
        };
        this.transactiontoggle = this.transactiontoggle.bind(this);
        this.transactiontabtoggle = this.transactiontabtoggle.bind(this);
        this.Deletelisttoggle = this.Deletelisttoggle.bind(this);
        this.SubmitTransaction = this.SubmitTransaction.bind(this);
        this.SubmitTransactionupload = this.SubmitTransactionupload.bind(this);
        this.transactioncollapsestoggle = this.transactioncollapsestoggle.bind(this);
        this.arrowtoggleTransaction = this.arrowtoggleTransaction.bind(this);
        this.CategorytoggleTransaction = this.CategorytoggleTransaction.bind(this);
    }
    transactioncollapsestoggle(e) {
        e.preventDefault();
        this.setState({ transactioncollapse: !this.state.transactioncollapse });
        if (this.state.transactionData === '') {
            this.setState({ transactionData: 'none' });
        } else {
            this.setState({ transactionData: '' });
        }
    }
    transactiontoggle() {
        let categorydata = this.state.industrylistTransaction;
        // eslint-disable-next-line
        this.state.industrylistTransaction.map((category, i) => {
            categorydata[i].checked = false;
            categorydata[i].partialed = false;
            // eslint-disable-next-line
            categorydata[i].children.map((lists, j) => {
                categorydata[i].children[j].checked = false;
            });
        });
        this.setState({ transactionmodal: !this.state.transactionmodal, editId: '', fields: [], listingmonthselectedOption: '', listingyearselectedOption: '', sellingmonthselectedOption: '', sellingyearselectedOption: '', locationvalues: [], location: [], latitude: '', longitude: '', city: '', state: '', errors: {}, secondlevelmax: '', selectlabel: 1, industrylistTransaction: categorydata, brokerIndustry: [], success: '' });
        window.setTimeout(() => {
            if (document.getElementById('transactiontitle') !== null) {
                document.getElementById('transactiontitle').focus();
            }
        }, 500);
    }
    transactiontabtoggle(tab) {
        if (this.state.transactionactiveTab !== tab) {
            this.setState({ transactionactiveTab: tab, success: '' });
        }
        window.setTimeout(() => {
            if (document.getElementById('transactiontitle') !== null) {
                document.getElementById('transactiontitle').focus();
            }
        }, 100);
    }
    componentDidMount() {
        this.gettransactionlist();
        fetch(process.env.REACT_APP_API_ROOT + 'industry/legacy', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => res.json())
            .then(
                (res) => {
                    //console.log(res.list);
                    this.setState({ industrylistTransaction: res, brokerIndustry: [], });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        //error
                    });
                }
            )
        let listingyear = [];
        let sellingyear = [];
        for (let i = 1950; i <= moment().year(); i++) {
            let temp = {
                "value": i,
                "label": i
            };
            listingyear.push(temp);
            sellingyear.push(temp);
        }
        let montharray = [{ "value": "January", "label": "January" }, { "value": "February", "label": "February" }, { "value": "March", "label": "March" }, { "value": "April", "label": "April" }, { "value": "May", "label": "May" }, { "value": "June", "label": "June" }, { "value": "July", "label": "July" }, { "value": "August", "label": "August" }, { "value": "September", "label": "September" }, { "value": "October", "label": "October" }, { "value": "November", "label": "November" }, { "value": "December", "label": "December" }];
        this.setState({ listingmonth: montharray, listingyear: listingyear.reverse(), sellingmonth: montharray, sellingyear: sellingyear.reverse() });
    }
    CategorytoggleTransaction = () => {
        if (TransactioncategoryRemoveClicked) {
            TransactioncategoryRemoveClicked = !TransactioncategoryRemoveClicked
            return;
        }
        this.setState({ dropdownCategoryTransaction: !this.state.dropdownCategoryTransaction });
    }
    arrowtoggleTransaction = (e) => {
        let id = e.target.id;
        let splitValue = id.split("_");
        let splitid = splitValue[1];
        let categorydata = this.state.industrylistTransaction;
        if (categorydata[splitid].toggle === undefined) {
            categorydata[splitid].toggle = true;
        } else {
            if (categorydata[splitid].toggle === false) {
                categorydata[splitid].toggle = true;
            } else {
                categorydata[splitid].toggle = false;
            }
        }
        this.setState({ industrylistTransaction: categorydata, brokerIndustry: [], });
    }
    checkValidationForCategorytransaction() {
        let parenti = 0;
        let childi = 0;
        // eslint-disable-next-line
        this.state.industrylistTransaction.map((industrySelected, i) => {
            if (industrySelected.checked === true) {
                parenti++;
            } else {
                // eslint-disable-next-line
                industrySelected.children.map((industryChildren, j) => {
                    if (industryChildren.checked === true) {
                        childi++;
                    }
                });
            }
        });
        if (parenti === 1 && childi === 1) {
            this.setState({ selectlabel: 0 });
        } else if (parenti === 0 && childi === 1) {
            this.setState({ selectlabel: 0 });
        } else if (parenti === 1 && childi === 0) {
            this.setState({ selectlabel: 0 });
        } else {
            this.setState({ selectlabel: 1 });
        }
    }
    gettransactionlist = async () => {
        this.setState({
            dealLoading: true
        })
        await fetch(process.env.REACT_APP_API_ROOT + `transaction/?completed=1&page=${this.state.currentPage}&limit=${this.state.dealPerPage}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        })
        .then(r => r.json())
        .then(r => this.setState({transactionlist: r.list, totalCount: r.count, dealLoading: false }))
    }
    handleEditTransactionClick = (e) => {
        e.preventDefault();
        if (e.target.id !== '') {
            document.getElementById('loadertransaction').setAttribute("style", "display: block;");
            fetch(process.env.REACT_APP_API_ROOT + 'transaction/' + e.target.id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    'x-access-token': localStorage.getItem('token'),
                },
            })
            .then(r => r.json())
            .then((res) => {
                document.getElementById('loadertransaction').setAttribute("style", "display: none;");
                this.transactiontoggle();
                let fields = this.state.fields.slice();
                fields["transactiontitle"] = res.name;
                fields["saleprice"] = cleanNumericString(res.price);
                fields["revenue"] = cleanNumericString(res.revenue);
                fields["cashflow"] = cleanNumericString(res.cashflow);
                fields["keywords"] = res.keywords;
                let dataArea = [];
                let temp = '';
                temp = {
                    "description": "", //res.location_address,
                    "label": "", //res.location_address,
                    "latitude": res.latitude,
                    "longitude": res.longitude

                };
                dataArea.push(temp);

                fetch(process.env.REACT_APP_API_ROOT + 'transaction/' + res.id + '/legacyindustries', {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        'x-access-token': localStorage.getItem('token'),
                    },
                })
                    .then(r => r.json())
                    .then((legacy_industries) => {
                        const selectedIndustries = []
                        legacy_industries.forEach(industry => {
                            if (industry.checked) {
                                selectedIndustries.push(industry)
                            }
                            if (industry.children && industry.children.length) {
                                industry.children.forEach(subIndustry => {
                                    if (subIndustry.checked) {
                                        selectedIndustries.push(subIndustry)
                                    }
                                })
                            }
                        })

                        this.setState({
                            editId: res.id,
                            fields,
                            listingmonthselectedOption: res.listing_date ? moment(res.listing_date).format("MMMM") : null,
                            listingyearselectedOption: res.listing_date ? moment(res.listing_date).format("YYYY") : null,
                            sellingmonthselectedOption: res.selling_date ? moment(res.selling_date).format("MMMM") : null,
                            sellingyearselectedOption: res.selling_date ? moment(res.selling_date).format("YYYY") : null,
                            industrylistTransaction: legacy_industries,
                            brokerIndustry: selectedIndustries,
                            selectlabel: res.industries.length === 0,
                            locationvalues: dataArea,
                            location: res.location_address,
                            latitude: res.latitude,
                            longitude: res.longitude,
                            city: res.city,
                            state: res.state,
                        });
                    });

                this.transactiontabtoggle('1');
            },
                (error) => {
                    this.setState({
                        //error
                    });
                }
            )
        }

    }
    Deletelisttoggle = (e) => {
        e.preventDefault();
        this.setState({ Deletelistmodal: !this.state.Deletelistmodal, itemID: e.target.id });
    }
    handleRemoveCompletedTransactionClick = async (e) => {
        this.setState({ Deletelistmodal: !this.state.Deletelistmodal });
        if (e.target.getAttribute("data-itemID") !== '') {
            document.getElementById('loadertransaction').setAttribute("style", "display: block;");

            //console.log(jsonCompletedtransactionData);
            await fetch(process.env.REACT_APP_API_ROOT + `transaction/${e.target.getAttribute("data-itemID")}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    'x-access-token': localStorage.getItem('token'),
                },
            }).then((res) => {
                if (res.status < 200 || res.status >= 300) {
                    //console.log("Error 401");
                    //console.log(res);
                    if (res.status === 401) {
                        this.props.history.push('/');
                    } else {
                        return res.json().then(err => { throw err; });
                    }
                } else { return res }
            }).then(r => this.gettransactionlist())
            
            document.getElementById('loadertransaction').setAttribute("style", "display: none;");
        }
    }
    handletransactionValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        //transactiontitle
        if (!fields["transactiontitle"]) {
            formIsValid = false;
            errors["transactiontitle"] = "Please enter business name or a transaction title";
        }
        //listingdate
        if (!this.state.listingyearselectedOption) {
            formIsValid = false;
            errors["listingyear"] = "Please select.";
            //errors["listingdate"] = "Please select year company was listed for sale";
        }
        //listingdate
        if (!this.state.sellingyearselectedOption) {
            formIsValid = false;
            errors["sellingyear"] = "Please select.";
            //errors["sellingdate"] = "Please select date the company sold";
        }
        //saleprice
        if (!fields["saleprice"]) {
            formIsValid = false;
            errors["saleprice"] = "Please enter final sale price";
        }
        if (typeof fields["saleprice"] !== "undefined") {
            if (!fields["saleprice"].match(/^(?=.*[0-9]).{2,}$/)) {
                formIsValid = false;
                errors["saleprice"] = "Please enter final sale price";
            }
            else if (isNaN(parseFloat(fields["saleprice"]))) {
                errors["saleprice"] = "Please enter only numbers.";
            }
        }
        //revenue
        if (!fields["revenue"]) {
            formIsValid = false;
            errors["revenue"] = "Please enter annual revenue";
        }
        if (typeof fields["revenue"] !== "undefined") {
            if (!fields["revenue"].match(/^(?=.*[0-9]).{2,}$/)) {
                formIsValid = false;
                errors["revenue"] = "Please enter annual revenue";
            }
            else if (isNaN(parseFloat(fields["revenue"]))) {
                errors["revenue"] = "Please enter only numbers.";
            }
        }
        //cashflow
        if (!fields["cashflow"]) {
            formIsValid = false;
            errors["cashflow"] = "Please enter annual cash flow";
        }
        if (typeof fields["cashflow"] !== "undefined") {
            if (!fields["cashflow"].match(/^(?=.*[0-9]).{2,}$/)) {
                formIsValid = false;
                errors["cashflow"] = "Please enter annual cash flow";
            }
            else if (isNaN(parseFloat(fields["cashflow"]))) {
                errors["cashflow"] = "Please enter only numbers.";
            }
        }
        //category
        if (this.state.selectlabel === 1) {
            formIsValid = false;
            errors["category"] = "Please select relevant categories";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleChangetransaction(field, e) {
        if (e.target !== '' && e.target !== undefined) {
            let fields = this.state.fields;
            let errors = this.state.errors;
            fields[field] = e.target.value;
            if (e.target.name === 'saleprice') {
                let saleprice = e.target.value.replace(/[^0-9]/g, '');
                if (saleprice > MAX_PRICE_VALUE) {
                    errors[field] = 'Max value limit exceeded'
                } else {
                    fields[field] = saleprice.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    delete errors[field]
                }
            }
            if (e.target.name === 'revenue') {
                let revenue = e.target.value.replace(/[^0-9]/g, '');
                if (revenue > MAX_PRICE_VALUE) {
                    errors[field] = 'Max value limit exceeded'
                } else {
                    fields[field] = revenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    delete errors[field]
                }
            }
            if (e.target.name === 'cashflow') {
                let cashflow = e.target.value.replace(/[^0-9]/g, '');
                if (cashflow > MAX_PRICE_VALUE) {
                    errors[field] = 'Max value limit exceeded'
                } else {
                    fields[field] = cashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    delete errors[field]
                }
            }
            this.setState({ fields, errors });
        }
    }
    handleChangelistingmonth = (listingmonthselectedOption) => {
        this.setState({ listingmonthselectedOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (listingmonthselectedOption) {
            // console.log(`Selected: ${listingmonthselectedOption.label}`);
        }
    }
    handleChangelistingyear = (listingyearselectedOption) => {
        this.setState({ listingyearselectedOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (listingyearselectedOption) {
            let sellingyear = [];
            for (let i = listingyearselectedOption.value; i <= moment().year(); i++) {
                let temp = {
                    "value": i,
                    "label": i
                };
                sellingyear.push(temp);
            }
            this.setState({ sellingyear: sellingyear.reverse(), sellingyearselectedOption: '' });
            // console.log(`Selected: ${listingyearselectedOption.label}`);
        }
    }
    handleChangesellingmonth = (sellingmonthselectedOption) => {
        this.setState({ sellingmonthselectedOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (sellingmonthselectedOption) {
            // console.log(`Selected: ${sellingmonthselectedOption.label}`);
        }
    }
    handleChangesellingyear = (sellingyearselectedOption) => {
        let errors = this.state.errors
        delete errors.sellingyear
        this.setState(prevState => ({ ...prevState, sellingyearselectedOption, errors }));
        // selectedOption can be null when the `x` (close) button is clicked
        if (sellingyearselectedOption) {
            // console.log(`Selected: ${sellingyearselectedOption.label}`);
        }
    }
    handleGeosuggestChange = (location) => {
        let dataArea = [];
        let temp = '';
        if (location !== undefined) {
            temp = {
                "location": location.label,
                "latitude": location.location.lat,
                "longitude": location.location.lng
            };
            if (this.state.locationvalues.length !== 0) {
                this.state.locationvalues.push(temp)
            } else {
                this.state.locationvalues.push(temp)
            }
            dataArea.push(temp);
            this.setState({ locationvalues: dataArea });
            let addressComponent = location.gmaps.address_components;
            for (var j = 0; j < location.gmaps.address_components.length; j++) {
                if (addressComponent[j].types[0] === 'locality') {
                    //$('#ListingCity').val(addressComponent[j].long_name);
                    this.setState({ city: addressComponent[j].long_name });
                }
                if (addressComponent[j].types[0] === 'administrative_area_level_1') {
                    //$('#ListingState').val(addressComponent[j].long_name);
                    this.setState({ state: addressComponent[j].long_name });
                }
            }
            this.setState({ location: location.label });
            this.setState({ latitude: location.location.lat });
            this.setState({ longitude: location.location.lng });
        }
        else {
            this.setState({ locationvalues: [], location: '', latitude: '', longitude: '', city: '', state: '' });
        }
    }
    SubmitTransaction = async (event) => {
        event.preventDefault();
        if (this.handletransactionValidation()) {
            document.getElementById('loadertransactionform').setAttribute("style", "display: block;");
            let fields = this.state.fields;
            let keywords = '';
            if (fields["keywords"] !== undefined) {
                keywords = fields["keywords"]
            }
            let categoryArrayData = [];
            // eslint-disable-next-line
            this.state.industrylistTransaction.map((industrySelected, i) => {
                if (industrySelected.checked === true) {
                    let categoryarray = {
                        "id": industrySelected.value,
                        "name": industrySelected.label,
                        "path": industrySelected.label + ">>All"
                    };
                    categoryArrayData.push(categoryarray);
                } else {
                    // eslint-disable-next-line
                    industrySelected.children.map((industryChildren, j) => {
                        if (industryChildren.checked === true) {
                            let categoryarray = {
                                "id": industryChildren.value,
                                "name": industryChildren.label,
                                "path": industrySelected.label + ">>" + industryChildren.label
                            };
                            categoryArrayData.push(categoryarray);
                        }
                    });
                }
            });

            const listingdate = moment((
                (this.state.listingmonthselectedOption ? this.state.listingmonthselectedOption.value : "") + 
                " " + 
                (this.state.listingyearselectedOption ? this.state.listingyearselectedOption.value : "")));

            const sellingdate = moment((
                (this.state.sellingmonthselectedOption ? this.state.sellingmonthselectedOption.value : "") +
                " " +
                (this.state.sellingyearselectedOption ? this.state.sellingyearselectedOption.value : "")));

            let jsontransactionsData = JSON.stringify({
                "id": this.state.editId,
                "name": fields["transactiontitle"],
                "price": fields["saleprice"],
                "city": this.state.city,
                "state": this.state.state,
                "completed": "1",
                "revenue": fields["revenue"],
                "cashflow": fields["cashflow"],
                "status": "1",
                "listing_date": listingdate.isValid() ? listingdate.toDate() : null,
                "selling_date": sellingdate.isValid() ? sellingdate.toDate() : null,
                "latitude": this.state.latitude,
                "longitude": this.state.longitude,
                "industries": categoryArrayData.map(c => ({ industry_id: c.id })),
                "keywords": keywords,
                "category_date": this.state.category_date.format("YYYY-MM-DD")
            });
            //console.log(jsontransactionsData);
            
            await fetch(process.env.REACT_APP_API_ROOT + 'transaction/' + (this.state.editId || ""), {
                method: 'POST',
                body: jsontransactionsData,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    'x-access-token': localStorage.getItem('token'),
                },
            }).then((res) => {
                if (res.status < 200 || res.status >= 300) {
                    //console.log("Error 401");
                    //console.log(res);
                    if (res.status === 401) {
                        this.props.history.push('/');
                    } else {
                        return res.json().then(err => { throw err; });
                    }
                } else { return res.json() }
            })
                .then(
                    (res) => {
                        //console.log(res);                        
                        let categorydata = this.state.industrylistTransaction;
                        // eslint-disable-next-line
                        this.state.industrylistTransaction.map((category, i) => {
                            categorydata[i].checked = false;
                            categorydata[i].partialed = false;
                            // eslint-disable-next-line
                            categorydata[i].children.map((lists, j) => {
                                categorydata[i].children[j].checked = false;
                            });
                        });

                        document.getElementById('loadertransactionform').setAttribute("style", "display: none;");
                        // eslint-disable-next-line
                        fields["transactiontitle"] = '', fields["saleprice"] = '', fields["revenue"] = '', fields["cashflow"] = '', fields["keywords"] = '';
                        let success = {};
                        // success["success"] = res.message;
                        this.refs.location.update('');
                        this.setState({ transactionmodal: false, success: success, locationvalues: [], industrylistTransaction: categorydata, brokerIndustry: [], listingmonthselectedOption: '', listingyearselectedOption: '', sellingmonthselectedOption: '', sellingyearselectedOption: '', secondlevelmax: '', selectlabel: 1, editId: '' });
                        // if (document.getElementById('transactiontitle') !== null) {
                        //     document.getElementById('transactiontitle').focus();
                        // }
                        // document.getElementById('transactionmodal').scrollIntoView(true);
                    }
                )
                .catch(err => {
                    document.getElementById('loadertransactionform').setAttribute("style", "display: none;");
                    // eslint-disable-next-line
                    let errors = err;
                    this.setState({ errors });
                })
            this.gettransactionlist();
        } else {
            document.getElementById('transactionmodal').scrollIntoView(true);
            //console.log("Form has errors.")
        }
    }
    SubmitTransactionupload = async (ev) => {
        ev.preventDefault();
        let errors = {};
        let formIsValid = true;
        if (this.uploadInput.files[0] === undefined) {
            errors["uploadtransactionfile"] = "Please select file.";
            formIsValid = false;
        }
        else if (this.uploadInput.files[0].size > 5120010) {
            errors["uploadtransactionfile"] = "Please select file size should be less than 5mb";
            formIsValid = false;
        }
        else {
            // eslint-disable-next-line
            let validExts = new Array(".xlsx", ".xls");
            let fileExt = this.uploadInput.files[0].name;
            fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
            if (validExts.indexOf(fileExt) < 0) {
                errors["uploadtransactionfile"] = "Invalid file selected, valid files are of " + validExts.toString() + " types.";
                formIsValid = false;
            }
            else {
                document.getElementById('loadertransactionupload').setAttribute("style", "display: block;");
                var form = new FormData();
                form.append("filename", this.uploadInput.files[0]);
                form.append("userId", this.props.userId.toString());
                await fetch(process.env.REACT_APP_API_ROOT + 'transaction/uploadTransaction', {
                    method: 'POST',
                    body: form,
                    headers: {
                        'mimeType': "multipart/form-data"
                    },
                }).then(function (res) {
                    if (res.status < 200 || res.status >= 300) {
                        //console.log("Error 401");
                        //console.log(res);
                        //return res.json().then(err => {throw err;});
                        throw res;
                        //throw Error(.statusText);
                    } else { return res.json() }
                })
                    .then(
                        (res) => {
                            //console.log(res);
                            document.getElementById('loadertransactionupload').setAttribute("style", "display: none;");
                            let success = {};
                            success["success"] = res.message;
                            this.setState({ Clientlist: [], success: success, editId: '' });
                            /*window.setTimeout(() => {
                                this.setState({ success: '' });
                            }, 5000);*/
                        },
                        (error) => {
                            this.setState({
                                //error
                            });
                        }
                    )
                this.gettransactionlist();
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    onChangeVisibility = (checked, id) => {
        const completed_id = checked ? TransactionCompletedStatuses.Completed : TransactionCompletedStatuses.CompletedTurnedOff;
        api.post(`transaction/visibility/${id}`, { completed_id }, true).then(() => {
            this.gettransactionlist();
        });
    }

    getUpdatedIndustryList = (item, checked) => {
        return this.state.industrylistTransaction.map(i =>
            i.value === item.value
                ? {...i, checked}
                : i.children && i.children.length
                    ? {...i, children: i.children.map(ic => ic.value === item.value ? {...ic, checked} : ic)}
                    : i
        )
    }
    getParentAndChildCounts = (newItems) => {
        let parentCount = 0;
        let childCount = 0;

        // eslint-disable-next-line
        newItems.forEach((industrySelected, i) => {
            if (industrySelected.children && industrySelected.children.length) {
                parentCount++;
            } else {
                childCount++;
            }
        });
        return {parentCount, childCount}
    }

    onRemoveBrokerIndustry = item => {
        const brokerIndustry = _.reject(this.state.brokerIndustry, bi => bi.value === item.value);
        const industrylistTransaction = this.getUpdatedIndustryList(item, false)
        const {parentCount, childCount} = this.getParentAndChildCounts(brokerIndustry)

        if (parentCount === 1 && childCount === 1) {
            this.setState({secondlevelmax: 1, ListingdropdownCategory: false});
        } else {
            this.setState({secondlevelmax: '', selectlabel: 0});
        }
        this.setState({
            brokerIndustry,
            industrylistTransaction
        });
    }

    onUpdateBrokerIndustry = (items = []) => {
        const selectedItem = items.find(item => !this.state.brokerIndustry.some(bi => bi.value === item.value))
        if (!selectedItem) {
            const deselectedItem = this.state.brokerIndustry.find(bi => !items.some(item => bi.value === item.value))
            this.onRemoveBrokerIndustry(deselectedItem)
            return
        }
        const newIndustryList = this.getUpdatedIndustryList(selectedItem, true)
        const {parentCount, childCount} = this.getParentAndChildCounts(items)

        if (parentCount < 3 && childCount < 3) {
            if (parentCount === 1 && childCount === 1) {
                this.setState({secondlevelmax: 1, ListingdropdownCategory: false});
            } else {
                this.setState({secondlevelmax: '', selectlabel: 0});
            }
        } else {
            this.setState({secondlevelmax: 1, ListingdropdownCategory: false});
            return
        }
        this.setState({
            brokerIndustry: items,
            industrylistTransaction: newIndustryList
        });
    }

    handlePageChange = pageNumber => {

        this.setState(prevState => ({
            ...prevState,
            currentPage: pageNumber
        }), () => this.gettransactionlist())
    }

    render() {
        const { listingmonthselectedOption, listingyearselectedOption, sellingmonthselectedOption, sellingyearselectedOption } = this.state;
        return (
            <div>
                
                <div>
                    <FormGroup className="control-bx">
                        <div>
                            <Label className="h1 mb-3">Completed Transactions
                            <i style={{ margin: '8px' }} id="MessagesTooltip" className="sprite i-question"></i>
                            <UncontrolledTooltip placement="bottom" target="MessagesTooltip"
                                                autohide={false}
                                                innerClassName="areas-tooltip">
                                Completed transactions help your profile show up in search results and increase frequency of matching your profile and deal opportunities across our platform.
                            </UncontrolledTooltip>
                            </Label>
                            <button className="btn btn-primary d-block create_deal_btn" style={{position: 'static!important'}} onClick={this.transactiontoggle}>Add Completed Transactions Now</button>
                        </div>

                        {this.state.transactionlist.length === 0 && !this.state.dealLoading &&
                            <p className="">Your profile is missing completed transactions. <br />Upload your completed transactions now to get matched with appropriate sellers.</p>
                        }
                        
                        <Modal isOpen={this.state.transactionmodal} toggle={this.transactiontoggle} className="profile-modal listing-modal" keyboard={false} backdrop="static" id="transactionmodal" >
                            <ModalHeader toggle={this.transactiontoggle}>{this.state.editId !== '' ? "Update your transactions" : "Upload your past transactions"}</ModalHeader>
                            <ModalBody className="pt-3 pb-5">
                                {
                                    this.state.editId === '' &&
                                    <React.Fragment>
                                        <p className="text-center text-small mb-3">Submit past transactions.</p>
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink className={classnames({ active: this.state.transactionactiveTab === '1' })} onClick={() => { this.transactiontabtoggle('1'); }}>Add Transactions Manually</NavLink>
                                            </NavItem>
                                            {/* <NavItem>
                                                <NavLink className={classnames({ active: this.state.transactionactiveTab === '2' })} onClick={() => { this.transactiontabtoggle('2'); }}>Upload Spreadsheet</NavLink>
                                            </NavItem> */}
                                        </Nav>
                                    </React.Fragment>
                                }
                                <TabContent activeTab={this.state.transactionactiveTab}>
                                    <TabPane tabId="1" className={this.state.editId !== '' ? "border-0 p-0 active" : ""}>
                                        <div className="position-relative">
                                            <div className="loader bg-white" id="loadertransactionform" style={{ display: 'none' }}><div className="spiner"></div></div>
                                            <p className={
                                                this.state.success["success"] === "Transaction Added Successfully." || this.state.success["success"] === "Transaction Edited Successfully."
                                                    ? "text-success text-center font-size-18"
                                                    : " text-center font-size-18 text-success"}
                                            >{this.state.success["success"]}</p>
                                            {this.state.editId === '' && <p className="text-center">Please fill in the required fields so BizNexus can properly process your transaction.</p>}
                                            <Form onSubmit={this.SubmitTransaction}>
                                                <FormGroup row>
                                                    <Label htmlFor="transactiontitle" md={5}>Business Name or Transaction Title*</Label>
                                                    <Col md={6}>
                                                        <Input type="text" name="transactiontitle" id="transactiontitle" ref="transactiontitle" onChange={this.handleChangetransaction.bind(this, "transactiontitle")} value={this.state.fields["transactiontitle"]} placeholder="Business Name or Transaction Title" />
                                                        <span className="error ">{this.state.errors["transactiontitle"]}</span>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label htmlFor="location" md={5}>Location (Optional for Internet Co's)</Label>
                                                    <Col md={6}>
                                                        <div className="position-relative">
                                                            <Geosuggest
                                                                inputClassName="search-input form-control"
                                                                id="location"
                                                                name="location"
                                                                ref="location"
                                                                placeholder="Enter your location"
                                                                types={['(cities)']}
                                                                onSuggestSelect={this.handleGeosuggestChange}
                                                                initialValue={this.state.location}
                                                                country={['us', 'ca']} />
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label md={5}>Listing date*</Label>
                                                    <Col md={6}>
                                                        <div className="custom-gutter">
                                                            <Row>
                                                                <Col>
                                                                    <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                                                        name="listingmonth"
                                                                        id="listingmonth"
                                                                        isSearchable={false}
                                                                        isClearable={false}
                                                                        onChange={this.handleChangelistingmonth}
                                                                        options={this.state.listingmonth}
                                                                        value={listingmonthselectedOption}
                                                                        ref="listingmonth"
                                                                    />
                                                                    <span className="error ">{this.state.errors["listingmonth"]}</span>
                                                                </Col>
                                                                <Col>
                                                                    <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                                                        name="listingyear"
                                                                        id="listingyear"
                                                                        isSearchable={false}
                                                                        isClearable={false}
                                                                        onChange={this.handleChangelistingyear}
                                                                        options={this.state.listingyear}
                                                                        value={listingyearselectedOption}
                                                                        ref="listingyear"
                                                                    />
                                                                    <span className="error ">{this.state.errors["listingyear"]}</span>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label md={5}>Date Company Was Sold*</Label>
                                                    <Col md={6}>
                                                        <div className="custom-gutter">
                                                            <Row>
                                                                <Col>
                                                                    <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                                                        name="sellingmonth"
                                                                        id="sellingmonth"
                                                                        isSearchable={false}
                                                                        isClearable={false}
                                                                        onChange={this.handleChangesellingmonth}
                                                                        options={this.state.sellingmonth}
                                                                        value={sellingmonthselectedOption}
                                                                        ref="sellingmonth"
                                                                    />
                                                                    <span className="error">{this.state.errors["sellingmonth"]}</span>
                                                                </Col>
                                                                <Col>
                                                                    <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                                                        name="sellingyear"
                                                                        id="sellingyear"
                                                                        isSearchable={false}
                                                                        isClearable={false}
                                                                        onChange={this.handleChangesellingyear}
                                                                        options={this.state.sellingyear}
                                                                        value={sellingyearselectedOption}
                                                                        ref="sellingyear"
                                                                    />
                                                                    <span className="error">{this.state.errors["sellingyear"]}</span>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label htmlFor="saleprice" md={5}>Company Sale price*</Label>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroupText>
                                                                $
                                                            </InputGroupText>
                                                            <Input type="text" name="saleprice" id="saleprice" ref="saleprice" onChange={this.handleChangetransaction.bind(this, "saleprice")} value={this.state.fields["saleprice"]} />
                                                        </InputGroup>
                                                        <span className="error">{this.state.errors["saleprice"]}</span>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label htmlFor="revenue" md={5}>Annual Revenue*</Label>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroupText>
                                                                $
                                                            </InputGroupText>
                                                            <Input type="text" name="revenue" id="revenue" ref="revenue" onChange={this.handleChangetransaction.bind(this, "revenue")} value={this.state.fields["revenue"]} />
                                                        </InputGroup>
                                                        <span className="error">{this.state.errors["revenue"]}</span>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label htmlFor="cashflow" md={5}>Annual SDE*</Label>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroupText>
                                                                $
                                                            </InputGroupText>
                                                            <Input type="text" name="cashflow" id="cashflow" ref="cashflow" onChange={this.handleChangetransaction.bind(this, "cashflow")} value={this.state.fields["cashflow"]} />
                                                        </InputGroup>
                                                        <span className="error">{this.state.errors["cashflow"]}</span>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="custom-menu-category" row>
                                                    <Label htmlFor="" md={5}>Choose industry*  <i id="categoryTooltip" className="sprite i-question"></i>
                                                        <UncontrolledTooltip placement="bottom" target="categoryTooltip" autohide={false} innerClassName="areas-tooltip">Maximum 2 selection allowed</UncontrolledTooltip></Label>
                                                    <Col md={6}>
                                                        <Dropdown
                                                            isOpen={this.state.dropdownCategoryTransaction} toggle={this.CategorytoggleTransaction}
                                                        >
                                                            <DropdownToggle tag="a" className="form-control" caret>
                                                                Select Industry
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <IndustryFilter
                                                                    industriesList={this.state.industrylistTransaction}
                                                                    selectedIndustries={this.state.brokerIndustry}
                                                                    updateIndustries={this.onUpdateBrokerIndustry}
                                                                    onRemoveIndustry={this.onRemoveBrokerIndustry}
                                                                />
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                        <div className="d-flex flex-wrap align-items-center row-gutter-10 ms-1">
                                                            {this.state.brokerIndustry.map((item, i) =>
                                                                <>
                                                                    <div className="mt-1 ms-1">
                                                                        <button onClick={(e) => e.preventDefault()}
                                                                                className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                                                style={{
                                                                                    padding: '1.5px 11px',
                                                                                    wordBreak: 'break-all'
                                                                                }}>
                                                                            <span className="me-2">{item.label}</span>
                                                                            <svg id={i + "_parentselected"}
                                                                                 onClick={() => this.onRemoveBrokerIndustry(item)}
                                                                                 className="icon"
                                                                                 viewBox="0 0 14 13" fill="none"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                                      d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                                      fill="currentColor"/>
                                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                                      d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                                      fill="currentColor"/>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                        {/*<Dropdown isOpen={this.state.dropdownCategoryTransaction} toggle={this.CategorytoggleTransaction} >*/}
                                                        {/*    <DropdownToggle tag="a" className="form-control" caret>*/}
                                                        {/*        {*/}
                                                        {/*            (this.state.selectlabel === 1) ? "Select" :*/}
                                                        {/*                <span className="location-tags">*/}
                                                        {/*                    {*/}
                                                        {/*                        this.state.industrylistTransaction.map((selectedlists, i) => {*/}
                                                        {/*                            return (*/}
                                                        {/*                                <React.Fragment key={`div-industry-locations-${i}`}>*/}
                                                        {/*                                    {*/}
                                                        {/*                                        (selectedlists.checked === true) ?*/}
                                                        {/*                                            <span>*/}
                                                        {/*                                                <span className="selected-location">*/}
                                                        {/*                                                    <span id={i + "_parentselected_transaction"} onClick={this.removeTransactionCategoryClick.bind(this)} className="location-value-icon">×</span>*/}
                                                        {/*                                                    <span className="location-value-label">{selectedlists.label}</span>*/}
                                                        {/*                                                </span>*/}
                                                        {/*                                            </span> : ""*/}
                                                        {/*                                    }*/}
                                                        {/*                                    {*/}
                                                        {/*                                        selectedlists.children.map((staticchildrenlists, j) => {*/}
                                                        {/*                                            return (*/}
                                                        {/*                                                (staticchildrenlists.checked === true && selectedlists.checked === false) ?*/}
                                                        {/*                                                    <span key={`industry-locations-${i}-${j}`} className="selected-location">*/}
                                                        {/*                                                        <span id={i + "_childstatic_" + j + "_transaction"} onClick={this.removeTransactionCategoryClick.bind(this)} className="location-value-icon">×</span>*/}
                                                        {/*                                                        <span className="location-value-label">{selectedlists.label + ": " + staticchildrenlists.label}</span>*/}
                                                        {/*                                                    </span> : ""*/}
                                                        {/*                                            )*/}
                                                        {/*                                        })*/}
                                                        {/*                                    }*/}
                                                        {/*                                </React.Fragment>*/}
                                                        {/*                            )*/}
                                                        {/*                        })*/}
                                                        {/*                    }*/}
                                                        {/*                </span>*/}
                                                        {/*        }*/}
                                                        {/*    </DropdownToggle>*/}
                                                        {/*    <DropdownMenu>*/}
                                                        {/*        {*/}
                                                        {/*            this.state.industrylistTransaction.map((lists, i) => {*/}
                                                        {/*                return (*/}
                                                        {/*                    <div key={`categorylist-${i}`}>*/}
                                                        {/*                        <div className={(lists.partialed===true) ? "custom-checkbox custom-control partialed" :"custom-checkbox custom-control"}>*/}
                                                        {/*                            <Input type="checkbox" id={i + "_parent_transaction"} name={`category-${i}`} className="custom-control-input" onChange={this.parentCategoryTransaction.bind(this)} value={lists.value} checked={lists.checked} />*/}
                                                        {/*                            <Label className="custom-control-label" htmlFor={i + "_parent_transaction"}>{lists.label} </Label>*/}
                                                        {/*                            <i className={(lists.toggle === true) ? "caret-bottom up-arrow" : "caret-bottom"} onClick={this.arrowtoggleTransaction.bind(this)} id={'toggle_' + i}></i>*/}
                                                        {/*                        </div>*/}
                                                        {/*                        <div className={(lists.toggle === true) ? "categorylist-child" : "categorylist-child d-none"} >*/}
                                                        {/*                            {*/}
                                                        {/*                                lists.children.map((childrenlists, j) => {*/}
                                                        {/*                                    return (*/}

                                                        {/*                                        <div key={`childrenlists-${i}-${j}`} className="custom-checkbox custom-control childinput">*/}
                                                        {/*                                            <Input type="checkbox" id={i + "_child_" + j + "_transaction"} name={`childrenlists-${j}`} onChange={this.parentCategoryTransaction.bind(this)} className="custom-control-input" value={childrenlists.value} checked={childrenlists.checked} />*/}
                                                        {/*                                            <Label className="custom-control-label" htmlFor={i + "_child_" + j + "_transaction"}>{childrenlists.label} </Label>*/}
                                                        {/*                                        </div>*/}

                                                        {/*                                    )*/}
                                                        {/*                                })*/}
                                                        {/*                            }*/}
                                                        {/*                        </div>*/}
                                                        {/*                    </div>*/}
                                                        {/*                )*/}
                                                        {/*            })*/}
                                                        {/*        }*/}
                                                        {/*    </DropdownMenu>*/}
                                                        {/*</Dropdown>*/}
                                                        {this.state.secondlevelmax === "" && <span className="error">{this.state.errors["category"]}</span>}
                                                        {this.state.secondlevelmax !== "" && <span className="error">Maximum 2 selection allowed</span>}
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label htmlFor="keywords" md={5}>Keywords</Label>
                                                    <Col md={6}>
                                                        <Input type="text" name="keywords" id="keywords" ref="keywords" onChange={this.handleChangetransaction.bind(this, "keywords")} value={this.state.fields["keywords"]} placeholder="Enter Keywords" />
                                                    </Col>
                                                </FormGroup>
                                                <Button type="submit" className="btn-bluedark" disabled={Object.keys(this.state.errors).length}>{this.state.editId !== '' ? "Update Transaction" : "Add Transactions Manually to Profile"}</Button>
                                            </Form>
                                        </div>
                                    </TabPane>
                                    {
                                        this.state.editId === '' &&
                                        <TabPane tabId="2" className="upload-file">
                                            <div className="position-relative">
                                                <div className="loader bg-white" id="loadertransactionupload" style={{ display: 'none' }}><div className="spiner"></div></div>
                                                <h5>Step 1: Create Upload File</h5>
                                                <p>Using a spreadsheet program (Microsoft Excel, Apple Numbers, Google Spreadsheet), arrange your transaction history to match the <a href={process.env.PUBLIC_URL + '/Upload-Transactions-Template.xlsx'} className="text-blue">BizNexus Transaction Format</a> Specification and save the result as a .xlsx or .xlx file. For a sample of the spreadsheet, <a href={process.env.PUBLIC_URL + '/Upload-Transactions-Template.xlsx'} className="text-blue"> click here</a>. (Maximum size: 500 transactions or 5MB file size per upload)</p>
                                                <Table bordered hover size="sm" responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Location</th>
                                                            <th>YearListed</th>
                                                            <th>YearSold</th>
                                                            <th>SalePrice</th>
                                                            <th>Revenue</th>
                                                            <th>Cashflow</th>
                                                            <th>Category1</th>
                                                            <th>Category2</th>
                                                            <th>Keywords</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="bg-yellow">
                                                            <td>Required</td>
                                                            <td className="bg-white">Optional</td>
                                                            <td>Required</td>
                                                            <td>Required</td>
                                                            <td>Required</td>
                                                            <td>Required</td>
                                                            <td>Required</td>
                                                            <td>Required</td>
                                                            <td className="bg-white">Optional</td>
                                                            <td className="bg-white">Optional</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Company ABC For Sale</td>
                                                            <td>New York, New York</td>
                                                            <td>2016</td>
                                                            <td>2018</td>
                                                            <td>$800,000</td>
                                                            <td>$800,000</td>
                                                            <td>$800,000</td>
                                                            <td>Agriculture</td>
                                                            <td>Engineering > Consultants</td>
                                                            <td>saw mill</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Company XYZ For Sale</td>
                                                            <td>Boston, Massachusetts</td>
                                                            <td>2016</td>
                                                            <td>2017</td>
                                                            <td>$600,000</td>
                                                            <td>$400,000</td>
                                                            <td>$900,000</td>
                                                            <td>Engineering</td>
                                                            <td>Agriculture</td>
                                                            <td>Subway, KFC, McD</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Company JKL For Sale</td>
                                                            <td>New York, NY, USA</td>
                                                            <td>2015</td>
                                                            <td>2016</td>
                                                            <td>$650,000</td>
                                                            <td>$100,000</td>
                                                            <td>$600,000</td>
                                                            <td>Real Estate > Cafes</td>
                                                            <td>Engineering > Consultants</td>
                                                            <td>saw mill</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <h5>Step 2: Upload Transaction File</h5>
                                                <form onSubmit={this.SubmitTransactionupload}>
                                                    <FormGroup>
                                                        <InputGroup>
                                                            <input ref={(ref) => { this.uploadInput = ref; }} accept='.xlsx,.xls' className="form-control-file" type="file" />
                                                            <InputGroup addonType="append">
                                                                <Button type="submit" className="btn-bluedark">Upload File</Button>
                                                            </InputGroup>
                                                        </InputGroup>
                                                        <span className="error">{this.state.errors["uploadtransactionfile"]}</span>
                                                        <p className={this.state.success["success"] === "Transaction Added Successfully." ? "text-success error text-center mt-3" : "text-danger error text-center mt-3"}>{this.state.success["success"]}</p>
                                                    </FormGroup>
                                                </form>
                                                <h5>Step 3: Fix Errors & Submit</h5>
                                                <p>After you upload your transaction file, our system will review to ensure there are no errors. If errors exist, you will be able to correct them and then confirm the final transaction list for processing.</p>
                                                <h5>Step 4: Process Transactions</h5>
                                                <p>BizNexus will process the transactions. They should appear on your profile in 10 to 15 minutes, but may take up to 24 hours.</p>
                                            </div>
                                        </TabPane>
                                    }
                                </TabContent>
                            </ModalBody>
                        </Modal>
                        {/* <p className="text-center pt-2"><a href={process.env.PUBLIC_URL + '/Upload-Transactions-Template.xlsx'} download="Upload-Transactions-Template.xlsx" className="link-download">Download sample file here</a></p> */}

                        {
                            !!this.state.transactionlist.length &&
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.currentPage}
                                itemsCountPerPage={this.state.dealPerPage}
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />
                        }
                        <div className="user-listings-wrapper">
                            {
                                this.state.dealLoading &&
                                <div className="loader_listings_page" id="loaderlistings">
                                    <div className="spiner"/>
                                </div>
                            }
                            {
                                this.state.transactionlist.length > 0 &&
                                <div className="position-relative">
                                    <div className="loader bg-white" id="loadertransaction" style={{ display: 'none' }}><div className="spiner"></div></div>
                                    <Table responsive className="tbl-transaction">
                                        <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Listing Date</th>
                                            <th>Category</th>
                                            <th>Sale Price</th>
                                            <th>Revenue</th>
                                            <th>SDE</th>
                                            <th>Public</th>
                                            <th width="60"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.transactionlist.map((transactionlists, j) => {

                                                return (
                                                    <tr key={`transactionlists-${j}`}>
                                                        <td>{transactionlists.name}
                                                        </td>
                                                        <td>{transactionlists.listing_date ? moment(transactionlists.listing_date).format("MMMM, YYYY") : unknownValue()}</td>
                                                        <td>{transactionlists.industries.map(c => c.industry.name).join(", ")}</td>
                                                        <td>{transactionlists.price || unknownValue()}</td>
                                                        <td>{transactionlists.revenue || unknownValue()}</td>
                                                        <td>{transactionlists.cashflow || unknownValue()}</td>
                                                        <td className="text-center">
                                                            <div className="custom-control custom-switch">
                                                                <input
                                                                    onChange={(e) => this.onChangeVisibility(e.target.checked, transactionlists.id)}
                                                                    type="checkbox"
                                                                    checked={transactionlists.completed === 1 ? true : false}
                                                                    className="custom-control-input"
                                                                    id={`customSwitch${transactionlists.id}`}/>
                                                                <label className="custom-control-label"
                                                                       htmlFor={`customSwitch${transactionlists.id}`}></label>
                                                            </div>
                                                        </td>
                                                        <td className="text-right">
                                                            <Link to="" onClick={e => { e.preventDefault() }} className="me-3"><i id={transactionlists.id} onClick={this.handleEditTransactionClick} className="sprite i-edit"></i></Link>
                                                            <Link to="" onClick={e => { e.preventDefault() }}><i id={transactionlists.id} onClick={this.Deletelisttoggle} className="sprite i-delete"></i></Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                        </tbody>
                                    </Table>
                                </div>
                            }
                        </div>

                        {
                            !!this.state.transactionlist.length &&
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.currentPage}
                                itemsCountPerPage={this.state.dealPerPage}
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />
                        }
                        <Modal isOpen={this.state.Deletelistmodal} toggle={this.Deletelisttoggle} className="modal-dialog-centered delete-item" keyboard={false} backdrop="static">
                            <ModalHeader>Are you sure?</ModalHeader>
                            <ModalBody>
                                Would you like to remove this item from the list?
                        </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.Deletelisttoggle}>No</Button>
                                <Button color="danger" onClick={this.handleRemoveCompletedTransactionClick} data-itemID={this.state.itemID}>Yes</Button>
                            </ModalFooter>
                        </Modal>
                    </FormGroup>
                </div>
            </div>
        );
    }
}
export default withRouter(CompletedTransactions);
