import * as actionTypes from '../actionTypes';

const initalState = {
  featuredListings: {},
  loading: false,
};

const reducer = (state = initalState, {type, payload}) => {
  switch (type) {
    case actionTypes.GET_FEATURED_TRANSACTIONS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_FEATURED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        featuredListings: payload.transactions,
        loading: false,
      };
    case actionTypes.GET_FEATURED_TRANSACTIONS_FAIL:
      return {
        ...state,
        loading: false
      };
    default:
      return {...state};
  }
};

export default reducer;