import React from 'react';
import moment from 'moment';
import './style.css';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import api from '../../../services/api';
import stripe from '../../../constants/stripe';
import {trackEvent} from '../../util';
import {
    Collapse, CardBody, Card, ListGroup, ListGroupItem,
    Modal, ModalBody, ModalHeader, ModalFooter, Button, UncontrolledTooltip,
} from 'reactstrap';
import {SubscriptionLevels} from "../../../constants/subscriptionLevels";
import {USER_TYPE} from "../../../constants/userTypes";
import FAQ from "./components/FAQ/FAQ";
import PremiumPlan from "./components/SubscriptionPlans/PremiumPlan";
import ConciergeOption from "./components/SubscriptionPlans/ConciergeOption";
import PartnerOption from "./components/SubscriptionPlans/PartnerOption";
import queryString from "query-string";
import CustomAlert from "../../alerts/CustomAlert";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const refreshWithKey = value => {
    const parser = new URL(window.location);
    parser.searchParams.set('a', value);
    window.location = parser.href;
}


class Premium extends React.Component {


    constructor(props) {
        super(props);

        const queryParams = queryString.parse(window.location.search);

        this.state = {
            premium: false,
            premium_obj: null,
            checked: true,
            free_trial: false,
            free: false,
            invoiceModal: false,
            levelToUpgrade: null,

            upcoming: {},

            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            q6: false,
            qUpgrade: false,
            freeModal: false,
            premiumUntil: null,
            free_trial_end: false,

            eventMessage: '',
            eventHeader: 'Thank You!',
            eventModal: false,
            header: {status: null},
            alertMessage: queryParams.message ?  decodeURIComponent(queryParams.message) : false,
        };
    }

    componentDidMount() {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        document.getElementById('loader').setAttribute("style", "display: block;");
        if (params.has('listing_id') && params.has('level')) {
            api.post('premium/one-time-payment', {id: params.get('listing_id'), level: params.get('level')}, true)
                .then(res => {
                    this.props.stripe
                        .redirectToCheckout({
                            sessionId: res.sessionId,
                        })
                        .then(r => {
                            if (r.error) {
                                console.log(r.error);
                            }
                        })
                });
            return;
        }

        api.get('user/header', null, true)
            .then(res => {
                this.setState({
                    premium: res.premium,
                    header: res,
                    free_trial: res.free_trial,
                    premium_obj: res.premium_obj,
                    premium_level: res.premium_level,
                    free_trial_end: res.free_trial_end,
                    eventHeader: 'Thank You!',
                });

                if (res.premium && res.premium_obj.due_date) {
                    this.setState({premiumUntil: res.premium_obj.due_date});
                }
                document.getElementById('loader').setAttribute("style", "display: none;");

                const params = (new URL(document.location)).searchParams;

                if (params.has('a')) {
                    const event = params.get('a');
                    if (event === 'free_trial') {
                        trackEvent({
                            category: 'Subscription',
                            action: 'Free Trial Signup'
                        });
                        this.setState({eventMessage: 'Your free trial has started'});
                    }
                    if (event === 'upgrade') {
                        trackEvent({
                            category: 'Subscription',
                            action: 'Upgrade Membership',
                        });
                        this.setState({eventMessage: 'Your subscription has been successfully updated'});
                    }
                    if (event === 'premium_signup' || event === 'pro_signup') {
                        trackEvent({
                            category: 'Subscription',
                            action: event === 'premium_signup' ? 'Premium Signup' : 'Pro Signup',
                        });
                        this.setState({eventMessage: 'Your subscription has started'});
                    }
                    if (event === 'unsubscribe') {
                        trackEvent({
                            category: 'Subscription',
                            action: 'Unsubscribe'
                        });
                        this.setState({
                            eventMessage: 'You\'ve been successfully unsubscribed',
                            eventHeader: 'Done & done'
                        });
                    }
                    this.setState({eventModal: true});
                }
            });

        api.get('messages/free', null, true)
            .then(res => {
                this.setState({
                    free: res.free,
                });
            });
    }

    onUpgrade = () => {
        return api.post('premium/upgrade', {level: this.state.levelToUpgrade, annual: this.state.checked}, true)
            .then(() => {
                refreshWithKey('upgrade')
            });
    }

    onCheckout = (e, planId, level) => {
        e.preventDefault();

        if (this.state.premium_level === level && !this.state.premiumUntil) {
            return;
        }

        if (this.state.premium && this.state.premium_level !== level && !this.state.premiumUntil) {
            return api.post('premium/upcoming_invoice', {level, annual: this.state.checked}, true)
                .then(upcoming => {
                    this.setState({
                        upcoming,
                        invoiceModal: true,
                        levelToUpgrade: level,
                    });
                });
        }

        this.createCheckoutSession(planId, level)
            .then(res => {
                this.props.stripe
                    .redirectToCheckout({
                        sessionId: res.sessionId,
                    })
                    .then(r => {
                        if (r.error) {
                            console.log(r.error);
                        }
                    })
            });
    }

    createCheckoutSession = (planId, level) => {
        return api.post('premium/create-checkout-session', {planId, level}, true);
    }

    onCancel = (e) => {
        e.preventDefault();
        api.post('premium/unsubscribe', {id: this.state.premium_obj.id}, true)
            .then(() => {
                refreshWithKey('unsubscribe')
            })
    }

    onStartFreeTrial = () => {
        api.post('premium/start_free_trial', null, true)
            .then(() => {
                refreshWithKey('free_trial')
            });
    }

    render() { // 891
        moment.updateLocale('en', {
            week: {
                dow: 1,
                doy: 4
            }
        });

        const premiumLeft = moment(this.state.premiumUntil).diff(moment(new Date()), 'days');

        return (
            <div className="subscription-page">
                <div className="">

                    <div className="loader" id="loader" style={{display: 'none'}}>
                        <div className="spiner"/>
                    </div>
                    {
                        !!this.state.alertMessage && <CustomAlert text={this.state.alertMessage} type={'info'} dismissable />
                    }
                    <section className="mb-5">
                        {(this.state.free_trial && this.state.header.status === '1') &&
                            <div className="advertise-page">
                                <section className="mb-5">
                                    <div className="cta-banner">
                                        <div className="cta-banner__image">
                                            <img src="/images/free_trial.png"
                                                 className="img-fluid"
                                                 alt=""/>
                                        </div>
                                        <div className="cta-banner__content">
                                            <h2 className="cta-banner__content-title">Start Your Free 14 Day Trial
                                                Today</h2>
                                            <p className="cta-banner__content-description" style={{fontSize: 13}}>Try
                                                out our premium subscription for unlimited access to thousands of active
                                                deals on the BizNexus platform. New public, private and pre-CIM deals
                                                added (and removed) daily.</p>
                                            <button className="btn btn-primary-blue"
                                                    onClick={() => this.setState({freeModal: true})}>Try It Now
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            </div>}

                        {this.state.header.status === '0' &&
                            <div className="advertise-page">
                                <section className="mb-5">
                                    <div className="cta-banner">
                                        <div className="cta-banner__image">
                                            <i style={{fontSize: 130, color: '#3890da'}} className="far fa-clock"></i>
                                        </div>
                                        <div className="cta-banner__content">
                                            <h2 className="cta-banner__content-title">Your Account Is Under Review</h2>
                                            <p className="cta-banner__content-description" style={{fontSize: 13}}>It's
                                                not you, it's us. We review all new platform applicants within 24 hours
                                                of signup. During that time, you may have limited access to key features
                                                while we confirm you're not a malicious bot or bad actor. We appreciate
                                                your patience, and if you have any questions please reach out to our
                                                team at <a href="mailto:help@biznexus.com">help@biznexus.com</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>}

                        {this.state.premiumUntil &&
                            <div className="advertise-page">
                                <section className="mb-5">
                                    <div className="cta-banner">

                                        <div className="cta-banner__content">
                                            <h2 className="cta-banner__content-title">{premiumLeft === 0 ? `This is your last day` : `You have ${premiumLeft} days left`} of
                                                free access to the BizNexus Premium subscription</h2>
                                            <p className="cta-banner__content-description">Subscription ends
                                                on: {moment(this.state.premiumUntil).format('LLL')}</p>
                                        </div>
                                    </div>
                                </section>


                            </div>}

                        {this.state.free_trial_end &&
                            <div className="advertise-page">
                                <section className="mb-5">
                                    <div className="cta-banner">

                                        <div className="cta-banner__content">
                                            <h2 className="cta-banner__content-title">Your free access to the BizNexus
                                                Premium subscription has expired.</h2>
                                        </div>
                                    </div>
                                </section>
                            </div>}
                        <div className="pricing-plans-wrapper container-fluid" style={{paddingTop: 50}}>

                            <h1 className="h1 mb-3 text-center">Deal Origination Simplified</h1>

                            {this.state.header.user_type_id !== USER_TYPE.BROKER && ''
                                // <div className="d-flex mt-3" style={{justifyContent: 'center'}}>
                                //
                                //
                                //     <div className="btn-container">
                                //         <label className="switch btn-color-mode-switch">
                                //             <input type="checkbox" name="color_mode" id="color_mode"
                                //                    checked={this.state.checked} value={this.state.checked}
                                //                    onChange={(e) => this.setState({checked: e.target.checked})}/>
                                //             <label htmlFor="color_mode" data-on="Annual" data-off="Monthly"
                                //                    className="btn-color-mode-switch-inner"/>
                                //         </label>
                                //
                                //     </div>
                                //     {/*<p style={{ padding: '5px' }}>*/}
                                //     {/*    Monthly*/}
                                //     {/*</p>*/}
                                //     {/*<div className="">*/}
                                //     {/*    <section className="pricing">*/}
                                //     {/*        <label className="switch">*/}
                                //     {/*            <input checked={this.state.checked} value={this.state.checked} onChange={(e) => this.setState({checked: e.target.checked})} type="checkbox" id="togBtn" />*/}
                                //     {/*            <div className="slider round"></div>*/}
                                //     {/*        </label> */}
                                //     {/*    </section>  */}
                                //     {/*</div>*/}
                                //
                                //
                                // </div>
                            }

                            {this.state.header.user_type_id !== USER_TYPE.BROKER &&
                                <div className="text-center mb-4"><p style={{color: 'gray'}}>
                                    Our Membership Options
                                </p></div>}

                            {this.state.header.user_type_id === USER_TYPE.BROKER &&
                                <div className="row pb-2" style={{justifyContent: 'center'}}>

                                    <div className="col-4" style={{maxWidth: 375}}>
                                        <div className="pricing-card">
                                            <div className="pricing-card-head">
                                                <h2 className="pricing-card__title">Starter</h2>
                                                <p className="pricing-card__price">Free</p>

                                                <div className="content-divider" style={{marginTop: 42}}></div>
                                            </div>
                                            <ul className="pricing-card__features">


                                                <li>
                                                    <i className="fas fa-check-circle"
                                                       style={{color: 'green', fontSize: 16, paddingRight: 5}}></i>
                                                    Platform access
                                                </li>
                                                <li>
                                                    <i className="fas fa-check-circle"
                                                       style={{color: 'green', fontSize: 16, paddingRight: 5}}></i>
                                                    Buyer matching
                                                </li>

                                                <li>
                                                    <i className="fas fa-times-circle"
                                                       style={{color: 'red', fontSize: 16, paddingRight: 5}}></i>
                                                    Suggested buyers by teaser or listing
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`SuggestedBuyers`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`SuggestedBuyers`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        View 20 unique suggested buyers per listing or teaser based on investor mandate, industry focus, deal size and location.
                                                    </UncontrolledTooltip>
                                                </li>

                                                <li>
                                                    <i className="fas fa-times-circle"
                                                       style={{color: 'red', fontSize: 16, paddingRight: 5}}></i>
                                                    <span style={{ color: 'red' }}>NEW:</span> Buyer intent
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`BuyerIntent`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`BuyerIntent`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        View buyer info including contact info and linkedin profile when they favorite your deal
                                                    </UncontrolledTooltip>
                                                </li>

                                                <li>
                                                    <i className="fas fa-times-circle"
                                                       style={{color: 'red', fontSize: 16, paddingRight: 5}}></i>
                                                    Boosted profile & branding
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`BoostedProfile`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`BoostedProfile`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        Premium subscriber profiles are automatically boosted across our daily newsletter, social media content and throughout strategic locations in our marketplace.
                                                    </UncontrolledTooltip>
                                                </li>
                                                <li>
                                                    <i className="fas fa-times-circle"
                                                       style={{color: 'red', fontSize: 16, paddingRight: 5}}></i>
                                                    Premium directory exposure
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`PremiumDirectory`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`PremiumDirectory`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        Premium members get prioritized placement in our directory
                                                    </UncontrolledTooltip>
                                                </li>

                                                <li>
                                                    <i className="fas fa-times-circle"
                                                       style={{color: 'red', fontSize: 16, paddingRight: 5}}></i>
                                                    Seller matching

                                                </li>
                                                <li>
                                                    <i className="fas fa-times-circle"
                                                       style={{color: 'red', fontSize: 16, paddingRight: 5}}></i>
                                                    Buy-Side toolkit
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`BuySide`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`BuySide`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        Access advance search filters & set up projects for buy-side clients. Export results to CSV or integrate them directly with your buy-side clients CRM.
                                                    </UncontrolledTooltip>
                                                </li>
                                                <li>
                                                    <i className="fas fa-times-circle"
                                                       style={{color: 'red', fontSize: 16, paddingRight: 5}}></i>
                                                    Cold email toolkit
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`ColdEmail`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`ColdEmail`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        Get 10k free emails + 60 days of free access to our favorite tool for cold email with a 15% discount after that. Premium community cold email masterclass access included.
                                                    </UncontrolledTooltip>
                                                </li>
                                                <li>
                                                    <i className="fas fa-times-circle"
                                                       style={{color: 'red', fontSize: 16, paddingRight: 5}}></i>
                                                    M&A Advisor Bundle Masterclass Access
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`AdvisorBundle`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`AdvisorBundle`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        Access premium community masterclasses, events and groups specifically designed to help business intermediaries stay ahead of the most effective deal flow tactics & tools.
                                                    </UncontrolledTooltip>
                                                </li>


                                            </ul>
                                            <div className="position-relative text-center mt-auto">
                                                <button
                                                    class={`btn ${!this.state.premium ? 'btn-primary-blue' : 'btn-disabled'} pricing-card__select-btn`}>{this.state.premium ? '' : 'Current Plan'}</button>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-4" style={{maxWidth: 375}}>
                                        <div className="pricing-card">
                                            <div className="pricing-card-head">
                                                <h2 className="pricing-card__title">Premium</h2>
                                                <p className="pricing-card__price">
                                                    $997
                                                    <span className="pricing-card__price-addition">/year</span>
                                                </p>
                                                <div className="content-divider" style={{marginTop: 42}}></div>
                                            </div>
                                            {/* <h3 className="pricing-card__title-sm">Everything in Starter, plus:</h3> */}
                                            <ul className="pricing-card__features">
                                                <li>
                                                    <i className="fas fa-check-circle"
                                                       style={{color: 'green', fontSize: 16, paddingRight: 5}}></i>
                                                    Platform access
                                                </li>
                                                <li>
                                                    <i className="fas fa-check-circle"
                                                       style={{color: 'green', fontSize: 16, paddingRight: 5}}></i>
                                                    Buyer matching
                                                </li>

                                                <li>
                                                    <i className="fas fa-check-circle"
                                                       style={{color: 'green', fontSize: 16, paddingRight: 5}}></i>
                                                    Suggested buyers by teaser or listing
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`BuyersByTeaser`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`BuyersByTeaser`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        View 20 unique suggested buyers per listing or teaser based on investor mandate, industry focus, deal size and location.
                                                    </UncontrolledTooltip>
                                                </li>

                                                <li>
                                                    <i className="fas fa-check-circle"
                                                       style={{color: 'green', fontSize: 16, paddingRight: 5}}></i>
                                                    <span style={{ color: 'red' }}>NEW:</span> Buyer intent
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`IntentBuyer`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`IntentBuyer`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        View buyer info including contact info and linkedin profile when they favorite your deal
                                                    </UncontrolledTooltip>
                                                </li>

                                                <li>
                                                    <i className="fas fa-check-circle"
                                                       style={{color: 'green', fontSize: 16, paddingRight: 5}}></i>
                                                    Boosted profile & branding
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`Branding`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`Branding`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        Premium subscriber profiles are automatically boosted across our daily newsletter, social media content and throughout strategic locations in our marketplace.
                                                    </UncontrolledTooltip>
                                                </li>
                                                <li>
                                                    <i className="fas fa-check-circle"
                                                       style={{color: 'green', fontSize: 16, paddingRight: 5}}></i>
                                                    Premium directory exposure
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`Directory`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`Directory`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        Premium members get prioritized placement in our directory
                                                    </UncontrolledTooltip>
                                                </li>

                                                <li>
                                                    <i className="fas fa-check-circle"
                                                       style={{color: 'green', fontSize: 16, paddingRight: 5}}></i>
                                                    Seller matching

                                                </li>
                                                <li>
                                                    <i className="fas fa-check-circle"
                                                       style={{color: 'green', fontSize: 16, paddingRight: 5}}></i>
                                                    Buy-Side toolkit
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`Buy-Side`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`Buy-Side`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        Access advance search filters & set up projects for buy-side clients. Export results to CSV or integrate them directly with your buy-side clients CRM.
                                                    </UncontrolledTooltip>
                                                </li>
                                                <li>
                                                    <i className="fas fa-check-circle"
                                                       style={{color: 'green', fontSize: 16, paddingRight: 5}}></i>
                                                    Cold email toolkit
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`ColdEmail`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`ColdEmail`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        Get 10k free emails + 60 days of free access to our favorite tool for cold email with a 15% discount after that. Premium community cold email masterclass access included.
                                                    </UncontrolledTooltip>
                                                </li>
                                                <li>
                                                    <i className="fas fa-check-circle"
                                                       style={{color: 'green', fontSize: 16, paddingRight: 5}}></i>
                                                    M&A Advisor Bundle Masterclass Access
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`Masterclass`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`Masterclass`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        Access premium community masterclasses, events and groups specifically designed to help business intermediaries stay ahead of the most effective deal flow tactics & tools.
                                                    </UncontrolledTooltip>
                                                </li>


                                            </ul>

                                            <div className="position-relative text-center mt-auto">

                                                {!this.state.checked ?
                                                    this.state.header.status === '1' ? <button
                                                        onClick={e => this.onCheckout(e, stripe.premium_month, SubscriptionLevels.PREMIUM)}
                                                        className={`btn ${this.state.premium && this.state.premium_level >= SubscriptionLevels.PREMIUM && !this.state.premiumUntil ? 'btn-primary-blue' : 'btn-dark'} pricing-card__select-btn`}>{this.state.premium && this.state.premium_level === SubscriptionLevels.PREMIUM && !this.state.premiumUntil ? 'Current Plan' : this.state.free_trial ? 'Upgrade' : this.state.premium_level > SubscriptionLevels.PREMIUM ? 'Downgrade' : 'Upgrade'}</button> : null
                                                    :
                                                    this.state.header.status === '1' ? <button
                                                        onClick={e => this.onCheckout(e, stripe.premium_annual, SubscriptionLevels.PREMIUM)}
                                                        className={`btn ${this.state.premium && this.state.premium_level >= SubscriptionLevels.PREMIUM && !this.state.premiumUntil ? 'btn-primary-blue' : 'btn-dark'} pricing-card__select-btn`}>{this.state.premium && this.state.premium_level === SubscriptionLevels.PREMIUM && !this.state.premiumUntil ? 'Current Plan' : this.state.free_trial ? 'Upgrade' : this.state.premium_level > SubscriptionLevels.PREMIUM ? 'Downgrade' : 'Upgrade'}</button> : null}
                                            </div>

                                            {this.state.header.status === '0' &&
                                                <button className={`btn disabled`}>Disabled</button>}

                                            {this.state.premium && this.state.premium_level >= SubscriptionLevels.PREMIUM &&
                                                <div className="text-center mt-2 d-none d-xl-block">
                                                    <button style={{fontSize: 14}}
                                                            className="btn btn-unstyled pricing-card__cancel-btn"
                                                            onClick={e => this.onCancel(e)}>Cancel Subscription
                                                    </button>
                                                </div>}


                                        </div>


                                    </div>


                                </div>}


                                <div className="row pb-2" style={{justifyContent: 'center'}}>

                                    {/*<FreePlan userType={this.state.header.user_type_id} premium={this.state.premium}/>*/}
                                    <PremiumPlan
                                        userType={this.state.header.user_type_id}
                                        annual={this.state.checked}
                                        premium={this.state.premium}
                                        premiumLevel={this.state.premium_level}
                                        premiumUntil={this.state.premiumUntil}
                                        userStatus={this.state.header.status}
                                        freeTrial={this.state.free_trial}
                                        onCheckout={this.onCheckout}
                                        onCancel={this.onCancel}
                                    />
                                    <PartnerOption
                                        userType={this.state.header.user_type_id}
                                        annual={this.state.checked}
                                        premium={this.state.premium}
                                        premiumLevel={this.state.premium_level}
                                        premiumUntil={this.state.premiumUntil}
                                        userStatus={this.state.header.status}
                                        freeTrial={this.state.free_trial}
                                    />
                                    <ConciergeOption
                                        userType={this.state.header.user_type_id}
                                        annual={this.state.checked}
                                        premium={this.state.premium}
                                        premiumLevel={this.state.premium_level}
                                        premiumUntil={this.state.premiumUntil}
                                        userStatus={this.state.header.status}
                                        freeTrial={this.state.free_trial}
                                    />

                                </div>

                                <div style={{textAlign: 'center', fontSize: 14, paddingTop: 10}}>
                                    <div style={{display: 'inline-block', textAlign: 'left'}}>
                                        <p style={{margin: 0}}><sup>1</sup> - Owner-Posted Deals must be
                                            reviewed & approved to go live on the BizNexus platform</p>
                                        <p style={{margin: 0}}><sup>2</sup> - Access premium acquisition & deal
                                            origination accelerator workshops</p>
                                    </div>
                                </div>
                        </div>
                    </section>
                    <FAQ userType={this.state.header.user_type_id}/>
                </div>

                <Modal size={'lg'} isOpen={this.state.invoiceModal}
                       toggle={() => this.setState({invoiceModal: !this.state.invoiceModal})}
                       className="modal-dialog modal-featured" keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({invoiceModal: !this.state.invoiceModal})}>
                        Upcoming Invoice
                    </ModalHeader>
                    <ModalBody>
                        <div className="container">
                            <p>{this.state.upcoming.subtotal / 100 >  0
                            ? `You will be charge for $${this.state.upcoming.subtotal / 100} on ${moment.unix(this.state.upcoming.next_payment_attempt).format('LL')}`
                            : `You will have a credit of $${Math.abs(this.state.upcoming.subtotal / 100)} starting on ${moment.unix(this.state.upcoming.next_payment_attempt).format('LL')}`}</p>


                            <ListGroup>
                                <ListGroupItem style={{fontWeight: '800', cursor: 'pointer'}}
                                               onClick={() => this.setState({qUpgrade: !this.state.qUpgrade})}>How do
                                    prorated charges work?</ListGroupItem>
                            </ListGroup>
                            <Collapse isOpen={this.state.qUpgrade}>
                                <Card>
                                    <CardBody style={{margin: '25px'}}>
                                        <b>Proration 101</b> <br/>

                                        When you upgrade (or downgrade) your subscription plan you will be charged (or
                                        credited) a prorated amount.
                                        <br/><br/>
                                        For example, let's say your billing starts on January 1st, and on January 15th
                                        you upgrade from a Premium subscription to the Pro subscription plan. On January
                                        1st you were charged $99 which pays for the full month of January. January 15th
                                        is 50% of the way through the month and that's when you upgraded to Pro. A few
                                        things happen here:
                                        <br/><br/>
                                        You will be credited $49.50 since the Premium plan is no longer in use for the
                                        remaining 50% of the month.
                                        <br/><br/>
                                        You will be charged $125.00 to pay for your new Pro plan from April 15th to
                                        April 30th (normally $250.00 for the full month).
                                        <br/><br/>
                                        On February 1st, you will be charged $250 (monthly subscription price for the
                                        Pro plan) for the entire upcoming month of February.
                                        <br/><br/>
                                        You will have a chance to preview these charges before completing your upgrade
                                        and when you do, the one-time charge for the current month will be collected
                                        immediately, and you will be enrolled into your new plan going forward.

                                        <br/><br/>
                                        <b>Prorating downgrades</b>
                                        <br/>
                                        When you downgrade your subscription, you may end up with a credit on your
                                        account.
                                        <br/><br/>
                                        Say you paid for Pro and then downgraded to Premium through the month. For our
                                        example, we'll say this prorating event gives you a credit of $99 on your
                                        account. You will be refunded and account credit for this amount, and future
                                        BizNexus charges will first draw down from this credit balance before charging
                                        your account again.

                                    </CardBody>
                                </Card>
                            </Collapse>
                        </div>
                        <ModalFooter style={{paddingTop: '15px', borderTop: 'none'}}>
                            <Button color="success" onClick={() => this.onUpgrade()}>Ok, I Agree</Button>
                            <Button color="error" onClick={() => this.setState({invoiceModal: false})}>Cancel</Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.freeModal} toggle={() => this.setState({freeModal: !this.state.freeModal})}
                       className="modal-dialog modal-featured" keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({freeModal: !this.state.freeModal})}>
                        Start your free trial
                    </ModalHeader>
                    <ModalBody>
                        <div className="container">
                            Click below to start your unlimited deal access for the next 30 days and be sure to ping us
                            with any questions!
                        </div>
                        <ModalFooter style={{paddingTop: '15px', borderTop: 'none'}}>
                            <Button color="success" onClick={() => this.onStartFreeTrial()}>Start Free Trial</Button>
                            <Button color="error" onClick={() => this.setState({freeModal: false})}>Cancel</Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.eventModal} toggle={() => this.setState({eventModal: !this.state.eventModal})}
                       className="modal-dialog modal-featured modal-dialog-centered" keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({eventModal: !this.state.eventModal})}>

                    </ModalHeader>
                    <ModalBody>
                        <div className="container text-center" style={{position: 'relative', top: 18}}>
                            <h1 className="h1">{this.state.eventHeader}</h1>
                            <div style={{fontSize: 18}}>{this.state.eventMessage}</div>
                        </div>
                        <ModalFooter style={{paddingTop: '15px', borderTop: 'none'}}>
                            <Button color="primary" onClick={() => this.setState({eventModal: false})}>Close</Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>

            </div>
        )
    }
}

const PremiumContainer = (props) => (
    <Elements stripe={stripePromise}>
        <ElementsConsumer>
            {({stripe, elements}) => (
                <Premium {...props} stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    </Elements>
);

export default PremiumContainer;
