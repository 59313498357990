export function uploadFile(dataurl, filename, type, endpoint) {
  // hack for now until we use proper files
  // adapted from https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
  return fetch(dataurl)
    .then(r => r.blob())
    .then(blob => new File([blob], filename, { type: type }))
    .then(file => {
      const data = new FormData();
      data.append("file", file)

      return fetch(process.env.REACT_APP_API_ROOT + endpoint, {
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
          "Access-Control-Allow-Origin": "*",
          'x-access-token': localStorage.getItem('token'),
      }})
      .then(res => {
        if (res.status < 200 || res.status >= 300) {
          res.json().then(r => {throw r})
        } 
        else { 
          return res.json();
        }
      })
    })
}