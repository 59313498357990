import React from "react";
import {Col, Modal, ModalBody, Row} from "reactstrap";

const PremiumModal = ({ isOpen, toggle }) => {
    return <Modal style={{width: 400}} className="modal-dialog-centered delete-item" isOpen={isOpen}
                  toggle={toggle}>
        <ModalBody>
            <div style={{textAlign: 'center'}}>
                <h1 className="h1 mb-4">Premium Access Required</h1>
            </div>
            <Row>
                <Col>
                    <div className="mb-4" style={{textAlign: 'center'}}>
                        <a href={'/user/subscription'} className="btn btn-primary-blue w-100">
                            <i style={{marginRight: '4px'}} className="fa fa-lock" aria-hidden="true"/>
                            Please Upgrade To Continue
                        </a>
                    </div>
                </Col>
            </Row>
        </ModalBody>
    </Modal>
}

export default PremiumModal
