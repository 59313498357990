import React from 'react';
import {getImageUrl} from '../../components/util';

export default class Ads extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ad: {},
        };
    }

    componentDidMount() {
        this.fetchAd();
    }

    async fetchAd() {
        await fetch(process.env.REACT_APP_API_ROOT + 'ads/ad/' + this.props.planId + '?url=' + window.location.href, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'content-type': 'application/json',
            },
            }).then(function (res) {
            if (res.status < 200 || res.status >= 300) {
                
                //console.log(res);
                //return res.json().then(err => {throw err;});
                throw res;
                //throw Error(.statusText);
            } else { 
                return res.json();
            }
        
        }).then(res => {
            this.setState({ ad: res });
        });
    }

    render() {

        if(this.state.ad.type === 'text') {
            return (
                <p>
                    <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_ROOT + 'ads/click/' + this.state.ad.id}>
                        {this.state.ad.text}
                    </a>
                </p>
            )
        } else {
            return (
                <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_ROOT + 'ads/click/' + this.state.ad.id}>
                    <img style={{ boxShadow: '1px 1px 15px #c2c2c2' }} alt="" width="370" height="350" src={getImageUrl(this.state.ad.image)} />
                </a>
            )
        }
    }
}
