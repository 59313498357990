import React from "react";
import PropTypes from "prop-types";
import {SubscriptionLevels} from "../../../../../constants/subscriptionLevels";
import AvailableOption from "./AvailableOption";
import {UncontrolledTooltip} from "reactstrap";

const PremiumPlan = ({
    annual,
    premium,
    premiumLevel,
    premiumUntil,
    userStatus,
 }) => {

    return (

        <div className="col-md-4 col-sm-12" style={{maxWidth: 375, marginBottom: 32}}>
            <div className="pricing-card">
                <div className="pricing-card-head">
                    <h2 className="pricing-card__title">Premium</h2>
                    <p className="pricing-card__price" style={{fontSize: 20, lineHeight: '22px', padding: '16px 0'}}>
                        Choose A Plan
                    </p>
                    <p className="pricing-card__annual">
                        Access In Community
                        <i style={{ margin: '0px 3px 3px' }} id="premium-tooltip" className="sprite i-question"/>
                        <UncontrolledTooltip placement="auto"
                                             target={`premium-tooltip`}>
                            Marketplace access is included through multiple membership plans available in our community. We offer paid access OR success fee-only access.
                        </UncontrolledTooltip>
                    </p>
                    <div className="content-divider" style={{marginTop: !annual ? 42 : 0}}/>
                </div>
                {/* <h3 class="pricing-card__title-sm">Everything in Starter, plus:</h3> */}
                <ul className="pricing-card__features">
                    <AvailableOption>Marketplace access</AvailableOption>
                    <AvailableOption>View ALL deals & broker contact info</AvailableOption>
                    <AvailableOption>Connect directly to your CRM</AvailableOption>
                    <AvailableOption>Post your own sell-side opportunity <sup>1</sup></AvailableOption>
                    <AvailableOption>Get matched with M&A Advisors</AvailableOption>
                    <AvailableOption>Advanced search filters</AvailableOption>
                    <AvailableOption>Unlimited saved searches & favorites</AvailableOption>
                    <AvailableOption>Organize mandates by target folder</AvailableOption>
                    <AvailableOption>
                        <strong>
                            <span style={{color: 'red', fontWeight: 600}}>NEW: </span>
                            Premium Community Access <sup>2</sup>
                        </strong>
                    </AvailableOption>

                </ul>

                <div className="position-relative text-center mt-auto">

                    {/*{userStatus === '1'*/}
                    {/*    && <button*/}
                    {/*    onClick={e => onCheckout(e, annual ? stripe.premium_annual : stripe.premium_month, SubscriptionLevels.PREMIUM)}*/}
                    {/*    className={`${premium && premiumLevel >= SubscriptionLevels.PREMIUM && !premiumUntil ? 'btn-premium-no-action' : ''} btn ${premium && premiumLevel >= SubscriptionLevels.PREMIUM && !premiumUntil*/}
                    {/*        ? 'btn-primary-blue'*/}
                    {/*        : 'btn-dark'} pricing-card__select-btn`}*/}
                    {/*>*/}
                    {/*    {premium && premiumLevel >= SubscriptionLevels.PREMIUM && !premiumUntil*/}
                    {/*        ? 'Current Plan'*/}
                    {/*        : freeTrial*/}
                    {/*            ? 'Upgrade'*/}
                    {/*            : premiumLevel > SubscriptionLevels.PREMIUM*/}
                    {/*                ? 'Downgrade'*/}
                    {/*                : 'Upgrade'}*/}
                    {/*</button>}*/}

                    {userStatus === '1'
                        && <a
                            href="https://www.biznexus.com/pricing"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`btn ${premium && premiumLevel >= SubscriptionLevels.CONCIERGE && !premiumUntil
                                ? 'btn-primary-blue'
                                : 'btn-success'} pricing-card__select-btn`}
                        >
                            Get Access Now
                        </a>}

                </div>

                {userStatus === '0' &&
                    <button className={`btn disabled`}>Disabled</button>}

                {/*{premium && premiumLevel >= SubscriptionLevels.PREMIUM &&*/}
                {/*    <div className="text-center mt-2 d-none d-xl-block">*/}
                {/*        <button style={{fontSize: 14}}*/}
                {/*                className="btn btn-unstyled pricing-card__cancel-btn"*/}
                {/*                onClick={e => onCancel(e)}>Cancel Subscription*/}
                {/*        </button>*/}
                {/*    </div>}*/}


            </div>
        </div>
    )
}

PremiumPlan.propTypes = {
    premium: PropTypes.bool,
    annual: PropTypes.bool,
    premiumLevel: PropTypes.number,
    userStatus: PropTypes.number,
}

export default PremiumPlan
