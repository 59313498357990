import { Bars } from "react-loader-spinner";
import React from 'react';
import Select from 'react-select';
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    InputGroup,
    Modal, ModalBody, ModalHeader, ModalFooter, Table, FormText
} from 'reactstrap';
import queryString from 'query-string'
import _ from 'lodash';
import Header from '../components/Header';
import {getImageUrl, trackEvent} from '../components/util';
import {connect} from 'react-redux';
import * as actions from '../storage/actions';
import FeaturedListing from '../components/SearchResult/FeaturedListing';
import ListItem from '../components/SearchResult/ListItem';
import { USER_TYPE_LABEL } from '../constants/userTypes';
import Footer from '../components/Footer';
import api from '../services/api';
import AdvancedConsole from '../components/cards/advanced-console';
import { toast } from 'react-toastify';
import InfiniteScroll from "react-infinite-scroll-component";
import ScrollButton from "../components/ScrollButton";
import {Helmet} from "react-helmet";
import {addOnResize, MOBILE_WIDTH, onResize, removeOnResize, SMALL_WIDTH} from "../services/resize";
import SearchLoader from "../components/SearchLoader/SearchLoader";
import AdDisplayer from "../components/AdDisplayer";
import stripeEnv from "../constants/stripe";
import {withRouter} from "./withRouter/withRouter";

// let categoryRemoveClicked = false;

function setWithExpiry(key, value, ttl) {
    const now = new Date()
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

class AdvancedSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newProjectError: '',
            searchLoader: false,
            fields: [],
            errors: {},
            message: '',
            gridview: '12',
            categoryOption: [],
            categorylist: [],
            dateaddedOption: '',
            shortbyOption: '',
            location: '',
            Lat: '',
            Lng: '',
            Searchcollapse: true,
            AdvanceSearchToggle: 'none',
            minpricerangeselected: '',
            maxpricerangeselected: '',
            mincashflowselected: '',
            maxcashflowselected: '',
            minrevenueselected: '',
            maxrevenueselected: '', 
            minebitdaselected: '',
            maxebitdaselected: '',
            minebitdamultipleselected: '',
            maxebitdamultipleselected: '',
            minrevenuemultipleselected: '',
            maxrevenuemultipleselected: '',
            mincashflowmultipleselected: '',
            maxcashflowmultipleselected: '',
            brokername: '',
            brokerprofile_pic: '',
            brokerslug_url: '',
            brokerfirm_name: '',
            brokerptype: '',
            brokerarea_served: '',
            brokerreviews: '',
            brokerratings: '',
            brokertranscount: '',
            brokerlistcount: '',
            orderby: 'listing_date',
            SearchResultList: [],
            franchiseRecordListing: [],
            totalrecords: '',
            activePage: 1,
            activePageInfinity: 1,
            staticNumberOfRow: 25,
            showingData: '',
            showSavedSearch: false,
            savedSearch: null,
            modal: false,
            savedSearchName: '',
            featured_profile: null,
            premium: false,
            selectedProject: null,
            guideModalOpen: false,
            saveThisSearchModal: false,
            offMarket: 'yes',
            offMarketBlock: false,

            premiumModal: false,
            dropdownRevenueMultiple: false,
            dropdownCashflowMultiple: false,
            dropdownEBITDAMultiple: false,

            dropdownCategory: false,
            selectparentlabel: 0,

            newProjectModal: false,
            hasSearch: false,
            projectName: '',
            locationType: '',
            assignListingModal: false,
            assignListingTo: '',
            savedSearchProjects: [],
            listingToFavorite: {},
            savedListings: [],

            brokerIndustry: [],
            city: '',
            state: '',
            country: '',

            viewMode: localStorage.getItem('viewMode') || 'table',
            listReady: false,
            sort: 'listing_date',
            sortDirectionDesc: true,
            sortLoading: false,
            mouseStatusSort: '',
            showBanner: false,
            locations: [],
            user_type_id: null,
            pageTitle: 'BizNexus',

        };
        this.Searchtoggle = this.Searchtoggle.bind(this);
        this.SubmitSearchResult = this.SubmitSearchResult.bind(this);
        this.clearSavedSearch = this.clearSavedSearch.bind(this);

    }

    createMarkup(data) {
        return { __html: data };
    }

    Searchtoggle(e) {
        e.preventDefault();
        this.setState({ Searchcollapse: !this.state.Searchcollapse });
        if (this.state.AdvanceSearchToggle === '') {
            this.setState({ AdvanceSearchToggle: 'none' });
        } else {
            this.setState({ AdvanceSearchToggle: '' });
        }
    }

    getlistinglist = async () => {
        await fetch(process.env.REACT_APP_API_ROOT + 'franchise/', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        }).then((res) => {
            if (res.status < 200 || res.status >= 300) {
    
                if (res.status === 401) {
                    this.props.history.push('/');
                } else {
                    return res.json().then(err => { throw err; });
                }
            } else { return res.json() }
        })
            .then(
                (res) => {
                         
                    this.setState({ franchiseRecordListing:res });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        //error
                    });
                }
            )
    } 

    async fetchSavedSearch() {

        if(!localStorage.getItem('token')) {
            return;
        }

        return await api.get('user/saved_search', null, true)
        .then(res => {
            this.setState({ 
                savedSearchProjects: res.savedSearchProjects.map(p => {
                    return {
                        label: p.name,
                        value: p.id
                    }
                }),
                savedSearchList: res.savedSearchAll.map(p => {
                    return {
                        label: p.name,
                        value: p,
                        id: p.id
                    }
                }),
                savedListings: res.savedListings.map(l => l.transaction_id)
            });
           
        });
    }

    async fetchPremium() {

        if(!localStorage.getItem('token')) {
            return;
        }

        return await fetch(process.env.REACT_APP_API_ROOT + 'user/header', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(res => res.json()).then(res => {
            this.setState({ 
                premium: res.premium,
                user_type_id: res.user_type_id,
            });
        });
    }

    isToUpgrade() {
        return !this.state.premium && localStorage.getItem('token');
    }
    resizeCallback = width => {
        const isSmallScreen = width < SMALL_WIDTH
        this.setState({
            isSmallScreen,
            isMobileScreen: width < MOBILE_WIDTH,
            viewMode: isSmallScreen ? 'list' : this.state.viewMode
        })
    }

    componentDidMount() {
        onResize(window, this.resizeCallback)()
        addOnResize(window, this.resizeCallback)
        
        fetch(process.env.REACT_APP_API_ROOT + 'industry/legacy', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => res.json())
            .then(
                (res) => {
                    let categorylistarray = [];
                    let temp = {};
                    
                    // eslint-disable-next-line
                    res.map((categories, i) => {
                        temp = {
                            "value": categories.value,
                            "label": categories.label,
                            "children": categories.children
                        };
                        categorylistarray.push(temp);
                    });
                    /*
                    let temp1 = {
                        "value": "Franchise Opportunities",
                        "label": "Franchise Opportunities"
                    }
                    categorylistarray.push(temp1);*/
                    if(localStorage.getItem('token')) {
                        this.fetchSavedSearch()
                        .then(() => {
                            this.setState({ categorylist: categorylistarray }, () => { this.parseUrlToSearch();});
                        });
                    } else {
                        this.setState({ categorylist: categorylistarray }, () => { this.parseUrlToSearch();});
                    }
                    

                    
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        //error
                    });
                }
            );
        this.gettopbrockerlist();
        
        this.init();
        
    }

    init = async () => {
        await this.fetchPremium();

        await api.get('ads/ad/' + stripeEnv.ad_search_first + '?url=' + window.location.href, null, true)
            .then(res => {
                this.setState({ ad1: res });
            });
        await api.get('ads/ad/' + stripeEnv.ad_search_second + '?url=' + window.location.href, null, true)
            .then(res => {
                this.setState({ ad2: res });
            });
        window.onpopstate = () => {
            this.parseUrlToSearch(true);
        }
    }

    componentWillUnmount() {
        window.onpopstate = null;
        removeOnResize(window)
    }

    parseUrlToSearch = async (isFromPopstate = false, searchParams = '') => {
        const allIndustries = [];
        const queryParams = queryString.parse(searchParams || this.props.location.search);
        this.state.categorylist.forEach(ind => {
            allIndustries.push(ind);
            if(ind.children && ind.children.length) {
                ind.children.forEach(ci => allIndustries.push(ci));
            }
        });
        const categoryOption = queryParams.industry_id
          ? allIndustries.filter(item => {
              const query = queryParams.industry_id.split(',');
              return query.includes(item.value.toString()) 
          })
          : []; 

        this.setState({
            location: queryParams.location,
            locations: queryParams.locations? JSON.parse(queryParams.locations) : [],
            city: queryParams.city,
            state: queryParams.state,
            country: queryParams.country,
            locationType: queryParams.locationType,
            categoryOption,
            activePage: queryParams.page || 1,
            Lat: queryParams.from_latitude,
            Lng: queryParams.from_longitude,
            dateaddedOption: queryParams.date_added || '',
            offMarket: queryParams.offMarket ? queryParams.offMarket : 'yes',
            offMarketBlock: queryParams.offMarket && queryParams.offMarket === 'yes' ? true : false,
            fields: {
                minpricerange: queryParams.price_min,
                maxpricerange: queryParams.price_max,
                mincashflow: queryParams.cashflow_min,
                maxcashflow: queryParams.cashflow_max,
                minrevenue: queryParams.revenue_min,
                maxrevenue: queryParams.revenue_max,
                minebitda: queryParams.ebitda_min,
                maxebitda: queryParams.ebitda_max,

                minebitda_multiple: queryParams.minebitda_multiple,
                maxebitda_multiple: queryParams.maxebitda_multiple,
                minrevenue_multiple: queryParams.minrevenue_multiple,
                maxrevenue_multiple: queryParams.maxrevenue_multiple,
                mincashflow_multiple: queryParams.mincashflow_multiple,
                maxcashflow_multiple: queryParams.maxcashflow_multiple,

                searchbox: queryParams.name,
                excluded: queryParams.excluded,
                radius: queryParams.radius,
                asset_sale: queryParams.asset_sale ? true : false,
            }
        }, () => {
            if(queryParams.search_id) {
                const savedSearch = this.state.savedSearchList ? this.state.savedSearchList.find(l => l.id === parseInt(queryParams.search_id)) : null;
                if(savedSearch) {
                    const data = JSON.parse(savedSearch.value.data);

                    this.setState({ savedSearch,
                        Lat: data.from_latitude,
                        Lng: data.from_longitude,
                        fields: data.fields,
                        location: data.location,
                        categoryOption: data.categoryOption,
                        locationType: data.locationType,
                        city: data.city,
                        state: data.state,
                        country: data.country,
                        locations: data.locations || [],
                    }, () => {
                        this.getSearchResultlist({
                            orderby: this.state.orderby,
                            page: this.state.premium ? this.state.activePage : 1,
                        }, isFromPopstate);
                    });
                }

            } else {
                if(queryParams.selected_search) {
                    const savedSearch = this.state.savedSearchList.find(l => l.id === parseInt(queryParams.selected_search));
                    this.setState({ savedSearch });
                }

                this.getSearchResultlist({
                    orderby: this.state.orderby,
                    page: this.state.premium ? this.state.activePage : 1,
                }, isFromPopstate);
            }
        });

        
    };


    gettopbrockerlist = () => {
        fetch(process.env.REACT_APP_API_ROOT + 'profile/featured_profile', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(function (res) {
                if (res.status < 200 || res.status >= 300) {

                    //return res.json().then(err => {throw err;});
                    throw res;
                    //throw Error(.statusText);
                } else { return res.json() }

            })
            .then((res) => {
               
                if (res.length > 0) {
                    let firstname = '';
                    let fullname = res[0].name.match(/(\w+)(?:\s[^\s]+)?\s(\w+).*/);
                    if (fullname !== null) {
                        firstname = fullname[1] + " " + fullname[2].charAt(0) + ".";
                    }
                    else {
                        firstname = res[0].name;
                    }
                    this.setState({ brokername: firstname, brokerprofile_pic: res[0].profile_pic, brokerslug_url: res[0].slug_url, brokerfirm_name: res[0].firm_name, brokerptype: res[0].ptype, brokerarea_served: res[0].area_served, brokerreviews: res[0].reviews, brokerratings: res[0].ratings, brokertranscount: res[0].transcount, brokerlistcount: res[0].listcount, });
                }
            })
            .catch((err) => {
                this.setState({
                    //error
                });
            })
    }
    handleSearchResultValidation() {
        // let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleGeosuggestChange = (location) => {
        if (location !== undefined) {
            let addressComponent = location.gmaps.address_components;
            let locationType = '';
            switch(addressComponent[0].types[0]) {
                case 'locality': locationType = 'city'; break;
                case 'administrative_area_level_1': locationType = 'state'; break;
                case 'country': locationType = 'country'; break;
                default: locationType = 'point';
            }
            for (var j = 0; j < location.gmaps.address_components.length; j++) {
                if (addressComponent[j].types[0] === 'locality') {
                    this.setState({ city: addressComponent[j].long_name });
                }
                if (addressComponent[j].types[0] === 'administrative_area_level_1') {
                    this.setState({ state: addressComponent[j].short_name });
                }
                if (addressComponent[j].types[0] === 'country') {
                    this.setState({ country: addressComponent[j].short_name });
                }
            }
            
            this.setState({ Lat: location.location.lat });
            this.setState({ Lng: location.location.lng });
            this.setState({ location: location.label, locationType });
        }
    }
    handlecategoryChange = (categoryOption) => {
        
        this.setState({ categoryOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (categoryOption) {
     
        }
    }
    handledateaddedChange = (dateaddedOption) => {
        this.setState({ dateaddedOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (dateaddedOption) {
        
        }
    }
    handleChangeGridview = () => {
        this.setState({ gridview: "3" });
    }
    handleChangeListview = () => {
        this.setState({ gridview: "12" });
    }

    handleChangeSearch(field, e) {
        if (e.target !== '' && e.target !== undefined) {
            let fields = this.state.fields;
            fields[field] = e.target.value;
            
            if (e.target.name === 'minpricerange') {
                let minpricerange = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxpricerange') {
                let maxpricerange = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'mincashflow') {
                let mincashflow = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = mincashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxcashflow') {
                let maxcashflow = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxcashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'minrevenue') {
                let minrevenue = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxrevenue') {
                let maxrevenue = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'minebitda') {
                let minebitda = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minebitda.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxebitda') {
                let maxebitda = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxebitda.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'asset_sale') {
                fields[field] = e.target.checked;
            }
            if (e.target.name === 'minebitda_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');  
            }
            if (e.target.name === 'maxebitda_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');  
            }
            if (e.target.name === 'minrevenue_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');  
            }
            if (e.target.name === 'maxrevenue_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');  
            }
            if (e.target.name === 'mincashflow_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');  
            }
            if (e.target.name === 'maxcashflow_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');  
            }
            this.setState({ fields });
        }
    }
    handleChangeMinPriceRange = (e) => {
        let fields = this.state.fields;
        fields["minpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, minpricerangeselected: e.target.value });
    }
    handleChangeMaxPriceRange = (e) => {
        let fields = this.state.fields;
        fields["maxpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, maxpricerangeselected: e.target.value });
    }
    handleChangeMincashflow = (e) => {
        let fields = this.state.fields;
        fields["mincashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, mincashflowselected: e.target.value });
    }
    handleChangeMaxcashflow = (e) => {
        let fields = this.state.fields;
        fields["maxcashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, maxcashflowselected: e.target.value });
    }
    handleChangeMinRevenue = (e) => {
        let fields = this.state.fields;
        fields["minrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, minrevenueselected: e.target.value });
    }
    handleChangeMaxRevenue = (e) => {
        let fields = this.state.fields;
        fields["maxrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, maxrevenueselected: e.target.value });
    }
    handleChangeMinEbitda = (e) => {
        let fields = this.state.fields;
        fields["minebitda"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, minebitdaselected: e.target.value });
    }
    //
    handleChangeMinEbitdaMultiple = (e) => {
        let fields = this.state.fields;
        fields["minebitda_multiple"] = e.target.value;
        this.setState({ fields, minebitdamultipleselected: e.target.value });
    }
    handleChangeMaxEbitdaMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxebitda_multiple"] = e.target.value;
        this.setState({ fields, maxebitdamultipleselected: e.target.value });
    }

    handleChangeMinRevenueMultiple = (e) => {
        let fields = this.state.fields;
        fields["minrevenue_multiple"] = e.target.value;
        this.setState({ fields, minrevenuemultipleselected: e.target.value });
    }
    handleChangeMaxRevenueMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxrevenue_multiple"] = e.target.value;
        this.setState({ fields, maxrevenuemultipleselected: e.target.value });
    }

    handleChangeMinCashflowMultiple = (e) => {
        let fields = this.state.fields;
        fields["mincashflow_multiple"] = e.target.value;
        this.setState({ fields, mincashflowmultipleselected: e.target.value });
    }
    handleChangeMaxCashflowMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxcashflow_multiple"] = e.target.value;
        this.setState({ fields, maxcashflowmultipleselected: e.target.value });
    }
    //
    
    handleChangeMaxEbitda = (e) => {
        let fields = this.state.fields;
        fields["maxebitda"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, maxebitdaselected: e.target.value });
    }
    SubmitSearchResult = (e) => {
        e && e.preventDefault();
        if (this.handleSearchResultValidation()) {
            //let fields = this.state.fields;
            this.getSearchResultlist({
                orderby: this.state.orderby,
                page: this.state.activePage,
                save: true,
            });

        } else {
         
        }
    }
    handleshortbyChange = (shortbyOption) => {
        this.setState({ shortbyOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (shortbyOption) {
            this.setState({ orderby: shortbyOption.value });
            this.getSearchResultlist({
                orderby: shortbyOption.value,
                page: 1
            });
      
        }
    }
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
        this.getSearchResultlist({
            orderby: this.state.orderby,
            page: pageNumber,
        });
    }
    handleChangeClear = async (e) => {
        e.preventDefault();
        let fields = this.state.fields;
        // eslint-disable-next-line
        fields["searchbox"] = '', fields["minpricerange"] = '', fields["maxpricerange"] = '', fields["mincashflow"] = '', fields["maxcashflow"] = '', fields["minrevenue"] = '', fields["maxrevenue"] = '', fields["radius"] = '', fields["minebitda"] = '', fields["maxebitda"] = '', fields['asset_sale'] = false;
        fields['minebitda_multiple'] = '';
        fields['maxebitda_multiple'] = '';
        fields['minrevenue_multiple'] = '';
        fields['maxrevenue_multiple'] = '';
        fields['mincashflow_multiple'] = '';
        fields['maxcashflow_multiple'] = '';
        
        const ClearResult = () => {
            this.refs.location.update('');
            this.setState({ savedSearch:  null, categoryOption: '', dateaddedOption: '', minpricerangeselected: '', maxpricerangeselected: '', mincashflowselected: '', maxcashflowselected: '', minrevenueselected: '', maxrevenueselected: '', location: '', minebitdaselected: '', maxebitdaselected: '', 
            minebitdamultipleselected: '',
            maxebitdamultipleselected: '',
            minrevenuemultipleselected: '',
            maxrevenuemultipleselected: '',
            mincashflowmultipleselected: '',
            maxcashflowmultipleselected: '',
        });
            var ip = localStorage.getItem("ip");
            // localStorage.clear();
            localStorage.setItem('ip', ip);
        };
        await ClearResult();
        
        this.getSearchResultlist({
            orderby: this.state.orderby,
            page: 1,
        });
    }
    getSearchResultlist = ({ orderby, page, save }, isFromPopstate = false, infinity = false) => {
        
        // document.getElementById('loader').setAttribute("style", "display: block;");
        if(!infinity) {
            this.setState({ activePage: page });
        }
        let location, latitude, longitude, minpricerangeselected, maxpricerangeselected, mincashflowselected, maxcashflowselected, minrevenueselected, maxrevenueselected, dateaddedOption, minebitdaselected, maxebitdaselected = '';
        let fields = this.state.fields;
        if (this.state.location !== '' && this.state.location !== undefined) {
            location = this.state.location;
            latitude = this.state.Lat;
            longitude = this.state.Lng;
        }
        else {
            if (localStorage.getItem("buyloaction") !== null && localStorage.getItem("buyloaction") !== "undefined") {
                location = localStorage.getItem("buyloaction");
                latitude = localStorage.getItem("buylatitude");
                longitude = localStorage.getItem("buylongitude");
            }
            else {
                location = "";
                latitude = ""
                longitude = "";
            }
        }

        if (this.state.dateaddedOption.value !== undefined) {
            dateaddedOption = this.state.dateaddedOption.value;
        }
        else {
            dateaddedOption = this.state.dateaddedOption;
        }
        if (fields["minpricerange"] !== undefined) {
            if (this.state.minpricerangeselected === "Any Min") {
                minpricerangeselected = '';
            }
            else {
                minpricerangeselected = fields["minpricerange"].replace(/,/g, "");
            }
        }
        if (fields["maxpricerange"] !== undefined) {
            if (this.state.maxpricerangeselected === "Any Max") {
                maxpricerangeselected = '';
            }
            else {
                maxpricerangeselected = fields["maxpricerange"].replace(/,/g, "");
            }
        }
        if (fields["mincashflow"] !== undefined) {
            if (this.state.mincashflowselected === "Any Min") {
                mincashflowselected = '';
            }
            else {
                mincashflowselected = fields["mincashflow"].replace(/,/g, "");
            }
        }
        if (fields["maxcashflow"] !== undefined) {
            if (this.state.maxcashflowselected === "Any Max") {
                maxcashflowselected = '';
            }
            else {
                maxcashflowselected = fields["maxcashflow"].replace(/,/g, "");
            }
        }
        if (fields["minrevenue"] !== undefined) {
            if (this.state.minrevenueselected === "Any Min") {
                minrevenueselected = '';
            }
            else {
                minrevenueselected = fields["minrevenue"].replace(/,/g, "");
            }
        }
        if (fields["maxrevenue"] !== undefined) {
            if (this.state.maxrevenueselected === "Any Max") {
                maxrevenueselected = '';
            }
            else {
                maxrevenueselected = fields["maxrevenue"].replace(/,/g, "");
            }
        }
        if (fields["minebitda"] !== undefined) {
            if (this.state.minebitdaselected === "Any Min") {
                minebitdaselected = '';
            }
            else {
                minebitdaselected = fields["minebitda"].replace(/,/g, "");
            }
        }
        if (fields["maxebitda"] !== undefined) {
            if (this.state.maxebitdaselected === "Any Max") {
                maxebitdaselected = '';
            }
            else {
                maxebitdaselected = fields["maxebitda"].replace(/,/g, "");
            }
        }


        const categoryOption = this.state.categoryOption.map(bi => bi.children && bi.children.length ? [bi.value].concat(bi.children.map(cc => cc.value)) : bi.value);
        
        
        const dataForQuery = {
          "location": location,
          "industry_id": categoryOption.length ? categoryOption : null,
          "from_latitude": latitude,
          "from_longitude": longitude,
          "name": fields["searchbox"],
          excluded: fields["excluded"],
          "price_min": minpricerangeselected,
          "price_max": maxpricerangeselected,
          "cashflow_min": mincashflowselected,
          "cashflow_max": maxcashflowselected,
          "revenue_min": minrevenueselected,
          "revenue_max": maxrevenueselected,
          "ebitda_min": minebitdaselected,
          "ebitda_max": maxebitdaselected,
          "date_added": dateaddedOption === "" ? null : dateaddedOption,
          "orderby": orderby,
          "page": page,
          "radius": fields.radius,
          "rows": this.state.staticNumberOfRow,
          city: this.state.city,
          state: this.state.state,
          country: this.state.country,
          locationType: this.state.locationType,
          locations: this.state.locations && this.state.locations.length ? JSON.stringify(this.state.locations) : null,
          sortKey: this.state.sort,
          sortDirectionDesc: this.state.sortDirectionDesc,
        };

        if(this.state.locations && this.state.locations.length === 1) {
            // this.setState({ pageTitle: `Businesses for sale in ${this.state.locations[0].city}, ${this.state.locations[0].state}` });
        }

        if(fields['asset_sale']) {
            dataForQuery.asset_sale = '1';
        }

        if(fields['minebitda_multiple'] && fields['minebitda_multiple'] !== 'Any Max' && fields['minebitda_multiple'] !== 'Any Min') {
            dataForQuery.minebitda_multiple = fields['minebitda_multiple'];
        }

        if(fields['maxebitda_multiple'] && fields['maxebitda_multiple'] !== 'Any Max' && fields['maxebitda_multiple'] !== 'Any Min') {
            dataForQuery.maxebitda_multiple = fields['maxebitda_multiple'];
        }

        if(fields['minrevenue_multiple'] && fields['minrevenue_multiple'] !== 'Any Max' && fields['minrevenue_multiple'] !== 'Any Min') {
            dataForQuery.minrevenue_multiple = fields['minrevenue_multiple'];
        }

        if(fields['maxrevenue_multiple'] && fields['maxrevenue_multiple'] !== 'Any Max' && fields['maxrevenue_multiple'] !== 'Any Min') {
            dataForQuery.maxrevenue_multiple = fields['maxrevenue_multiple'];
        }

        if(fields['mincashflow_multiple'] && fields['mincashflow_multiple'] !== 'Any Max' && fields['mincashflow_multiple'] !== 'Any Min') {
            dataForQuery.mincashflow_multiple = fields['mincashflow_multiple'];
        }

        if(fields['maxcashflow_multiple'] && fields['maxcashflow_multiple'] !== 'Any Max' && fields['maxcashflow_multiple'] !== 'Any Min') {
            dataForQuery.maxcashflow_multiple = fields['maxcashflow_multiple'];
        }

        // const i_id = this.state.categoryOption && this.state.categoryOption.value !== 'All' ? this.state.categoryOption.value : ''; //
        const i_id = this.state.categoryOption.map(bi => bi.value)[0];
        this.props.getFeaturedTransactions(i_id, {latitude, longitude});
        fetch(process.env.REACT_APP_API_ROOT + `transaction/search/featured_profile?industry_id=${i_id}&latitude=${latitude}&longitude=${longitude}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => res.json()).then(res => {
            this.setState({ 
                featured_profile: res 
            });
        });

        this.makeSearch(JSON.stringify(dataForQuery), infinity);
        
        if(!isFromPopstate && !infinity){
          delete dataForQuery.sortKey;
          delete dataForQuery.sortDirectionDesc;
          this.props.history.push('/advanced-search/' + this.makeSearchQueryString(dataForQuery));
        }
      
    };

    makeSearchQueryString = searchData => {
        delete searchData.rows;
        // in future if this field will be added just remove line below
        delete searchData.orderby;

        let output = '';
        for(let property in searchData){
            if(searchData.hasOwnProperty(property) && searchData[property]){
                if(!output){
                    output = '?'
                } else {
                    output += '&'
                }
                output += property + '=' +  searchData[property];
            }
        }
        return output;
    };


    makeSearch = (searchData, infinity) => {
      const parseSearchData = _.pickBy(JSON.parse(searchData), _.identity);
      if (!infinity) {
        this.setState({ searchLoader: true });
      }

      if(Object.keys(parseSearchData).length > 5) {
          this.setState({ hasSearch: true });
      }

      api.post('transaction/search', {
          advancedSearch: true,
          ...JSON.parse(searchData)
      }, true)
        .then((res) => {
          // document.getElementById('loader').setAttribute("style", "display: none;");
          this.setState({ listReady: true, sortLoading: false });
          const onlyNumbers = s => s ? s.replace(/[^0-9]/g, '') : '';
          if (res.status === 200) {
            const list = infinity ? this.state.SearchResultList.concat(res.list) : res.list;
            const SearchResultList =  list.map(deal => ({ ...deal })).sort((a,b) => {
                if(['price', 'revenue', 'cashflow', 'ebitda'].includes(this.state.sort)) {
                    if(this.state.sortDirectionDesc) {
                        return onlyNumbers(b[this.state.sort]) - onlyNumbers(a[this.state.sort]);
                    } else {
                        return onlyNumbers(a[this.state.sort]) - onlyNumbers(b[this.state.sort]);
                    }
                   
                }
                if(['name', 'industry', 'location'].includes(this.state.sort)) {
                    let nameA, nameB = '';
                    if(this.state.sort === 'name') {
                        nameA = a.name.toUpperCase();
                        nameB = b.name.toUpperCase();
                    }

                    if(this.state.sort === 'location') {
                        nameA = a.location_address.toUpperCase();
                        nameB = b.location_address.toUpperCase();
                    }
                    
                    if(this.state.sort === 'industry') {
                        if(a.industries.length > 0 && b.industries.length > 0) {
                            nameA = a.industries[0].industry.name.toUpperCase();
                            nameB = b.industries[0].industry.name.toUpperCase();
                        }
                    }
                    
                    if(this.state.sortDirectionDesc) {
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                    } else {
                        if (nameA > nameB) {
                            return -1;
                        }
                        if (nameA < nameB) {
                            return 1;
                        }
                    }
                   
                }
                return true;
            })

            this.setState({ SearchResultList, totalrecords: res.total, message: '' });
    
            const save_this_search_modal = getWithExpiry('save_this_search_modal');
            const show_banner = getWithExpiry('show_banner');
            
            if(this.state.hasSearch && show_banner === null) {
                this.setState({ showBanner: true });
            }
    
            if( ! save_this_search_modal && this.state.hasSearch && !(this.state.savedSearch && this.state.savedSearch.value) && localStorage.getItem('token')) {
                setTimeout(() => {
                    this.setState({
                        saveThisSearchModal: true
                    });
                }, 3000);
    
                setWithExpiry('save_this_search_modal', true, 86400 * 1000);
            }

          } else {
            this.setState({ SearchResultList: [], message: res.message, totalrecords: 0 })
          }
          localStorage.setItem("buyloaction","");
          localStorage.setItem("buylatitude","")
          localStorage.setItem("buylongitude","");
          localStorage.setItem("buycategory","");

        })
        .catch((err) => {
            console.log(err);
          this.setState({
            //error
          });
        })
          .finally(() => {
              if (!infinity) {
                  this.setState({ searchLoader: false });
              }
          })
    };

    onCloseBanner = () => {
        setWithExpiry('show_banner', true, 86400 * 7000);
        this.setState({ showBanner: false });
    }


    starRatings = (stars) => {
        let ratings = [];
        let filledstars = stars;
        let blankstars = 5 - stars;
        for (let k = 0; k < filledstars; k++) {
            ratings.push(<i className="sprite i-starfill-md" key={"filledstars" + k}></i>);
        }
        for (let k = 0; k < blankstars; k++) {
            ratings.push(<i className="sprite i-star-md" key={"blankstars" + k}></i>);
        }
        return ratings;
    }
    truncString(str, max, add) {
        add = add || '...';
        return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
    };

    handleSaveSearh = () => {
        if(! this.state.savedSearchName.trim() || !this.state.selectedProject) {
            if (! this.state.savedSearchName.trim()) {
                this.setState({
                    savedSearchNameError: 'Name is required'
                })
            }
            if (!this.state.selectedProject) {
                this.setState({
                    selectedProjectError: 'Target Folder is required'
                })
            }
            return;
        }

        api.post('user/saved_search', { project_id: this.state.selectedProject.value || null, name: this.state.savedSearchName, data: JSON.stringify({
            fields: this.state.fields,
            // location: this.state.location,
            // locationType: this.state.locationType,
            // city: this.state.city,
            // state: this.state.state,
            // country: this.state.country,
            locations: this.state.locations,
            categoryOption: this.state.categoryOption,
            // from_latitude: this.state.Lat,
            // from_longitude: this.state.Lng,
        })}, true)
        .then((res) => {
            if(res.status === 'success') {
                toast.info("New Search was Successfully Saved", {
                    position: "bottom-right",
                    className: 'toast-bar',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({ showSavedSearch: false, savedSearchName: '' });
                this.fetchSavedSearch();
            } else if(res.message) {
                this.setState({ savedSearchNameError: res.message });
            }

        })
    } 

    handleDeleteSavedSearch = () => {
        fetch(process.env.REACT_APP_API_ROOT + 'user/saved_search', {
            method: 'delete',
            body: JSON.stringify({ id:  this.state.savedSearch.value.id}),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*",
              'x-access-token': localStorage.getItem('token'),
            },
        }).then(() => {
            this.setState({ showSavedSearch: false, savedSearch: null });
            this.fetchSavedSearch();
        });
    }

    redirectLink(name) {
        return `/${name}?redirect_url=${window.location.href}`;  
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    saveThisSearch = () => {
        this.setState({ saveThisSearchModal: false });
        if(!localStorage.getItem('token')) {
            this.toggle();
            return;
        }
        this.setState({ showSavedSearch: !this.state.showSavedSearch });
    }

    parentCategory = (e) => {
        let id = e.target.id;
        let splitValue = id.split("_");
        let splitid = splitValue[0];
        let type = splitValue[1];

        let parent = 0;
        let child = 0;

        // eslint-disable-next-line
        this.state.categorylist.map((industrySelected, i) => {
            if (industrySelected.checked === true) {
                parent++;
            } else {
                // eslint-disable-next-line
                industrySelected.children.map((industryChildren, j) => {
                    if (industryChildren.checked === true) {
                        child++;
                    }
                });
            }
        });

        let categorydata = this.state.categorylist;
        if (type === 'parent') {
            if (e.target.checked === true) {
                categorydata[splitid].checked = true;
                // eslint-disable-next-line
                categorydata[splitid].children.map((lists, i) => {
                    categorydata[splitid].children[i].checked = true;
                });
                this.setState({selectparentlabel: 1});
            } else {

                if (parent === 1 && child === 1) {
                    this.setState({selectparentlabel: 1});
                } else if (parent === 0 && child === 1) {
                    this.setState({selectparentlabel: 0});
                } else if (parent === 1 && child === 0) {
                    this.setState({selectparentlabel: 0});
                } else {
                    this.setState({selectparentlabel: 1});
                }
                categorydata[splitid].checked = false;
                categorydata[splitid].partialed = false;
                // eslint-disable-next-line
                categorydata[splitid].children.map((lists, i) => {
                    categorydata[splitid].children[i].checked = false;
                });
            }
            this.setState({categorylist: categorydata, profileupdate: 1});
        } else {
            let child_id = splitValue[2];
            if (e.target.checked === true) {
                categorydata[splitid].partialed = true;
                categorydata[splitid].children[child_id].checked = true;
                let checkboxlength = categorydata[splitid].children.length;
                let count_checked = 0;
                // eslint-disable-next-line
                categorydata[splitid].children.map((lists, i) => {
                    if (categorydata[splitid].children[i].checked === true) {
                        count_checked++;
                    }
                });
                if (count_checked === checkboxlength) {
                    categorydata[splitid].checked = true;
                }
                this.setState({selectparentlabel: 1});
            } else {
                if (parent === 1 && child === 1) {
                    //categorydata[splitid].partialed = false;
                    this.setState({selectparentlabel: 1});
                } else if (parent === 0 && child === 1) {
                    //categorydata[splitid].partialed = false;
                    this.setState({selectparentlabel: 0});
                } else if (parent === 1 && child === 0) {
                    //categorydata[splitid].partialed = false;
                    this.setState({selectparentlabel: 1});
                } else {
                    //categorydata[splitid].partialed = false;
                    this.setState({selectparentlabel: 1});
                }

                let count_checked = 0;
                // eslint-disable-next-line
                categorydata[splitid].children.map((lists, i) => {
                    if (categorydata[splitid].children[i].checked === true) {
                        count_checked++;
                    }
                });
                if (count_checked === 1) {
                    categorydata[splitid].partialed = false;
                } else {
                    categorydata[splitid].partialed = true;
                }

                categorydata[splitid].checked = false;
                categorydata[splitid].children[child_id].checked = false;
            }
            this.setState({categorylist: categorydata, profileupdate: 1});
        }

    }

    removeCategoryClick(e) {
        // categoryRemoveClicked = true;
        let parent = 0;
        // eslint-disable-next-line
        this.state.categorylist.map((industrySelected, i) => {
            if (industrySelected.checked === true) {
                parent++;
            } else {
                // eslint-disable-next-line
                industrySelected.children.map((industryChildren, j) => {
                    if (industryChildren.checked === true) {
                        parent++;
                    }
                });
            }
        });

        let categorydata = this.state.categorylist;
        let id = e.target.id;
        let splitValue = id.split("_");
        let splitid = splitValue[0];
        let type = splitValue[1];
        if (type === 'parentselected') {
            categorydata[splitid].checked = false;
            // eslint-disable-next-line
            categorydata[splitid].children.map((lists, i) => {
                categorydata[splitid].children[i].checked = false;
            });
        } else {
            let child_id = splitValue[2];
            categorydata[splitid].children[child_id].checked = false;
        }
        this.setState({categorylist: categorydata, profileupdate: 1});

        if (parent === 1) {
            this.setState({selectparentlabel: 0});
        }
    }

    arrowtoggle = (e) => {
        let id = e.target.id;
        let splitValue = id.split("_");
        let splitid = splitValue[1];
        let categorydata = this.state.categorylist;
        if (categorydata[splitid].toggle === undefined) {
            categorydata[splitid].toggle = true;
        } else {
            if (categorydata[splitid].toggle === false) {
                categorydata[splitid].toggle = true;
            } else {
                categorydata[splitid].toggle = false;
            }
        }
        this.setState({categorylist: categorydata});
    }

    onCreateNewProject = () => {
        if(!this.state.projectName) {
            this.setState({ newProjectError: 'Name is required' })
            return null;
        }
        trackEvent({ category: 'Listing', action: 'New Buy-Side Project' });
        this.setState({ newProjectModal: false });
        api.post('user/create_new_project', {name: this.state.projectName}, true)
            .then(async (res) => {
                if(this.state.listingToFavorite.id && res && res.id) {
                    await api.post('user/saved_listings/' + this.state.listingToFavorite.id, { project_id: res.id, }, true)
                    await api.get('transaction/track/' + this.state.listingToFavorite.id, null, true);
                    toast.info("New Project was Successfully Saved", {
                        position: "bottom-right",
                        className: 'toast-bar',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.SubmitSearchResult();
                    this.setState({ projectName: '' });
                    await this.fetchSavedSearch();
                    this.setState({ selectedProject: { value: res.id, label: res.name }, selectedProjectError: '' });
                } else if(res && res.id) {
                    this.setState({ projectName: '' });
                    this.setState({ selectedProject: { value: res.id, label: res.name }, selectedProjectError: '' });
                    await this.fetchSavedSearch();
                }

                
            });
    }

    onHideDeal = transaction_id => {
        api.post('transaction/hide_deal', { transaction_id }, true)
            .then(() => {
                toast.info("The deal is now hidden", {
                    position: "bottom-right",
                    className: 'toast-bar',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.SubmitSearchResult();
            });
    }

    onAddToFavorite = data => {
        if (this.state.savedListings.includes(data.id)) {
            this.setState(prevState => ({
                ...prevState,
                savedListings: prevState.savedListings.filter(id => id !== data.id)
            }), () => {
                api.delete('user/saved_listings/' + data.id, null, true).then(() => {
                    this.fetchSavedSearch();
                })
            })

        } else {
            this.setState({ assignListingModal: !this.state.assignListingModal, listingToFavorite: data });
        }
    }

    onClickAssignListing = () => {
        if(!this.state.assignListingTo) {
            return null;
        }
    
        this.setState({ assignListingModal: false });
        api.post('user/saved_listings/' + this.state.listingToFavorite.id, { project_id: this.state.assignListingTo.value, }, true)
            .then(() => {
                toast.info("The deal was successfully saved", {
                    position: "bottom-right",
                    className: 'toast-bar',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.fetchSavedSearch();
                this.SubmitSearchResult();
            });

        api.get('transaction/track/' + this.state.listingToFavorite.id, null, true);
    }

    changeViewMode = viewMode => {
        localStorage.setItem('viewMode', viewMode);
        this.setState({ viewMode });
    }

    onChangeSort = sort => {
        this.setState({ sortLoading: true, sort, sortDirectionDesc: sort === this.state.sort ? !this.state.sortDirectionDesc : true }, () => {
            this.getSearchResultlist({
                orderby: this.state.orderby,
                page: 1,
            }, true);
        });

    }

    renderSortIcon = name => {
        if(name === this.state.sort) {
            if(this.state.sortDirectionDesc) {
                return (
                    <i class="fas fa-sort-down" style={{ color: 'white', position: 'relative', top: -3, fontSize: 12 }}></i>
                )
            } else {
                return (
                    <i class="fas fa-sort-up" style={{ color: 'white', position: 'relative', top: 3, fontSize: 12 }}></i>
                )
            }
            
        }

        return (<i class="fas fa-sort-down" style={{ color: 'white', position: 'relative', top: -3, fontSize: 12 }}></i>);
    }

    fetchMoreData = () => {
        this.setState({ activePageInfinity: this.state.activePageInfinity + 1 });
        this.getSearchResultlist({
            orderby: this.state.orderby,
            page: this.state.activePageInfinity,
        }, false, true);
    }
    clearSavedSearch (cb) {
        this.setState(prevState => ({ ...prevState, savedSearch: null, hasSearch: false, }), () => {
            cb()
        })
    }

    render() {
        // console.log(this.state.pageTitle)

        const loggedIn = !!localStorage.getItem('token');
        
        const FakeDeal = image => <div class="business-card mt-3" style={{ width: '100%', filter: 'blur(4px)' }}>
                            <div class="business-card__image position-relative">
                                <img src={image} alt="" style={{ width: 250 }} />
                                <div className="" style={{ position: 'absolute', bottom: 0, width: 250 }}>
                                    <div style={{ borderRadius: 0 }} class="btn btn-primary w-100">Price: $250,000</div>
                                </div>
                                
                            </div>
                            <div class="business-card__content" style={{ width: '100%', paddingLeft: 80 }}>
                                <div class="business-card__actions">
                                
                                
                                
                                </div>
                                <h3 class="business-card__title">
                                    <a style={{ color: '#373c41' }} href={`/`} target="_blank" rel="noopener noreferrer">
                                    Name NameNameNameName
                                    </a>
                                </h3>
                                
                                <p class="d-flex" style={{ fontSize: 16 }}>
                                <span style={{ backgroundColor: 'transparent', border: '1px solid black', marginRight: 10 }} class="badge text-dark">
                                    <i style={{ color: 'gold' }} class="fas fa-star"></i> Premium Deal
                                </span>
                                </p>
                                

                                <p class="business-card__location d-flex">
                                <span class="me-1">
                                    <svg class="icon" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                <span>location_address</span>
                                </p>
                                <p class="business-card__description" style={{ whiteSpace: 'normal' }}>
                                descriptiondescriptiondescriptiondescription descriptiondescriptiondescriptiondescription descriptiondescriptiondescriptiondescription
                                </p>
                                <div class="row row-gutter-15">
                                <div class="col">
                                    <p>
                                        <span class="font-weight-bolder">Revenue: </span>
                                        <p class="text-primary-blue">$500000</p>
                                    </p>
                                </div>
                                <div class="col">
                                    <p>
                                        <span class="font-weight-bolder">SDE: </span>
                                        <p class="text-primary-blue">$500000</p>
                                    </p>
                                </div>
                                <div class="col">
                                    <p>
                                        <span class="font-weight-bolder">EBITDA: </span>
                                        <p class="text-primary-blue">$500000</p>
                                    </p>
                                </div>
                                <div class="col">
                                    <a href={`/`} target="_blank" rel="noopener noreferrer" class="btn btn-primary">View details</a>
                                </div>
                                </div>
                            
                            </div>
                        </div>;
  
        return (
            <div>
                <Header slugUrl={this.state.slugName} />
                <Helmet title={this.state.pageTitle} />
                <div className="loader bg-white position-fixed" id="loader" style={{ display: 'none' }}><h3>Please Wait...</h3><div className="spiner"></div></div>
                
               
                <Modal isOpen={this.state.modal} toggle={this.toggle} style={{ width: 350 }}>
                    <ModalBody>
                        <h3>Please sign in</h3>
                        <a href={this.redirectLink('login')} className="btn-primary btn w-100">Sign In</a>
                        <div className="break txt--center txt--subtle push--top push_half--bottom">
                        <div className="break__content">Or</div>
                        </div>
                        <a href={this.redirectLink('signup')} className="btn-primary btn w-100">Sign Up</a>
                    </ModalBody>
                </Modal>


                <div className="p-3">
                    <div className="d-flex row pl-3" style={{ marginRight: 0, minHeight: 800, height: 'calc(100vh - 300px)', overflow: 'auto' }}>
                        <div className="col-lg-3 col-md-0 ac-sidebar">
                            <AdvancedConsole
                                savedSearchList={this.state.savedSearchList}
                                fetchSearch={this.parseUrlToSearch}
                                isSmallScreen={this.state.isSmallScreen}
                                isMobileScreen={this.state.isMobileScreen}
                                savedSearch={{
                                    clearSavedSearch: this.clearSavedSearch,
                                    savedSearch: this.state.savedSearch,
                                    showSavedSearch: this.state.showSavedSearch,
                                    hasSearch: this.state.hasSearch,
                                    saveThisSearch: this.saveThisSearch,
                                    handleDeleteSavedSearch: this.handleDeleteSavedSearch,
                                }}
                            />
                        </div>
                        <div className="col-md-12 col-lg-9 ac-content">
                            <div className="search-results">

                                {!this.state.listReady && <div className="text-center justify-content-center container d-flex" style={{ minHeight: 500 }}>
                                    <Bars
                                        color="#3890da"
                                        height={60}
                                        width={60}
                                        ariaLabel="bars-loading"
                                        visible={true}
                                    />
                                </div>}

                                {this.state.listReady && <div className="homepage">

                                    <Row className="result-description mb-3" style={{ paddingRight: 15 }}>
                                        <Col className="align-self-center form-inline">

                                            <div style={{ fontSize: 13, color: 'rgb(93, 93, 93)' }} >
                                                {(this.state.user_type_id && this.state.user_type_id !== 3 && this.state.totalrecords >= 100) &&
                                                    <Label>
                                                        About {Math.ceil(this.state.totalrecords / 100) * 100} results
                                                    </Label>
                                                }
                                                {(this.state.user_type_id && this.state.user_type_id !== 3 && this.state.totalrecords < 100) &&
                                                    <Label>
                                                        {this.state.totalrecords} results
                                                    </Label>
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="auto">
                                            <div className="form-inline">




                                                <FormGroup className="d-none me-sm-2 d-sm-block">
                                                    <Label style={{color: this.state.viewMode === 'list' ? 'rgb(56, 144, 218)' : '#5d5d5d', cursor: 'pointer', borderBottom: this.state.viewMode === 'list' ? '1px solid rgb(56, 144, 218)' : 'none' }} onClick={() => this.changeViewMode('list')}>
                                                        List View<i className="fas fa-th-list ms-1"></i>
                                                    </Label>
                                                </FormGroup>
                                                {
                                                    this.state.isSmallScreen !== undefined && !this.state.isSmallScreen && <FormGroup className="d-none me-sm-2 d-sm-block">
                                                        <Label style={{ color: this.state.viewMode === 'table' ? 'rgb(56, 144, 218)' : '#5d5d5d', cursor: 'pointer', borderBottom: this.state.viewMode === 'table' ? '1px solid rgb(56, 144, 218)' : 'none' }} onClick={() => this.changeViewMode('table')}>
                                                            Table View<i className="fas fa-table ms-1"></i>
                                                        </Label>

                                                    </FormGroup>
                                                }

                                            </div>
                                        </Col>
                                    </Row>
                                </div>}

                                {(this.state.listReady && this.state.SearchResultList.length === 0 && loggedIn) &&
                                    <React.Fragment>
                                        <div className="justify-content-center row container search_fixed_width margin_center">
                                            <div className="col-xl-10">
                                                {/*<div className="profile-bx">*/}
                                                {/*    <div className="profile-form mt-4 mb-4">*/}
                                                {/*        <p className="text-center">There are currently no businesses for sale that match your search criteria. Please, broaden your search or subscribe to our buyer's list to be notified when a listing is posted</p>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="text-center mb-4">
                                                    <div className="mb-4 ms-5">
                                                        <svg width="275" height="212" viewBox="0 0 302 212" fill="none">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M98.2128 99.1643C97.7483 99.3101 97.2518 99.3101 96.7874 99.1643L20.2527 75.2482C19.2533 74.9359 18.5742 74.0118 18.5742 72.9644C18.5742 71.9186 19.2533 70.9945 20.2527 70.6822L96.7874 46.766C97.2518 46.6203 97.7483 46.6203 98.2128 46.766L174.747 70.6822C175.747 70.9945 176.426 71.9186 176.426 72.9644C176.426 74.0118 175.747 74.9359 174.747 75.2482L98.2128 99.1643ZM166.014 72.9645L97.4994 51.5547L28.9843 72.9645L97.4994 94.3759L166.014 72.9645Z" fill="#CFDFF0"/>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M97.5005 94.4904C96.1792 94.4904 95.1094 95.5619 95.1094 96.8831V192.548C95.1094 193.309 95.4713 194.025 96.0831 194.475C96.6965 194.927 97.4877 195.058 98.2132 194.831L174.748 170.915C175.747 170.602 176.426 169.678 176.426 168.632V101.663C176.426 100.343 175.355 99.2718 174.035 99.2718C172.714 99.2718 171.643 100.343 171.643 101.663V166.873L99.8925 189.295V96.8815C99.8925 95.5602 98.8227 94.4888 97.5014 94.4888L97.5005 94.4904Z" fill="#CFDFF0"/>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M97.5001 94.4904C98.8214 94.4904 99.8912 95.5619 99.8912 96.8831V192.548C99.8912 193.309 99.5293 194.025 98.9175 194.475C98.3041 194.927 97.5129 195.058 96.7874 194.831L20.2527 170.915C19.2533 170.602 18.5742 169.678 18.5742 168.632V101.663C18.5742 100.343 19.6457 99.2718 20.9653 99.2718C22.2866 99.2718 23.3581 100.343 23.3581 101.663V166.873L95.1081 189.295V96.8815C95.1081 95.5602 96.1779 94.4888 97.4992 94.4888L97.5001 94.4904Z" fill="#CFDFF0"/>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M172.803 70.9153C171.671 71.5943 171.304 73.0646 171.983 74.1969L184.77 95.5058L112.934 117.955L99.5511 95.6518C98.872 94.5195 97.4034 94.1527 96.2711 94.8318C95.1388 95.5108 94.7704 96.9811 95.4511 98.1134L109.801 122.03C110.368 122.978 111.51 123.412 112.564 123.082L189.098 99.1656C189.798 98.9462 190.359 98.4193 190.62 97.7338C190.881 97.0483 190.814 96.2812 190.436 95.6518L176.086 71.7357C175.407 70.6034 173.936 70.2366 172.804 70.9157L172.803 70.9153Z" fill="#CFDFF0"/>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2004 70.9153C23.3327 71.5943 23.6995 73.0646 23.0204 74.1969L10.2333 95.5058L82.0694 117.955L95.4522 95.6518C96.1313 94.5195 97.5999 94.1527 98.7322 94.8318C99.8645 95.5108 100.233 96.9811 99.5522 98.1134L85.2022 122.03C84.6353 122.978 83.4934 123.412 82.4395 123.082L5.90484 99.1656C5.20497 98.9462 4.64441 98.4193 4.38337 97.7338C4.12231 97.0483 4.18958 96.2812 4.56755 95.6518L18.9175 71.7357C19.5966 70.6034 21.0652 70.2366 22.1991 70.9157L22.2004 70.9153Z" fill="#CFDFF0"/>
                                                            <mask id="mask0_328_170" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="90" y="0" width="212" height="212">
                                                                <rect x="90" width="212" height="212" fill="#D9D9D9"/>
                                                            </mask>
                                                            <g mask="url(#mask0_328_170)">
                                                                <circle cx="179.5" cy="86.5" r="44.5" fill="#F8F8F8"/>
                                                                <path d="M265.597 175L210.566 121.148C206.164 124.882 201.101 127.754 195.377 129.764C189.654 131.775 184.004 132.78 178.428 132.78C164.927 132.78 153.48 128.184 144.088 118.994C134.696 109.803 130 98.6017 130 85.3899C130 72.1782 134.696 60.9769 144.088 51.7862C153.48 42.5954 164.927 38 178.428 38C191.929 38 203.375 42.5954 212.767 51.7862C222.159 60.9769 226.855 72.1782 226.855 85.3899C226.855 91.2778 225.755 97.022 223.553 102.623C221.352 108.223 218.491 112.962 214.969 116.84L270 170.692L265.597 175ZM178.428 126.748C190.314 126.748 200.332 122.762 208.479 114.789C216.621 106.822 220.692 97.022 220.692 85.3899C220.692 73.7579 216.621 63.9553 208.479 55.9823C200.332 48.0151 190.314 44.0314 178.428 44.0314C166.541 44.0314 156.527 48.0151 148.385 55.9823C140.237 63.9553 136.164 73.7579 136.164 85.3899C136.164 97.022 140.237 106.822 148.385 114.789C156.527 122.762 166.541 126.748 178.428 126.748Z" fill="#CFDFF0"/>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <p style={{ color: '#777777', fontSize: 20 }}>
                                                        No deals found. Can't find what you're looking for? Try our off-market deal origination program with BizNexus Concierge.
                                                    </p>
                                                    <a className="btn btn-success" rel="noopener noreferrer" href="https://www.biznexus.com/concierge-services" target="_blank">Schedule a Strategy Session Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                {
                                    this.state.SearchResultList.length > 0 &&
                                    <div
                                        id="scrollableDiv"
                                        style={{
                                            height: 'calc(90vh - 125px)',
                                            overflow: 'auto',
                                            ...(this.state.viewMode === 'list' ? {margin: '-15px', padding: '15px'} : {})
                                        }}
                                    >


                                        <InfiniteScroll
                                            dataLength={this.state.SearchResultList.length}
                                            next={this.fetchMoreData}
                                            style={{ overflow: 'inherit' }}
                                            hasMore={Boolean(loggedIn && this.state.totalrecords > 25 && this.state.totalrecords > this.state.SearchResultList.length)}
                                            loader={this.state.totalrecords > 25 ? <div className="text-center d-flex justify-content-center container pt-4">
                                                <Bars
                                                    color="#3890da"
                                                    height={60}
                                                    width={60}
                                                    ariaLabel="bars-loading"
                                                    visible={true}
                                                />
                                            </div>: null}
                                            scrollableTarget="scrollableDiv"
                                        >
                                            <div className="SearchResultView" ref={(section) => { this.SearchResultView = section; }}>
                                                <FeaturedListing list={this.props.transactions.featuredListings} onAddToFavorite={this.onAddToFavorite}
                                                                 savedListings={this.state.savedListings} />
                                                {(this.state.featured_profile && this.state.featured_profile.user) && <a href={`/profile/${this.state.featured_profile.user_id}`}>
                                                    {(this.state.featured_profile && this.state.featured_profile.user) && <Row style={{ paddingTop: 30 }} className="search_fixed_width margin_center">

                                                        <div className="gridview col-sm-12 col-md-10 col-lg-12">
                                                            {/* <h3 style={{ color: 'grey' }}>Tech&Media Intermediaries</h3> */}
                                                            <Row style={{ marginLeft: '0px', marginRight: '0px' }} className="franchises-bx">

                                                                <div className="col-sm-2" style={{ margin: 'auto', padding: '10px' }}>
                                                                    <img alt="" width="100px" height="60px" src={(this.state.featured_profile.user.profile_pic) ? (this.state.featured_profile.user.profile_pic.indexOf('base64') > -1) ? `${this.state.featured_profile.user.profile_pic}` : (this.state.featured_profile.user.profile_pic.indexOf('user_profile') > -1) ? getImageUrl(this.state.featured_profile.user.profile_pic) : getImageUrl(this.state.featured_profile.user.profile_pic) : '/images/thumbnail-small.gif'}
                                                                         className="img-fluid rounded-circle d-block m-auto" />
                                                                </div>

                                                                <div className="col-sm-7">
                                                                    <p style={{ paddingTop: '10px' }} className="text-blue">{this.state.featured_profile.user.name}, {USER_TYPE_LABEL[this.state.featured_profile.user.user_type_id]}</p>

                                                                    <p style={{ fontSize: '15px', color: 'black' }}>{this.state.featured_profile.description.slice(0, 200)}</p>
                                                                </div>

                                                                <div className="col-sm-3" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                                    <div className="align-self-center text-center">
                                                                        <a href={`/profile/${this.state.featured_profile.user_id}`} className="btn btn-primary w-100 mt-10">View Profile</a>
                                                                    </div>
                                                                </div>



                                                            </Row>
                                                        </div>


                                                    </Row>}
                                                </a>}


                                                <div className={`justify-content-center ${this.state.viewMode === 'list' ? '' : ''}`}>



                                                    {this.state.viewMode === 'list' ?
                                                        this.state.SearchResultList.map((resultList, j) => {
                                                            return (
                                                                <ListItem data={resultList}
                                                                          premium={this.state.premium}
                                                                          key={`data-${j}`}
                                                                          onHideDeal={this.onHideDeal}
                                                                          onAddToFavorite={this.onAddToFavorite}
                                                                          savedListings={this.state.savedListings}
                                                                          j={j}
                                                                          viewMode={this.state.viewMode}
                                                                          gridView={this.state.gridview === '3' ? '3' : '10'}
                                                                />
                                                            )

                                                        })
                                                        :
                                                        <div className="tableFixHead" style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                            {
                                                                this.state.SearchResultList.length > 3 && ((this.state.ad1 && this.state.ad1.id) || (this.state.ad2 && this.state.ad2.id))
                                                                    ? <><Table bordered hover striped>
                                                                        <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'name' ? '#1F69A7' : '#282828' }} className="cursor_pointer" onClick={() => this.onChangeSort('name')}>Deal Name {this.renderSortIcon('name')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'industry' ? '#1F69A7' : '#282828' }} className="cursor_pointer" onClick={() => this.onChangeSort('industry')}>Industry {this.renderSortIcon('industry')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'location' ? '#1F69A7' : '#282828' }} className="cursor_pointer" onClick={() => this.onChangeSort('location')}>Location {this.renderSortIcon('location')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'price' ? '#1F69A7' : '#282828' }} className="cursor_pointer" onClick={() => this.onChangeSort('price')}>Price {this.renderSortIcon('price')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'revenue' ? '#1F69A7' : '#282828', width: 170 }} className="cursor_pointer" onClick={() => this.onChangeSort('revenue')}>Revenue / Multiple {this.renderSortIcon('revenue')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'ebitda' ? '#1F69A7' : '#282828', width: 157 }} className="cursor_pointer" onClick={() => this.onChangeSort('ebitda')}>EBITDA / Multiple {this.renderSortIcon('ebitda')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'cashflow' ? '#1F69A7' : '#282828', width: 148 }} className="cursor_pointer" onClick={() => this.onChangeSort('cashflow')}>SDE / Multiple {this.renderSortIcon('cashflow')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'listing_date' ? '#1F69A7' : '#282828', width: 105 }} className="cursor_pointer" onClick={() => this.onChangeSort('listing_date')}>Updated {this.renderSortIcon('listing_date')}</th>
                                                                        </tr>
                                                                        </thead>

                                                                        <tbody style={{ position: 'relative' }}>
                                                                        {this.state.sortLoading && <div className="text-center justify-content-center container d-flex" style={{ minHeight: 500, top: 100, position: 'absolute', left: 0, right: 0, bottom: 0  }}>
                                                                            <Bars
                                                                                color="#3890da"
                                                                                height={60}
                                                                                width={60}
                                                                                ariaLabel="bars-loading"
                                                                                visible={true}
                                                                            />
                                                                        </div>}
                                                                        {this.state.SearchResultList.slice(0, 3).map((resultList, j) =>
                                                                            <ListItem data={resultList}
                                                                                      premium={this.state.premium}
                                                                                      key={`data-${j}`}
                                                                                      onHideDeal={this.onHideDeal}
                                                                                      onAddToFavorite={this.onAddToFavorite}
                                                                                      savedListings={this.state.savedListings}
                                                                                      viewMode={this.state.viewMode}
                                                                                      j={j}
                                                                                      gridView={this.state.gridview === '3' ? '3' : '10'}
                                                                            />
                                                                        )}
                                                                        </tbody>
                                                                    </Table>
                                                                        <div style={{ paddingBottom: '15px' }} className="text-center"><AdDisplayer plan={this.state.ad1 && this.state.ad1.id ? this.state.ad1 : this.state.ad2} /></div>
                                                                        {
                                                                        this.state.SearchResultList.length > 6 && this.state.ad1 && this.state.ad1.id && this.state.ad2 && this.state.ad2.id
                                                                            ? <><Table bordered hover striped>
                                                                                <thead>
                                                                                <tr>
                                                                                    <th></th>
                                                                                    <th style={{backgroundColor: this.state.sort === 'name' ? '#1F69A7' : '#282828'}}
                                                                                        className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('name')}>Deal
                                                                                        Name {this.renderSortIcon('name')}</th>
                                                                                    <th style={{backgroundColor: this.state.sort === 'industry' ? '#1F69A7' : '#282828'}}
                                                                                        className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('industry')}>Industry {this.renderSortIcon('industry')}</th>
                                                                                    <th style={{backgroundColor: this.state.sort === 'location' ? '#1F69A7' : '#282828'}}
                                                                                        className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('location')}>Location {this.renderSortIcon('location')}</th>
                                                                                    <th style={{backgroundColor: this.state.sort === 'price' ? '#1F69A7' : '#282828'}}
                                                                                        className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('price')}>Price {this.renderSortIcon('price')}</th>
                                                                                    <th style={{
                                                                                        backgroundColor: this.state.sort === 'revenue' ? '#1F69A7' : '#282828',
                                                                                        width: 170
                                                                                    }} className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('revenue')}>Revenue
                                                                                        /
                                                                                        Multiple {this.renderSortIcon('revenue')}</th>
                                                                                    <th style={{
                                                                                        backgroundColor: this.state.sort === 'ebitda' ? '#1F69A7' : '#282828',
                                                                                        width: 157
                                                                                    }} className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('ebitda')}>EBITDA
                                                                                        /
                                                                                        Multiple {this.renderSortIcon('ebitda')}</th>
                                                                                    <th style={{
                                                                                        backgroundColor: this.state.sort === 'cashflow' ? '#1F69A7' : '#282828',
                                                                                        width: 148
                                                                                    }} className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('cashflow')}>SDE
                                                                                        /
                                                                                        Multiple {this.renderSortIcon('cashflow')}</th>
                                                                                    <th style={{
                                                                                        backgroundColor: this.state.sort === 'listing_date' ? '#1F69A7' : '#282828',
                                                                                        width: 105
                                                                                    }} className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('listing_date')}>Updated {this.renderSortIcon('listing_date')}</th>
                                                                                </tr>
                                                                                </thead>

                                                                                <tbody style={{position: 'relative'}}>
                                                                                {this.state.sortLoading && <div
                                                                                    className="text-center justify-content-center container d-flex"
                                                                                    style={{
                                                                                        minHeight: 500,
                                                                                        top: 100,
                                                                                        position: 'absolute',
                                                                                        left: 0,
                                                                                        right: 0,
                                                                                        bottom: 0
                                                                                    }}>
                                                                                    <Bars
                                                                                        color="#3890da"
                                                                                        height={60}
                                                                                        width={60}
                                                                                        ariaLabel="bars-loading"
                                                                                        visible={true}
                                                                                    />
                                                                                </div>}
                                                                                {this.state.SearchResultList.slice(3, 6).map((resultList, j) =>
                                                                                    <ListItem data={resultList}
                                                                                              premium={this.state.premium}
                                                                                              key={`data-${j}`}
                                                                                              onHideDeal={this.onHideDeal}
                                                                                              onAddToFavorite={this.onAddToFavorite}
                                                                                              savedListings={this.state.savedListings}
                                                                                              viewMode={this.state.viewMode}
                                                                                              j={j}
                                                                                              gridView={this.state.gridview === '3' ? '3' : '10'}
                                                                                    />
                                                                                )}
                                                                                </tbody>
                                                                            </Table>
                                                                                <div style={{ paddingBottom: '15px' }} className="text-center"><AdDisplayer plan={this.state.ad2} /></div>
                                                                                <Table bordered hover striped>
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th></th>
                                                                                        <th style={{backgroundColor: this.state.sort === 'name' ? '#1F69A7' : '#282828'}}
                                                                                            className="cursor_pointer"
                                                                                            onClick={() => this.onChangeSort('name')}>Deal
                                                                                            Name {this.renderSortIcon('name')}</th>
                                                                                        <th style={{backgroundColor: this.state.sort === 'industry' ? '#1F69A7' : '#282828'}}
                                                                                            className="cursor_pointer"
                                                                                            onClick={() => this.onChangeSort('industry')}>Industry {this.renderSortIcon('industry')}</th>
                                                                                        <th style={{backgroundColor: this.state.sort === 'location' ? '#1F69A7' : '#282828'}}
                                                                                            className="cursor_pointer"
                                                                                            onClick={() => this.onChangeSort('location')}>Location {this.renderSortIcon('location')}</th>
                                                                                        <th style={{backgroundColor: this.state.sort === 'price' ? '#1F69A7' : '#282828'}}
                                                                                            className="cursor_pointer"
                                                                                            onClick={() => this.onChangeSort('price')}>Price {this.renderSortIcon('price')}</th>
                                                                                        <th style={{
                                                                                            backgroundColor: this.state.sort === 'revenue' ? '#1F69A7' : '#282828',
                                                                                            width: 170
                                                                                        }} className="cursor_pointer"
                                                                                            onClick={() => this.onChangeSort('revenue')}>Revenue
                                                                                            /
                                                                                            Multiple {this.renderSortIcon('revenue')}</th>
                                                                                        <th style={{
                                                                                            backgroundColor: this.state.sort === 'ebitda' ? '#1F69A7' : '#282828',
                                                                                            width: 157
                                                                                        }} className="cursor_pointer"
                                                                                            onClick={() => this.onChangeSort('ebitda')}>EBITDA
                                                                                            /
                                                                                            Multiple {this.renderSortIcon('ebitda')}</th>
                                                                                        <th style={{
                                                                                            backgroundColor: this.state.sort === 'cashflow' ? '#1F69A7' : '#282828',
                                                                                            width: 148
                                                                                        }} className="cursor_pointer"
                                                                                            onClick={() => this.onChangeSort('cashflow')}>SDE
                                                                                            /
                                                                                            Multiple {this.renderSortIcon('cashflow')}</th>
                                                                                        <th style={{
                                                                                            backgroundColor: this.state.sort === 'listing_date' ? '#1F69A7' : '#282828',
                                                                                            width: 105
                                                                                        }} className="cursor_pointer"
                                                                                            onClick={() => this.onChangeSort('listing_date')}>Updated {this.renderSortIcon('listing_date')}</th>
                                                                                    </tr>
                                                                                    </thead>

                                                                                    <tbody style={{position: 'relative'}}>
                                                                                    {this.state.sortLoading && <div
                                                                                        className="text-center justify-content-center container d-flex"
                                                                                        style={{
                                                                                            minHeight: 500,
                                                                                            top: 100,
                                                                                            position: 'absolute',
                                                                                            left: 0,
                                                                                            right: 0,
                                                                                            bottom: 0
                                                                                        }}>
                                                                                        <Bars
                                                                                            color="#3890da"
                                                                                            height={60}
                                                                                            width={60}
                                                                                            ariaLabel="bars-loading"
                                                                                            visible={true}
                                                                                        />
                                                                                    </div>}
                                                                                    {this.state.SearchResultList.slice(6).map((resultList, j) =>
                                                                                        <ListItem data={resultList}
                                                                                                  premium={this.state.premium}
                                                                                                  key={`data-${j}`}
                                                                                                  onHideDeal={this.onHideDeal}
                                                                                                  onAddToFavorite={this.onAddToFavorite}
                                                                                                  savedListings={this.state.savedListings}
                                                                                                  viewMode={this.state.viewMode}
                                                                                                  j={j}
                                                                                                  gridView={this.state.gridview === '3' ? '3' : '10'}
                                                                                        />
                                                                                    )}
                                                                                    </tbody>
                                                                                </Table>
                                                                            </>
                                                                            : <Table bordered hover striped>
                                                                                <thead>
                                                                                <tr>
                                                                                    <th></th>
                                                                                    <th style={{backgroundColor: this.state.sort === 'name' ? '#1F69A7' : '#282828'}}
                                                                                        className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('name')}>Deal
                                                                                        Name {this.renderSortIcon('name')}</th>
                                                                                    <th style={{backgroundColor: this.state.sort === 'industry' ? '#1F69A7' : '#282828'}}
                                                                                        className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('industry')}>Industry {this.renderSortIcon('industry')}</th>
                                                                                    <th style={{backgroundColor: this.state.sort === 'location' ? '#1F69A7' : '#282828'}}
                                                                                        className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('location')}>Location {this.renderSortIcon('location')}</th>
                                                                                    <th style={{backgroundColor: this.state.sort === 'price' ? '#1F69A7' : '#282828'}}
                                                                                        className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('price')}>Price {this.renderSortIcon('price')}</th>
                                                                                    <th style={{
                                                                                        backgroundColor: this.state.sort === 'revenue' ? '#1F69A7' : '#282828',
                                                                                        width: 170
                                                                                    }} className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('revenue')}>Revenue
                                                                                        /
                                                                                        Multiple {this.renderSortIcon('revenue')}</th>
                                                                                    <th style={{
                                                                                        backgroundColor: this.state.sort === 'ebitda' ? '#1F69A7' : '#282828',
                                                                                        width: 157
                                                                                    }} className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('ebitda')}>EBITDA
                                                                                        /
                                                                                        Multiple {this.renderSortIcon('ebitda')}</th>
                                                                                    <th style={{
                                                                                        backgroundColor: this.state.sort === 'cashflow' ? '#1F69A7' : '#282828',
                                                                                        width: 148
                                                                                    }} className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('cashflow')}>SDE
                                                                                        /
                                                                                        Multiple {this.renderSortIcon('cashflow')}</th>
                                                                                    <th style={{
                                                                                        backgroundColor: this.state.sort === 'listing_date' ? '#1F69A7' : '#282828',
                                                                                        width: 105
                                                                                    }} className="cursor_pointer"
                                                                                        onClick={() => this.onChangeSort('listing_date')}>Updated {this.renderSortIcon('listing_date')}</th>
                                                                                </tr>
                                                                                </thead>

                                                                                <tbody style={{position: 'relative'}}>
                                                                                {this.state.sortLoading && <div
                                                                                    className="text-center justify-content-center container d-flex"
                                                                                    style={{
                                                                                        minHeight: 500,
                                                                                        top: 100,
                                                                                        position: 'absolute',
                                                                                        left: 0,
                                                                                        right: 0,
                                                                                        bottom: 0
                                                                                    }}>
                                                                                    <Bars
                                                                                        color="#3890da"
                                                                                        height={60}
                                                                                        width={60}
                                                                                        ariaLabel="bars-loading"
                                                                                        visible={true}
                                                                                    />
                                                                                </div>}
                                                                                {this.state.SearchResultList.slice(3).map((resultList, j) =>
                                                                                    <ListItem data={resultList}
                                                                                              premium={this.state.premium}
                                                                                              key={`data-${j}`}
                                                                                              onHideDeal={this.onHideDeal}
                                                                                              onAddToFavorite={this.onAddToFavorite}
                                                                                              savedListings={this.state.savedListings}
                                                                                              viewMode={this.state.viewMode}
                                                                                              j={j}
                                                                                              gridView={this.state.gridview === '3' ? '3' : '10'}
                                                                                    />
                                                                                )}
                                                                                </tbody>
                                                                            </Table>
                                                                    }
                                                                    </>
                                                                    : <Table bordered hover striped>
                                                                        <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'name' ? '#1F69A7' : '#282828' }} className="cursor_pointer" onClick={() => this.onChangeSort('name')}>Deal Name {this.renderSortIcon('name')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'industry' ? '#1F69A7' : '#282828' }} className="cursor_pointer" onClick={() => this.onChangeSort('industry')}>Industry {this.renderSortIcon('industry')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'location' ? '#1F69A7' : '#282828' }} className="cursor_pointer" onClick={() => this.onChangeSort('location')}>Location {this.renderSortIcon('location')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'price' ? '#1F69A7' : '#282828' }} className="cursor_pointer" onClick={() => this.onChangeSort('price')}>Price {this.renderSortIcon('price')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'revenue' ? '#1F69A7' : '#282828', width: 170 }} className="cursor_pointer" onClick={() => this.onChangeSort('revenue')}>Revenue / Multiple {this.renderSortIcon('revenue')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'ebitda' ? '#1F69A7' : '#282828', width: 157 }} className="cursor_pointer" onClick={() => this.onChangeSort('ebitda')}>EBITDA / Multiple {this.renderSortIcon('ebitda')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'cashflow' ? '#1F69A7' : '#282828', width: 148 }} className="cursor_pointer" onClick={() => this.onChangeSort('cashflow')}>SDE / Multiple {this.renderSortIcon('cashflow')}</th>
                                                                            <th style={{ backgroundColor: this.state.sort === 'listing_date' ? '#1F69A7' : '#282828', width: 105 }} className="cursor_pointer" onClick={() => this.onChangeSort('listing_date')}>Updated {this.renderSortIcon('listing_date')}</th>
                                                                        </tr>
                                                                        </thead>

                                                                        <tbody style={{ position: 'relative' }}>
                                                                        {this.state.sortLoading && <div className="text-center d-flex justify-content-center container" style={{ minHeight: 500, top: 100, position: 'absolute', left: 0, right: 0, bottom: 0  }}>
                                                                            <Bars
                                                                                color="#3890da"
                                                                                height={60}
                                                                                width={60}
                                                                                ariaLabel="bars-loading"
                                                                                visible={true}
                                                                            />
                                                                        </div>}
                                                                        {this.state.SearchResultList.map((resultList, j) =>
                                                                            <ListItem data={resultList}
                                                                                      premium={this.state.premium}
                                                                                      key={`data-${j}`}
                                                                                      onHideDeal={this.onHideDeal}
                                                                                      onAddToFavorite={this.onAddToFavorite}
                                                                                      savedListings={this.state.savedListings}
                                                                                      viewMode={this.state.viewMode}
                                                                                      j={j}
                                                                                      gridView={this.state.gridview === '3' ? '3' : '10'}
                                                                            />
                                                                        )}
                                                                        </tbody>
                                                                    </Table>
                                                            }
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </InfiniteScroll>
                                        {this.state.premium ? <div style={{ textAlign: 'center', padding: 30 }}>
                                                {/*        <Pagination*/}
                                                {/*    hideFirstLastPages*/}
                                                {/*    activePage={this.state.activePage}*/}
                                                {/*    prevPageText={<i className='sprite i-prev' />}*/}
                                                {/*    nextPageText={<i className='sprite i-next' />}*/}
                                                {/*    itemsCountPerPage={this.state.staticNumberOfRow}*/}
                                                {/*    itemClass="page-item"*/}
                                                {/*    linkClass="page-link"*/}
                                                {/*    totalItemsCount={this.state.totalrecords}*/}
                                                {/*    pageRangeDisplayed={5}*/}
                                                {/*    onChange={this.handlePageChange}*/}
                                                {/*/>*/}
                                            </div>:
                                            loggedIn ? <React.Fragment>
                                                <div className="justify-content-center row mt-10 mt-4 search_fixed_width margin_center">
                                                    <div className="col-xl-12 container">
                                                        <div className="profile-bx container">
                                                            <div className="profile-form mt-4 mb-4">
                                                                <p className="text-center" style={{ fontSize: '17px', fontWeight: '800' }}>
                                                                    Upgrade to access thousands of listings and premium matching functionality
                                                                </p>
                                                                <p className="text-center">
                                                                    <i style={{ fontSize: '48px' }} className="fas fa-search"></i>
                                                                </p>
                                                                <p className="text-center">
                                                                    <a href="/user/subscription" className="btn btn-success">START FREE TRIAL</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment> : null

                                        }


                                    </div>
                                }

                                {((this.state.totalrecords === 0 && !loggedIn) || !loggedIn ) && <div className="search_fixed_width margin_center container" style={{ position: 'relative' }}>
                                    {FakeDeal('/images/default_listing/techmedia.jpg')}
                                    {FakeDeal('/images/default_listing/food.jpg')}
                                    {FakeDeal('/images/default_listing/leisure.jpg')}
                                    <div className="justify-content-center row " style={{
                                        position: 'absolute',
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        margin: 'auto',
                                        top: 'calc(50% - 50px)'
                                    }}>
                                        <div className="col-xl-12">
                                            <div className="profile-bx">
                                                <div className="profile-form mt-4 mb-4">
                                                    <p className="text-center" style={{ fontSize: '17px', fontWeight: '800' }}>
                                                        Sign in OR create your free account now to view your search results
                                                    </p>
                                                    <p className="text-center">
                                                        <a href="/signup" className="btn btn-success">Create a Free Account Now</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}



                                <p className="text-danger text-center">{this.state.message}</p>

                            </div>
                        </div>
                    </div>

                </div>

                
                <Footer />
            {/*    <Modal isOpen={this.state.saveThisSearchModal} toggle={() => this.setState({ saveThisSearchModal: !this.state.saveThisSearchModal })}>*/}
            {/*        <ModalHeader toggle={() => this.setState({ saveThisSearchModal: !this.state.saveThisSearchModal })}>Save This Search!!</ModalHeader>*/}
            {/*    <ModalBody>*/}
            {/*        We'll shoot you an update as soon as new opportunities that match this exact criteria become available.*/}
            {/*    </ModalBody>*/}
            {/*    <ModalFooter>*/}
            {/*        <Button onClick={this.saveThisSearch} color="success">Save this Search</Button>*/}
            {/*    </ModalFooter>*/}
            {/*</Modal>*/}

                <Modal isOpen={this.state.premiumModal} toggle={() => this.setState({ premiumModal: !this.state.premiumModal })}>
                    <ModalHeader toggle={() => this.setState({ premiumModal: !this.state.premiumModal })}>BizNexus</ModalHeader>
                    <ModalBody>
                        Upgrade your subscription to search by multiples.
                    </ModalBody>
                    <ModalFooter>
                        <a href="/premium" className="btn btn-info">Learn More</a>
                    </ModalFooter>
                </Modal>
                {
                    this.state.searchLoader ? <SearchLoader/> : ''
                }

                {/*{*/}
                {/*    this.state.showSavedSearch &&*/}
                {/*    <React.Fragment>*/}
                {/*        <div className="justify-content-center row margin_center">*/}
                {/*            <div className="col-xl-12">*/}
                {/*                <div className="profile-bx">*/}
                {/*                    <div className="profile-form mt-4 mb-4">*/}
                {/*                        <h2 className="h2 mb-4">Save this search:</h2>*/}
                {/*                        <div className="text-center">*/}
                {/*                            <Row className="m-0">*/}
                {/*                                <Col className="p-0">*/}
                {/*                                    <Input*/}
                {/*                                        invalid={!!this.state.savedSearchNameError}*/}
                {/*                                        onKeyPress={e => {*/}
                {/*                                            if (e.charCode === 13) {*/}
                {/*                                                this.handleSaveSearh();*/}
                {/*                                            }*/}
                {/*                                        }} value={this.state.savedSearchName} onChange={e => this.setState({ savedSearchName: e.target.value, savedSearchNameError: '' })} placeholder="Name This Search" />*/}
                {/*                                    {this.state.savedSearchNameError &&*/}
                {/*                                        <InputGroup>*/}
                {/*                                            <FormText color="danger">{this.state.savedSearchNameError}</FormText>*/}
                {/*                                        </InputGroup>*/}
                {/*                                    }*/}
                {/*                                </Col>*/}
                {/*                                <Col className="p-0">*/}
                {/*                                    <FormGroup style={{ marginLeft: '15px' }}>*/}
                {/*                                        <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}*/}
                {/*                                            className={!!this.state.selectedProjectError && 'invalid'}*/}
                {/*                                            isSearchable={false}*/}
                {/*                                            isClearable={false}*/}
                {/*                                            placeholder="Save To Target List"*/}
                {/*                                            onChange={(p) => {*/}
                {/*                                                if(p.value === 0) {*/}
                {/*                                                    this.setState({ newProjectModal: true })*/}
                {/*                                                } else {*/}
                {/*                                                    this.setState({ selectedProject: p, selectedProjectError: '' });*/}
                {/*                                                }*/}
                {/*                                            }}*/}
                {/*                                            options={this.state.savedSearchProjects.concat([{ label: 'Unassigned', value: null }, { label: '+ Create New', value: 0 }])}*/}
                {/*                                            value={this.state.selectedProject}*/}
                {/*                                        />*/}
                {/*                                        {this.state.selectedProjectError &&*/}
                {/*                                            <InputGroup>*/}
                {/*                                                <FormText color="danger">{this.state.selectedProjectError}</FormText>*/}
                {/*                                            </InputGroup>*/}
                {/*                                        }*/}
                {/*                                    </FormGroup>*/}

                {/*                                </Col>*/}

                {/*                                /!* <div className="p-1">*/}
                {/*                                                <button onClick={() => this.setState({ newProjectModal: true })} className="btn btn-success">*/}
                {/*                                                    New Project*/}

                {/*                                                </button>*/}
                {/*                                            </div> *!/*/}



                {/*                                <Col className="p-1">*/}
                {/*                                    <button onClick={this.handleSaveSearh} className="btn btn-primary" style={{ width: 150 }}>Save</button>*/}
                {/*                                </Col>*/}
                {/*                                <Col sm={1} className="p-1">*/}
                {/*                                    <a href="/advanced-search" onClick={(e) => {*/}
                {/*                                        e.preventDefault();*/}
                {/*                                        this.setState({ showSavedSearch: false });*/}
                {/*                                    }}*/}
                {/*                                       className="btn btn-unstyled btn-link">Cancel</a>*/}
                {/*                                </Col>*/}
                {/*                            </Row>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*    </React.Fragment>*/}
                {/*}*/}

                <Modal isOpen={this.state.showSavedSearch} toggle={() => this.setState({ showSavedSearch: !this.state.showSavedSearch })}
                       className="modal-dialog-centered delete-item" style={{}} keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({ showSavedSearch: !this.state.showSavedSearch })}>
                        Save this search
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label htmlFor="" md={4}>Search Name:</Label>
                            <Col md={7}>
                                <Input
                                    invalid={!!this.state.savedSearchNameError}
                                    onKeyPress={e => {
                                        if (e.charCode === 13) {
                                            this.handleSaveSearh();
                                        }
                                    }} value={this.state.savedSearchName} onChange={e => this.setState({ savedSearchName: e.target.value, savedSearchNameError: '' })} placeholder="Name This Search" />
                                {this.state.savedSearchNameError &&
                                    <InputGroup>
                                        <FormText color="danger">{this.state.savedSearchNameError}</FormText>
                                    </InputGroup>
                                }
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label htmlFor="" md={4}>Target Folder:</Label>
                            <Col md={7}>
                                <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                    className={`target-list-select ${!!this.state.selectedProjectError && 'invalid'}`}
                                    isSearchable={false}
                                    isClearable={false}
                                    placeholder="Save To Target Folder"
                                    onChange={(p) => {
                                        if(p.value === 0) {
                                            this.setState({ newProjectModal: true })
                                        } else {
                                            this.setState({ selectedProject: p, selectedProjectError: '' });
                                        }
                                    }}
                                    options={this.state.savedSearchProjects.concat([{ label: 'Unassigned', value: null }, { label: '+ Create New', value: 0 }])}
                                    value={this.state.selectedProject}
                                />
                                {this.state.selectedProjectError &&
                                    <InputGroup>
                                        <FormText color="danger">{this.state.selectedProjectError}</FormText>
                                    </InputGroup>
                                }
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.handleSaveSearh}>Save</Button>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={this.state.assignListingModal} toggle={() => this.setState({ assignListingModal: !this.state.assignListingModal })}
                className="modal-dialog-centered delete-item" style={{}} keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({ assignListingModal: !this.state.assignListingModal })}>
                        Assign to Target Folder
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label htmlFor="" md={4}>Assign to:</Label>
                            <Col md={7}>
                                <InputGroup>
                                    <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                        className="w-100"
                                        
                                        onChange={(p) => {
                                            if(p.value === 0) {
                                                
                                                this.setState({ assignListingModal: false });
                                                this.setState({ newProjectModal: true });
                                                
                                            } else {
                                                this.setState({ assignListingTo: p });
                                            }
                                        }}
                                        options={this.state.savedSearchProjects.concat([{ label: 'Unassigned', value: null }, { label: '+ Create New', value: 0 }])}
                                        value={this.state.assignListingTo}
                                    />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.onClickAssignListing}>Assign</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.newProjectModal} toggle={() => this.setState({ newProjectModal: !this.state.newProjectModal })}
                        className="modal-dialog-centered delete-item" keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({ newProjectModal: !this.state.newProjectModal })}>
                        Create a new Target Folder
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label md={12}>Add as many specific searches as you'd like for each acquisition client or project you have</Label>
                            <Label htmlFor="" md={4}>Name</Label>
                            <Col md={7}>
                                <InputGroup>
                                    <Input
                                        invalid={!!this.state.newProjectError }
                                        onKeyPress={e => {
                                                if (e.charCode === 13) {
                                                    this.onCreateNewProject();
                                                }
                                            }} value={this.state.projectName}
                                           onChange={(e) => this.setState({ projectName: e.target.value, newProjectError: '' })} type="text" />
                                </InputGroup>
                                {this.state.newProjectError &&
                                    <InputGroup>
                                        <FormText color="danger">{this.state.newProjectError}</FormText>
                                    </InputGroup>
                                }
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.onCreateNewProject}>Create</Button>
                    </ModalFooter>
                </Modal>

                <ScrollButton />
            </div>
        );
    }
}



const mapStateToProps = ({transactions}) => ({transactions});

const mapDispatchToProps = dispatch => ({
  getFeaturedTransactions: (industry_id, location) => dispatch(actions.getFeaturedTransactions(industry_id, location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdvancedSearch));
