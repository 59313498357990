import React from "react";
import {USER_TYPE} from "../../../../../constants/userTypes";


const providerFAQ = [
    { q: 'What is the BizNexus Marketplace?', a: <>
            Our marketplace promotes M&A advisors and service providers. We show real contact info and LinkedIn profile URL’s for each user, so our members can easily connect or message each other directly, outside of our own messaging system if you choose.
            <br /><br />
            All members of the BizNexus marketplace need to submit an application for review and approval before gaining access to our platform in an effort to maintain high quality interactions and minimize wasted time for our members.
        </> }, {
        q: 'What is BizNexus Concierge?', a: <>
            Our concierge solution is our fully managed B2B prospect sourcing solution with dedicated account & CRM management.
            <br /><br />
            For lead generation, we use a multi-channel approach spanning across social channels, email, phone & snail mail.
            <br /><br />
            Partnerships are exclusive, and monthly retainers start at $1800 per month.
            <br /><br />
            <a rel="noopener noreferrer" target="_blank" href="https://www.biznexus.com/concierge-services">Learn more.</a>
        </>
    }, {
        q: 'How do I get matched with buyers and sellers on BizNexus?', a: <>
            Each completed profile is matched with potential buyers and sellers in your industry niche or geographic area, and for premium subscribers you will receive prioritized placement for your suggested profiles for matched owners / potential sellers.
            <br /><br />
            Incomplete profiles will NOT be suggested or matched in the BizNexus marketplace. All profiles must have a real photo, complete overview and preferred industries sections to qualify for matching.
            <br /><br />
            For superior matching & branding opportunities inside and outside of our marketplace, please view our advertising and branding options.
            <br /><br />
            <a rel="noopener noreferrer" target="_blank" href="https://www.biznexus.com/advertise">Learn more.</a>
        </>
    }, {
        q: 'What premium community access do I get with a paid subscription?', a: <>
            Annual premium subscribers get bonus access to branding opportunities, sales & marketing prospecting masterclasses, networking groups & M&A-focused events in our community. Check out our <a rel="noopener noreferrer" target="_blank" href="https://community.biznexus.com/all-courses">premium masterclasses</a> for more information or schedule a strategy session <a rel="noopener noreferrer" target="_blank" href="https://calendly.com/biznexus-team/strategy-session">HERE.</a>
        </>
    }, {
        q: 'Is a premium membership a fit for me?', a: <>
            Premium membership gets you added exposure across our marketplace, newsletter, social accounts and community.
            <br /><br />
            Premium membership includes exclusive BizNexus partnerships with tools you can use for your prospecting efforts, along with actionable training included for using those tools as part of your deal flow program.
            <br /><br />
            Premium access puts you front-&-center with lower-middle market buyers, operators and potential sellers who have committed capital to stay active through volatile markets.
        </>
    }];

const brokerFAQ = [
    { q: 'What is the BizNexus Marketplace?', a: <>
            Our marketplace promotes intermediaries and intermediary-repped deal flow, with thousands of active broker-listed deals and teasers at any given time. We show real contact info and LinkedIn profile URL’s for each buyer and broker, so our users can easily connect or message each other directly, outside of our own messaging system if you choose.
            <br /><br />
            The BizNexus marketplace aggregates submitted / approved broker deals and removes expired opportunities daily.
            <br /><br />
            All members of the BizNexus marketplace need to submit an application for review and approval before gaining access to our platform in an effort to maintain high quality interactions and minimize wasted time for our members.
        </> },
    { q: 'What is BizNexus Concierge?', a: <>
            Our concierge solution is our fully managed deal sourcing solution with dedicated account & CRM management.
            <br /><br />
            For off-market, proprietary sourcing, we use a multi-channel approach spanning across social channels, email, phone & snail mail.
            <br /><br />
            Partnerships are exclusive, and monthly retainers start at $1800 per month.
            <br /><br />
            <a rel="noopener noreferrer" target="_blank" href="https://www.biznexus.com/concierge-services">Learn more.</a>
        </> },
    { q: 'How do I get matched with buyers and sellers on BizNexus?', a: <>
            Your public listings are primarily matched with buyers via direct email, and we also offer CRM integrations so your deals will automatically show up in buyer CRM if it’s a match with what they’re looking for. (FYI we realize this is a headache for the intermediary’s marketing tracking, but it’s a value-add for our buyers, and they’re our main source of income as we give so much away to intermediaries at no cost).
            <br /><br />
            Teasers are not matched with buyers. We show you suggested buyers, but it is 100% up to you to reach out to them for any private teaser you add.
            <br /><br />
            Each completed broker profile is matched with potential buyers and sellers in your industry niche or geographic area, and for premium subscribers we match you with individual and buyout group acquirers for each of your submitted listings or teasers.
            <br /><br />
            Premium subscribers also receive prioritized placement for your suggested profiles for matched owners / potential sellers.
            <br /><br />
            Incomplete profiles will NOT be suggested or matched in the BizNexus marketplace. All profiles must have a real photo, complete overview and preferred industries sections to qualify for matching.
        </> },
    { q: 'What kinds of deals are on BizNexus?', a: <>
            The majority of deals on the platform are managed by intermediaries, advisors and niche consultants. In addition to a larger number of manually added deals daily, we have exclusive API integration & deal curation partnerships in place with partner investment banks, brokerage firms, & other industry platforms like ours.
            <br /><br />
            For qualifying brokerages, our team can potentially facilitate the addition of your deals on our platform. Email us at <a href="mailto:deals@biznexus.com">deals@biznexus.com</a> for more information.
        </> },
    { q: 'What premium community access do I get with a paid subscription?', a: <>
            Annual premium subscribers get bonus access to branding opportunities, proprietary deal sourcing masterclasses, networking groups & intermediary-focused events in our community. Check out our <a rel="noopener noreferrer" target="_blank" href="https://www.biznexus.com/broker-bundle">Premium Masterclass M&A Advisor Bundle</a> for more information or schedule a strategy session <a rel="noopener noreferrer" target="_blank" href="https://calendly.com/biznexus-team/strategy-session">HERE.</a>
        </> },
    { q: 'How does BizNexus compare to other listing sites out there?', a: <>
            Rather than focus on quantity of deals, we focus on quality. All submitted deals and private teasers are verified and removed after a period of time unless the seller or seller’s representative confirms it’s active.
            <br /><br />
            We show real contact info and LinkedIn profile URL’s for each buyer and broker, so our users can easily connect or message each other directly, outside of our own messaging system if you choose.
            <br /><br />
            Buyers & business owners have to pay for  full access to our platform and community functionality, so in addition to contact info / buyer clarity you know that buyers / owners are qualified and actively pursuing deals in the market.
            <br /><br />
            We are also built by intermediaries, are on a mission to promote intermediaries, and are not owned by any monster parent companies or VC $$ with carefully laid plans for galaxy domination by death star.
        </> },
    { q: 'Is a premium membership a fit for me?', a: <>
            Premium membership gets you added exposure across our marketplace, newsletter, social accounts and community.
            <br /><br />
            Premium membership includes exclusive BizNexus partnerships with tools you can use for your brokerage deal origination efforts, along with actionable training included for using those tools as part of your M&A deal flow program.
            <br /><br />
            Premium access puts you front-&-center with lower-middle market buyers, operators and potential sellers who have committed capital to stay active through volatile markets.
        </> },
    { q: 'Does BizNexus charge a success fee or referral fee for listed deals in the marketplace?', a: <>
            No. In our marketplace broker deals are broker deals, and we only charge our buyers a premium, month-to-moth subscription fee to access our platform, data and matching and transaction management software.
        </> },
];

const buyerFAQ = [
    { q: 'What is the BizNexus marketplace?', a: <>
            Our marketplace aggregates an average of over 20k LIVE, verified acquisition opportunities and teasers at any given time. The platform continuously aggregates, approves and removes business acquisition opportunities from across the entire interweb of publicly available deals, intermediated deals. pre-CIM teasers & FSBO opportunities at various stages of the exit planning journey.
            <br /><br />
            We also match acquirers and sellers with intermediaries who match your specific target criteria, and each deal posted in the BizNexus marketplace includes up to 60 suggested acquirers & M&A professionals who match the exact criteria of your posted deal or private teaser.
            <br /><br />
            We require marketplace applicants to verify identity, search status & acquisition interest before gaining access to our platform in an effort to maintain high quality interactions and minimize wasted time for our members.
        </> },
    { q: 'What is BizNexus Concierge?', a: <>
            As of {new Date().getFullYear()}, nothing else exists like this.
            <br /><br />
            Our Concierge partners are set up with your own team access to our proprietary deal origination platform <a rel="noopener noreferrer"
            href="https://www.biznexus.com/about-omnisource" target="_blank">OmniSource<sup>TM</sup></a>.
            <br /><br />
            Target approval, on-market and off-market opportunities are sourced for your specific mandate, and we leverage AI and omnichannel outbound sourcing alongside niche trackable content for inbound origination. Concierge partnerships are exclusive, and we do not overlap our partner target mandates.
            <br /><br />
            If you’re interested in a demo, <a rel="noopener noreferrer"
                                               href="https://www.biznexus.com/demo" target="_blank">schedule one with our team today</a>.

        </> },
    { q: 'What kinds of deals are on BizNexus?', a: <>
            First, we average about 20k+ active deals in our marketplace and we not only add these daily, but we have a clear focus on REMOVING deals daily as well. All manually submitted deals expire after a specified time period unless proactively renewed by the intermediary or seller, and integrated deals update live status daily.
            <br /><br />
            All deals on the BizNexus platform must be pre-approved and are submitted directly by approved current business owners, intermediaries, sell-side transaction advisors and niche consultants.

            <br /><br />
            In addition to a larger number of manually added deals daily, we have exclusive curation partnerships in place with brokerage firms, industry platforms like ours, and other niche sell-side organizations.

            <br /><br />
            We also leverage our own proprietary technical processes to facilitate the addition of partner deals and publicly available deals from across the internet, so our premium members have unmatched exposure to deals coming-to-market or currently available on the market, eliminating the need to subscribe to and manage multiple feeds, newsletters and marketplace feeds.
            <br /><br />
            The majority of active buyers on our platform appreciate the superior depth of coverage we provide to reach fringe bolt-on deal flow submitted by or sourced from the fragmented universe of boutique brokerages and one-time sellers.
        </> },
    { q: 'Do you have special access for veterans and first responders?', a: <>
            Absolutely.

            <br /><br />
            Just prove you served and we’ll get you in there.

            <br /><br />
            Please contact our sales team at <a rel="noopener noreferrer" href="mailto:sales@biznexus.com" target="_blank">sales@biznexus.com</a>
            <br /><br />
            <i>Thank you for your service.</i>
        </> },
    { q: 'If an intermediary doesn’t get back to me, what can I do?', a: <>
            We publicly list typical response times for each intermediary on our platform.

            <br /><br />
            We show you publicly available contact information for each business broker that lists their deal on our platform, so you can reach out to them directly outside of the BizNexus Marketplace.
            <br /><br />
            We can and will proactively reach out to intermediaries who do not respond to your inquiry within 24 hours if needed.

            <br /><br />
            We regularly kick bad-actors off of our platform.

            <br /><br />
            With all of that, ideally this wouldn’t still be an issue, but it is. As a whole, the business brokerage industry needs to work on this, but we’re doing our part to highlight the top-performers.

            <br /><br />
            If you have any trouble reaching an intermediary just ping us at <a rel="noopener noreferrer" href="mailto:nudge@biznexus.com" target="_blank">nudge@biznexus.com</a> and we’ll personally reach out to the intermediary to give them a “nudge.”


        </> },
];

const FAQ = ({ userType, fullWidth = false }) => {
    return (
        <section className="pt-5">
            <h2 className="h1 mb-5 text-center">Frequently asked questions</h2>

            <div className="accordion" id="faq">
                <div className={!fullWidth ? "col-lg-6" : ""} style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                    <div>

                        {userType === USER_TYPE.BROKER && brokerFAQ.map((o, i) =>
                            <div className="">
                                <div className="card">
                                    <div className="card-header" id={`heading-${i}`}>
                                        <button className="btn btn-link d-flex w-100 justify-content-between text-decoration-none"
                                                type="button" data-toggle="collapse" data-target={`#collapse-${i}`}
                                                aria-expanded="false" aria-controls={`collapse-${i}`}>
                                            <span>{o.q}</span>
                                            <span className="btn-icon">
                                        <span className="d-when-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span className="d-when-not-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                                        </button>
                                    </div>

                                    <div id={`collapse-${i}`} className="collapse" aria-labelledby={`heading-${i}`}
                                         data-parent="#faq">
                                        <div className="card-body">
                                            {o.a}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {userType !== USER_TYPE.BROKER && buyerFAQ.map((o, i) =>
                            <div className="">
                                <div className="card">
                                    <div className="card-header" id={`heading-${i}`}>
                                        <button className="btn btn-link d-flex w-100 justify-content-between text-decoration-none"
                                                type="button" data-toggle="collapse" data-target={`#collapse-${i}`}
                                                aria-expanded="false" aria-controls={`collapse-${i}`}>
                                            <span>{o.q}</span>
                                            <span className="btn-icon">
                                        <span className="d-when-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span className="d-when-not-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                                        </button>
                                    </div>

                                    <div id={`collapse-${i}`} className="collapse" aria-labelledby={`heading-${i}`}
                                         data-parent="#faq">
                                        <div className="card-body">
                                            {o.a}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {userType === USER_TYPE.BROKER && providerFAQ.map((o, i) =>
                            <div className="">
                                <div className="card">
                                    <div className="card-header" id={`heading-${i}`}>
                                        <button className="btn btn-link d-flex w-100 justify-content-between text-decoration-none"
                                                type="button" data-toggle="collapse" data-target={`#collapse-${i}`}
                                                aria-expanded="false" aria-controls={`collapse-${i}`}>
                                            <span>{o.q}</span>
                                            <span className="btn-icon">
                                        <span className="d-when-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                        <span className="d-when-not-expanded">
                                        <svg className="icon" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
                                                  fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                                  fill="currentColor"/>
                                        </svg>

                                        </span>
                                    </span>
                                        </button>
                                    </div>

                                    <div id={`collapse-${i}`} className="collapse" aria-labelledby={`heading-${i}`}
                                         data-parent="#faq">
                                        <div className="card-body">
                                            {o.a}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQ
